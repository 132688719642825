import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 10,
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f2f2f2",
  },
  usersCount: {
    padding: "0 10px",
  },
});

export default useStyles;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Table from "../../Table";
import useStyles from "../../../useStyles";
import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { ComplianceAPI } from "../../../../../api/compliance";
import { IntlFormatUSD } from "../../../../../utils";
import SearchByListValues from "../../SearchByListValues";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { getQueryStringByStringValues } from "../../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";
import { CENTS_IN_DOLLAR, DATE_FORMAT_hhmmssAzz } from "../../../../../constants";
import { useComplianceSearchContext } from "../../../../../context";
import { CopyToClipboard } from "../../../../../components/ui";

const COLUMNS: Array<IColum> = [
  {
    by: "acc_email",
    title: "Email",
    path: "acc_email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "total_spent",
    title: "Total spent",
    path: "total_spent",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
    getCellValue: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "total_orders",
    title: "Total orders",
    path: "total_orders",
  },
  {
    by: "total_new_purchases",
    title: "Total new purchases",
    path: "total_new_purchases",
  },
  {
    by: "total_paid_resets",
    title: "Total paid resets",
    path: "total_paid_resets",
  },
  {
    by: "total_free_resets",
    title: "Total free resets",
    path: "total_free_resets",
  },
  {
    by: "total_rebills",
    title: "Total rebills",
    path: "total_rebills",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "reg_ip",
    title: "Reg IP",
    path: "reg_ip",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
  {
    by: "address",
    title: "Address",
    path: "address",
  },
  {
    by: "address2",
    title: "Address 2",
    path: "address2",
  },
  {
    by: "city",
    title: "City",
    path: "city",
  },
  {
    by: "stateprov",
    title: "Stateprov",
    path: "stateprov",
  },
  {
    by: "country",
    title: "Country",
    path: "country",
  },
  {
    by: "joined",
    title: "Joined",
    path: "joined",
    getCellValue: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
    copyToClipBoardCallBack: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
  },
];

const ResetsRebillsNewPurchases = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { emails } = tabsTablesSearchState.quickSearchTab.resetsRebillsNewPurchasesTable.search;

  const { data, loading } = useSelector(
    (state) => state.complianceSearch.resetsRebillsNewPurchases
  );

  const fetchData = async (values: { emails: string }) => {
    setTabsTablesSearchState({
      ...tabsTablesSearchState,
      quickSearchTab: {
        ...tabsTablesSearchState.quickSearchTab,
        resetsRebillsNewPurchasesTable: {
          search: { emails: values.emails },
        },
      },
    });

    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesRequest());

      const response = await ComplianceAPI.getAccountsResetsRebillsNewPurchases(
        getQueryStringByStringValues("emails", values.emails)
      );

      dispatch(
        ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesSuccess(
          response.data.accounts
        )
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesFailed());
      console.log("error fetching ResetsRebillsNewPurchases data", error);
    }
  };
  return (
    <div className={classes.container}>
      <b className={classes.title}>Search resets, rebills, new purchases</b>
      <SearchByListValues
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        initialValues={{ emails }}
      />

      <Table
        loading={loading}
        columns={COLUMNS}
        extractKey={(row) => row.acc_email}
        data={data}
        csv_table_name="E2T quick search - Search resets, rebills, new purchases"
      />
    </div>
  );
};

export default ResetsRebillsNewPurchases;

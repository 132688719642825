import React from "react";
import { Grid } from "@material-ui/core";
import { filter } from "lodash";

interface ISearchContainer {
  children: React.ReactNode;
  name: string;
  searchText: string;
  fields: string[];
  currentFilters: string[];
}

const SearchContainer = ({
  children,
  name,
  fields,
  searchText,
  currentFilters,
}: ISearchContainer): React.ReactElement | null => {
  const isMatchSearch = fields.filter((el) => el.includes(searchText)).includes(name);

  if (searchText.length || currentFilters.length) {
    const selectedOptions = currentFilters.map((curr) => filter(fields, (s) => s.includes(curr)));

    if ((searchText && isMatchSearch) || selectedOptions.flat().includes(name)) {
      return (
        <Grid item md={4} xs={4}>
          {children}
        </Grid>
      );
    }

    return null;
  }

  return (
    <Grid item md={4} xs={4}>
      {children}
    </Grid>
  );
};

export default SearchContainer;

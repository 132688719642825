import React, { useEffect, useState } from "react";
import { List, ListItem } from "@material-ui/core";

import { ERRORS_DICTIONARY } from "../../errorsDictionary";
import { REG_EXP_DOUBLE_QUOTES } from "../Logs";
import * as Types from "../../index.types";

interface IProps {
  csv: Types.TCsv;
}
const ERROR_CODE_CEIL_PATTERN_TITLE = ["ErrorCode", "Error", "Error Code"];
type TError = [string, number, string | undefined][];
const ErrorsInfo: React.FC<IProps> = ({ csv }) => {
  const [errors, setErrors] = useState<TError>([]);
  useEffect(() => {
    const errors: TError = [];

    csv.forEach((row, index): void => {
      if (ERROR_CODE_CEIL_PATTERN_TITLE.some((s) => row.some(({ value }) => value.includes(s)))) {
        const errorCellIndex = row.findIndex((s) =>
          ERROR_CODE_CEIL_PATTERN_TITLE.includes(s.value)
        );
        const [administrative_operation_name] = csv[index + 1];
        const error_code =
          errorCellIndex === -1
            ? null
            : +csv[index + 1][errorCellIndex]?.value.replace(REG_EXP_DOUBLE_QUOTES, "");

        if (error_code) {
          const error_name = administrative_operation_name.value.replace(REG_EXP_DOUBLE_QUOTES, "");
          const error_code_description =
            // @ts-ignore
            ERRORS_DICTIONARY[error_name][error_code] ||
            "Unknown code error, please contact Rithmic Operations";

          errors.push([error_name, error_code, error_code_description]);
        }
      }
    });

    setErrors(errors);
  }, [csv]);

  return (
    <div>
      <List>
        {errors.map(([administrative_operation_name, error_code, error_description]) => (
          <ListItem key={administrative_operation_name}>
            {administrative_operation_name} - error code:{error_code} - {error_description}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ErrorsInfo;

interface ICheckiSPassedEvaluation {
  largest_pnl_percent: number;
  pnl: number;
  profit_goal: number;
  days_traded: number;
}
export const checkisPassedEvaluation = ({
  largest_pnl_percent,
  pnl,
  profit_goal,
  days_traded,
}: ICheckiSPassedEvaluation): boolean =>
  largest_pnl_percent <= 30 && pnl > profit_goal && days_traded >= 15;

import httpClient from "../apiClient";
import { TGetAppLogsRequest } from "./index.types";

export const DEBUG_API_URLS = {
  appLogs: "/app-logs",
} as const;

export class AppDebugAPI {
  static getAppLogsRequest: TGetAppLogsRequest = () => httpClient.post(DEBUG_API_URLS.appLogs);
}

import React, { useEffect, useRef, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import pretty from "pretty";

const useStyles = makeStyles({
  htmlTextArea: {
    minWidth: "100%",
    fontSize: "13px",
  },
});
interface IProps {
  html: string;
}

const HTMLPreview = ({ html }: IProps): React.ReactElement => {
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [isShowRaw, setShowRaw] = useState(false);

  const handleMouseEnterLeave = () => setShowRaw(!isShowRaw);

  useEffect(() => {
    setTimeout(() => {
      ref.current && setHeight(ref.current.clientHeight);
    }, 0);
  }, [html]);

  return (
    <div style={{ height }}>
      <TextField
        ref={ref}
        onMouseEnter={handleMouseEnterLeave}
        onMouseLeave={handleMouseEnterLeave}
        disabled
        multiline
        className={classes.htmlTextArea}
        value={isShowRaw ? html : pretty(html)}
        variant="outlined"
      />
    </div>
  );
};

export default HTMLPreview;

import React, { useEffect, useState } from "react";

import {
  ContainerWithLoader,
  TableColumnsView,
  SortableTableHeader,
  PaginationWithLimit,
} from "../../../../components/ui";
import { APIRithmic } from "../../../../api";
import { IRithmicPastPosition } from "../../../../api/rithmic/index.types";

import useClasses from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import TableBody from "./TableBody";
import { useIntercomContext } from "../../../../context";
import usePagination from "../../../../hooks/usePagination";

interface IColumns {
  text: string;
  name: string;
}
const DEFAULT_ACTIVE_COLUMNS = [
  "date",
  "AcctBal",
  "CashBal",
  "Commissions",
  "ClosedPnl",
  "NetPnl",
  "BuyQty",
  "SellQty",
  "NetQty",
];

const PastPositions = (): React.ReactElement => {
  const classes = useClasses();
  const generalClasses = useGeneralStyles();
  const { gauntletAccountName } = useIntercomContext();
  const { page, setPage, limit, setLimit, count, setCount } = usePagination();

  const [sortBy, setSortBy] = useState({
    by: "date",
    order: false,
  });

  const [loading, setLoading] = useState(true);
  const [rithmicData, setRithmicData] = useState<IRithmicPastPosition[]>([]);
  const [tableColumns, setTableColumns] = useState<IColumns[]>([]);

  const [activeColumns, setActiveColumns] = useState<string[]>(DEFAULT_ACTIVE_COLUMNS);

  const handleShowExternalColumns = (value: string[]) => setActiveColumns(value);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await APIRithmic.getRithmicPastPositionsRequest(gauntletAccountName, {
          limit,
          sortBy,
          page,
        });
        setRithmicData(data.rithmicData.rows);
        setCount(data.rithmicData.count);
        const columns = data.columns.map((key) => ({ text: key, name: key }));
        setTableColumns(columns);
      } catch (error) {
        console.error("Error fetching past position", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, sortBy, limit]);

  useEffect(() => {
    setPage(0);
  }, [limit]);

  return (
    <div>
      <ContainerWithLoader
        isLoading={loading}
        report={rithmicData}
        noResultsMessage="There is no history"
      >
        <div className={classes.actionsWrapper}>
          <TableColumnsView
            columns={tableColumns}
            relatedTables={activeColumns}
            handleShowExternalColumns={handleShowExternalColumns}
          />
        </div>

        <div className={classes.tableContainer}>
          <table className={generalClasses.table}>
            <SortableTableHeader
              activeColumns={activeColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <TableBody activeColumns={activeColumns} data={rithmicData} />
          </table>
        </div>
      </ContainerWithLoader>

      {!!rithmicData.length && (
        <PaginationWithLimit
          count={count}
          limit={limit.toString()}
          page={page}
          setPageState={setPage}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default PastPositions;

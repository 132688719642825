import React from "react";
import cx from "classnames";

import useStyles from "../../useStyles";
import { useCustomerProvider } from "../../../../context/CustomerProvider";

interface IProps {
  popup: string;
  setPopup: (popup: string) => void;
  createFinamark: () => void;
}

const CreateFinamarkUserWindow: React.FC<IProps> = ({ popup, setPopup, createFinamark }) => {
  const classes = useStyles();
  const { fina, setFina } = useCustomerProvider();

  return (
    <div
      className={cx("fina fina-create", classes.createFinUserContainer, {
        [classes.hidden]: popup !== "fina-create",
        [classes.openModal]: popup === "fina-create",
      })}
    >
      <div className="title">Create Finamark User</div>
      <input
        type="text"
        name="user"
        placeholder="Username"
        value={fina.user}
        onChange={(e) => setFina({ ...fina, user: e.target.value })}
      />
      <input
        type="text"
        name="pass"
        placeholder="Password"
        value={fina.pass}
        onChange={(e) => setFina({ ...fina, pass: e.target.value })}
      />
      <input
        type="text"
        name="system"
        placeholder="System"
        value={fina.sys}
        onChange={(e) => setFina({ ...fina, sys: e.target.value })}
      />
      <input
        type="text"
        name="gateway"
        placeholder="Gateway"
        value={fina.gway}
        onChange={(e) => setFina({ ...fina, gway: e.target.value })}
      />
      <button type="button" onClick={() => createFinamark()}>
        Create
      </button>
      <button type="button" onClick={() => setPopup("")}>
        Cancel
      </button>
    </div>
  );
};

export default CreateFinamarkUserWindow;

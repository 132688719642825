import React from "react";
import { mergeWith } from "lodash";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import { Chart as ChartJS } from "chart.js";
import { Line, ChartProps } from "react-chartjs-2";

interface ICtx {
  p0: { skip: boolean };
  p1: { skip: boolean };
}
export const skipped = (ctx: ICtx, value: number | number[]): number | number[] | undefined =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

export interface IDataLineChart {
  labels: string[];
  datasets: ChartProps<"line", number[], string>["data"];
}
interface IProps {
  data: ChartProps<"line", number[], string>["data"];
  externalOptions?: ChartProps<"line", number[], string>["options"];
  height?: number;
}
const LineChart: React.FC<IProps> = ({ data, externalOptions }: IProps) => {
  ChartJS.register(DataLabelsPlugin);

  const options: ChartProps<"line", number[], string>["options"] = {
    scales: {
      yAxes: {
        ticks: {
          // beginAtZero: true,
        },
      },
    },
    plugins: {
      tooltip: {
        position: "nearest",
      },
      datalabels: {
        display: true,
        color: "black",
        font: { size: 10 },
        anchor: "end",
        align: "end",
      },
    },
  };

  return <Line data={data} options={mergeWith(options, externalOptions)} />;
};

export default LineChart;

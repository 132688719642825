import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  hover: {
    cursor: "pointer",
  },
  container: {
    marginBottom: 5,
  },
  expandButton: {
    marginBottom: 5,
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 7,
  },
  borderBottom: {
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,.2)",
    },
  },
  timestamp: {
    minWidth: 80,
    textAlign: "end",
  },
});
export default useStyles;

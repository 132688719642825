/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import {
  ICustomersInfo,
  IGetAllEvaluationsResponseData,
  IRithmicView,
} from "../api/evaluations/index.types";

export type EvaluationsAction =
  | EvaluationsFetchRequest
  | EvaluationsFetchSuccess
  | EvaluationsFetchFailed;

interface EvaluationsFetchRequest extends ReduxAction {
  type: EvaluationsActionType.evaluationsFetchRequest;
}
interface EvaluationsFetchSuccess extends ReduxAction {
  type: EvaluationsActionType.evaluationsFetchSuccess;
  payload: {
    report: IRithmicView[];
    customersInfo: ICustomersInfo[];
  };
}
interface EvaluationsFetchFailed extends ReduxAction {
  type: EvaluationsActionType.evaluationsFetchFailed;
}

export enum EvaluationsActionType {
  evaluationsFetchRequest = "evaluations/FetchRequest",
  evaluationsFetchSuccess = "evaluations/FetchSuccess",
  evaluationsFetchFailed = "evaluations/FetchFailed",
}

export const EvaluationsActions = {
  fetchEvaluationsRequest: (): EvaluationsAction => ({
    type: EvaluationsActionType.evaluationsFetchRequest,
  }),
  fetchEvaluationsSuccess: ({
    customersInfo,
    report,
  }: IGetAllEvaluationsResponseData): EvaluationsAction => ({
    type: EvaluationsActionType.evaluationsFetchSuccess,
    payload: {
      customersInfo,
      report,
    },
  }),
  fetchEvaluationsFailed: (): EvaluationsAction => ({
    type: EvaluationsActionType.evaluationsFetchFailed,
  }),
};

export interface IEvaluationsState {
  data: {
    evaluations: {
      report: IRithmicView[];
      customersInfo: ICustomersInfo[];
      count: number;
    };
    loading: boolean;
  };
}

const initialState: IEvaluationsState = {
  data: {
    evaluations: {
      report: [],
      customersInfo: [],
      count: 0,
    },
    loading: false,
  },
};

const evaluationsReducer = (
  prevState = initialState,
  action: EvaluationsAction
): IEvaluationsState =>
  produce(prevState, (draft: IEvaluationsState) => {
    switch (action.type) {
      case EvaluationsActionType.evaluationsFetchRequest:
        draft.data.loading = true;
        break;
      case EvaluationsActionType.evaluationsFetchSuccess:
        draft.data.loading = false;
        draft.data.evaluations.report = action.payload.report;
        draft.data.evaluations.customersInfo = action.payload.customersInfo;
        draft.data.evaluations.count = action.payload.report[0]?.full_count;
        break;
      case EvaluationsActionType.evaluationsFetchFailed:
        draft.data.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default evaluationsReducer;

import React, { createContext, useContext, useEffect, useState } from "react";
import usePagination from "../../hooks/usePagination";
import { ISortBy } from "../../types";

type TBy = "email" | "accounts" | "id";
export type TSortParams = ISortBy<TBy>;

interface IProps {
  children: React.ReactNode;
}

interface IRithmicAccountsContextProps {
  search: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in TBy]?: any;
  };
  page: number;
  setPage: (page: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearch: (a: any) => void;
  limit: string;
  setLimit: (limit: string) => void;
  sortBy: TSortParams;
  setSortBy: (sort: TSortParams) => void;
}
const RithmicAccountsContext = createContext<IRithmicAccountsContextProps>({
  search: {},
  setSearch: () => null,
  page: 0,
  setPage: () => ({}),
  limit: "20",
  setLimit: () => ({}),
  sortBy: {
    by: "email",
    order: false,
  },
  setSortBy: () => null,
});

const RithmicAccountsProvider = ({ children }: IProps): React.ReactElement => {
  const [search, setSearch] = useState({});
  const { page, setPage, limit, setLimit } = usePagination();
  const [sortBy, setSortBy] = useState<TSortParams>({
    by: "email",
    order: false,
  });

  useEffect(() => {
    setPage(0);
  }, [search]);
  return (
    <RithmicAccountsContext.Provider
      value={{
        search,
        setSearch,
        page,
        setPage,
        limit,
        setLimit,
        sortBy,
        setSortBy,
      }}
    >
      {children}
    </RithmicAccountsContext.Provider>
  );
};

export default RithmicAccountsProvider;
export const useRithmicAccountsContext = (): IRithmicAccountsContextProps =>
  useContext(RithmicAccountsContext);

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  paperContainer: {
    padding: 14,
    backgroundColor: "#f2f2f2",
  },
});

export default useStyles;

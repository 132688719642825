import { makeStyles, styled } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const BaseDatePicker = styled(KeyboardDatePicker)({
  position: "relative",
});

export const useStyles = makeStyles((theme) => ({
  errorMessage: {
    position: "absolute",
    left: 0,
    top: 55,
  },
  errorContainerStandardVariant: {
    top: 47,
  },
  errorContainerSmallSize: {
    top: 38,
  },
  warning: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.warning.main,
    },
  },
}));

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  deletingIcon: {
    color: "#e53935",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteAddedFileIcon: {
    padding: 0,
  },
  acceptedItem: {
    display: "flex",
    marginTop: 5,
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "gray",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  disabled: {
    opacity: 0.4,
  },
});

export default useStyles;

import React from "react";
import * as Yup from "yup";

export interface IAllowedFeature {
  name: string;
  label: string;
  component?: React.FC<never>;
  contextualHelpText?: string;
  group?: string | null;
  isSubOption?: boolean | null;
  subOptionsList?: string[] | null;
}

export const AGENT_NOTE_VALIDATION_SCHEMA = Yup.object().shape({
  note: Yup.string()
    .required("Note is required")
    .test(
      "is-not-empty-spaces",
      "Shouldn't start or end with empty spaces",
      (value = "") => !/^\s|\s$/.test(value)
    )
    .min(5, "Note should be at least 5 characters")
    .max(300, "Note should be at most 300 characters"),
});

export const AGENT_SHIFT_OPTIONS = [
  { value: "american", label: "American", id: 1 },
  { value: "european", label: "European", id: 2 },
  { value: "asian", label: "Asian", id: 3 },
] as const;

export const AGENT_POSITION_OPTIONS = [
  { value: "manager", label: "Manager", id: 1 },
  { value: "agent", label: "Agent", id: 2 },
] as const;

import React from "react";

import AccountsTable from "../AccountsTable";
import LogLoginsTable from "../LogLoginsTable";
import UniqueRegistrationIpTable from "../UniqueRegistrationIpTable";
import PaymentCards from "../PaymentCards";
import useStyles from "../../useStyles";

const E2TAccountsInvestigation: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <AccountsTable />
      </div>
      <hr className={classes.hr} />
      <div className={classes.relatedTablesContainer}>
        <div>
          <LogLoginsTable />
        </div>
        <div>
          <UniqueRegistrationIpTable />
        </div>
        <div>
          <PaymentCards />
        </div>
      </div>
    </div>
  );
};

export default E2TAccountsInvestigation;

import React from "react";
import { Grid } from "@material-ui/core/";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";

import { ACTIVE_OPTIONS, STATUS_OPTIONS } from "../../utils";

import { IAccountGauntlets } from "../../../../../../api/accounts/index.types";
import { APIAccountsGauntlet } from "../../../../../../api";
import { ContainerWithLoader, ConfirmDialog, FormSelect } from "../../../../../../components/ui";
import { ACCOUNTS_API_URLS } from "../../../../../../api/accounts";

type THandleSubmitForm = (
  id: number,
  data: { active: boolean; status: IAccountGauntlets["status"] }
) => Promise<void>;

const GauntletsAccounts = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const { id: accounts_id } = useParams();
  const {
    loading,
    data: { account_gauntlets },
  } = useSelector((state) => state.customer.managementData);

  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const isDisabledByBunReason = !!banned_account?.field_values_before_bun?.account_gauntlets_id;

  const handleSubmitForm: THandleSubmitForm = async (id, { active, status }) => {
    try {
      if (!active) {
        await APIAccountsGauntlet.updateGauntletAccountRequest(id, {
          active,
          status: "canceled",
          accounts_id,
        });
      } else {
        await APIAccountsGauntlet.updateGauntletAccountRequest(id, {
          active,
          status,
          accounts_id,
        });
      }

      await queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.getAccountManagementData, accounts_id],
      });
    } catch (error) {
      console.error("Error updating gauntlet account", error);
    }
  };

  return (
    <div>
      <h4>Gauntlet account</h4>

      <ContainerWithLoader
        noResultsMessage="There isn't active gauntlet account"
        isLoading={loading}
        report={account_gauntlets}
      >
        {account_gauntlets.map((account) => (
          <Formik
            key={account.id}
            onSubmit={(values) => handleSubmitForm(account.id, values)}
            enableReinitialize
            initialValues={{ active: account.active, status: account.status }}
          >
            {({ dirty, handleReset, handleSubmit }) => (
              <Form>
                <Grid container spacing={3}>
                  <ConfirmDialog
                    isOpen={dirty}
                    dialogTitle="Edit Gauntlet account"
                    dialogContentText="Are you sure you want to edit Gauntlet account?"
                    handleClickOk={() => handleSubmit()}
                    handleCancel={() => handleReset()}
                  />
                  <Grid item xs={6}>
                    <Field
                      name="active"
                      label="Active"
                      placeholder="Active"
                      size="small"
                      component={FormSelect}
                      options={ACTIVE_OPTIONS}
                      disabled={isDisabledByBunReason}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="status"
                      label="Status"
                      placeholder="Status"
                      size="small"
                      component={FormSelect}
                      options={STATUS_OPTIONS}
                      disabled={isDisabledByBunReason}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ))}
      </ContainerWithLoader>
    </div>
  );
};

export default GauntletsAccounts;

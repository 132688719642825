import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { Button, Chip, CircularProgress, Grid, TextField } from "@material-ui/core";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { INTERCOM_ROLES, MS_IN_SECOND } from "../../../../constants";
import SearchContainer from "./components/SearchContainer";
import CustomAttributes from "./components/CustomAttributes";
import useStyles from "./useStyles";
import { FormInput, Snackbar, FormSelect } from "../../../../components/ui";

import {
  makeDate,
  isDateKeyValue,
  getChangedValues,
  CUSTOM_ATTRIBUTES_KEY,
  getAutocompleteOptions,
  getIntercomInitialValues,
  getContactValidationSchema,
} from "./utils";
import { APIIntercom } from "../../../../api";
import { IUpdateIntercomContactData } from "../../../../api/intercom/index.types";

import { useIntercomContext, useCustomerProvider } from "../../../../context";
import { getCustomAttributes } from "../../../../context/IntercomProvider/utils";
import { AGENT_ACTIVITY_LOG_API_URLS } from "../../../../api/agentActivityLog";

const EditIntercomValues = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { id } = useParams();

  const { getCustomer, fina } = useCustomerProvider();
  const { contact, setContact, isLoading, getIntercomContact } = useIntercomContext();
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [intercomResultStatusRequest, setIntercomResultStatusRequest] = useState<
    "error" | "success" | ""
  >("");

  const [search, setSearch] = useState<Array<string>>([]);
  const [searchText, setSearchText] = useState("");

  const [responseErrorMessage, setResponseErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      await getIntercomContact(fina.id);
    })();
  }, [fina.id]);

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
    setIntercomResultStatusRequest("");
  };

  const initialValues = getIntercomInitialValues(contact);

  const autocompleteOptions = getAutocompleteOptions(initialValues);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleSubmit = async (formValues: any) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const dataToSend: IUpdateIntercomContactData = { id: fina.id, user_id: id };
    const changedValues: string[] = getChangedValues(formValues, initialValues);

    changedValues.forEach((key) => {
      if (key !== CUSTOM_ATTRIBUTES_KEY) {
        if (key === "signed_up_at" || key === "last_seen_at") {
          dataToSend[key] = makeDate(moment(formValues[key]).unix() * MS_IN_SECOND);
        } else if (key.includes("_balance")) {
          dataToSend[key] = +formValues[key];
        } else {
          dataToSend[key] = formValues[key];
        }
      }
    });

    if (changedValues.includes(CUSTOM_ATTRIBUTES_KEY)) {
      const changedCustomAttributes = getChangedValues(
        formValues[CUSTOM_ATTRIBUTES_KEY],
        initialValues.custom_attributes
      );
      dataToSend[CUSTOM_ATTRIBUTES_KEY] = {};

      changedCustomAttributes.forEach((key) => {
        if (key !== CUSTOM_ATTRIBUTES_KEY) {
          if (isDateKeyValue(key)) {
            dataToSend[CUSTOM_ATTRIBUTES_KEY][key] = makeDate(
              moment(formValues[CUSTOM_ATTRIBUTES_KEY][key]).unix() * 1000
            );
          } else if (key.includes("_balance")) {
            dataToSend[CUSTOM_ATTRIBUTES_KEY][key] = +formValues[CUSTOM_ATTRIBUTES_KEY][key];
          } else {
            dataToSend[CUSTOM_ATTRIBUTES_KEY][key] = formValues[CUSTOM_ATTRIBUTES_KEY][key];
          }
        }
      });
    }

    try {
      const response = await APIIntercom.updateIntercomContactRequest(
        dataToSend,
        contact.external_id
      );

      if (response.data.status === 400) {
        setIntercomResultStatusRequest("error");
        const errors = response.data.data.errors.map(({ message }) => message);
        setResponseErrorMessage(errors.join(", "));
        return;
      }
      await getCustomer();
      const responseUpdated = await APIIntercom.getIntercomContactRequest(fina.id);
      const { custom_attributes } = responseUpdated.data;
      responseUpdated.data.custom_attributes = getCustomAttributes(custom_attributes);
      setContact(responseUpdated.data);
      queryClient.invalidateQueries({
        queryKey: [AGENT_ACTIVITY_LOG_API_URLS.getCustomerLogs, id],
      });

      setIntercomResultStatusRequest("success");
    } catch (error) {
      setIntercomResultStatusRequest("error");
    } finally {
      setIsOpenSnackbar(true);
    }
  };

  const SearchContainerWithProps = ({
    children,
    name,
  }: {
    children: React.ReactElement;
    name: string;
  }) => (
    <SearchContainer
      currentFilters={search}
      name={name}
      fields={autocompleteOptions}
      searchText={searchText}
    >
      {children}
    </SearchContainer>
  );

  return (
    <div>
      <h2>Intercom</h2>

      {isLoading && (
        <div className={classes.preloaderContainer}>
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validateOnChange
          validationSchema={getContactValidationSchema(contact)}
          data-test="formik"
        >
          {({ handleReset, isValid, dirty }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item md={8} xs={8}>
                  <Autocomplete
                    multiple
                    options={autocompleteOptions}
                    freeSolo
                    onChange={(event, newValue) => {
                      setSearchText("");
                      setSearch(newValue);
                    }}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          key={option}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Field Name"
                        placeholder="Field Name"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <SearchContainerWithProps name="role">
                  <Field
                    name="role"
                    label="role"
                    placeholder="role"
                    options={INTERCOM_ROLES}
                    component={FormSelect}
                    contextualHelpText="The role of the contact. Accepted values are user or lead (only if lead already)."
                  />
                </SearchContainerWithProps>

                <SearchContainerWithProps name="external_id">
                  <Field
                    name="external_id"
                    label="External_id"
                    placeholder="External_id"
                    component={FormInput}
                    contextualHelpText="A unique identifier for the contact which is given to Intercom."
                  />
                </SearchContainerWithProps>

                <SearchContainerWithProps name="phone">
                  <Field
                    name="phone"
                    label="Phone"
                    placeholder="Phone"
                    component={FormInput}
                    contextualHelpText="The contacts phone."
                    data-test="phone-field"
                  />
                </SearchContainerWithProps>

                <SearchContainerWithProps name="name">
                  <Field
                    name="name"
                    label="Name"
                    placeholder="Name"
                    component={FormInput}
                    contextualHelpText="The contacts name."
                  />
                </SearchContainerWithProps>

                <SearchContainerWithProps name="email">
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    component={FormInput}
                    contextualHelpText="The contacts email."
                  />
                </SearchContainerWithProps>
              </Grid>

              <h3>E2T Dashboard</h3>

              <CustomAttributes
                contact={contact}
                search={search}
                fields={autocompleteOptions}
                searchText={searchText}
              />

              {dirty && (
                <Grid container spacing={3}>
                  <Grid item md={4} xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginTop: 25 }}
                      disabled={!isValid}
                      data-test="submit-button"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginTop: 25, marginLeft: 20 }}
                      onClick={handleReset}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        type={intercomResultStatusRequest}
        isOpenSnackbar={isOpenSnackbar}
        responseSuccessMessage="Success"
        responseErrorMessage={`Error ${responseErrorMessage}`}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default EditIntercomValues;

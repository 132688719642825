import { ReduxAction } from "../../static-types";
import { TGetLiveAndLiveSimAccountsCountResponse } from "../../api/accounts/index.types";

export type HeliosAdminAction =
  | HeliosAdminSimAndLiveSimAccountsFetchRequest
  | HeliosAdminSimAndLiveSimAccountsFetchSuccess
  | HeliosAdminSimAndLiveSimAccountsFetchFailed;

export interface HeliosAdminSimAndLiveSimAccountsFetchRequest extends ReduxAction {
  type: HeliosAdminActionType.fetchSimAndLiveSimAccountsRequest;
}
export interface HeliosAdminSimAndLiveSimAccountsFetchSuccess extends ReduxAction {
  type: HeliosAdminActionType.fetchSimAndLiveSimAccountsSuccess;
  payload: TGetLiveAndLiveSimAccountsCountResponse;
}
export interface HeliosAdminSimAndLiveSimAccountsFetchFailed extends ReduxAction {
  type: HeliosAdminActionType.fetchSimAndLiveSimAccountsFailed;
}

export enum HeliosAdminActionType {
  fetchSimAndLiveSimAccountsRequest = "heliosAdmin/fetchSimAndLiveSimAccountsRequest",
  fetchSimAndLiveSimAccountsSuccess = "heliosAdmin/fetchSimAndLiveSimAccountsSuccess",
  fetchSimAndLiveSimAccountsFailed = "heliosAdmin/fetchSimAndLiveSimAccountsFailed",
}
export interface IHeliosAdminState {
  simAndLiveSimAccounts: {
    data: {
      liveSimCount: number;
      liveCount: number;
    };
    loading: boolean;
  };
}

import React from "react";

import useGeneralStyles from "../../useGeneralStyles";
import Logs from "./components/Logs";

const RithmicFiles = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  return (
    <div>
      <div className={generalClasses.containerBg}>
        <Logs folderPath="archive" title="Archive" />
        <Logs folderPath="errors" title="Errors" />
      </div>
    </div>
  );
};

export default RithmicFiles;

import produce from "immer";

import {
  ComplianceSearchAccountsAction,
  ComplianceSearchAccountsActionType,
  IComplianceSearchAccountsState,
} from "./index.types";

export const initialState: IComplianceSearchAccountsState = {
  accounts_table: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  logins: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  uniqueReqIp: {
    data: [],
    loading: false,
  },
  paymentCards: {
    data: [],
    loading: false,
  },
  plans: {
    loading: false,
    data: {
      accounts: [],
      plans: [],
    },
  },
  plansStatus: {
    loading: false,
    data: [],
  },
  purchasesHistory: {
    data: [],
    loading: false,
  },
  resetsRebillsNewPurchases: {
    data: [],
    loading: false,
  },
  basicAccountDetailsAndSalesValue: {
    data: [],
    loading: false,
  },
  searchOnNameVariations: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  searchOnPhoneVariations: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  rithmicAccounts: {
    data: {
      accounts: [],
      tradedAccounts: [],
      evaluation_completed_history: [],
      trailing_history: [],
    },
    loading: false,
  },
  watchlistSubmissionToProp: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        timestamp: "",
      },
    },
    loading: false,
  },
  watchlistWithdrawals: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        account_name: "",
      },
    },
    loading: false,
  },
  watchlistFailed: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        failure_date: "",
      },
    },
    loading: false,
  },
  watchlistLiveSimTif: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        submission_timestamp: "",
      },
    },
    loading: false,
  },
  watchlistLiveTif: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        submission_timestamp: "",
      },
    },
    loading: false,
  },
  watchlistAppiusLiveTif: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        timestamp: "",
      },
    },
    loading: false,
  },
  watchlistNotes: {
    data: {
      results: {
        count: 0,
        rows: [],
      },
      updated: {
        measure_timestamp: "",
      },
    },
    loading: false,
  },
  heliosAccountsInvestigation: {
    loading: false,
    data: {
      passes: {
        count: 0,
        rows: [],
      },
      liveSimTif: {
        count: 0,
        rows: [],
      },
      liveTif: {
        count: 0,
        rows: [],
      },
      withdrawals: {
        count: 0,
        rows: [],
      },
      watchlistFailed: {
        count: 0,
        rows: [],
      },
      gm_cappers: {
        count: 0,
        rows: [],
      },
      tcp_steps: {
        count: 0,
        rows: [],
      },
    },
  },
  pnlCounter: {
    data: {
      updated: "",
      pnlData: [],
    },
    loading: false,
  },
  heliosAndAppiusLiveTif: {
    loading: false,
    data: {
      rows: [],
      updated: {
        submission_timestamp: "",
      },
    },
  },
  topLiveSimTakers: {
    loading: false,
    data: {
      results: {
        rows: [],
        count: 0,
      },
      updated: {
        timestamp: "",
      },
    },
  },
  topLiveLosers: {
    loading: false,
    data: {
      results: {
        rows: [],
        count: 0,
      },
      updated: {
        timestamp: "",
      },
    },
  },
  tcpSteps: {
    loading: false,
    data: {
      updated: {
        timestamp: "",
      },
      results: {
        rows: [],
        count: 0,
      },
    },
  },
  gmCappers: {
    loading: false,
    data: {
      updated: {
        timestamp: "",
      },
      results: {
        rows: [],
        count: 0,
      },
    },
  },
  masterBanList: {
    loading: false,
    data: {
      updated: {
        timestamp: "",
      },
      results: {
        rows: [],
        count: 0,
      },
    },
  },
};

const complianceSearchReducer = (
  prevState = initialState,
  action: ComplianceSearchAccountsAction
): IComplianceSearchAccountsState =>
  produce(prevState, (draft: IComplianceSearchAccountsState): IComplianceSearchAccountsState => {
    switch (action.type) {
      //SearchAccounts
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest:
        draft.accounts_table.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess:
        draft.accounts_table.data = action.payload;
        draft.accounts_table.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed:
        draft.accounts_table.loading = false;
        break;
      //Search logins
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest:
        draft.logins.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess:
        draft.logins.data = action.payload;
        draft.logins.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed:
        draft.logins.loading = false;
        break;
      // Search unique req ip
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest:
        draft.uniqueReqIp.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess:
        draft.uniqueReqIp.data = action.payload;
        draft.uniqueReqIp.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed:
        draft.uniqueReqIp.loading = false;
        break;
      // SearchPaymentCards
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest:
        draft.paymentCards.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess:
        draft.paymentCards.data = action.payload;
        draft.paymentCards.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed:
        draft.paymentCards.loading = false;
        break;
      // SearchPlans
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest:
        draft.plans.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess:
        draft.plans.data = action.payload;
        draft.plans.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed:
        draft.plans.loading = false;
        break;
      // SearchPlansStatus
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest:
        draft.plansStatus.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess:
        draft.plansStatus.data = action.payload;
        draft.plansStatus.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed:
        draft.plansStatus.loading = false;
        break;
      // SearchPurchases
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest:
        draft.purchasesHistory.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess:
        draft.purchasesHistory.data = action.payload;
        draft.purchasesHistory.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed:
        draft.purchasesHistory.loading = false;
        break;
      // SearchResetsRebillsNewPurchases
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest:
        draft.resetsRebillsNewPurchases.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess:
        draft.resetsRebillsNewPurchases.data = action.payload;
        draft.resetsRebillsNewPurchases.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed:
        draft.resetsRebillsNewPurchases.loading = false;
        break;
      // BasicAccountDetailsAndSalesValue
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest:
        draft.basicAccountDetailsAndSalesValue.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess:
        draft.basicAccountDetailsAndSalesValue.data = action.payload;
        draft.basicAccountDetailsAndSalesValue.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed:
        draft.basicAccountDetailsAndSalesValue.loading = false;
        break;
      // AccountSearchOnNameVariations
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest:
        draft.searchOnNameVariations.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess:
        draft.searchOnNameVariations.data = action.payload;
        draft.searchOnNameVariations.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed:
        draft.searchOnNameVariations.loading = false;
        break;
      // AllAccountSearchByPhone
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest:
        draft.searchOnPhoneVariations.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess:
        draft.searchOnPhoneVariations.data = action.payload;
        draft.searchOnPhoneVariations.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed:
        draft.searchOnPhoneVariations.loading = false;
        break;
      // RithmicAccounts
      case ComplianceSearchAccountsActionType.fetchRithmicAccountsRequest:
        draft.rithmicAccounts.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchRithmicAccountsSuccess:
        draft.rithmicAccounts.data = action.payload;
        draft.rithmicAccounts.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchRithmicAccountsFailed:
        draft.rithmicAccounts.loading = false;
        break;
      // WatchlistPasses
      case ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropRequest:
        draft.watchlistSubmissionToProp.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropSuccess:
        draft.watchlistSubmissionToProp.data = action.payload;
        draft.watchlistSubmissionToProp.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropFailed:
        draft.watchlistSubmissionToProp.loading = false;
        break;
      // WatchlistWithdrawals
      case ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsRequest:
        draft.watchlistWithdrawals.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsSuccess:
        draft.watchlistWithdrawals.data = action.payload;
        draft.watchlistWithdrawals.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsFailed:
        draft.watchlistWithdrawals.loading = false;
        break;
      // WatchlistFailed
      case ComplianceSearchAccountsActionType.fetchWatchlistFailedRequest:
        draft.watchlistFailed.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistFailedSuccess:
        draft.watchlistFailed.data = action.payload;
        draft.watchlistFailed.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistFailedFailed:
        draft.watchlistFailed.loading = false;
        break;
      // WatchlistLiveSimTif
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifRequest:
        draft.watchlistLiveSimTif.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifSuccess:
        draft.watchlistLiveSimTif.data = action.payload;
        draft.watchlistLiveSimTif.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifFailed:
        draft.watchlistLiveSimTif.loading = false;
        break;
      // WatchlistLiveTif
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveTifRequest:
        draft.watchlistLiveTif.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveTifSuccess:
        draft.watchlistLiveTif.data = action.payload;
        draft.watchlistLiveTif.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistLiveTifFailed:
        draft.watchlistLiveTif.loading = false;
        break;
      // WatchlistAppiusLiveTif
      case ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifRequest:
        draft.watchlistAppiusLiveTif.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifSuccess:
        draft.watchlistAppiusLiveTif.data = action.payload;
        draft.watchlistAppiusLiveTif.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifFailed:
        draft.watchlistAppiusLiveTif.loading = false;
        break;
      // WatchlistNotes
      case ComplianceSearchAccountsActionType.fetchWatchlistNotesRequest:
        draft.watchlistNotes.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistNotesSuccess:
        draft.watchlistNotes.data = action.payload;
        draft.watchlistNotes.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistNotesFailed:
        draft.watchlistNotes.loading = false;
        break;
      // HeliosAccountsInvestigation
      case ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationRequest:
        draft.heliosAccountsInvestigation.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationSuccess:
        draft.heliosAccountsInvestigation.data = action.payload;
        draft.heliosAccountsInvestigation.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationFailed:
        draft.heliosAccountsInvestigation.loading = false;
        break;
      // PnLCounter
      case ComplianceSearchAccountsActionType.fetchPnLCounterRequest:
        draft.pnlCounter.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchPnLCounterSuccess:
        draft.pnlCounter.data = action.payload;
        draft.pnlCounter.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchPnLCounterFailed:
        draft.pnlCounter.loading = false;
        break;
      // HeliosAndAppiusLiveTif
      case ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifRequest:
        draft.heliosAndAppiusLiveTif.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifSuccess:
        draft.heliosAndAppiusLiveTif.data = action.payload;
        draft.heliosAndAppiusLiveTif.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifFailed:
        draft.heliosAndAppiusLiveTif.loading = false;
        break;
      // TopLiveLosersAndLiveSimTakers
      case ComplianceSearchAccountsActionType.fetchLiveSimTakersRequest:
        draft.topLiveSimTakers.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchLiveSimTakersSuccess:
        draft.topLiveSimTakers.data = action.payload;
        draft.topLiveSimTakers.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchLiveSimTakersFailed:
        draft.topLiveSimTakers.loading = false;
        break;
      // TopLiveLosers
      case ComplianceSearchAccountsActionType.fetchTopLiveLosersRequest:
        draft.topLiveLosers.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchTopLiveLosersSuccess:
        draft.topLiveLosers.data = action.payload;
        draft.topLiveLosers.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchTopLiveLosersFailed:
        draft.topLiveLosers.loading = false;
        break;
      // TCPSteps
      case ComplianceSearchAccountsActionType.fetchTCPStepsRequest:
        draft.tcpSteps.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchTCPStepsSuccess:
        draft.tcpSteps.data = action.payload;
        draft.tcpSteps.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchTCPStepsFailed:
        draft.tcpSteps.loading = false;
        break;
      // GMCappers
      case ComplianceSearchAccountsActionType.fetchGMCappersRequest:
        draft.gmCappers.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchGMCappersSuccess:
        draft.gmCappers.data = action.payload;
        draft.gmCappers.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchGMCappersFailed:
        draft.gmCappers.loading = false;
        break;
      // MasterBanList
      case ComplianceSearchAccountsActionType.fetchMasterBanListRequest:
        draft.masterBanList.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchMasterBanListSuccess:
        draft.masterBanList.data = action.payload;
        draft.masterBanList.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchMasterBanListFailed:
        draft.masterBanList.loading = false;
        break;
    }
    return draft;
  });

export default complianceSearchReducer;

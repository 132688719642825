import { IRithmicView } from "../../../../api/evaluations/index.types";

type IGetIsShowPlanDivider = (report: IRithmicView[], index: number, plans_key: string) => boolean;
export const getIsShowPlanDivider: IGetIsShowPlanDivider = (report, index, plans_key) => {
  const nextPlan = report[index + 1]?.plans_key;
  const prevPlan = report[index - 1]?.plans_key;

  const isPrevPlanNotEqualNextPlan = plans_key !== nextPlan;
  const isPrevPlanNotEqualCurrentPlan = plans_key !== prevPlan;

  const isShowPlanDivider =
    isPrevPlanNotEqualCurrentPlan ||
    (isPrevPlanNotEqualNextPlan && isPrevPlanNotEqualCurrentPlan) ||
    (!nextPlan && isPrevPlanNotEqualCurrentPlan) ||
    (!prevPlan && isPrevPlanNotEqualNextPlan);

  return isShowPlanDivider;
};

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import cx from "classnames";

import { DiscountCodesActions } from "../../../../reducers/discountCodesReducer";
import { useDiscountsContext } from "../../../../context";

import useStyles from "../TableBody/useStyles";
import { APIDiscounts } from "../../../../api";
import { DiscountCodeState, DiscountCodesData } from "../../../../api/discounts/index.types";

interface IProps {
  isAvailableActions: boolean;
  discount: DiscountCodeState;
}
interface IActions {
  id: number;
}

const CodeActivationActionStatus = ({
  isAvailableActions,
  discount,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const { sortBy, search, page, limit } = useDiscountsContext();

  const changeActivationStatus = async (id: number, accepted: boolean) => {
    setIsUpdating(true);
    try {
      const data = {
        code_id: id,
        accepted,
        limit,
        page,
        contains: search,
        sortBy,
        inactive: true,
        editableValueKeyName: "accepted",
      };

      const response = await APIDiscounts.discountCodeActivateRequest(data);
      const responseData: DiscountCodesData = response.data;

      dispatch(DiscountCodesActions.fetchSuccess(responseData));
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const Actions = ({ id }: IActions) =>
    isAvailableActions ? (
      <>
        <Button
          disabled={isUpdating}
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          color="primary"
          onClick={() => changeActivationStatus(id, true)}
          data-test="accept-button-code"
        >
          Accept
        </Button>

        <Button
          disabled={isUpdating}
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          color="secondary"
          onClick={() => changeActivationStatus(id, false)}
        >
          Decline
        </Button>
      </>
    ) : (
      <>Waiting for approval</>
    );

  const StatusIc = () =>
    discount.accepted ? (
      <b className={classes.active}>✔</b>
    ) : (
      <b className={classes.onTimeInactive}>✘</b>
    );
  const ContentStatus = () =>
    discount.accepted === null ? <Actions id={discount.id} /> : <StatusIc />;

  return (
    <td className={cx(classes.alignCenter)}>
      <ContentStatus />
    </td>
  );
};

export default CodeActivationActionStatus;

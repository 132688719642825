import React from "react";
import { IRithmicPastPosition } from "../../../../../api/rithmic/index.types";

interface IProps {
  data: IRithmicPastPosition[];
  activeColumns: string[];
}
const TableBody = ({ data, activeColumns }: IProps): React.ReactElement => (
  <tbody>
    {data.map((row) => {
      const { FillId, Pnl, NetChange } = row;
      return (
        <tr key={`${FillId}_${Pnl}_${NetChange}`}>
          {activeColumns.map((columnName) => (
            // @ts-ignore
            <td key={columnName}>{row[columnName]}</td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

export default TableBody;

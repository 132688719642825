import { SuspiciousCustomersAction, SuspiciousCustomersActionType } from "./index.types";
import { IGetSuspiciousNotesResponse } from "../../api/accounts/index.types";

export const SuspiciousCustomersActions = {
  fetchSuspiciousCustomersRequest: (): SuspiciousCustomersAction => ({
    type: SuspiciousCustomersActionType.suspiciousCustomersFetchRequest,
  }),
  fetchSuspiciousCustomersSuccess: (
    payload: IGetSuspiciousNotesResponse["data"]
  ): SuspiciousCustomersAction => ({
    type: SuspiciousCustomersActionType.suspiciousCustomersFetchSuccess,
    payload,
  }),
  fetchSuspiciousCustomersFailed: (): SuspiciousCustomersAction => ({
    type: SuspiciousCustomersActionType.suspiciousCustomersFetchFailed,
  }),
};

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";

import useStyles from "./useStyles";
import { Plan } from "../../../../Customer";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { UpdatePlanConfirmWindow } from "../../../index";
import { getCurrentPlanPrice, PLAN_VALIDATION_SCHEMA } from "./utils";
import { ICustomerPlan } from "../../../../../../api/accounts/index.types";

const PlansTable = (): React.ReactElement => {
  const classes = useStyles();
  const { plans } = useSelector((state) => state.customer.plans.data);
  const [editingPlan, setEditingPlan] = useState<Plan>();
  const [editingPlanSaveStatus, setEditingPlanSaveStatus] = useState("");

  const {
    data: { billing_history_by_plans },
  } = useSelector((state) => state.customer.plans);
  const getPlanPriceUsd = (plan: ICustomerPlan, index: number) => {
    return { price_usd: billing_history_by_plans[index]?.price_usd || plan.price_usd };
  };

  return (
    <div className={classes.tableBorder}>
      {editingPlan && (
        <UpdatePlanConfirmWindow
          editingPlan={editingPlan}
          editingPlanSaveStatus={editingPlanSaveStatus}
          setEditingPlan={setEditingPlan}
          setEditingPlanSaveStatus={setEditingPlanSaveStatus}
        />
      )}

      <Formik
        validationSchema={PLAN_VALIDATION_SCHEMA}
        initialValues={{
          plans: plans.map((p, index) => ({
            ...p,
            price_usd: getCurrentPlanPrice(p, getPlanPriceUsd(p, index)),
          })),
        }}
        enableReinitialize
        onSubmit={() => console.log()}
      >
        {() => {
          return (
            <Form>
              <table>
                <TableHead />
                <TableBody setEditingPlanData={setEditingPlan} />
              </table>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PlansTable;

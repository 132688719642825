import moment from "moment-timezone";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";

export const COLUMNS: Array<IColum> = [
  {
    by: "id",
    title: "Account ID",
    path: "id",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "email",
  },
];

export const TRADED_ACCOUNTS_COLUMNS: Array<IColum> = [
  {
    by: "",
    title: "Account",
    path: "account",
  },
  {
    by: "",
    title: "First activity",
    path: "trailing_history_start",
    getCellValue: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
    copyToClipBoardCallBack: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
  },
  {
    by: "",
    title: "Last activity",
    path: "trailing_history_end",
    getCellValue: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
    copyToClipBoardCallBack: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
  },
  {
    by: "",
    title: "Start",
    path: "start",
  },
  {
    by: "",
    title: "Canceled",
    path: "canceled",
  },
  {
    by: "",
    title: "Passed",
    path: "passed",
    getCellValue: (date) => (date ? moment(date).format(DATE_FORMAT_hhmmssAzz) : ""),
    copyToClipBoardCallBack: (date) => (date ? moment(date).format(DATE_FORMAT_hhmmssAzz) : ""),
  },
];

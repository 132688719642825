export const ACTIVE_OPTIONS = [
  {
    id: 0,
    label: "No",
    value: false,
  },
  {
    id: 0,
    label: "Yes",
    value: true,
  },
];
export const STATUS_OPTIONS = [
  {
    id: 0,
    label: "Active",
    value: "active",
  },
  {
    id: 1,
    label: "Canceled",
    value: "canceled",
  },
];

export const ACCOUNT_STATUS_OPTIONS = [
  {
    value: 0,
    label: "0",
    id: 0,
  },
  {
    value: 1,
    label: "1",
    id: 1,
  },
  {
    value: 2,
    label: "2",
    id: 2,
  },
];

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "center",
  },
  buttonRoot: {
    height: 36,
  },
  tableContainer: {
    overflowX: "scroll",
  },
});

export default useStyles;

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { useCustomerProvider } from "../../../../context";
import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import usePagination from "../../../../hooks/usePagination";
import { CollapseWithControls, PaginationWithLimit } from "../../../../components/ui";
import useStyles from "./useStyles";

const ComplianceNotes = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination("4");

  const { customer } = useCustomerProvider();
  const { data } = useSelector((state) => state.customer.complianceNotes);

  useQuery({
    enabled: !!customer.email,
    retry: false,
    queryKey: [COMPLIANCE_API_URLS.getNotes, customer.email, pagination.limit, pagination.page],
    async queryFn({ signal }) {
      dispatch(CustomerActions.fetchCustomerComplianceNotesRequest());
      const query = new URLSearchParams();
      query.set("email", customer.email);
      query.set("limit", pagination.limit);
      query.set("page", pagination.page.toString());

      const response = await ComplianceAPI.getCustomerComplianceNotesRequest(
        query.toString(),
        signal
      );
      pagination.setCount(response.data.count);
      dispatch(CustomerActions.fetchCustomerComplianceNotesSuccess(response.data));
      return response;
    },
    onError: (error) => {
      dispatch(CustomerActions.fetchCustomerComplianceNotesFailed());
      console.error("Error handle saveNote", error);
    },
  });

  return (
    <div className={classes.container}>
      <CollapseWithControls
        buttonText={`Compliance Notes: ${data.count}`}
        buttonProps={{
          disabled: !data.count,
          ...(data.count && { color: "secondary" }),
        }}
      >
        <div className={classes.tableBorder}>
          <table className={classes.table}>
            <tbody>
              {data.rows.map((r) => (
                <tr key={r.id} className={cx({ [classes.borderBottom]: data.rows.length > 1 })}>
                  <td>{r.measure_taken}</td>
                  <td className={classes.timestamp}>{r.measure_timestamp}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <PaginationWithLimit
            count={pagination.count}
            limit={pagination.limit.toString()}
            page={pagination.page}
            setPageState={pagination.setPage}
            setLimit={pagination.setLimit}
          />
        </div>
      </CollapseWithControls>
    </div>
  );
};

export default ComplianceNotes;

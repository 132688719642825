/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { IFundedTraderWidthAccountData } from "../api/funds/index.types";

export type FundedTradersAction =
  | FundedTradersFetchRequest
  | FundedTradersFetchSuccess
  | FundedTradersFetchFailed;

export enum FundedTradersActionType {
  fundedTradersFetchRequest = "fundedTraders/FetchRequest",
  fundedTradersFetchSuccess = "fundedTraders/FetchSuccess",
  fundedTradersFetchFailed = "fundedTraders/FetchFailed",
}

interface FundedTradersFetchRequest extends ReduxAction {
  type: FundedTradersActionType.fundedTradersFetchRequest;
}
interface FundedTradersFetchSuccess extends ReduxAction {
  type: FundedTradersActionType.fundedTradersFetchSuccess;
  payload: {
    funded_traders: IFundedTraderWidthAccountData[];
    count: number;
  };
}
interface FundedTradersFetchFailed extends ReduxAction {
  type: FundedTradersActionType.fundedTradersFetchFailed;
}

interface IFundedTraders {
  count: number;
  funded_traders: IFundedTraderWidthAccountData[];
}

export const FundedTradersActions = {
  fetchFailedPaymentsRequest: (): FundedTradersAction => ({
    type: FundedTradersActionType.fundedTradersFetchRequest,
  }),
  fetchFailedPaymentsSuccess: ({ count, funded_traders }: IFundedTraders): FundedTradersAction => ({
    type: FundedTradersActionType.fundedTradersFetchSuccess,
    payload: {
      count,
      funded_traders,
    },
  }),
  fetchFailedPaymentsFailed: (): FundedTradersAction => ({
    type: FundedTradersActionType.fundedTradersFetchFailed,
  }),
};

export interface SortParams {
  by: "id" | "name" | "email" | "processing_status";
  order: boolean;
}

export interface IFundedTradersState {
  data: {
    funded_traders: IFundedTraderWidthAccountData[];
    count: number;
  };
  isLoading: boolean;
}
const initialState: IFundedTradersState = {
  data: {
    funded_traders: [],
    count: 0,
  },
  isLoading: false,
};

const fundedTradersReducer = (
  prevState = initialState,
  action: FundedTradersAction
): IFundedTradersState =>
  produce(prevState, (draft: IFundedTradersState) => {
    switch (action.type) {
      case FundedTradersActionType.fundedTradersFetchRequest:
        draft.isLoading = true;
        break;
      case FundedTradersActionType.fundedTradersFetchSuccess:
        draft.data.funded_traders = action.payload.funded_traders;
        draft.data.count = action.payload.count;
        draft.isLoading = false;
        break;
      case FundedTradersActionType.fundedTradersFetchFailed:
        draft.isLoading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default fundedTradersReducer;

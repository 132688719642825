import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  copyToClipboardButton: {
    fontWeight: 800,
    color: "rgb(192, 192, 192)",
    textTransform: "none",
  },
  bridgerLogo: {
    width: 35,
  },
});

export default useStyles;

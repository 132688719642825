import axios, { AxiosInstance } from "axios";
import { HOST } from "../constants";

const apiClient: AxiosInstance = axios.create({
  baseURL: HOST,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("Token") || ""}`,
  },
});

export default apiClient;

import { ICustomerPlan } from "../../../../api/accounts/index.types";

type TGetAvailableFreeResets = (plans: ICustomerPlan[]) => number;
export const getAvailableFreeResets: TGetAvailableFreeResets = (plans: ICustomerPlan[]) => {
  return plans.reduce((acc, curValue) => {
    if (curValue.status === "active") {
      return acc + curValue.free_resets;
    }
    return acc;
  }, 0);
};

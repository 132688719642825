import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import useStyles from "./useStyles";
import TableBody from "./TableBody/TableBody";
import TableHeader from "./TableHeader/TableHeader";
import { DATE_FORMAT } from "../../../../constants";
import {
  ContainerWithLoader,
  FilterAutocomplete,
  PaginationWithLimit,
} from "../../../../components/ui";
import { DiscountCodesLogsActions } from "../../../../reducers/discountCodesLogsReducer";
import { APIDiscounts } from "../../../../api";
import { useDiscountCodesLogsContext } from "../../../../context";
import { DISCOUNT_CODES_LOGS_FILTERS } from "../../constants";

const ChangeLogs = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [key, setKey] = useState(1);

  const { data, loading } = useSelector((state) => state.discountCodesLogs);
  const { rows, count } = data;

  const { page, setPage, limit, setLimit, sortBy, search, setSearch } =
    useDiscountCodesLogsContext();

  const handleChangeExpires = (date: MaterialUiPickersDate) => {
    setSearch({
      ...search,
      log_time: date ? date.format("YYYY-MM-DD") : "",
    });
  };

  const handleSetSearchParams = (name: string) => {
    setSearch({
      ...search,
      admin: name,
      new_field_value: "created",
    });
    setKey(key + 1);
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(DiscountCodesLogsActions.fetchRequest());
        const { data } = await APIDiscounts.getDiscountCodesLogChanges({
          limit,
          page,
          sortBy,
          contains: search,
        });
        dispatch(DiscountCodesLogsActions.fetchSuccess(data.logs_data));
      } catch (error) {
        dispatch(DiscountCodesLogsActions.fetchFailed());
      }
    })();
  }, [limit, page, sortBy, search]);

  return (
    <div className="discounts">
      <div className={classes.searchContainer}>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={DISCOUNT_CODES_LOGS_FILTERS}
          clearValuesFilter={key}
        />

        <KeyboardDatePicker
          inputVariant="outlined"
          label="Date"
          name="log_time"
          clearable
          value={search.log_time || null}
          placeholder="2018/10/10"
          onChange={handleChangeExpires}
          format={DATE_FORMAT}
          autoOk
        />
      </div>

      <p className={classes.totalRecords}>
        Logs found:<b>{count}</b>
      </p>

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={rows} noResultsMessage="There are no logs">
          <table className={classes.table}>
            <TableHeader />
            <TableBody handleSetSearchParams={handleSetSearchParams} />
          </table>
        </ContainerWithLoader>
      </div>

      <div className={classes.bottomControls}>
        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPageState={setPage}
        />
      </div>
    </div>
  );
};

export default ChangeLogs;

import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import useStyles from "./useStyles";

import { IFundedTradersStatus } from "../../../../api/service/index.types";
import AccountsProcessingAndInvoiceStatusSelect from "../../../FundedTrader/components/AccountsProcessingAndInvoiceStatusSelect";
import { IDialogConfirmProps } from "../FundedTradersTable/TableBody";
import { EVALUATION_TYPE_OPTIONS } from "../../../../constants";

interface IProps {
  setDialogConfirmProps: (v: IDialogConfirmProps) => void;
  funded_trader_status: IFundedTradersStatus;
  accounts_id: number;
  evaluation_type: null | string;
  is_forced_to_live: boolean;
}

const FundedTraderStatus: React.FC<IProps> = ({
  setDialogConfirmProps,
  accounts_id,
  funded_trader_status,
  evaluation_type,
  is_forced_to_live,
}) => {
  const classes = useStyles();
  const {
    data: { fundedTradersStatusesList },
  } = useSelector((state) => state.fundedTradersStatusesList);

  const handleChangeStatus = async (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    _: number,
    accounts_id: number
  ) => {
    if (e.target.name === "evaluation_type") {
      setDialogConfirmProps({
        isOpen: true,
        dialogTitle: "Change evaluation type",
        dialogContentText: "Are you sure you want to change evaluation type?",
        id: -1,
        accounts_id,
        name: e.target.name as string,
        value: "",
        evaluation_type: e.target.value as string,
        isEditInitialProcStatusAndInvoiceStatus: false,
      });
      return;
    }
    setDialogConfirmProps({
      isOpen: true,
      dialogTitle: "Change funded trader status",
      dialogContentText: "Are you sure you want to change funded trader status?",
      id: -1,
      accounts_id,
      name: "",
      value: "",
      status_id: e.target.value as number,
      isEditInitialProcStatusAndInvoiceStatus: false,
    });
  };

  let bgClass = "";

  const status = fundedTradersStatusesList.find((s) => s.id === funded_trader_status.id);
  if (status) {
    bgClass = classes[status.status as keyof typeof classes];
  }

  if (is_forced_to_live) {
    bgClass = classes["forcedToLive"];
  }

  return (
    <>
      <td>
        <AccountsProcessingAndInvoiceStatusSelect
          useSmallSize
          options={EVALUATION_TYPE_OPTIONS}
          name="evaluation_type"
          value={evaluation_type || ""}
          id={-1}
          accounts_id={accounts_id}
          handleChange={handleChangeStatus}
        />
      </td>
      <td>
        <AccountsProcessingAndInvoiceStatusSelect
          useSmallSize
          bgStylesClass={cx(classes.bgContainer, bgClass)}
          options={fundedTradersStatusesList.map(({ id, status_name }) => ({
            value: id,
            label: status_name,
          }))}
          name="funded_trader_status"
          value={funded_trader_status.id}
          handleChange={handleChangeStatus}
          accounts_id={accounts_id}
          id={-1}
        />
      </td>
    </>
  );
};

export default FundedTraderStatus;

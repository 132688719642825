import React from "react";

const Exit = ({
  w,
  h,
  m,
}: {
  w?: string | number;
  h?: string | number;
  m?: string | number;
}): React.ReactElement => (
  <svg
    height={h || w || "300px"}
    width={w || "300px"}
    style={{ marginBottom: m || 0, marginRight: 10, marginLeft: 2 }}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 31 30.034"
  >
    <g>
      <path d="M14.123,2.759L0.88,0.101c-0.229-0.045-0.44,0.014-0.62,0.161C0.079,0.409,0,0.629,0,0.861v25.407   c0,0.578,0.407,1.075,0.975,1.188l12.768,2.554c0.356,0.071,0.629-0.021,0.91-0.252S15,29.185,15,28.821V3.948   C15,3.37,14.689,2.872,14.123,2.759z M12.637,17.873c-0.548,0-0.992-0.444-0.992-0.991c0-0.549,0.444-0.992,0.992-0.992   s0.992,0.443,0.992,0.992C13.629,17.429,13.185,17.873,12.637,17.873z" />
      <path d="M21,13h2V2c0-1.104-0.896-2-2-2H8l8,2h5V13z" />
      <path d="M21,25h-4v2h4c1.104,0,2-0.896,2-2v-4h-2V25z" />
      <path d="M31,17l-6-5v3h-6c-0.553,0-1,0.447-1,1v2c0,0.553,0.447,1,1,1h6v3L31,17z" />
    </g>
  </svg>
);

export default Exit;

import httpClient from "../apiClient";
import {
  TGetLastLoginsRequest,
  TGetLoginsListRequest,
  TGetSharedIpsRequest,
  TGetSharedRegIpsRequest,
} from "./index.types";

export const LOGINS_API_URLS = {
  logins: (id: string) => `logins/${id}`,
  loginsCsv: (id: string) => `loginsCsv/${id}`,
  sharedIps: "/logins/shared-ip",
  sharedRegIp: "/logins/shared-reg-ip",
} as const;

export class LoginsAPI {
  static getLastLoginsRequest: TGetLastLoginsRequest = (id, data) =>
    httpClient.post(LOGINS_API_URLS.logins(id), data);
  static getLoginsListRequest: TGetLoginsListRequest = (id, data, signal, getCsv) =>
    httpClient.post(getCsv ? LOGINS_API_URLS.loginsCsv(id) : LOGINS_API_URLS.logins(id), data, {
      signal,
    });
  static getSharedIpsRequest: TGetSharedIpsRequest = (ip, body) =>
    httpClient.post(`${LOGINS_API_URLS.sharedIps}/${ip}`, body);
  static getSharedRegIpsRequest: TGetSharedRegIpsRequest = (ip, accounts_id, body) =>
    httpClient.post(`${LOGINS_API_URLS.sharedRegIp}/${ip}/${accounts_id}`, body);
}

import React from "react";
import { Accordion, Typography, AccordionDetails, styled } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";

import useStyles from "./useStyles";
import Management from "../Management";
import AgentNotes from "../AgentNotes";
import { useAdminProviderDataContext } from "../../../../context";

const StyledAccordion = styled(Accordion)({
  backgroundColor: "#f2f2f2",
});

const SubAgentDataAccordion = (): React.ReactElement => {
  const classes = useStyles();
  const agent = useSelector((state) => state.agent);

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  return (
    <div>
      {allowed_features.agent_notes_change_status_or_remove && (
        <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title}>
              Notes: {agent.data.admin_accounts_notes.active_notes}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AgentNotes />
          </AccordionDetails>
        </StyledAccordion>
      )}

      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title}>Management</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Management />
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default SubAgentDataAccordion;

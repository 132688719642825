import React from "react";
import * as Yup from "yup";
import _ from "lodash";

import { TAllowedFeaturesList } from "../../constants";
import { IPoliciesOptions, IPresetsOptions } from "../../api/service/index.types";

export const AGENT_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email(({ originalValue }) => `"${originalValue}" is invalid email `)
    .required("Email is required"),
  password: Yup.string(),
  passwordConfirmation: Yup.string().when("password", {
    is: (value: string) => !!value,
    then: Yup.string()
      .required("Password confirm is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  }),
});
export const getButtonColor = (status: string): string => {
  if (status === "Success!") {
    return "green";
  }
  if (status === "Error!") {
    return "red";
  }
  return "";
};

export const handleIsSelectAll = (
  selectedOptions: TAllowedFeaturesList,
  policiesOptions: IPoliciesOptions[] = [],
  setIsSelectedAll: (a: boolean) => void
): void => {
  setIsSelectedAll(policiesOptions.every((o) => selectedOptions[o.name]));
};

export const handleSelectAll = <TIAgent,>(
  setValues: (values: React.SetStateAction<TIAgent>, shouldValidate?: boolean) => void,
  values: TIAgent,
  setIsSelectedAll: (a: boolean) => void,
  isSelectedAll: boolean,
  policiesOptions: IPoliciesOptions[] = []
): void => {
  const newValues = {} as TAllowedFeaturesList;
  if (!isSelectedAll) {
    policiesOptions.forEach((o) => {
      newValues[o.name] = true;
    });
  }
  setValues({ ...values, allowed_features: { ...newValues } });
  setIsSelectedAll(!isSelectedAll);
};

export const getAgentPreset = (
  selectedOptions: Record<string, boolean>,
  presets: IPresetsOptions[] = [],
  policiesOptions: IPoliciesOptions[] = []
): IPresetsOptions["name"] => {
  const optionsByPreset: Record<
    IPresetsOptions["name_preset"],
    Record<IPoliciesOptions["name"], boolean>
  > = {};

  presets.forEach((preset) => {
    if (!optionsByPreset[preset.name]) {
      optionsByPreset[preset.name] = {};
    }

    policiesOptions.forEach((option) => {
      // @ts-ignore
      if (option[preset.name]) {
        optionsByPreset[preset.name][option.name] = true;
      }
    });
  });
  let activePreset = "custom";

  const enabledOptions: Record<string, boolean> = {};
  Object.keys(selectedOptions).forEach((option) => {
    if (selectedOptions[option]) {
      enabledOptions[option] = true;
    }
  });
  Object.keys(optionsByPreset).forEach((preset) => {
    const presetProp = optionsByPreset[preset as keyof string];
    if (_.isEqual(enabledOptions, presetProp)) {
      activePreset = preset as string;
    }
  });
  return activePreset;
};

const utils = {
  AGENT_SCHEMA,
  getButtonColor,
  handleIsSelectAll,
  handleSelectAll,
  getAgentPreset,
};
export default utils;

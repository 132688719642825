import produce from "immer";

import {
  FundedTradersStatusesListAction,
  FundedTradersStatusesListActionType,
  IFundedTradersStatusesListState,
} from "./index.types";

export const initialState: IFundedTradersStatusesListState = {
  data: {
    fundedTradersStatusesList: [],
  },
  loading: true,
};

const fundedTradersStatusesListReducer = (
  prevState = initialState,
  action: FundedTradersStatusesListAction
): IFundedTradersStatusesListState =>
  produce(prevState, (draft: IFundedTradersStatusesListState): IFundedTradersStatusesListState => {
    switch (action.type) {
      case FundedTradersStatusesListActionType.fetchFundedTradersStatusesListRequest:
        draft.loading = true;
        break;
      case FundedTradersStatusesListActionType.fetchFundedTradersStatusesListSuccess:
        draft.data.fundedTradersStatusesList = action.payload;
        draft.loading = false;
        break;
      case FundedTradersStatusesListActionType.fetchFundedTradersStatusesListFailed:
        draft.loading = false;
        break;
    }
    return draft;
  });

export default fundedTradersStatusesListReducer;

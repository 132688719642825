import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 7,
  },
  noteInput: {
    marginRight: 7,
  },
});
export default useStyles;

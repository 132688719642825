import { makeStyles } from "@material-ui/core";
import { STATUS_COLORS_OPACITY } from "../../../constants";

const useStyles = makeStyles({
  formControl: {
    marginBottom: 5,
  },
  label: {
    backgroundColor: "#f2f2f2",
  },
  scrollableContainer: {
    overflow: "scroll",
  },
  tittleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paymentIcContainer: {
    textAlign: "center",
  },
  paymentIc: {
    width: "20px",
    display: "inline-block",
  },
  failedPayment: {
    backgroundColor: "#ffff0000",
    borderBottom: "1px solid #000",
    borderTop: "1px solid #000",
    "& td": {
      backgroundColor: "#ffff003b !important",
    },
  },
  ownCharge: {
    backgroundColor: STATUS_COLORS_OPACITY.lightGreen,
  },
  sumContainer: {
    display: "flex",
    alignItems: "center",
  },
  refundPayment: {
    backgroundColor: "#ffff0000",
    borderBottom: "1px solid #000",
    borderTop: "1px solid #000",
    "& td": {
      backgroundColor: STATUS_COLORS_OPACITY.lightRed,
    },
  },
  preWhiteSpace: {
    whiteSpace: "pre",
  },
});

export default useStyles;

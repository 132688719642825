import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  expires: {
    marginBottom: 10,
  },
  expirationDurationContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: 3,
    padding: "0 5px 0 5px",
  },
});

export default useStyles;

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";

import TableBody from "./TableBody";
import {
  ContainerWithLoader,
  SortableTableHeader,
  PaginationWithLimit,
  TableColumnsView,
} from "../../../../components/ui";
import GenerateGauntletReportDialog from "./GenerateGauntletReportDialog";

import { useAdminProviderDataContext, useIntercomContext } from "../../../../context";
import { APIRithmic } from "../../../../api";
import { IRithmicData } from "../../../../api/rithmic/index.types";
import useGeneralStyles from "../../../../useGeneralStyles";
import useClasses from "./useStyles";

import usePagination from "../../../../hooks/usePagination";

export const DEFAULT_COMMISSION_PRICE = 2.22;
export const MOCK_LIST_COMMISSIONS = {
  MYM: 0.83,
  MNQ: 0.83,
  M2K: 0.83,
  MES: 0.83,
};

interface IColumns {
  text: string;
  name: string;
}
const Trades = (): React.ReactElement | null => {
  const classes = useClasses();
  const generalClasses = useGeneralStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const { count, setCount, limit, setLimit, page, setPage } = usePagination();
  const { gauntletAccountName } = useIntercomContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    by: "Trade Date",
    order: false,
  });

  const [rithmicData, setRithmicData] = useState<IRithmicData[]>([]);
  const [activeColumns, setActiveColumns] = useState<string[]>([
    "Server Process Date",
    "Exchange",
    "Buy/Sell Indicator",
    "Quantity",
    "Orig Price Type",
    "Strike Price",
    "Limit Price",
    "Stop Price",
    "Fill Price",
  ]);
  const [tableColumns, setTableColumns] = useState<IColumns[]>([]);

  const handleShowExternalColumns = (value: string[]) => setActiveColumns(value);
  const handleOpenDialog = () => setDialogOpen(true);

  const fetchRithmicData = async (isExportCsv: boolean) => {
    const isGetAll = false;
    const data = { page, limit, sortBy, isGetAll };
    if (isExportCsv) {
      data.isGetAll = true;
    }
    return APIRithmic.getRithmicDataRequest(gauntletAccountName, data);
  };

  useEffect(() => {
    if (gauntletAccountName) {
      (async () => {
        try {
          setLoading(true);
          const response = await fetchRithmicData(false);
          const columns = response.data.columns.map((key) => ({ text: key, name: key }));
          columns.shift();
          setTableColumns(columns);
          const { rows } = response.data.rithmicData;
          setRithmicData(rows);
          setCount(response.data.rithmicData.count);
        } catch (error) {
          console.error("Error fetching rithmic data", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [gauntletAccountName, page, sortBy, limit]);

  useEffect(() => {
    setPage(0);
  }, [limit]);

  return (
    <div>
      <ContainerWithLoader
        isLoading={loading}
        report={rithmicData}
        noResultsMessage="There are no trades"
      >
        <div className={classes.actionsWrapper}>
          <TableColumnsView
            relatedTables={activeColumns}
            handleShowExternalColumns={handleShowExternalColumns}
            columns={tableColumns}
          />

          {allowed_features.generate_gauntlet_report && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              classes={{ root: classes.buttonRoot }}
              onClick={handleOpenDialog}
            >
              Generate gauntlet report
            </Button>
          )}
        </div>

        <div className={classes.tableContainer}>
          <table className={generalClasses.table}>
            <SortableTableHeader
              activeColumns={activeColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <TableBody activeColumns={activeColumns} rithmicData={rithmicData} />
          </table>
        </div>
      </ContainerWithLoader>

      <GenerateGauntletReportDialog
        fetchRithmicData={fetchRithmicData}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        commissions={MOCK_LIST_COMMISSIONS}
      />

      {!!rithmicData.length && (
        <PaginationWithLimit
          count={count}
          limit={limit.toString()}
          page={page}
          setPageState={setPage}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Trades;

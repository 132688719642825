import { makeStyles } from "@material-ui/core";
import { STATUS_COLORS_OPACITY } from "../../../../constants";

const useStyles = makeStyles({
  formContainer: {
    marginBottom: 10,
  },
  ipInput: {
    marginRight: 10,
  },
  formWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  preloaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 80,
  },
  success: {
    color: "green",
  },
  failed: {
    color: "red",
  },
  sharedIp: {
    backgroundColor: STATUS_COLORS_OPACITY.tango,
    "&:hover td": {
      cursor: "pointer !important",
    },
  },
  exportButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  legend: {
    marginRight: 10,
  },
});

export default useStyles;

import {
  EProcessingStatuses,
  EInvoiceStatusesLiveFundedTrader,
  EInvoiceStatusesLiveSimFundedTrader,
} from "../../../../constants";

// PROCESSING_STATUSES
export const PROCESSING_STATUSES_IN_PROGRESS = [
  EProcessingStatuses.askedForInfo,
  EProcessingStatuses.ok_paperwork,
  EProcessingStatuses.live_sim_live,
  EProcessingStatuses.pro_must_pay_for_data,
  EProcessingStatuses.on_hold,
];
export const PROCESSING_STATUSES_READY = [
  EProcessingStatuses.ready_for_review,
  EProcessingStatuses.reviewed,
  EProcessingStatuses.active_trading_account,
  EProcessingStatuses.completed,
];

// INVOICE_STATUSES
export const INVOICE_STATUSES_IN_PROGRESS = [
  EInvoiceStatusesLiveSimFundedTrader.needs_setup_invoice,
  EInvoiceStatusesLiveSimFundedTrader.invoice_sent,
  EInvoiceStatusesLiveSimFundedTrader.claimed_paid,
  EInvoiceStatusesLiveSimFundedTrader.pro_needs_invoice,
  EInvoiceStatusesLiveSimFundedTrader.pro_invoice_sent,
  EInvoiceStatusesLiveFundedTrader.needs_invoice,
  EInvoiceStatusesLiveFundedTrader.no_data,
];
export const INVOICE_STATUSES_READY = [
  EInvoiceStatusesLiveSimFundedTrader.paid,
  EInvoiceStatusesLiveSimFundedTrader.pro_sept_2023,
  EInvoiceStatusesLiveSimFundedTrader.pro_paid,
];

export const INACTIVE_STATUS_COLOR = "#d9d9d9";
export const ON_HOLD_STATUS_COLOR = "#ff9900";
export const IN_PROGRESS_STATUS_COLOR = "#ffe5a0";
export const READY_STATUS_COLOR = "#d4edbc";
export const FAILED_OR_BANNED_STATUS_COLOR = "#f00d0d96";

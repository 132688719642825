import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import ExportCsvProductsStats from "./ExportCsvProductsStats";
import ProductStatsTable from "./ExportCsvProductsStats/components/ProductStatsTable";
import TotalIncomeChart from "./TotalIncomeChart";
import SalesChartsAveragePrice from "./SalesChartsAveragePrice";

const SalesSection: React.FC = () => {
  const [isIgnoreWeekends, setIsIgnoreWeekends] = useState(true);

  return (
    <div>
      <h2>Sales</h2>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isIgnoreWeekends}
              onChange={() => setIsIgnoreWeekends(!isIgnoreWeekends)}
              name="isFilterByEmail"
            />
          }
          label="Remove weekends"
        />
      </div>
      <ExportCsvProductsStats isIgnoreWeekends={isIgnoreWeekends} />
      <ProductStatsTable isIgnoreWeekends={isIgnoreWeekends} />
      <TotalIncomeChart isIgnoreWeekends={isIgnoreWeekends} />
      <SalesChartsAveragePrice isIgnoreWeekends={isIgnoreWeekends} />
    </div>
  );
};

export default SalesSection;

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId } from "lodash";

import useStyles from "../../useStyles";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import Table from "../Table";

import usePagination from "../../../../hooks/usePagination";
import { TitleUpdated } from "../../utils";
import { TOP_LIVE_LOSERS_COLUMNS } from "../../constants";

const TopLiveLosers: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();

  const { data, loading } = useSelector((state) => state.complianceSearch.topLiveLosers);

  useQuery({
    queryKey: [COMPLIANCE_API_URLS.getTopLiveLosers, pagination.limit, pagination.page],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchTopLiveLosersRequest());
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());

      const response = await ComplianceAPI.getTopLiveLosersRequest(query.toString(), signal);

      dispatch(ComplianceSearchActions.fetchTopLiveLosersSuccess(response.data));
      pagination.setCount(response.data.results.count);
      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchTopLiveLosersFailed());
      console.error("Error fetching Top Live losers", error);
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Top Live losers</b>

      <Table
        title={<TitleUpdated value={data.updated.timestamp} />}
        columns={TOP_LIVE_LOSERS_COLUMNS}
        loading={loading}
        extractKey={(row) => `${row.name}_${uniqueId()}`}
        data={data.results.rows}
        pagination={pagination}
        handleChangePage={pagination.setPage}
        csv_table_name="Top Live losses, and top LiveSim takers - Top Live losers"
      />
    </div>
  );
};

export default TopLiveLosers;

import React, { useState } from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import useStyles from "./useStyles";
import useGeneralStyles from "../../useGeneralStyles";

import TabPanel from "../../components/ui/TabPanel";
import FundedTradersTable from "./components/FundedTradersTable";
import { SERVICE_API_URLS, ServiceAPI } from "../../api/service";
import { FundedTradersStatusesListActions } from "../../reducers/fundedTradersStatusesList/actions";

const FundedTraders = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const [activeTabIndex, setActiveTab] = useState(0);

  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  useQuery({
    queryKey: [SERVICE_API_URLS.getFundedTradersStatusesList],
    queryFn: async () => {
      dispatch(FundedTradersStatusesListActions.fetchFundedTradersStatusesListRequest());
      const response = await ServiceAPI.getFundedTradersStatusesListRequest();
      dispatch(
        FundedTradersStatusesListActions.fetchFundedTradersStatusesListSuccess(response.data)
      );
      return response.data;
    },
    onError: () =>
      dispatch(FundedTradersStatusesListActions.fetchFundedTradersStatusesListFailed()),
  });

  return (
    <div className={generalClasses.containerBg}>
      <div>
        <Paper square>
          <Tabs value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab label="Funded Traders" />
            <Tab label="LiveSim" />
            <Tab label="Live" />
            {/*<Tab label="Forced Live" />*/}
          </Tabs>
        </Paper>
      </div>

      <div className={classes.tabsContainer}>
        <TabPanel value={activeTabIndex} index={0}>
          <FundedTradersTable account_type="all" />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <FundedTradersTable evaluation_type="LiveSim" />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          <FundedTradersTable evaluation_type="Live" />
        </TabPanel>
        {/*<TabPanel value={activeTabIndex} index={3}>*/}
        {/*  <FundedTradersTable account_type="forcedToLive" />*/}
        {/*</TabPanel>*/}
      </div>
    </div>
  );
};

export default FundedTraders;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  activePlan: {
    textAlign: "center",
    color: "green",
  },
  inactivePlan: {
    textAlign: "center",
    color: "red",
  },
  expiresInputContainer: {
    maxWidth: 145,
    minWidth: 140,
  },
  tr: {
    "& td": {
      minHeight: 60,
      height: 45,
    },
  },
  planPriceInput: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeInputContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
  },
});
export default useStyles;

import httpClient from "../apiClient";
import {
  TCreateURMAProfileRequest,
  TGetURMAProfileRequest,
  TGetURMAUserRequest,
  TCreateURMAUserRequest,
  TGetUrmaAccountRequest,
  TCreateUrmaAccountRequest,
} from "./index.types";

export class URMAAPI {
  static createURMAProfileRequest: TCreateURMAProfileRequest = (data) =>
    httpClient.post("urma/profile-create", data);
  static getURMAProfileRequest: TGetURMAProfileRequest = (account_id) =>
    httpClient.post(`urma/profile/${account_id}`);
  static getURMAUserRequest: TGetURMAUserRequest = (profile_id) =>
    httpClient.post(`urma/user/${profile_id}`);
  static createURMAUserRequest: TCreateURMAUserRequest = (data) =>
    httpClient.post("urma/user-create", data);
  static getUrmaAccountRequest: TGetUrmaAccountRequest = (profile_id) =>
    httpClient.post(`urma/account/${profile_id}`);
  static createUrmaAccountRequest: TCreateUrmaAccountRequest = (data) =>
    httpClient.post("urma/account-create", data);
}

import React from "react";
import m from "moment";
import { FormikHelpers, Field } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ICode } from "../types";
import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../constants";
import { FormCheckbox, FormDatePicker, FormInput } from "../../index";
import { useAdminProviderDataContext } from "../../../../context";

interface IProps {
  values: ICode;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
}

const DurationControls: React.FC<IProps> = ({ values, setFieldValue }) => {
  const classes = useStyles();
  const { user } = useAdminProviderDataContext();

  return (
    <>
      <Field
        component={FormDatePicker}
        label="Expiry Date"
        name="expires"
        datePickerProps={{
          minDate: new Date(),
          minDateMessage: "",
          inputVariant: "standard",
          className: classes.expires,
        }}
        disabled={!values.autoExpires}
        format={DATE_FORMAT}
        onChange={(date: MaterialUiPickersDate) =>
          setFieldValue("expires", date ? date.format(DATE_FORMAT) : null)
        }
        className={classes.expires}
      />

      {user.allowed_features.set_code_expiration_props && (
        <div className={classes.expirationDurationContainer}>
          {values.use_expiration_days && (
            <Field
              color="primary"
              name="expiration_days"
              label="Expiration days"
              component={FormInput}
              variant="standard"
              type="number"
              step="1"
            />
          )}
          {!values.use_expiration_days && (
            <Field
              component={FormDatePicker}
              label="Expiration Date"
              name="expiration"
              datePickerProps={{
                minDate: m().add(30, "days"),
                minDateMessage: "",
                inputVariant: "standard",
                className: classes.expires,
              }}
              disabled={!values.use_expiration}
              format={DATE_FORMAT}
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("expiration", date ? date.format(DATE_FORMAT) : null)
              }
              className={classes.expires}
            />
          )}

          <Field
            color="primary"
            name="use_expiration_days"
            label="Use expiration days"
            component={FormCheckbox}
            disabled={!values.use_expiration}
          />
        </div>
      )}
      {(values.use_failover_type || values.use_failover_amt) && (
        <Field
          name="failover_amt"
          component={FormInput}
          label="Discount amount past expiration"
          type="number"
          variant="standard"
          step="1"
        />
      )}
    </>
  );
};

export default DurationControls;

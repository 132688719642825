import React from "react";

const TableHead = (): React.ReactElement => (
  <thead>
    <tr>
      <td>ID</td>
      <td>Brand</td>
      <td>Card usage count</td>
      <td>Last 4</td>
      <td>Active</td>
      <td>Default</td>
    </tr>
  </thead>
);
export default TableHead;

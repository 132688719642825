import React from "react";
import useStyles from "./useStyles";

const TableHead = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <thead>
      <tr className={classes.tr}>
        <th>Plan Name</th>
        <th>Purchase Date</th>
        <th>Rebill Date</th>
        <th>Initial Plan Price</th>
        <th>Current Plan Price</th>
        <th>Active</th>
        <th>Discount</th>
        <th>Discount Applied Until</th>
        <th>Available free resets</th>
        <th />
      </tr>
    </thead>
  );
};

export default TableHead;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { CodesActions } from "../../../reducers/plansReducer";
import { PolicesActions } from "../../../reducers/policesReducer/actions";
import { APIPlans } from "../../../api";
import { useAuthContext } from "../../../context";
import { SERVICE_API_URLS, ServiceAPI } from "../../../api/service";

interface IProps {
  children?: React.ReactNode;
}

const AppState = ({ children }: IProps): React.ReactElement => {
  const dispatch = useDispatch();
  const { token } = useAuthContext();

  useQuery({
    retry: false,
    queryKey: [SERVICE_API_URLS.getPolicesOptions],
    queryFn: async () => {
      dispatch(PolicesActions.fetchPolicesRequest());
      const response = await ServiceAPI.getPolicesOptionsRequest();
      dispatch(PolicesActions.fetchPolicesSuccess(response.data));
      return response;
    },
    onError: (error) => {
      dispatch(PolicesActions.fetchPolicesFailed());
      console.error("error fetching getPolicesOptionsRequest", error);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          dispatch(CodesActions.fetchRequest());
          const { data } = await APIPlans.getPlansListRequest();
          dispatch(CodesActions.fetchSuccess(data));
        }
      } catch (error) {
        console.error("error fetching plans list:", error);
        dispatch(CodesActions.fetchFailed());
      }
    })();
  }, [token]);

  return <>{children}</>;
};

export default AppState;

import httpClient from "../apiClient";

import {
  TSaveICCustomerRequest,
  TExtendFinamarkRequest,
  TCreateFinamarkRequest,
  TSaveEditingPlanRequest,
  TGetICCustomerRequest,
  TGetIntercomContactRequest,
  TUpdateIntercomContactRequest,
  TClearFailIntercomContactLogRequest,
} from "./index.types";

export const INTERCOM_API_URLS = {
  updateContact: (): string => "ic/user/updateContact/",
  getCustomer: (xid: string): string => `ic/user/${xid}`,
  retrieveContact: "ic/user/retrieveContact",
};

export class IntercomAPI {
  static saveICCustomerRequest: TSaveICCustomerRequest = (xid, data) =>
    httpClient.post(`ic/user/save/${xid}`, data);
  static extendFinamarkRequest: TExtendFinamarkRequest = (email, days) =>
    httpClient.get(`https://api.earn2trade.com/finamark/demo/${email}/extend/${days}`);
  static createFinamarkRequest: TCreateFinamarkRequest = (customer, fina) =>
    httpClient.get(
      `https://api.earn2trade.com/finamark/demo/${customer.email}?user=${fina.user}&pass=${fina.pass}&system=${fina.sys}&gateway=${fina.gway}`
    );
  static saveEditingPlanRequest: TSaveEditingPlanRequest = (xid, data) =>
    httpClient.post(`ic/user/plan/save/${xid}`, data);
  static getICCustomerRequest: TGetICCustomerRequest = (xid) =>
    httpClient.post(INTERCOM_API_URLS.getCustomer(xid));
  static getIntercomContactRequest: TGetIntercomContactRequest = (id) =>
    httpClient.post(`${INTERCOM_API_URLS.retrieveContact}/${id}`);
  static updateIntercomContactRequest: TUpdateIntercomContactRequest = (data, external_id) =>
    httpClient.post(`${INTERCOM_API_URLS.updateContact()}${external_id}`, data);
  static clearFailIntercomContactLogRequest: TClearFailIntercomContactLogRequest = (body) =>
    httpClient.post("agent_activity_log/mgm/clear_failures", body);
}

import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import cx from "classnames";
import * as LogRocket from "logrocket";

import useGeneralStyles from "../../useGeneralStyles";
import { APIAccounts } from "../../api";
import { IAccountsMessagesData } from "../../api/accounts/index.types";

import { ContainerWithLoader } from "../../components/ui";
import { useAuthContext } from "../../context";
import useStyles from "./useStyles";
import HTMLPreview from "./components/HTMLPreview";
import EditMessageDialog from "./components/EditMessageDialog";
import EditHTMLMessageDialog from "./components/EditHTMLMessageDialog/EditHTMLMessageDialog";

export interface IEditingHTML {
  html: string;
  id: number;
  label: string;
}

const Messages = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { hasExpired } = useAuthContext();

  const [isEditingPreview, setEditingPreview] = useState(false);
  const [isEditingContent, setEditingContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [messages, setMessages] = useState<IAccountsMessagesData[]>([]);
  const [editingHtml, setEditingHtml] = useState<IEditingHTML>({
    label: "",
    html: "",
    id: -1,
  });

  const handleCloseEditing = () => {
    setEditingPreview(false);
    setEditingContent(false);
  };
  const handleSave = async () => {
    setUpdating(true);
    try {
      const { data } = await APIAccounts.updateAccountMessageRequest(editingHtml);
      setMessages(data.messages);
    } catch (error) {
      console.error("error saving user message", error);
      hasExpired();
    } finally {
      handleCloseEditing();
      setUpdating(false);
    }
  };

  useEffect(() => {
    LogRocket.track("Messages: open page");
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await APIAccounts.getAccountsMessagesRequest();
        setMessages(data.messages);
      } catch (error) {
        console.error("error fetching accounts messages:", error);
        hasExpired();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className={generalClasses.containerBg}>
      <h2>User messages</h2>

      <EditMessageDialog
        isOpen={isEditingPreview}
        setEditing={setEditingPreview}
        setEditingHtml={setEditingHtml}
        editingHtml={editingHtml}
        handleCloseEditing={handleCloseEditing}
        handleSave={handleSave}
        isUpdating={isUpdating}
      />

      <EditHTMLMessageDialog
        isOpen={isEditingContent}
        setEditingHtml={setEditingHtml}
        editingHtml={editingHtml}
        handleSave={handleSave}
        setEditing={setEditingContent}
        handleCloseEditing={handleCloseEditing}
        isUpdating={isUpdating}
      />

      <ContainerWithLoader isLoading={isLoading} report={messages}>
        <div>
          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <thead>
              <tr>
                <td className={classes.labelContainer}>Label</td>
                <td className={classes.htmlContent}>Content</td>
                <td>Preview</td>
              </tr>
            </thead>

            <tbody>
              {messages.map(({ label, html, id }) => (
                <tr key={id + html}>
                  <td className={classes.labelContent}>{label}</td>
                  <td className={cx(generalClasses.tableHover)}>
                    <div
                      onClick={() => {
                        setEditingContent(true);
                        setEditingHtml({ html, id, label });
                      }}
                      tabIndex={-1}
                      role="button"
                    >
                      <HTMLPreview html={html} />
                    </div>
                  </td>
                  <td className={generalClasses.tableHover}>
                    <div
                      onClick={() => {
                        setEditingPreview(true);
                        setEditingHtml({ html, id, label });
                      }}
                      tabIndex={-1}
                      role="button"
                    >
                      {parse(html)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ContainerWithLoader>
    </div>
  );
};

export default Messages;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
  },
  countryContainer: {
    whiteSpace: "break-spaces",
    width: "calc(100% - 82px)",
    marginLeft: 15,
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  innerContainer: {
    marginRight: 5,
    verticalAlign: "top",
    maxWidth: 346,
  },
  innerContainerFundedTraderPage: {
    display: "grid",
    gridGap: 13,
  },
});

export default useStyles;

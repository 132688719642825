import React, { useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Typography, styled, IconButton, CircularProgress } from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import { AnyObjectSchema } from "yup";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { CopyToClipboard, FormInput } from "../index";
import useStyles from "./useStyles";

interface IProps {
  title?: string;
  value: string | number;
  name: string;
  label: string;
  onSave: () => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setValue: React.Dispatch<React.SetStateAction<any>>;
  validationSchema: AnyObjectSchema;
  isWithCopyToClipboard?: boolean;
  isAllowedEditing: boolean;
  type?: string;
}

const StyledFrom = styled(Form)({
  width: "100%",
  display: "flex",
});

const EditableText: React.FC<IProps> = ({
  title = "",
  value,
  onSave,
  name,
  label,
  setValue,
  validationSchema,
  isWithCopyToClipboard,
  isAllowedEditing,
  type = "text",
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const classes = useStyles({ isEditing });

  const handleClickEdit = () => setIsEditing(true);
  const handleCancel = () => setIsEditing(false);
  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      setIsEditing(false);
      await onSave();
    } catch (error) {
      console.log("error");
    } finally {
      setIsUpdating(false);
    }
  };
  const FormikContext = () => {
    const { values }: { values: { [key: string]: string | number } } = useFormikContext();
    React.useEffect(() => {
      if (name in values) {
        const value = values[name];
        if (typeof value === "number" || !values[name]) {
          setValue(value);
        } else {
          setValue(value.trim());
        }
      }
    }, [values[name]]);
    return null;
  };

  const content = () => (isWithCopyToClipboard ? <CopyToClipboard value={value} /> : value);

  return (
    <div>
      <div>
        {!!title && (
          <div className={classes.valueContent}>
            <Typography classes={{ root: classes.title }} variant="subtitle2">
              {title}
            </Typography>
            <Typography>&nbsp;</Typography>

            {isEditing ? (
              <div className={classes.mainContainer}>
                <Formik
                  initialValues={{
                    [name]: value,
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isValid, dirty }) => (
                    <StyledFrom>
                      <Field
                        type={type}
                        fullWidth
                        size="small"
                        name={name}
                        label={label}
                        placeholder={label}
                        errorContainerClassName={classes.errorContainer}
                        component={FormInput}
                      />
                      <div className={classes.actionsContainer}>
                        {isValid && dirty && (
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckCircleIcon color="primary" className={classes.icon} />
                          </IconButton>
                        )}
                        <IconButton size="small" onClick={handleCancel}>
                          <CancelIcon color="secondary" className={classes.icon} />
                        </IconButton>
                      </div>
                      <FormikContext />
                    </StyledFrom>
                  )}
                </Formik>
              </div>
            ) : (
              <>
                {value !== null ? (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      !isWithCopyToClipboard && handleClickEdit();
                    }}
                    className={classes.value}
                  >
                    {isUpdating ? <CircularProgress size={20} /> : content()}
                  </div>
                ) : null}

                {!isUpdating && isWithCopyToClipboard && isAllowedEditing && (
                  <EditIcon
                    onClick={handleClickEdit}
                    classes={{ root: classes.value }}
                    fontSize="small"
                    data-test="edit-text"
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableText;

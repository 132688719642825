/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { IActivityLogsData, IGetActivityLogsResponse } from "../api/agentActivityLog/index.types";

export type TAgentsLogsActivityAction =
  | IActivityLogsDataFetchRequest
  | IActivityLogsDataFetchSuccess
  | IActivityLogsDataFetchFailed;

interface IActivityLogsDataFetchRequest extends ReduxAction {
  type: EActivityLogsDataActionType.fetchRequest;
}
interface IActivityLogsDataFetchSuccess extends ReduxAction {
  type: EActivityLogsDataActionType.fetchSuccess;
  payload: {
    logs: IActivityLogsData[];
    count: number;
  };
}
interface IActivityLogsDataFetchFailed extends ReduxAction {
  type: EActivityLogsDataActionType.fetchFailed;
}

export enum EActivityLogsDataActionType {
  fetchRequest = "agentsActivityLogs/FetchRequest",
  fetchSuccess = "agentsActivityLogs/FetchSuccess",
  fetchFailed = "agentsActivityLogs/FetchFailed",
}

export const AgentsLogActivityActions = {
  fetchAgentsLogsRequest: (): TAgentsLogsActivityAction => ({
    type: EActivityLogsDataActionType.fetchRequest,
  }),
  fetchAgentsLogsSuccess: ({ data }: IGetActivityLogsResponse): TAgentsLogsActivityAction => ({
    type: EActivityLogsDataActionType.fetchSuccess,
    payload: {
      logs: data.logs,
      count: data.count,
    },
  }),
  fetchAgentsLogsFailed: (): TAgentsLogsActivityAction => ({
    type: EActivityLogsDataActionType.fetchFailed,
  }),
};

export interface IAgentsActivityLogsState {
  data: {
    logs: IActivityLogsData[];
    count: number;
  };
  loading: boolean;
}
const initialState: IAgentsActivityLogsState = {
  data: {
    logs: [],
    count: 0,
  },
  loading: false,
};

const agentsLogsActivityReducer = (
  prevState = initialState,
  action: TAgentsLogsActivityAction
): IAgentsActivityLogsState =>
  produce(prevState, (draft: IAgentsActivityLogsState) => {
    switch (action.type) {
      case EActivityLogsDataActionType.fetchRequest:
        draft.loading = true;
        break;
      case EActivityLogsDataActionType.fetchSuccess:
        draft.loading = false;
        draft.data.count = action.payload.count;
        draft.data.logs = action.payload.logs;
        break;
      default:
        draft.loading = false;
        return draft;
    }
    return draft;
  });
export default agentsLogsActivityReducer;

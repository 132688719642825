import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core/";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import AccountStatus from "./components/AccountStatus";
import GauntletsAccounts from "./components/GauntletsAccounts";
import AccountSimulators from "./components/AccountSimulators";

import { useAdminProviderDataContext } from "../../../../context";
import { ACCOUNTS_API_URLS } from "../../../../api/accounts";
import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import { APIAccounts } from "../../../../api";
import { PaymentCards } from "../index";

const AccountManagement = (): React.ReactElement => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    user: {
      allowed_features: { change_customer_status, change_gauntlet_and_simulator_data },
    },
  } = useAdminProviderDataContext();

  useQuery({
    retry: false,
    queryKey: [ACCOUNTS_API_URLS.getAccountManagementData, id],
    async queryFn() {
      dispatch(CustomerActions.fetchCustomerManagementDataRequest());
      const response = await APIAccounts.getAccountManagementDataRequest(id);
      dispatch(CustomerActions.fetchCustomerManagementDataSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetch account management data", error);
      dispatch(CustomerActions.fetchCustomerManagementDataFailed());
    },
  });

  return (
    <div>
      <h3>Account management</h3>

      <div>
        {change_customer_status && <AccountStatus />}
        {change_gauntlet_and_simulator_data && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <GauntletsAccounts />
            </Grid>
            <Grid item xs={6}>
              <AccountSimulators />
            </Grid>
          </Grid>
        )}
        <PaymentCards />
      </div>
    </div>
  );
};

export default AccountManagement;

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { getExpirationData } from "../utils";
import PlansPriceData from "../../PlansPriceData";
import useStyles from "./useStyles";
import { useAdminProviderDataContext } from "../../../../../../../context";
import { FormDatePicker } from "../../../../../../../components/ui";
import { DATE_FORMAT } from "../../../../../../../constants";
import { ICustomerPlan } from "../../../../../../../api/accounts/index.types";
import { Plan } from "../../../../../Customer";

const Status = ({ status }: { status: ICustomerPlan["status"] }) => {
  const classes = useStyles();
  return status === "active" ? (
    <td className={classes.activePlan}>✔</td>
  ) : (
    <td className={classes.inactivePlan}>✘</td>
  );
};

interface IProps {
  setEditingPlanData: (p: Plan) => void;
}

const TableBody: React.FC<IProps> = ({ setEditingPlanData }) => {
  const classes = useStyles();

  const {
    data: { plans },
  } = useSelector((state) => state.customer.plans);
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const { account_plans_id } = banned_account?.field_values_before_bun || {};

  const [editingPlanId, setEditingPlanId] = useState(0);

  return (
    <tbody>
      <FieldArray
        name="plans"
        render={({ form }) => {
          return plans.map((plan, index) => {
            const isEditing = editingPlanId === plan.id;
            const isPasses = plan.passed;

            const expiresFieldName = `plans.${index}.expires`;
            const statusFiledName = `plans.${index}.status`;

            const status = form.values.plans[index]?.status;
            const isActiveStatus = status === "active";

            const { isHasExpiration, expirationDate } = getExpirationData(plan);

            return (
              <tr key={plan.id} className={classes.tr}>
                <td>{plan.plan && plan.plan.name}</td>
                <td>{plan.start}</td>
                <td>
                  {isEditing ? (
                    <div className={classes.expiresInputContainer}>
                      <Field
                        datePickerProps={{ inputVariant: "standard" }}
                        format={DATE_FORMAT}
                        name={expiresFieldName}
                        component={FormDatePicker}
                        onChange={(date: MaterialUiPickersDate) =>
                          form.setFieldValue(
                            expiresFieldName,
                            date ? date.format(DATE_FORMAT) : null
                          )
                        }
                      />
                    </div>
                  ) : (
                    plan.expires
                  )}
                </td>

                <PlansPriceData plan={plan} index={index} isEditing={isEditing} />
                {isEditing ? (
                  <td className={classes.activeInputContainer}>
                    <input
                      disabled={!allowed_features.edit_customer_active_status_subscriptions}
                      className={classes.planPriceInput}
                      type="checkbox"
                      checked={isActiveStatus}
                      onChange={() => {
                        form.setFieldValue(statusFiledName, isActiveStatus ? "canceled" : "active");
                      }}
                    />
                  </td>
                ) : (
                  <>{isPasses ? <td>Passed</td> : <Status status={plan.status} />}</>
                )}

                <td>{plan.discount_code?.code}</td>
                <td>{isHasExpiration ? expirationDate : ""}</td>
                <td>{plan.free_resets}</td>
                <td>
                  <Button
                    size="small"
                    onClick={() => {
                      if (form.dirty && isEditing) {
                        setEditingPlanData(form.values.plans[index]);
                        form.resetForm();
                        setEditingPlanId(0);
                        return;
                      }
                      form.resetForm();
                      isEditing ? setEditingPlanId(0) : setEditingPlanId(plan.id);
                    }}
                    disabled={!!account_plans_id || plan.passed || !form.isValid}
                  >
                    {isEditing ? (form.dirty ? "Save" : "Cancel") : "Edit"}
                  </Button>
                </td>
              </tr>
            );
          });
        }}
      />
    </tbody>
  );
};

export default TableBody;

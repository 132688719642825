import React, { useState } from "react";
import cx from "classnames";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";

import Bar from "./Components/Bar";
import ListQuantityOfProducts from "../ListQuantityOfProducts/ListQuantityOfProducts";
import { IReport, IGetReport, IGetReportResponse } from "../../../../api/billing/index.types";
import { ContainerWithLoader, SelectByDateWithCustomPeriod } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../constants";

interface IProps {
  purchaseStatus: string;
  type: "sum" | "purchased" | "refunded" | "chargeback";
  isShowList?: boolean;
  getReportRequest: (body: IGetReport) => Promise<IGetReportResponse>;
}

const ReportChartWithDateSelect = ({
  purchaseStatus,
  type,
  isShowList,
  getReportRequest,
}: IProps): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<IReport[]>([]);

  const isNegativeValue = type === "chargeback" || type === "refunded";

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    setIsLoading(true);
    try {
      const response = await getReportRequest({
        startDate: `${startDate}`,
        endDate: `${endDate}`,
        purchaseStatus,
        isAllTime,
      });
      const reportResponse = response.data.report || [];

      setReport(reportResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const sum = report
    .map(({ sum_price_usd, sum_price_usd_resets }) => +sum_price_usd + Number(sum_price_usd_resets))
    .reduce((a, b) => a + b, 0);

  const isTypePurchased = type === "purchased";

  return (
    <div className={classes.container}>
      <div className={classes.selectContainer}>
        <SelectByDateWithCustomPeriod
          setIsLoading={setIsLoading}
          getReport={getReport}
          setReport={setReport}
        />

        <h3 className={classes.sum}>
          {!isLoading && !isTypePurchased && (
            <>
              Sum:{" "}
              <span>
                {isNegativeValue && "-"}
                {IntlFormatUSD(sum / CENTS_IN_DOLLAR)}
              </span>
            </>
          )}
        </h3>
      </div>

      <div className={cx(generalClasses.containerBg, classes.tableWrapper)}>
        <ContainerWithLoader isLoading={isLoading} report={report}>
          {isShowList ? (
            <ListQuantityOfProducts report={report} />
          ) : (
            <Bar type={type} report={report} />
          )}
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default ReportChartWithDateSelect;

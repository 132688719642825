import * as Yup from "yup";
import moment_tz from "moment-timezone";

export const STANDARD_VACATION_DURATION = 10;

export const MANAGEMENT_VALIDATION_SCHEMA = Yup.object().shape({
  start_date: Yup.date()
    .required("Start date is required")
    .max(moment_tz(), "Date can't be in the future")
    .typeError("Invalid date")
    .nullable()
    .default(null),
  shift: Yup.string().required("Required"),
  position: Yup.string().required("Required"),
  used_vacation_allowance: Yup.number().min(0, "Must be greater than 0").required("Required"),
});

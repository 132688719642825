import { isEqual, reduce } from "lodash";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import { IIntercomContact } from "../../../../api/intercom/index.types";
import { MS_IN_SECOND } from "../../../../constants";
import { CURRENCY_REGEX, EMAIL_REG_EXP, PHONE_REG_EXP } from "../../../../utils/validation";
import { ICustomAttributes } from "../../../../context/IntercomProvider/utils";

export const CUSTOM_ATTRIBUTES_KEY = "custom_attributes";

export const getChangedValues = (
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  a: { [key: string]: any },
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  b: { [key: string]: any }
): string[] | [] =>
  reduce(
    a,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (result: string[], value: any, key: string) =>
      isEqual(value, b[key]) ? result : result.concat(key),
    []
  );

export const isDateKeyValue = (key: string): boolean =>
  key.includes("Date") ||
  key.includes("date") ||
  key === "subscription_canceled" ||
  key === "subscription_until";

export const TIME_CORRECTION_MS = 86400;
export const makeDate = (ds: number): number => {
  const d = new Date(ds);
  d.setHours(0);
  d.setMinutes(0);
  return d.getTime() / 1000 + TIME_CORRECTION_MS;
};

export const getDate = (date: string): Moment | null => {
  if (date) {
    const value = +date * MS_IN_SECOND - TIME_CORRECTION_MS;
    return moment(value).isValid() ? moment(value) : null;
  }
  return null;
};

export const getConfiguredCustomAttributes = (contact: IIntercomContact): ICustomAttributes => {
  const updatedAttributes: ICustomAttributes = {};
  Object.keys(contact[CUSTOM_ATTRIBUTES_KEY]).forEach((key) => {
    if (key.includes("_balance")) {
      updatedAttributes[key] = +contact[CUSTOM_ATTRIBUTES_KEY][key];
    }
    if (isDateKeyValue(key)) {
      const value = contact[CUSTOM_ATTRIBUTES_KEY][key];
      updatedAttributes[key] = getDate(value);
    }
  });
  return updatedAttributes;
};

export const getIntercomInitialValues = (contact: IIntercomContact): IIntercomContact => ({
  role: contact.role,
  external_id: contact.external_id,
  email: contact.email,
  phone: contact.phone,
  name: contact.name,
  avatar: contact.avatar || "",
  owner_id: contact.owner_id || "",
  // @ts-ignore
  signed_up_at: getDate(contact.signed_up_at),
  // @ts-ignore
  last_seen_at: getDate(contact.last_seen_at),
  unsubscribed_from_emails: contact.unsubscribed_from_emails,
  [CUSTOM_ATTRIBUTES_KEY]: {
    ...contact[CUSTOM_ATTRIBUTES_KEY],
    ...getConfiguredCustomAttributes(contact),
  },
});

export const getAutocompleteOptions = (initialValues: IIntercomContact): string[] =>
  [...Object.keys(initialValues), ...Object.keys(initialValues[CUSTOM_ATTRIBUTES_KEY])].filter(
    (name) => name !== CUSTOM_ATTRIBUTES_KEY
  );

const getSchemaValidationKeys = (contact: IIntercomContact) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const optionalValidationObject: { [key: string]: any } = {};

  Object.keys(contact[CUSTOM_ATTRIBUTES_KEY]).forEach((key) => {
    if (isDateKeyValue(key)) {
      optionalValidationObject[key] = Yup.date().nullable();
    }
    if (key.includes("_balance")) {
      optionalValidationObject[key] = Yup.string().matches(
        CURRENCY_REGEX,
        "Should have no more than 2 decimals"
      );
    }
  });
  return optionalValidationObject;
};
export const getContactValidationSchema = (contact: IIntercomContact): unknown =>
  Yup.object().shape({
    role: Yup.string().required("Role is required"),
    external_id: Yup.string().when(["role", "email"], {
      is: (role: string, email: string) => role === "user" && !email,
      then: Yup.string().required("External ID is required"),
    }),
    email: Yup.string().test("Email validation", "Email is invalid", function validEmail() {
      // eslint-disable-next-line react/no-this-in-sfc
      const { role, external_id, email } = this.parent;
      if (role === "lead") {
        return EMAIL_REG_EXP.test(email);
      }
      if (role === "user" && !external_id) {
        return EMAIL_REG_EXP.test(email);
      }
      return !email || EMAIL_REG_EXP.test(email);
    }),
    phone: Yup.string().matches(PHONE_REG_EXP, "Phone number is not valid"),
    avatar: Yup.string().url("Avatar URL is invalid").min(0),
    signed_up_at: Yup.date(),
    last_seen_at: Yup.date(),
    [CUSTOM_ATTRIBUTES_KEY]: Yup.object().shape(getSchemaValidationKeys(contact)),
  });

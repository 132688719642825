import React from "react";
import moment from "moment-timezone";
import { MenuItem } from "@material-ui/core/";
import { Link } from "react-router-dom";

import { IAffiliateFullAccountData } from "../../../../api/affiliates/intex.types";
import { HOURS_12_FORMAT_WITH_DATE } from "../../../../constants";

interface IProps {
  affiliates: IAffiliateFullAccountData[];
}

const TableBody: React.FC<IProps> = ({ affiliates }) => {
  return (
    <tbody>
      {affiliates.map(({ id, name, email, added, active, affiliate_campaign }) => (
        <tr key={id}>
          <td>{id}</td>
          <td>
            <MenuItem component={Link} to={`/affiliate/${id}`}>
              {name}
            </MenuItem>
          </td>
          <td>{email}</td>
          <td>{moment(added).tz("America/Chicago").format(HOURS_12_FORMAT_WITH_DATE)}</td>
          <td>{active ? "Active" : "Inactive"}</td>
          <td>{affiliate_campaign?.campaign_property?.uid}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

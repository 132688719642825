import React from "react";
import { Field, FieldArray } from "formik";
import cx from "classnames";
import { useSelector } from "react-redux";

import { FormCheckbox } from "../../../../../components/ui";
import { IPaymentCard } from "../../../../../api/paymentCards/index.types";
import useStyles from "./useStyles";
import { useAdminProviderDataContext } from "../../../../../context";

interface IProps {
  payment_cards: IPaymentCard[];
  setSelectedCardId: (card_id: number) => void;
}

const TableBody: React.FC<IProps> = ({ payment_cards, setSelectedCardId }) => {
  const classes = useStyles();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const isDisabledByBunReason = !!banned_account?.field_values_before_bun?.payment_cards?.length;

  const handleClickUsageCount = (card_id: number) => setSelectedCardId(card_id);

  return (
    <tbody>
      <FieldArray
        name="payment_cards"
        render={() => {
          return payment_cards.map((card, index) => (
            <tr
              key={card.id}
              className={cx({
                [classes.defaultPaymentCard]: card.def,
                [classes.sharedPaymentCard]: !!card.used_times,
              })}
            >
              <td>{card.id}</td>
              <td>{card.brand}</td>
              <td
                className={cx({ usedTimes: !!card.used_times })}
                onClick={() => !!card.used_times && handleClickUsageCount(card.id)}
              >
                {card.used_times}
              </td>
              <td>{card.brand === "paypal" ? card.name : card.last4}</td>
              <td>
                <Field
                  name={`payment_cards.${index}.active`}
                  label="Active"
                  placeholder="Active"
                  color="primary"
                  component={FormCheckbox}
                  disabled={!allowed_features.chane_payment_card_data || isDisabledByBunReason}
                />
              </td>
              <td>
                <Field
                  label="Default"
                  placeholder="Default"
                  component={FormCheckbox}
                  name={`payment_cards.${index}.def`}
                  color="primary"
                  disabled={!allowed_features.chane_payment_card_data || isDisabledByBunReason}
                />
              </td>
            </tr>
          ));
        }}
      />
    </tbody>
  );
};

export default TableBody;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 8,
  },
});
export default useStyles;

import React, { useState } from "react";
import cx from "classnames";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CheckCircle, Edit, Cancel } from "@material-ui/icons";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../useGeneralStyles";

interface IProps {
  renderComponent: () => React.ReactElement;
  tableDataCellClasses?: Record<string, boolean | string>;
  isEditable: boolean;
  isChanged: boolean;
  defaultValue: unknown | React.ReactElement;
  handleApply: () => void;
  isUpdating: boolean;
  alertErrorText?: string;
  isShowAlert: boolean;
  setIsShowAlert: (a: boolean) => void;
  isShowEditIcon?: boolean;
  ParentComponent?: string;
  onCancel?: () => void;
}

const EditableContainerWithActions = ({
  isEditable,
  tableDataCellClasses,
  renderComponent,
  defaultValue,
  isChanged,
  handleApply,
  isUpdating,
  alertErrorText = "Error updating code",
  isShowAlert,
  setIsShowAlert,
  ParentComponent = "td",
  onCancel,
  isShowEditIcon = false,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const [isEditing, setEditing] = useState(false);
  const handleClickActiveEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isEditable) {
      setEditing(true);
    }
  };
  const handleCancel = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setEditing(false);
    onCancel && onCancel();
  };
  const onApply = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setEditing(false);
    handleApply();
  };
  const handleCloseAlert = () => setIsShowAlert(false);

  return (
    // @ts-ignore
    <ParentComponent
      className={cx({
        [generalClasses.tableHover]: isEditable && !isEditing,
        ...tableDataCellClasses,
      })}
      onClick={handleClickActiveEdit}
    >
      <div className={classes.dataContainer}>
        {isUpdating && <CircularProgress size={30} />}
        {isUpdating ? null : isEditing ? (
          <div className={classes.autocompleteContainer}>
            {renderComponent()}
            <div className={classes.actionsContainer}>
              {isChanged && (
                <CheckCircle onClick={onApply} color="primary" className={classes.icon} />
              )}
              <Cancel onClick={handleCancel} color="secondary" className={classes.icon} />
            </div>
          </div>
        ) : (
          <>
            {defaultValue}
            {isShowEditIcon && <Edit fontSize="small" classes={{ root: classes.editIcon }} />}
          </>
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
      </div>

      <Snackbar open={isShowAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          {alertErrorText}
        </Alert>
      </Snackbar>
    </ParentComponent>
  );
};

export default EditableContainerWithActions;

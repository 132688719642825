import React from "react";

import TableDataCellWithSort from "../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../reducers/customersReducer";
import newSortBy from "../../../../utils/newSortBy";

interface ISetSortBy {
  order: boolean;
  by: string;
}

interface IProps {
  sortBy: ISetSortBy;
  setSortBy: (a: ISetSortBy) => void;
}
const TableHeader: React.FC<IProps> = ({ sortBy, setSortBy }) => {
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        <TableDataCellWithSort newSortBy={handleSortBy} sortBy={sortBy} by="id" title="ID" />
        <TableDataCellWithSort newSortBy={handleSortBy} sortBy={sortBy} by="name" title="Name" />
        <TableDataCellWithSort newSortBy={handleSortBy} sortBy={sortBy} by="email" title="Email" />
        <TableDataCellWithSort newSortBy={handleSortBy} sortBy={sortBy} by="added" title="Added" />
        <TableDataCellWithSort
          newSortBy={handleSortBy}
          sortBy={sortBy}
          by="active"
          title="Active"
        />
        <td>Code</td>
      </tr>
    </thead>
  );
};

export default TableHeader;

import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ButtonBase } from "@material-ui/core";
import { FormikHelpers } from "formik";
import { useSelector } from "react-redux";

import useStyles from "./useStyles";
import blankProfilePhoto from "../../../../assets/blank-profile-picture.jpg";
import { useAdminProviderDataContext } from "../../../../context";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

interface IFilePreview extends File {
  preview: string;
}
interface IProps<TSetFieldValue> {
  setFieldValue: FormikHelpers<TSetFieldValue>["setFieldValue"];
  fieldName: keyof TSetFieldValue & string;
  backgroundImageUrl: string | null;
  maxFiles?: number;
  accept?: string;
}
const ProfilePhoto = <TSetFieldValue,>({
  setFieldValue,
  fieldName,
  backgroundImageUrl,
  maxFiles = 1,
  accept = "image/*",
}: IProps<TSetFieldValue>): React.ReactElement => {
  const classes = useStyles();
  const { user } = useAdminProviderDataContext();
  const agent = useSelector((state) => state.agent);

  const isAllowedChangeProfilePhoto = user.position === "agent" && user.id !== agent.data.id;

  const [files, setFiles] = useState<IFilePreview[]>([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    disabled: isAllowedChangeProfilePhoto,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
    accept: { [accept]: [] },
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      setFiles(
        acceptedFiles.map((file) => {
          formData.append("file", file);
          setFieldValue(fieldName, formData);
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          alt="profile_photo"
          className={classes.thumbImage}
          src={file.preview}
          onLoad={() => URL.revokeObjectURL(file.preview)}
        />
      </div>
    </div>
  ));

  const ValidateError = (): React.ReactElement | null => {
    if (fileRejections.length > 0) {
      const { code } = fileRejections[0].errors[0];
      const isFileTooLarge = code === "file-too-large";
      const errorMessage = isFileTooLarge
        ? "File is larger then 5MB"
        : fileRejections[0].errors[0].message;
      return (
        <div className={classes.validateError}>
          <div className={classes.validateErrorText}>{errorMessage}</div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <ButtonBase
      className={classes.profileImage}
      style={{
        ...(!thumbs.length && {
          backgroundImage: backgroundImageUrl
            ? `url('${backgroundImageUrl}')`
            : `url(${blankProfilePhoto})`,
        }),
      }}
    >
      {backgroundImageUrl && (
        <img
          alt="profile_photo"
          style={{ display: "none" }}
          referrerPolicy="no-referrer"
          src={backgroundImageUrl}
        />
      )}

      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <aside>{thumbs}</aside>
      </div>
      <ValidateError />
    </ButtonBase>
  );
};

export default ProfilePhoto;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  th: {
    textAlign: "left",
  },
  sumResets: {
    textAlign: "center",
  },
});

export default useStyles;

import httpClient from "../apiClient";
import { TUpdateAccountSimulatorRequest } from "./index.types";

const ACCOUNTS_SIMULATOR_API_URLS = {
  updateAccountSimulator: "/account-simulators/update-simulator",
} as const;

export class AccountsSimulatorsAPI {
  static updateAccountSimulatorRequest: TUpdateAccountSimulatorRequest = (id, data) =>
    httpClient.post(`${ACCOUNTS_SIMULATOR_API_URLS.updateAccountSimulator}/${id}`, data);
}

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import SearchByListValues from "../SearchByListValues";
import { WITHDRAWALS_COLUMNS } from "./constants";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";

const HeliosWithdrawalsSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { names } =
    tabsTablesSearchState.fundedAccountQuickSearchTab.heliosWithdrawalsSearchTable.search;

  const {
    data: { updated, results },
    loading,
  } = useSelector((state) => state.complianceSearch.watchlistWithdrawals);

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    enabled: !!names,
    queryKey: [COMPLIANCE_API_URLS.getWithdrawals, names, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(names, query, "names");

      dispatch(ComplianceSearchActions.fetchWatchlistWithdrawalsRequest());
      const response = await ComplianceAPI.getWatchlistWithdrawalsRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistWithdrawalsSuccess(response.data));
      return response;
    },
    onError: (error) => {
      dispatch(ComplianceSearchActions.fetchWatchlistWithdrawalsFailed());
      console.error("Error fetching withdrawals", error);
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [names, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Withdrawals</b>
      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            fundedAccountQuickSearchTab: {
              ...tabsTablesSearchState.fundedAccountQuickSearchTab,
              heliosWithdrawalsSearchTable: {
                search: { names: values.names },
              },
            },
          });
        }}
        isLoading={false}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={WITHDRAWALS_COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.account_name} />}
        csv_table_name="Funded account quick search - Withdrawals"
      />
    </div>
  );
};

export default HeliosWithdrawalsSearch;

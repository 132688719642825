/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import {
  CustomersByFailedPayment,
  DbTransactionsInfo,
  FailedStripeTransactions,
  ICharge,
  IGetAllChargesListResponseData,
} from "../api/charges/index.types";

export enum EChargesActionType {
  fetchRequest = "charges/FetchRequest",
  fetchSuccess = "charges/FetchSuccess",
  fetchFailed = "charges/FetchFailed",
  clearCharges = "charges/ClearCharges",
}

interface IChargesFetchRequest extends ReduxAction {
  type: EChargesActionType.fetchRequest;
}
interface IChargesFetchSuccess extends ReduxAction {
  type: EChargesActionType.fetchSuccess;
  payload: IGetAllChargesListResponseData;
}

interface IChargesFetchFetchFailed extends ReduxAction {
  type: EChargesActionType.fetchFailed;
}
interface IChargesClearCharges extends ReduxAction {
  type: EChargesActionType.clearCharges;
}

export type TChargesAction =
  | IChargesFetchRequest
  | IChargesFetchSuccess
  | IChargesFetchFetchFailed
  | IChargesClearCharges;

export const DayChargesActions = {
  fetchRequest: (): TChargesAction => ({
    type: EChargesActionType.fetchRequest,
  }),
  fetchSuccess: (dayCharges: IGetAllChargesListResponseData): TChargesAction => ({
    type: EChargesActionType.fetchSuccess,
    payload: dayCharges,
  }),
  fetchFailed: (): TChargesAction => ({
    type: EChargesActionType.fetchFailed,
  }),
  clearCharges: (): TChargesAction => ({
    type: EChargesActionType.clearCharges,
  }),
};

export interface IDayChargesState {
  data: {
    transactions: ICharge[];
    dbTransactionsInfo: DbTransactionsInfo[];
    failedStripeTransactions: FailedStripeTransactions[];
    customersByFailedPayment: CustomersByFailedPayment[];
    transactionsByEmail: ICharge[];
  };
  loading: boolean;
}

const initialState: IDayChargesState = {
  data: {
    transactions: [],
    dbTransactionsInfo: [],
    failedStripeTransactions: [],
    customersByFailedPayment: [],
    transactionsByEmail: [],
  },
  loading: false,
};

const chargesReducer = (prevState = initialState, action: TChargesAction): IDayChargesState =>
  produce(prevState, (draft: IDayChargesState): IDayChargesState => {
    switch (action.type) {
      case EChargesActionType.fetchRequest:
        draft.loading = true;
        break;
      case EChargesActionType.fetchSuccess:
        draft.loading = false;
        draft.data = action.payload;
        break;
      case EChargesActionType.fetchFailed:
        draft.loading = false;
        break;
      case EChargesActionType.clearCharges:
        draft.data = {
          transactions: [],
          dbTransactionsInfo: [],
          failedStripeTransactions: [],
          customersByFailedPayment: [],
          transactionsByEmail: [],
        };
        break;
      default:
        return draft;
    }
    return draft;
  });
export default chargesReducer;

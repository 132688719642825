import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  passedColor: {
    borderBottom: "1px solid #000",
    "& td": {
      backgroundColor: "#85E8583b !important",
    },
  },
  closeToPassingColor: {
    borderBottom: "1px solid #000",
    "& td": {
      backgroundColor: "#ffff003b !important",
    },
  },
  suspiciousAccountColor: {
    borderBottom: "1px solid #000",
    "& td": {
      backgroundColor: "#f15050 !important",
    },
  },
  plansDivider: {
    textAlign: "left",
    padding: "10px 0px 10px 15px",
  },
});

export default useStyles;

import React from "react";
import moment from "moment-timezone";
import { get } from "lodash";
import { Button, makeStyles } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { CustomerState } from "../../../reducers/customersReducer";
import downloadCsv from "../../../utils/downloadCsv";
import { useSearchCustomersContext, useAuthContext } from "../../../context";
import { DATE_FORMAT } from "../../../constants";
import { IntlFormatUSD } from "../../../utils";
import { getCustomer } from "../utils";

const useStyles = makeStyles({
  exportButton: {
    marginLeft: 10,
  },
});

const ExportCsv = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchCustomersContext = useSearchCustomersContext();
  const customerCount = useSelector((state) => state.customers.data.count);
  const { hasExpired } = useAuthContext();
  const customersData = useSelector((state) => state.customers.data);

  const { relatedTables } = searchCustomersContext;

  const handleGetCustomer = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    const customers = await getCustomer(
      searchCustomersContext,
      customerCount,
      hasExpired,
      customersData,
      true,
      dispatch,
      signal,
      true
    );
    if (customers) {
      handleExport(customers.accounts);
    }
  };
  const handleExport = (customers: CustomerState[]) => {
    const HEADER = ["Id", "Name", "Email", "Joined", "Country", "Active plan", "Next bill"];

    if (relatedTables.includes("billing_histories")) {
      HEADER.push("Billing history");
    }
    if (relatedTables.includes("account_notes")) {
      HEADER.push("Account notes");
    }
    if (relatedTables.includes("account_agreements")) {
      HEADER.push("Account agreement");
    }
    if (relatedTables.includes("account_flags")) {
      HEADER.push("Account flags");
    }

    if (relatedTables.includes("free_reset")) {
      HEADER.push("Fee reset");
    }

    if (relatedTables.includes("last4")) {
      HEADER.push("Last 4 digits");
    }

    const rows: string[][] = [HEADER];

    customers.forEach((data) => {
      const {
        id,
        name,
        email,
        joined,
        country,
        account_plans,
        billing_histories,
        account_notes,
        account_agreements,
        account_flags,
        free_reset,
        last4,
      } = data;
      const { expires } = account_plans;
      const tempRow = [
        id.toString(),
        name,
        email,
        moment(joined).utc().format(DATE_FORMAT),
        country.name,
        get(account_plans, "plan.name", ""),
        moment(expires).format(DATE_FORMAT),
      ];

      if (relatedTables.includes("billing_histories")) {
        tempRow.push(IntlFormatUSD(+billing_histories.total / 100).replace(",", "."));
      }

      if (relatedTables.includes("account_notes")) {
        tempRow.push(account_notes.note?.split("|||").join("   "));
      }

      if (relatedTables.includes("account_agreements")) {
        tempRow.push(account_agreements.status);
      }

      if (relatedTables.includes("account_flags")) {
        tempRow.push(account_flags.flag);
      }

      if (relatedTables.includes("free_reset")) {
        tempRow.push(free_reset);
      }
      if (relatedTables.includes("last4")) {
        let last4Values = "";
        last4.split(",").map((digit) => (last4Values += `'${digit}' `));
        tempRow.push(last4Values);
      }

      rows.push(tempRow);
    });
    downloadCsv(rows, "Customers");
  };

  return (
    <Button
      onClick={handleGetCustomer}
      variant="contained"
      color="primary"
      className={classes.exportButton}
    >
      Export CSV
    </Button>
  );
};

export default ExportCsv;

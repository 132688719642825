import React from "react";

import useStyles from "../../useStyles";
import TopLiveSimTakers from "../TopLiveSimTakers";
import TopLiveLosers from "../TopLiveLosers";

const TopLiveLosersAndLiveSimTakersTab: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableContainer}>
        <TopLiveSimTakers />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <TopLiveLosers />
      </div>
    </div>
  );
};

export default TopLiveLosersAndLiveSimTakersTab;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import {
  ContainerWithLoader,
  FilterAutocomplete,
  PaginationWithLimit,
} from "../../../../components/ui";

import { FUNDED_TRADER_STATUS, FUNDED_TRADERS, STATUSES_COLORS } from "../../constants";

import useStyles from "./useStyles";
import newSortBy from "../../../../utils/newSortBy";
import usePagination from "../../../../hooks/usePagination";
import useGeneralStyles from "../../../../useGeneralStyles";
import { APIFunds } from "../../../../api";
import { FundedTradersActions } from "../../../../reducers/fundedTradersReducer";
import { ISearchAndPaginateData } from "../../../../api/funds/index.types";
import { FUNDS_API_URLS } from "../../../../api/funds";
import StatusColorLegend from "../../../../components/ui/StatusColorLegend";
import ProcessingStatusFilterOptions from "../ProcessingStatusFilterOptions";

interface IProps {
  account_type?: ISearchAndPaginateData["account_type"];
  evaluation_type?: ISearchAndPaginateData["evaluation_type"];
}

const FundedTradersTable: React.FC<IProps> = ({ account_type, evaluation_type }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { limit, setLimit, setPage, page, setCount } = usePagination();
  const { loading: fundedTradersStatusesListLoading } = useSelector(
    (state) => state.fundedTradersStatusesList
  );

  const [search, setSearch] = useState<{
    processing_status: string[];
    isShowActive: boolean;
    isShowFailed: boolean;
  }>({
    processing_status: [
      "LiveSim:Live",
      "Duplicate",
      "Asked for Info",
      "Pro:Must Pay",
      "Ok Paperwork",
      "Ready for Review",
      "Reviewed",
      "Active Trading Account",
      "On Hold",
    ],
    isShowActive: true,
    isShowFailed: false,
  });

  const isActiveLiveSimOrSimTab = evaluation_type === "LiveSim" || evaluation_type === "Live";
  const [sortBy, setSortBy] = useState(
    isActiveLiveSimOrSimTab
      ? {
          by: "invoice_status",
          order: true,
        }
      : {
          by: "id",
          order: false,
        }
  );

  const {
    data: { funded_traders, count },
    isLoading,
  } = useSelector((state) => state.fundedTraders);

  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  const handleChangeProcessingStatus = (
    processing_status: string[],
    isShowActive: boolean,
    isShowFailed: boolean
  ) => {
    setSearch({
      ...search,
      processing_status,
      isShowActive,
      isShowFailed,
    });
  };

  useQuery({
    queryKey: [FUNDS_API_URLS.funds, page, limit, sortBy, search],
    queryFn: async () => {
      dispatch(FundedTradersActions.fetchFailedPaymentsRequest());
      const { data } = await APIFunds.getFundedTraders({
        account_type,
        evaluation_type,
        limit: +limit,
        page,
        sortBy,
        contains: search,
      });
      // dispatch(ForcedToLiveAccounts.fetchForcedToLiveAccountsSuccess(data.forced_to_live));
      dispatch(
        FundedTradersActions.fetchFailedPaymentsSuccess({
          funded_traders: data.funded_traders.rows,
          count: data.funded_traders.count,
        })
      );
      setCount(data.funded_traders.count);
      return data;
    },
    onError: (error) => {
      console.error("Error fetching funded traders", error);
      dispatch(FundedTradersActions.fetchFailedPaymentsFailed());
    },
  });

  return (
    <div>
      <div className={classes.searchContainer}>
        {/*@ts-ignore*/}
        <FilterAutocomplete search={search} setSearch={setSearch} filters={FUNDED_TRADERS} />
        <div className={classes.filterContainer}>
          <ProcessingStatusFilterOptions handleSearchChange={handleChangeProcessingStatus} />
        </div>
      </div>

      <div className={classes.legendContainer}>
        <div className={classes.legendInfo}>
          <span>Funded trader status legend:</span>
          <StatusColorLegend legend={FUNDED_TRADER_STATUS} />
        </div>
        <div className={classes.legendInfo}>
          <span>Invoice and processing status legend:</span>
          <StatusColorLegend legend={STATUSES_COLORS} />
        </div>
      </div>

      <ContainerWithLoader
        isLoading={isLoading || fundedTradersStatusesListLoading}
        report={funded_traders}
      >
        <div className={classes.tableContainer}>
          <table className={cx(generalClasses.table)}>
            <TableHeader sortBy={sortBy} handleSortBy={handleSortBy} />
            <TableBody data-test="funded-traders-table" />
          </table>
        </div>
      </ContainerWithLoader>

      <div>
        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPageState={setPage}
        />
      </div>
    </div>
  );
};

export default FundedTradersTable;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    marginBottom: 0,
  },
  iconsContainer: {
    position: "relative",
  },
  deleteIcon: {
    position: "absolute",
    bottom: -13,
    right: -6,
    cursor: "pointer",
  },
  deletingIcon: {
    color: "#e53935",
  },
});

export default useStyles;

import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import cx from "classnames";

import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import newSortBy from "../../utils/newSortBy";
import useGeneralStyles from "../../useGeneralStyles";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../api/compliance";
import { ComplianceWatchlistActions } from "../../reducers/compliance/actions";
import { ContainerWithLoader, PaginationWithLimit, FilterAutocomplete } from "../../components/ui";
import usePagination from "../../hooks/usePagination";

const ComplianceWatchlist = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const { data, loading } = useSelector((state) => state.complianceWatchlist.watchlist);

  const [search, setSearch] = useState({});
  const [sortBy, setSortBy] = useState({
    by: "id",
    order: false,
  });
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  useQuery({
    retry: false,
    queryKey: [COMPLIANCE_API_URLS.getAllNotes, pagination.page, pagination.limit, sortBy, search],
    async queryFn({ signal }) {
      dispatch(ComplianceWatchlistActions.fetchComplianceWatchlistRequest());

      const params = new URLSearchParams();
      params.set("page", pagination.page.toString());
      params.set("limit", pagination.limit);
      params.set("sort_by", sortBy.by);
      params.set("order", sortBy.order ? "ASC" : "DESC");
      Object.entries(search).forEach(([key, value]) => {
        if (value) {
          params.set(key, value as string);
        }
      });

      const response = await ComplianceAPI.getAllComplianceNotesRequest(params.toString(), signal);
      dispatch(ComplianceWatchlistActions.fetchComplianceWatchlistSuccess(response.data));
      pagination.setCount(response.data.count);
      return response.data;
    },
    onError(error) {
      dispatch(ComplianceWatchlistActions.fetchComplianceWatchlistFailed());
      console.error("Error getting compliance watchlist data", error);
    },
  });

  return (
    <div className={cx(generalClasses.containerBg, generalClasses.tableWithoutHover)}>
      <FilterAutocomplete
        search={search}
        setSearch={setSearch}
        filters={{
          Id: "id",
          Email: "email",
          Name: "name",
          Reason: "reason",
          Measure: "measure",
          Timestamp: "timestamp",
          "Measure  num": "measure_num",
        }}
        defaultSearchFields={{
          Email: "email",
          Name: "name",
        }}
        inputTypes={{
          Timestamp: "date",
        }}
      />
      <div>
        <p>
          Users found:<b>{data.count}</b>
        </p>
      </div>

      <ContainerWithLoader report={data.rows} isLoading={loading}>
        <table className={generalClasses.table}>
          <TableHead sortBy={sortBy} handleSortBy={handleSortBy} />
          <TableBody />
        </table>
      </ContainerWithLoader>
      <div>
        <PaginationWithLimit
          count={pagination.count}
          limit={pagination.limit}
          setLimit={pagination.setLimit}
          page={pagination.page}
          setPageState={pagination.setPage}
        />
      </div>
    </div>
  );
};

export default ComplianceWatchlist;

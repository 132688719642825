import React, { createContext, useContext, useEffect, useState } from "react";
import LogRocket from "logrocket";
import { useHistory } from "react-router-dom";

import { useAdminProviderDataContext } from "../AdminProviderData";
import apiClient from "../../api/apiClient";
import { APIAuth } from "../../api";

interface IProps {
  children: React.ReactNode;
}

interface ILoginData {
  email: string;
  password: string;
}

export interface AuthContextProps {
  token: string;
  loginAndQuery: (cb: () => void) => void;
  hasExpired: () => void;
  login: () => void;
  expired: boolean;
  loginData: ILoginData;
  setLoginData: (a: ILoginData) => void;
  expiredMessage: string;
  loginFeedback: string;
  isExpiredCloudFlareToken: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  token: "",
  loginAndQuery: (cb) => cb,
  hasExpired: () => ({}),
  login: () => ({}),
  expired: true,
  loginData: {
    email: "",
    password: "",
  },
  setLoginData: () => ({}),
  expiredMessage: "",
  loginFeedback: "",
  isExpiredCloudFlareToken: false,
});

const AuthProvider = ({ children }: IProps): React.ReactElement => {
  const history = useHistory();
  const [loginFeedback, setLoginFeedback] = useState<string>("");
  const [expiredMessage, setExpiredMessage] = useState<string>("");
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [isExpiredCloudFlareToken, setExpiredCloudFlareToken] = useState(false);

  const [loginData, setLoginData] = useState<ILoginData>({
    email: "",
    password: "",
  });
  const [expired, setExpired] = useState<boolean>(false);

  const { setUser } = useAdminProviderDataContext();

  useEffect(() => {
    if (expired) {
      // setExpiredMessage("Please log into Cloudflare");
      setLoginFeedback("");
      setToken("");
      setExpired(false);
    }
  }, [expired]);

  useEffect(() => {
    if (token !== "") {
      setExpiredMessage("");
    }
  }, [token]);

  const login = (): Promise<string> => {
    if (token === "") {
      setLoginFeedback("Authenticating...");
      return new Promise<string>((resolve, reject) =>
        APIAuth.loginRequest(loginData)
          .then((response) => {
            // @ts-ignore
            apiClient.defaults.headers.Authorization = `Bearer ${response.headers.token}`;
            const { id, name, email, allowed_features } = response.data;
            // @ts-ignore
            setUser({ id, name, email, allowed_features });
            // @ts-ignore
            LogRocket.identify(id, {
              name,
              email,
            });

            localStorage.setItem("Token", response.headers.token);
            // @ts-ignore
            localStorage.setItem("Id", id);
            localStorage.setItem("Name", name);
            localStorage.setItem("Email", email);

            setToken(response.headers.token);
            setLoginFeedback("Success!");
            const { access_withdrawals, access_funded_traders, access_all_features } =
              allowed_features;
            const isHeliosAgent = access_withdrawals || access_funded_traders;
            if (isHeliosAgent && !access_all_features) {
              history.push("/fundedTraders");
            } else {
              history.push("/");
            }
            return resolve(response.headers.token);
          })
          .catch((error) => {
            console.error("Error CF");
            console.log("!error.response", !error.response);
            if (!error.response) {
              // if (process.env.ENVOY_ENV === "production") {
              setLoginFeedback("Error! please reload the page");
              return reject;
              // }
            }
            setLoginFeedback("Error!");
            return reject;
          })
      );
    }
    return new Promise<string>((resolve) => {
      setToken("");
      setLoginFeedback("");
      localStorage.clear();
      resolve();
    });
  };

  const loginAndQuery = async (callback: (token: string) => void) => {
    let usedToken = token;
    if (usedToken === "") {
      usedToken = await login();
    }
    callback(usedToken);
  };

  const hasExpired = () => setExpired(true);

  useEffect(() => {
    apiClient.interceptors.response.use(
      (config) => config,
      (error) => {
        if (error.code === "ERR_NETWORK") {
          setExpiredCloudFlareToken(true);
        }
        if (!error.response) {
          // Detach CORS error, happened if cloud flare token is expired
          // (error.message !== "canceled") Prevent redirect when used AbortController.abort()
          if (error.message !== "canceled") {
            setExpiredCloudFlareToken(true);
            window.location.href = "https://admin.earn2trade.com/";
          }
        }
        if (error.response?.status === 401 && error.response?.data?.name === "TokenExpiredError") {
          hasExpired();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        loginAndQuery,
        hasExpired,
        login,
        expired,
        loginData,
        setLoginData,
        expiredMessage,
        loginFeedback,
        isExpiredCloudFlareToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuthContext = (): AuthContextProps => useContext(AuthContext);

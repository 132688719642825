import * as Yup from "yup";

export const NEW_AGENT_VALIDATE_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email(({ originalValue }) => `"${originalValue}" is invalid email `)
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  preset: Yup.string().required("Preset is required"),
});

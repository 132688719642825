import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, TextField, CircularProgress } from "@material-ui/core";

import { APIRithmicFiles } from "../../../../api";
import CsvView from "../CsvView";
import ErrorsInfo from "../ErrorsInfo";
import * as Types from "../../index.types";

interface IProps {
  folderPath: Types.TFolderPath;
  title: string;
}
export const REG_EXP_DOUBLE_QUOTES = /['"]+/g;

const Logs: React.FC<IProps> = ({ folderPath, title }) => {
  const [loading, setLoading] = useState(false);

  const [archiveFiles, setArchiveFiles] = useState<string[]>([]);
  const [fileContent, setFileContent] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [csv, setCsv] = useState<Types.TCsv>();
  const [updatedTime, setUpdatedTime] = useState("");
  const fetchArchiveFiles = async (signal: AbortSignal) => {
    try {
      setLoading(true);
      const response = await APIRithmicFiles.searchFiles(
        { file_name: "", folder_path: folderPath },
        signal
      );

      setUpdatedTime(response.data.updatedTime);
      setArchiveFiles(response.data.results);
    } catch (error) {
      console.error("Error fetching archive files list", error);
    } finally {
      setLoading(false);
    }
  };
  const downloadFile = async (file_name: string) => {
    try {
      setLoading(true);
      setSelectedFileName(file_name);
      const response = await APIRithmicFiles.loadFile({ file_name, folder_path: folderPath });
      setFileContent(response.data);
    } catch (error) {
      console.log("Error downloading file", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    (async () => {
      await fetchArchiveFiles(signal);
    })();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (fileContent.length) {
      const rows = fileContent.split("\n");
      rows.pop();

      const csv: Types.TCsv = [];
      rows.forEach((row) => {
        const columns = row.split(",");
        const data = columns.map((c) => ({
          value: c,
          ...(folderPath === "errors" && { disableEvents: true }),
        }));
        csv.push(data);
      });
      setCsv(csv);
    } else {
      setFileContent("");
      setCsv([]);
    }
  }, [fileContent]);

  return (
    <div>
      <h4>{title}</h4>
      <p>{updatedTime} (America/Chicago)</p>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            options={archiveFiles}
            getOptionLabel={(option) => option}
            loading={loading}
            onChange={(_, newValue) => downloadFile(newValue as string)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {csv && (
            <>
              <ErrorsInfo csv={csv} />
              <CsvView currentFileName={selectedFileName} csv={csv} folderPath={folderPath} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Logs;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { SVGArrow } from "../../svgs";
import { ISortBy } from "../../../types";

interface Props {
  sortBy: ISortBy;
  by: string;
  invert?: boolean;
}

const useStyles = makeStyles({
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
});

const SortArrow: React.FC<Props> = ({ sortBy, by, invert }) => {
  let { order } = sortBy;
  if (invert) {
    order = !sortBy.order;
  }
  const classes = useStyles();
  if (sortBy.by === by) {
    return order ? (
      <span className={classes.icon}>
        <SVGArrow rotate="90deg" r="3" />
      </span>
    ) : (
      <span className={classes.icon}>
        <SVGArrow rotate="-90deg" r="3" />
      </span>
    );
  }
  return null;
};

export default SortArrow;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ct from "countries-and-timezones";

import "./styles.scss";
import { useCustomerProvider, useAdminProviderDataContext } from "../../context";
import { APIAgentActivityLog, APIIntercom } from "../../api";

import {
  TimeNow,
  CustomerTitle,
  CustomerStatus,
  MainCustomerInfo,
  Snackbar,
} from "../../components/ui";

import {
  Last5Logins,
  ChargesTable,
  LoginsHistory,
  SimilarAccounts,
  RithmicDataTabs,
  E2TAccountsList,
  EvaluationJourney,
  AccountManagement,
  EditIntercomValues,
  CustomerHistoryLogs,
  AssignedDiscountCodes,
  CreateFinamarkUserWindow,
  ManagementConfirmWindows,
  UserPlans,
  ExtendFinamarkUserConfirmWindow,
  // URMAManagement
} from "./components";

export interface IFinamark {
  days: string | number;
  user: string;
  pass: string;
  sys: string;
  gway: string;
  id: string;
}
export interface Plan {
  id: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: any; // TODO fix any type
}
export enum StatusList {
  chargeback = "chargeback",
  refunded = "refunded",
  fraud = "fraud",
}

const Customer = (): React.ReactElement => {
  const { id } = useParams();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const { customer, setCustomer, fina } = useCustomerProvider();

  const [popup, setPopup] = useState("");

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(true);
  const [finamarkResultStatusRequest, setFinamarkResultStatusRequest] = useState<
    "error" | "success" | ""
  >("");

  const handleCloseSnackbar = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
    setFinamarkResultStatusRequest("");
  };

  const extendFinamark = async () => {
    try {
      await APIIntercom.extendFinamarkRequest(customer.email, fina.days);
      await APIAgentActivityLog.extendAccountLogRequest({
        days: fina.days,
        accounts_id: customer.id,
      });
      setFinamarkResultStatusRequest("success");
      setIsOpenSnackbar(true);
    } catch (error) {
      setFinamarkResultStatusRequest("error");
      setIsOpenSnackbar(true);
    }
  };

  const createFinamark = async () => {
    try {
      await APIIntercom.createFinamarkRequest(customer, fina);
      await APIAgentActivityLog.createAccountLoginRequest({
        accounts_id: customer.id,
        gway: fina.gway,
        sys: fina.sys,
        user: fina.user,
      });
      setFinamarkResultStatusRequest("success");
      setIsOpenSnackbar(true);
    } catch (error) {
      setFinamarkResultStatusRequest("error");
      setIsOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const tz = ct.getCountry(customer.country?.iso2 ? customer.country.iso2 : "HU");
    setCustomer({
      ...customer,
      country: { ...customer.country, timezone: tz },
    });
  }, [customer.country?.iso2]);

  return (
    <CustomerStatus>
      <CreateFinamarkUserWindow createFinamark={createFinamark} setPopup={setPopup} popup={popup} />
      <ExtendFinamarkUserConfirmWindow
        extendFinamark={extendFinamark}
        popup={popup}
        setPopup={setPopup}
      />
      <CustomerTitle />
      <MainCustomerInfo />
      <EvaluationJourney />
      <br />
      <ManagementConfirmWindows setPopup={setPopup} />
      <UserPlans />
      <AccountManagement />
      {!!customer.id && (
        <ChargesTable
          stripeCustomerId={customer.stripe_customer}
          accountsId={id}
          email={customer.email}
        />
      )}
      <AssignedDiscountCodes />
      <Last5Logins />
      <br />
      {allowed_features.access_rithmic_accounts && <E2TAccountsList email={customer.email} />}
      <br />
      <SimilarAccounts />
      <br />
      Chicago time now: <TimeNow />
      <EditIntercomValues />
      {allowed_features.access_rithmic_customer_info && <RithmicDataTabs />}
      <CustomerHistoryLogs />
      <LoginsHistory />
      {/* <URMAProvider> */}
      {/*  <URMAManagement /> */}
      {/* </URMAProvider> */}
      <Snackbar
        type={finamarkResultStatusRequest}
        isOpenSnackbar={isOpenSnackbar}
        responseSuccessMessage="Success"
        responseErrorMessage="Error"
        onClose={handleCloseSnackbar}
      />
    </CustomerStatus>
  );
};

export default Customer;

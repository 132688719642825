import { makeStyles } from "@material-ui/core";
import {
  FAILED_OR_BANNED_STATUS_COLOR,
  IN_PROGRESS_STATUS_COLOR,
  INACTIVE_STATUS_COLOR,
  ON_HOLD_STATUS_COLOR,
  READY_STATUS_COLOR,
} from "./utils";

const useStyles = makeStyles({
  td: {
    height: 40,
  },
  invoiceStatus: {
    height: 40,
  },
  e2tAccountID: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fullWidth: {
    width: "100%",
    "& td": {
      background: "none !important",
    },
  },
  table: {
    borderCollapse: "collapse",
  },
  accountsIdContainer: {
    display: "block",
    padding: "7px 10px",
    borderRadius: 16,
    minWidth: "100%",
    textAlign: "center",
  },
  regularStatus: {
    backgroundColor: `${READY_STATUS_COLOR} !important`,
  },
  failedReviewStatus: {
    backgroundColor: `${FAILED_OR_BANNED_STATUS_COLOR} !important`,
  },
  inactiveStatus: {
    backgroundColor: `${INACTIVE_STATUS_COLOR} !important`,
  },
  onHoldOrBannedStatus: {
    backgroundColor: `${ON_HOLD_STATUS_COLOR} !important`,
  },
  inProgressStatus: {
    backgroundColor: `${IN_PROGRESS_STATUS_COLOR} !important`,
  },
  readyStatus: {
    backgroundColor: `${READY_STATUS_COLOR} !important`,
  },
  failedStatus: {
    backgroundColor: `${FAILED_OR_BANNED_STATUS_COLOR} !important`,
  },
});

export default useStyles;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";

import HTMLEditor from "../../../../components/ui/HTMLEditor";
import { IEditingHTML } from "../../index";

const StyledTextField = styled(TextField)`
  margin-bottom: 10px;
`;

interface IProps {
  isOpen: boolean;
  setEditing: (a: boolean) => void;
  editingHtml: {
    label: string;
    html: string;
    id: number;
  };
  setEditingHtml: (a: IEditingHTML) => void;
  handleCloseEditing: () => void;
  handleSave: () => void;
  isUpdating: boolean;
}
const EditMessageDialog = ({
  isOpen,
  setEditing,
  editingHtml,
  handleCloseEditing,
  handleSave,
  isUpdating,
  setEditingHtml,
}: IProps): React.ReactElement => {
  const onChangeEditor = (html: string) => {
    setEditingHtml({
      ...editingHtml,
      html,
    });
  };
  const onChangeLabel = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditingHtml({
      ...editingHtml,
      label: event.target.value,
      id: editingHtml.id,
    });
  };

  return (
    <Dialog open={isOpen} onClose={() => setEditing(false)}>
      <DialogTitle>Edit message</DialogTitle>
      <DialogContent>
        <StyledTextField
          onChange={onChangeLabel}
          name="label"
          id="label"
          label="Label"
          variant="outlined"
          value={editingHtml.label}
        />

        <HTMLEditor html={editingHtml.html} onChangeEditor={onChangeEditor} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseEditing} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined" disabled={isUpdating}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMessageDialog;

import React, { useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core/";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useGeneralStyles from "../../../../../useGeneralStyles";

import TradedAccountsInfoTableColumns from "../../TradedAccountsInfoTableColumns";
import { ConfirmDialog } from "../../../../../components/ui";
import { FUNDS_API_URLS } from "../../../../../api/funds";
import { APIFunds } from "../../../../../api";
import FundedTraderStatus from "../../FundedTraderStatus";

export interface IDialogConfirmProps {
  isOpen: boolean;
  dialogTitle: string;
  dialogContentText: string;
  id: number;
  accounts_id: number;
  name: string;
  value: string;
  status_id?: number;
  evaluation_type?: string;
  isEditInitialProcStatusAndInvoiceStatus: boolean;
}

const dialogConfirmPropsInitialState: IDialogConfirmProps = {
  isOpen: false,
  dialogTitle: "",
  dialogContentText: "",
  id: -1,
  accounts_id: -1,
  name: "",
  value: "",
  isEditInitialProcStatusAndInvoiceStatus: false,
};

const TableBody = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const queryClient = useQueryClient();

  const {
    data: { funded_traders },
  } = useSelector((state) => state.fundedTraders);

  const [dialogConfirmProps, setDialogConfirmProps] = useState<IDialogConfirmProps>(
    dialogConfirmPropsInitialState
  );

  const handleChangeFundedTraderAccount = async () => {
    try {
      const { accounts_id, status_id, evaluation_type, isEditInitialProcStatusAndInvoiceStatus } =
        dialogConfirmProps;

      await APIFunds.updateFundedtraderStatusRequest({
        accounts_id,
        status_id: status_id as number,
        ...(evaluation_type && { evaluation_type }),
        ...(isEditInitialProcStatusAndInvoiceStatus && {
          [dialogConfirmProps.name]: dialogConfirmProps.value,
        }),
      });
      await queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.funds] });
    } catch (error) {
      console.error("Error while updating status", error);
    }
  };

  const handleEditTradingAccount = useMutation({
    mutationKey: [FUNDS_API_URLS.update_traded_accounts],
    mutationFn: () => {
      return APIFunds.updateAccountProcessingStatus(dialogConfirmProps.accounts_id, {
        id: dialogConfirmProps.id,
        accounts_id: dialogConfirmProps.accounts_id,
        [dialogConfirmProps.name as string]: dialogConfirmProps.value,
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.funds] }),
  });

  return (
    <tbody>
      <ConfirmDialog
        isOpen={dialogConfirmProps.isOpen}
        dialogTitle={dialogConfirmProps.dialogTitle}
        dialogContentText={dialogConfirmProps.dialogContentText}
        handleCancel={() => {
          setDialogConfirmProps(dialogConfirmPropsInitialState);
        }}
        handleClickOk={() => {
          if (
            dialogConfirmProps.isEditInitialProcStatusAndInvoiceStatus ||
            dialogConfirmProps.status_id ||
            dialogConfirmProps.evaluation_type
          ) {
            handleChangeFundedTraderAccount();
            return;
          }
          handleEditTradingAccount.mutate();
        }}
      />

      {funded_traders.map(
        ({
          id,
          accounts_id,
          account,
          traded_accounts,
          funded_trader_status,
          evaluation_type,
          is_forced_to_live,
          invoice_status,
          processing_status,
        }) => {
          return (
            <tr key={id} className={cx(generalClasses.tableHover)}>
              <td>{accounts_id}</td>
              <td>
                <MenuItem component={Link} to={`fundedTraders/${accounts_id}`}>
                  {account.name}
                </MenuItem>
              </td>
              <td>{account.email}</td>

              <FundedTraderStatus
                setDialogConfirmProps={setDialogConfirmProps}
                accounts_id={accounts_id}
                funded_trader_status={funded_trader_status}
                evaluation_type={evaluation_type}
                is_forced_to_live={is_forced_to_live}
              />

              <TradedAccountsInfoTableColumns
                traded_accounts={traded_accounts}
                setDialogConfirmProps={setDialogConfirmProps}
                id={accounts_id}
                invoice_status={invoice_status}
                processing_status={processing_status}
              />
            </tr>
          );
        }
      )}
    </tbody>
  );
};

export default TableBody;

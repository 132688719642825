import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  notApplicable: {
    color: "gray",
  },
  onTimeInactive: {
    color: "red",
  },
  active: {
    color: "green",
  },
  buttonRoot: {
    maxWidth: 75,
  },
  tableRow: {
    height: 52,
  },
  expiredInactiveCodeBg: {
    backgroundColor: "#a6a3a3",
  },
  actionIconsContainer: {
    display: "flex",
    flexDirection: "column",
    height: "inherit",
    justifyContent: "space-around",
    marginTop: 5,
  },
  actionIcon: {
    fontSize: "20px",
    transition: "color 300ms",
    "&:hover": {
      color: "#0a0a0a",
      cursor: "pointer",
    },
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
  },
  savaButton: {
    marginLeft: 3,
  },
  datePicker: {
    "& .MuiOutlinedInput-root": {
      paddingRight: 0,
    },
  },
});

export default useStyles;

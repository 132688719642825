import { ChartProps } from "react-chartjs-2";

import { IntlFormatUSD } from "../../../../utils";

export const CHART_OPTIONS: ChartProps<"line", number[], string>["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: { borderWidth: 1 },
    point: { radius: 2 },
  },
  plugins: {
    legend: {
      align: "start",
    },
    datalabels: {
      formatter(value: number) {
        return null;
      },
    },
    tooltip: {
      callbacks: {
        label(value) {
          const { raw, dataset } = value;
          return `${dataset.label}: ${IntlFormatUSD(raw as number)}`;
        },
      },
    },
  },
};

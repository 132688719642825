import httpClient from "../apiClient";
import * as Type from "./index.types";

export const G_DRIVE_API_URLS = {
  getCustomerFiles: "/g-drive/get-trader-files",
  uploadCustomerFiles: "/g-drive/upload-trader-files",
  deleteCustomerFile: "/g-drive/trash-trader-file",
} as const;

export class GoogleDriveAPI {
  static getCustomerFiles: Type.TGetCustomerFiles = (id) =>
    httpClient.post(`${G_DRIVE_API_URLS.getCustomerFiles}/${id}`);
  static uploadCustomerFiles: Type.TUploadCustomerFiles = (id, data) =>
    httpClient.post(`${G_DRIVE_API_URLS.uploadCustomerFiles}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  static deleteCustomerFile: Type.TDeleteCustomerFile = (id, body) =>
    httpClient.post(`${G_DRIVE_API_URLS.deleteCustomerFile}/${id}`, body);
}

import React, { ChangeEvent } from "react";
import { Field, FormikHelpers } from "formik";
import { Button, Dialog, makeStyles } from "@material-ui/core";

import { ICode } from "../types";
import { getSuffix } from "../utils";
import { FormInput } from "../../index";

interface IProps {
  code: ICode;
  submitForm: () => void;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
  isSavingCode: boolean;
}

const useStyles = makeStyles({
  container: {
    padding: 30,
    background: "rgb(247, 247, 247)",
  },
  buttonContainer: {
    marginTop: 5,
    display: "flex",
    justifyContent: "space-between",
  },
});

const BatchCreateNTimes = ({
  code,
  submitForm,
  setFieldValue,
  isSavingCode,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const handleClickCancel = () => setFieldValue("isBatchCreate", false);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const suffix = getSuffix(e.target.value);
    setFieldValue("suffix", suffix);
    setFieldValue("times", +e.target.value);
  };

  return (
    <Dialog open={code.isBatchCreate}>
      <div className={classes.container}>
        <Field
          component={FormInput}
          name="times"
          type="number"
          label="N times"
          onChange={handleChange}
        />
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!code.times || isSavingCode}
            onClick={submitForm}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickCancel}
            disabled={isSavingCode}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default BatchCreateNTimes;

import { DiscountCodeState } from "../../api/discounts/index.types";

export const DISCOUNT_CODES_FILTERS = {
  // key - drop down value, value search value to send
  Code: "code",
  Description: "description",
  User: "user",
  Discount: "discount",
  Quantity: "quantity",
  LifeTime: "lifeTime",
  Plan: "plan",
  Price: "price",
  Type: "type",
  Active: "active",
  Accepted: "accepted",
  "Created by": "createdBy",
  Expires: "expires",
  "Discount Applied Until": "expiration",
  "Show expired": "showExpired",
};

export const INPUT_TYPES = {
  Quantity: "number",
  Discount: "number",
  Price: "number",
  LifeTime: "number",
  Expires: "date",
  "Show expired": "number",
  "Discount Applied Until": "date",
};

export const DISCOUNT_CODES_LOGS_FILTERS = {
  // key - drop-down value, value search value to send
  Code: "code",
  Admin: "admin",
  Field: "field_name",
  "Previous value": "previous_field_value",
  "New value": "new_field_value",
};

export const AGENT_LOGS_ACTIVITY_FILTERS = {
  Agent: "admin",
  Customer: "customer",
  Code: "code",
} as const;

export const AGENT_LOGS_ACTIVITY_FILTERS_FOR_MANAGERS = {
  "Updated agent": "updated_agent",
} as const;

export const CREATED_DISCOUNT_CODE_INITIAL_STATE: DiscountCodeState = {
  code_count: 0,
  active: false,
  amt: 0,
  code: "",
  description: "",
  plan: {
    name: "",
    price_usd: 0,
    id: -1,
  },
  plan_id: -1,
  qty: null,
  type: "",
  id: -1,
  one_time: false,
  expires: null,
  account: {
    id: -1,
    name: "",
    email: "",
  },
  reset: null,
  price: 0,
  accepted: null,
  ac: {
    createdBy: null,
  },
  discount: 0,
  created: null,
  desc: "",
  accounts_id: null,
  expiration: null,
  failover_amt: null,
  failover_type: null,
  altered: null,
};

import { TTypeMessage } from "../../../../components/ui/Snackbar";

type ACTION_TYPE =
  | { type: "openDialog" }
  | { type: "closeDialog" }
  | {
      type: "showSnackbar";
      payload: {
        snackbarType: TTypeMessage;
        isOpenSnackbar: boolean;
        responseErrorMessage?: string;
      };
    };
type InitialState = {
  snackbarType: TTypeMessage;
  responseErrorMessage: string;
  isOpenSnackbar: boolean;
  isOpenDialog: boolean;
};
export const initialState: InitialState = {
  snackbarType: "",
  responseErrorMessage: "",
  isOpenSnackbar: false,
  isOpenDialog: false,
};

const stateReducer: StateReducer<typeof initialState, ACTION_TYPE> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "openDialog":
      return { ...state, isOpenDialog: true };
    case "closeDialog":
      return { ...state, isOpenDialog: false };
    case "showSnackbar":
      return {
        ...state,
        isOpenSnackbar: true,
        snackbarType: action.payload.snackbarType,
        responseErrorMessage: action.payload.responseErrorMessage || "",
      };
    default:
      return state;
  }
};

export default stateReducer;

import React from "react";
import cx from "classnames";

import useStyles from "./useStyles";
import SortArrow from "../../../../components/ui/SortArrow";
import { useDiscountsContext } from "../../../../context";
import newSortBy from "../../../../utils/newSortBy";

const TableHeader = (): React.ReactElement => {
  const classes = useStyles();
  const { sortBy, setSortBy, columns } = useDiscountsContext();
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        {sortBy.by !== "id" && (
          <td className={cx("canSort", classes.alignRight)}>
            <div onClick={() => handleSortBy("id")} role="button" tabIndex={-1}>
              R
            </div>
          </td>
        )}

        <td className={classes.alignCenter}>
          <div className={classes.wrapper}>#</div>
        </td>

        {columns.includes("code") && (
          <td
            className={cx("canSort", classes.alignRight, {
              [classes.alignCenter]: columns.length <= 2,
            })}
          >
            <div
              className={classes.wrapper}
              onClick={() => handleSortBy("code")}
              role="button"
              tabIndex={-1}
            >
              <SortArrow sortBy={sortBy} by="code" invert />
              Code
            </div>
          </td>
        )}

        {columns.includes("description") && (
          <td className="canSort">
            <div
              className={classes.wrapper}
              onClick={() => handleSortBy("description")}
              role="button"
              tabIndex={-1}
            >
              Description
              <SortArrow sortBy={sortBy} by="description" invert />
            </div>
          </td>
        )}

        {columns.includes("user") && (
          <td>
            <div className={classes.wrapper}>
              User
              <SortArrow sortBy={sortBy} by="user" invert />
            </div>
          </td>
        )}

        {columns.includes("discount") && <td className={classes.alignRight}>Discount</td>}

        {columns.includes("discount_past_expiration") && (
          <td className={classes.alignRight}>Discount (Past Expiration)</td>
        )}

        {columns.includes("type") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={classes.wrapper}
              onClick={() => handleSortBy("type")}
              role="button"
              tabIndex={-1}
            >
              Type
              <SortArrow sortBy={sortBy} by="type" invert />
            </div>
          </td>
        )}

        {columns.includes("price") && <td>Price</td>}

        {columns.includes("plan") && <td>Plan</td>}

        {columns.includes("used") && <td>Used</td>}

        <td className={cx("canSort", classes.alignCenter)}>
          <div
            className={classes.wrapper}
            onClick={() => handleSortBy("code_count")}
            tabIndex={-1}
            role="button"
          >
            Quantity
            <SortArrow sortBy={sortBy} by="code_count" invert />
          </div>
        </td>

        {columns.includes("active") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={cx("canSort", classes.wrapper)}
              onClick={() => handleSortBy("active")}
              role="button"
              tabIndex={-1}
            >
              Active
              <SortArrow sortBy={sortBy} by="active" invert />
            </div>
          </td>
        )}

        {columns.includes("expires") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={classes.wrapper}
              onClick={() => handleSortBy("expires")}
              tabIndex={-1}
              role="button"
            >
              Expires
              <SortArrow sortBy={sortBy} by="expires" invert />
            </div>
          </td>
        )}

        {columns.includes("expiration") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={cx("canSort", classes.wrapper)}
              onClick={() => handleSortBy("expiration")}
              role="button"
              tabIndex={-1}
            >
              Discount Duration / Termination Date
              <SortArrow sortBy={sortBy} by="expiration" invert />
            </div>
          </td>
        )}

        {columns.includes("lifetime") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={classes.wrapper}
              onClick={() => handleSortBy("one_time")}
              tabIndex={-1}
              role="button"
            >
              Lifetime
              <SortArrow sortBy={sortBy} by="one_time" invert />
            </div>
          </td>
        )}

        {columns.includes("accepted") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={cx("canSort", classes.wrapper)}
              role="button"
              tabIndex={-1}
              onClick={() => handleSortBy("accepted")}
            >
              Accepted
              <SortArrow sortBy={sortBy} by="accepted" invert />
            </div>
          </td>
        )}

        {columns.includes("createdBy") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={cx("canSort", classes.wrapper)}
              role="button"
              tabIndex={-1}
              onClick={() => handleSortBy("ac.createdBy")}
            >
              Created by
              <SortArrow sortBy={sortBy} by="ac.createdBy" invert />
            </div>
          </td>
        )}
        {columns.includes("created") && (
          <td className={cx("canSort", classes.alignCenter)}>
            <div
              className={cx("canSort", classes.wrapper)}
              role="button"
              tabIndex={-1}
              onClick={() => handleSortBy("created")}
            >
              Created
              <SortArrow sortBy={sortBy} by="created" invert />
            </div>
          </td>
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;

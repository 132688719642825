import { ReduxAction } from "../../static-types";
import {
  IFundedAccountNote,
  IFundedTrader,
  IGetFundedAccountNotesResponse,
  IGetFundedTraderDataResponse,
  ITradedAccount,
} from "../../api/funds/index.types";
import { FundedAccountDocumentDates } from "../../pages/FundedTrader/components/DocumentDatesForm";
import { IGetCustomerEvaluationCompleteHistoryResponse } from "../../api/evaluations/index.types";

export type FundedTraderAction =
  //TraderAccounts
  | FundedTraderAccountsFetchRequest
  | FundedTraderAccountsFetchSuccess
  | FundedTraderAccountsFetchFailed
  // TraderData
  | FundedTraderDataFetchRequest
  | FundedTraderDataFetchSuccess
  | FundedTraderDataFetchFailed
  //TraderDocumentsDates
  | FundedTraderDocumentsDatesFetchRequest
  | FundedTraderDocumentsDatesFetchSuccess
  | FundedTraderDocumentsDatesFetchFailed
  // TraderNotes
  | FundedTraderNotesFetchRequest
  | FundedTraderNotesFetchSuccess
  | FundedTraderNotesFetchFailed
  // EvaluationCompletedHistory
  | FundedTraderEvaluationCompletedHistoryFetchRequest
  | FundedTraderEvaluationCompletedHistoryFetchSuccess
  | FundedTraderEvaluationCompletedHistoryFetchFailed
  // EvaluationCompletedActiveAndRecentAccounts
  | FundedTraderPassesActiveAndRecentAccountsFetchRequest
  | FundedTraderPassesActiveAndRecentAccountsFetchSuccess
  | FundedTraderPassesActiveAndRecentAccountsFetchFailed;

export enum FundedTraderActionType {
  //TraderAccounts
  fundedTraderAccountsFetchRequest = "fundedTraderAccounts/FetchRequest",
  fundedTraderAccountsFetchSuccess = "fundedTraderAccounts/FetchSuccess",
  fundedTraderAccountsFetchFailed = "fundedTraderAccounts/FetchFailed",
  // TraderData
  fundedTraderDataFetchRequest = "fundedTraderData/FetchRequest",
  fundedTraderDataFetchSuccess = "fundedTraderData/FetchSuccess",
  fundedTraderDataFetchFailed = "fundedTraderData/FetchFailed",
  //TraderDocumentsDates
  fundedTraderDocumentsDatesFetchRequest = "fundedTraderDocumentsDates/FetchRequest",
  fundedTraderDocumentsDatesFetchSuccess = "fundedTraderDocumentsDates/FetchSuccess",
  fundedTraderDocumentsDatesFetchFailed = "fundedTraderDocumentsDates/FetchFailed",
  // TraderNotes
  fundedTraderNotesFetchRequest = "fundedTraderNotes/FetchRequest",
  fundedTraderNotesFetchSuccess = "fundedTraderNotes/FetchSuccess",
  fundedTraderNotesFetchFailed = "fundedTraderNotes/FetchFailed",
  // EvaluationCompletedHistory
  fundedTraderEvaluationCompletedHistoryFetchRequest = "fundedTraderEvaluationCompletedHistory/FetchRequest",
  fundedTraderEvaluationCompletedHistoryFetchSuccess = "fundedTraderEvaluationCompletedHistory/FetchSuccess",
  fundedTraderEvaluationCompletedHistoryFetchFailed = "fundedTraderEvaluationCompletedHistory/FetchFailed",
  // EvaluationCompletedActiveAndRecentAccounts
  fundedTraderPassesActiveAndRecentAccountsFetchRequest = "fundedTraderPassesActiveAndRecentAccounts/FetchRequest",
  fundedTraderPassesActiveAndRecentAccountsFetchSuccess = "fundedTraderPassesActiveAndRecentAccounts/FetchSuccess",
  fundedTraderPassesActiveAndRecentAccountsFetchFailed = "fundedTraderPassesActiveAndRecentAccounts/FetchFailed",
}

export interface FundedTraderAccountsFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderAccountsFetchRequest;
}
export interface FundedTraderAccountsFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderAccountsFetchSuccess;
  payload: ITradedAccount[];
}
export interface FundedTraderAccountsFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderAccountsFetchFailed;
}
// TraderData
export interface FundedTraderDataFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDataFetchRequest;
}
export interface FundedTraderDataFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDataFetchSuccess;
  payload: IGetFundedTraderDataResponse["data"];
}
export interface FundedTraderDataFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDataFetchFailed;
}
//TraderDocumentsDates
export interface FundedTraderDocumentsDatesFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDocumentsDatesFetchRequest;
}
export interface FundedTraderDocumentsDatesFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDocumentsDatesFetchSuccess;
  payload: FundedAccountDocumentDates;
}
export interface FundedTraderDocumentsDatesFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderDocumentsDatesFetchFailed;
}
// TraderNotes
export interface FundedTraderNotesFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderNotesFetchRequest;
}
export interface FundedTraderNotesFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderNotesFetchSuccess;
  payload: IGetFundedAccountNotesResponse;
}
export interface FundedTraderNotesFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderNotesFetchFailed;
}
// EvaluationCompletedHistory
export interface FundedTraderEvaluationCompletedHistoryFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchRequest;
}
export interface FundedTraderEvaluationCompletedHistoryFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchSuccess;
  payload: IGetCustomerEvaluationCompleteHistoryResponse["data"];
}
export interface FundedTraderEvaluationCompletedHistoryFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchFailed;
}
// EvaluationCompletedActiveAndRecentAccounts
export interface FundedTraderPassesActiveAndRecentAccountsFetchRequest extends ReduxAction {
  type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchRequest;
}
export interface FundedTraderPassesActiveAndRecentAccountsFetchSuccess extends ReduxAction {
  type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchSuccess;
  payload: IGetCustomerEvaluationCompleteHistoryResponse["data"];
}
export interface FundedTraderPassesActiveAndRecentAccountsFetchFailed extends ReduxAction {
  type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchFailed;
}

export interface IFundedTraderState extends IFundedTrader {
  tradedAccounts: {
    data: ITradedAccount[];
    isLoading: boolean;
  };
  documentsDates: {
    data: FundedAccountDocumentDates;
    isLoading: boolean;
  };
  isLoading: boolean;
  fundedAccounts: ITradedAccount[];
  priorAccounts: ITradedAccount[];
  account: {
    name: string;
    email: string;
  };
  notes: {
    data: IFundedAccountNote[];
    count: number;
    isLoading: boolean;
  };
  net_withdrawal_amount: number;
  evaluationCompletedHistory: {
    data: IGetCustomerEvaluationCompleteHistoryResponse["data"]["rows"];
    count: number;
    isLoading: boolean;
  };
  evaluationCompletedActiveOrRecentAccounts: {
    data: IGetCustomerEvaluationCompleteHistoryResponse["data"]["rows"];
    count: number;
    isLoading: boolean;
  };
}

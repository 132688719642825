import moment from "moment-timezone";

import { ICharge } from "../../../api/charges/index.types";
import { ICharge as IChargeBilling } from "../../../api/billing/index.types";

export const UPDATE_REQUEST_TIMEOUT = 1000 * 60;
export const All_CHARGES_REQUEST_BODY = {
  payPal_start_date: moment().format(),
  page: 1,
};
export const DATE_2_HOURS_AGO = Math.floor(+moment().subtract(2, "h") / 1000);
export const getFilteredCharges = (
  filterBy: string,
  report: ICharge[],
  isAgentPage: boolean
): ICharge[] => {
  let data;
  if (filterBy !== "all") {
    data = report.filter(({ status, source, amount_refunded }) => {
      if (source === "stripe") {
        if (filterBy === "successful" && status === "succeeded") {
          return true;
        }
        if (
          (filterBy === "failed" && status === "failed") ||
          (amount_refunded > 0 && !isAgentPage)
        ) {
          return true;
        }
      }
      if (source === "paypal") {
        if (filterBy === "successful" && status === "S") {
          return true;
        }
        if (filterBy === "failed" && status !== "S") {
          return true;
        }
      }
      return null;
    });
  } else {
    data = report;
  }
  return data;
};

export const getFeeValue = (charges: IChargeBilling[]): number => {
  let fee = 0;
  charges.forEach((value) => {
    if (value.status === "succeeded") {
      fee += 1;
    }
  });
  return fee;
};

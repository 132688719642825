export const CUSTOMERS_FILTERS = {
  "E2T Account ID": "rithmic_user",
  "Account id": "account_id",
  Name: "name",
  Email: "email",
  Country: "country",
  "Active plan": "active_plan",
  "Billing history": "billing_history",
  "Account notes": "account_notes",
  "Account agreement": "account_agreement",
  // "Account flags": "account_flags",
  "Payment ID": "stripe_ch",
  "Free reset": "free_reset",
  "Last 4 digits": "last4",
};

export const INPUT_TYPES = {
  billing_history: "number",
  free_reset: "number",
};

export const COLUMNS = [
  {
    text: "Billing history",
    name: "billing_histories",
  },
  {
    text: "Account notes",
    name: "account_notes",
  },
  {
    text: "Account agreement",
    name: "account_agreements",
  },
  // {
  //   text: "Account Flags",
  //   name: "account_flags",
  // },
  {
    text: "State Province Region",
    name: "stateprov",
  },
  {
    text: "Free reset",
    name: "free_reset",
  },
  {
    text: "Last 4 digits",
    name: "last4",
  },
];

export const STATUSES_COLORS = {
  passed: {
    color: "#ffe5a0",
    name: "Passed evaluations",
  },
  lead: {
    color: "#d7e3fc",
    name: "Lead",
  },
  active: {
    color: "#c5dedd",
    name: "Active",
  },
  inactive: {
    color: "#fec5bb",
    name: "Inactive",
  },
};

import React from "react";

const Customer = ({
  w,
  h,
  m,
}: {
  w?: string | number;
  h?: string | number;
  m?: string | number;
}): React.ReactElement => (
  <svg
    height={h || w || "300px"}
    width={w || "300px"}
    style={{ marginBottom: m || 0, marginRight: 10 }}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <path d="M86.4,21.8H13.1c-4.6,0-8.3,3.7-8.3,8.3v39.3c0,4.6,3.7,8.3,8.3,8.3h73.4c4.6,0,8.3-3.7,8.3-8.3V30.1  C94.8,25.5,91,21.8,86.4,21.8z M45.7,64.4c-3.9,0-31.8,0-31.8,0c-0.2,0,0.7-4.2,0.9-4.6c0.8-1.7,2.2-2.4,3.7-2.9  c1.1-0.3,3.2-0.8,5.1-1.4c1.5-0.5,2.9-1.1,3.3-1.7c0.1-0.2,0.3-0.4,0.4-0.7c-0.3-0.3-0.7-0.6-0.9-0.7c-1.6-1.2-2.4-3.3-2.8-5.2  c-0.4-1.9-0.7-3.8-0.7-5.7c0-1.6,0.4-3.3,1.7-4.4c1.5-1.3,3.5-1.8,5.3-1.8c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0  c1.8,0,3.8,0.5,5.3,1.8c0.9,0.8,1.5,2,1.6,3.3c0.2,2.3-0.1,4.7-0.6,6.9c-0.4,1.9-1.2,4-2.8,5.2c-0.2,0.2-0.7,0.4-0.9,0.7  c0.1,0.2,0.2,0.5,0.4,0.7c0.4,0.6,1.8,1.2,3.3,1.7c1.9,0.6,4,1.1,5.1,1.4c1.5,0.4,2.8,1.1,3.7,2.9C44.9,60.2,45.8,64.4,45.7,64.4z   M85.7,61.2h-30c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h30c0.8,0,1.4,0.6,1.4,1.4S86.5,61.2,85.7,61.2z M85.7,51.2h-30  c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h30c0.8,0,1.4,0.6,1.4,1.4S86.5,51.2,85.7,51.2z M85.7,41.1h-30c-0.8,0-1.4-0.6-1.4-1.4  s0.6-1.4,1.4-1.4h30c0.8,0,1.4,0.6,1.4,1.4S86.5,41.1,85.7,41.1z" />
  </svg>
);

export default Customer;

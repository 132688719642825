import React, { useCallback } from "react";
import cx from "classnames";
import * as LogRocket from "logrocket";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import useStyles from "./useStyles";
import { DiscountCodeState } from "../../../../api/discounts/index.types";
import useGeneralStyles from "../../../../useGeneralStyles";
import { useDiscountsContext } from "../../../../context";

interface IProps {
  discount: DiscountCodeState;
}

const DiscountCodeTableData: React.FC<IProps> = ({ discount }) => {
  const history = useHistory();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { columns } = useDiscountsContext();

  const handleClickDiscountCode = useCallback(
    (e: React.MouseEvent, id: string) => {
      if (e.type === "click") {
        history.push(`/discount/${id}`);
      } else if (e.type === "contextmenu") {
        LogRocket.track("Discounts: Copy/ View Code");
        copy(id);
        toast.info(`${id} copied`, {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    [history, LogRocket]
  );

  return (
    <td
      className={cx("hover", classes.alignRight, {
        [classes.alignCenter]: columns.length <= 2,
      })}
      onContextMenu={(e) => {
        e.preventDefault();
        handleClickDiscountCode(e, discount.code);
      }}
    >
      <div
        onClick={(e) => {
          discount.id !== -1 && handleClickDiscountCode(e, discount.code);
        }}
        tabIndex={-1}
        role="button"
        className={generalClasses.tableHover}
      >
        {discount.code}
      </div>
    </td>
  );
};

export default DiscountCodeTableData;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 7,
    marginTop: 20,
  },
  activeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  actionsChangeContainer: {
    display: "flex",
  },
  noteValueContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorContainerEditNote: {
    position: "absolute",
    left: 0,
    bottom: "-13px",
  },
  createdNoteContainer: {
    marginTop: 10,
  },
});

export default useStyles;

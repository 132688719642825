import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import useGeneralStyles from "../../useGeneralStyles";
import { APIJourneys } from "../../api";
import { IJourney } from "../../api/journeys/index.types";

import { ContainerWithLoader, PaginationWithLimit } from "../../components/ui";
import usePagination from "../../hooks/usePagination";
import { setPage } from "../../utils";
import useStyles from "./useStyles";
import { useAuthContext } from "../../context";

const Journeys = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const { hasExpired } = useAuthContext();
  const history = useHistory();
  const pagination = usePagination();

  const [journeys, setJourneys] = useState<IJourney[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isShowInactiveJourneys, setIsShowInactiveJourneys] = useState(false);

  const handleClickJourney = (id: number) => history.push(`journeys/${id}`);
  const handleShowInactiveJourneys = () => setIsShowInactiveJourneys(!isShowInactiveJourneys);
  const handleSetPage = (value: number) =>
    setPage(value, pagination.count, +pagination.limit, pagination.setPage);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await APIJourneys.getJourneysList({
          limit: pagination.limit,
          page: pagination.page,
          contains: {
            isShowInactiveJourneys,
          },
        });
        setJourneys(data.journeys);
        pagination.setCount(data.count);
      } catch (error) {
        console.error("Error fetching journeys list", error);
        hasExpired();
      } finally {
        setLoading(false);
      }
    })();
  }, [pagination.page, pagination.limit, isShowInactiveJourneys]);

  return (
    <div>
      <h2>Journeys</h2>
      <div className={cx(generalClasses.containerBg, generalClasses.scrollableContainer)}>
        <ContainerWithLoader isLoading={isLoading} report={journeys}>
          <table className={generalClasses.table}>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              {journeys.map(({ id, name, description }) => (
                <tr key={id} onClick={() => handleClickJourney(id)}>
                  <td>{id}</td>
                  <td>{name}</td>
                  <td>{description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerWithLoader>
        <div className={classes.optionsContainer}>
          <PaginationWithLimit
            count={pagination.count}
            limit={pagination.limit}
            page={pagination.page}
            setPageState={handleSetPage}
            setLimit={pagination.setLimit}
          />
          <FormControlLabel
            label="Show inactive journeys"
            control={
              <Checkbox
                checked={isShowInactiveJourneys}
                onChange={handleShowInactiveJourneys}
                name="inactiveAccounts"
                color="primary"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Journeys;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tr: {
    "& th": {
      verticalAlign: "baseline",
      fontSize: 14,
    },
  },
});
export default useStyles;

import React from "react";

import TableDataCellWithSort from "../../TableDataCellWithSort";
import { ISortBy } from "../../../../types";

interface IProps {
  sortBy: ISortBy;
  handleSortBy: (by: string) => void;
}

const TableHead: React.FC<IProps> = ({ sortBy, handleSortBy }) => (
  <thead>
    <tr>
      <TableDataCellWithSort by="id" title="ID" sortBy={sortBy} newSortBy={handleSortBy} />
      <TableDataCellWithSort by="name" title="Name" sortBy={sortBy} newSortBy={handleSortBy} />
      <TableDataCellWithSort by="email" title="Email" sortBy={sortBy} newSortBy={handleSortBy} />
      <TableDataCellWithSort
        by="amount_requested"
        title="Withdrawals"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
    </tr>
  </thead>
);

export default TableHead;

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../../useStyles";
import SearchByListValues from "../../../SearchByListValues";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../../../utils/validation";
import { ComplianceAPI } from "../../../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../../../reducers/ComplianceSearchReducer/actions";
import usePagination from "../../../../../../hooks/usePagination";
import Table from "../Table";
import { useComplianceSearchContext } from "../../../../../../context";

const SearchAccountsByName = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { names } = tabsTablesSearchState.allAccountSearchTab.searchAccountsByName.search;

  const { loading, data } = useSelector((state) => state.complianceSearch.searchOnNameVariations);

  const handleChangePage = (page: number) => pagination.setPage(page);

  const fetchData = async (values: { names: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchByNameRequest());

      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      values.names
        .trim()
        .replaceAll(", ", ",")
        .split(",")
        .forEach((value) => {
          query.append("names", value.trim());
        });
      const response = await ComplianceAPI.getAllEntriesRequestByValues(query.toString());

      pagination.setCount(response.data.count);
      dispatch(ComplianceSearchActions.fetchComplianceSearchByNameSuccess(response.data));
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchByNameFailed());
      console.log("error SearchAccountsByName fetching data", error);
    }
  };

  useEffect(() => {
    (async () => {
      names && (await fetchData({ names }));
    })();
  }, [names, pagination.page, pagination.limit]);

  useEffect(() => {
    pagination.setPage(0);
  }, [names]);

  return (
    <div>
      <b className={classes.title}>Search on name variations</b>
      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            allAccountSearchTab: {
              ...tabsTablesSearchState.allAccountSearchTab,
              searchAccountsByName: {
                search: { names: values.names },
              },
            },
          });
        }}
        isLoading={false}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        handleChangePage={handleChangePage}
        pagination={pagination}
        data={data}
        loading={loading}
        table_name="E2T All accounts search - Search on name variations"
      />
    </div>
  );
};

export default SearchAccountsByName;

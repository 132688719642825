import React from "react";
import { Button, DialogActions } from "@material-ui/core";

interface IProps {
  handleCancel: () => void;
  handleConfirm: () => void;
  isDisabledActionButton: boolean;
}
const DialogActionsButtons: React.FC<IProps> = ({
  handleCancel,
  handleConfirm,
  isDisabledActionButton,
}) => {
  return (
    <DialogActions>
      <Button
        disabled={isDisabledActionButton}
        size="small"
        variant="contained"
        onClick={handleCancel}
        color="secondary"
      >
        No
      </Button>
      <Button
        disabled={isDisabledActionButton}
        size="small"
        variant="contained"
        onClick={handleConfirm}
        color="primary"
        autoFocus
      >
        Yes
      </Button>
    </DialogActions>
  );
};

export default DialogActionsButtons;

import React from "react";
import { Typography, styled, makeStyles } from "@material-ui/core/";
import { useSelector } from "react-redux";

import { getNewPrice, getPlanPrice } from "../utils";
import { IntlFormatUSD } from "../../../../utils";
import { ICode } from "../types";

const StyledTypography = styled(Typography)({
  fontWeight: "bold",
  fontSize: "17px",
  display: "inline-block",
});
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
});

interface IProps {
  isNewPrice: boolean;
  values: ICode;
}
const NewPrice = ({ values, isNewPrice }: IProps): React.ReactElement | null => {
  const classes = useStyles();
  const { plans } = useSelector((state) => state.plans.data);

  const newPrice = getNewPrice({ plans, code: values });
  const planPrice = getPlanPrice(plans, values.plan) / 100;
  const planPriceWithDiscount = planPrice - newPrice;

  if (values.plan <= 0) {
    return null;
  }
  return (
    <div className={classes.container}>
      <Typography component="span">
        Price of plan:
        <StyledTypography>{IntlFormatUSD(planPrice)}</StyledTypography>
      </Typography>
      <Typography component="span">
        Discounts amount:
        {isNewPrice ? (
          <StyledTypography>{IntlFormatUSD(newPrice)}</StyledTypography>
        ) : (
          <StyledTypography>{IntlFormatUSD(planPriceWithDiscount)}</StyledTypography>
        )}
      </Typography>
      <Typography component="span">
        Total price:{" "}
        {isNewPrice ? (
          <StyledTypography>{IntlFormatUSD(planPriceWithDiscount)}</StyledTypography>
        ) : (
          <StyledTypography>{IntlFormatUSD(newPrice)}</StyledTypography>
        )}
      </Typography>
    </div>
  );
};

export default NewPrice;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  discountSearch: {
    marginTop: 15,
    marginBottom: 5,
  },
  rootTabs: {
    backgroundColor: "#1288e5 ",
  },
});

export default useStyles;

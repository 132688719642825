import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { IntlFormatUSD } from "../../../../../../utils";
import useStyles from "./useStyles";

interface IProps {
  isFilterByEmail: boolean;
  setIsFilterByEmail: (v: boolean) => void;
  chargesByEmail: string[];
  chargesIds: string[];
  chargesSum: number;
}
const MainInfo: React.FC<IProps> = ({
  isFilterByEmail,
  chargesByEmail,
  chargesIds,
  chargesSum,
  setIsFilterByEmail,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <b data-test="transactions-count">
          Total transactions: {isFilterByEmail ? chargesByEmail.length : chargesIds.length}
        </b>
      </div>
      <div className={classes.sumContainer}>
        <div>
          <b data-test="transactions-sum">{chargesIds.length ? IntlFormatUSD(chargesSum) : 0}</b>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isFilterByEmail}
                onChange={() => setIsFilterByEmail(!isFilterByEmail)}
                name="isFilterByEmail"
                color="primary"
              />
            }
            label="Filter transactions based on email"
          />
        </div>
      </div>
    </div>
  );
};

export default MainInfo;

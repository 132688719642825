import React from "react";
import { IRithmicData } from "../../../../../api/rithmic/index.types";

interface IProps {
  activeColumns: string[];
  rithmicData: IRithmicData[];
}
const TableBody = ({ activeColumns, rithmicData }: IProps): React.ReactElement => (
  <tbody>
    {rithmicData.map((data) => {
      const transactionNumber = data["Server Transaction Number"];
      return (
        <tr key={transactionNumber}>
          {activeColumns.map((columnName) => (
            // @ts-ignore
            <td key={columnName}>{data[columnName] || "n/a"}</td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

export default TableBody;

import React from "react";

import { CENTS_IN_DOLLAR } from "../../../../../../constants";
import { IntlFormatUSD } from "../../../../../../utils";
import { ChartPie } from "../../../../../../components/ui";

interface IProps {
  us_total: number;
  international_total: number;
}
const PieChart: React.FC<IProps> = ({ us_total, international_total }) => {
  const pieChartData = {
    labels: ["US", "International"],
    datasets: [
      {
        label: "# of Votes",
        data: [us_total, international_total],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      },
    ],
  };
  const sum = us_total + international_total;

  const pieChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label(value: { label: string; raw: number }) {
            const { label, raw } = value;
            return `${label} ${IntlFormatUSD(raw / CENTS_IN_DOLLAR)}`;
          },
        },
      },
      datalabels: {
        formatter(value: number) {
          return `${((value / sum) * 100).toFixed(2)}%`;
        },
      },
    },
  };

  return (
    <div>
      <ChartPie height={300} data={pieChartData} options={pieChartOptions} />
    </div>
  );
};

export default PieChart;

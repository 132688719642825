import httpClient from "../apiClient";
import { ILoadFiles, ISearchFiles, TUploadFile } from "./index.types";

export const RITHMIC_FILES_API_URLS = {
  searchFiles: "/rithmic-files/search-files",
  downloadArchiveFile: "/rithmic-files/get-log-data",
  uploadFile: "/rithmic-files/upload-file",
} as const;

export class RithmicFilesAPI {
  static searchFiles: ISearchFiles = (data, signal) =>
    httpClient.post(RITHMIC_FILES_API_URLS.searchFiles, data, { signal });
  static loadFile: ILoadFiles = (data) =>
    httpClient.post(RITHMIC_FILES_API_URLS.downloadArchiveFile, data);
  static uploadFile: TUploadFile = (data, folderPath) =>
    httpClient.post(`${RITHMIC_FILES_API_URLS.uploadFile}/${folderPath}`, data);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles } from "@material-ui/core/";
import cx from "classnames";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { getIn } from "formik";

import { InfoTitle, InputMessage, ContextualHelp } from "../index";

const useClasses = makeStyles({
  formControlRoot: {
    width: "100%",
  },
  errorContainer: {
    position: "absolute",
    left: 0,
    top: 55,
  },
  errorContainerStandardVariant: {
    top: 48,
  },
  errorContainerSmallVariant: {
    top: 40,
  },
  errorContainerSmallVariantMultiline: {
    bottom: -14,
    top: "initial",
  },
});

interface IProps {
  placement: string;
  disabled: boolean;
  placeholder: string;
  label: string;
  field: {
    name: string;
    value: string;
  };
  shrink: boolean | undefined;
  infoTitle: string;
  form: {
    errors: {
      optionalObject: Record<string, any>;
    };
  };
  onFocus: (a: any) => void;
  onBlur: (a: any) => void;
  contextualHelpText: string;
  contextualHelpProps: Record<string, any>;
  errorContainerClassName?: string;
  multiline?: boolean;
  variant?: "filled" | "standard" | "outlined";
  size?: "small" | "medium";
}

const Input = ({
  placement,
  disabled,
  placeholder,
  label,
  field,
  shrink,
  infoTitle,
  form: { errors },
  onFocus,
  onBlur,
  contextualHelpText,
  contextualHelpProps,
  errorContainerClassName,
  ...props
}: IProps): React.ReactElement => {
  const errorMessage = getIn(errors, field.name);
  const classes = useClasses();

  return (
    <FormControl classes={{ root: classes.formControlRoot }}>
      <ContextualHelp title={contextualHelpText} {...contextualHelpProps}>
        <TextField
          {...field}
          variant="outlined"
          {...props}
          value={field.value === null || field.value === undefined ? "" : field.value}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          error={!!errorMessage}
          onFocus={(event) => {
            onFocus && onFocus(event);
          }}
          onBlur={(event) => {
            onBlur && onBlur(event);
          }}
          data-test="base-input"
        />
      </ContextualHelp>

      <div
        className={cx(classes.errorContainer, {
          [classes.errorContainerStandardVariant]: props.variant === "standard",
          [classes.errorContainerSmallVariant]: props.size === "small",
          [classes.errorContainerSmallVariantMultiline]: props.multiline,
        })}
      >
        {errorMessage && <InputMessage error={errorMessage} />}
      </div>

      {infoTitle && <InfoTitle title={infoTitle} />}
    </FormControl>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  loadBar: {
    width: "100%",
    margin: "-30px -30px 30px -30px",
    position: "absolute",
    top: "30px",
    zIndex: 3,
  },
  innerLoadBAr: {
    height: 5,
    background: "#1886cf",
  },
});
interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elements: any[];
}
const LinearProgress = ({ elements }: IProps): React.ReactElement => {
  const classes = useStyles();
  const [animBar, setAnimBar] = useState<number>(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimBar(0);
    }, 1000 * 60);

    setAnimBar(100);

    return () => {
      clearTimeout(timeout);
    };
  }, [elements]);

  return (
    <div className={classes.loadBar}>
      <div
        className={classes.innerLoadBAr}
        style={{
          width: `${animBar}%`,
          transition: animBar === 0 ? ".2s width" : "70s width linear",
        }}
      />
    </div>
  );
};

export default LinearProgress;

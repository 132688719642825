import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../../components/ui/Select";
import { APIFunds } from "../../../../api";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";

const E2T_STATUS_INTERVIEW = [
  {
    name: "not_offered",
    text: "Not Offered",
  },
  {
    name: "ask_later",
    text: "Ask Later",
  },
  {
    name: "accepted",
    text: "Accepted",
  },
  {
    name: "completed",
    text: "Completed",
  },
];
const E2TInterview = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { accounts_id, e2t_interview } = useSelector((state) => state.fundedTrader);

  const handleChangeE2TInterviewStatus = async (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown | string }>
  ) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.updateInterviewStatusRequest(accounts_id, {
        status: event.target.value as string,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error handle change e2t interview status", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  return (
    <div>
      <Select
        options={E2T_STATUS_INTERVIEW}
        label="E2T Interview"
        inputLabel="E2T Interview"
        onChange={handleChangeE2TInterviewStatus}
        value={e2t_interview?.status || E2T_STATUS_INTERVIEW[0].name}
        size="small"
      />
    </div>
  );
};

export default E2TInterview;

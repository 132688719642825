import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface IProps {
  isOpen: boolean;
  dialogTitle: string;
  dialogContentText: string;
  handleCancel: () => void;
  handleClickOk: () => void;
}
const ConfirmDialog: React.FC<IProps> = ({
  isOpen,
  dialogTitle,
  dialogContentText,
  handleCancel,
  handleClickOk,
}) => (
  <Dialog open={isOpen}>
    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

    <DialogContent dividers>
      <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button onClick={handleCancel} size="small" color="primary" variant="contained">
        Cancel
      </Button>
      <Button
        onClick={handleClickOk}
        size="small"
        color="secondary"
        autoFocus
        variant="contained"
        data-test="button-submit"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
export default ConfirmDialog;

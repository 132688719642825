import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  filtersWrapper: {
    display: "flex",
    marginTop: 15,
    marginBottom: 5,
  },
});

export default useStyles;

import httpClient from "../apiClient";
import * as T from "./index.types";

export const BILLING_API_URLS = {
  productsStatisticsByDates: "billing/productsStatisticsByDates",
  getReport: "billing/getReport",
  getNetSalesValuesReport: "/billing/getNetSalesValuesReport",
  productsStatistics: "billing/productsStatistics",
  getRebillStatistics: "billing/getRebillStatistics",
  statuses: "billing/statuses",
  billing: "billing",
  getFreeResetsCount: "/billing/getFreeResetsCount",
  salesBreakdownPerCountry: "/billing/sales_breakdown_per_country",
  salesByPeriodPerCountry: "/billing/sales-by-period-per-country",
  getSalesStatsByDay: "/billing/get-sales-stats-by-day",
} as const;

export class BillingAPI {
  static getProductsStatisticsByDatesRequest: T.TGetProductsStatisticsByDatesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.productsStatisticsByDates, data);
  static getReportRequest: T.TGetReportRequest = (data) =>
    httpClient.post(BILLING_API_URLS.getReport, data);
  static getProductsStatisticsRequest: T.TGetProductsStatisticsRequest = (
    beginPeriod,
    endPeriod,
    period
  ) => httpClient.post(BILLING_API_URLS.productsStatistics, { beginPeriod, endPeriod, period });
  static getRebillStatisticsRequest: T.TGetRebillStatisticsRequest = (beginPeriod, endPeriod) =>
    httpClient.post(BILLING_API_URLS.getRebillStatistics, { beginPeriod, endPeriod });
  static getNetSalesValuesRequest: T.TGetNetSalesValuesRequest = (startDate, endDate, reportType) =>
    httpClient.post(BILLING_API_URLS.getNetSalesValuesReport, { startDate, endDate, reportType });
  static getBillingStatusesRequest: T.TGetBillingStatusesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.statuses, data);
  static getThisDayChargesRequest: T.TGetThisDayChargesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.billing, data);
  static getFreeResetsCountRequest: T.TGetFreeResetsCountRequest = (id) =>
    httpClient.post(`${BILLING_API_URLS.getFreeResetsCount}/${id}`);
  static getSalesBreakdownPerCountryRequest: T.TGetSalesBreakdownPerCountryRequest = (query) =>
    httpClient.get(`${BILLING_API_URLS.salesBreakdownPerCountry}?${query}`);
  static getSalesByPeriodPerCountryRequest: T.TGetSalesByPeriodPerCountryRequest = (
    query,
    signal
  ) => httpClient.get(`${BILLING_API_URLS.salesByPeriodPerCountry}?${query}`, { signal });
  static getSalesStatsByDayRequest: T.IGetSalesStatsByDayRequest = (query, signal) =>
    httpClient.get(`${BILLING_API_URLS.getSalesStatsByDay}?${query}`, { signal });
}

import React from "react";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core/";

interface Columns {
  text: string;
  name: string;
}

interface IProps {
  handleShowExternalColumns: (a: string[]) => void;
  relatedTables: string[];
  columns: Columns[];
}

const useStyles = makeStyles({
  formControlContainer: {
    width: "fit-content",
    minWidth: 200,
    maxWidth: 250,
  },
});

const TableColumnsView: React.FC<IProps> = ({
  handleShowExternalColumns,
  relatedTables,
  columns,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    handleShowExternalColumns(event.target.value as string[]);

  return (
    <FormControl className={classes.formControlContainer}>
      <InputLabel id="column-multiple-checkbox-label">Column</InputLabel>
      <Select
        labelId="column-multiple-checkbox-label"
        id="column-multiple-checkbox"
        multiple
        input={<Input />}
        renderValue={(selected) => {
          const values = columns.filter(({ name }) => (selected as string[]).includes(name));
          return values.map(({ text }) => text).join(", ");
        }}
        value={relatedTables}
        onChange={handleChange}
        autoWidth
      >
        {columns.map(({ name, text }) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={relatedTables.indexOf(name) > -1} />
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TableColumnsView;

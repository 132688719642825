import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import { APIBilling } from "../../../../api";
import { IProductStatistic } from "../../../../api/billing/index.types";
import useGeneralStyles from "../../../../useGeneralStyles";
import { CircularProgress, SelectByDateWithCustomPeriod } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";
import useStyles from "./useStyles";

const RebillStatistic = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const [isShowUnsuccessfulLit, setShowUnsuccessfulLit] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [statistic, setStatistic] = useState<IProductStatistic>({
    total: 0,
    successfulRebills: 0,
    rebillsAttempted: 0,
    unsuccessfulRebills: 0,
    unsuccessfulLinks: [],
    canceledByUser: 0,
  });

  const fetchStatistics = async (customPeriodStart: string, customPeriodEnd: string) => {
    setFetching(true);
    try {
      const response = await APIBilling.getRebillStatisticsRequest(
        customPeriodStart,
        customPeriodEnd
      );
      setStatistic(response.data);
    } catch (error) {
      console.log("error fetching rebill statistics", error);
    } finally {
      setFetching(false);
    }
  };

  const handleShowList = () => setShowUnsuccessfulLit(!isShowUnsuccessfulLit);

  return (
    <div>
      <h2>Rebill statistics</h2>
      <div className={classes.filterContainer}>
        <SelectByDateWithCustomPeriod
          setIsLoading={setFetching}
          getReport={fetchStatistics}
          setReport={setStatistic}
        />
      </div>

      {isFetching ? (
        <CircularProgress isLoading={isFetching} />
      ) : (
        <div className={generalClasses.containerBg}>
          <ul>
            <li>
              Canceled by user during rebilling: <span>{statistic.canceledByUser}</span>
            </li>
            <li>
              Rebills attempted:{" "}
              <span data-test="rebils-attempt">{statistic.rebillsAttempted}</span>
            </li>
            <li>
              Rebills successful:{" "}
              <span data-test="rebils-successful">{statistic.successfulRebills}</span>
            </li>
            <li>
              Rebills unsuccessful:{" "}
              <span data-test="rebils-unsuccessful">{statistic.unsuccessfulRebills}</span>
            </li>
            <li>
              Total: <span data-test="rebils-total">{IntlFormatUSD(statistic.total / 100)}</span>
            </li>
          </ul>

          {!!statistic.unsuccessfulRebills && (
            <div>
              <Button variant="contained" color="primary" onClick={handleShowList}>
                Show unsuccessful list
              </Button>
            </div>
          )}

          {isShowUnsuccessfulLit && (
            <div>
              <ul>
                {statistic.unsuccessfulLinks.map(({ accounts_id, account }) => (
                  <li key={`${accounts_id}`}>
                    <Link target="_blank" to={`customer/${accounts_id}`}>
                      {account.email}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RebillStatistic;

import React from "react";
import { getCurrentActiveTab } from "../../utils";
import { useEvaluationsContext } from "../../../../context";

const TableDataJourneyName = (): React.ReactElement | null => {
  const { activeTabIndex } = useEvaluationsContext();
  const {
    isActivePassedTab,
    isActiveCertificateDesignTab,
    isActiveCertificateEmailTab,
    isActiveWaitingForOfferTab,
  } = getCurrentActiveTab(activeTabIndex);

  if (isActivePassedTab) {
    return <td>Confirm passed</td>;
  }
  if (isActiveCertificateDesignTab) {
    return <td>Confirm certificated design</td>;
  }
  if (isActiveCertificateEmailTab) {
    return <td>Confirm certificated email</td>;
  }
  if (isActiveWaitingForOfferTab) {
    return <td>Confirm waiting for offer</td>;
  }
  return null;
};

export default TableDataJourneyName;

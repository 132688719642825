import * as Yup from "yup";

export const URMA_ACCOUNT_INITIAL_STATE_FORM = {
  name: "",
  balance: 0,
  account_class: 0,
  brokerage_account_number: "",
  profile_id: "",
  account_type_id: "",
  sales_series_id: "",
  auth_traders: [],
  trading_routes: [],
  is_active: true,
};
export const URMA_USER_INITIAL_STATE_FORM = {
  name: "",
  subscriber_type: 0,
  login_class: 0,
  currency: "",
  login_domain: 0,
  is_active: true,
};

export const URMA_PROFILE_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  legal_type: Yup.number().required("Legal type is required"),
  contact: Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    emails: Yup.array()
      .of(
        Yup.string()
          .email(({ originalValue }) => `"${originalValue}" is invalid email `)
          .required("Email is required")
      )
      .min(1, "Email is required"),
    phones: Yup.array()
      .of(Yup.number().typeError(({ originalValue }) => `"${originalValue}" is invalid phone `))
      .min(1, "Phones is required"),

    faxes: Yup.array()
      .of(Yup.number().typeError(({ originalValue }) => `"${originalValue}" is invalid fax `))
      .min(1, "Faxes is required"),
    addresses: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      zip: Yup.string().required("ZIP is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      address_line1: Yup.string().required("Address line 1 is required"),
      address_line2: Yup.string().required("Address line 2 is required"),
    }),
  }),
  profile_type: Yup.number().required("Profile type is required"),
  number: Yup.number().required("Number is required"),
});
export const URMA_USER_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  subscriber_type: Yup.number().required("Subscriber type type is required"),
  login_class: Yup.number().required("Login class is required"),
  currency: Yup.string().required("Currency is required"),
  login_domain: Yup.number().required("Login domain type type is required"),
  profile_id: Yup.string().required("Profile ID is required"),
});
export const URMA_ACCOUNT_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  balance: Yup.number().required("Balance is required"),
  account_class: Yup.number().required("Account class is required"),
  brokerage_account_number: Yup.string().required("Brokerage account number is required"),
  profile_id: Yup.string().required("Profile id is required"),
  account_type_id: Yup.string().required("Account type id is required"),
  sales_series_id: Yup.string().required("Sales series id is required"),
  auth_traders: Yup.array()
    .of(Yup.string().required("Auth traders is incorrect"))
    .min(1, "Auth traders is required"),
  trading_routes: Yup.array()
    .of(Yup.string().required("Trading routes is incorrect"))
    .min(1, "Trading routes is required"),
});

export const LEGAL_TYPE_OPTIONS = [
  {
    value: 0,
    label: "Unknown",
    id: 0,
  },
  {
    value: 1,
    label: "Individual",
    id: 1,
  },
];
export const PROFILE_TYPE_OPTIONS = [
  {
    value: 0,
    label: "Unknown",
    id: 0,
  },
  {
    value: 1,
    label: "Customer",
    id: 1,
  },
];
export const SUBSCRIBER_TYPE_OPTIONS = [
  {
    value: 0,
    label: "Pro",
    id: 0,
  },
  {
    value: 1,
    label: "Non pro",
    id: 1,
  },
];
export const LOGIN_CLASSES_OPTIONS = [
  {
    value: 0,
    label: "Unknown",
    id: 0,
  },
  {
    value: 1,
    label: "Regular",
    id: 1,
  },
  {
    value: 2,
    label: "Fix-direct",
    id: 2,
  },
  {
    value: 3,
    label: "Order-handler",
    id: 3,
  },
  {
    value: 4,
    label: "Template",
    id: 4,
  },
];
export const LOGIN_DOMAIN_OPTIONS = [
  {
    value: 0,
    label: "Unknown",
    id: 0,
  },
  {
    value: 2,
    label: "CQG Gateway login domain",
    id: 1,
  },
  {
    value: 3,
    label: "CQG System (IC, QTrader, etc.) login domain",
    id: 2,
  },
  {
    value: 4,
    label: "CQG Admin (CMS, CAST, CAST2) login domain",
    id: 3,
  },
];
export const ACCOUNT_CLASS_OPTIONS = [
  {
    value: 0,
    label: "Unknown account class",
    id: 0,
  },
  {
    value: 1,
    label: "Regular account class",
    id: 1,
  },
];

import React from "react";
import FundedAccountsInvestigationSearch from "../FundedAccountsInvestigationSearch";

const FundedAccountInvestigation: React.FC = () => {
  return (
    <div>
      <FundedAccountsInvestigationSearch />
    </div>
  );
};

export default FundedAccountInvestigation;

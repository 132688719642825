import React from "react";
import cx from "classnames";
import moment from "moment-timezone";

import {
  HOURS_12_FORMAT_WITH_DATE,
  PAYPAL_CODE_TRANSACTION_STATUS,
} from "../../../../../../constants";
import {
  CURRENT_TIME_ZONE_ABBREVIATION,
  getTimeByAmericaChicagoTz,
} from "../../../../../../utils/moment";
import { IntlFormatUSD } from "../../../../../../utils";

import {
  ICharge,
  ICustomerDbTransactions,
  IDbChargesByAPIIds,
} from "../../../../../../api/charges/index.types";

import { CopyToClipboard } from "../../../../../../components/ui";
import { ReactComponent as PayPalIc } from "../../../../../../assets/icons/pay_pal_ic.svg";
import { ReactComponent as StripeIc } from "../../../../../../assets/icons/stripe_s_ic.svg";
import { ReactComponent as BitPayIC } from "../../../../../../assets/icons/bitbay.svg";
import bridger from "../../../../../../assets/icons/bridgerpay_logo.png";

import { TCharge } from "../../index";
import useGeneralStyles from "../../../../../../useGeneralStyles";
import useStyles from "./useStyles";

interface IProps {
  chargesToRender: string[];
  apiDataCharges: TCharge<ICharge>;
  dbTransactions: TCharge<ICustomerDbTransactions>;
  dbTransactionsByAPIIds: TCharge<IDbChargesByAPIIds>;
}
const TableBody: React.FC<IProps> = ({
  chargesToRender,
  apiDataCharges,
  dbTransactions,
  dbTransactionsByAPIIds,
}) => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  return (
    <tbody>
      {chargesToRender.map((charge_id) => {
        const currentCharge = apiDataCharges[charge_id];
        const currentDbChargeRecord = dbTransactions[charge_id];
        if (!currentCharge) {
          return null;
        }
        return (
          <tr
            key={currentCharge.id}
            data-test="transaction-row"
            className={cx({
              [classes.refundPayment]: !!currentCharge?.amount_refunded,
              [classes.ownCharge]: !currentCharge?.amount_refunded && !!currentDbChargeRecord,
              [classes.failedPayment]:
                currentCharge?.stripe_failure_code ||
                currentCharge?.status === "failed" ||
                currentCharge?.status === "D" ||
                currentCharge?.status === "declined",
            })}
          >
            <td className={classes.paymentIcContainer}>
              {apiDataCharges[charge_id]?.source === "stripe" && (
                <StripeIc className={classes.paymentIc} />
              )}
              {apiDataCharges[charge_id]?.source === "paypal" && (
                <PayPalIc className={classes.paymentIc} />
              )}
              {apiDataCharges[charge_id]?.source === "bitpay" && (
                <BitPayIC className={classes.bitPayIc} />
              )}
              {apiDataCharges[charge_id]?.source === "bridgerpay" && (
                <img src={bridger} alt="bridgerpay_logo" className={classes.bridgerIc} />
              )}
            </td>
            <td>
              <p className={classes.preWhiteSpace}>
                {getTimeByAmericaChicagoTz(currentCharge?.date * 1000, true)} CT
              </p>
              <p className={classes.preWhiteSpace}>
                {moment(currentCharge?.date * 1000).format(HOURS_12_FORMAT_WITH_DATE)}{" "}
                {CURRENT_TIME_ZONE_ABBREVIATION}
              </p>
            </td>
            <td className={generalClasses.noWrap}>
              {currentCharge?.amount_refunded
                ? `- ${IntlFormatUSD(currentCharge?.amount_refunded)}`
                : IntlFormatUSD(currentCharge?.amount)}
              {currentCharge?.transactionCurrency && (
                <p>
                  {currentCharge?.transactionCurrency}:{currentCharge.displayAmountPaid}
                </p>
              )}
            </td>
            <td>{currentCharge?.object}</td>
            <td>{currentCharge?.cardSource?.name || "n/a"}</td>
            <td>{currentCharge?.cardSource?.last4 || "n/a"}</td>
            <td>{currentCharge?.stripe_failure_code}</td>
            <td>{currentCharge?.outcome?.reason}</td>
            <td>
              {/* @ts-ignore */}
              {PAYPAL_CODE_TRANSACTION_STATUS[currentCharge.status]}
              {currentCharge.outcome?.seller_message} {currentCharge.stripe_failure_message}
            </td>
            <td>{currentDbChargeRecord?.discount_code?.code}</td>
            <td>
              <CopyToClipboard value={currentCharge.id} />
            </td>
            <td>
              {dbTransactionsByAPIIds[charge_id] ? (
                <span style={{ color: "green" }}>✔</span>
              ) : (
                <span style={{ color: "red" }}>✘</span>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
export default TableBody;

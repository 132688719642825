import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import Table from "../Table";
import usePagination from "../../../../hooks/usePagination";
import { FAILED_COLUMNS } from "../HeliosFailedSearch/constants";
import { FilterAutocomplete } from "../../../../components/ui";
import { WITHDRAWALS_COLUMNS } from "../HeliosWithdrawalsSearch/constants";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { appendSearchParamsToQuery } from "../../utils";
import { useComplianceSearchContext } from "../../../../context";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import {
  PASSES_COLUMNS,
  TCP_STEPS_COLUMNS,
  GM_CAPPERS_COLUMNS,
  HELIOS_LIVE_TIF_COLUMNS,
  HELIOS_LIVE_SIM_TIF_COLUMNS,
} from "../../constants";

const FundedAccountsInvestigationSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();
  const dispatch = useDispatch();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } =
    tabsTablesSearchState.fundedAccountInvestigationTab.fundedAccountsInvestigationSearchTable;

  const {
    loading,
    data: { passes, liveSimTif, liveTif, withdrawals, watchlistFailed, gm_cappers, tcp_steps },
  } = useSelector((state) => state.complianceSearch.heliosAccountsInvestigation);

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.fundedAccountInvestigation,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendSearchParamsToQuery(search, query);

      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationRequest());
      const response = await ComplianceAPI.getFundedAccountInvestigationRequest(
        query.toString(),
        signal
      );
      pagination.setCount(response.data.passes.count);
      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching getHeliosRelatedSearch", error);
      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationFailed());
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              fundedAccountInvestigationTab: {
                ...tabsTablesSearchState.fundedAccountInvestigationTab,
                fundedAccountsInvestigationSearchTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
            Phone: "phone",
            Address: "address",
          }}
        />
      </div>
      <div>
        <Table
          columns={PASSES_COLUMNS}
          data={passes.rows}
          loading={loading}
          extractKey={(row) => row.id}
          theadTitle="Passes"
          pagination={pagination}
          handleChangePage={handleChangePage}
          csv_table_name="Funded account investigation - Passes"
        />
      </div>

      <div className={classes.heliosRelatedSearch}>
        <div>
          <Table
            columns={GM_CAPPERS_COLUMNS}
            data={gm_cappers.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Gaunt Cappers"
            csv_table_name="Funded account investigation - Gaunt Cappers"
          />
        </div>
        <div>
          <Table
            columns={TCP_STEPS_COLUMNS}
            data={tcp_steps.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="TCP steps"
            csv_table_name="Funded account investigation - TCP steps"
          />
        </div>
      </div>

      <div className={classes.heliosRelatedSearch}>
        <div>
          <Table
            columns={WITHDRAWALS_COLUMNS}
            data={withdrawals.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Withdrawals"
            csv_table_name="Funded account investigation - Withdrawals"
          />
        </div>
        <div>
          <Table
            columns={FAILED_COLUMNS}
            data={watchlistFailed.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Failed"
            csv_table_name="Funded account investigation - Failed"
          />
        </div>
      </div>

      <div className={classes.heliosRelatedSearch}>
        <div>
          <Table
            columns={HELIOS_LIVE_SIM_TIF_COLUMNS}
            data={liveSimTif.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Live Sim TIF"
            csv_table_name="Funded account investigation - Live Sim TIF"
          />
        </div>
        <div>
          <Table
            columns={HELIOS_LIVE_TIF_COLUMNS}
            data={liveTif.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Live TIF"
            csv_table_name="Funded account investigation - Live TIF"
          />
        </div>
      </div>
    </div>
  );
};

export default FundedAccountsInvestigationSearch;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  filterContainer: {
    marginTop: 10,
    display: "flex",
  },
  datePickerContainer: {
    width: "fit-content",
  },
});

export default useStyles;

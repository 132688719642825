import { FilterOptionsState } from "@material-ui/lab";

import {
  ACTIVE_FILTER,
  DIRECTION_FILTER_COMPARISONS,
  LIFE_TIME_FILTER,
  TYPE_FILTER,
  EXPIRED_DISCOUNT_CODE_FILTER,
} from "./constants";
import { IPlan } from "../../../api/plans/index.types";

type TCheckIsShowCompareSymbol = (
  option: string | number,
  inputTypes?: { [index: string]: string }
) => boolean;
export const checkIsShowCompareSymbol: TCheckIsShowCompareSymbol = (option, inputTypes) => {
  const inputTypesByNumber = [];
  if (inputTypes) {
    const filteredInputsByNumber = Object.entries(inputTypes).filter(
      ([, type]) => type === "number"
    );
    inputTypesByNumber.push(...filteredInputsByNumber);
  }

  return [
    "Billing history",
    "Discount",
    "Price",
    "Quantity",
    "Free reset",
    "Withdrawals",
    ...[...(inputTypesByNumber.length ? Object.keys(Object.fromEntries(inputTypesByNumber)) : [])],
  ].includes(option as string);
};

type TGetKeyByValue = (
  object: { [index: string]: unknown },
  value: string | number
) => string | undefined;
export const getKeyByValue: TGetKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

type TGetLabel = (
  option: string | number,
  optionValue: string | number,
  search: { [index: string]: string | number },
  filters: { [index: string]: string },
  inputTypes?: { [index: string]: string }
) => string;
export const getLabel: TGetLabel = (option, optionValue, search, filters, inputTypes) => {
  const isShowWithCompareSymbol = checkIsShowCompareSymbol(option, inputTypes);
  return isShowWithCompareSymbol
    ? `${option} ${
        getKeyByValue(DIRECTION_FILTER_COMPARISONS, search[filters[option] + "_compare"]) || ""
      } ${optionValue}`
    : `${option}=${optionValue}`;
};

export const getActiveFilterName = (
  filterName: string,
  inputTypes?: { [index: string]: string }
): { [key: string]: boolean } => {
  const isSelectedFilterByIntegerValue = checkIsShowCompareSymbol(filterName, inputTypes);

  const isActivePlanFilter =
    // customers page
    filterName === "Active plan" ||
    // discounts page
    filterName === "Plan";

  const isFilterByAccepted = filterName === "Accepted";
  const isFilterByLifeTime = filterName === "LifeTime";
  const isFilterByActive = filterName === "Active";
  const isFilterByType = filterName === "Type";
  const isFilterByUnused = filterName === "Show expired";

  return {
    isActivePlanFilter,
    isFilterByAccepted,
    isFilterByLifeTime,
    isFilterByActive,
    isFilterByType,
    isSelectedFilterByIntegerValue,
    isFilterByUnused,
  };
};

type TGetAutocompleteOptions = (
  plans: IPlan[],
  filters: { [p: string]: string },
  filterName: string,
  inputTypes?: {
    [index: string]: string;
  }
) => string[];
export const getAutocompleteOptions: TGetAutocompleteOptions = (
  plans,
  filters,
  filterName,
  inputTypes
) => {
  const currentFilter = getActiveFilterName(filterName, inputTypes);
  if (currentFilter.isActivePlanFilter) {
    return plans.map(({ name }) => name);
  }
  if (currentFilter.isFilterByLifeTime || currentFilter.isFilterByAccepted) {
    return Object.keys(LIFE_TIME_FILTER);
  }
  if (currentFilter.isSelectedFilterByIntegerValue) {
    return Object.keys(DIRECTION_FILTER_COMPARISONS);
  }
  if (currentFilter.isFilterByActive) {
    return Object.keys(ACTIVE_FILTER);
  }
  if (currentFilter.isFilterByType) {
    return Object.keys(TYPE_FILTER);
  }

  if (currentFilter.isFilterByUnused) {
    return Object.keys(EXPIRED_DISCOUNT_CODE_FILTER);
  }

  return Object.keys(filters);
};

// DropDown options
// eslint-disable-next-line
type TFilterOptions = (
  options: (string | undefined)[],
  state: FilterOptionsState<string | undefined>,
  filterName: string,
  search: { [index: string]: string | number },
  filters: { [index: string]: string }
) => (string | undefined)[];
export const getFilterOptions: TFilterOptions = (options, state, filterName, search, filters) => {
  const {
    isActivePlanFilter,
    isFilterByActive,
    isFilterByLifeTime,
    isFilterByAccepted,
    isFilterByType,
    isSelectedFilterByIntegerValue,
    isFilterByUnused,
  } = getActiveFilterName(filterName);
  // Returns special values to drop down
  if (isActivePlanFilter && options) {
    return options;
  }

  if (isFilterByActive) {
    return Object.keys(ACTIVE_FILTER);
  }

  if (isFilterByLifeTime || isFilterByAccepted) {
    return Object.keys(LIFE_TIME_FILTER);
  }

  if (isFilterByType) {
    return Object.keys(TYPE_FILTER);
  }

  if (isFilterByUnused) {
    return Object.keys(EXPIRED_DISCOUNT_CODE_FILTER);
  }

  if (isSelectedFilterByIntegerValue) {
    if (search[filters[filterName] + "_compare"]) {
      return [];
    }
    return Object.keys(DIRECTION_FILTER_COMPARISONS);
  }
  // Returns default filters by column name
  return filterName ? [] : options.filter((option) => option && option.includes(state.inputValue));
};

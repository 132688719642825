import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { CopyToClipboard } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";

export const FAILED_COLUMNS: Array<IColum> = [
  {
    by: "account",
    title: "Funded account name",
    path: "account",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "amount",
    title: "Amount",
    path: "amount",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value),
    getCellValue: (value) => IntlFormatUSD(+value),
  },
  {
    by: "failure_date",
    title: "Failure date",
    path: "failure_date",
  },
  {
    by: "reason",
    title: "Reason",
    path: "reason",
  },
];

import React from "react";
import cx from "classnames";

import useStyles from "./useStyles";

interface IProps {
  error: string;
  waring?: string;
}

const InputMessage = ({ error, waring }: IProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div
      className={cx({
        [classes.error]: !!error,
        [classes.waring]: !!waring,
      })}
    >
      <div className={classes.errorContainer}>
        <p>{error || waring}</p>
      </div>
    </div>
  );
};

export default InputMessage;

import React from "react";
import { Button, Grid } from "@material-ui/core";
import * as Type from "../../../../index.types";

interface IProps {
  isChanged: boolean;
  hasSelection: boolean;
  isSelectedRows: boolean;
  handleUploadCsv: () => void;
  handleOpenConfirmDialog: (type: Type.TDialogWindowTypes) => void;
  handleCopySelectedRowsToClipBoard: () => void;
}
const Actions: React.FC<IProps> = ({
  isChanged,
  hasSelection,
  isSelectedRows,
  handleUploadCsv,
  handleOpenConfirmDialog,
  handleCopySelectedRowsToClipBoard,
}) => {
  return (
    <>
      <Grid item xs={2}>
        <Button disabled={!isChanged} variant="contained" color="primary" onClick={handleUploadCsv}>
          Upload
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          disabled={!hasSelection}
          variant="contained"
          color="secondary"
          onClick={() => handleOpenConfirmDialog("removeSelectedCells")}
        >
          Remove selected cells
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          disabled={!isSelectedRows}
          variant="contained"
          color="secondary"
          onClick={() => handleOpenConfirmDialog("removeSelectedRows")}
        >
          Remove selected rows
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          disabled={!isSelectedRows}
          variant="contained"
          color="primary"
          onClick={handleCopySelectedRowsToClipBoard}
        >
          Copy to clipboard selected rows
        </Button>
      </Grid>
    </>
  );
};

export default Actions;

import React from "react";

const ErrorBoundaryFallback: React.FC = () => (
  <div className={"main logged-in error-boundary-fallback"}>
    <div>
      <p>Oops! Something went wrong.</p>
      <p>We are sorry, but an unexpected error has occurred.</p>

      <p>Please try the following steps:</p>
      <ul>
        <li>Refresh the page to see if the issue resolves.</li>
        <li>If the problem persists, contact the develop team.</li>
      </ul>

      <p>Thank you for your understanding.</p>
    </div>
  </div>
);

export default ErrorBoundaryFallback;

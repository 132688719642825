import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";

import {
  ContainerWithLoader,
  TableDataCellWithSort,
  PaginationWithLimit,
} from "../../../../../components/ui";
import { PaymentCardsAPI, PAYMENT_CARDS_API_URLS } from "../../../../../api/paymentCards";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";
import usePagination from "../../../../../hooks/usePagination";

interface IProps {
  card: number | null;
  handleClose: () => void;
}

const SharedICardsDialog: React.FC<IProps> = ({ card, handleClose }) => {
  const generalClasses = useGeneralStyles();
  const pagination = usePagination();

  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });
  const [sortBy, setSortBy] = useState({
    by: "id",
    order: false,
  });

  const data = useQuery({
    enabled: !!card,
    queryKey: [
      PAYMENT_CARDS_API_URLS.getCustomerSharedCards,
      card,
      pagination.limit,
      pagination.page,
    ],
    queryFn: async ({ signal }) => {
      const params = new URLSearchParams();
      params.set("limit", pagination.limit);
      params.set("page", pagination.page.toString());
      const { data } = await PaymentCardsAPI.getCustomerSharedCardsRequest(
        card as number,
        params.toString(),
        signal
      );
      pagination.setCount(data.count);
      return data;
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <Dialog fullWidth open={!!card} onClose={handleClose}>
      <DialogTitle>Shared accounts for card ID: {card}</DialogTitle>

      <DialogContent>
        <ContainerWithLoader
          isLoading={data.isLoading || data.isFetching}
          report={data.data?.rows || []}
        >
          <table className={generalClasses.table}>
            <thead>
              <tr>
                <TableDataCellWithSort
                  sortBy={sortBy}
                  by="id"
                  title="Account ID"
                  newSortBy={handleSortBy}
                />
                <TableDataCellWithSort
                  sortBy={sortBy}
                  by="email"
                  title="Email"
                  newSortBy={handleSortBy}
                />
              </tr>
            </thead>
            <tbody>
              {data.data?.rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => {
                    const url = `#/customer/${row.id}`;
                    window.open(url);
                  }}
                  className={generalClasses.tableHover}
                >
                  <td>{row.id}</td>
                  <td>{row.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerWithLoader>

        <PaginationWithLimit
          count={pagination.count}
          limit={pagination.limit}
          page={pagination.page}
          setPageState={pagination.setPage}
          setLimit={pagination.setLimit}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharedICardsDialog;

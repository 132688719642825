import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  e2tAccountsList: {
    padding: 0,
    marginLeft: 15,
  },
  sectionContainer: {
    marginLeft: 10,
  },
});

export default useStyles;

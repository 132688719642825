import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 8,
  },
  noResults: {
    padding: "2px 7px",
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  dangerHighlight: {
    backgroundColor: "#EA5348",
  },
  createFinUserContainer: {
    zIndex: 10,
    position: "fixed",
  },
  openModal: {
    display: "flex",
  },
  hidden: {
    display: "none",
  },
  flexBox: {
    display: "flex",
  },
  verticalAlignWithSpace: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
});
export default useStyles;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: 1.33,
    color: "#86868b",
    marginTop: "10px !important",
    "& div": {
      display: "flex",
      alignItems: "center",
      minHeight: "18px",
    },
  },
  icon: {
    margin: "1px 6.4px 0 0",
    width: "16px",
  },
});

export default useStyles;

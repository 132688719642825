interface IGetAmountToBePay {
  amount_requested: number;
  manual_deduction: number;
  account_balance: number;
  watermark_level: number;
  manual_amount_interest_rate: number;
  manual_withdrawal_amount: number;
}

const CONVENIENCE_FEES_USD = 10;
const REGULAR_DEDUCTION_AMOUNT_REQUEST_INTEREST_RATE = 20;
const INTEREST = REGULAR_DEDUCTION_AMOUNT_REQUEST_INTEREST_RATE / 100;

interface IGetAmountToBePayResult {
  amountToPay: number;
  fee: {
    regular_deduction_rate: number;
    conveniences_fees: number;
    sum: number;
    manual_amount_interest_rate?: number;
  };
}
export const getAmountToBePay = ({
  amount_requested,
  manual_deduction,
  account_balance,
  manual_amount_interest_rate,
  ...rest
}: IGetAmountToBePay): IGetAmountToBePayResult => {
  const watermark_level = +rest.watermark_level;
  const base_fee = amount_requested * INTEREST;
  const base_fee_watermark = amount_requested * (manual_amount_interest_rate / 100);

  const amount_to_pay_with_watermark = amount_requested;

  if (amount_requested >= 500) {
    if (watermark_level) {
      const amountToPay = amount_to_pay_with_watermark - manual_deduction - base_fee_watermark;
      const sum = amount_requested - amountToPay;
      return {
        amountToPay,
        fee: {
          regular_deduction_rate: manual_amount_interest_rate ? base_fee_watermark : base_fee,
          conveniences_fees: 0,
          sum,
        },
      };
    }
    return {
      amountToPay: amount_requested * 0.8 - manual_deduction,
      fee: {
        regular_deduction_rate: base_fee,
        conveniences_fees: 0,
        manual_amount_interest_rate: 0,
        sum: base_fee,
      },
    };
  }
  if (watermark_level) {
    const amountToPay = amount_to_pay_with_watermark;
    return {
      amountToPay: amountToPay - CONVENIENCE_FEES_USD - manual_deduction - base_fee_watermark,
      fee: {
        regular_deduction_rate: manual_amount_interest_rate ? base_fee_watermark : base_fee,
        conveniences_fees: CONVENIENCE_FEES_USD,
        manual_amount_interest_rate: 0,
        sum:
          amount_requested -
          amountToPay -
          CONVENIENCE_FEES_USD -
          manual_deduction -
          base_fee_watermark,
      },
    };
  }
  return {
    amountToPay: amount_requested * 0.8 - manual_deduction - CONVENIENCE_FEES_USD,
    fee: {
      conveniences_fees: CONVENIENCE_FEES_USD,
      regular_deduction_rate: amount_requested * INTEREST,
      sum: amount_requested * INTEREST + CONVENIENCE_FEES_USD,
    },
  };
};

interface IGetNewAccountBalance {
  amount_requested: number;
  account_balance: number;
  manual_deduction: number;
}
export const getNewAccountBalance = ({
  amount_requested,
  account_balance,
}: IGetNewAccountBalance): number => account_balance - amount_requested;

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useLocation } from "react-router-dom";
import { Chip, Popper, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";

import { DATE_FORMAT, PAGES_PATHNAME } from "../../../constants";
import {
  getActiveFilterName,
  getKeyByValue,
  getLabel,
  getAutocompleteOptions,
  getFilterOptions,
} from "./utils";
import {
  DIRECTION_FILTER_COMPARISONS,
  LIFE_TIME_FILTER,
  ACTIVE_FILTER,
  TYPE_FILTER,
  EXPIRED_DISCOUNT_CODE_FILTER,
} from "./constants";
import useStyles from "./useStyles";

interface IProps {
  search: {
    [index: string]: string | number;
  };
  // eslint-disable-next-line
  setSearch: (a: any) => void;
  filters: {
    [index: string]: string;
  };
  inputTypes?: {
    [index: string]: string;
  };
  clearValuesFilter?: number;
  defaultSearchFields?: {
    [index: string]: string;
  };
}

const usePageLocation = () => useLocation();

const FilterAutocomplete = ({
  search,
  setSearch,
  filters,
  inputTypes,
  clearValuesFilter,
  defaultSearchFields,
}: IProps): React.ReactElement => {
  const classes = useStyles();

  const [filterName, setFilterName] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [autocompleteValue, setAutocompleteValue] = useState(
    Object.keys(search).map((key) => {
      if (search[key]) {
        return getKeyByValue(filters, key);
      }
      return undefined;
    })
  );
  const plans = [...useSelector((state) => state.plans.data.plans)];
  const { pathname } = usePageLocation();

  if (pathname === PAGES_PATHNAME.discounts) {
    plans.push(
      {
        key: "reset",
        name: "Reset",
        id: -1,
        price_usd: 0,
        is_available_for_evaluation_complete: false,
      },
      {
        key: "All",
        name: "All",
        id: -1,
        price_usd: 0,
        is_available_for_evaluation_complete: false,
      }
    );
  }

  const {
    isActivePlanFilter,
    isFilterByAccepted,
    isFilterByLifeTime,
    isFilterByActive,
    isFilterByType,
    isSelectedFilterByIntegerValue,
    isFilterByUnused,
  } = getActiveFilterName(filterName, inputTypes);
  // eslint-disable-next-line
  const PopperStyled = ({ children, ...props }: any) => (
    <Popper {...props} className={classes.popperRoot} placement="bottom-start" key={props.key}>
      {children}
    </Popper>
  );

  const handleDelete = (index: number) => {
    const updatedValues = [...autocompleteValue];
    const removedFilterName = updatedValues.splice(index, 1)[0] || "";
    setAutocompleteValue(updatedValues);

    delete search[filters[removedFilterName]];
    if (removedFilterName === "Active plan") {
      delete search["plan"];
    }
    if (removedFilterName === "Free reset") {
      delete search["free_reset"];
      delete search["free_reset_compare"];
      delete search["compare_total"];
    }
    if (removedFilterName === "Billing history") {
      delete search["billing_history"];
      delete search["billing_history_compare"];
      delete search["compare_total"];
    }
    if (inputTypes && Object.keys(inputTypes).includes(removedFilterName)) {
      delete search[removedFilterName.toLowerCase()];
      delete search[`${removedFilterName.toLowerCase()}_compare`];
      delete search["compare_total"];
    }
    if (removedFilterName === "Quantity") {
      delete search["compare_total"];
      delete search["quantity_compare"];
    }
    if (removedFilterName === "Price") {
      delete search["price_compare"];
    }

    setInputValue("");
    setFilterName("");
    setSearch({
      ...search,
      expires: search.expires,
      joined: search.joined,
    });
  };

  const renderTags = (value: (string | undefined)[], getTagProps: AutocompleteGetTagProps) => {
    return value.map((option: string | undefined = "", index: number) => {
      const key = getKeyByValue(filters, option) || "";
      const optionValue = search[filters[key]] || search[filters[option]];
      if (Object.keys(filters).includes(option) || filters[key]) {
        return (
          <Chip
            key={key}
            variant="outlined"
            label={getLabel(option, optionValue, search, filters, inputTypes)}
            {...getTagProps({ index })}
            onDelete={() => handleDelete(index)}
          />
        );
      }
      return null;
    });
  };

  const placeHolder = filterName ? `${filterName}=` : "Filter by";

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const { inputProps } = params;
    // @ts-ignore
    const { value, ...inputPropsReset } = inputProps;

    if (inputTypes && inputTypes[filterName] === "date") {
      return (
        <KeyboardDatePicker
          {...{ ...params, inputProps: inputPropsReset }}
          value={search[filterName.toLowerCase()] || null}
          onChange={handleChangeDateInput}
          inputVariant="outlined"
          label="Filter selected options"
          name={filterName}
          clearable
          placeholder="YYYY/MM/DD"
          format={DATE_FORMAT}
          autoOk
          onKeyDown={handleKeyDown}
        />
      );
    }
    return (
      <TextField
        {...params}
        inputProps={{
          ...inputProps,
          value: inputValue,
        }}
        type={(inputTypes && inputTypes[filterName]) || "string"}
        variant="outlined"
        label="Filters"
        placeholder={placeHolder}
        onChange={handleChangeInput}
        onKeyDown={handleKeyDown}
        onClick={() => setIsFocused(true)}
        // onFocus={() => {
        //   console.log("xxxx");
        //   setIsFocused(true);
        // }}
        onBlur={() => setIsFocused(false)}
        data-test="filter-text-filed"
      />
    );
  };

  const handleChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: (string | undefined)[],
    reason: string
  ) => {
    const currentFilter = value[value.length - 1] || "";
    const filterId: string = filters[currentFilter];

    if (reason === "clear") {
      setSearch({});
      setFilterName("");
      setInputValue("");
      setAutocompleteValue([]);
      return;
    }

    if (reason === "select-option") {
      setFilterName(currentFilter);
      setInputValue("");

      if (isActivePlanFilter) {
        setSearch({
          ...search,
          plan: currentFilter,
          active_plan: currentFilter,
        });
        setFilterName("");
        return;
      }

      if (isFilterByLifeTime) {
        setSearch({
          ...search,
          // @ts-ignore
          lifeTime: LIFE_TIME_FILTER[currentFilter],
        });
        setFilterName("");
        return;
      }

      if (isFilterByUnused) {
        setSearch({
          ...search,
          // @ts-ignore
          showExpired: EXPIRED_DISCOUNT_CODE_FILTER[currentFilter],
        });
        setFilterName("");
        return;
      }

      if (isFilterByAccepted) {
        setSearch({
          ...search,
          // @ts-ignore
          accepted: LIFE_TIME_FILTER[currentFilter],
        });
        setFilterName("");
        return;
      }

      if (isFilterByActive) {
        setSearch({
          ...search,
          // @ts-ignore
          active: ACTIVE_FILTER[currentFilter],
        });
        setFilterName("");
        return;
      }

      if (isFilterByType) {
        setSearch({
          ...search,
          // @ts-ignore
          type: TYPE_FILTER[currentFilter],
        });
        setFilterName("");
        return;
      }

      if (isSelectedFilterByIntegerValue) {
        setSearch({
          ...search,
          // @ts-ignore
          compare_total: DIRECTION_FILTER_COMPARISONS[currentFilter],
          // @ts-ignore
          [filters[filterName] + "_compare"]: DIRECTION_FILTER_COMPARISONS[currentFilter],
        });
        setFilterName(filterName);
        return;
      }

      if (value) {
        const newValues = new Set([...autocompleteValue, ...value]);
        // @ts-ignore
        setAutocompleteValue(Array.from(newValues));
      }

      if (defaultSearchFields) {
        Object.keys(defaultSearchFields).forEach((k) => {
          const isAppliedDefaultSearchFields = autocompleteValue.includes(k);
          if (!isAppliedDefaultSearchFields) {
            delete search[defaultSearchFields[k]];
          }
        });
      }
      setSearch({
        ...search,
        [filterId]: "",
      });
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSelectedFilterByIntegerValue && !search.compare_total) {
      return;
    }
    const { value } = event.target;
    setInputValue(value);

    const filterNameByKey = filters[filterName];
    if (filterName && filterNameByKey) {
      setSearch({
        ...search,
        [filterNameByKey]: value,
      });
    } else {
      if (defaultSearchFields) {
        const defaultFiltersNames = Object.keys(defaultSearchFields);
        const isAppliedDefaultSearchFields = autocompleteValue.some(
          (v) => v && defaultFiltersNames.includes(v)
        );

        if (!isAppliedDefaultSearchFields) {
          const filters: IProps["search"] = {};
          const defaultFields = Object.values(defaultSearchFields);
          defaultFields.forEach((field) => (filters[field] = value));
          setSearch({
            ...search,
            ...filters,
          });
        }
      }
    }
  };
  const handleChangeDateInput = (date: MaterialUiPickersDate) => {
    const filterNameByKey = filters[filterName];
    setInputValue(date ? date.format("YYYY-MM-DD") : "");
    setSearch({
      ...search,
      [filterNameByKey]: date ? date.format("YYYY-MM-DD") : null,
    });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue) {
      e.preventDefault();

      if (defaultSearchFields && !filterName) {
        const defaultFiltersNames = Object.keys(defaultSearchFields);
        const isAppliedDefaultSearchFields = autocompleteValue.some(
          (v) => v && defaultFiltersNames.includes(v)
        );
        const filters: IProps["search"] = {};
        defaultFiltersNames.forEach((field) => (filters[field] = inputValue));
        const updatedAutocompleteValues = new Set([...autocompleteValue, ...defaultFiltersNames]);
        if (isAppliedDefaultSearchFields) return;
        setAutocompleteValue(Array.from(updatedAutocompleteValues));
      }

      if (isSelectedFilterByIntegerValue && !search.compare_total) {
        return;
      }
      setFilterName("");
      setInputValue("");
    }
  };

  // Is open drop down
  const isShowOptions =
    isActivePlanFilter ||
    isSelectedFilterByIntegerValue ||
    isFilterByLifeTime ||
    isFilterByAccepted ||
    isFilterByActive ||
    isFilterByType ||
    (isFocused && !filterName);

  useEffect(() => {
    if (clearValuesFilter && clearValuesFilter > 1) {
      setAutocompleteValue([]);
      setFilterName("");
    }
  }, [clearValuesFilter]);

  return (
    <Autocomplete
      classes={{
        root: classes.filterContainer,
        popper: classes.popperRoot,
      }}
      value={autocompleteValue}
      open={isShowOptions}
      PopperComponent={PopperStyled}
      disabledItemsFocusable
      multiple
      filterSelectedOptions
      id="filterByColumn"
      onChange={handleChange}
      options={getAutocompleteOptions(plans, filters, filterName, inputTypes)}
      filterOptions={(options, state) =>
        getFilterOptions(options, state, filterName, search, filters)
      }
      inputValue={inputValue}
      renderTags={renderTags}
      renderInput={renderInput}
      fullWidth
      key={clearValuesFilter}
      freeSolo
      data-test="filter-autocomplete-component"
    />
  );
};

export default FilterAutocomplete;

import httpClient from "../apiClient";
import * as types from "./index.types";
import { TGetAccountsListByEmailRequest } from "./index.types";

export const ACCOUNTS_API_URLS = {
  accountsExtend: (id: number) => `accounts/extend/${id}`,
  addNote: () => "accounts/note",
  getNotes: "/accounts/getNotes",
  updateNote: "/accounts/updateNote",
  deleteNote: "/accounts/deleteNote",
  getSuspiciousNotes: "/accounts/suspicious-notes",
  addSuspiciousNotes: "/accounts/suspicious-notes/add",
  updateSuspiciousNotes: "/accounts/suspicious-notes/update",
  deleteSuspiciousNotes: "/accounts/suspicious-notes/delete",
  getAccountManagementData: "/accounts/management-data",
  getIpMonitoringData: "/accounts/monitoring/ip",
  accountPlans: "accounts/plans/all",
  getAccountsListByEmail: "accounts/search-accounts-by-list-emails",
} as const;

export class AccountsAPI {
  static getAccountsRequest: types.TGetAccountsRequest = (data, signal) =>
    httpClient.post("accounts", data, { signal });
  static getAccountsCsvRequest: types.TGetAccountsCsvRequest = () =>
    httpClient.post("accounts/csv");
  static deleteAccountRequest: types.TDeleteAccountRequest = ({ id, cus_id }) =>
    httpClient.post(`/accounts/delete/${id}/${cus_id}`);
  static getAccountsMessagesRequest: types.TGetAccountsMessagesRequest = () =>
    httpClient.post("accounts/getAccountsMessages");
  static updateAccountMessageRequest: types.TUpdateAccountMessageRequest = ({ id, label, html }) =>
    httpClient.post(`accounts/updateAccountsMessages/${id}`, { label, html });
  static getEmailsRequest: types.TGetEmailsRequest = () => httpClient.post("accounts/emails");
  static getCustomerPlansRequest: types.TGetCustomerPlansRequest = (id, plansPage) =>
    httpClient.post(`${ACCOUNTS_API_URLS.accountPlans}/${id}`, { page: plansPage });
  static getCustomerRequest: types.TGetCustomerRequest = (id) =>
    httpClient.post(ACCOUNTS_API_URLS.accountsExtend(id));
  static addAccountNotesRequest: types.TAddAccountNotesRequest = (data) =>
    httpClient.post(ACCOUNTS_API_URLS.addNote(), data);
  static updateAccountDataRequest: types.TUpdateAccountDataRequest = (id, data) =>
    httpClient.put(`accounts/${id}`, data);
  static getAccountNotesRequest: types.TGetAccountNotesRequest = (id, body) =>
    httpClient.post(`${ACCOUNTS_API_URLS.getNotes}/${id}`, body);
  static updateAccountNoteRequest: types.TUpdateAccountNoteRequest = (id, body) =>
    httpClient.post(`${ACCOUNTS_API_URLS.updateNote}/${id}`, body);
  static deleteAccountNoteRequest: types.TDeleteAccountNoteRequest = (id) =>
    httpClient.post(`${ACCOUNTS_API_URLS.deleteNote}/${id}`);
  static getAccountSuspiciousNotesRequest: types.TGetAccountNotesRequest = (id, data) =>
    httpClient.post(`${ACCOUNTS_API_URLS.getSuspiciousNotes}/${id}`, data);
  static addAccountSuspiciousNotesRequest: types.TAddAccountNotesRequest = (data) =>
    httpClient.post(ACCOUNTS_API_URLS.addSuspiciousNotes, data);
  static updateAccountSuspiciousNoteRequest: types.TUpdateAccountNoteRequest = (id, body) =>
    httpClient.post(`${ACCOUNTS_API_URLS.updateSuspiciousNotes}/${id}`, body);
  static deleteAccountSuspiciousNoteRequest: types.TDeleteAccountNoteRequest = (id) =>
    httpClient.post(`${ACCOUNTS_API_URLS.deleteSuspiciousNotes}/${id}`);
  static getSuspiciousNotesRequest: types.TGetSuspiciousNotesRequest = (data) =>
    httpClient.post(ACCOUNTS_API_URLS.getSuspiciousNotes, data);
  static getAccountManagementDataRequest: types.TGetAccountManagementDataRequest = (id) =>
    httpClient.post(`${ACCOUNTS_API_URLS.getAccountManagementData}/${id}`);
  static bunAccountRequest: types.TBunAccountRequest = (accounts_id, body) =>
    httpClient.post(`/accounts/ban-account/${accounts_id}`, body);
  static unBunAccountRequest: types.TUnBunAccountRequest = (accounts_id, body) =>
    httpClient.post(`/accounts/un-ban-account/${accounts_id}`, body);
  static getLiveAndLiveSimAccountsCountRequest: types.TGetLiveAndLiveSimAccountsCountRequest = () =>
    httpClient.post("/accounts/sim-live-accounts/count");
  static getIpMonitoringData: types.TGetIpMonitoringDataRequest = () =>
    httpClient.post(ACCOUNTS_API_URLS.getIpMonitoringData);
  static getAccountsListByEmailRequest: TGetAccountsListByEmailRequest = (query: string) =>
    httpClient.get(`${ACCOUNTS_API_URLS.getAccountsListByEmail}?${query}`);
}

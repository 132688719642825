import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Checkbox } from "@material-ui/core";

import { useComplianceSearchContext } from "../../../../../context";
import { TableDataCellWithSort } from "../../../../../components/ui";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";
import { CENTS_IN_DOLLAR, HOURS_12_FORMAT_WITH_DATE } from "../../../../../constants";
import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import useStyles from "../useStyles";
import { IntlFormatUSD } from "../../../../../utils";

export const COLUMNS: Array<IColum> = [
  {
    by: "selectAll",
    title: "",
    path: "",
  },
  {
    by: "id",
    title: "Account ID",
    path: "id",
  },
  {
    by: "total_sales",
    title: "Total sales",
    path: "total_sales",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "email",
  },
  {
    by: "reg_ip",
    title: "RegIp",
    path: "reg_ip",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
  {
    by: "country",
    title: "Country",
    path: "country.name",
  },
  {
    by: "zip",
    title: "Zip",
    path: "zip",
  },
  {
    by: "city",
    title: "City",
    path: "city",
  },
  {
    by: "adress",
    title: "Address",
    path: "address",
  },
  {
    by: "joined",
    title: "Joined",
    path: "joined",
    copyToClipBoardCallBack: (value) => moment(value).utc().format(HOURS_12_FORMAT_WITH_DATE),
  },
];

const TableHead = (): React.ReactElement => {
  const classes = useStyles();
  const {
    accounts_table: { sortBy, setSortBy, selectedRows, setSelectedRows },
  } = useComplianceSearchContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });
  const { data } = useSelector((state) => state.complianceSearch.accounts_table);

  const handleSelectUnselectAll = () => {
    if (selectedRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.rows.map((r) => ({ id: r.id })));
    }
  };

  return (
    <thead>
      <tr className={classes.fixedRow}>
        {COLUMNS.map((r) => {
          if (r.by === "selectAll") {
            return (
              <td key={r.by}>
                <Checkbox
                  checked={!!selectedRows.length}
                  color="default"
                  onChange={handleSelectUnselectAll}
                />
              </td>
            );
          }
          if (r.by === "billing_histories") {
            return <th key={r.by}>{r.title}</th>;
          }
          return (
            <TableDataCellWithSort
              key={r.by}
              sortBy={sortBy}
              by={r.by}
              title={r.title}
              newSortBy={handleSortBy}
            />
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;

import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";

import { IAllowedFeature } from "../../../../../Agent/constants";

interface IProps {
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
}
const FilterByAllowedFunctions: React.FC<IProps> = ({ selectedOptions, setSelectedOptions }) => {
  const polices = useSelector((state) => state.polices);
  const { optionsByCategory } = polices.data;

  const [options, setOptions] = useState<Record<string, IAllowedFeature>>({});
  useEffect(() => {
    let options: Record<string, IAllowedFeature> = {};
    const optionsByCategoryValues = Object.values(optionsByCategory);

    optionsByCategoryValues.forEach((category) => {
      options = { ...options, ...category };
    });

    const sortedOptions: Record<string, IAllowedFeature> = Object.keys(options)
      .sort((a, b) => options[a].label.localeCompare(options[b].label))
      .reduce<Record<string, IAllowedFeature>>((acc, key) => {
        acc[key] = options[key];
        return acc;
      }, {});

    setOptions(sortedOptions);
  }, [optionsByCategory]);

  return (
    <>
      <Autocomplete
        limitTags={4}
        value={selectedOptions}
        onChange={(_, newValue) => setSelectedOptions(newValue)}
        multiple
        disableCloseOnSelect
        options={Object.keys(options)}
        getOptionLabel={(option) => options[option].label}
        renderInput={(params) => <TextField {...params} label="Prop firm" variant="outlined" />}
      />
    </>
  );
};

export default FilterByAllowedFunctions;

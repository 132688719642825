import React from "react";
import { useParams } from "react-router-dom";

import TableDataCellWithSort from "../../../../../components/ui/TableDataCellWithSort";
import newSortBy from "../../../../../utils/newSortBy";
import { ISortBy } from "../../../../../types";

interface IProps {
  sortBy: ISortBy;
  setSortBy: (a: { by: string; order: boolean }) => void;
}
const TableHead: React.FC<IProps> = ({ sortBy, setSortBy }): React.ReactElement => {
  const { id } = useParams();
  const isShowCustomerInfoColumn = !id;

  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        <TableDataCellWithSort
          by="initialization_date"
          title="Date"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        {isShowCustomerInfoColumn && (
          <>
            <TableDataCellWithSort
              by="name"
              title="Name"
              sortBy={sortBy}
              newSortBy={handleSortBy}
            />
            <TableDataCellWithSort
              by="email"
              title="Customer"
              sortBy={sortBy}
              newSortBy={handleSortBy}
            />
          </>
        )}
        <TableDataCellWithSort
          by="e2t_account_id"
          title="Account ID"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="amount_requested"
          title="Amount requested"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="account_balance"
          title="Account Balance"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="watermark_level"
          title="Watermark Level"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <td>New Account Balance</td>
        <TableDataCellWithSort
          by="manual_deduction"
          title="Manual Deduction"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <td>Fee</td>
        <TableDataCellWithSort
          by="comment"
          title="Comment"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <td>Amount to be Paid</td>
        <TableDataCellWithSort
          by="changed_status_time"
          title="Changed status time"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="status"
          title="Status"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHead;

import httpClient from "../apiClient";

import {
  TCreateAffiliateAccountRequest,
  TGetAffiliateAccountRequest,
  TGetAffiliateCodeUsageRequest,
} from "./intex.types";

export const AFFILIATES_API_URLS = {
  addNewAffiliate: "/affiliates/add-new-affiliate",
  getAffiliates: "/affiliates/get-affiliate-accounts",
  getAffiliateCodeUsage: "/affiliates/get-affiliate-code-usage",
} as const;

export class AffiliatesAPI {
  static createAffiliateRequest: TCreateAffiliateAccountRequest = (body) =>
    httpClient.post(AFFILIATES_API_URLS.addNewAffiliate, body);
  static getAffiliatesRequest: TGetAffiliateAccountRequest = (body) =>
    httpClient.post(AFFILIATES_API_URLS.getAffiliates, body);
  static getAffiliateCodeUsageRequest: TGetAffiliateCodeUsageRequest = (urlParams: string) =>
    httpClient.get(`${AFFILIATES_API_URLS.getAffiliateCodeUsage}?${urlParams}`);
}

import httpClient from "../apiClient";
import {
  TGetRithmicDataRequest,
  TGetRithmicUserProductsRequest,
  TGetRithmicPastPositionsRequest,
  TGetRithmicUserBalanceOverTimeRequest,
  TGetRithmicAccountsData,
  TGetRithmicAccountsByCustomer,
} from "./index.types";
export const RITHMIC_API_URLS = {
  rithmic_accounts: "/rithmic/accounts",
} as const;

export class RithmicAPI {
  static getRithmicDataRequest: TGetRithmicDataRequest = (gauntlet_account_name, body) =>
    httpClient.post(`rithmic/${gauntlet_account_name}`, body);
  static getRithmicUserProductsRequest: TGetRithmicUserProductsRequest = (gauntlet_account_name) =>
    httpClient.post(`rithmic/user-products/${gauntlet_account_name}`);
  static getRithmicPastPositionsRequest: TGetRithmicPastPositionsRequest = (
    gauntlet_account_name,
    body
  ) => httpClient.post(`rithmic/past-positions/${gauntlet_account_name}`, body);
  static getRithmicUserBalanceOverTimeRequest: TGetRithmicUserBalanceOverTimeRequest = (
    gauntlet_account_name,
    symbol
  ) => httpClient.post(`rithmic/user-balance-over-time/${gauntlet_account_name}`, { symbol });
  static getRithmicAccountsData: TGetRithmicAccountsData = (data, signal) =>
    httpClient.post(RITHMIC_API_URLS.rithmic_accounts, data, { signal });
  static getRithmicAccountsByCustomer: TGetRithmicAccountsByCustomer = (email) =>
    httpClient.post(`${RITHMIC_API_URLS.rithmic_accounts}/${email}`);
}

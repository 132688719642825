import React from "react";
import { get } from "lodash";
import cx from "classnames";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core/";

import { useSearchCustomersContext } from "../../../../context";
import CardLast4 from "../CardLast4";
import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../constants";
import { CopyToClipboard } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";

const TableBody = (): React.ReactElement => {
  const classes = useStyles();
  const { relatedTables } = useSearchCustomersContext();
  const customers = useSelector((state) => state.customers.data.accounts);

  return (
    <tbody>
      {customers.map((customer) => {
        const expiresDate = get(customer, "account_plans.expires", "");
        const isValidDate = moment(expiresDate).isValid();

        const isLead =
          customer.status === 1 ||
          customer.status === 2 ||
          (customer.status === 0 && customer.address === null);
        const isInactive =
          (customer.status === 0 && customer.address) || !!customer.banned_accounts?.id;

        const isActive =
          (customer.status === 1 || customer.status === 2) &&
          customer.account_plans.status === "active";
        const isPassed = customer.account_plans.passed;

        return (
          <tr
            key={`${customer.id}`}
            className={cx({
              [classes.inactiveAccount]: isInactive,
              [classes.activeAccount]: isActive,
              [classes.leadAccount]: isLead,
              [classes.passedAccount]: isPassed,
            })}
          >
            <td>
              <CopyToClipboard value={customer.id} />
            </td>
            <td>
              <MenuItem
                component={Link}
                to={`/customer/${customer.id}`}
                className={classes.customerName}
              >
                {customer.name}
              </MenuItem>
            </td>
            <td>
              <CopyToClipboard value={customer.email} />
            </td>
            <td>{moment(customer.joined).utc().format(DATE_FORMAT)}</td>
            <td>{customer.country.name}</td>
            {relatedTables.includes("stateprov") && <td>{customer.stateprov}</td>}
            <td>{get(customer, "account_plans.plan.name", "")}</td>
            <td>{isValidDate ? moment(expiresDate).format(DATE_FORMAT) : "n/a"}</td>

            {relatedTables.includes("last4") && <CardLast4 last4={customer.last4} />}

            {relatedTables.includes("billing_histories") && (
              <td>{IntlFormatUSD(get(customer, "billing_histories.total", 0) / 100)}</td>
            )}

            {relatedTables.includes("account_notes") && (
              <td className={classes.notes}>
                {get(customer, "account_notes.note", "")
                  .split("|||")
                  .map((val: string) => (
                    <div key={val}>{val}</div>
                  ))}
              </td>
            )}

            {relatedTables.includes("account_agreements") && (
              <td>{get(customer, "account_agreements.status", "")}</td>
            )}

            {relatedTables.includes("account_flags") && (
              <td>{get(customer, "account_flags.flag", "")}</td>
            )}
            {relatedTables.includes("free_reset") && <td>{customer.free_reset}</td>}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button } from "@material-ui/core/";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import {
  ContainerWithLoader,
  PaginationWithLimit,
  StatusColorLegend,
} from "../../../../components/ui";

import { APIPaymentCards } from "../../../../api";
import {
  IPaymentCard,
  IUpdatePaymentCardRequestData,
} from "../../../../api/paymentCards/index.types";

import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import usePagination from "../../../../hooks/usePagination";
import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import { PAYMENT_CARDS_API_URLS } from "../../../../api/paymentCards";
import { STATUS_COLORS_OPACITY } from "../../../../constants";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import SharedIPsDialog from "./SharedICardsDialog";

const PaymentCards = (): React.ReactElement => {
  const { id: accounts_id } = useParams();
  const dispatch = useDispatch();
  const pagination = usePagination("5");
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const {
    loading,
    data: { payment_cards },
  } = useSelector((state) => state.customer.payment_cards);

  const fetchCustomerPaymentCards = useQuery({
    retry: false,
    queryKey: [
      PAYMENT_CARDS_API_URLS.getCustomerPaymentCards,
      accounts_id,
      pagination.limit,
      pagination.page,
    ],
    async queryFn() {
      dispatch(CustomerActions.fetchCustomerPaymentCardsRequest());
      const customer_cards = await APIPaymentCards.getCustomerPaymentCardsRequest(accounts_id, {
        limit: pagination.limit,
        page: pagination.page,
      });
      dispatch(CustomerActions.fetchCustomerPaymentCardsSuccess(customer_cards.data));
      return customer_cards;
    },
    onError(error) {
      dispatch(CustomerActions.fetchCustomerPaymentCardsFailed());
      console.error("Error get customer payment cards", error);
    },
  });

  const handleSubmitForm = async (formValues: { payment_cards: IPaymentCard[] }) => {
    try {
      const updated_payment_cards: IUpdatePaymentCardRequestData[] = [];
      formValues.payment_cards.forEach((card, index) => {
        const { active, def } = payment_cards.cards[index];
        if (active !== card.active || def !== card.def) {
          updated_payment_cards.push({
            id: card.id,
            active: card.active,
            def: card.def,
          });
        }
        return;
      });

      await APIPaymentCards.updatePaymentCardRequest(accounts_id, updated_payment_cards);
      await fetchCustomerPaymentCards.refetch();
    } catch (error) {
      console.error("Error update payment cards", error);
    }
  };

  const [selectedCardId, setSelectedCardId] = React.useState<number | null>(null);

  return (
    <div className={classes.container}>
      <h4>Payment cards</h4>
      <SharedIPsDialog
        card={selectedCardId}
        handleClose={() => {
          setSelectedCardId(null);
        }}
      />
      <div className={classes.legend}>
        <StatusColorLegend
          legend={{
            active: {
              color: STATUS_COLORS_OPACITY.lightGreen,
              name: "Active",
            },
            shared: {
              color: STATUS_COLORS_OPACITY.tango,
              name: "Shared card",
            },
          }}
        />
      </div>

      <ContainerWithLoader isLoading={loading} report={payment_cards.cards}>
        <Formik
          initialValues={{ payment_cards: payment_cards.cards }}
          enableReinitialize
          onSubmit={handleSubmitForm}
        >
          {({ values, resetForm, dirty }) => {
            return (
              <Form>
                <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
                  <TableHead />
                  <TableBody
                    payment_cards={values.payment_cards}
                    setSelectedCardId={setSelectedCardId}
                  />
                </table>

                {dirty && (
                  <div>
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="secondary"
                      size="small"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ContainerWithLoader>

      <PaginationWithLimit
        count={payment_cards.count}
        limit={pagination.limit}
        page={pagination.page}
        setPageState={pagination.setPage}
        setLimit={pagination.setLimit}
      />
    </div>
  );
};

export default PaymentCards;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  preloaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 240,
  },
});

export default useStyles;

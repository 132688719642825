import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  subAccount: {
    paddingLeft: 20,
  },
  gridCell: {
    display: "flex",
    alignItems: "center",
  },
  gridContainer: {
    marginLeft: 15,
    marginTop: 5,
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto auto",
    gridGap: 1,
  },
});

export default useStyles;

/* eslint-disable no-param-reassign */
import produce from "immer";
import { IPolicesState, ListOptions, PolicesAction, PolicesActionType } from "./index.types";
import { IPoliciesOptions } from "../../api/service/index.types";
import { IAllowedFeature } from "../../pages/Agent/constants";

export const initialState: IPolicesState = {
  data: {
    policiesOptions: [],
    presetsOptions: [],
    optionsByCategory: {},
  },
  loading: true,
};

const policesReducer = (prevState = initialState, action: PolicesAction): IPolicesState =>
  produce(prevState, (draft: IPolicesState): IPolicesState => {
    switch (action.type) {
      case PolicesActionType.fetchPolicesRequest:
        draft.loading = true;
        break;
      case PolicesActionType.fetchPolicesSuccess: {
        const listOptions: ListOptions = {};
        action.payload.policiesOptions.forEach(
          ({ id, name, category, parent_name, parent_id, option_name, tool_tip }) => {
            const isSubOption = !!parent_id;
            const isParentOption = !!parent_name;

            const option: Record<IPoliciesOptions["name"], IAllowedFeature> = {
              [name]: {
                name: `allowed_features.${name}`,
                label: option_name,
                isSubOption,
                ...(tool_tip && { contextualHelpText: tool_tip }),
                ...(isParentOption && {
                  group: parent_name,
                  subOptionsList: [
                    ...(isParentOption
                      ? [
                          ...action.payload.policiesOptions
                            .filter(({ parent_id }) => id === parent_id)
                            .map(({ name }) => name),
                        ]
                      : []),
                  ],
                }),
              },
            };

            if (!listOptions[category]) {
              listOptions[category] = {
                ...listOptions[category],
                ...option,
              };
            } else {
              listOptions[category] = {
                ...listOptions[category],
                ...option,
              };
            }
          }
        );

        draft.loading = false;
        draft.data.policiesOptions = action.payload.policiesOptions;
        draft.data.presetsOptions = action.payload.presetsOptions;
        draft.data.optionsByCategory = listOptions;
        break;
      }
      case PolicesActionType.fetchPolicesFailed:
        draft.loading = false;
        break;
      default:
        break;
    }
    return draft;
  });

export default policesReducer;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto",
    gridGap: 3,
  },
  tableGridItem: {
    display: "flex",
    alignItems: "center",
  },
});

export default useStyles;

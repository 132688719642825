import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@material-ui/core";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import { PaginationWithLimit, TableDataCellWithSort, TabPanel } from "../../../../components/ui";
import newSortBy from "../../../../utils/newSortBy";
import { ISortBy } from "../../../../types";
import useGeneralStyles from "../../../../useGeneralStyles";
import SharedIpsTable from "../SharedIpsTable";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";
import { IPaginationState } from "../../../../hooks/usePagination";
import { useCustomerProvider } from "../../../../context";

interface IProps {
  open: boolean;
  handleClose: () => void;
  sharedIpsPagination: IPaginationState;
  sharedRegIpsPagination: IPaginationState;
  sortBySharedIps: [ISortBy, React.Dispatch<React.SetStateAction<ISortBy>>];
  sortBySharedRegIp: [ISortBy, React.Dispatch<React.SetStateAction<ISortBy>>];
}

const SharedRegIpsTable: React.FC<IProps> = ({
  open,
  handleClose,
  sharedIpsPagination,
  sharedRegIpsPagination,
  sortBySharedIps,
  sortBySharedRegIp,
}) => {
  const history = useHistory();
  const { customer } = useCustomerProvider();
  const regularClasses = useGeneralStyles();
  const { sharedIps, sharedRegIp } = useSelector((state) => state.customer.ipsData);

  const [value, setValue] = useState(0);

  const handleChange = (_: unknown, newValue: number) => setValue(newValue);
  const handleSortBy = (by: string) =>
    newSortBy({ by, sortBy: sortBySharedRegIp[0], setSortBy: sortBySharedRegIp[1] });

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Shared IPs for: {customer.reg_ip}</DialogTitle>
      <DialogContent>
        <Tabs onChange={handleChange} value={value}>
          <Tab label="Logins" />
          <Tab label="Registration" />
        </Tabs>

        <TabPanel value={value} index={0}>
          <SharedIpsTable
            sortBy={sortBySharedIps[0]}
            setSortBy={sortBySharedIps[1]}
            data={sharedIps.data.rows}
            handleClose={handleClose}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <table className={cx(regularClasses.table)}>
            <thead>
              <tr>
                <TableDataCellWithSort
                  sortBy={sortBySharedRegIp[0]}
                  by="joined"
                  title="Joined"
                  newSortBy={handleSortBy}
                />
                <TableDataCellWithSort
                  sortBy={sortBySharedRegIp[0]}
                  by="email"
                  title="Email"
                  newSortBy={handleSortBy}
                />
              </tr>
            </thead>
            <tbody>
              {sharedRegIp.data.rows.map((data) => (
                <tr
                  key={data.id}
                  onClick={() => {
                    history.push(`/customer/${data.id}`);
                    handleClose();
                  }}
                >
                  <td>{moment(data.joined).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}</td>
                  <td>{data.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>

        <PaginationWithLimit
          count={value ? sharedRegIpsPagination.count : sharedIpsPagination.count}
          limit={value ? sharedRegIpsPagination.limit : sharedIpsPagination.limit}
          page={value ? sharedRegIpsPagination.page : sharedIpsPagination.page}
          setPageState={value ? sharedRegIpsPagination.setPage : sharedIpsPagination.setPage}
          setLimit={value ? sharedRegIpsPagination.setLimit : sharedIpsPagination.setLimit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SharedRegIpsTable;

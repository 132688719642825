import React from "react";
import { Form, Formik, Field } from "formik";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment_tz from "moment-timezone";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import useStyles from "./useStyles";
import { FormDatePicker, FormSelect, FormInput } from "../../../../components/ui";
import { AGENT_POSITION_OPTIONS, AGENT_SHIFT_OPTIONS } from "../../constants";
import { MANAGEMENT_VALIDATION_SCHEMA } from "./constants";
import { AGENTS_API_URLS, AgentsAPI } from "../../../../api/agents";
import { IAgent } from "../../../../api/agents/index.types";
import { getAvailableVocationDays, getCooperationDuration } from "./utils";
import { DATE_FORMAT } from "../../../../constants";
import { useAdminProviderDataContext } from "../../../../context";

const Management = (): React.ReactElement => {
  const classes = useStyles();
  const { data: agentData } = useSelector((state) => state.agent);
  const queryClient = useQueryClient();

  const { user } = useAdminProviderDataContext();
  const isAgent = user.position === "agent";

  const handleSubmit = useMutation({
    mutationKey: [`put/${AGENTS_API_URLS.agents}`, agentData.id],
    mutationFn: async (values: Partial<IAgent>) => {
      const response = await AgentsAPI.updateAgentRequest(values);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`get/${AGENTS_API_URLS.agents}`, agentData.id],
      });
    },
  });

  return (
    <div className={classes.container}>
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={MANAGEMENT_VALIDATION_SCHEMA}
        onSubmit={(values) => handleSubmit.mutate(values)}
        initialValues={{
          id: agentData.id,
          start_date: agentData.start_date || "",
          shift: agentData.shift || ("" as IAgent["shift"]),
          position: agentData.position || ("" as IAgent["position"]),
          used_vacation_allowance: agentData.used_vacation_allowance,
          preset: agentData.admin_accounts_policies_preset.name,
        }}
      >
        {({ isValid, setFieldValue, dirty, resetForm }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    disabled={isAgent}
                    name="start_date"
                    label="Start Date"
                    placeholder="Start Date"
                    component={FormDatePicker}
                    datePickerProps={{
                      maxDate: moment_tz(),
                      maxDateMessage: "",
                    }}
                    onChange={(data: MaterialUiPickersDate) => {
                      if (data) {
                        setFieldValue("start_date", data.format(DATE_FORMAT));
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="shift"
                    label="Shift"
                    placeholder="Shift"
                    options={AGENT_SHIFT_OPTIONS}
                    component={FormSelect}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="position"
                    label="Position"
                    disabled={isAgent}
                    placeholder="Position"
                    options={AGENT_POSITION_OPTIONS}
                    component={FormSelect}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className={classes.vacationInfo}>
                    <span>
                      {agentData.start_date && getCooperationDuration(agentData.start_date)}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="used_vacation_allowance"
                    label="Used vacation days"
                    placeholder="Used vacation allowance"
                    component={FormInput}
                    disabled={isAgent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.vacationInfo}>
                    Vacation allowance:{" "}
                    {agentData.start_date &&
                      getAvailableVocationDays(
                        agentData.start_date,
                        agentData.used_vacation_allowance
                      )}
                    {" Days"}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <Button
                    disabled={!dirty || !isValid}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    disabled={!dirty}
                    variant="contained"
                    color="primary"
                    onClick={() => resetForm()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Management;

import { useState } from "react";

export interface IPaginationState {
  count: number;
  limit: string;
  page: number;
  setCount: (count: number) => void;
  setLimit: (limit: string) => void;
  setPage: (page: number) => void;
}

const usePagination = (newLimit?: string): IPaginationState => {
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(newLimit || "20");
  const [page, setPage] = useState(0);

  return {
    count,
    limit,
    page,
    setCount,
    setLimit,
    setPage,
  };
};

export default usePagination;

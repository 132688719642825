import { HeliosAdminAction, HeliosAdminActionType } from "./index.types";
import { TGetLiveAndLiveSimAccountsCountResponse } from "../../api/accounts/index.types";

export const AdminActions = {
  fetchSimAndLiveSimAccountsRequest: (): HeliosAdminAction => ({
    type: HeliosAdminActionType.fetchSimAndLiveSimAccountsRequest,
  }),
  fetchSimAndLiveSimAccountsSuccess: (
    payload: TGetLiveAndLiveSimAccountsCountResponse
  ): HeliosAdminAction => ({
    type: HeliosAdminActionType.fetchSimAndLiveSimAccountsSuccess,
    payload,
  }),
  fetchSimAndLiveSimAccountsFailed: (): HeliosAdminAction => ({
    type: HeliosAdminActionType.fetchSimAndLiveSimAccountsFailed,
  }),
};

import * as Yup from "yup";

import { SPECIAL_CHARACTERS_REGEX, COMMA_REGEX } from "../../../utils/validation";

export const validateSchemaEmail = Yup.object().shape({
  email: Yup.string().required("Email is required").email(),
});
export const validateSchemaZip = Yup.object().shape({
  zip: Yup.string()
    .test("no-empty-comma", "Cannot contain commas", (value = "") => !COMMA_REGEX.test(value))
    .matches(SPECIAL_CHARACTERS_REGEX, "Invalid value, allowed symbols: - _ # &")
    .trim()
    .required("Zip is required")
    .min(1),
});
export const validateSchemaAddress = Yup.object().shape({
  address: Yup.string()
    .test("no-empty-comma", "Cannot contain commas", (value = "") => !COMMA_REGEX.test(value))
    .matches(SPECIAL_CHARACTERS_REGEX, "Invalid value, allowed symbols: - _ # &")
    .trim()
    .required("Address is required")
    .min(1)
    .max(100),
});
export const validateSchemaAddress2 = Yup.object().shape({
  address2: Yup.string()
    .test("no-empty-comma", "Cannot contain commas", (value = "") => !COMMA_REGEX.test(value))
    .matches(SPECIAL_CHARACTERS_REGEX, "Invalid value, allowed symbols: - _ # &")
    .trim()
    .max(30),
});

export const validateSchemaCity = Yup.object().shape({
  city: Yup.string()
    .test("no-empty-comma", "Cannot contain commas", (value = "") => !COMMA_REGEX.test(value))
    .matches(SPECIAL_CHARACTERS_REGEX, "Invalid value, allowed symbols: - _ # &")
    .trim()
    .required("City is required"),
});
export const validateSchemaStateprov = Yup.object().shape({
  stateprov: Yup.string()
    .test("no-empty-comma", "Cannot contain commas", (value = "") => !COMMA_REGEX.test(value))
    .matches(SPECIAL_CHARACTERS_REGEX, "Invalid value, allowed symbols: - _ # &")
    .trim()
    .required("Stateprov is required"),
});

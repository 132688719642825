import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    marginTop: 15,
    marginBottom: 20,
  },
  title: {
    display: "block",
    margin: "10px 0",
  },
});

export default useStyles;

import React, { createContext, useContext, useEffect, useState } from "react";

export interface SortBy {
  by: string;
  order: boolean;
}

export type ISearchBy = "id" | "expires" | "code" | "lifeTime" | "createdBy" | "showExpired";

export interface DiscountsContextProps {
  COLUMNS: Array<{
    text: string;
    name: string;
  }>;
  columns: string[];
  setColumns: (tableName: string[]) => void;
  sortBy: SortBy;
  setSortBy: (a: SortBy) => void;
  inactive: boolean;
  setInactive: (a: boolean) => void;
  search: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [key in ISearchBy]: any;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setSearch: (search: {
    expires?: string | null;
    code?: string;
    id?: number;
    lifeTime: string;
    createdBy?: string;
    showExpired: string;
  }) => void;
  page: number;
  _setPage: (a: number) => void;
  limit: string;
  _setLimit: (a: string) => void;
}

interface Props {
  children: React.ReactNode;
}

const COLUMNS = [
  {
    text: "Code",
    name: "code",
  },
  {
    text: "Description",
    name: "description",
  },
  {
    text: "User",
    name: "user",
  },
  {
    text: "Discount",
    name: "discount",
  },
  {
    text: "Discount (Past Expiration)",
    name: "discount_past_expiration",
  },
  {
    text: "Type",
    name: "type",
  },
  {
    text: "Price",
    name: "price",
  },
  {
    text: "Plan",
    name: "plan",
  },
  {
    text: "Expires",
    name: "expires",
  },
  {
    text: "Discount Duration / Termination Date",
    name: "expiration",
  },
  {
    text: "Lifetime",
    name: "lifetime",
  },
  {
    text: "Reset",
    name: "reset",
  },
  {
    text: "Used",
    name: "used",
  },
  {
    text: "Active",
    name: "active",
  },
  {
    text: "Accepted",
    name: "accepted",
  },
  {
    text: "Created by",
    name: "createdBy",
  },
  {
    text: "Created",
    name: "created",
  },
];

const COLUMNS_INITIAL_STATE = COLUMNS.map(({ name }) => (name !== "type" ? name : ""));

const DiscountsContext = createContext<DiscountsContextProps>({
  COLUMNS: [],
  columns: [],
  setColumns: () => null,
  sortBy: {
    by: "id",
    order: true,
  },
  setSortBy: () => null,
  inactive: false,
  setInactive: () => null,
  // @ts-ignore
  search: {
    showExpired: "Hide",
  },
  setSearch: () => null,
  page: 0,
  _setPage: () => null,
  limit: "25",
  _setLimit: () => null,
});

const DiscountsProvider = ({ children }: Props): React.ReactElement => {
  const [columns, setColumns] = useState<string[]>(COLUMNS_INITIAL_STATE);
  const [inactive, setInactive] = useState<boolean>(false);
  const [search, setSearch] = useState({
    showExpired: "Hide",
  });
  const [page, _setPage] = useState<number>(0);
  const [limit, _setLimit] = useState<string>("25");
  const [sortBy, setSortBy] = useState<SortBy>({
    by: "id",
    order: true,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      _setPage(0);
    }, 300);
    return () => clearTimeout(timeout);
  }, [search, limit, _setPage]);

  return (
    <DiscountsContext.Provider
      value={{
        COLUMNS,
        columns,
        setColumns,
        sortBy,
        setSortBy,
        inactive,
        setInactive,
        // @ts-ignore
        search,
        setSearch,
        page,
        _setPage,
        limit,
        _setLimit,
      }}
    >
      {children}
    </DiscountsContext.Provider>
  );
};

export default DiscountsProvider;
export const useDiscountsContext = (): DiscountsContextProps => useContext(DiscountsContext);

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  label: {
    backgroundColor: "#f2f2f2",
  },
  formControlContainer: {
    marginBottom: 5,
  },
  email: {
    marginLeft: 5,
  },
});

export default useStyles;

import React from "react";
import FilterAutocomplete from "../../../../components/ui/FilterAutocomplete";
import { RITHMIC_ACCOUNTS_FILTERS } from "./constants";
import { useRithmicAccountsContext } from "../../../../context";

const AccountsFilterAutocomplete = (): React.ReactElement => {
  const { search, setSearch } = useRithmicAccountsContext();

  return (
    <div>
      <FilterAutocomplete
        search={search}
        setSearch={setSearch}
        filters={RITHMIC_ACCOUNTS_FILTERS}
        inputTypes={{}}
      />
    </div>
  );
};

export default AccountsFilterAutocomplete;

import httpClient from "../apiClient";

import * as T from "./index.types";
import { TUpdateCustomerEvaluationCompleteHistory } from "./index.types";

export const EVALUATIONS_API_URLS = {
  getAllEvaluations: "/evaluations",
  getEvaluations: (e2tId: string) => `/evaluations/${e2tId}`,
  setEvaluationStep: "/evaluations/step",
  evaluationComplete: "/evaluations/complete",
  customerEvaluationCompleteHistory: "/evaluations/history",
} as const;

export class EvaluationsAPI {
  static getAllEvaluationsRequest: T.TGetAllEvaluationsRequest = (body, signal) =>
    httpClient.post(EVALUATIONS_API_URLS.getAllEvaluations, body, { signal });
  static getCustomerEvaluationsRequest: T.TGetCustomerEvaluationsRequest = (e2tId, email) =>
    httpClient.post(EVALUATIONS_API_URLS.getEvaluations(e2tId), { email });
  static setCustomerEvaluationsSteps: T.TSetCustomerEvaluationsSteps = (e2tId, body) =>
    httpClient.post(`${EVALUATIONS_API_URLS.setEvaluationStep}/${e2tId}`, body);
  static evaluationCompleteRequest: T.TCompeliteEvaluation = (e2tId: string, body) =>
    httpClient.post(`${EVALUATIONS_API_URLS.evaluationComplete}/${e2tId}`, body);
  static getCustomerEvaluationCompleteHistoryRequest: T.TGetCustomerEvaluationCompleteHistory = (
    query
  ) => httpClient.get(`${EVALUATIONS_API_URLS.customerEvaluationCompleteHistory}?${query}`);
  static updateCustomerEvaluationCompleteHistoryRequest: TUpdateCustomerEvaluationCompleteHistory =
    (body) => {
      return httpClient.patch(`${EVALUATIONS_API_URLS.customerEvaluationCompleteHistory}`, body);
    };
}

import moment from "moment-timezone";
import { DATE_FORMAT } from "../../../../../constants";

const getBooleanRepresentation = (value: boolean): string => {
  return value ? "✔" : "✘";
};

export const getStringLogs = (logs: string): string => {
  const logsObj = JSON.parse(logs);
  const getValue = (key: string, currentValue: string | []): string => {
    if (key === "intercom") {
      return Object.entries(currentValue)
        .map(([key, value]) => {
          return `\n    ${key}: ${
            key.toLowerCase().includes("date")
              ? value
                ? moment(+value * 100)
                    .tz("America/Chicago")
                    .format(DATE_FORMAT)
                : ""
              : value
          }`;
        })
        .join("");
    }
    if (key === "payment_cards" && Array.isArray(currentValue)) {
      return currentValue
        .map((card: { id: number; active: boolean; def: boolean }) => {
          return `\n    id:${card.id} active:${getBooleanRepresentation(
            card.active
          )} def:${getBooleanRepresentation(card.def)}`;
        })
        .join("");
    }

    if (["initialization_date", "on_vacation_until"].includes(key)) {
      if (moment(currentValue).isValid()) {
        return moment(currentValue).tz("America/Chicago").format(DATE_FORMAT);
      }
      return "";
    }

    const value = logsObj[key];
    if (typeof value === "boolean") {
      return getBooleanRepresentation(value);
    }
    return value;
  };

  return Object.keys(logsObj).length
    ? Object.keys(logsObj).reduce(
        (acc, currentValue) =>
          `${acc} ${currentValue}: ${getValue(currentValue, logsObj[currentValue])} \n`,
        ""
      )
    : "";
};

export const getMoneyValue = (log: string): number => {
  if (log) {
    const value = JSON.parse(log);
    if (value.price_usd) {
      return value.price_usd;
    }
  }

  return 0;
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import Table from "../../Table";
import useStyles from "../../../useStyles";
import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../../utils";
import { ComplianceAPI } from "../../../../../api/compliance";
import SearchByListValues from "../../SearchByListValues";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { getQueryStringByStringValues } from "../../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";
import { CENTS_IN_DOLLAR, DATE_FORMAT_hhmmssAzz } from "../../../../../constants";
import { useComplianceSearchContext } from "../../../../../context";
import { CopyToClipboard } from "../../../../../components/ui";

const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account id",
    path: "account.id",
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "name",
    title: "Name",
    path: "account.name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "transaction_id",
    title: "Transaction id",
    path: "id",
  },
  {
    by: "plan_type",
    title: "Plan type",
    path: "plan.name",
  },
  {
    by: "plan_id",
    title: "Plan id ",
    path: "plan.id",
  },
  {
    by: "price_usd",
    title: "Price usd",
    path: "price_usd",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
    getCellValue: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "stripe_ch",
    title: "Stripe ch",
    path: "stripe_ch",
  },
  {
    by: "purchase_date",
    title: "Purchase date",
    path: "purchase_date",
    getCellValue: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
    copyToClipBoardCallBack: (value) => moment(value).format(DATE_FORMAT_hhmmssAzz),
  },
  {
    by: "rebill",
    title: "Rebill",
    path: "rebill",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
    getCellValue: (value) => (value ? "true" : "false"),
  },
  {
    by: "reset",
    title: "Reset",
    path: "reset",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
    getCellValue: (value) => (value ? "true" : "false"),
  },
  {
    by: "free_reset",
    title: "Free reset",
    path: "free_reset",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
    getCellValue: (value) => (value ? "true" : "false"),
  },
  {
    by: "discount_code_used",
    title: "Used discount code",
    path: "discount_code.code",
  },
  {
    by: "discount_code_description",
    title: "Discount code description",
    path: "discount_code.desc",
  },
  {
    by: "discount_type",
    title: "Discount type",
    path: "discount_code.type",
  },
  {
    by: "discount_amount",
    title: "Discount amount",
    path: "discount_code.amt",
  },
  {
    by: "not_recurring",
    title: "Not recurring",
    path: "discount_code.one_time",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
    getCellValue: (value) => (value ? "true" : "false"),
  },
  {
    by: "no_discount",
    title: "No discount",
    path: "discount_code",
    copyToClipBoardCallBack: (value) => (value ? "false" : "true"),
    getCellValue: (value) => (value ? "true" : "false"),
  },
];

const AllPurchases = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { emails } = tabsTablesSearchState.quickSearchTab.allPurchasesTable.search;
  const { loading, data } = useSelector((state) => state.complianceSearch.purchasesHistory);

  const fetchData = async (values: { emails: string }) => {
    setTabsTablesSearchState({
      ...tabsTablesSearchState,
      quickSearchTab: {
        ...tabsTablesSearchState.quickSearchTab,
        allPurchasesTable: {
          search: { emails: values.emails },
        },
      },
    });

    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPurchasesRequest());
      const response = await ComplianceAPI.getAccountsPurchases(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchPurchasesSuccess(
          response.data.purchasesHistory
        )
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPurchasesFailed());
      console.log("error fetching AllPurchases data", error);
    }
  };

  return (
    <div className={classes.container}>
      <b className={classes.title}>Search all purchases</b>
      <SearchByListValues
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        initialValues={{ emails }}
      />

      <Table
        loading={loading}
        columns={COLUMNS}
        extractKey={(row) => row.id}
        data={data}
        csv_table_name="E2T quick search - Search all purchases"
      />
    </div>
  );
};

export default AllPurchases;

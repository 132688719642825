import produce from "immer";
import { AffiliatesAction, AffiliatesActionType, IAffiliatesState } from "./index.types";

const initialState: IAffiliatesState = {
  accounts: {
    loading: false,
    data: [],
    count: 0,
  },
};

const affiliatesReducer = (prevState = initialState, action: AffiliatesAction): IAffiliatesState =>
  produce(prevState, (draft: IAffiliatesState) => {
    switch (action.type) {
      case AffiliatesActionType.affiliateAccountsFetchRequest:
        draft.accounts.loading = true;
        break;
      case AffiliatesActionType.affiliateAccountsFetchSuccess:
        draft.accounts.loading = false;
        draft.accounts.data = action.payload.affiliates;
        draft.accounts.count = action.payload.count;
        break;
      case AffiliatesActionType.affiliateAccountsFetchFailed:
        draft.accounts.loading = false;
        break;
    }
    return draft;
  });

export default affiliatesReducer;

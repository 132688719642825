import moment from "moment";
import * as Yup from "yup";

import { ICustomerPlan } from "../../../../../../api/accounts/index.types";
import { CENTS_IN_DOLLAR, DATE_FORMAT } from "../../../../../../constants";

type THetExpirationData = (plan: ICustomerPlan) => {
  isOutExpiration: boolean;
  isNoOutExpiration: boolean;
  isHasExpiration: boolean;
  expirationDate: string;
};
export const getExpirationData: THetExpirationData = (plan) => {
  const expirationDiscountDate = plan.discount_code?.expiration;
  const isHasExpiration = !!expirationDiscountDate;

  const isUseDateExpiration = isNaN(parseInt(expirationDiscountDate as string));
  const isUseNumberDaysExpiration = isHasExpiration && !isUseDateExpiration;
  const expirationDate = isUseNumberDaysExpiration
    ? moment(plan.start).add(expirationDiscountDate, "days")
    : moment(expirationDiscountDate);

  const isOutExpiration = isHasExpiration && expirationDate < moment();
  const isNoOutExpiration = isHasExpiration && expirationDate >= moment();

  return {
    expirationDate: moment(expirationDate).format(DATE_FORMAT),
    isOutExpiration,
    isNoOutExpiration,
    isHasExpiration,
  };
};

type TGetCurrentPlanPrice = (plan: ICustomerPlan, billing_history: { price_usd: number }) => number;
export const getCurrentPlanPrice: TGetCurrentPlanPrice = (plan, billing_history) => {
  const { isOutExpiration, isNoOutExpiration, isHasExpiration } = getExpirationData(plan);

  const originalPlanPrice = plan.plan.price_usd;

  const currentPlanPrice = plan.price_usd / CENTS_IN_DOLLAR;
  const planPriceFormatUSD = currentPlanPrice; //IntlFormatUSD();

  const { discount_code } = plan;
  const altered = discount_code?.altered;

  const amt = discount_code?.amt;
  const type = discount_code?.type;

  const checkoutPrice = plan.discount_code
    ? billing_history.price_usd / 100 // IntlFormatUSD
    : planPriceFormatUSD;

  const getCurrentAlteredPlanPrice = () => {
    if (amt) {
      if (type === "percent") {
        return (originalPlanPrice - (originalPlanPrice * +amt) / CENTS_IN_DOLLAR) / CENTS_IN_DOLLAR;
      }
      return (originalPlanPrice - +amt * CENTS_IN_DOLLAR) / CENTS_IN_DOLLAR;
    }
  };

  const getCurrentPlanPriceBasedOnFailover = (): number => {
    const failover_amt = plan.discount_code?.failover_amt;
    const failover_type = plan.discount_code?.failover_type;

    if (failover_amt && failover_type) {
      if (failover_type === "flat") {
        return (originalPlanPrice - failover_amt * CENTS_IN_DOLLAR) / CENTS_IN_DOLLAR;
      } else {
        return (originalPlanPrice - (originalPlanPrice * failover_amt) / 100) / CENTS_IN_DOLLAR;
      }
    }
    return originalPlanPrice / CENTS_IN_DOLLAR; //IntlFormatUSD;
  };

  const isChangedPriceAfterExpiration =
    isHasExpiration && plan.price_usd !== billing_history.price_usd;

  if (isChangedPriceAfterExpiration) {
    // Returns the plan price if the price edited after expiration
    return currentPlanPrice;
  }

  if (altered) {
    return getCurrentAlteredPlanPrice() as number;
  }

  if (isOutExpiration) {
    return getCurrentPlanPriceBasedOnFailover();
  }

  if (isNoOutExpiration) {
    return checkoutPrice;
  }

  if (!isHasExpiration) {
    return planPriceFormatUSD;
  }

  return 0;
};

export const PLAN_VALIDATION_SCHEMA = Yup.object().shape({
  plans: Yup.array().of(
    Yup.object().shape({
      price_usd: Yup.number().required("Required"),
      expires: Yup.date().required("Start date is required").typeError("Invalid date"),
    })
  ),
});

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  foundUsers: {
    margin: "5px 0",
  },
  filtersWrapper: {
    display: "flex",
    marginTop: 15,
    marginBottom: 5,
  },
});

export default useStyles;

import httpClient from "../apiClient";
import {
  TUpdateDiscountCodeRequest,
  TGetDiscountCodesRequest,
  TGetCustomerDiscountCodesRequest,
  TGetDiscountCodesReportRequest,
  TSaveDiscountCodesRequest,
  TGetEmailsListRequest,
  TCreateBatchCodesRequest,
  TGetDiscountCodesByUsedUserRequest,
  TGetDiscountCodesLogChanges,
  TDiscountCodeActivateRequest,
} from "./index.types";
export const DISCOUNT_API_URLS = {
  customer_discounts: "/discounts/customerDiscounts",
  discounts: "discounts",
  discount_codes: "discounts/all",
  discount_codes_report: "billing/getDiscountCodesReport",
  emails_list: "discounts/emails/link",
  batch_create: "discountsBatchCustomers",
  discount_logs: "/discounts/log_changes",
  discounts_plans: "discounts/plans",
  activate_discount_code: "discounts/activate",
} as const;

export class DiscountsAPI {
  static updateDiscountCodeRequest: TUpdateDiscountCodeRequest = (data) =>
    httpClient.put(DISCOUNT_API_URLS.discounts, data);
  static getDiscountCodesRequest: TGetDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_codes, data);
  static getCustomerDiscountCodesRequest: TGetCustomerDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.customer_discounts, data);
  static getDiscountCodesReportRequest: TGetDiscountCodesReportRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_codes_report, data);
  static saveDiscountCodesRequest: TSaveDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discounts, data);
  static getEmailsListRequest: TGetEmailsListRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.emails_list, data);
  static createBatchCodesRequest: TCreateBatchCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.batch_create, data);
  static getDiscountCodesByUsedUserRequest: TGetDiscountCodesByUsedUserRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discounts_plans, data);
  static getDiscountCodesLogChanges: TGetDiscountCodesLogChanges = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_logs, data);
  static discountCodeActivateRequest: TDiscountCodeActivateRequest = (data) =>
    httpClient.put(DISCOUNT_API_URLS.activate_discount_code, data);
}

const downloadCsv = <Rows>(rows: Rows[][], tableName: string): void => {
  const csvContent = rows.map((e) => e.join(",")).join("\n");
  const blob = new Blob(["\ufeff", csvContent]);
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${tableName}.csv`);

  document.body.appendChild(link);
  link.click();
};

export default downloadCsv;

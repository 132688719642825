import React from "react";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { DATE_FORMAT } from "../../../../constants";
import { CUSTOMERS_FILTERS, INPUT_TYPES } from "../../constants";

import { PRIMARY_LANGUAGE_OPTIONS } from "../../../FundedTraders/utils";
import Select from "../../../../components/ui/Select";
import FilterAutocomplete from "../../../../components/ui/FilterAutocomplete";
import { useSearchCustomersContext } from "../../../../context";

const useStyles = makeStyles({
  filtersWrapper: {
    display: "flex",
    marginTop: 15,
    marginBottom: 5,
  },
  emailSearch: {
    width: 300,
  },
  regionContainer: {
    margin: "0 5px 10px 5px",
    width: 320,
  },
});

interface IProps {
  keyVal: number;
}
const CustomersFilters: React.FC<IProps> = ({ keyVal }) => {
  const classes = useStyles();
  const { search, setSearch } = useSearchCustomersContext();
  const handleChangeRegion = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSearch({ ...search, primaryLanguage: event.target.value as string });
  };
  const handleChangeJoined = (date: MaterialUiPickersDate) => {
    setSearch({
      ...search,
      joined: date ? date.format(DATE_FORMAT) : null,
    });
  };
  const handleChangeExpires = (date: MaterialUiPickersDate) => {
    setSearch({
      ...search,
      expires: date ? date.format(DATE_FORMAT) : null,
    });
  };

  return (
    <div className={classes.filtersWrapper}>
      <FilterAutocomplete
        clearValuesFilter={keyVal}
        search={search}
        setSearch={setSearch}
        filters={CUSTOMERS_FILTERS}
        inputTypes={INPUT_TYPES}
        defaultSearchFields={{
          Name: "name",
          Email: "email",
        }}
      />
      <div className={classes.regionContainer}>
        <Select
          inputLabel="Primary language"
          label="Primary language"
          value={search.primaryLanguage}
          onChange={handleChangeRegion}
          options={PRIMARY_LANGUAGE_OPTIONS}
        />
      </div>
      <KeyboardDatePicker
        inputVariant="outlined"
        label="Joined"
        name="joined"
        clearable
        value={search.joined || null}
        placeholder="2018/10/13"
        onChange={handleChangeJoined}
        format={DATE_FORMAT}
      />
      <KeyboardDatePicker
        inputVariant="outlined"
        label="Next bill"
        name="next_bill"
        clearable
        value={search.expires || null}
        placeholder="2018/10/13"
        onChange={handleChangeExpires}
        format={DATE_FORMAT}
      />
    </div>
  );
};

export default CustomersFilters;

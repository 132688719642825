import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { LineOptions } from "chart.js";
import { ChartDataset } from "chart.js";

import useStyles from "./useStyles";
import { isWeekend } from "../../../../../utils";
import { ChartLine } from "../../../../../components/ui";
import { TABLE_PLANS } from "../ExportCsvProductsStats/constants";
import { COLOR_PALETTE } from "../../../../../constants";
import { CHART_OPTIONS } from "../constants";
import { ISalesStatsByDayResponse } from "../../../../../api/billing/index.types";

interface IDataSet {
  label: string;
  data: (number | null)[];
  backgroundColor: string;
  borderColor: string;
  tension: number;
  borderDash?: Partial<LineOptions["borderDash"]>;
}
interface IProps {
  isIgnoreWeekends: boolean;
}

const calculateMovingAverage = (
  data: ISalesStatsByDayResponse["data"]["report"],
  plan: string,
  period: number
): (number | null)[] => {
  return data.map((item, index, arr) => {
    if (index < period - 1) {
      return null; // Not enough data for MA
    }

    const sum = arr.slice(index - period + 1, index + 1).reduce((acc, curr) => {
      const netNotUs = curr[`${plan.toLowerCase()}_net_not_us` as keyof typeof item] as number;
      const netUs = curr[`${plan.toLowerCase()}_net_us` as keyof typeof item] as number;

      return acc + netNotUs + netUs;
    }, 0);

    return Math.round(sum / period);
  });
};

const TotalIncomeChart: React.FC<IProps> = ({ isIgnoreWeekends }) => {
  const classes = useStyles();
  const { data: report } = useSelector((state: RootState) => state.financials.sales_by_days);

  const [maPeriod, setMaPeriod] = useState<number>(3);
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<IDataSet[]>([]);

  useEffect(() => {
    const data: IDataSet[] = [];
    const labels = new Set();

    TABLE_PLANS.forEach((plan, index) => {
      const planSales: IDataSet = {
        label: plan,
        data: [],
        backgroundColor: COLOR_PALETTE[index + 10],
        borderColor: COLOR_PALETTE[index + 10],
        tension: 0.2,
      };

      const planSalesMA: IDataSet = {
        label: plan + "MA",
        data: calculateMovingAverage(report, plan, maPeriod),
        backgroundColor: COLOR_PALETTE[index + 10],
        borderColor: COLOR_PALETTE[index + 10],
        tension: 0.2,
        borderDash: [5, 5],
      };

      report.forEach((item) => {
        const netNotUs = item[`${plan.toLowerCase()}_net_not_us` as keyof typeof item] as number;
        const netUs = item[`${plan.toLowerCase()}_net_us` as keyof typeof item] as number;

        if (!isIgnoreWeekends) {
          labels.add(item.date);
          planSales.data.push(netNotUs + netUs);
        }

        if (isIgnoreWeekends && !isWeekend(new Date(item.date))) {
          labels.add(item.date);
          planSales.data.push(netNotUs + netUs);
        }
      });
      data.push(planSales);
      data.push(planSalesMA);
    });

    setLabels(Array.from(labels) as string[]);
    setDatasets(data);
  }, [report, isIgnoreWeekends, maPeriod]);

  return (
    <div>
      <h3>Sales by days</h3>

      <TextField
        label="MA period"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={maPeriod}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setMaPeriod(+e.target.value);
        }}
      />

      <div className={classes.chartContainer}>
        <ChartLine
          data={{ labels, datasets: datasets as ChartDataset<"line", number[]>[] }}
          externalOptions={CHART_OPTIONS}
        />
      </div>
    </div>
  );
};

export default TotalIncomeChart;

export const REGULAR_EVALUATIONS_FILTERS = {
  "E2T Account ID": "acc_id",
  Customer: "account_name",
  Email: "email",
} as const;
export const EXTENDED_EVALUATIONS_FILTERS = {
  AccountBalance: "acc_balance",
  TotalPnl: "total_pnl",
  LargestPnl: "largest_pnl",
  Consistency: "consistency",
  DaysTraded: "daysTraded",
} as const;
export const INPUT_TYPES = {
  AccountBalance: "number",
  TotalPnl: "number",
  LargestPnl: "number",
  Consistency: "number",
  DaysTraded: "number",
} as const;

export enum E_EVALUATIONS_STEPS {
  CLOSE_TO_PASSING_TAB_INDEX,
  PASSED_TAB_INDEX,
  CERTIFICATE_DESIGN_TAB_INDEX,
  CERTIFICATE_EMAIL_TAB_INDEX,
  WAITING_FOR_OFFER_TAB_INDEX,
  WELCOMING_PROCESS,
}

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../pages/ComplianceSearch/components/Table";
import useStyles from "../../pages/ComplianceSearch/useStyles";
import usePagination from "../../hooks/usePagination";
import SearchByListValues from "../../pages/ComplianceSearch/components/SearchByListValues";
import { GM_CAPPERS_COLUMNS } from "../../pages/ComplianceSearch/constants";
import { ComplianceSearchActions } from "../ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../context";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../utils/validation";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../api/compliance";
import {
  appendCommaSeparatedValuesToQuery,
  TitleUpdated,
} from "../../pages/ComplianceSearch/utils";

const GMCappersSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const {
    data: { results, updated },
    loading,
  } = useSelector((state) => state.complianceSearch.gmCappers);

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { names } = tabsTablesSearchState.fundedAccountQuickSearchTab.gmCappersSearchTable.search;

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    enabled: !!names,
    queryKey: [COMPLIANCE_API_URLS.getGMCappers, names, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchGMCappersRequest());

      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(names, query, "names");

      const response = await ComplianceAPI.getGMCappersRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchGMCappersSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching GM cappers", error);
      dispatch(ComplianceSearchActions.fetchGMCappersFailed());
    },
  });

  return (
    <div>
      <b className={classes.title}>Gaunt Cappers</b>

      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            fundedAccountQuickSearchTab: {
              ...tabsTablesSearchState.fundedAccountQuickSearchTab,
              gmCappersSearchTable: {
                search: { names: values.names },
              },
            },
          });
        }}
        isLoading={loading}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={GM_CAPPERS_COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.timestamp} />}
        csv_table_name="Funded account quick search - Gaunt Cappers"
      />
    </div>
  );
};

export default GMCappersSearch;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    margin: 10,
  },
  centered: {
    display: "flex",
    alignItems: "center",
  },
  financialsIc: {
    marginRight: 10,
  },
  messageIcon: {
    height: 17,
    marginRight: 10,
  },
  affiliateContainerIc: {
    display: "flex",
    alignItems: "center",
  },
  affiliateTextContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
  },
});

export default useStyles;

import React, { useEffect } from "react";
import mtz from "moment-timezone";
import { Paper } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";

import useStyles from "./useStyles";
import useComponentsStyles from "../useStyles";

import usePagination from "../../../../hooks/usePagination";
import { EVALUATIONS_API_URLS, EvaluationsAPI } from "../../../../api/evaluations";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";
import { PASSES_STATUS_OPTIONS } from "./constants";
import { ContainerWithLoader, PaginationWithLimit, Select } from "../../../../components/ui";

const PassesHistory = (): React.ReactElement => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const componentsClasses = useComponentsStyles();
  const pagination = usePagination("5");

  const handleChangePage = (page: number) => pagination.setPage(page);

  const {
    isLoading,
    id,
    accounts_id,
    evaluationCompletedHistory: { data, isLoading: isLoadingEvaluationCompletedHistory },
  } = useSelector((state) => state.fundedTrader);

  const fetchEvaluationCompleteHistory = useQuery({
    enabled: !isLoading && !!id,
    queryKey: [
      EVALUATIONS_API_URLS.customerEvaluationCompleteHistory,
      id,
      pagination.limit,
      pagination.page,
    ],
    queryFn: async () => {
      dispatch(FundedTraderActions.fetchFundedTraderEvaluationCompletedHistoryRequest());

      const query = new URLSearchParams();
      query.set("funded_trader_id", id.toString());
      query.set("page", pagination.page.toString());
      query.set("limit", pagination.limit);
      const response = await EvaluationsAPI.getCustomerEvaluationCompleteHistoryRequest(
        query.toString()
      );
      pagination.setCount(response.data.count);
      dispatch(
        FundedTraderActions.fetchFundedTraderEvaluationCompletedHistorySuccess(response.data)
      );
      return response;
    },
    onError: (error) => {
      console.error("Error fetching funded trader data", error);
      dispatch(FundedTraderActions.fetchFundedTraderEvaluationCompletedHistoryFailed());
    },
  });

  const handleUpdatePassStatus = useMutation({
    mutationKey: [EVALUATIONS_API_URLS.customerEvaluationCompleteHistory, id],
    mutationFn: async ({ id, status }: { id: number; status: string }) => {
      const response = await EvaluationsAPI.updateCustomerEvaluationCompleteHistoryRequest({
        id,
        status,
        accounts_id,
      });
      return response;
    },
    onSuccess: () => {
      fetchEvaluationCompleteHistory.refetch();
      queryClient.invalidateQueries({
        queryKey: [
          EVALUATIONS_API_URLS.customerEvaluationCompleteHistory,
          "get-active-passed-accounts",
          accounts_id,
        ],
      });
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <Paper className={componentsClasses.paperContainer}>
      <div className={componentsClasses.title}>
        <b>Passes</b>
      </div>
      <ContainerWithLoader isLoading={isLoadingEvaluationCompletedHistory} report={data}>
        <div className={classes.tableContainer}>
          <div>
            <b>Date</b>
          </div>
          <div>
            <b>Account name</b>
          </div>
          <div>
            <b>Email</b>
          </div>
          <div>
            <b>Type & Size</b>
          </div>
          <div>
            <b>Status</b>
          </div>

          {data.map((d) => (
            <React.Fragment key={d.id}>
              <div className={classes.tableGridItem}>
                {mtz(d.timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}
              </div>
              <div className={classes.tableGridItem}>{d.e2t_account_id}</div>
              <div className={classes.tableGridItem}>{d.email}</div>
              <div className={classes.tableGridItem}>{d.type_and_size}</div>
              <div>
                <Select
                  disabled={handleUpdatePassStatus.isLoading}
                  size="small"
                  options={PASSES_STATUS_OPTIONS}
                  value={d.status || ""}
                  label="Status"
                  inputLabel="Status"
                  onChange={(
                    event: React.ChangeEvent<{ name?: string | undefined; value: unknown | string }>
                  ) =>
                    handleUpdatePassStatus.mutate({
                      id: d.id,
                      status: event.target.value as string,
                    })
                  }
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </ContainerWithLoader>
      <PaginationWithLimit
        count={pagination.count}
        limit={pagination.limit}
        setLimit={pagination.setLimit}
        page={pagination.page}
        setPageState={handleChangePage}
      />
    </Paper>
  );
};

export default PassesHistory;

import { IGetAllComplianceNotesResponse } from "../../api/compliance/index.types";

export type ComplianceWatchlistAction =
  | ComplianceWatchlistActionsFetchRequest
  | ComplianceWatchlistActionsFetchSuccess
  | ComplianceWatchlistActionsFetchFailed;

export interface ComplianceWatchlistActionsFetchRequest {
  type: ComplianceWatchlistActionType.fetchComplianceWatchlistRequest;
}
export interface ComplianceWatchlistActionsFetchSuccess {
  type: ComplianceWatchlistActionType.fetchComplianceWatchlistSuccess;
  payload: IGetAllComplianceNotesResponse["data"];
}
export interface ComplianceWatchlistActionsFetchFailed {
  type: ComplianceWatchlistActionType.fetchComplianceWatchlistFailed;
}

export enum ComplianceWatchlistActionType {
  fetchComplianceWatchlistRequest = "compliance/fetchComplianceWatchlistRequest",
  fetchComplianceWatchlistSuccess = "compliance/fetchComplianceWatchlistSuccess",
  fetchComplianceWatchlistFailed = "compliance/fetchComplianceWatchlistFailed",
}

export interface IComplianceWatchlistState {
  watchlist: {
    data: IGetAllComplianceNotesResponse["data"];
    loading: boolean;
  };
}

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import newSortBy from "../../../../utils/newSortBy";
import { useComplianceSearchContext } from "../../../../context";
import useGeneralStyles from "../../../../useGeneralStyles";

const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account Id",
    path: "payment_card.accounts_id",
    isSortable: true,
  },
  {
    by: "name",
    title: "Name on card",
    path: "payment_card.name",
    isSortable: true,
  },
  {
    by: "brand",
    title: "Brand",
    path: "payment_card.brand",
    isSortable: true,
  },
  {
    by: "last4",
    title: "Last4",
    path: "payment_card.last4",
    isSortable: true,
  },
  {
    by: "expires",
    title: "Expires",
    path: "payment_card.expires",
    isSortable: true,
  },
  {
    by: "partner",
    title: "Partner",
    path: "payment_card.partner",
    isSortable: true,
  },
  {
    by: "price_usd",
    title: "Sales value",
    path: "price_usd",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
    isSortable: true,
  },
  {
    by: "address",
    title: "Address",
    path: "payment_card.address",
    isSortable: true,
  },
  {
    by: "city",
    title: "City",
    path: "payment_card.city",
    isSortable: true,
  },
  {
    by: "state",
    title: "State",
    path: "payment_card.state",
    isSortable: true,
  },
  {
    by: "country",
    title: "Country",
    path: "payment_card.country",
    isSortable: true,
  },
  {
    by: "zip",
    title: "Zip",
    path: "payment_card.zip",
    isSortable: true,
  },
];

const PaymentCards = (): React.ReactElement => {
  const baseClasses = useStyles();
  const generalClasses = useGeneralStyles();

  const { data } = useSelector((state) => state.complianceSearch.paymentCards);
  const { accounts_table, paymentCards } = useSelector((state) => state.complianceSearch);

  const {
    payment_cards_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
      <Table
        theadTitle="Payment cards"
        loading={paymentCards.loading || accounts_table.loading}
        columns={COLUMNS}
        extractKey={(row) => row.id}
        data={data}
        csv_table_name="E2T Accounts Investigation - Payment cards"
        handleSortBy={handleSortBy}
        sortBy={sortBy}
      />
    </div>
  );
};

export default PaymentCards;

import { makeStyles } from "@material-ui/core";
import { STATUS_COLORS_OPACITY } from "../../../../constants";

const useStyles = makeStyles({
  tr: {
    height: 52,
  },
  forcedToLiveStatusBg: {
    "& td": {
      backgroundColor: `${STATUS_COLORS_OPACITY.purple} !important`,
    },
  },
});

export default useStyles;

import { PROCESSING_STATUSES_OPTIONS } from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export const OPTIONS = PROCESSING_STATUSES_OPTIONS.map(({ value, label }) => ({
  name: value,
  text: label,
}));

export const PRIMARY_LANGUAGE_OPTIONS = [
  {
    name: "all",
    text: "All",
  },
  {
    name: "spanish",
    text: "Spanish",
  },
  {
    name: "arabic",
    text: "Arabic",
  },
  {
    name: "english",
    text: "English",
  },
  {
    name: "portuguese",
    text: "Portuguese",
  },
  {
    name: "german",
    text: "German",
  },
  {
    name: "french",
    text: "French",
  },
  {
    name: "russian",
    text: "Russian",
  },
  {
    name: "chinese",
    text: "Chinese",
  },
];

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { useSelector } from "react-redux";
import moment from "moment";

import { SVGArrow } from "../../svgs";
import { useAdminProviderDataContext } from "../../../context";
import { useCustomerProvider } from "../../../context";
import { APIFunds } from "../../../api";
import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../constants";

const CustomerTitle = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { customer, setCustomer } = useCustomerProvider();
  const { banned_account, funded_trader } = useSelector((state) => state.customer.mainData.data);
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const {
    customerEvaluation: { days_traded, largest_pnl_percent, pnl, largest_pnl, timestamp },
    isPassedEvaluation,
  } = useCustomerProvider();
  const [isLoading, setLoading] = useState(false);
  const isShowSendToFundingButton =
    isPassedEvaluation && allowed_features.send_to_funding && !customer?.funded_trader;

  const handleOnClick = () => history.goBack();
  const handleSendToFunding = async () => {
    try {
      setLoading(true);
      const data = {
        pnl,
        consistency_interest: largest_pnl_percent,
        consistency_usd: largest_pnl,
        days_traded,
        timestamp,
      };
      const response = await APIFunds.sendTraderToFundsRequest(customer.id, data);
      if (response.data && setCustomer) {
        setCustomer({
          ...customer,
          funded_traders: [response.data],
        });
      }
    } catch (error) {
      console.log("error handle sendTraderToFundsRequest", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cx("title", classes.container)}>
      <div className={classes.arrowContainer}>
        <SVGArrow onClick={handleOnClick} />
        <div className={classes.customerNameContainer}>
          <div>
            <span className={classes.customerName}>
              {customer.name} {customer.display_name ? `(${customer.display_name})` : ""}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.banDataContainer}>
        {banned_account && (
          <div className={classes.banInfoContainer}>
            <b>Banned at E2T</b>
            <b>Banned on: {moment(banned_account.timestamp).format(DATE_FORMAT)}</b>
          </div>
        )}
        {funded_trader?.ban_date && (
          <div className={classes.banInfoContainer}>
            <b>Banned at Helios</b>
            <b>Banned on: {moment(funded_trader.ban_date).format(DATE_FORMAT)}</b>
          </div>
        )}
      </div>
      {!!customer.id && isShowSendToFundingButton && (
        <Button
          disabled={isLoading}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSendToFunding}
        >
          Send to Funding
        </Button>
      )}
      <div className={classes.customerId}>Intercom ID: {customer.xid}</div>
    </div>
  );
};

export default CustomerTitle;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles<null, { isEditing: boolean }>({
  valueContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ isEditing }) => (isEditing ? "space-between" : "flex-start"),
    margin: ({ isEditing }) => (isEditing ? "20px 0px 15px 0" : "0"),
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  value: {
    wordBreak: "break-all",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: "#1886cf",
      cursor: "pointer",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainContainer: {
    display: "flex",
    width: 265,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorContainer: {
    position: "absolute",
    left: 0,
    bottom: "-15px",
  },
});

export default useStyles;

import React from "react";
import { useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useStyles from "./useStyles";
import { FormInput } from "../../../../components/ui";
import { AGENTS_API_URLS, AgentsAPI } from "../../../../api/agents";
import { useAdminProviderDataContext } from "../../../../context";
import { AGENT_NOTE_VALIDATION_SCHEMA } from "../../constants";

interface IProps {
  pagination: {
    page: number;
    limit: string;
  };
}
const CreateNoteForm: React.FC<IProps> = ({ pagination }) => {
  const classes = useStyles();
  const admin = useAdminProviderDataContext();
  const agent = useSelector((state) => state.agent);
  const queryClient = useQueryClient();

  const addNote = useMutation({
    mutationKey: [`post/${AGENTS_API_URLS.agentsNotes}`, agent.data.id],
    mutationFn: async (values: { note: string; admin_accounts_id: number }) => {
      return await AgentsAPI.addAgentNote(agent.data.id, values);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          `get/${AGENTS_API_URLS.agentsNotes}`,
          agent.data.id,
          pagination.page,
          pagination.limit,
        ],
      });
    },
  });

  return (
    <Formik
      initialValues={{ note: "" }}
      validationSchema={AGENT_NOTE_VALIDATION_SCHEMA}
      onSubmit={(values, { resetForm }) => {
        addNote.mutate({
          note: values.note,
          admin_accounts_id: admin.user.id,
        });
        resetForm();
      }}
    >
      {({ isValid }) => (
        <Form>
          <Grid container>
            <Grid item xs={11}>
              <Field
                name="note"
                placeholder="Note"
                size="small"
                component={FormInput}
                multiline
                errorContainerClassName={classes.errorContainerCreateNote}
              />
            </Grid>
            <Grid item xs={1} className={classes.centerEnd}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid || agent.data.admin_accounts_notes.loading}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNoteForm;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";

import { LOGINS_API_URLS, LoginsAPI } from "../../../../api/logins";
import { useCustomerProvider } from "../../../../context";
import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import { ContainerWithLoader } from "../../../../components/ui";
import useStyles from "./useStyles";
import { SharedRegIpsTable } from "../index";
import usePagination from "../../../../hooks/usePagination";
import { ISortBy } from "../../../../types";

const SharedRegIps = (): React.ReactElement | null => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customer } = useCustomerProvider();

  const [isOpenSharedIps, setIsOpenSharedIps] = useState(false);
  const handleOpenSharedIps = () => setIsOpenSharedIps(!isOpenSharedIps);

  const { sharedIps, sharedRegIp } = useSelector((state) => state.customer.ipsData);

  const sharedIpsPagination = usePagination();
  const [sortBySharedIps, setSortBySharedIps] = useState<ISortBy>({
    by: "timestamp",
    order: false,
  });
  useQuery({
    enabled: !!customer.reg_ip,
    retry: false,
    queryKey: [
      `${LOGINS_API_URLS.sharedIps}/${customer.reg_ip}`,
      id,
      customer.reg_ip,
      sharedIpsPagination.limit,
      sharedIpsPagination.page,
      sortBySharedIps,
    ],
    queryFn: async () => {
      dispatch(CustomerActions.fetchCustomerSharedIpsRequest());
      const { data } = await LoginsAPI.getSharedIpsRequest(customer.reg_ip, {
        accounts_id: +id,
        sortBy: {
          by: sortBySharedIps.by,
          order: sortBySharedIps.order,
        },
        limit: sharedIpsPagination.limit,
        page: sharedIpsPagination.page,
      });
      dispatch(CustomerActions.fetchCustomerSharedIpsSuccess(data.sharedIps));
      sharedIpsPagination.setCount(data.sharedIps.count);
      return data;
    },
    onError: () => {
      dispatch(CustomerActions.fetchCustomerSharedIpsFailed());
    },
  });

  const sharedRegIpsPagination = usePagination();
  const [sortBySharedRegIp, setSortSharedRegIp] = useState<ISortBy>({
    by: "joined",
    order: false,
  });
  useQuery({
    retry: false,
    enabled: !!customer.reg_ip,
    queryKey: [
      LOGINS_API_URLS.sharedRegIp,
      id,
      customer.reg_ip,
      sharedRegIpsPagination.limit,
      sharedRegIpsPagination.page,
      sortBySharedRegIp,
    ],
    queryFn: async () => {
      dispatch(CustomerActions.fetchCustomerSharedRegIpsRequest());
      const { data } = await LoginsAPI.getSharedRegIpsRequest(customer.reg_ip, +id, {
        sortBy: {
          by: sortBySharedRegIp.by,
          order: sortBySharedRegIp.order,
        },
        limit: sharedRegIpsPagination.limit,
        page: sharedRegIpsPagination.page,
      });
      dispatch(CustomerActions.fetchCustomerSharedRegIpsSuccess(data.sharedRegIp));
      sharedRegIpsPagination.setCount(data.sharedRegIp.count);
      return data;
    },
    onError: () => {
      dispatch(CustomerActions.fetchCustomerSharedRegIpsFailed());
    },
  });

  const countSharedIps = sharedIps.data.count;
  const countSharedRegIp = sharedRegIp.data.count;

  const isLoading = sharedIps.loading || sharedRegIp.loading;

  if (!customer.reg_ip) {
    return null;
  }

  return (
    <div className={classes.container}>
      <SharedRegIpsTable
        sharedIpsPagination={sharedIpsPagination}
        sharedRegIpsPagination={sharedRegIpsPagination}
        sortBySharedIps={[sortBySharedIps, setSortBySharedIps]}
        sortBySharedRegIp={[sortBySharedRegIp, setSortSharedRegIp]}
        open={isOpenSharedIps}
        handleClose={handleOpenSharedIps}
      />

      <ContainerWithLoader
        noResultsMessage=""
        isLoading={!isOpenSharedIps && isLoading}
        report={[...sharedRegIp.data.rows, sharedIps.data.rows]}
      >
        <div className={classes.ipInfoContainer}>
          <div className={classes.ipInfoValues}>
            {!!countSharedRegIp && (
              <p className={classes.ipInfo}>
                Registration IP was used in <b>{countSharedRegIp}</b> other accounts for
                registration
              </p>
            )}
            {!!countSharedIps && (
              <p className={classes.ipInfo}>
                Registration IP was used <b>{countSharedIps}</b> times for login in other accounts
              </p>
            )}
          </div>

          {!!(countSharedRegIp || countSharedIps) && (
            <Button
              className={classes.getInfoButton}
              color="secondary"
              variant="contained"
              onClick={handleOpenSharedIps}
            >
              Get info
            </Button>
          )}
        </div>
      </ContainerWithLoader>
    </div>
  );
};

export default SharedRegIps;

import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import cx from "classnames";
import moment from "moment-timezone";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as PayPalIc } from "../../../assets/icons/pay_pal_ic.svg";
import { ReactComponent as StripeIc } from "../../../assets/icons/stripe_s_ic.svg";
import useGeneralStyles from "../../../useGeneralStyles";
import { getSumAllCharges, IntlFormatUSD } from "../../../utils";
import { ContainerWithLoader, CopyToClipboard, PaginationWithLimit } from "../index";
import { APICharges } from "../../../api";
import { ICharge } from "../../../api/charges/index.types";
import {
  HOURS_12_FORMAT,
  PAYPAL_CODE_TRANSACTION_STATUS,
  HOURS_12_FORMAT_WITH_DATE,
} from "../../../constants";
import usePagination from "../../../hooks/usePagination";
import useStyles from "./useStyles";
import { CURRENT_TIME_ZONE_ABBREVIATION, getTimeByAmericaChicagoTz } from "../../../utils/moment";
import { ReduxState } from "../../../reducers";
import { DATE_2_HOURS_AGO, getFilteredCharges } from "./utils";
import { latestFailedCharges } from "../../../reducers/latestFailedChargesReducer";

interface IPros {
  stripeCustomerId?: string;
  accountsId?: number;
}
const ChargesTable = ({ stripeCustomerId, accountsId }: IPros): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const pagination = usePagination();
  const params: { id: string | undefined } = useParams();
  const isCustomerData = !!stripeCustomerId || !!accountsId;

  const {
    data: {
      transactions: report,
      dbTransactionsInfo: dbTransactionsIds,
      failedStripeTransactions,
      customersByFailedPayment,
      transactionsByEmail,
    },
    loading: isLoading,
  } = useSelector((state: ReduxState) => (isCustomerData ? state.charges : state.latestCharges));

  const [isFilterByEmail, setIsFilterByEmail] = useState(false);
  const [currentItems, setCurrentItems] = useState<ICharge[]>([]);
  const filterBy = params.id ? "all" : "failed";

  const handleChangePage = (page: number) => pagination.setPage(page);

  const handleOpenCustomerPageById = (accountId: number): void =>
    history.push(`customer/${accountId}`);

  const handleSearchByCustomerName = (charge_id: string, cardHolderName: string): void => {
    const stripeCustomerId = failedStripeTransactions.find(({ id }) => id === charge_id)?.customer;
    if (stripeCustomerId) {
      let customer = customersByFailedPayment.find(
        ({ stripe_customer }) => stripe_customer === stripeCustomerId
      );
      if (!customer) {
        customer = customersByFailedPayment.find(({ name }) => name === cardHolderName);
      }
      if (customer) {
        history.push(`customer/${customer.id}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(latestFailedCharges.fetchRequest());
        const response = await APICharges.searchStripeCharges({
          query: `status:'failed' and created>${DATE_2_HOURS_AGO}`,
        });
        dispatch(latestFailedCharges.fetchSuccess(response.data));
      } catch (error) {
        console.error("Error fetching latestFailedCharges", error);
        dispatch(latestFailedCharges.fetchFailed());
      }
    })();
  }, [stripeCustomerId, accountsId]);

  const filterByEmail = () => {
    const listIdsByEmil = transactionsByEmail.map(({ id }) => id);
    return report.filter(({ id }) => listIdsByEmil.includes(id) || !id.includes("ch_"));
  };

  useEffect(() => {
    const { page, limit } = pagination;

    const reportByFilter = isFilterByEmail ? filterByEmail() : report;
    const data = getFilteredCharges(filterBy, reportByFilter, !!params.id);
    pagination.setCount(data.length);
    const newReport = data.slice(page * +limit, page * +limit + +limit);
    setCurrentItems(newReport);
  }, [pagination.page, report, pagination.limit, filterBy, isFilterByEmail]);

  return (
    <div>
      <div className={classes.tittleContainer}>{isCustomerData && <b>Charges</b>}</div>
      <div className={cx(generalClasses.containerBg, classes.scrollableContainer)}>
        <div className={classes.filterContainer}>
          {!isLoading && (
            <div>
              <div>
                <b data-test="transactions-count">Total transactions: {pagination.count}</b>
              </div>
              {isCustomerData && (
                <div className={classes.sumContainer}>
                  <div>
                    <b data-test="transactions-sum">
                      Sum:{" "}
                      {currentItems.length
                        ? IntlFormatUSD(
                            getSumAllCharges(isFilterByEmail ? filterByEmail() : report)
                          )
                        : 0}
                    </b>
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isFilterByEmail}
                          onChange={() => setIsFilterByEmail(!isFilterByEmail)}
                          name="isFilterByEmail"
                          color="primary"
                        />
                      }
                      label="Filter transactions based on email"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <ContainerWithLoader isLoading={isLoading} report={report}>
          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <thead>
              <tr>
                <td>Payment Type</td>
                <td>Date</td>
                <td>Amount</td>
                <td>Product</td>
                <td>Name</td>
                <td>last 4 digits</td>
                <td>Failure Code</td>
                <td>Failure Reason</td>
                <td>Seller Message</td>
                <td>Discount code</td>
                <td>Payment Id</td>
                <td>recorded in DB</td>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(
                ({
                  id,
                  date,
                  amount,
                  object,
                  stripe_failure_code,
                  outcome,
                  cardSource,
                  source,
                  status,
                  amount_refunded,
                  stripe_failure_message,
                }) => {
                  const getDbRecord = dbTransactionsIds.find((data) => data.stripe_ch === id);
                  const isChargeBelongsToEmail = transactionsByEmail?.find(
                    (charge) => charge.id === id
                  );
                  return (
                    <tr
                      key={id}
                      data-test="transaction-row"
                      className={cx({
                        [classes.failedPayment]:
                          stripe_failure_code || status === "failed" || status === "D",
                        [classes.refundPayment]: !!amount_refunded,
                        [classes.ownCharge]: !amount_refunded && isChargeBelongsToEmail,
                      })}
                    >
                      <td className={classes.paymentIcContainer}>
                        {source === "stripe" ? (
                          <StripeIc className={classes.paymentIc} />
                        ) : (
                          <PayPalIc className={classes.paymentIc} />
                        )}
                      </td>
                      <td>
                        <p className={classes.preWhiteSpace}>
                          {getTimeByAmericaChicagoTz(date * 1000, !!params.id)} CT
                        </p>
                        <p className={classes.preWhiteSpace}>
                          {moment(date * 1000).format(
                            params.id ? HOURS_12_FORMAT_WITH_DATE : HOURS_12_FORMAT
                          )}{" "}
                          {CURRENT_TIME_ZONE_ABBREVIATION}
                        </p>
                      </td>
                      <td className={generalClasses.noWrap}>
                        {amount_refunded
                          ? `- ${IntlFormatUSD(amount_refunded)}`
                          : IntlFormatUSD(amount)}
                      </td>
                      <td>{object}</td>
                      {params.id ? (
                        <td>{cardSource.name || "n/a"}</td>
                      ) : (
                        <td
                          className={generalClasses.tableHover}
                          onClick={() =>
                            getDbRecord?.account?.id
                              ? handleOpenCustomerPageById(getDbRecord.account.id)
                              : handleSearchByCustomerName(id, cardSource.name)
                          }
                        >
                          {cardSource.name || "n/a"}
                        </td>
                      )}
                      <td>{cardSource.last4 || "n/a"}</td>
                      <td>{stripe_failure_code}</td>
                      <td>{outcome?.reason}</td>
                      <td>
                        {/* @ts-ignore */}
                        {PAYPAL_CODE_TRANSACTION_STATUS[status]}
                        {outcome?.seller_message} {stripe_failure_message}
                      </td>
                      <td>{getDbRecord?.discount_code?.code}</td>
                      <td>
                        <CopyToClipboard value={id} />
                      </td>
                      <td>
                        {getDbRecord ? (
                          <span style={{ color: "green" }}>✔</span>
                        ) : (
                          <span style={{ color: "red" }}>✘</span>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
            <tbody />
          </table>
        </ContainerWithLoader>

        <div>
          <PaginationWithLimit
            count={pagination.count}
            limit={pagination.limit}
            setLimit={pagination.setLimit}
            page={pagination.page}
            setPageState={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ChargesTable;

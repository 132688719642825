import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { FilterAutocomplete } from "../../../../components/ui";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { HELIOS_AND_APPIUS_LIVE_TIF_COLUMNS } from "../../constants";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendSearchParamsToQuery, TitleUpdated } from "../../utils";

const HeliosAndAppiusLiveSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.complianceSearch.heliosAndAppiusLiveTif);

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } =
    tabsTablesSearchState.fundedAccountQuickSearchTab.heliosAndAppiusLiveSearchTable;

  useQuery({
    queryKey: [
      COMPLIANCE_API_URLS.getWatchlistHeliosAppiusLiveTif,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendSearchParamsToQuery(search, query);

      dispatch(ComplianceSearchActions.fetchWatchlistHeliosAndAppiusLiveTifRequest());
      const response = await ComplianceAPI.getWatchlistHeliosAppiusLiveTifRequest(
        query.toString(),
        signal
      );

      const {
        data: { rows },
      } = response;

      pagination.setCount(rows[0]?.total_count);
      dispatch(ComplianceSearchActions.fetchWatchlistHeliosAndAppiusLiveTifSuccess(response.data));

      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchWatchlistHeliosAndAppiusLiveTifFailed());
      console.error("Error fetch fetchWatchlistHeliosAndAppiusLiveTifRequest", error);
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Helios and Appius Live TIF</b>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              fundedAccountQuickSearchTab: {
                ...tabsTablesSearchState.fundedAccountQuickSearchTab,
                heliosAndAppiusLiveSearchTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
            Phone: "phone",
            Address: "address",
          }}
        />
      </div>

      <Table
        columns={HELIOS_AND_APPIUS_LIVE_TIF_COLUMNS}
        title={<TitleUpdated value={data.updated.submission_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id + row.timestamp}
        data={data.rows}
        csv_table_name="Funded account quick search - Helios and Appius Live TIF"
      />
    </div>
  );
};

export default HeliosAndAppiusLiveSearch;

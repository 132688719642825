import React from "react";
import cx from "classnames";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { IActivityLogsData } from "../../../api/agentActivityLog/index.types";
import useStyles from "./useStyles";
import TableHead from "./Components/TableHead";
import TableBody from "./Components/TableBody";
import { ContainerWithLoader, PaginationWithLimit, FilterAutocomplete } from "../index";
import { ISortBy } from "../../../types";
import { IPaginationState } from "../../../hooks/usePagination";
import {
  AGENT_LOGS_ACTIVITY_FILTERS,
  AGENT_LOGS_ACTIVITY_FILTERS_FOR_MANAGERS,
} from "../../../pages/Discounts/constants";
import { DATE_FORMAT } from "../../../constants";
import { useAdminProviderDataContext } from "../../../context";

interface ISearch {
  log_time: string;
  [index: string]: string | number;
}
interface IProps {
  loading: boolean;
  logs: IActivityLogsData[];
  count: number;
  pagination: Omit<IPaginationState, "setCount">;
  handleSort: [ISortBy, (sortBy: ISortBy) => void];
  handleSearch: [ISearch, (search: ISearch) => void];
  filters?: { [index: string]: string };
}
const LogsHistoryTable: React.FC<IProps> = ({
  loading,
  logs,
  pagination,
  count,
  handleSort,
  handleSearch,
  filters = AGENT_LOGS_ACTIVITY_FILTERS,
}) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = handleSort;
  const [search, setSearch] = handleSearch;
  const { user } = useAdminProviderDataContext();
  const handleChangeExpires = (date: MaterialUiPickersDate) => {
    setSearch({
      ...search,
      log_time: date ? date.format("YYYY-MM-DD") : "",
    });
  };

  return (
    <div className={cx("discounts", classes.mainContainer)}>
      <div className={classes.searchContainer}>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={{ ...filters, ...(user.is_manager && AGENT_LOGS_ACTIVITY_FILTERS_FOR_MANAGERS) }}
        />

        <KeyboardDatePicker
          inputVariant="outlined"
          label="Date"
          name="log_time"
          clearable
          value={search.log_time || null}
          placeholder="2018/10/10"
          onChange={handleChangeExpires}
          format={DATE_FORMAT}
          autoOk
        />
      </div>

      <p className={classes.totalRecords}>
        Logs found:<b>{count}</b>
      </p>

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={logs} noResultsMessage="There are no logs">
          <table className={classes.table}>
            <TableHead handleSort={[sortBy, setSortBy]} />
            <TableBody logs={logs} />
          </table>
        </ContainerWithLoader>
      </div>

      <div>
        <PaginationWithLimit
          count={pagination.count}
          limit={pagination.limit}
          page={pagination.page}
          setLimit={pagination.setLimit}
          setPageState={pagination.setPage}
        />
      </div>
    </div>
  );
};

export default LogsHistoryTable;

import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";

import useStyles from "./useStyles";
import { APIBilling } from "../../../../../api";
import { DATES_VALUE } from "../../../../../constants";
import { buildReport } from "./utils";
import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import { SelectByDateWithCustomPeriod } from "../../../../../components/ui";

interface IProps {
  isIgnoreWeekends: boolean;
}

const ExportCsvProductsStats: React.FC<IProps> = ({ isIgnoreWeekends }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: report, loading } = useSelector(
    (state: RootState) => state.financials.sales_by_days
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsAllTime(!!isAllTime);
  };

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [BILLING_API_URLS.getSalesStatsByDay, startDate, endDate, isAllTime],
    async queryFn({ signal }) {
      try {
        dispatch(FinancialsActions.fetchFinancialsSalesByDaysRequest());
        const query = new URLSearchParams();
        query.append("startDate", startDate);
        query.append("endDate", endDate);
        query.append("isAllTime", String(isAllTime));

        const response = await APIBilling.getSalesStatsByDayRequest(query.toString(), signal);
        dispatch(FinancialsActions.fetchFinancialsSalesByDaysSuccess(response.data.report));
        return response;
      } catch (error) {
        dispatch(FinancialsActions.fetchFinancialsSalesByDaysFailed());
      }
    },
  });

  const downloadReport = () =>
    buildReport(
      report,
      {
        startDate,
        endDate,
        isAllTime,
      },
      isIgnoreWeekends
    );

  return (
    <div>
      <div className={classes.datePickerContainer}>
        <SelectByDateWithCustomPeriod
          setIsLoading={() => void null}
          getReport={getReport}
          setReport={() => void null}
          initialDateBy={DATES_VALUE.thisWeek}
        />

        <Button
          className={classes.exportButton}
          variant="contained"
          color="primary"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} />}
          onClick={downloadReport}
        >
          Export CSV
        </Button>
      </div>
    </div>
  );
};

export default ExportCsvProductsStats;

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";
import { CopyToClipboard } from "../../../../components/ui";

const TableBody = (): React.ReactElement => {
  const {
    data: { rows },
  } = useSelector((state) => state.complianceWatchlist.watchlist);

  return (
    <tbody>
      {rows.map((r) => (
        <tr key={r.id}>
          <td>{r.id}</td>
          <td>
            <CopyToClipboard value={r.email} />
          </td>
          <td>{r.name}</td>
          <td>{r.reason}</td>
          <td>{r.measure}</td>
          <td>{moment(r.timestamp).format(DATE_FORMAT_hhmmssAzz)}</td>
          <td>{r.measure_num}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ContainerWithLoader, PaginationWithLimit } from "../../../../components/ui";
import { LoginsAPI, LOGINS_API_URLS } from "../../../../api/logins";
import usePagination from "../../../../hooks/usePagination";
import { SharedIpsTable } from "../index";
interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  ip: string;
}
const SharedIPsDialog: React.FC<IProps> = ({ ip, isOpen, handleClose }) => {
  const { id } = useParams();
  const { limit, setLimit, page, setPage, count, setCount } = usePagination();

  const [sortBy, setSortBy] = useState({
    by: "timestamp",
    order: false,
  });

  const data = useQuery({
    enabled: isOpen,
    queryKey: [`${LOGINS_API_URLS.sharedIps}/${ip}`, page, limit, sortBy],
    queryFn: async () => {
      const { data } = await LoginsAPI.getSharedIpsRequest(ip, {
        accounts_id: +id,
        limit,
        sortBy,
        page,
      });
      setCount(data.sharedIps.count);
      return data;
    },
  });

  useEffect(() => {
    setPage(0);
  }, [limit]);

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>Shared IPs for: {ip}</DialogTitle>

      <DialogContent>
        <ContainerWithLoader isLoading={data.isLoading} report={data.data?.sharedIps.rows || []}>
          <SharedIpsTable
            sortBy={sortBy}
            setSortBy={setSortBy}
            data={data.data?.sharedIps.rows || []}
            handleClose={handleClose}
          />
        </ContainerWithLoader>

        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setPageState={setPage}
          setLimit={setLimit}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharedIPsDialog;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { Field, Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";

import { APIAgents } from "../../../../api";
import useStyles from "./useStyles";
import { NEW_AGENT_VALIDATE_SCHEMA } from "./utils";
import { FormInput, AgentPresetsSelect, InputMessage } from "../../../../components/ui";

interface INewAgent {
  email: string;
  name: string;
  password: string;
  preset: string;
}
const CreateAgent = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useSelector((state) => state.polices);
  const { presetsOptions } = data;

  const [buttonText, setButtonText] = useState("Create");
  const [status, setStatus] = useState<string>("Create");

  const getSaveButtonStyle = () => {
    if (status === "Success!") {
      return "green";
    }
    if (status === "Error!") {
      return "red";
    }
    return "";
  };

  const handleSubmit = async (values: INewAgent) => {
    const { preset, ...newAgentData } = values;
    const selectedPreset = presetsOptions.find((option) => option.name === preset);
    try {
      const response = await APIAgents.createAgentRequest({
        ...newAgentData,
        admin_accounts_policies_presets_id: selectedPreset?.id as number,
      });
      if (response.status === 200) {
        setStatus("Success!");
        setTimeout(() => {
          history.push(`agent/${response.data.agent_id}`);
        }, 500);
      }
    } catch (error) {
      const { message } = error.response.data;
      setStatus("Error!");
      setButtonText(message);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStatus("Create");
      setButtonText("Create");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [status]);

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={NEW_AGENT_VALIDATE_SCHEMA}
        initialValues={{
          name: "",
          email: "",
          password: "",
          preset: "",
        }}
      >
        {({ setFieldValue, isValid, errors }) => {
          const errorPresetMessage = getIn(errors, "preset");
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    component={FormInput}
                    name="name"
                    data-test="input-name"
                    placeholder="Name"
                    label="Name"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={FormInput}
                    name="email"
                    data-test="input-email"
                    placeholder="Email"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={FormInput}
                    name="password"
                    data-test="input-password"
                    placeholder="Password"
                    label="Password"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.presetContainer}>
                    <AgentPresetsSelect
                      options={presetsOptions.filter((option) => option.name !== "custom")}
                      onChange={(preset: string) => {
                        setFieldValue("preset", preset, true);
                      }}
                    />
                    <div className={classes.errorContainer}>
                      {errorPresetMessage && <InputMessage error={errorPresetMessage} />}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.submitContainer}>
                <Grid item xs={6}>
                  <Button
                    disabled={!isValid}
                    data-test="submit-button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={getSaveButtonStyle()}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAgent;

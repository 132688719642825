import React, { useState } from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";

import TabPanel from "../../components/ui/TabPanel";
import AgentsList from "./components/AgentsList";
import CreateAgent from "./components/CreateAgent";
import useGeneralStyles from "../../useGeneralStyles";
import { useAdminProviderDataContext } from "../../context/AdminProviderData";

const Agents = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [activeTabIndex, setActiveTab] = useState(0);
  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  return (
    <div>
      <div>
        <Paper square>
          <Tabs
            scrollButtons="off"
            variant="scrollable"
            value={activeTabIndex}
            onChange={onChangeActiveTab}
          >
            <Tab label="All agents" value={0} />
            <Tab label="Add agent" value={1} disabled={!allowed_features.create_new_agent} />
          </Tabs>
        </Paper>
      </div>

      <div className={generalClasses.containerBg}>
        <TabPanel value={activeTabIndex} index={0}>
          <AgentsList />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <CreateAgent />
        </TabPanel>
      </div>
    </div>
  );
};

export default Agents;

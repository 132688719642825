import React from "react";
import TableDataCellWithSort from "../../../../../components/ui/TableDataCellWithSort";
import { ISortBy } from "../../../../../types";

interface IProps {
  sortBy: ISortBy;
  handleSortBy: (by: string) => void;
}
const TableHeader: React.FC<IProps> = ({ sortBy, handleSortBy }) => (
  <thead>
    <tr>
      <TableDataCellWithSort
        by="accounts_id"
        title="IID"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <TableDataCellWithSort
        by="account.name"
        title="Name"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <TableDataCellWithSort
        by="account.email"
        title="Email"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <TableDataCellWithSort
        by="evaluation_type"
        title="Evaluation Type"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <td>Funded trader status</td>
      <TableDataCellWithSort
        by="e2t_account_id"
        title="Account ID"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <TableDataCellWithSort
        by="processing_status"
        title="Processing status"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
      <TableDataCellWithSort
        by="invoice_status"
        title="Invoice status"
        sortBy={sortBy}
        newSortBy={handleSortBy}
      />
    </tr>
  </thead>
);

export default TableHeader;

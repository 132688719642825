import React from "react";
import SearchAccountsByName from "./components/SearchAccountsByName";
import SearchAccountsByPhone from "./components/SearchAccountsByPhone";
import EvaluationsRithmicAccounts from "../EvaluationsRithmicAccounts";

const AllAccountSearch = (): React.ReactElement => {
  return (
    <div>
      <SearchAccountsByName />
      <SearchAccountsByPhone />
      <EvaluationsRithmicAccounts />
    </div>
  );
};

export default AllAccountSearch;

import { ReduxAction } from "../../static-types";
import { IGoogleDriveCustomerFilesResponse } from "../../api/googleDrive/index.types";

export type FundedTraderFilesAction =
  | FundedTraderFilesFetchRequest
  | FundedTraderFilesFetchSuccess
  | FundedTraderFilesFetchFailed;

export enum FundedTraderFilesActionType {
  fundedTraderFilesFetchRequest = "fundedTraderFilesAccounts/FetchRequest",
  fundedTraderFilesFetchSuccess = "fundedTraderFilesAccounts/FetchSuccess",
  fundedTraderFilesFetchFailed = "fundedTraderFilesAccounts/FetchFailed",
}
export interface FundedTraderFilesFetchRequest extends ReduxAction {
  type: FundedTraderFilesActionType.fundedTraderFilesFetchRequest;
}
export interface FundedTraderFilesFetchSuccess extends ReduxAction {
  type: FundedTraderFilesActionType.fundedTraderFilesFetchSuccess;
  payload: IGoogleDriveCustomerFilesResponse["data"];
}
export interface FundedTraderFilesFetchFailed extends ReduxAction {
  type: FundedTraderFilesActionType.fundedTraderFilesFetchFailed;
}

export interface IFundedTraderFilesState {
  isLoading: boolean;
  listOfUploadedFiles: IGoogleDriveCustomerFilesResponse["data"]["listOfUploadedFiles"];
}

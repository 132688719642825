import { AgentAction, AgentActionType } from "./index.types";
import { IAgent, IGetAgentNotesResponse } from "../../api/agents/index.types";

export const AgentActions = {
  fetchAgentRequest: (): AgentAction => ({
    type: AgentActionType.fetchAgentRequest,
  }),
  fetchAgentSuccess: (payload: IAgent): AgentAction => ({
    type: AgentActionType.fetchAgentSuccess,
    payload,
  }),
  fetchAgentFailed: (): AgentAction => ({
    type: AgentActionType.fetchAgentFailed,
  }),

  fetchAgentNotesRequest: (): AgentAction => ({
    type: AgentActionType.fetchAgentNotesRequest,
  }),
  fetchAgentNotesSuccess: (payload: IGetAgentNotesResponse): AgentAction => ({
    type: AgentActionType.fetchAgentNotesSuccess,
    payload,
  }),
  fetchAgentNotesFailed: (): AgentAction => ({
    type: AgentActionType.fetchAgentNotesFailed,
  }),
};

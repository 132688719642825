import { EWithdrawalStatuses } from "../../constants";

export const WITHDRAWAL_FILTERS = {
  Name: "name",
  Email: "email",
  "Trading Account Name": "e2t_account_id",
};

export const WITHDRAWAL_STATUSES_OPTIONS = [
  {
    name: EWithdrawalStatuses.all,
    text: EWithdrawalStatuses.all,
  },
  {
    name: EWithdrawalStatuses.paid,
    text: EWithdrawalStatuses.paid,
  },
  {
    name: EWithdrawalStatuses.onHold,
    text: EWithdrawalStatuses.onHold,
  },
  {
    name: EWithdrawalStatuses.pending,
    text: EWithdrawalStatuses.pending,
  },
  {
    name: EWithdrawalStatuses.canceled,
    text: EWithdrawalStatuses.canceled,
  },
];

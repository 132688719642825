import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  profileImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    cursor: "pointer",
  },
  dropzone: {
    width: "100%",
    height: "100%",
  },
  thumbImage: {
    width: "100%",
  },
  rejectedFiles: {
    color: "red",
  },
  validateError: {
    padding: 5,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.9)",
    cursor: "default",
  },
  validateErrorText: {
    color: "#f01f1f",
  },
});

export default useStyles;

/* eslint-disable no-param-reassign */
import produce from "immer";

import { ReduxAction } from "../static-types";
import { ICharge, ISearchStripeFailedChargesResponseData } from "../api/charges/index.types";

export enum ELatestFailedChargesActionType {
  fetchRequest = "latestFailedCharges/FetchRequest",
  fetchSuccess = "latestFailedCharges/FetchSuccess",
  fetchFailed = "latestFailedCharges/FetchFailed",
  clearCharges = "latestFailedCharges/ClearCharges",
}

interface ILatestFailedChargesFetchRequest extends ReduxAction {
  type: ELatestFailedChargesActionType.fetchRequest;
}
interface ILatestFailedChargesFetchSuccess extends ReduxAction {
  type: ELatestFailedChargesActionType.fetchSuccess;
  payload: ISearchStripeFailedChargesResponseData;
}
interface ILatestFailedChargesFetchFetchFailed extends ReduxAction {
  type: ELatestFailedChargesActionType.fetchFailed;
}
interface ILatestFailedChargesClearCharges extends ReduxAction {
  type: ELatestFailedChargesActionType.clearCharges;
}

export type TLatestFailedChargesAction =
  | ILatestFailedChargesFetchRequest
  | ILatestFailedChargesFetchSuccess
  | ILatestFailedChargesFetchFetchFailed
  | ILatestFailedChargesClearCharges;

export const latestFailedCharges = {
  fetchRequest: (): TLatestFailedChargesAction => ({
    type: ELatestFailedChargesActionType.fetchRequest,
  }),
  fetchSuccess: (
    failedCharges: ISearchStripeFailedChargesResponseData
  ): TLatestFailedChargesAction => ({
    type: ELatestFailedChargesActionType.fetchSuccess,
    payload: failedCharges,
  }),
  fetchFailed: (): TLatestFailedChargesAction => ({
    type: ELatestFailedChargesActionType.fetchFailed,
  }),
  clearCharges: (): TLatestFailedChargesAction => ({
    type: ELatestFailedChargesActionType.clearCharges,
  }),
};

interface ILatestFailedChargesState {
  data: {
    transactions: ICharge[];
  };
  loading: boolean;
}

const initialState: ILatestFailedChargesState = {
  data: {
    transactions: [],
  },
  loading: false,
};

const latestFailedChargesReducer = (
  prevState = initialState,
  action: TLatestFailedChargesAction
): ILatestFailedChargesState =>
  produce(prevState, (draft: ILatestFailedChargesState) => {
    switch (action.type) {
      case ELatestFailedChargesActionType.fetchRequest:
        draft.loading = true;
        break;
      case ELatestFailedChargesActionType.fetchSuccess:
        draft.loading = false;
        draft.data = action.payload;
        break;
      case ELatestFailedChargesActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default latestFailedChargesReducer;

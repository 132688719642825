import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePagination from "../../../../../../hooks/usePagination";
import { ComplianceAPI } from "../../../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../../../reducers/ComplianceSearchReducer/actions";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../../../utils/validation";

import Table from "../Table";
import SearchByListValues from "../../../SearchByListValues";
import useStyles from "../../../../useStyles";
import { useComplianceSearchContext } from "../../../../../../context";

const SearchAccountsByPhone = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();
  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { phones } = tabsTablesSearchState.allAccountSearchTab.searchAccountsByPhone.search;

  const { loading, data } = useSelector((state) => state.complianceSearch.searchOnPhoneVariations);

  const handleChangePage = (page: number) => pagination.setPage(page);

  const fetchData = async (values: { phones: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchByPhoneRequest());
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      values.phones
        .trim()
        .replaceAll(", ", ",")
        .split(",")
        .forEach((value) => {
          query.append("phones", value.trim());
        });

      const response = await ComplianceAPI.getAllEntriesRequestByValues(query.toString());
      pagination.setCount(response.data.count);
      dispatch(ComplianceSearchActions.fetchComplianceSearchByPhoneSuccess(response.data));
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchByPhoneFailed());
      console.log("error SearchAccountsByPhone fetching data", error);
    }
  };

  useEffect(() => {
    (async () => {
      phones && (await fetchData({ phones }));
    })();
  }, [phones, pagination.page, pagination.limit]);

  useEffect(() => {
    pagination.setPage(0);
  }, [phones]);

  return (
    <div className={classes.container}>
      <b className={classes.title}>Search on phone numbers</b>

      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            allAccountSearchTab: {
              ...tabsTablesSearchState.allAccountSearchTab,
              searchAccountsByPhone: {
                search: { phones: values.phones },
              },
            },
          });
        }}
        isLoading={false}
        name="phones"
        label="Phone numbers"
        placeholder="+3..., +6..., e.t.c ..."
        initialValues={{ phones }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        handleChangePage={handleChangePage}
        pagination={pagination}
        data={data}
        loading={loading}
        table_name="E2T All accounts search - Search on phone numbers"
      />
    </div>
  );
};

export default SearchAccountsByPhone;

import httpClient from "../apiClient";

import {
  TGetAllChargesList,
  TGetCustomerChargesRequest,
  TSearchStripeCharges,
} from "./index.types";

export class ChargesAPI {
  static getAllChargesList: TGetAllChargesList = (data) => httpClient.post("/charges/all", data);
  static searchStripeCharges: TSearchStripeCharges = (data) =>
    httpClient.post("/charges/stripeSearchCharges", data);
  static getCustomerChargesRequest: TGetCustomerChargesRequest = (data) =>
    httpClient.post("/charges_customer/all", data);
}

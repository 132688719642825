import httpClient from "../apiClient";
import { TGetCountriesListRequest } from "./index.types";

export const COUNTRIES_API_URLS = {
  countries: "countriesList",
} as const;

export class CountriesAPI {
  static getCountriesListRequest: TGetCountriesListRequest = (searchText) =>
    httpClient.post(COUNTRIES_API_URLS.countries, { countryName: searchText });
}

import React, { useEffect } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import cx from "classnames";

import { CircularProgress, CopyToClipboard, PaginationWithLimit } from "../../../../components/ui";
import { UserDiscountCodesActions } from "../../../../reducers/userDiscountReducer";

import usePagination from "../../../../hooks/usePagination";
import { APIDiscounts } from "../../../../api";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import { DATE_FORMAT_hhmmAzz } from "../../../../constants";

const AssignedDiscountCodes = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const generalClasses = useGeneralStyles();
  const { id } = useParams();
  const { limit, setLimit, page, setPage } = usePagination();
  const { loading, data } = useSelector((state) => state.userDiscountCodes);
  const codes = data[id]?.codes || [];
  const count = data[id]?.count;

  useEffect(() => {
    if (+limit === 0) return;

    (async () => {
      dispatch(UserDiscountCodesActions.fetchRequest());
      try {
        const response = await APIDiscounts.getCustomerDiscountCodesRequest({
          id,
          limit,
          page,
        });
        dispatch(UserDiscountCodesActions.fetchSuccess(response.data, id));
      } catch (error) {
        console.error("error loading user discount codes", error);
        dispatch(UserDiscountCodesActions.fetchFailed());
      }
    })();
  }, [page, limit]);

  return (
    <div className={classes.mainContainer}>
      <h3>Discount codes</h3>
      <CircularProgress isLoading={loading} />

      {!loading && !codes.length && <span>There is no discount codes</span>}

      {!loading && !!codes.length && (
        <div>
          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <thead>
              <tr>
                <td>Code</td>
                <td>Plan</td>
                <td>Used</td>
                <td>Created</td>
              </tr>
            </thead>

            <tbody>
              {codes.map(({ code, name, purchase_date, created, index_row }) => (
                <tr key={index_row} data-test="discount-code-row">
                  <td>
                    <CopyToClipboard value={code} />
                  </td>
                  <td data-test="plan-name">{name}</td>
                  <td data-test="purchase-date">
                    {purchase_date &&
                      moment(purchase_date).tz("America/Chicago").format(DATE_FORMAT_hhmmAzz)}
                  </td>
                  <td data-test="created-date">
                    {created && moment(created).tz("America/Chicago").format(DATE_FORMAT_hhmmAzz)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <PaginationWithLimit
            count={count}
            limit={limit}
            page={page}
            setPageState={setPage}
            setLimit={setLimit}
          />
        </div>
      )}
    </div>
  );
};

export default AssignedDiscountCodes;

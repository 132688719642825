type ACTION_TYPE =
  | { type: "openDialog" }
  | { type: "closeDialog" }
  | { type: "onSettled" }
  | { type: "onSuccessResponse"; payload: { responseSuccessMessage: string } }
  | { type: "onErrorResponse"; payload: { responseErrorMessage: string } }
  | { type: "openConfirmationForBannedAccounts" };

export const initialState = {
  open: false,
  openConfirmation: false,
  openSnackbar: false,
  responseSuccessMessage: "",
  responseErrorMessage: "",
};
const stateReducer: StateReducer<typeof initialState, ACTION_TYPE> = (state, action) => {
  switch (action.type) {
    case "openDialog":
      return { ...state, open: true };
    case "closeDialog":
      return { ...state, open: false, openConfirmation: false };
    case "onSuccessResponse":
      return {
        ...state,
        responseSuccessMessage: action.payload.responseSuccessMessage,
        open: false,
        openSnackbar: true,
      };
    case "onErrorResponse":
      return {
        ...state,
        open: false,
        openSnackbar: true,
        responseErrorMessage: action.payload.responseErrorMessage,
      };
    case "onSettled":
      return {
        ...state,
        openSnackbar: false,
      };
    case "openConfirmationForBannedAccounts":
      return {
        ...state,
        openConfirmation: true,
      };
    default:
      return state;
  }
};

export default stateReducer;

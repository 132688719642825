import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  scrollableContainer: {
    overflow: "scroll",
  },
  tittleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sumContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export default useStyles;

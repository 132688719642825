import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: unknown;
  value: unknown;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}): React.ReactElement => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
);

export default TabPanel;

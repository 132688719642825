import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import { useAuthContext, useSearchCustomersContext } from "../../context";
import TableBody from "./components/TableBody";
import TableHeader from "./components/TableHeader";
import { setPage } from "../../utils";
import useStyles from "./useStyles";
import { ContainerWithLoader, PaginationWithLimit, StatusColorLegend } from "../../components/ui";
import ViewTableControlsAndActions from "./components/ViewTableControlsAndActions";
import CustomersFilters from "./components/CustomersFilters";
import { getCustomer } from "./utils";
import useGeneralStyles from "../../useGeneralStyles";
import { STATUSES_COLORS } from "./constants";

const Customers = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const searchCustomersContext = useSearchCustomersContext();
  const {
    search,
    page,
    setSearch,
    limit,
    sortBy,
    isShowActiveSubscription,
    isShowLeads,
    setLimit,
    setPage: setPageContext,
    relatedTables,
    isShowInactiveAccounts,
    isShowPassedEvaluations,
  } = searchCustomersContext;
  const { loginAndQuery, hasExpired } = useAuthContext();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.data);
  const customerCount = useSelector((state) => state.customers.data.count);
  const { loading, data } = useSelector((state) => state.customers);

  const [key, setKey] = useState(1);

  const handleSetPage = (value: number) => setPage(value, customerCount, +limit, setPageContext);

  useEffect(() => {
    setSearch(search);
    const controller = new AbortController();
    const { signal } = controller;

    loginAndQuery(
      // @ts-ignore
      getCustomer.bind(
        null,
        searchCustomersContext,
        customerCount,
        hasExpired,
        customers,
        false,
        dispatch,
        signal,
        false
      )
    );
    return () => {
      controller.abort();
    };
  }, [
    Object.values(search).join(""),
    limit,
    isShowLeads,
    isShowActiveSubscription,
    isShowInactiveAccounts,
    isShowPassedEvaluations,
    relatedTables,
    page,
    sortBy,
  ]);

  return (
    <div className="customers">
      <ViewTableControlsAndActions key={key} keyVal={key} setKey={setKey} />
      <CustomersFilters keyVal={key} />

      <div className={classes.bottomControls}>
        <p className={classes.foundUsers}>
          Users found:<b>{customers.count}</b>
        </p>
        <StatusColorLegend legend={STATUSES_COLORS} />
      </div>

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={data.accounts}>
          <table className={cx(generalClasses.table, classes.table)}>
            <TableHeader />
            <TableBody />
          </table>
        </ContainerWithLoader>
      </div>

      <div className={classes.bottomControls}>
        <PaginationWithLimit
          count={customerCount}
          limit={limit}
          page={page}
          setPageState={handleSetPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default Customers;

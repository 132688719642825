import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { useCustomerProvider } from "../../../../context";
import { FormCheckbox } from "../../../../components/ui";
import { APIEvaluation } from "../../../../api";
import { ICustomerEvaluationsSteps } from "../../../../api/evaluations/index.types";

const EvaluationJourney = (): React.ReactElement | null => {
  const { customer, isPassedEvaluation, evaluation_steps, loading, setEvaluationSteps } =
    useCustomerProvider();

  const initialValues = {
    confirm_passed: evaluation_steps?.confirm_passed || false,
    certificated_design: evaluation_steps?.certificated_design || false,
    certificated_email: evaluation_steps?.certificated_email || false,
    welcoming_process: evaluation_steps?.welcoming_process || false,
  };
  const handleSubmitForm = async (
    values: ICustomerEvaluationsSteps,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      const e2tId = customer.intercom?.custom_attributes?.["Gauntlet Account Name"];
      const { confirm_passed, certificated_design, certificated_email, welcoming_process } = values;
      const response = await APIEvaluation.setCustomerEvaluationsSteps(e2tId, {
        accounts_id: customer.id,
        ...(initialValues.confirm_passed !== confirm_passed && { confirm_passed }),
        ...(initialValues.certificated_design !== certificated_design && { certificated_design }),
        ...(initialValues.certificated_email !== certificated_email && { certificated_email }),
        ...(initialValues.welcoming_process !== welcoming_process && { welcoming_process }),
      });
      setEvaluationSteps({ ...response.data });
      resetForm();
    } catch (error) {
      console.error("Error handle update evaluation steps");
    }
  };

  if (!isPassedEvaluation && loading.customer !== 2) {
    return null;
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <b>Evaluation journey</b>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validateOnChange
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {({ dirty }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <Field name="confirm_passed" label="Confirm passed" component={FormCheckbox} />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="certificated_design"
                    label="Certificated design"
                    component={FormCheckbox}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="certificated_email"
                    label="Certificated email"
                    component={FormCheckbox}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="welcoming_process"
                    label="Waiting for Offer"
                    component={FormCheckbox}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" type="submit" disabled={!dirty}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EvaluationJourney;

import React from "react";
import { PAYMENTS_STATUSES } from "../../../../constants";

interface IProps {
  groupBy: string;
}

const TableHeader = ({ groupBy }: IProps): React.ReactElement => (
  <thead>
    <tr>
      <td>Date</td>
      <td>Payment amount</td>
      <td>IID</td>
      <td>Name</td>
      <td>Email</td>
      <td>Object</td>
      <td>Description</td>
      <td>Failure code</td>
      <td>Failure message</td>
      <td>Seller message</td>
      {groupBy === PAYMENTS_STATUSES.all.status && <td>Status</td>}
    </tr>
  </thead>
);

export default TableHeader;

import React, { useEffect } from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  getAmountToBePay,
  getNewAccountBalance,
} from "../WithdrawalRequestFormWithRequestsList/utils";
import { APIFunds } from "../../../../api";
import {
  ICreateWithdrawalRequestData,
  IWithdrawalWithAccountInfo,
} from "../../../../api/funds/index.types";
import { IntlFormatUSD } from "../../../../utils";
import useStyles from "./useStyles";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { initialWithdrawalsValue, withdrawalsSchema } from "./constants";
import { CENTS_IN_DOLLAR } from "../../../../constants";

import {
  ContainerWithLoader,
  FormInput,
  FormSelect,
  FormDatePicker,
  FormCheckbox,
} from "../../../../components/ui";

interface IProps {
  accounts_id: number;
  setWithdrawal?: (a: { id: number }) => void;
  editValues?: IWithdrawalWithAccountInfo;
  onClose?: () => void;
}
interface ICreateWithdrawalWithOnHoldProp extends ICreateWithdrawalRequestData {
  isOnHold: boolean;
}
const WithdrawalRequestForm: React.FC<IProps> = ({
  accounts_id,
  setWithdrawal,
  editValues,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    tradedAccounts: { isLoading: isFetchingTradedAccounts },
    fundedAccounts,
  } = useSelector((state) => state.fundedTrader);

  const handleSubmitForm = async (
    values: ICreateWithdrawalWithOnHoldProp,
    formikHelpers: FormikHelpers<ICreateWithdrawalWithOnHoldProp>
  ) => {
    try {
      values.status = values.isOnHold ? "on hold" : "pending";
      values.net_withdrawal_amount = getAmountToBePay(values).amountToPay;

      values.amount_requested = values.amount_requested * CENTS_IN_DOLLAR;
      values.account_balance = values.account_balance * CENTS_IN_DOLLAR;
      values.manual_deduction = values.manual_deduction * CENTS_IN_DOLLAR;
      values.manual_withdrawal_amount = values.manual_withdrawal_amount * CENTS_IN_DOLLAR;
      values.net_withdrawal_amount = values.net_withdrawal_amount * CENTS_IN_DOLLAR;

      if (editValues) {
        const { isOnHold, ...rest } = values;
        const response = await APIFunds.editWithdrawalRequest(rest);
        onClose && onClose();
        setWithdrawal && setWithdrawal(response.data.withdrawal);
      } else {
        const response = await APIFunds.createWithdrawalRequest(values);
        setWithdrawal && setWithdrawal(response.data.withdrawal);
        formikHelpers.resetForm();
      }
    } catch (error) {
      console.error("error creating withdrawal request", error);
    }
  };

  useEffect(() => {
    if (editValues) {
      (async () => {
        try {
          dispatch(FundedTraderActions.fetchFundedTraderAccountsRequest());
          const response = await APIFunds.getTradedAccountsRequest(editValues.accounts_id);
          dispatch(FundedTraderActions.fetchFundedTraderAccountsSuccess(response));
        } catch (error) {
          console.error("Error getting traded accounts", error);
        } finally {
          dispatch(FundedTraderActions.fetchFundedTraderAccountsFailed());
        }
      })();
    }
  }, [editValues]);

  return (
    <ContainerWithLoader
      isLoading={isFetchingTradedAccounts}
      report={fundedAccounts}
      noResultsMessage="To make a withdrawal request requires at least one Funded account"
    >
      <Formik
        initialValues={{
          ...initialWithdrawalsValue,
          accounts_id,
          ...(editValues && {
            ...editValues,
            amount_requested: editValues.amount_requested / CENTS_IN_DOLLAR,
            account_balance: editValues.account_balance / CENTS_IN_DOLLAR,
            manual_deduction: editValues.manual_deduction / CENTS_IN_DOLLAR,
            manual_withdrawal_amount: editValues.manual_withdrawal_amount / CENTS_IN_DOLLAR,
            net_withdrawal_amount: editValues.net_withdrawal_amount / CENTS_IN_DOLLAR,
            isOnHold: editValues.status === "on hold",
          }),
        }}
        onSubmit={handleSubmitForm}
        validateOnChange
        enableReinitialize
        validationSchema={withdrawalsSchema}
      >
        {({ isValid, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <p>Withdrawals Requested:</p>
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item md={4} xs={4}>
                <Field
                  name="amount_requested"
                  label="Amount Requested"
                  placeholder="Amount Requested"
                  component={FormInput}
                  type="number"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="account_balance"
                  label="Trading Account Balance"
                  placeholder="Trading Account Balance"
                  component={FormInput}
                  type="number"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="watermark_level"
                  label="Watermark Level"
                  placeholder="Watermark Level"
                  component={FormInput}
                  type="number"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="manual_deduction"
                  label="Manual Deduction"
                  placeholder="Manual Deduction"
                  component={FormInput}
                  type="number"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="initialization_date"
                  label="Date"
                  placeholder="Date"
                  component={FormDatePicker}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="e2t_account_id"
                  label="Account ID"
                  placeholder="Account ID"
                  component={FormSelect}
                  options={fundedAccounts.map(({ id, e2t_account_id }) => ({
                    id,
                    value: e2t_account_id,
                    label: e2t_account_id,
                  }))}
                />
              </Grid>
              <Grid item md={8} xs={8}>
                <Field name="comment" label="Comment" placeholder="Comment" component={FormInput} />
              </Grid>
              <Grid item md={4} xs={4}>
                <Field
                  name="isOnHold"
                  label="On hold"
                  placeholder="On hold"
                  component={FormCheckbox}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={6}>
              <p>
                New Account Balance: <b>{IntlFormatUSD(getNewAccountBalance(values))}</b>
              </p>
              <div className={classes.deductionContainer}>
                <p>
                  Regular Deduction Rate (20%):{" "}
                  <b>{IntlFormatUSD(getAmountToBePay(values).fee.regular_deduction_rate)}</b>
                </p>
                {!!values.watermark_level && (
                  <div className={classes.rateContainer}>
                    <Field
                      name="manual_amount_interest_rate"
                      label="New rate %"
                      placeholder="New rate %"
                      component={FormInput}
                      type="number"
                      contextualHelpText="O for using regular rate"
                    />
                  </div>
                )}
              </div>
              <p>
                Convenience Fee{" "}
                <b>({IntlFormatUSD(getAmountToBePay(values).fee.conveniences_fees)})</b>
              </p>
              <p>
                Total Deduction: <b>{IntlFormatUSD(getAmountToBePay(values).fee.sum)}</b>
              </p>
              <div className={classes.deductionContainer}>
                <p>
                  Withdrawal Amount: <b>{IntlFormatUSD(getAmountToBePay(values).amountToPay)}</b>
                </p>
                <div className={classes.rateWithdrawalAmount}>
                  <Field
                    name="manual_withdrawal_amount"
                    label="New amount"
                    placeholder="New amount"
                    component={FormInput}
                    type="number"
                    contextualHelpText="O for using calculated value"
                  />
                </div>
              </div>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item md={3} xs={3} className={classes.flexBox}>
                <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
                  {editValues ? "Update" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ContainerWithLoader>
  );
};

export default WithdrawalRequestForm;

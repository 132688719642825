import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  popperRoot: {
    width: "fit-content !important",
    zIndex: 10,
  },
  filterContainer: {
    marginRight: 10,
  },
});

export default useStyles;

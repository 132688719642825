import React from "react";
import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const Over8KWithdrawals = (): React.ReactElement => {
  const { net_withdrawal_amount } = useSelector((state) => state.fundedTrader);
  return (
    <div>
      <FormControlLabel
        label="Over 8K withdrawals"
        control={
          <Checkbox
            size="small"
            checked={net_withdrawal_amount > 8000}
            name="over_8k_withdrawals"
            color="primary"
            disabled
          />
        }
      />
    </div>
  );
};

export default Over8KWithdrawals;

/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import {
  IAccountsBaseInfo,
  IRithmicAccountData,
  IRithmicAccountDataResponse,
} from "../api/rithmic/index.types";

type RithmicAccountsAction =
  | RithmicAccountsFetchRequest
  | RithmicAccountsFetchSuccess
  | RithmicAccountsFetchFailed;

interface RithmicAccountsFetchRequest extends ReduxAction {
  type: RithmicAccountsActionType.rithmicAccountsFetchRequest;
}
interface RithmicAccountsFetchSuccess extends ReduxAction {
  type: RithmicAccountsActionType.rithmicAccountsFetchSuccess;
  payload: {
    e2tAccounts: IRithmicAccountData[];
    count: number;
    accounts: IAccountsBaseInfo[];
  };
}
interface RithmicAccountsFetchFailed extends ReduxAction {
  type: RithmicAccountsActionType.rithmicAccountsFetchFailed;
}

export enum RithmicAccountsActionType {
  rithmicAccountsFetchRequest = "rithmicAccounts/FetchRequest",
  rithmicAccountsFetchSuccess = "rithmicAccounts/FetchSuccess",
  rithmicAccountsFetchFailed = "rithmicAccounts/FetchFailed",
}

export const RithmicAccountsActions = {
  fetchRithmicAccountsRequest: (): RithmicAccountsAction => ({
    type: RithmicAccountsActionType.rithmicAccountsFetchRequest,
  }),
  fetchRithmicAccountsSuccess: ({
    e2tAccounts,
    count,
    accounts,
  }: IRithmicAccountDataResponse): RithmicAccountsAction => ({
    type: RithmicAccountsActionType.rithmicAccountsFetchSuccess,
    payload: {
      e2tAccounts,
      count,
      accounts,
    },
  }),
  fetchRithmicAccountsFailed: (): RithmicAccountsAction => ({
    type: RithmicAccountsActionType.rithmicAccountsFetchFailed,
  }),
};

export interface IRithmicAccountsState {
  data: {
    e2tAccounts: IRithmicAccountData[];
    count: number;
    loading: boolean;
  };
}

const initialState: IRithmicAccountsState = {
  data: {
    e2tAccounts: [],
    count: 0,
    loading: false,
  },
};

const rithmicAccountsReducer = (
  prevState = initialState,
  action: RithmicAccountsAction
): IRithmicAccountsState =>
  produce(prevState, (draft: IRithmicAccountsState) => {
    switch (action.type) {
      case RithmicAccountsActionType.rithmicAccountsFetchRequest:
        draft.data.loading = true;
        break;
      case RithmicAccountsActionType.rithmicAccountsFetchSuccess: {
        draft.data.loading = false;
        const { e2tAccounts, accounts, count } = action.payload;
        e2tAccounts.forEach(({ email }, index: number) => {
          const res = accounts.find((account) => account.email === email);
          if (res) {
            e2tAccounts[index].id = res.id;
          }
        });
        draft.data.e2tAccounts = e2tAccounts;
        draft.data.count = count;
        break;
      }
      case RithmicAccountsActionType.rithmicAccountsFetchFailed:
        draft.data.loading = false;
        break;
      default:
        return draft;
    }
  });

export default rithmicAccountsReducer;

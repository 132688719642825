import { ComplianceWatchlistAction, ComplianceWatchlistActionType } from "./index.types";
import { IGetAllComplianceNotesResponse } from "../../api/compliance/index.types";

export const ComplianceWatchlistActions = {
  fetchComplianceWatchlistRequest: (): ComplianceWatchlistAction => ({
    type: ComplianceWatchlistActionType.fetchComplianceWatchlistRequest,
  }),
  fetchComplianceWatchlistSuccess: (
    payload: IGetAllComplianceNotesResponse["data"]
  ): ComplianceWatchlistAction => ({
    type: ComplianceWatchlistActionType.fetchComplianceWatchlistSuccess,
    payload,
  }),
  fetchComplianceWatchlistFailed: (): ComplianceWatchlistAction => ({
    type: ComplianceWatchlistActionType.fetchComplianceWatchlistFailed,
  }),
};

import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

interface IProps {
  rowSelections: boolean[];
  index: number;
  setRowSelections: (selection: boolean[]) => void;
}
const SelectRowCell: React.FC<IProps> = ({ rowSelections, index, setRowSelections }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={rowSelections[index] || false}
          onChange={(e) => {
            const selections = [...rowSelections];
            selections[index] = e.target.checked;
            setRowSelections(selections);
          }}
          color="secondary"
        />
      }
      label={index + 1}
    />
  );
};
export default SelectRowCell;

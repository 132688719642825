import { makeStyles } from "@material-ui/core";
import { STATUS_COLORS_OPACITY } from "../../../../../../constants";

const useStyles = makeStyles({
  paymentIcContainer: {
    textAlign: "center",
  },
  paymentIc: {
    width: "20px",
    display: "inline-block",
  },
  ownCharge: {
    backgroundColor: STATUS_COLORS_OPACITY.lightGreen,
  },
  failedPayment: {
    backgroundColor: "#ffff0000",
    borderBottom: "1px solid #000",
    borderTop: "1px solid #000",
    "& td": {
      backgroundColor: "#ffff003b !important",
    },
  },
  refundPayment: {
    backgroundColor: "#ffff0000",
    borderBottom: "1px solid #000",
    borderTop: "1px solid #000",
    "& td": {
      backgroundColor: STATUS_COLORS_OPACITY.lightRed,
    },
  },
  preWhiteSpace: {
    whiteSpace: "pre",
  },
  bitPayIc: {
    width: "30px",
  },
  bridgerIc: {
    width: 50,
  },
});

export default useStyles;

import React from "react";
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from "@material-ui/core/";

interface IProps {
  last4: string;
}
const useStyles = makeStyles({
  singleLast4: {
    marginLeft: 14,
  },
});

const CardLast4: React.FC<IProps> = ({ last4 }) => {
  const classes = useStyles();
  const cardNumbers = last4?.split(",");

  if (cardNumbers && cardNumbers.length > 1) {
    return (
      <td>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Cards</InputLabel>
          <Select value={cardNumbers[0]} label="Cards">
            {cardNumbers.map((number) => {
              return (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </td>
    );
  }
  return (
    <td>
      <span className={classes.singleLast4}>{last4}</span>
    </td>
  );
};

export default CardLast4;

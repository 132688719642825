import React, { createContext, useContext, useEffect, useState } from "react";
import { PAYMENTS_STATUSES } from "../../constants";

export type TSortBy = "id" | "name" | "purchase_date";

export interface ISortBy {
  by: TSortBy;
  order: boolean;
}

export interface FeedContextProps {
  limit: string;
  setLimit: (limit: string) => void;
  page: number;
  setPage: (page: number) => void;
  sortBy: ISortBy;
  setSortBy: (sort: TSortBy) => void;
  search: {
    [index: string]: string;
  };
  setSearch: (search: Record<string, unknown>) => void;
  groupBy: string;
  setGroupBy: (groupBy: string) => void;
}

interface Props {
  children: React.ReactNode;
}

const FeedContext = createContext<FeedContextProps>({
  limit: "20",
  setLimit: () => ({}),
  page: 0,
  setPage: () => ({}),
  sortBy: {
    by: "id",
    order: false,
  },
  setSortBy: () => ({}),
  search: {},
  setSearch: () => ({}),
  groupBy: "id",
  setGroupBy: () => ({}),
});

const FeedProvider = ({ children }: Props): React.ReactElement => {
  const [limit, setLimit] = useState<string>("20");
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "purchase_date",
    order: false,
  });
  const [search, setSearch] = useState({});
  const [groupBy, setGroupBy] = useState<string>(PAYMENTS_STATUSES.accepted.status);

  useEffect(() => {
    setPage(0);
  }, [search, groupBy]);

  return (
    <FeedContext.Provider
      value={{
        limit,
        setLimit,
        page,
        setPage,
        sortBy,
        // @ts-ignore
        setSortBy,
        search,
        setSearch,
        groupBy,
        setGroupBy,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export default FeedProvider;
export const useFeedContext = (): FeedContextProps => useContext(FeedContext);

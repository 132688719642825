import React from "react";
import { IColum } from "../../utils/copyToClipboardInXlsFormat";
import { replaceNewLineAndTrim } from "../ComplianceSearch/utils";
import { CopyToClipboard } from "../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "name",
    title: "Name",
    path: "name",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "measure_timestamp",
    title: "Measure timestamp",
    path: "measure_timestamp",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "reason",
    title: "Reason",
    path: "reason",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value: unknown): React.ReactElement | string => {
      if (typeof value === "string") {
        return (
          <p
            style={{
              whiteSpace: "pre",
              display: "block",
            }}
          >
            {value?.replace(".", ".\n")}
          </p>
        );
      }
      return "";
    },
  },
  {
    by: "measure_taken",
    title: "Measure taken",
    path: "measure_taken",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
];

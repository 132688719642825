import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    marginBottom: 20,
  },
  saveButton: {
    marginRight: 18,
  },
  legend: {
    justifyContent: "end",
    display: "flex",
    marginBottom: 10,
  },
});

export default useStyles;

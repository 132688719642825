import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  text: {
    marginBottom: 0,
    marginTop: 10,
  },
  flexBox: {
    display: "flex",
  },
});

export default useStyles;

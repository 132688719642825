import React from "react";
import { ConfirmDialog, Snackbar } from "../../../../../../components/ui";
import { TTypeMessage } from "../../../../../../components/ui/Snackbar";

interface IProps {
  isOpenSnackbar: boolean;
  handleCloseSnackBar: () => void;
  status: TTypeMessage;
  isConfirmDialogOpen: "removeSelectedCells" | "removeSelectedRows" | "";
  handleRemoveSelectedCells: () => void;
  handleCancelRemoveRowsAndColumns: () => void;
  handleRemoveRows: () => void;
  responseErrorMessage: string;
}
const Feedback: React.FC<IProps> = ({
  isOpenSnackbar,
  handleCloseSnackBar,
  status,
  isConfirmDialogOpen,
  handleRemoveSelectedCells,
  handleCancelRemoveRowsAndColumns,
  handleRemoveRows,
  responseErrorMessage = "Error uploading file",
}) => (
  <div>
    <Snackbar
      isOpenSnackbar={isOpenSnackbar}
      responseSuccessMessage="File uploaded successfully"
      responseErrorMessage={responseErrorMessage}
      onClose={handleCloseSnackBar}
      type={status}
    />

    {isConfirmDialogOpen === "removeSelectedCells" && (
      <ConfirmDialog
        isOpen
        dialogTitle="Remove selected cells"
        dialogContentText="Are you sure you want to remove selected cells?"
        handleClickOk={handleRemoveSelectedCells}
        handleCancel={handleCancelRemoveRowsAndColumns}
      />
    )}
    {isConfirmDialogOpen === "removeSelectedRows" && (
      <ConfirmDialog
        isOpen
        dialogTitle="Remove selected rows"
        dialogContentText="Are you sure you want to remove selected rows?"
        handleClickOk={handleRemoveRows}
        handleCancel={handleCancelRemoveRowsAndColumns}
      />
    )}
  </div>
);

export default Feedback;

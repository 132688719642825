import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import useStyles from "./useStyles";
import ChangeSumOfCustomerWithdrawal from "../ChangeSumOfCustomerWithdrawal";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import { IntlFormatUSD } from "../../../../utils";

interface IProps {
  isEditableWithdrawalAmount?: boolean;
}
const TableBody: React.FC<IProps> = ({ isEditableWithdrawalAmount }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    accounts: { data },
  } = useSelector((state) => state.forcedToLiveAccounts);

  const handleCustomerClick = (id: number) => history.push(`fundedTraders/${id}`);

  return (
    <tbody>
      {data.rows.map((row) => {
        const widthdrawalAmount = data.accountsOver8KWithdrawals.find(
          ({ accounts_id }) => row.accounts_id === accounts_id
        );
        const liveSimWithdrawalAmount = data.forcedToLive.find(
          ({ accounts_id }) => row.accounts_id === accounts_id
        );
        return (
          <tr
            key={row.accounts_id}
            className={cx({
              [classes.tr]: isEditableWithdrawalAmount,
              [classes.forcedToLiveStatusBg]: liveSimWithdrawalAmount?.is_forced_to_live,
            })}
          >
            <td>{row.accounts_id}</td>
            <td onClick={() => handleCustomerClick(row.accounts_id)}>{row.name}</td>
            <td>{row.email}</td>

            {isEditableWithdrawalAmount ? (
              <ChangeSumOfCustomerWithdrawal
                sum={
                  liveSimWithdrawalAmount?.livesim_net_withdrawal_amount ||
                  widthdrawalAmount?.net_withdrawal_amount ||
                  0
                }
                accounts_id={row.accounts_id}
              />
            ) : (
              <td>
                {widthdrawalAmount &&
                  IntlFormatUSD(widthdrawalAmount.net_withdrawal_amount / CENTS_IN_DOLLAR)}
                {!widthdrawalAmount &&
                  liveSimWithdrawalAmount &&
                  IntlFormatUSD(
                    liveSimWithdrawalAmount.livesim_net_withdrawal_amount / CENTS_IN_DOLLAR
                  )}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

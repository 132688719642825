import React from "react";

import { TBy } from "../../index";
import { ISortBy } from "../../../../../../types";
import { TableDataCellWithSort } from "../../../../../../components/ui/";

interface IProps {
  sortBy: ISortBy<TBy>;
  handleSortBy: (by: TBy) => void;
}

const TableHead: React.FC<IProps> = ({ sortBy, handleSortBy }) => {
  return (
    <thead>
      <tr>
        <td>IID</td>
        <TableDataCellWithSort by="name" title="Name" sortBy={sortBy} newSortBy={handleSortBy} />
        <TableDataCellWithSort by="email" title="Email" sortBy={sortBy} newSortBy={handleSortBy} />
        <td>Preset</td>
        <td>Prop Firm</td>
        <TableDataCellWithSort
          by="active"
          title="Status"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHead;

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

import useStyles from "./useStyles";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import { EditableContainerWithActions } from "../../index";
import { APIFunds } from "../../../../api";
import { IntlFormatUSD } from "../../../../utils";

interface IProps {
  sum: number;
  accounts_id: number;
}
const ChangeSumOfCustomerWithdrawal: React.FC<IProps> = ({ sum, accounts_id }) => {
  const classes = useStyles();
  const [value, setValue] = useState(sum / CENTS_IN_DOLLAR);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleEditSum = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setValue(Number(e.target.value));
  const handleCloseAlert = () => setIsShowAlert(false);

  const handleApply = async () => {
    setIsUpdating(true);
    try {
      await APIFunds.changeCustomerSumOfWithdrawals(accounts_id, {
        livesim_net_withdrawal_amount: value * CENTS_IN_DOLLAR,
      });
      setValue(value);
    } catch (error) {
      console.log("Handle error in change sum of customer withdrawal", error);
      setIsShowAlert(true);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <EditableContainerWithActions
      renderComponent={() => (
        <TextField
          size="small"
          variant="outlined"
          type="number"
          value={value}
          onChange={handleEditSum}
        />
      )}
      isEditable
      defaultValue={IntlFormatUSD(value)}
      isChanged={sum !== value}
      handleApply={handleApply}
      isShowAlert={isShowAlert}
      tableDataCellClasses={{
        hover: false,
        [classes.container]: true,
      }}
      isUpdating={isUpdating}
      setIsShowAlert={handleCloseAlert}
      alertErrorText="Error while updating sum of customer withdrawal"
    />
  );
};

export default ChangeSumOfCustomerWithdrawal;

import { CustomerAction, CustomerActionType } from "./index.types";
import { IActivityLogsData } from "../../api/agentActivityLog/index.types";
import {
  IAccountNote,
  IGetAccountManagementDataResponse,
  IGetCustomerPlansResponse,
  IGetCustomerResponse,
} from "../../api/accounts/index.types";
import { IGetCustomerPaymentCardsResponse } from "../../api/paymentCards/index.types";
import { IGetSharedRegIpsResponse, TGetSharedIpsResponse } from "../../api/logins/index.types";
import { IGetCustomerComplianceNotesResponse } from "../../api/compliance/index.types";
import { IFindSimilarFundedAccountsResponse } from "../../api/funds/index.types";

export const CustomerActions = {
  fetchAgentLogsRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchAgentLogsRequest,
  }),
  fetchAgentLogsSuccess: (logs: IActivityLogsData[], count: number): CustomerAction => ({
    type: CustomerActionType.fetchAgentLogsSuccess,
    payload: { logs, count },
  }),
  fetchAgentLogsFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchAgentLogsFailed,
  }),
  //Plans
  fetchCustomerPlansRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPlansRequest,
  }),
  fetchCustomerPlansSuccess: (data: IGetCustomerPlansResponse["data"]): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPlansSuccess,
    payload: data,
  }),
  fetchCustomerPlansFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPlansFailed,
  }),
  // complianceNotes
  fetchCustomerComplianceNotesRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerComplianceNotesRequest,
  }),
  fetchCustomerComplianceNotesSuccess: (
    payload: IGetCustomerComplianceNotesResponse["data"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerComplianceNotesSuccess,
    payload,
  }),
  fetchCustomerComplianceNotesFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerComplianceNotesFailed,
  }),
  //Notes
  fetchCustomerNotesRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerNotesRequest,
  }),
  fetchCustomerNotesSuccess: (
    notes: IAccountNote[],
    count: number,
    countActive: number
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerNotesSuccess,
    payload: { notes, count, countActive },
  }),
  fetchCustomerNotesFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerNotesFailed,
  }),
  // Suspicious Notes
  fetchCustomerSuspiciousNotesRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSuspiciousNotesRequest,
  }),
  fetchCustomerSuspiciousNotesSuccess: (
    notes: IAccountNote[],
    count: number,
    countActive: number
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSuspiciousNotesSuccess,
    payload: { notes, count, countActive },
  }),
  fetchCustomerSuspiciousNotesFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSuspiciousNotesFailed,
  }),
  // Management data
  fetchCustomerManagementDataRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerManagementDataRequest,
  }),
  fetchCustomerManagementDataSuccess: (
    payload: IGetAccountManagementDataResponse["data"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerManagementDataSuccess,
    payload,
  }),
  fetchCustomerManagementDataFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerManagementDataFailed,
  }),
  // Payment Cards
  fetchCustomerPaymentCardsRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPaymentCardsRequest,
  }),
  fetchCustomerPaymentCardsSuccess: (
    payload: IGetCustomerPaymentCardsResponse["data"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPaymentCardsSuccess,
    payload,
  }),
  fetchCustomerPaymentCardsFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerPaymentCardsFailed,
  }),
  // Main data
  fetchCustomerMainDataRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerMainDataRequest,
  }),
  fetchCustomerMainDataSuccess: (
    payload: Partial<IGetCustomerResponse["data"]>
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerMainDataSuccess,
    payload,
  }),
  fetchCustomerMainDataFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerMainDataFailed,
  }),
  // Shared IPs
  fetchCustomerSharedIpsRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedIpsDataRequest,
  }),
  fetchCustomerSharedIpsSuccess: (
    payload: TGetSharedIpsResponse["data"]["sharedIps"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedIpsDataSuccess,
    payload,
  }),
  fetchCustomerSharedIpsFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedIpsDataFailed,
  }),
  // Shared Reg Ips
  fetchCustomerSharedRegIpsRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedRegIpsRequest,
  }),
  fetchCustomerSharedRegIpsSuccess: (
    payload: IGetSharedRegIpsResponse["data"]["sharedRegIp"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedRegIpsSuccess,
    payload,
  }),
  fetchCustomerSharedRegIpsFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSharedRegIpsFailed,
  }),
  // similarFundedAccounts
  fetchSimilarFundedAccountsRequest: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSimilarFundedAccountsRequest,
  }),
  fetchSimilarFundedAccountsSuccess: (
    data: IFindSimilarFundedAccountsResponse["data"]
  ): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSimilarFundedAccountsSuccess,
    payload: data,
  }),
  fetchSimilarFundedAccountsFailed: (): CustomerAction => ({
    type: CustomerActionType.fetchCustomerSimilarFundedAccountsFailed,
  }),
};

import React from "react";

import { ISortBy } from "../../../../../../types";
import newSortBy from "../../../../../../utils/newSortBy";
import TableDataCellWithSort from "../../../../../../components/ui/TableDataCellWithSort";

interface IProps {
  handleSort: [ISortBy, (sortBy: ISortBy) => void];
}
const TableHead: React.FC<IProps> = ({ handleSort: [sortBy, setSortBy] }) => {
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });
  return (
    <thead>
      <tr>
        <TableDataCellWithSort sortBy={sortBy} by="id" title="Id" newSortBy={handleSortBy} />
        <TableDataCellWithSort sortBy={sortBy} by="name" title="Name" newSortBy={handleSortBy} />
        <TableDataCellWithSort sortBy={sortBy} by="email" title="email" newSortBy={handleSortBy} />
        <td>Notes</td>
      </tr>
    </thead>
  );
};

export default TableHead;

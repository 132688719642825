/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { ICappedSteppedAccount } from "../api/funds/index.types";

export type CappedSteppedAccountsAction =
  | CappedSteppedAccountsFetchRequest
  | CappedSteppedAccountsFetchSuccess
  | CappedSteppedAccountsFetchFailed;

export enum CappedSteppedAccountsActionType {
  cappedSteppedAccountsFetchRequest = "cappedSteppedAccounts/FetchRequest",
  cappedSteppedAccountsFetchSuccess = "cappedSteppedAccounts/FetchSuccess",
  cappedSteppedAccountsFetchFailed = "cappedSteppedAccounts/FetchFailed",
}

interface CappedSteppedAccountsFetchRequest extends ReduxAction {
  type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchRequest;
}
interface CappedSteppedAccountsFetchSuccess extends ReduxAction {
  type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchSuccess;
  payload: {
    cappedSteppedAccounts: {
      count: number;
      rows: ICappedSteppedAccount[];
    };
  };
}
interface CappedSteppedAccountsFetchFailed extends ReduxAction {
  type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchFailed;
}

interface ICappedSteppedAccounts {
  cappedSteppedAccounts: {
    rows: ICappedSteppedAccount[];
    count: number;
  };
}

export const CappedSteppedAccountsActions = {
  fetchCappedSteppedAccountsRequest: (): CappedSteppedAccountsAction => ({
    type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchRequest,
  }),
  fetchCappedSteppedAccountsSuccess: ({
    cappedSteppedAccounts,
  }: ICappedSteppedAccounts): CappedSteppedAccountsAction => ({
    type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchSuccess,
    payload: {
      cappedSteppedAccounts,
    },
  }),
  fetchCappedSteppedAccountsFailed: (): CappedSteppedAccountsAction => ({
    type: CappedSteppedAccountsActionType.cappedSteppedAccountsFetchFailed,
  }),
};

export interface ICappedSteppedAccountsState {
  data: {
    count: number;
    cappedSteppedAccounts: ICappedSteppedAccount[];
  };
  isLoading: boolean;
}

const initialState: ICappedSteppedAccountsState = {
  data: {
    count: 0,
    cappedSteppedAccounts: [],
  },
  isLoading: false,
};

const cappedSteppedAccountsReducer = (
  prevState = initialState,
  action: CappedSteppedAccountsAction
): ICappedSteppedAccountsState =>
  produce(prevState, (draft: ICappedSteppedAccountsState) => {
    switch (action.type) {
      case CappedSteppedAccountsActionType.cappedSteppedAccountsFetchRequest:
        draft.isLoading = true;
        break;
      case CappedSteppedAccountsActionType.cappedSteppedAccountsFetchSuccess:
        draft.data.count = action.payload.cappedSteppedAccounts.count;
        draft.data.cappedSteppedAccounts = action.payload.cappedSteppedAccounts.rows;
        draft.isLoading = false;
        break;
      case CappedSteppedAccountsActionType.cappedSteppedAccountsFetchFailed:
        draft.isLoading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default cappedSteppedAccountsReducer;

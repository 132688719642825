import { INetSalesValuesReport } from "../../../../api/billing/index.types";
import { NET_SALES_CALCULATOR_TAB_INDEXES } from "./index";
import {
  PAYPAL_FIXED_FEE_INTEREST,
  PAYPAL_INTEREST,
  STRIPE_USA_INTEREST,
  STRIPE_USA_OTHER_COUNTRIES,
} from "../../../../constants";

interface IGetReportValues {
  us_total: number;
  international_total: number;
  totalNet: number;
  sumRefAndChar: number;
  paypalNetTotal: number;
  payPalGrossTotal: number;
  stripeNetTotal: number;
  stripeGrossTotal: number;
  not_us_bitpay_total_net: number;
  bridgerPayGrossTotal: number;
  bridgerPayNetTotal: number;
}
export const getReportValues = (
  report: INetSalesValuesReport,
  activeTabIndex: NET_SALES_CALCULATOR_TAB_INDEXES
): IGetReportValues => {
  const {
    us_stripe_total = 0,
    not_us_stripe_total = 0,
    us_paypal_total = 0,
    not_us_paypal_total = 0,
    us_stripe_total_ref_and_char = 0,
    not_us_stripe_total_ref_and_char = 0,
    not_us_bitpay_total_net = 0,
    us_bridger_pay_total = 0,
    not_us_us_bridger_pay_total_total = 0,
  } = report;

  const isCombinedSales = activeTabIndex === "combined_sales";
  const isUSSales = activeTabIndex === "us_sales";

  const stripeGrossTotal = isCombinedSales
    ? us_stripe_total + not_us_stripe_total
    : isUSSales
    ? us_stripe_total
    : not_us_stripe_total;
  const stripeNetTotal = isCombinedSales
    ? us_stripe_total * (1 - STRIPE_USA_INTEREST / 100) +
      not_us_stripe_total * (1 - STRIPE_USA_OTHER_COUNTRIES / 100)
    : isUSSales
    ? us_stripe_total * (1 - STRIPE_USA_INTEREST / 100)
    : not_us_stripe_total * (1 - STRIPE_USA_OTHER_COUNTRIES / 100);

  const bridgerPayGrossTotal = isCombinedSales
    ? us_bridger_pay_total + not_us_us_bridger_pay_total_total
    : isUSSales
    ? us_bridger_pay_total
    : not_us_us_bridger_pay_total_total;
  // TODO calc fees
  const bridgerPayNetTotal = isCombinedSales
    ? us_bridger_pay_total + not_us_us_bridger_pay_total_total
    : isUSSales
    ? us_bridger_pay_total
    : not_us_us_bridger_pay_total_total;

  const payPalGrossTotal = isCombinedSales
    ? us_paypal_total + not_us_paypal_total
    : isUSSales
    ? us_paypal_total
    : not_us_paypal_total;
  const us_paypal_total_with_interest = us_paypal_total * (1 - PAYPAL_INTEREST / 100);
  const not_us_paypal_total_with_interest =
    not_us_paypal_total * (1 - (STRIPE_USA_OTHER_COUNTRIES + PAYPAL_FIXED_FEE_INTEREST) / 100);

  const paypalNetTotal = isCombinedSales
    ? us_paypal_total_with_interest + not_us_paypal_total_with_interest
    : isUSSales
    ? us_paypal_total_with_interest
    : not_us_paypal_total_with_interest;

  /* us_stripe_total_ref_and_char and not_us_stripe_total_ref_and_char comes from stripe API*/
  const us_sumRefAndChar = us_stripe_total_ref_and_char;
  const international_sumRefAndChar = not_us_stripe_total_ref_and_char;
  const combined_sumRefAndChar = us_sumRefAndChar + international_sumRefAndChar;

  const us_total =
    us_stripe_total * (1 - STRIPE_USA_INTEREST / 100) +
    us_paypal_total_with_interest +
    us_bridger_pay_total -
    us_sumRefAndChar;
  const international_total =
    not_us_stripe_total * (1 - STRIPE_USA_OTHER_COUNTRIES / 100) +
    not_us_paypal_total_with_interest +
    not_us_us_bridger_pay_total_total +
    not_us_bitpay_total_net -
    international_sumRefAndChar;

  const getSumRefAndChar = (): number => {
    const values = {
      [NET_SALES_CALCULATOR_TAB_INDEXES.combined_sales]: combined_sumRefAndChar,
      [NET_SALES_CALCULATOR_TAB_INDEXES.us_sales]: us_sumRefAndChar,
      [NET_SALES_CALCULATOR_TAB_INDEXES.international_sales]: international_sumRefAndChar,
    };

    return values[activeTabIndex];
  };

  const totalNet =
    stripeNetTotal +
    paypalNetTotal +
    bridgerPayNetTotal +
    not_us_bitpay_total_net -
    getSumRefAndChar();

  return {
    us_total,
    international_total,
    totalNet,
    sumRefAndChar: getSumRefAndChar(),
    paypalNetTotal,
    payPalGrossTotal,
    stripeNetTotal,
    stripeGrossTotal,
    not_us_bitpay_total_net,
    bridgerPayGrossTotal,
    bridgerPayNetTotal,
  };
};

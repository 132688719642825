import { E_EVALUATIONS_STEPS } from "./constants";

interface IGetCurrentActiveTabReturnValue {
  isActivePassedTab: boolean;
  isActiveCertificateDesignTab: boolean;
  isActiveCertificateEmailTab: boolean;
  isActiveWaitingForOfferTab: boolean;
}
export const getCurrentActiveTab = (activeTabIndex: number): IGetCurrentActiveTabReturnValue => ({
  isActivePassedTab: activeTabIndex === E_EVALUATIONS_STEPS.PASSED_TAB_INDEX,
  isActiveCertificateDesignTab: activeTabIndex === E_EVALUATIONS_STEPS.CERTIFICATE_DESIGN_TAB_INDEX,
  isActiveCertificateEmailTab: activeTabIndex === E_EVALUATIONS_STEPS.CERTIFICATE_EMAIL_TAB_INDEX,
  isActiveWaitingForOfferTab: activeTabIndex === E_EVALUATIONS_STEPS.WAITING_FOR_OFFER_TAB_INDEX,
});

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  onTimeInactive: {
    color: "red",
  },
  active: {
    color: "green",
  },
});

interface IProps {
  isActive: boolean;
}
const StatusIcons = ({ isActive }: IProps): React.ReactElement => {
  const classes = useStyles();
  return isActive ? (
    <b className={classes.onTimeInactive}>✘</b>
  ) : (
    <b className={classes.active}>✔</b>
  );
};

export default StatusIcons;

import React from "react";
import moment from "moment-timezone";

import { TBy } from "../../../../reducers/customersReducer";
import newSortBy from "../../../../utils/newSortBy";
import { ISortBy } from "../../../../types";
import { ISharedIp } from "../../../../api/logins/index.types";
import useGeneralStyles from "../../../../useGeneralStyles";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";
import { TableDataCellWithSort } from "../../../../components/ui";

interface IProps {
  sortBy: ISortBy;
  setSortBy: (sortBy: ISortBy) => void;
  data: ISharedIp[];
  handleClose: () => void;
}
const SharedIpsTable: React.FC<IProps> = ({ sortBy, setSortBy, data }) => {
  const generalClasses = useGeneralStyles();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <table className={generalClasses.table}>
      <thead>
        <tr>
          <TableDataCellWithSort
            sortBy={sortBy}
            by="timestamp"
            title="Date"
            newSortBy={handleSortBy}
          />
          <TableDataCellWithSort
            sortBy={sortBy}
            by="success"
            title="Success"
            newSortBy={handleSortBy}
          />
          <TableDataCellWithSort
            sortBy={sortBy}
            by="email"
            title="Email"
            newSortBy={handleSortBy}
          />
        </tr>
      </thead>
      <tbody>
        {data.map((log) => (
          <tr
            key={log.id}
            onClick={() => {
              const url = `#/customer/${log.accounts_id}`;
              window.open(url);
            }}
          >
            <td>{moment(log.timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}</td>
            <td>{log.success ? "✔" : "✘"}</td>
            <td>{log.account.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SharedIpsTable;

import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import cx from "classnames";

import { ContextualHelp } from "../../../../components/ui";
import { IAllowedFeature } from "../../constants";
import { TAllowedFeaturesList } from "../../../../constants";
import { useAdminProviderDataContext } from "../../../../context";

const useStyles = makeStyles({
  formControlLabelRoot: {
    cursor: "default",
    pointerEvents: "none",
  },
  checkboxRoot: {
    pointerEvents: "all",
  },
  subOption: {
    marginLeft: 10,
  },
});

interface IProps {
  label: string;
  options: Record<IAllowedFeature["name"], IAllowedFeature>;
  allowed_features: TAllowedFeaturesList;
  setFieldValue: (fieldName: string, value: boolean) => void;
}
const AllowedFeaturesAutocomplete: React.FC<IProps> = ({
  options,
  allowed_features,
  setFieldValue,
  label,
}) => {
  const classes = useStyles();
  const { user } = useAdminProviderDataContext();

  return (
    <div>
      <Autocomplete
        classes={{ option: classes.formControlLabelRoot }}
        disableCloseOnSelect
        fullWidth
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        options={Object.keys(options)}
        getOptionLabel={(option) => options[option].label}
        groupBy={(option) => options[option]?.group || ""}
        renderOption={(fieldName: IAllowedFeature["name"]) => {
          const {
            name,
            label,
            contextualHelpText = "",
            isSubOption,
            subOptionsList,
            group,
          } = options[fieldName];
          const [, optionName] = name.split(".");

          const isDisabledSubOption = () => {
            if (!user.allowed_features.change_agent_allowed_features) {
              return true;
            }
            if (isSubOption) {
              const parentOption = Object.keys(options).find((key) => {
                if (
                  options[key as string].subOptionsList &&
                  options[key as string].group === group
                ) {
                  return true;
                }
                return false;
              });
              return !allowed_features[parentOption as string];
            }
          };

          return (
            <ContextualHelp title={contextualHelpText}>
              <FormControlLabel
                className={cx(classes.formControlLabelRoot, { [classes.subOption]: isSubOption })}
                label={label}
                control={
                  <Checkbox
                    disabled={isDisabledSubOption()}
                    className={classes.checkboxRoot}
                    name={label}
                    checked={!!allowed_features[optionName as string]}
                    onChange={(e) => {
                      setFieldValue(`allowed_features.${optionName}`, e.target.checked);
                      if (subOptionsList && !e.target.checked) {
                        subOptionsList.forEach((subOption) => {
                          setFieldValue(`allowed_features.${subOption}`, false);
                        });
                      }
                    }}
                  />
                }
              />
            </ContextualHelp>
          );
        }}
      />
    </div>
  );
};

export default AllowedFeaturesAutocomplete;

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import Charge from "../Charge/Charge";
import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../constants";
import { ContainerWithLoader, LinearProgress } from "../../../../components/ui";
import {
  All_CHARGES_REQUEST_BODY,
  getFeeValue,
  UPDATE_REQUEST_TIMEOUT,
} from "../../../../components/ui/ChargesTable/utils";
import { APIBilling, APICharges } from "../../../../api";
import { ICharge } from "../../../../api/billing/index.types";
import { useAuthContext } from "../../../../context";
import { DayChargesActions } from "../../../../reducers/chargesReducer";
import { getFilteredFailedCharges } from "./utils";

type TransactionsType = "successful" | "failed";

const CurrentDayBillingHistory = (): React.ReactElement => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { token, loginAndQuery, hasExpired } = useAuthContext();
  const {
    data: { transactions, failedStripeTransactions, customersByFailedPayment },
    loading,
  } = useSelector((state) => state.charges);

  const [isLoadingCharges, setIsLoadingCharges] = useState(true);
  const [charges, setCharges] = useState<ICharge[]>([]);
  const [failedCharges, setFailedCharges] = useState<ICharge[]>([]);
  const [fees, setFees] = useState<number>(0);

  const [typeTransactionsToShow, setTypeTransactionsToShow] =
    useState<TransactionsType>("successful");
  const [successfulChargesTimeOutId, setSuccessfulChargesTimeOutId] =
    useState<ReturnType<typeof setInterval>>();

  const [failedChargesTimeOutId, setFailedfulChargesTimeOutId] =
    useState<ReturnType<typeof setInterval>>();

  const getCharges = async (usedToken?: string, lastId?: string | number, page?: number) => {
    let data: {
      limit: number;
      date: string;
      lastId?: string | number;
      page?: number;
    } = {
      limit: 299,
      date: moment().tz("America/Chicago").format(DATE_FORMAT),
      lastId: lastId || "-1",
    };

    if (page) data = { ...data, page };
    try {
      setIsLoadingCharges(true);
      const response = await APIBilling.getThisDayChargesRequest(data);
      setCharges((prevState) => [...prevState, ...response.data].sort((a, b) => b.id - a.id));
    } catch (error) {
      console.error(error);
      hasExpired();
    } finally {
      setIsLoadingCharges(false);
    }
  };
  const handleChangeTypeTransactionToShow = (
    event: React.MouseEvent<HTMLElement>,
    newTypeToShow: TransactionsType
  ) => newTypeToShow && setTypeTransactionsToShow(newTypeToShow);

  const isShowSuccessfulTransactions = typeTransactionsToShow === "successful";
  const isShowFailedTransactions = typeTransactionsToShow === "failed";

  useEffect(() => {
    const fee = getFeeValue(charges);
    if (fee !== fees) setFees(0.029 + fee * 0.3);

    const id: ReturnType<typeof setTimeout> = setInterval(async () => {
      if (token && isShowSuccessfulTransactions) {
        await getCharges("", charges[0] ? charges[0].id : "-1");
      }
    }, UPDATE_REQUEST_TIMEOUT);
    setSuccessfulChargesTimeOutId(id);
    return () => {
      clearInterval(id);
    };
  }, [charges, typeTransactionsToShow]);

  useEffect(() => {
    if (isShowSuccessfulTransactions) {
      loginAndQuery(getCharges);
    }
  }, [token, typeTransactionsToShow]);

  useEffect(() => {
    const getDayCharges = async () => {
      dispatch(DayChargesActions.fetchRequest());
      const response = await APICharges.getAllChargesList(All_CHARGES_REQUEST_BODY);
      dispatch(DayChargesActions.fetchSuccess(response.data));
    };
    const id = setInterval(async () => {
      if (isShowFailedTransactions) {
        await getDayCharges();
      }
    }, UPDATE_REQUEST_TIMEOUT * 4);
    setFailedfulChargesTimeOutId(id);

    (async () => {
      if (isShowFailedTransactions) {
        await getDayCharges();
      }
    })();

    if (typeTransactionsToShow === "failed" && successfulChargesTimeOutId) {
      setCharges([]);
    } else if (failedChargesTimeOutId) {
      setFailedCharges([]);
    }

    return () => clearInterval(id);
  }, [typeTransactionsToShow]);

  useEffect(() => {
    const failedCharges = getFilteredFailedCharges(
      transactions,
      failedStripeTransactions,
      customersByFailedPayment
    );

    setFailedCharges(failedCharges);
  }, [transactions]);
  // @ts-ignore
  useEffect(() => () => dispatch(DayChargesActions.clearCharges()), []);

  const chargesToShow = typeTransactionsToShow === "successful" ? charges : failedCharges;

  return (
    <div className="feed">
      <div className={classes.toggleButtonGroupContainer}>
        <ToggleButtonGroup
          value={typeTransactionsToShow}
          exclusive
          onChange={handleChangeTypeTransactionToShow}
          aria-label="type transactions to show"
        >
          <ToggleButton className={classes.toggleButton} value="successful">
            Successful
          </ToggleButton>
          <ToggleButton
            className={classes.toggleButton}
            value="failed"
            data-test="toggle-failed-button"
          >
            Failed
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <ContainerWithLoader
        isLoading={(loading && typeTransactionsToShow === "failed") || isLoadingCharges}
        report={chargesToShow}
      >
        <LinearProgress elements={charges} />
        <div className={classes.feedCardMainContainer}>
          {chargesToShow.map((charge) => (
            <div className="chargeCardContainer" key={`${charge.id}`}>
              <Charge charge={charge} typeTransaction={typeTransactionsToShow} />
            </div>
          ))}
        </div>
      </ContainerWithLoader>
    </div>
  );
};

export default CurrentDayBillingHistory;

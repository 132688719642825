import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CopyToClipboard, E2TAccountIDsList } from "../../../../components/ui";

const TableBody = (): React.ReactElement => {
  const { e2tAccounts } = useSelector((state) => state.rithmicAccounts.data);

  return (
    <tbody>
      {e2tAccounts.map(({ email, accounts, id }) => {
        return (
          <tr key={email}>
            <td>
              <Link to={`/customer/${id}`}>{id}</Link>
            </td>
            <td>
              <CopyToClipboard value={email} />
            </td>
            <td>
              <E2TAccountIDsList accounts={accounts} />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  centerEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
  },
  errorContainerCreateNote: {
    position: "absolute",
    left: 0,
    bottom: "-16px",
  },
});

export default useStyles;

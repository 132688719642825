import {
  IGetPolicesOptionsResponse,
  IPoliciesOptions,
  IPresetsOptions,
} from "../../api/service/index.types";
import { ReduxAction } from "../../static-types";
import { IAllowedFeature } from "../../pages/Agent/constants";

export type PolicesAction =
  | PolicesActionsFetchRequest
  | PolicesActionsFetchSuccess
  | PolicesActionsFetchFailed;

export interface PolicesActionsFetchRequest extends ReduxAction {
  type: PolicesActionType.fetchPolicesRequest;
}
export interface PolicesActionsFetchSuccess extends ReduxAction {
  type: PolicesActionType.fetchPolicesSuccess;
  payload: IGetPolicesOptionsResponse["data"];
}
export interface PolicesActionsFetchFailed extends ReduxAction {
  type: PolicesActionType.fetchPolicesFailed;
}

export enum PolicesActionType {
  fetchPolicesRequest = "polices/fetchPolicesRequest",
  fetchPolicesSuccess = "polices/fetchPolicesSuccess",
  fetchPolicesFailed = "polices/fetchPolicesFailed",
}

export type ListOptions = Record<
  IPresetsOptions["name"],
  Record<IPoliciesOptions["name"], IAllowedFeature>
>;

export interface IPolicesState {
  data: {
    policiesOptions: IGetPolicesOptionsResponse["data"]["policiesOptions"];
    presetsOptions: IGetPolicesOptionsResponse["data"]["presetsOptions"];
    optionsByCategory: ListOptions;
  };
  loading: boolean;
}

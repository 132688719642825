import React, { useState } from "react";
import moment from "moment-timezone";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import useStyles from "./useStyles";
import { DiscountCodesActions } from "../../../../reducers/discountCodesReducer";
import { EditableContainerWithActions } from "../../../../components/ui";
import { useAdminProviderDataContext, useDiscountsContext } from "../../../../context";
import { APIDiscounts } from "../../../../api";
import { DiscountCodeState } from "../../../../api/discounts/index.types";

interface IProps {
  discount: DiscountCodeState;
}

const isExpired = (date: string | null) =>
  date ? moment().tz("America/Chicago").diff(date, "days") <= 0 : "";

const ChangeDiscountCodeExpires = ({ discount }: IProps): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sortBy, search, page, limit } = useDiscountsContext();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [selectedDate, handleDateChange] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const isValidDateValue = moment(discount.expires).isValid();
  const sellValue = isValidDateValue && discount.expires;

  const handleApply = async () => {
    try {
      setIsUpdating(true);
      await APIDiscounts.updateDiscountCodeRequest({
        expires: selectedDate,
        code_id: discount.id,
        editableValueKeyName: "expires",
      });

      const data = {
        limit,
        page,
        contains: search,
        sortBy,
        inactive: true,
      };
      const response = await APIDiscounts.getDiscountCodesRequest(data);
      const responseData = response.data;
      dispatch(DiscountCodesActions.fetchSuccess(responseData));
    } catch (error) {
      setIsShowAlert(true);
    } finally {
      setIsUpdating(false);
    }
  };
  const isAllowedToSave =
    moment(selectedDate).isValid() &&
    moment().tz("America/Chicago").diff(selectedDate, "days") <= 0;

  return (
    <EditableContainerWithActions
      renderComponent={() => (
        <KeyboardDatePicker
          autoOk
          clearable
          size="small"
          placeholder="10/10/2018"
          format="yyyy/MM/DD"
          value={selectedDate || (isValidDateValue ? discount.expires : null)}
          inputVariant="outlined"
          onChange={(date: MaterialUiPickersDate) =>
            handleDateChange(date ? date.format("YYYY-MM-DD") : null)
          }
          disablePast
          className={classes.textHelper}
        />
      )}
      isEditable={allowed_features.change_discount_code_expiration}
      tableDataCellClasses={{
        [classes.notApplicable]: !isExpired(discount.expires),
      }}
      defaultValue={sellValue}
      isChanged={isAllowedToSave && !!selectedDate}
      handleApply={handleApply}
      isUpdating={isUpdating}
      isShowAlert={isShowAlert}
      setIsShowAlert={setIsShowAlert}
    />
  );
};

export default ChangeDiscountCodeExpires;

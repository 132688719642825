import { ReduxAction } from "../../static-types";
import { TGetForcedToLiveAccountsResponse } from "../../api/funds/index.types";

export type TForcedToLiveAccountsAction =
  | ForcedToLiveAccountsFetchRequest
  | ForcedToLiveAccountsFetchSuccess
  | ForcedToLiveAccountsFetchFailed;

export enum ForcedToLiveAccountsActionType {
  forcedToLiveAccountsFetchRequest = "forcedToLiveAccountsFetchRequest/FetchRequest",
  forcedToLiveAccountsFetchSuccess = "forcedToLiveAccountsFetchRequest/FetchSuccess",
  forcedToLiveAccountsFetchFailed = "forcedToLiveAccountsFetchRequest/FetchFailed",
}
//ForcedToLiveAccounts
export interface ForcedToLiveAccountsFetchRequest extends ReduxAction {
  type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchRequest;
}
export interface ForcedToLiveAccountsFetchSuccess extends ReduxAction {
  type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchSuccess;
  payload: TGetForcedToLiveAccountsResponse["data"];
}
export interface ForcedToLiveAccountsFetchFailed extends ReduxAction {
  type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchFailed;
}

export interface IForcedToLiveAccountsState {
  accounts: {
    data: TGetForcedToLiveAccountsResponse["data"];
    isLoading: boolean;
  };
}

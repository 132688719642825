import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { HashRouter as Router } from "react-router-dom";
import * as dotenv from "dotenv";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reducer from "./reducers";
import { AdminProviderData, AuthProvider } from "./context";
dotenv.config();
/**
 * Create store with default Redux Devtools
 */
const store = createStore(
  reducer,
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
        applyMiddleware(/* swClientMiddleware as any */),
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        (window.__REDUX_DEVTOOLS_EXTENSION__ &&
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__()) ||
          undefined
      )
    : applyMiddleware(/* swClientMiddleware as any */)
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AdminProviderData>
          <AuthProvider>
            <App />
          </AuthProvider>
        </AdminProviderData>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </Router>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { FilterAutocomplete } from "../../../../components/ui";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { HELIOS_LIVE_SIM_TIF_COLUMNS } from "../../constants";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendSearchParamsToQuery, TitleUpdated } from "../../utils";

const HeliosLiveSIMTraderInformationFormSearch: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();
  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.watchlistLiveSimTif);

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } =
    tabsTablesSearchState.fundedAccountQuickSearchTab.heliosLiveSIMTraderInformationFormSearchTable;

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getLiveSimTif,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendSearchParamsToQuery(search, query);

      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifRequest());
      const response = await ComplianceAPI.getWatchlistLiveSimTif(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifSuccess(response.data));
      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifFailed());
      console.error("Error fetching LiveSIM Trader Information Form", error);
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>LiveSIM TIF</b>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              fundedAccountQuickSearchTab: {
                ...tabsTablesSearchState.fundedAccountQuickSearchTab,
                heliosLiveSIMTraderInformationFormSearchTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
            Status: "status",
          }}
        />
      </div>
      <Table
        title={<TitleUpdated value={updated?.submission_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        columns={HELIOS_LIVE_SIM_TIF_COLUMNS}
        extractKey={(row) => row.id}
        data={results.rows}
        csv_table_name="Funded account quick search - LiveSIM TIF"
      />
    </div>
  );
};

export default HeliosLiveSIMTraderInformationFormSearch;

import React from "react";

interface IProps {
  w: number;
  margin: string;
}

const Withdrawals: React.FC<IProps> = ({ w = 24, margin = "0" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={w} style={{ margin }}>
    <g data-name="1">
      <path d="M29,22H22a1,1,0,0,1,0-2h6V7H4V20h6a1,1,0,0,1,0,2H3a1,1,0,0,1-1-1V6A1,1,0,0,1,3,5H29a1,1,0,0,1,1,1V21A1,1,0,0,1,29,22Z" />
      <path d="M22,30H10a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1H22a1,1,0,0,1,1,1V29A1,1,0,0,1,22,30ZM11,28H21V16H11Z" />
      <path d="M25,16H7a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
      <circle cx="25" cy="11" r="1" />
      <path d="M20 12H9a1 1 0 0 1 0-2H20a1 1 0 0 1 0 2zM15.45 24.81V24a3.26 3.26 0 0 1-1.4-.35l.25-1a2.87 2.87 0 0 0 1.36.36c.47 0 .79-.18.79-.51s-.26-.51-.87-.71c-.88-.3-1.48-.71-1.48-1.51a1.52 1.52 0 0 1 1.39-1.46v-.78h.8v.72a2.88 2.88 0 0 1 1.19.28l-.24.92a2.7 2.7 0 0 0-1.18-.28c-.53 0-.7.23-.7.46s.28.45 1 .71c1 .34 1.37.8 1.37 1.54A1.56 1.56 0 0 1 16.25 24v.84z" />
      <path d="M16.5,25.06H15.2v-.8a3.17,3.17,0,0,1-1.27-.37l-.17-.09.38-1.46.28.16a2.66,2.66,0,0,0,1.24.33c.09,0,.54,0,.54-.26,0-.08,0-.24-.7-.48s-1.65-.69-1.65-1.74a1.72,1.72,0,0,1,1.39-1.66v-.83h1.3v.74a3,3,0,0,1,1.05.28l.19.09-.37,1.41-.33-.14a2.23,2.23,0,0,0-1-.24c-.17,0-.45,0-.45.21s.44.33.82.48c1.07.37,1.53.9,1.53,1.77a1.78,1.78,0,0,1-1.46,1.72Zm-.8-.5H16v-.8l.21,0a1.33,1.33,0,0,0,1.25-1.27c0-.53-.2-.95-1.2-1.3-.66-.25-1.15-.48-1.15-.95s.25-.71.95-.71a2.74,2.74,0,0,1,1,.19l.12-.44a3,3,0,0,0-.91-.17H16v-.71h-.3v.74l-.2,0a1.27,1.27,0,0,0-1.19,1.21c0,.59.38,1,1.31,1.27.54.18,1,.42,1,.95s-.41.76-1,.76a3.25,3.25,0,0,1-1.2-.25l-.12.46a3.42,3.42,0,0,0,1.12.24h.24Z" />
      <circle cx="24.78" cy="3.22" r=".78" />
      <circle cx="10.78" cy="4" r=".4" />
    </g>
  </svg>
);

export default Withdrawals;

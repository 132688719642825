// TODO remove
//@ts-nocheck
import React, { ComponentProps, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/lab";

interface IOption {
  name: string;
  key: string;
  subOptions?: IOption[];
  type?: "date" | "number";
  default?: boolean;
}

interface IProps<T = { [key: string]: string | number }> {
  search: T;
  setSearch: (a: T) => void;
  options: IOption[];
  defaultProps?: Partial<ComponentProps<typeof Autocomplete>>;
}

const FilterAutocompleteRef: React.FC<IProps> = ({ search, setSearch, options, defaultProps }) => {
  const [autocompleteValue, setAutocompleteValue] = useState<string[]>(
    Object.keys(search).map((k) => k)
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const [optionsToShow, setOptionsToShow] = useState<string[]>([]);

  const handleChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    list: string[],
    reason: AutocompleteChangeReason,
    detail: AutocompleteChangeDetails
  ) => {
    const selectedOptionName = detail?.option;
    const selectedOption = options.find((option) => option.name === selectedOptionName) as IOption;

    if (reason === "select-option") {
      setSearch({
        ...search,
        [selectedOption.key]: "",
      });

      if (selectedOption?.subOptions) {
        renderAutocompleteOptions(selectedOption.subOptions);
        setIsOpen(true);
      } else {
        setIsOpen(false);
        setIsFocused(false);
      }
    }

    if (reason === "remove-option") {
      const newSearch = { ...search };
      delete newSearch[selectedOption.key];
      setSearch(newSearch);
    }

    setAutocompleteValue(list);

    if (reason === "clear") {
      setAutocompleteValue([]);
      setSearch({});
    }
  };

  const renderAutocompleteOptions = (options: IOption[]) => {
    const dropdownOptions: string[] = [];
    for (const option of options) {
      dropdownOptions.push(option.name);
    }
    setOptionsToShow(dropdownOptions);
  };

  useEffect(() => {
    renderAutocompleteOptions(options);
  }, []);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          label="Filters"
          variant="outlined"
        />
      )}
      options={optionsToShow}
      value={autocompleteValue}
      open={isFocused || isOpen}
      onChange={handleChange}
      fullWidth
      {...defaultProps}
    />
  );
};

export default FilterAutocompleteRef;

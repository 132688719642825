import React from "react";
import { makeStyles } from "@material-ui/core";

import { setPage } from "../../../utils";

const useStyles = makeStyles({
  paginationContainer: {
    margin: "5px 0 15px 0",
  },
  paginationButton: {
    margin: "0 2px",
  },
});

interface IProps {
  count: number;
  page: number;
  _setPage: (a: number) => void;
  isLoading: boolean;
  limit?: number;
}

const Pagination = ({
  count,
  _setPage,
  page,
  isLoading,
  limit = 5,
}: IProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.paginationContainer}>
      <button
        type="button"
        className={classes.paginationButton}
        onClick={() => setPage(0, count, limit, _setPage)}
      >
        {"|<"}
      </button>
      <button
        type="button"
        className={classes.paginationButton}
        onClick={() => setPage(page - 1, count, limit, _setPage)}
      >
        {"<"}
      </button>

      {` ${page + 1}/${Math.ceil(count / limit)} `}

      <button
        type="button"
        className={classes.paginationButton}
        onClick={() => setPage(page + 1, count, limit, _setPage)}
      >
        {">"}
      </button>
      <button
        type="button"
        className={classes.paginationButton}
        onClick={() => setPage(Math.ceil(count / limit) - 1, count, limit, _setPage)}
      >
        {">|"}
      </button>

      {isLoading ? "Loading..." : ""}
    </div>
  );
};

export default Pagination;

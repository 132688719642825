/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO avoid use of any type
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import * as XLSX from "xlsx";
import cx from "classnames";
import moment from "moment";

import useGeneralStyles from "../../useGeneralStyles";
import { AccountsAPI } from "../../api/accounts";

const emailRegex = /\S+@\S+\.\S+/;

// Find email column with regex pattern
const emailColumn = (row: any) =>
  Object.keys(row).find((key) => {
    const value = row[key];
    return emailRegex.test(value);
  });

const extractEmailsFromSheetData = (data: any, resultArray: string[]) => {
  data.forEach((row: any) => {
    const foundEmailColumn = emailColumn(row); // Store result of emailColumn

    if (foundEmailColumn) {
      const email = row[foundEmailColumn]; // Use the stored result
      if (email && !resultArray.includes(email)) {
        resultArray.push(email);
      }
    }
  });
};

const ExcelParser: React.FC = () => {
  const [fileContent, setFileContent] = useState<any[]>([]);
  const allowedSheets = ["TCP100", "TCP50", "TCP25", "GAU50", "GAU100", "GAU150", "GAU200"];
  const generalClasses = useGeneralStyles();

  // File upload
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    try {
      const data = await readFileAsync(file); // Read the file asynchronously
      const workbook = XLSX.read(data, { type: "binary" });

      const sheetNames = workbook.SheetNames;
      const processedData: any[] = [];

      // Process each sheet
      for (const sheetName of sheetNames) {
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        // Filter rows if any of the columns contain "Waiting for cert"
        const filteredData = sheetData.filter((row: any) => {
          return Object.values(row).includes("Waiting for cert");
        });

        processedData.push({
          sheetName,
          data: filteredData,
        });
      }

      // Extract emails from the allowed sheets
      const extractedEmails: string[] = [];
      processedData.forEach((sheet) => {
        if (allowedSheets.includes(formatSheetName(sheet.sheetName)) && sheet.data.length > 0)
          extractEmailsFromSheetData(sheet.data, extractedEmails);
      });

      // Fetch user data from server, and attach it to the processed data array
      const updatedFileContent = await attachUserData(processedData, extractedEmails);
      // TODO: Save to mongodb
      setFileContent(updatedFileContent); // Update the state with the final content
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  // Function to attach user data to the file content
  const attachUserData = async (processedData: any[], emails: string[]) => {
    if (emails.length === 0) return processedData;

    try {
      const response = await getUserDataByEmails(emails);
      const { users } = response.data;
      const updatedContent = processedData.map((sheet) => {
        const updatedSheet = {
          ...sheet,
          data: sheet.data.map((row: any) => {
            // Dynamically find the email column in the row
            const emailColumn = Object.keys(row).find((key) => emailRegex.test(row[key]));

            if (emailColumn) {
              const email = row[emailColumn];
              const user = users.find((u: any) => u.email === email);

              if (user) {
                // Update the row with user address and name if a match is found
                return {
                  ...row,
                  address: user.address,
                  name: user.name,
                  country: user.country.name,
                };
              }
            }

            return row; // Return the original row if no match is found
          }),
        };

        return updatedSheet;
      });

      return updatedContent; // Return the updated content with extra data attached
    } catch (error) {
      console.error("Error fetching user data:", error);
      return processedData; // Return the original content if there’s an error
    }
  };

  // Helper function to read file asynchronously
  const readFileAsync = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsBinaryString(file);
    });
  };

  const formatSheetName = (sheetName: string) => {
    return sheetName.replace(/K/g, "").replace(/\s+/g, "").replace(/GM/g, "GAU");
  };

  const getUserDataByEmails = async (emails: string[]) => {
    const query = new URLSearchParams();
    emails.forEach((email) => query.append("emails", email));
    return await AccountsAPI.getAccountsListByEmailRequest(query.toString());
  };
  // Helper function to convert data to CSV
  const convertToCSV = (sheetData: any[]): string => {
    const csvRows = [];

    // Add headers (Name, Email, Country, Address)
    csvRows.push(["trader_email", "trader_name", "trader_full_name", "trader_address"].join(","));

    // Add rows of data
    sheetData.forEach((row: any) => {
      const rowData = [
        getRow(row, "email"),
        getRow(row, "name_short"),
        getRow(row, "name"),
        getRow(row, "address"),
      ];
      csvRows.push(rowData.join(","));
    });

    return csvRows.join("\n"); // Return CSV string
  };

  // Function to trigger CSV download
  const downloadCSV = (fileName: string, sheetData: any[]) => {
    const csvData = convertToCSV(sheetData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${moment().format("DDMMYYYY")}_${fileName}.csv`);
    a.click();
  };

  const isUSACountry = (row: any) => {
    const country = getRow(row, "country").toLowerCase();
    return (
      country === "us" ||
      country === "usa" ||
      country === "united states" ||
      country === "united states of america"
    );
  };

  // Function to split the data and export two CSV files (_US and _NONUS)
  const exportCSVFiles = (sheetName: string, sheetData: any[]) => {
    const usData = sheetData.filter((row) => isUSACountry(row));
    const nonUsData = sheetData.filter((row) => !isUSACountry(row));

    // Download the two CSV files, US and NONUS
    usData.length > 0 && downloadCSV(`${sheetName}_Helios`, usData);
    nonUsData.length > 0 &&
      setTimeout(() => {
        downloadCSV(`${sheetName}_EDX`, nonUsData);
      }, 500); // Add a 500ms delay before downloading the second file
  };

  // Helper function to find the email column
  const findEmailColumn = (row: any) =>
    Object.keys(row).find((key) => emailRegex.test(row[key])) || "__EMPTY_1";

  const getRow = (d: any, row: "name" | "name_short" | "email" | "country" | "address"): string => {
    const fieldMapping: { [key: string]: string } = {
      name: d["name"] || d["__EMPTY"] || "",
      name_short: (d["name"] || d["__EMPTY"] || "").split(" ")[0],
      email: d[findEmailColumn(d)] || "",
      country: d["country"] || d["__EMPTY_2"] || "",
      address: d["address"] || "N/A",
    };

    return (fieldMapping[row] || "").replace(",", "");
  };

  return (
    <div>
      <div className={generalClasses.containerBg}>
        <h2>Excel File Upload and Parsing</h2>
        <input type="file" accept=".xlsx" onChange={handleFileChange} />

        {fileContent.length > 0 &&
          fileContent.map((sheet, index) =>
            allowedSheets.includes(formatSheetName(sheet.sheetName)) && sheet.data.length > 0 ? (
              <React.Fragment key={index}>
                <h3>{sheet.sheetName}</h3>
                <table
                  className={cx(
                    generalClasses.table,
                    generalClasses.smTable,
                    generalClasses.tableWithoutHover,
                    generalClasses.noTextWrap
                  )}
                >
                  <thead>
                    <tr>
                      <td>Email</td>
                      <td>Name</td>
                      <td>Full name</td>
                      <td>Country</td>
                      <td>Address</td>
                    </tr>
                  </thead>
                  <tbody>
                    {sheet.data.map((d: any, i: number) => (
                      <tr key={i}>
                        <td>{getRow(d, "email")}</td>
                        <td>{getRow(d, "name_short")}</td>
                        <td>{getRow(d, "name")}</td>
                        <td>{getRow(d, "country")}</td>
                        <td>{getRow(d, "address")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => exportCSVFiles(sheet.sheetName, sheet.data)}
                >
                  Download {sheet.sheetName}
                </Button>
              </React.Fragment>
            ) : null
          )}
      </div>
    </div>
  );
};

export default ExcelParser;

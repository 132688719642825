import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

interface IPros {
  timeZone?: string;
  format?: string;
}

const TimeNov: React.FC<IPros> = ({
  timeZone = "America/Chicago",
  format = "YYYY-MM-DD HH:mm:ss",
}) => {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment().tz(timeZone).format(format));
    });

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <>{time}</>;
};

export default TimeNov;

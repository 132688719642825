import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Checkbox, IconButton } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import { CENTS_IN_DOLLAR, HOURS_12_FORMAT_WITH_DATE } from "../../../../../constants";
import { useComplianceSearchContext } from "../../../../../context";
import useStyles from "../useStyles";
import { CopyToClipboard } from "../../../../../components/ui";
import { IntlFormatUSD } from "../../../../../utils";

const TableBody = (): React.ReactElement => {
  const classes = useStyles();

  const { data } = useSelector((state) => state.complianceSearch.accounts_table);
  const {
    accounts_table: { selectedRows, setSelectedRows },
  } = useComplianceSearchContext();

  const handleRowClick = (id: number) => {
    if (selectedRows.find((r) => r.id === id)) {
      setSelectedRows(selectedRows.filter((r) => r.id !== id));
    } else {
      setSelectedRows([...selectedRows, { id }]);
    }
  };

  const handleClickProfileIcon = (id: number) => window.open(`#/customer/${id}`, "_blank");

  return (
    <tbody>
      {data.rows.map((r) => {
        const isSelectedRow = selectedRows.find((selected) => selected.id === r.id);

        return (
          <tr {...(isSelectedRow && { className: classes.selectedRow })} key={r.id}>
            <td>
              <Checkbox
                checked={!!isSelectedRow}
                color="default"
                onClick={() => handleRowClick(r.id)}
              />
            </td>
            <td>{r.id}</td>
            <td>{IntlFormatUSD(r.total_sales / CENTS_IN_DOLLAR)}</td>
            <td>
              <CopyToClipboard value={r.name} />
            </td>
            <td className={classes.tdEmail}>
              <IconButton onClick={() => handleClickProfileIcon(r.id)}>
                <AccountBoxIcon />
              </IconButton>
              <CopyToClipboard value={r.email} />
            </td>
            <td>
              <CopyToClipboard value={r.reg_ip} />
            </td>
            <td>
              <CopyToClipboard value={r.phone} />
            </td>
            <td>{r.country.name}</td>
            <td>{r.zip}</td>
            <td>{r.city}</td>
            <td>{r.address}</td>
            <td>{moment(r.joined).utc().format(HOURS_12_FORMAT_WITH_DATE)}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React from "react";

const Discount = ({
  w,
  h,
  m,
}: {
  w?: string | number;
  h?: string | number;
  m?: string | number;
}): React.ReactElement => (
  <svg
    height={h || w || "300px"}
    width={w || "300px"}
    style={{ marginBottom: m || 0, marginRight: 10 }}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    x="0px"
    y="0px"
  >
    <title>Artboard 6</title>
    <g data-name="Mail Box">
      <path d="M28.33,3.91A3,3,0,0,0,26,3l-7.47.5a3,3,0,0,0-1.9.86L3.92,17.13a3,3,0,0,0,0,4.2l7,7a3,3,0,0,0,4.2,0L27.83,15.59a3,3,0,0,0,.86-1.9l.5-7.47A3,3,0,0,0,28.33,3.91ZM18.06,17.42a3.92,3.92,0,0,0-.92-1.49c-.54-.54-1-.49-1.19-.25s-.16.74.28,1.72c.64,1.35.59,2.21-.17,3a2.27,2.27,0,0,1-3,.06l-.85.85-.82-.82.79-.79a4.76,4.76,0,0,1-1.06-1.78l1.23-.73a4.18,4.18,0,0,0,1,1.76c.48.48,1,.62,1.32.28s.25-.79-.16-1.61c-.59-1.2-.79-2.23,0-3a2.19,2.19,0,0,1,2.9-.08l.79-.79.82.82-.74.74a4.26,4.26,0,0,1,.94,1.49Zm5.4-5.85a2,2,0,1,1,0-2.79A2,2,0,0,1,23.46,11.57Z" />
    </g>
  </svg>
);

export default Discount;

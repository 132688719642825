import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    flexDirection: "row",
    display: "flex",
    padding: "10px 0",
    alignItems: "center",
  },
  formControl: {
    marginRight: 10,
  },
  select: {
    minWidth: 195,
  },
  cancelIcon: {
    marginLeft: 10,
  },
});

export default useStyles;

/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { IGetAgentsResponse } from "../api/agents/index.types";

export type TAgentsAction = IAgentsFetchRequest | IAgentsFetchSuccess | IAgentsFetchFailed;

interface IAgentsFetchRequest extends ReduxAction {
  type: EAgentsActionType.fetchRequest;
}
interface IAgentsFetchSuccess extends ReduxAction {
  type: EAgentsActionType.fetchSuccess;
  payload: {
    agents: IGetAgentsResponse["data"]["agents"];
    agentsWithCustomPreset: IGetAgentsResponse["data"]["agentsWithCustomPreset"];
    count: number;
  };
}
interface IAgentsFetchFailed extends ReduxAction {
  type: EAgentsActionType.fetchFailed;
}

export enum EAgentsActionType {
  fetchRequest = "agents/FetchRequest",
  fetchSuccess = "agents/FetchSuccess",
  fetchFailed = "agents/FetchFailed",
}

export const AgentsActions = {
  fetchAgentsRequest: (): TAgentsAction => ({
    type: EAgentsActionType.fetchRequest,
  }),
  fetchAgentsSuccess: ({ data }: IGetAgentsResponse): TAgentsAction => ({
    type: EAgentsActionType.fetchSuccess,
    payload: {
      agents: data.agents,
      count: data.count,
      agentsWithCustomPreset: data.agentsWithCustomPreset,
    },
  }),
  fetchAgentsFailed: (): TAgentsAction => ({
    type: EAgentsActionType.fetchFailed,
  }),
};

export interface IAgentsState {
  data: {
    agents: IGetAgentsResponse["data"]["agents"];
    agentsWithCustomPreset: Record<number, string[]>;
    count: number;
  };
  loading: boolean;
}
const initialState: IAgentsState = {
  data: {
    agents: [],
    agentsWithCustomPreset: {},
    count: 0,
  },
  loading: false,
};

const agentsReducer = (prevState = initialState, action: TAgentsAction): IAgentsState =>
  produce(prevState, (draft: IAgentsState) => {
    switch (action.type) {
      case EAgentsActionType.fetchRequest:
        draft.loading = true;
        break;
      case EAgentsActionType.fetchSuccess: {
        const agentsWithCustomerPreset: { [key: number]: string[] } = {};
        action.payload.agentsWithCustomPreset
          .flat()
          .forEach(({ admin_accounts_id, option_name }) => {
            admin_accounts_id.forEach((admin_id) => {
              if (agentsWithCustomerPreset[admin_id]) {
                agentsWithCustomerPreset[admin_id].push(option_name);
              } else {
                agentsWithCustomerPreset[admin_id] = [option_name];
              }
            });
          });
        draft.data.agentsWithCustomPreset = agentsWithCustomerPreset;
        draft.data.agents = action.payload.agents;
        draft.data.count = action.payload.count;
        draft.loading = false;
        break;
      }
      case EAgentsActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default agentsReducer;

import { ISalesStatsByDayResponse } from "../../api/billing/index.types";

export type FinancialsAction =
  //SalesByDays
  | FinancialsActionsSalesByDaysFetchRequest
  | FinancialsActionsSalesByDaysFetchSuccess
  | FinancialsActionsSalesByDaysFetchFailed;

//SalesByDays
export interface FinancialsActionsSalesByDaysFetchRequest {
  type: FinancialsActionType.fetchFinancialsSalesByDaysRequest;
}
export interface FinancialsActionsSalesByDaysFetchSuccess {
  type: FinancialsActionType.fetchFinancialsSalesByDaysSuccess;
  payload: ISalesStatsByDayResponse["data"]["report"];
}
export interface FinancialsActionsSalesByDaysFetchFailed {
  type: FinancialsActionType.fetchFinancialsSalesByDaysFailed;
}

export enum FinancialsActionType {
  //SalesByDays
  fetchFinancialsSalesByDaysRequest = "financials/fetchFinancialsSalesByDaysRequest",
  fetchFinancialsSalesByDaysSuccess = "financials/fetchFinancialsSalesByDaysSuccess",
  fetchFinancialsSalesByDaysFailed = "financials/fetchFinancialsSalesByDaysFailed",
}

export type IFinancialsState = {
  sales_by_days: {
    data: ISalesStatsByDayResponse["data"]["report"];
    loading: boolean;
  };
};

import React from "react";
import { Link, useHistory } from "react-router-dom";

import { IActivityLogsData } from "../../../../../api/agentActivityLog/index.types";
import { getTimeByAmericaChicagoTz, getTypeTime } from "../../../../../utils/moment";
import { getCellValue } from "../../../../../pages/Discounts/components/ChangeLogs/TableBody/TableBody";
import useGeneralStyles from "../../../../../useGeneralStyles";
import useStyles from "./useStyles";
import { IntlFormatUSD } from "../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../constants";
import { useAdminProviderDataContext, useDiscountsContext } from "../../../../../context";
import { getStringLogs, getMoneyValue } from "./utils";

const MONEY_ACTIONS = ["change_billing_amount"];

interface IProps {
  logs: IActivityLogsData[];
}
const TableBody: React.FC<IProps> = ({ logs }) => {
  const history = useHistory();

  const { setSearch: setDiscountCodesSearch, search: discountCodesSearch } = useDiscountsContext();
  const { user } = useAdminProviderDataContext();

  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const handleDiscountCodeClick = (code: string): void => {
    setDiscountCodesSearch({
      ...discountCodesSearch,
      code,
    });
    history.push("/discounts");
  };

  const getChangedValuesData = (log: IActivityLogsData): React.ReactElement | null => {
    const {
      log_admin_logins_id,
      discount_code_log_changes_id,
      discount_code_log_change,
      log_admin_accounts_activity_id,
      log_admin_accounts_activity,
      admin_accounts_log_profile_activity_id,
      admin_accounts_log_profile_activity,
    } = log;

    if (admin_accounts_log_profile_activity_id && admin_accounts_log_profile_activity) {
      const { field_name, previous_field_value, new_field_value, admin_account } =
        admin_accounts_log_profile_activity;
      return (
        <>
          <td data-test="action-name">{field_name}</td>
          <td className={classes.breakSpaces} data-test="previous-field-value">
            {previous_field_value && getStringLogs(previous_field_value)}
          </td>
          <td className={classes.breakSpaces} data-test="new-field-value">
            {new_field_value && getStringLogs(new_field_value)}
          </td>
          <td />
          <td>
            <Link to={`/agent/${admin_account.id}`}>{admin_account.name}</Link>
          </td>
          <td></td>
        </>
      );
    }
    if (log_admin_logins_id) {
      return (
        <>
          <td data-test="action-name">login</td>
          <td />
          <td />
          <td />
          <td />
          {user.is_manager && <td />}
        </>
      );
    }

    if (discount_code_log_changes_id && discount_code_log_change) {
      const { field_name, previous_field_value, new_field_value, discount_code } =
        discount_code_log_change;

      return (
        <>
          <td data-test="action-name">{discount_code_log_change?.field_name}</td>
          <td className={classes.breakSpaces} data-test="previous-field-value">
            {getCellValue(field_name, previous_field_value)}
          </td>
          <td className={classes.breakSpaces} data-test="new-field-value">
            {getCellValue(field_name, new_field_value)}
          </td>
          <td>
            <Link to={`/customer/${discount_code_log_change?.accounts_id}`}>
              {discount_code_log_change?.account?.name || ""}
            </Link>
          </td>
          {user.is_manager && <td />}
          <td
            data-test="discount-code"
            onClick={() => handleDiscountCodeClick(discount_code?.code)}
            className={generalClasses.tableHover}
          >
            {discount_code?.code}
          </td>
        </>
      );
    }

    if (log_admin_accounts_activity_id) {
      let prevTableDataValue = "";
      let newTableDataValue = "";
      const previous_field_value = log_admin_accounts_activity?.previous_field_value;
      const new_field_value = log_admin_accounts_activity?.new_field_value;
      if (previous_field_value) {
        prevTableDataValue = getStringLogs(previous_field_value);
      }
      if (new_field_value) {
        newTableDataValue = getStringLogs(new_field_value);
      }

      const field_name = log_admin_accounts_activity?.field_name || "";
      const isMoneyAction = MONEY_ACTIONS.includes(field_name);
      return (
        <>
          <td data-test="action-name">{log_admin_accounts_activity?.field_name}</td>
          <td data-test="previous-field-value" className={classes.breakSpaces}>
            {isMoneyAction
              ? IntlFormatUSD(
                  getMoneyValue(log_admin_accounts_activity?.previous_field_value as string) /
                    CENTS_IN_DOLLAR
                )
              : prevTableDataValue}
          </td>
          <td data-test="new-field-value" className={classes.breakSpaces}>
            {isMoneyAction
              ? IntlFormatUSD(
                  getMoneyValue(log_admin_accounts_activity?.new_field_value as string) /
                    CENTS_IN_DOLLAR
                )
              : newTableDataValue}
          </td>
          <td data-test="customer-link">
            <Link to={`/customer/${log_admin_accounts_activity?.accounts_id}`}>
              {log_admin_accounts_activity?.account.name}
            </Link>
          </td>
          {user.is_manager && <td />}
          <td />
        </>
      );
    }
    return null;
  };

  return (
    <tbody>
      {logs.map((log) => (
        <tr key={log.id} data-test="log-data">
          <td data-test="log-time">
            {getTimeByAmericaChicagoTz(log.log_time, true)} {getTypeTime(log.log_time)}
          </td>
          <td>
            <Link data-test="agent" to={`agent/${log.admin_accounts_id}`}>
              {log.admin_account.name}
            </Link>
          </td>
          {getChangedValuesData(log)}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

export const DIRECTION_FILTER_COMPARISONS = {
  "more than": ">",
  "less than": "<",
  equal: "=",
};

export const LIFE_TIME_FILTER = {
  "n/a": "n/a",
  "✘": "inactive",
  "✔": "active",
};
export const ACTIVE_FILTER = {
  active: "active",
  inactive: "inactive",
};
export const TYPE_FILTER = {
  $: "$",
  "%": "%",
};

export const EXPIRED_DISCOUNT_CODE_FILTER = {
  "✔": "Show",
  "✘": "Hide",
};

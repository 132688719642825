import { ReduxAction } from "../../static-types";
import { IGetAffiliateAccountsResponse } from "../../api/affiliates/intex.types";

export type AffiliatesAction =
  | AffiliateAccountsFetchRequest
  | AffiliateAccountsFetchSuccess
  | AffiliateAccountsFetchFailed;

export enum AffiliatesActionType {
  affiliateAccountsFetchRequest = "affiliate/AccountsFetchRequest",
  affiliateAccountsFetchSuccess = "affiliate/AccountsFetchSuccess",
  affiliateAccountsFetchFailed = "affiliate/AccountsFetchFailed",
}

export interface AffiliateAccountsFetchRequest extends ReduxAction {
  type: AffiliatesActionType.affiliateAccountsFetchRequest;
}
export interface AffiliateAccountsFetchSuccess extends ReduxAction {
  type: AffiliatesActionType.affiliateAccountsFetchSuccess;
  payload: IGetAffiliateAccountsResponse["data"];
}
export interface AffiliateAccountsFetchFailed extends ReduxAction {
  type: AffiliatesActionType.affiliateAccountsFetchFailed;
}

export interface IAffiliatesState {
  accounts: {
    data: IGetAffiliateAccountsResponse["data"]["affiliates"];
    count: IGetAffiliateAccountsResponse["data"]["count"];
    loading: boolean;
  };
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { getCurrentActiveTab } from "../../utils";
import { useEvaluationsContext } from "../../../../context";
import { APIEvaluation } from "../../../../api";

interface IProps {
  account: string;
  email: string;
}
const TableDataJourneyActions = ({ account, email }: IProps): React.ReactElement | null => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { activeTabIndex, fetchAllEvaluations } = useEvaluationsContext();
  const { customersInfo } = useSelector((state) => state.evaluations.data.evaluations);

  const {
    isActivePassedTab,
    isActiveCertificateDesignTab,
    isActiveCertificateEmailTab,
    isActiveWaitingForOfferTab,
  } = getCurrentActiveTab(activeTabIndex);

  const handleConfirmClick = async () => {
    try {
      setIsUpdating(true);
      await APIEvaluation.setCustomerEvaluationsSteps(account, {
        accounts_id: customersInfo.find((customer) => customer.email === email)?.id || 575,
        ...(isActivePassedTab && { confirm_passed: true }),
        ...(isActiveCertificateDesignTab && { certificated_design: true }),
        ...(isActiveCertificateEmailTab && { certificated_email: true }),
        ...(isActiveWaitingForOfferTab && { welcoming_process: true }),
      });
    } catch (error) {
      console.error("Error updating journey step", error);
    } finally {
      setIsUpdating(false);
      fetchAllEvaluations();
    }
  };

  if (Object.values(getCurrentActiveTab(activeTabIndex)).some((v) => v)) {
    return (
      <td>
        <FormControlLabel
          control={
            <Checkbox onChange={handleConfirmClick} name="" color="primary" disabled={isUpdating} />
          }
          label=""
        />
      </td>
    );
  }
  return null;
};

export default TableDataJourneyActions;

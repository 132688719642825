import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: 0,
  },
  tabsIndicator: {
    bottom: -5,
    backgroundColor: "#1288e5 ",
  },
  tabsRoot: {
    minHeight: 38,
    backgroundColor: "#f2f2f2",
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  ul: {
    paddingLeft: 30,
  },
});

export default useStyles;

import * as Yup from "yup";
import { EMAIL_REG_EXP } from "../../../../utils/validation";

export const newAffiliateValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .test("Email is invalid", function validEmail() {
      const { email } = this.parent;
      return !email || EMAIL_REG_EXP.test(email);
    }),
  code: Yup.string().required("Code is required"),
});

export const newAffiliateInitialValues = {
  name: "",
  email: "",
  code: "",
};

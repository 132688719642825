import httpClient from "../apiClient";
import { TLoginRequest } from "./index.types";

export const AUTH_API_URLS = {
  login: "agents/login",
} as const;

export class AuthAPI {
  static loginRequest: TLoginRequest = (data) => httpClient.post(AUTH_API_URLS.login, data);
}

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  centered: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "45%",
  },
});

export default useStyles;

import {
  IGetFundedAccountDocumentDatesResponse,
  IGetFundedAccountNotesResponse,
  IGetFundedTraderDataResponse,
  IGetTradedAccountsResponse,
} from "../../api/funds/index.types";
import { FundedTraderAction, FundedTraderActionType } from "./index.types";
import { IGetCustomerEvaluationCompleteHistoryResponse } from "../../api/evaluations/index.types";

export const FundedTraderActions = {
  //TraderAccounts
  fetchFundedTraderAccountsRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderAccountsFetchRequest,
  }),
  fetchFundedTraderAccountsSuccess: ({
    data: { traded_accounts },
  }: IGetTradedAccountsResponse): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderAccountsFetchSuccess,
    payload: traded_accounts,
  }),
  fetchFundedTraderAccountsFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderAccountsFetchFailed,
  }),
  // TraderData
  fetchFundedTraderDataRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDataFetchRequest,
  }),
  fetchFundedTraderDataSuccess: (
    data: IGetFundedTraderDataResponse["data"]
  ): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDataFetchSuccess,
    payload: data,
  }),
  fetchFundedTraderDataFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDataFetchFailed,
  }),
  //TraderDocumentsDates
  fetchFundedTraderDocumentsDatesRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDocumentsDatesFetchRequest,
  }),
  fetchFundedTraderDocumentsDatesSuccess: ({
    data,
  }: IGetFundedAccountDocumentDatesResponse): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDocumentsDatesFetchSuccess,
    payload: { ...data },
  }),
  fetchFundedTraderDocumentsDatesFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderDocumentsDatesFetchFailed,
  }),
  //TraderNotes
  fetchFundedTraderNotesRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderNotesFetchRequest,
  }),
  fetchFundedTraderNotesSuccess: (payload: IGetFundedAccountNotesResponse): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderNotesFetchSuccess,
    payload,
  }),
  fetchFundedTraderNotesFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderNotesFetchFailed,
  }),
  // EvaluationCompletedHistory
  fetchFundedTraderEvaluationCompletedHistoryRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchRequest,
  }),
  fetchFundedTraderEvaluationCompletedHistorySuccess: (
    payload: IGetCustomerEvaluationCompleteHistoryResponse["data"]
  ): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchSuccess,
    payload,
  }),
  fetchFundedTraderEvaluationCompletedHistoryFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchFailed,
  }),
  // EvaluationCompletedActiveAndRecentAccounts
  fetchFundedTraderPassesActiveAndRecentAccountsRequest: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchRequest,
  }),
  fetchFundedTraderPassesActiveAndRecentAccountsSuccess: (
    payload: IGetCustomerEvaluationCompleteHistoryResponse["data"]
  ): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchSuccess,
    payload,
  }),
  fetchFundedTraderPassesActiveAndRecentAccountsFailed: (): FundedTraderAction => ({
    type: FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchFailed,
  }),
};

import React from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { FormInput } from "../../../../components/ui";
import useStyles from "./useStyles";
import { APIFunds } from "../../../../api";
import { ISetFundedAccountNoteData } from "../../../../api/funds/index.types";

const newNoteSchema = Yup.object().shape({
  note: Yup.string().required("Text note is required").min(5),
});

interface IProps {
  fetchFundedTraderNotes: () => Promise<void>;
}
const AddNoteForm: React.FC<IProps> = ({ fetchFundedTraderNotes }) => {
  const { id } = useParams();

  const classes = useStyles();
  const { id: funded_traders_id } = useSelector((state) => state.fundedTrader);

  const handleSubmitNewNote = async (
    values: ISetFundedAccountNoteData,
    { resetForm }: { resetForm: () => void }
  ) => {
    const body = { ...values, funded_traders_id };
    await APIFunds.setFundedAccountNoteRequest(id, body);
    await fetchFundedTraderNotes();
    resetForm();
  };

  return (
    <Formik
      initialValues={{ note: "" }}
      validateOnChange
      validationSchema={newNoteSchema}
      onSubmit={handleSubmitNewNote}
    >
      {({ isValid }) => (
        <Form className={classes.verticalAlign}>
          <Field name="note" label="Note" placeholder="Note" size="small" component={FormInput} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid}
            className={classes.addButton}
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddNoteForm;

import React from "react";
import TableDataCellWithSort from "../../../TableDataCellWithSort";
import { ISortBy } from "../../../../../types";
import newSortBy from "../../../../../utils/newSortBy";
import { useAdminProviderDataContext } from "../../../../../context";

interface IProps {
  handleSort: [ISortBy, (sortBy: ISortBy) => void];
}

const TableHead: React.FC<IProps> = ({ handleSort: [sortBy, setSortBy] }) => {
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });
  const { user } = useAdminProviderDataContext();

  return (
    <thead>
      <tr>
        <TableDataCellWithSort
          sortBy={sortBy}
          by="log_time"
          title="Date"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="admin_accounts_id"
          title="Agent"
          newSortBy={handleSortBy}
        />
        <td>Action</td>
        <td>Previous value</td>
        <td>New value</td>
        <td>Customer</td>
        {user.is_manager && <td>Updated agent</td>}
        <td>Code</td>
      </tr>
    </thead>
  );
};

export default TableHead;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { APIAccounts } from "../../../../api";
import { IAccountEmail } from "../../../../api/accounts/index.types";

import { useCustomerProvider } from "../../../../context";
import useStyles from "./useStyles";
import { stringDistance } from "./utils";

interface ISimilarEmail {
  index: number;
  id: number;
  email: string;
}

const SimilarAccounts = (): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const { customer } = useCustomerProvider();

  const [emailsToDisplay, setETD] = useState<ISimilarEmail[]>([]);
  const [moreEmails, setMoreEmails] = useState<boolean>(false);
  const [emails, setEmails] = useState<IAccountEmail[]>([]);

  const similarEmails: ISimilarEmail[] = [];

  const handleOnUserClick = useCallback(
    (id: number) => {
      history.push(`/customer/${id}/#`);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    [history]
  );

  const findSimilarEmails = () => {
    if (customer.email !== "") {
      emails.forEach((e: IAccountEmail) => {
        if (e.email !== customer.email) {
          const sd = stringDistance(e.email, customer.email);
          if (sd > 0.9) similarEmails.push({ ...e, index: Number(sd.toFixed(2)) });
        }
      });
    }
    setETD(similarEmails);
  };

  useEffect(() => {
    findSimilarEmails();
  }, [customer]);

  useEffect(() => {
    (async () => {
      try {
        const response = await APIAccounts.getEmailsRequest();
        setEmails(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div>
      <b>
        Similar Accounts({emailsToDisplay && emailsToDisplay.length}):{" "}
        <button type="button" onClick={() => setMoreEmails(!moreEmails)}>
          {moreEmails ? "Show High" : "Show All"}
        </button>
      </b>

      <div className={classes.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Similarity</th>
            </tr>
          </thead>
          <tbody>
            {emailsToDisplay &&
              emailsToDisplay
                .sort((a, b) => b.index - a.index)
                .filter((f) => (moreEmails ? f.index > 0 : f.index > 0.95))
                .map((value) => {
                  const similarLevel = value.index > 0.92 ? "Medium" : "Low";
                  return (
                    <tr
                      className="similar-email"
                      key={value.email}
                      onClick={() => handleOnUserClick(value.id)}
                    >
                      <td>{value.email}</td>
                      <td>{value.index > 0.95 ? "High" : similarLevel}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SimilarAccounts;

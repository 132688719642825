import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";

import useStyles from "./useStyles";
import { CustomerTitle, MainCustomerInfo, CustomerStatus } from "../../components/ui";
import { useCustomerProvider } from "../../context";
import { APIFunds } from "../../api";
import { FundedTraderActions } from "../../reducers/fundedTraderReducer/actions";

import * as Component from "./components";
import { FUNDS_API_URLS } from "../../api/funds";

const FundedTrader = (): React.ReactElement => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { customer } = useCustomerProvider();

  useQuery({
    queryKey: [FUNDS_API_URLS.get_funded_trader_data, id],
    queryFn: async () => {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.getFundedTraderDataRequest(id);
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
      return response.data;
    },
    onError: (error) => {
      console.error("Error fetching funded trader data", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    },
  });

  return (
    <CustomerStatus>
      <CustomerTitle />
      <MainCustomerInfo />

      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <Component.UploadFilesGoogleDrive />
          </Grid>
        </Grid>
        <div className={classes.container}>
          <Component.DocumentDatesForm />
        </div>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Component.PassesHistory />
          </Grid>
        </Grid>
      </div>

      <div className={classes.container}>
        <Component.PreEnvoyData />
      </div>
      <div className={classes.container}>
        <Component.TradedAccounts customerId={customer.id} />
      </div>
      <div className={classes.container}>
        <Component.WithdrawalRequestFormWithRequestsList />
      </div>
    </CustomerStatus>
  );
};

export default FundedTrader;

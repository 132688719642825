import React from "react";

import useStyles from "../../useStyles";
import TCPStepsSearch from "../TCPStepsSearch";
import GMCappersSearch from "../../../../reducers/GMCappersSearch";
import HeliosPassesSearch from "../HeliosPassesSearch";
import HeliosFailedSearch from "../HeliosFailedSearch";
import HeliosWithdrawalsSearch from "../HeliosWithdrawalsSearch";
import HeliosAndAppiusLiveSearch from "../HeliosAndAppiusLiveSearch";
import HeliosLiveSIMTraderInformationFormSearch from "../HeliosLiveSIMTraderInformationFormSearch";

const FundedAccountQuickSearch: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableContainer}>
        <HeliosPassesSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosWithdrawalsSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosFailedSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <TCPStepsSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <GMCappersSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosLiveSIMTraderInformationFormSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosAndAppiusLiveSearch />
      </div>
    </div>
  );
};

export default FundedAccountQuickSearch;

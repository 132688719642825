import React from "react";
import copy from "copy-to-clipboard";
import { SelectRowCell } from "./components";

import * as Type from "../../index.types";

export const arrayToCsv = (csv: Type.TCsv): string => {
  let csvString = "";

  csv.forEach((row) => {
    row.forEach((cell, index) => {
      if (index === row.length - 1) {
        csvString += `${cell.value}`;
        return;
      }
      csvString += `${cell.value},`;
    });
    csvString += "\n";
  });

  return csvString;
};

export const handleCopySelectedCellsToClipBoard: Type.THandleCopySelectedCellsToClipBoard = (
  { start, end },
  csv
) => {
  const arrayToCopyClipboard: Type.TCsv = [];

  csv.forEach((row, rowIndex) => {
    if ((rowIndex >= start.i && rowIndex <= end.i) || (rowIndex <= start.i && rowIndex >= end.i)) {
      const startIndex = start.j;
      const endIndex = end.j;
      const r = [...row];
      if (startIndex > endIndex) {
        arrayToCopyClipboard.push(r.splice(endIndex, startIndex - endIndex + 1));
      } else {
        arrayToCopyClipboard.push(r.splice(startIndex, endIndex - startIndex + 1));
      }
    }
  });

  const csvString = arrayToCsv(arrayToCopyClipboard);
  copy(csvString);
  return csvString;
};

export const handleCopySelectedRowsToClipBoard = (
  csvWithActions: Type.TCsv,
  rowSelections: boolean[]
): void => {
  const selectedRows: Type.TCsv = [];
  csvWithActions.forEach((row, index) => {
    if (rowSelections[index]) {
      selectedRows.push([...row].splice(1));
    }
  });
  copy(arrayToCsv(selectedRows));
};

const BLOCKED_SYMBOLS_NAMES = ["Quote", "Comma", "IntlBackslash", "Digit6", "Semicolon"];
const BLOCKED_SYMBOLS = ["'", "^", ";", "´", "`"];
const regexPattern = new RegExp(`[${BLOCKED_SYMBOLS.map((symbol) => `\\${symbol}`).join("")}]`);
export const onBlockKeyDownKeys = (e: React.KeyboardEvent<HTMLDivElement>): void => {
  if (BLOCKED_SYMBOLS_NAMES.includes(e.code)) {
    e.preventDefault();
  }
};
export const isValidPastedValue = (e: React.ClipboardEvent<HTMLDivElement>): boolean => {
  const clipboardString = e.clipboardData.getData("text/plain");
  if (regexPattern.test(clipboardString)) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const removeSelectedCells: Type.THandleRemoveSelectedCells = ({
  csv,
  setCsv,
  selectedCells,
  setIsChanged,
  setIsConfirmDialogOpen,
}) => {
  const newCsv: Type.TCsv = [];
  csv.forEach((row, rowIndex) => {
    if (
      (rowIndex >= selectedCells.start.i && rowIndex <= selectedCells.end.i) ||
      (rowIndex <= selectedCells.start.i && rowIndex >= selectedCells.end.i)
    ) {
      const startIndex = selectedCells.start.j;
      const endIndex = selectedCells.end.j;
      const r = [...row];
      if (startIndex > endIndex) {
        r.splice(endIndex, startIndex - endIndex + 1);
      } else {
        r.splice(startIndex, endIndex - startIndex + 1);
      }
      newCsv.push(r);
      return;
    }
    newCsv.push(row);
  });
  setCsv(newCsv);
  setIsChanged(true);
  setIsConfirmDialogOpen("");
};

export const getFormData = (csv: Type.TCsv, currentFileName: string): FormData => {
  // Removes first column with checkboxes
  const csvWithoutCheckbox = csv.map((row) => row.filter((cell) => !cell.component));

  const csvString = arrayToCsv(csvWithoutCheckbox);
  const blob = new Blob([csvString], { type: "text/plain" });
  const formData = new FormData();

  const lastFileTypeIndex = currentFileName.lastIndexOf(".txt");
  const substringBeforeDate = currentFileName.substring(0, lastFileTypeIndex + 1);

  const lastUnderscoreIndex = substringBeforeDate.lastIndexOf("_");
  const substringBefore = substringBeforeDate.substring(0, lastUnderscoreIndex + 1);
  const fileName = substringBefore.replace("coperations_", "");
  const timestamp = Math.floor(Date.now() / 1000);

  formData.append("file", blob, `${fileName}${timestamp}.txt`);

  return formData;
};

export const getChangedCsv = (
  csv: Type.TCsv,
  changes: Type.TCellsChangedArgs
): Type.GridElement[][] => {
  const grid = csv.map((row) => [...row]);
  changes.forEach(({ row, col, value }) => {
    grid[row][col] = { ...grid[row][col], value: value as string };
  });
  return grid;
};

export const getChangedCsvAfterPaste = (
  csvString: string,
  { start }: Type.TSelection,
  currentCsv: Type.TCsv
): Type.GridElement[][] => {
  const pastedGrid = csvString.split("\n").map((row) => row.split(","));

  const newGrid = currentCsv.map((row, rowIndex) => {
    if (rowIndex < start.i || rowIndex > start.i + pastedGrid.length - 1) {
      return row;
    }
    const r = [...row];
    pastedGrid[rowIndex - start.i].forEach((cell, cellIndex) => {
      r[start.j + cellIndex] = { ...r[start.j + cellIndex], value: cell };
    });
    // replace all empty slots in array after pasting
    for (let i = 0; i < r.length; i++) {
      if (!r[i]) {
        r[i] = { value: "" };
      }
    }
    return r;
  });
  return newGrid;
};

const CELL_WITH_CHECKBOX_PROPS = {
  forceComponent: true,
  value: "",
  disableEvents: true,
  readOnly: true,
  width: 100,
};
export const addCellWithCheckboxIntoRow = (
  csv: Type.TCsv,
  rowSelections: boolean[],
  setRowSelections: (selection: boolean[]) => void,
  isArchiveFolder: boolean
): Type.GridElement[][] => {
  const newCsv = csv.map((row, index) => {
    if (isArchiveFolder) {
      row.unshift({
        ...CELL_WITH_CHECKBOX_PROPS,
        component: (
          <SelectRowCell
            index={index}
            rowSelections={rowSelections}
            setRowSelections={setRowSelections}
          />
        ),
      });
    }

    return row;
  });
  return newCsv;
};

type TProps = (
  csvWithActions: Type.TCsv,
  rowSelections: boolean[],
  setRowSelections: (selection: boolean[]) => void,
  isArchiveFolder: boolean
) => Type.TCsv;
export const getUpdatedCheckboxSelections: TProps = (
  csvWithActions,
  rowSelections,
  setRowSelections,
  isArchiveFolder
) => {
  const updated = csvWithActions.map((row, index) => {
    if (isArchiveFolder) {
      row[0] = {
        ...CELL_WITH_CHECKBOX_PROPS,
        component: (
          <SelectRowCell
            index={index}
            rowSelections={rowSelections}
            setRowSelections={setRowSelections}
          />
        ),
      };
    }

    return row;
  });

  return updated;
};

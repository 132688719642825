import React from "react";
import cx from "classnames";
import { IconButton } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import { IGetAllEntriesRequestByValuesResponse } from "../../../../../../api/compliance/index.types";
import {
  ContainerWithLoader,
  CopyToClipboard,
  PaginationWithLimit,
} from "../../../../../../components/ui";
import TableHead from "../SearchAccountsByName/TableHead";
import useStyles from "../../../../useStyles";
import useGeneralStyles from "../../../../../../useGeneralStyles";
import { IPaginationState } from "../../../../../../hooks/usePagination";

interface IProps {
  loading: boolean;
  data: IGetAllEntriesRequestByValuesResponse["data"];
  pagination: IPaginationState;
  handleChangePage: (page: number) => void;
  table_name: string;
}
const Table: React.FC<IProps> = ({ handleChangePage, pagination, loading, data, table_name }) => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const handleClickProfileIcon = (id: number) => window.open(`#/customer/${id}`, "_blank");

  return (
    <div className={classes.tableContainer}>
      <div>
        <span>
          Users found: <b>{data.count}</b>
        </span>
      </div>
      <ContainerWithLoader isLoading={loading} report={data.rows}>
        <div className={generalClasses.tableHorizontalScroll}>
          <table
            className={cx(
              generalClasses.table,
              generalClasses.smTable,
              generalClasses.tableWithoutHover,
              generalClasses.noTextWrap
            )}
          >
            <TableHead data={data.rows} table_name={table_name} />
            <tbody>
              {data.rows.map((account) => (
                <tr key={account.id}>
                  <td>{account.id}</td>
                  <td>
                    <CopyToClipboard value={account.name} />
                  </td>
                  <td className={classes.tdEmail}>
                    <IconButton onClick={() => handleClickProfileIcon(account.id)}>
                      <AccountBoxIcon />
                    </IconButton>
                    <CopyToClipboard value={account.email} />
                  </td>
                  <td>
                    <CopyToClipboard value={account.phone} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationWithLimit
          count={pagination.count}
          limit={pagination.limit}
          setLimit={pagination.setLimit}
          page={pagination.page}
          setPageState={handleChangePage}
        />
      </ContainerWithLoader>
    </div>
  );
};

export default Table;

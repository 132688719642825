import React, { useState } from "react";
import { useParams } from "react-router-dom";

import DialogConfirm from "../DialogConfirm/DialogConfirm";
import { APIAccounts } from "../../../../api";

interface IProps {
  cus_id: string;
}

const DeleteCustomerAccount = ({ cus_id }: IProps): React.ReactElement => {
  const { id } = useParams();
  const [isDeleted, setDeleted] = useState(false);

  const handleClickDelete = async () => {
    try {
      const response = await APIAccounts.deleteAccountRequest({ id, cus_id });
      if (response.data.result === "success") {
        setDeleted(true);
      } else {
        setDeleted(false);
      }
      return response;
    } catch (error) {
      setDeleted(false);
      console.error("error deleting account:", error);
    }
    return null;
  };

  return (
    <DialogConfirm
      data-test="delete-customer-account"
      handleRequest={handleClickDelete}
      isShowMainButton={!isDeleted}
      dialogTitle="Delete account"
      contentText="Are you sure you want to delete account ?"
      buttonText="Delete account"
      alertTextSuccess="The account has been deleted"
      alertTextError="Error deleting account"
      externalStyles={{
        backgroundColor: "#e53935",
      }}
    />
  );
};

export default DeleteCustomerAccount;

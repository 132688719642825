import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    display: "block",
    margin: "10px 0",
  },
  hover: {
    cursor: "pointer",
  },
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 7,
  },
  notesContainer: {
    marginTop: 5,
  },
  collapseContainer: {
    marginTop: 5,
  },
});

export default useStyles;

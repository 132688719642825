import { makeStyles } from "@material-ui/core";
import {
  INACTIVE_STATUS_COLOR,
  ON_HOLD_STATUS_COLOR,
  IN_PROGRESS_STATUS_COLOR,
  READY_STATUS_COLOR,
  FAILED_OR_BANNED_STATUS_COLOR,
} from "../TradedAccountsInfoTableColumns/utils";

const useStyles = makeStyles({
  bgContainer: {
    display: "block",
  },
  inactive: {
    backgroundColor: `${INACTIVE_STATUS_COLOR} !important`,
  },
  recent_passer: {
    backgroundColor: `${READY_STATUS_COLOR} !important`,
  },
  on_hold: {
    backgroundColor: `${ON_HOLD_STATUS_COLOR} !important`,
  },
  inProgressStatus: {
    backgroundColor: `${IN_PROGRESS_STATUS_COLOR} !important`,
  },
  active: {
    backgroundColor: `${READY_STATUS_COLOR} !important`,
  },
  banned: {
    backgroundColor: `${FAILED_OR_BANNED_STATUS_COLOR} !important`,
  },
  duplicate: {
    backgroundColor: `${IN_PROGRESS_STATUS_COLOR} !important`,
  },
  forcedToLive: {
    "& fieldset": {
      borderColor: FAILED_OR_BANNED_STATUS_COLOR,
    },
  },
});

export default useStyles;

import React from "react";
import cx from "classnames";

import AccountsProcessingAndInvoiceStatusSelect from "../../../FundedTrader/components/AccountsProcessingAndInvoiceStatusSelect";
import {
  EInvoiceStatusesLiveSimFundedTrader,
  EProcessingStatuses,
  INVOICE_LIVE_SIM_STATUSES_OPTIONS,
  INVOICE_LIVE_STATUSES_OPTIONS,
  PROCESSING_STATUSES_OPTIONS,
} from "../../../../constants";
import { ITradedAccount } from "../../../../api/funds/index.types";
import { IDialogConfirmProps } from "../FundedTradersTable/TableBody";
import useStyles from "./useStyles";
import {
  INVOICE_STATUSES_IN_PROGRESS,
  INVOICE_STATUSES_READY,
  PROCESSING_STATUSES_IN_PROGRESS,
  PROCESSING_STATUSES_READY,
} from "./utils";

interface IProps {
  traded_accounts: ITradedAccount[];
  setDialogConfirmProps: (v: IDialogConfirmProps) => void;
  id: number;
  invoice_status: string | null;
  processing_status: string | null;
}

const NestedTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <td>
      <table className={cx(classes.fullWidth, classes.table)}>
        <tbody>{children}</tbody>
      </table>
    </td>
  );
};

const TradedAccountsInfoTableColumns: React.FC<IProps> = ({
  traded_accounts,
  setDialogConfirmProps,
  id,
  invoice_status,
  processing_status,
}) => {
  const classes = useStyles();
  const handleChangeStatus = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number,
    e2t_account_id: string,
    isEditInitialProcStatusAndInvoiceStatus = false
  ) => {
    const { name, value } = e.target;
    const isProcessingStatusEditing = name === "processing_status";

    const baseTitle = `Are you sure you want to change Account ID "${e2t_account_id}"`;
    const dialogContentProcessingStatusText = `${baseTitle} processing status ?`;
    const dialogContentInvoiceStatusText = `${baseTitle} invoice status ?`;

    const dialogTitle = isProcessingStatusEditing
      ? "Change processing status"
      : "Change invoice status";
    const dialogContentText = isProcessingStatusEditing
      ? dialogContentProcessingStatusText
      : dialogContentInvoiceStatusText;

    setDialogConfirmProps({
      isOpen: true,
      dialogTitle,
      dialogContentText,
      id,
      accounts_id,
      name: name as string,
      value: value as string,
      isEditInitialProcStatusAndInvoiceStatus,
    });
  };

  const checkIsProcessingStatusIsReady = (processing_status: EProcessingStatuses) =>
    PROCESSING_STATUSES_READY.includes(processing_status);
  const checkIsInvoiceStatusIsReady = (invoice_status: EInvoiceStatusesLiveSimFundedTrader) =>
    INVOICE_STATUSES_READY.includes(invoice_status);

  const liveAndLiveSimPotions = [
    ...INVOICE_LIVE_SIM_STATUSES_OPTIONS,
    ...INVOICE_LIVE_STATUSES_OPTIONS,
  ].reduce((acc, option) => {
    if (!acc.some((e: { value: string }) => e.value === option.value)) {
      // @ts-ignore
      acc.push(option);
    }
    return acc;
  }, []);

  return (
    <>
      <NestedTable>
        <tr>
          <td className={classes.e2tAccountID}></td>
        </tr>
        {traded_accounts.map((account) => (
          <tr key={account.id}>
            <td className={classes.e2tAccountID}>
              <span
                className={cx(classes.accountsIdContainer, {
                  [classes.regularStatus]: true,
                  [classes.readyStatus]:
                    checkIsProcessingStatusIsReady(account.processing_status) &&
                    checkIsInvoiceStatusIsReady(account.invoice_status),
                  [classes.failedReviewStatus]:
                    account.processing_status === EProcessingStatuses.failed,
                  [classes.inProgressStatus]:
                    (PROCESSING_STATUSES_IN_PROGRESS.includes(account.processing_status) &&
                      INVOICE_STATUSES_IN_PROGRESS.includes(account.invoice_status)) ||
                    !checkIsProcessingStatusIsReady(account.processing_status) ||
                    !checkIsInvoiceStatusIsReady(account.invoice_status),
                })}
              >
                {account.e2t_account_id}
              </span>
            </td>
          </tr>
        ))}
      </NestedTable>

      <NestedTable>
        <tr>
          {/*INITIAL processing_status*/}
          <td className={classes.td}>
            <AccountsProcessingAndInvoiceStatusSelect
              useSmallSize
              options={PROCESSING_STATUSES_OPTIONS}
              name="processing_status"
              value={processing_status || ""}
              id={id}
              accounts_id={id}
              handleChange={(e, id, accounts_id) => {
                handleChangeStatus(e, id, accounts_id, "", true);
              }}
            />
          </td>
        </tr>
        {traded_accounts.map((account) => (
          <tr key={account.id}>
            <td className={classes.td}>
              <AccountsProcessingAndInvoiceStatusSelect
                useSmallSize
                bgStylesClass={cx({
                  [classes.inProgressStatus]: true,
                  [classes.readyStatus]: checkIsProcessingStatusIsReady(account.processing_status),
                  [classes.failedStatus]: account.processing_status === EProcessingStatuses.failed,
                })}
                options={PROCESSING_STATUSES_OPTIONS}
                name="processing_status"
                value={account.processing_status}
                id={account.id}
                accounts_id={account.accounts_id}
                handleChange={(e, id, accounts_id) => {
                  handleChangeStatus(e, id, accounts_id, account.e2t_account_id);
                }}
              />
            </td>
          </tr>
        ))}
      </NestedTable>

      <NestedTable>
        <tr>
          {/*INITIAL invoice_status*/}
          <td className={classes.td}>
            <AccountsProcessingAndInvoiceStatusSelect
              useSmallSize
              options={liveAndLiveSimPotions}
              name="invoice_status"
              value={invoice_status || ""}
              id={id}
              accounts_id={id}
              handleChange={(e, id, accounts_id) => {
                handleChangeStatus(e, id, accounts_id, "", true);
              }}
            />
          </td>
        </tr>
        {traded_accounts.map((account) => (
          <tr key={account.id}>
            <td className={classes.td}>
              <AccountsProcessingAndInvoiceStatusSelect
                useSmallSize
                bgStylesClass={cx({
                  [classes.inProgressStatus]: true,
                  [classes.readyStatus]: checkIsInvoiceStatusIsReady(account.invoice_status),
                })}
                options={
                  account.account_type === "LiveSim"
                    ? INVOICE_LIVE_SIM_STATUSES_OPTIONS
                    : INVOICE_LIVE_STATUSES_OPTIONS
                }
                name="invoice_status"
                value={account.invoice_status || ""}
                id={account.id}
                accounts_id={account.accounts_id}
                handleChange={(e, id, accounts_id) => {
                  handleChangeStatus(e, id, accounts_id, account.e2t_account_id);
                }}
              />
            </td>
          </tr>
        ))}
      </NestedTable>
    </>
  );
};

export default TradedAccountsInfoTableColumns;

import { FinancialsAction, FinancialsActionType } from "./index.types";
import { ISalesStatsByDayResponse } from "../../api/billing/index.types";

export const FinancialsActions = {
  //SalesByDays
  fetchFinancialsSalesByDaysRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysRequest,
  }),
  fetchFinancialsSalesByDaysSuccess: (
    payload: ISalesStatsByDayResponse["data"]["report"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysSuccess,
    payload,
  }),
  fetchFinancialsSalesByDaysFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysFailed,
  }),
};

import React from "react";
import { List, ListItem } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps {
  allowed_features: string[];
}

const AllowedFeaturesToolTip: React.FC<IProps> = ({ allowed_features }) => (
  <Tooltip
    title={
      <List>
        {allowed_features.map((feature) => (
          <ListItem style={{ height: 10 }} key={feature}>
            {feature}
          </ListItem>
        ))}
      </List>
    }
  >
    <InfoIcon />
  </Tooltip>
);

export default AllowedFeaturesToolTip;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { FeedActions } from "../../../../reducers/feedReducer";

import TableBody from "../TableBody";
import TableHeader from "../TableHeader";
import { PaginationWithLimit } from "../../../../components/ui";

import { APIBilling } from "../../../../api";
import { useFeedContext } from "../../../../context";
import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";

interface IProps {
  groupBy: string;
  inputProps?: {
    max: number;
  };
}
const PaymentsByCategory = ({ groupBy, inputProps }: IProps): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const dispatch = useDispatch();
  const { sortBy, search, page, setPage, limit, setLimit } = useFeedContext();

  const [isFetchError, setIsFetchError] = useState(false);

  const { loading, payments, count } = useSelector((state) => state.feed.data.failedPayments);

  useEffect(() => {
    dispatch(FeedActions.fetchFailedPaymentsRequest());

    (async () => {
      try {
        const response = await APIBilling.getBillingStatusesRequest({
          page,
          limit,
          sortBy,
          contains: search,
          groupBy,
        });
        const { data } = response;
        dispatch(FeedActions.fetchFailedPaymentsSuccess(data));
      } catch (error) {
        setIsFetchError(true);
        dispatch(FeedActions.fetchFailedPaymentsFailed());
      }
    })();
  }, [dispatch, limit, page, search, sortBy, groupBy]);

  return (
    <div>
      <table className={generalClasses.table}>
        <TableHeader groupBy={groupBy} />
        {!loading && <TableBody groupBy={groupBy} />}
      </table>

      {loading && (
        <div className={classes.preloaderContainer}>
          <CircularProgress classes={{ circle: classes.circle, root: classes.preloader }} />
        </div>
      )}
      {!loading && !payments.length && !isFetchError && (
        <div className={classes.noResults}>
          <span>No results</span>
        </div>
      )}

      {isFetchError && (
        <div className={classes.noResults}>
          <span>There was a problem retrieving info from Stripe</span>
        </div>
      )}

      <PaginationWithLimit
        inputProps={inputProps}
        count={count}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPageState={setPage}
      />
    </div>
  );
};

export default PaymentsByCategory;

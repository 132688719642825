import React, { useState } from "react";
import { Context } from "chartjs-plugin-datalabels";

import { COLOR_PALETTE } from "../../../../constants";
import { APIBilling } from "../../../../api";
import { IReportProductsStatisticsByDates } from "../../../../api/billing/index.types";
import {
  ContainerWithLoader,
  SelectByDateWithCustomPeriod,
  ChartBar,
} from "../../../../components/ui";
import useStyles from "./useStyles";

interface IChartDatasets {
  label: string;
  data: number[];
  backgroundColor: string;
  stack: number;
  gauntletValueName: number;
}

const externalOptions = {
  responsive: true,
  interaction: {
    mode: "point",
  },
  plugins: {
    // tooltip: {
    //   filter(e: Record<string, string>, i: any, a: any) {
    //     console.log("e", a);
    //     const { formattedValue } = e;
    //     if (+formattedValue > 0) {
    //       return e;
    //     }
    //     return null;
    //   },
    //   position: "nearest",
    // },
    datalabels: {
      formatter: (value: number) => value || "",
      anchor: (value: Context) => (value.datasetIndex % 2 ? "end" : "start"),
      align: (value: Context) => (value.datasetIndex % 2 ? "end" : "center"),
    },
    legend: {
      labels: {
        filter(
          { datasetIndex }: Record<string, number>,
          chartData: { datasets: IChartDatasets[] }
        ) {
          const { datasets } = chartData;
          return datasets[datasetIndex].data.some((n) => n > 0);
        },
      },
    },
  },
};

const ChargesOverTime = (): React.ReactElement => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [barData, setBarData] = useState({});
  const [report, setReport] = useState<IReportProductsStatisticsByDates[]>([]);

  const GAU_VALUE_NAME = 1000;

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    try {
      setIsLoading(true);
      const response = await APIBilling.getProductsStatisticsByDatesRequest({
        startDate,
        endDate,
        isAllTime,
      });
      const { report: reportData } = response.data;

      const labels = new Set(reportData.map(({ purchase_date }) => purchase_date));
      const datasets: IChartDatasets[] = [
        {
          label: "Paid resets",
          data: new Array(Array.from(labels).length).fill(0),
          backgroundColor: COLOR_PALETTE[10],
          stack: 0,
          gauntletValueName: GAU_VALUE_NAME - 2,
        },
        {
          label: "Free reset",
          data: new Array(Array.from(labels).length).fill(0),
          backgroundColor: COLOR_PALETTE[22],
          stack: 0,
          gauntletValueName: GAU_VALUE_NAME - 3,
        },
      ];
      reportData.forEach(({ name, plan_id }) => {
        const index = datasets.findIndex(({ stack }) => stack === plan_id);
        if (index === -1) {
          datasets.push({
            label: `${name} rebill`,
            data: [],
            backgroundColor: COLOR_PALETTE[datasets.length],
            stack: plan_id,
            /* position by default in chart should be the last */
            gauntletValueName: GAU_VALUE_NAME - 1,
          });
          datasets.push({
            label: `${name} new purchase`,
            data: [],
            backgroundColor: COLOR_PALETTE[datasets.length],
            stack: plan_id,
            /* position by default in chart should be the last */
            gauntletValueName: GAU_VALUE_NAME - 1,
          });
        }
      });

      let resetCountIndexDate = 0;
      let previous_purchase_date = "";
      reportData.forEach(
        ({ name, new_purchase, rebils, reset_total, free_reset_total, purchase_date }, index) => {
          const rebilIndex = datasets.findIndex(({ label }) => label === `${name} rebill`);
          const newPurchaseIndex = datasets.findIndex(
            ({ label }) => label === `${name} new purchase`
          );

          /* Count resets and split by month */
          const resetIndex = datasets.findIndex(({ label }) => label === "Paid resets");
          if (previous_purchase_date !== purchase_date) {
            previous_purchase_date = purchase_date;
            index !== 0 && (resetCountIndexDate += 1);
          }
          if (reset_total) {
            datasets[resetIndex].data[resetCountIndexDate] += reset_total;
          }

          const freeResetIndex = datasets.findIndex(({ label }) => label === "Free reset");
          if (free_reset_total) {
            datasets[freeResetIndex].data[resetCountIndexDate] += free_reset_total;
          }

          datasets[rebilIndex].data.push(rebils);
          datasets[newPurchaseIndex].data.push(new_purchase);
          /* Get value numbers from plan name */
          const gauntletValueName = name.match(/\d/g);
          if (gauntletValueName) {
            datasets[rebilIndex].gauntletValueName = +gauntletValueName.join("");
            datasets[newPurchaseIndex].gauntletValueName = +gauntletValueName.join("");
          }
        }
      );

      setBarData({
        labels: Array.from(labels),
        /*
          makes sort for plans, depends on numbers in the plan name
          Gau 25k
          Gau 50k
          ...
          Gau 150k
          Resets
          The gauntlet
          Bootcamp
        */
        datasets: datasets.sort((a, b) => (a.gauntletValueName > b.gauntletValueName ? 1 : -1)),
      });

      setReport(reportData);
    } catch (error) {
      console.error("error getting report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Charges over time</h2>
      <div className={classes.datePickerContainer}>
        <SelectByDateWithCustomPeriod
          setIsLoading={setIsLoading}
          getReport={getReport}
          setReport={setReport}
        />
      </div>

      <ContainerWithLoader isLoading={isLoading} report={report}>
        <ChartBar barData={barData} height={130} externalOptions={externalOptions} />
      </ContainerWithLoader>
    </div>
  );
};

export default ChargesOverTime;

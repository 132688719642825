import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "revert",
    justifyContent: "space-between",
  },
  regularContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    width: "100%",
  },
  sum: {
    marginRight: 10,
  },
  filtersContainer: {
    width: "45%",
  },
  statisticsTitle: {
    marginTop: 50,
    marginBottom: 0,
  },
});

export default useStyles;

import * as Yup from "yup";

export const BUSINESS_EIN_DATA_VALIDATION_SCHEMA = Yup.object().shape({
  is_business_ein: Yup.boolean(),
  business_ein_name: Yup.string().when("is_business_ein", {
    is: true,
    then: Yup.string()
      // .matches(/^[0-9]+$/, "Business/EIN name must be only digits")
      .max(30, "Business/EIN name must be less than 30 characters")
      .required("Business/EIN name is required")
      .test(
        "is-not-empty-spaces",
        "Business/EIN name shouldn't start or end with empty spaces",
        (value = "") => {
          return !/^\s|\s$/.test(value);
        }
      )
      .typeError("Business/EIN name is required"),
  }),
});

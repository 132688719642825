import React from "react";
import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";

import { IntlFormatUSD } from "../../../../utils";
import { SVGCard, SVGCardNo, SVGClock } from "../../../../components/svgs";
import { ICharge } from "../../../../api/billing/index.types";
import { ReactComponent as StripeIc } from "../../../../assets/icons/stripe_s_ic.svg";
import { ReactComponent as PayPalIc } from "../../../../assets/icons/pay_pal_ic.svg";
import { ReactComponent as BitPayIC } from "../../../../assets/icons/bitbay.svg";
import bridger from "../../../../assets/icons/bridgerpay_logo.png";

import { CURRENT_TIME_ZONE_ABBREVIATION } from "../../../../utils/moment";
import { CopyToClipboard } from "../../../../components/ui";
import useStyles from "../../useStyles";
import { useDiscountsContext } from "../../../../context";

interface IProps {
  charge: ICharge;
  typeTransaction: "successful" | "failed";
}
const TIME_FORMAT = "MMMM Do, h:mm a";
const Charge = ({ charge, typeTransaction }: IProps): React.ReactElement => {
  const history = useHistory();
  const { setSearch: setDiscountCodesSearch, search: discountCodesSearch } = useDiscountsContext();

  const classes = useStyles();
  const classNameFeedBox = (t: string) => {
    const classes = ["feed-box"];
    t.toLowerCase().includes("reset") && classes.push("reset");
    t.toLowerCase().includes("fraud") && classes.push("fraud");
    return classes.join(" ");
  };
  const classNameFeedLogo = (t: string, payment_card_brand: string): React.ReactElement => {
    if (payment_card_brand === "bridgerpay") {
      return <img className={classes.bridgerLogo} src={bridger} alt="bridger_logo" />;
    }
    return t.startsWith("ch_") ? (
      <StripeIc />
    ) : payment_card_brand === "bitpay" ? (
      <BitPayIC width={20} />
    ) : (
      <PayPalIc />
    );
  };

  const stripeAmount = (amount: number) => IntlFormatUSD(amount / 100);

  const handleDiscountCodeClick = (code: string): void => {
    setDiscountCodesSearch({
      ...discountCodesSearch,
      code,
    });
    history.push("/discounts");
  };

  const SuccessfulChargeDate = () => (
    <>
      <div className="feed-box-date" data-test="successful-charge-date-container">
        <SVGClock />
        {charge.created && moment.unix(charge.created).format(TIME_FORMAT)}
        {charge.purchase_date &&
          moment(charge.purchase_date).tz("America/Chicago").format(TIME_FORMAT)}
        {" CT"}
      </div>
      <div className="feed-box-date">
        {charge.purchase_date && moment(charge.purchase_date).local().format(TIME_FORMAT)}{" "}
        {CURRENT_TIME_ZONE_ABBREVIATION}
      </div>
    </>
  );
  const FailedChargeData = () => (
    <>
      <div className="feed-box-date" data-test="failed-charge-date-container">
        <SVGClock />
        {moment(charge.purchase_date).tz("America/Chicago").format(TIME_FORMAT)} CT
      </div>
      <div className="feed-box-date">
        {moment(charge.purchase_date).format(TIME_FORMAT)} {CURRENT_TIME_ZONE_ABBREVIATION}
      </div>
    </>
  );
  const discount_code = charge.discount_code?.code;

  return (
    <div className={classNameFeedBox(charge.status)}>
      <div className="background" />
      <div className="feed-box-id">
        <div style={{ width: 15, display: "inline-block" }}>
          {classNameFeedLogo(charge.stripe_ch, charge.payment_card?.brand || "")}
        </div>
        <CopyToClipboard
          value={charge.stripe_ch || charge.id}
          buttonStyles={{ root: classes.copyToClipboardButton }}
        />
      </div>
      <div className="feed-box-flex">
        <div className="feed-box-left">
          <div className="feed-box-description">
            {charge.description || charge.reason || charge.plan?.name}
          </div>
          <div>
            {typeTransaction === "successful" ? <SuccessfulChargeDate /> : <FailedChargeData />}
          </div>
        </div>
        <div className="feed-box-right">
          <div className="feed-box-amount">
            {stripeAmount(charge.price_usd)}
            <div>
              {charge.status === "failed" ? <SVGCardNo /> : <SVGCard />}
              {`${charge.payment_method_details?.card.brand || charge.payment_card?.brand || ""} ${
                charge.payment_method_details?.card.last4 || charge.payment_card?.last4 || ""
              }`}
            </div>
          </div>
        </div>
      </div>
      <div className="feed-box-personal">
        <div style={{ textTransform: "capitalize" }}>
          {charge.account.country.name}
          {charge.billing_details?.address.country === ""
            ? " [?] "
            : charge.billing_details?.address.country}
        </div>
        <Link
          to={`/customer/${charge.account.id}`}
          className="customer"
          style={{ textTransform: "capitalize" }}
        >
          {charge.billing_details?.name?.toLowerCase() || charge.account.name?.toLowerCase()}
        </Link>
        <div className="customer">{charge.account.email}</div>
        {charge.free_reset && <div>Free reset</div>}
        {discount_code && (
          <div
            role="button"
            tabIndex={-1}
            className="customer"
            onClick={() => handleDiscountCodeClick(discount_code)}
          >
            {discount_code}
          </div>
        )}
        {charge.status === "failed" && <div className="failed">{charge.failure_message}</div>}
        {charge.object === "refund" && charge.status && (
          <div className="succeeded">{charge.status.toUpperCase()}</div>
        )}
        {charge.refunded && <div className="refunded">Refunded</div>}
        {charge.disputed && <div className="disputed">Disputed</div>}
      </div>
    </div>
  );
};

export default Charge;

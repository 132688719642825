import { makeStyles } from "@material-ui/core";
import { STATUS_COLORS_OPACITY, STATUSES_COLOR } from "./constants";

const COLOR_PRIMARY = "#1886cf";

const useGeneralStyles = makeStyles({
  containerBg: {
    zIndex: 3,
    background: "rgb(242, 242, 242)",
    boxShadow: `0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
      0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white,
      inset 0 -2px 0 rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: "20px 30px 30px",
    margin: "20px auto",
    display: "flex",
    flexFlow: "column",
    overflow: "hidden",
    position: "relative",
    fontSize: 15,
  },
  labelRoot: {
    backgroundColor: "#f2f2f2",
  },
  successfulBg: {
    backgroundColor: STATUSES_COLOR.successful,
  },
  refundBg: {
    backgroundColor: STATUSES_COLOR.refund,
  },
  failedBg: {
    backgroundColor: STATUSES_COLOR.failed,
  },
  chargebackBg: {
    backgroundColor: STATUSES_COLOR.chargeback,
  },
  fraudBg: {
    backgroundColor: STATUSES_COLOR.fraud,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    borderSpacing: 0,
    "& thead": {
      background: COLOR_PRIMARY,
      color: "white",
      fontWeight: "bold",
    },
    "& td": {
      padding: 5,
    },
    "& tbody td:hover": {
      cursor: "pointer",
    },
    "& tr:nth-child(even) td": {
      background: "rgba(128, 128, 128, 0.12)",
    },
    "& .hover": {
      background: "rgba(128, 128, 128, 0.4)",
      cursor: "pointer",
    },
  },
  tableHover: {
    "&:hover": {
      cursor: "pointer !important",
      background: "rgba(128, 128, 128, 0.4) !important",
    },
  },
  tableWithoutHover: {
    "& tbody td:hover": {
      cursor: "default",
    },
  },
  tableBorder: {
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 10,
    padding: 8,
  },
  scrollableContainer: {
    overflow: "scroll",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  verticalAlignWithSpace: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  flexBox: {
    display: "flex",
  },
  tableHorizontalScroll: {
    overflowX: "scroll",
    "& table": {
      width: "100%",
    },
  },
  forcedToLiveStatusBg: {
    "& td": {
      backgroundColor: `${STATUS_COLORS_OPACITY.purple} !important`,
    },
  },
  smTable: {
    fontSize: 16,
    "& td": {
      padding: "2px 6px",
    },
    "@media (max-width: 1439px)": {
      fontSize: 12,
    },
    "@media (max-width: 1599px)": {
      fontSize: 13,
    },
    "@media (max-width: 1899px)": {
      fontSize: 14,
    },
  },
  tableName: {
    color: "#0e0c0c",
    "& td": {
      padding: 5,
    },
  },
  noTextWrap: {
    textWrap: "nowrap",
  },
});

export default useGeneralStyles;

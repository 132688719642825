import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { APIService } from "../../api";
import { TAllowedFeaturesList } from "../../constants";
import { AGENT_POSITION_OPTIONS } from "../../pages/Agent/constants";

export interface IUser {
  id: number;
  name: string;
  email: string;
  allowed_features: TAllowedFeaturesList;
  position: (typeof AGENT_POSITION_OPTIONS)[number]["value"] | null;
  is_manager: boolean;
  is_agent: boolean;
}

export interface UserContextProps {
  user: IUser;
  setUser: (a: IUser) => void;
}

interface IProps {
  children: React.ReactNode;
}

const AdminDataContext = createContext<UserContextProps>({
  user: {
    id: -1,
    name: "",
    email: "",
    position: null,
    allowed_features: {},
    is_manager: false,
    is_agent: false,
  },
  setUser: () => ({}),
});

const AdminProviderData = ({ children }: IProps): React.ReactElement => {
  const { pathname } = useLocation();
  const [token] = useState(localStorage.getItem("Token") || "");
  const [user, setUser] = useState<IUser>({
    id: Number(localStorage.getItem("Id") || -1),
    name: localStorage.getItem("Name") || "",
    email: localStorage.getItem("Email") || "",
    allowed_features: {},
    position: null,
    is_manager: false,
    is_agent: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await APIService.getAdminDataRequest();
        setUser({
          ...user,
          position: data.position,
          is_manager: data.position === "manager",
          is_agent: data.position === "agent",
          allowed_features: data.allowed_features,
        });
      } catch (error) {
        console.error("error", error);
      }
    })();
  }, [pathname, token]);

  return (
    <AdminDataContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AdminDataContext.Provider>
  );
};

export default AdminProviderData;
export const useAdminProviderDataContext = (): UserContextProps => useContext(AdminDataContext);

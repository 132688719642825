import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  hover: {
    cursor: "pointer",
  },
  expandButton: {
    marginBottom: 5,
    display: "flex",
    justifyContent: "space-between",
  },
});
export default useStyles;

import httpClient from "../apiClient";

import {
  TCreateAgentRequest,
  TGetAgentsRequest,
  TGetAgentRequest,
  TUpdateAgentRequest,
  TUpdateAgentProfilePhotoRequest,
  TAddAgentNoteRequest,
  TUpdateAgentNoteRequest,
  TDeleteAgentNoteRequest,
  TGetAgentNotesRequest,
} from "./index.types";

export const AGENTS_API_URLS = {
  agents: "agents",
  agentsSignup: "agents/signup",
  agentsNotes: "agents/notes",
} as const;

export class AgentsAPI {
  static createAgentRequest: TCreateAgentRequest = (data) =>
    httpClient.post(AGENTS_API_URLS.agentsSignup, data);
  static getAgentsRequest: TGetAgentsRequest = (data, signal) =>
    httpClient.post(AGENTS_API_URLS.agents, data, { signal });
  static getAgentRequest: TGetAgentRequest = (id) => httpClient.post(`agents/${id}`);
  static updateAgentRequest: TUpdateAgentRequest = (data) =>
    httpClient.put(`${AGENTS_API_URLS.agents}/${data.id}`, data);
  static updateAgentProfilePhotoRequest: TUpdateAgentProfilePhotoRequest = (id, data) =>
    httpClient.put(`${AGENTS_API_URLS.agents}/${id}`, data);
  // NOTES
  static getAgentNotes: TGetAgentNotesRequest = (agent_id, page, limit) =>
    httpClient.get(`${AGENTS_API_URLS.agentsNotes}/${agent_id}?page=${page}&limit=${limit}`);
  static addAgentNote: TAddAgentNoteRequest = (agent_id: number, body) =>
    httpClient.post(`${AGENTS_API_URLS.agentsNotes}/${agent_id}`, body);
  static updateAgentNote: TUpdateAgentNoteRequest = (note_id: number, body) =>
    httpClient.patch(`${AGENTS_API_URLS.agentsNotes}/${note_id}`, body);
  static deleteAgentNote: TDeleteAgentNoteRequest = (agent_id: number, note_id: number) =>
    httpClient.delete(`${AGENTS_API_URLS.agentsNotes}/${note_id}/${agent_id}`);
}

import React from "react";
import { Chip, Tooltip } from "@material-ui/core";

interface ILegend {
  [key: string]: {
    color: string;
    name: string;
    textColor?: string;
    toolTipText?: string;
  };
}
interface IProps {
  legend: ILegend;
}
const StatusColorLegend: React.FC<IProps> = ({ legend }) => {
  return (
    <div>
      {Object.entries(legend).map(([key, value]) => {
        const { color, textColor = "#000", ...rest } = value;
        const style = {
          backgroundColor: color,
          color: textColor,
          marginLeft: 10,
          ...rest,
        };
        if (value.toolTipText) {
          return (
            <Tooltip placement="top" key={key} title={value.toolTipText}>
              <Chip key={key} style={style} size="medium" label={value.name} />
            </Tooltip>
          );
        }

        return <Chip key={key} style={style} size="medium" label={value.name} />;
      })}
    </div>
  );
};

export default StatusColorLegend;

import React from "react";

const TableHead = (): React.ReactElement => (
  <thead>
    <tr>
      <td>Payment Type</td>
      <td>Date</td>
      <td>Amount</td>
      <td>Product</td>
      <td>Name</td>
      <td>last 4 digits</td>
      <td>Failure Code</td>
      <td>Failure Reason</td>
      <td>Seller Message</td>
      <td>Discount code</td>
      <td>Payment Id</td>
      <td>recorded in DB</td>
    </tr>
  </thead>
);
export default TableHead;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  planName: {
    float: "right",
  },
  tableRow: {
    cursor: "pointer",
  },
  ip: {
    cursor: "help",
  },
  tableContainer: {
    width: "100%",
  },
});

export default useStyles;

import React, { useState } from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";

import PaymentsByStatus from "./components/PaymentsByStatus";
import CurrentDayBillingHistory from "./components/CurrentDayBillingHistory";
import AgentLogsHistoryTable from "./components/AgentLogsHistoryTable";
import { TabPanel, ChargesTable } from "../../components/ui";
import { useAdminProviderDataContext } from "../../context";

const Feed = (): React.ReactElement => {
  const {
    user: {
      allowed_features: { access_activity_feed_page, read_agents_log },
    },
  } = useAdminProviderDataContext();

  const [activeTabIndex, setActiveTab] = useState(access_activity_feed_page ? 0 : 3);
  const onChangeActiveTab = (event: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  return (
    <div>
      <div>
        <Paper square>
          <Tabs value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab disabled={!access_activity_feed_page} label="Cards" value={0} data-test="cards" />
            <Tab
              disabled={!access_activity_feed_page}
              label="Failed Charges"
              value={1}
              data-test="failed-charges"
            />
            <Tab
              disabled={!access_activity_feed_page}
              label="Completed Charges"
              value={2}
              data-test="completed-charges"
            />
            <Tab
              disabled={!read_agents_log}
              label="Agents logs"
              value={3}
              data-test="agents-logs"
            />
          </Tabs>
        </Paper>
      </div>

      <TabPanel value={activeTabIndex} index={0}>
        <CurrentDayBillingHistory />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <ChargesTable />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={2}>
        <PaymentsByStatus />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={3}>
        <AgentLogsHistoryTable />
      </TabPanel>
    </div>
  );
};

export default Feed;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  datePickerContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
  },
  exportButton: {
    marginLeft: 10,
    minWidth: 160,
  },
});

export default useStyles;

import React from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as BaseSelect,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

import useGeneralStyles from "../../../useGeneralStyles";

const useStyles = makeStyles({
  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

interface IOptions {
  text: string;
  name: string | number | boolean;
}
interface IProps {
  value: unknown;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void;
  options: IOptions[];
  inputLabel?: string;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: "small" | "medium";
  name?: string;
  baseSelectStyles?: {
    className: string;
    classes?: Partial<ClassNameMap>;
  };
}
const Select: React.FC<IProps> = ({
  value,
  onChange,
  options,
  inputLabel,
  label,
  disabled,
  fullWidth,
  size = "medium",
  name,
  baseSelectStyles,
}) => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      classes={{ root: classes.formControlRoot }}
      size={size}
    >
      <InputLabel
        classes={{
          root: generalClasses.labelRoot,
        }}
      >
        {inputLabel}
      </InputLabel>

      <BaseSelect
        name={name}
        disabled={disabled}
        fullWidth
        value={value}
        onChange={onChange}
        label={label}
        className={baseSelectStyles?.className}
        classes={baseSelectStyles?.classes}
      >
        {options.map(({ text, name, ...optionsProps }) => (
          // @ts-ignore
          <MenuItem value={name} key={name} {...optionsProps}>
            {text}
          </MenuItem>
        ))}
      </BaseSelect>
    </FormControl>
  );
};

export default Select;

export const PASSES_STATUS_OPTIONS = [
  {
    name: "on hold",
    text: "On Hold",
    id: 0,
  },
  {
    name: "active",
    text: "Active",
    id: 1,
  },
  {
    name: "failed",
    text: "Failed",
    id: 2,
  },
];

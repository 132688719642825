import React, { useState } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Grid } from "@material-ui/core";

import { ContainerWithLoader, PaginationWithLimit } from "../../components/ui";

import TableBody from "./components/TableBody";
import TableHeader from "./components/TableHeader";
import AddNewAffiliate from "./components/AddNewAffiliate";

import { APIAffiliates } from "../../api";
import usePagination from "../../hooks/usePagination";

import useStyles from "./useStyles";
import useGeneralStyles from "../../useGeneralStyles";
import AffiliatesFilter from "./components/AffilatesFilter";
import { AFFILIATES_API_URLS } from "../../api/affiliates";
import { AffiliatesActions } from "../../reducers/affiliates";

export interface ISearch {
  name: null | string;
  email: null | string;
  referral_id: null | string;
}

const Affiliates = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const { data, count, loading } = useSelector((state) => state.affiliates.accounts);
  const { page, setPage, limit, setLimit } = usePagination();
  const [search, setSearch] = useState<ISearch>({
    name: "",
    email: "",
    referral_id: "",
  });
  const [sortBy, setSortBy] = useState({
    by: "id",
    order: false,
  });

  useQuery({
    queryKey: [AFFILIATES_API_URLS.getAffiliates, page, limit, sortBy, Object.values(search)],
    queryFn: async () => {
      dispatch(AffiliatesActions.fetchAffiliateAccountsRequest());
      const { data } = await APIAffiliates.getAffiliatesRequest({
        page,
        limit,
        sortBy,
        contains: search,
      });
      dispatch(AffiliatesActions.fetchAffiliateAccountsSuccess(data));
      return data;
    },
    onError: (error) => {
      dispatch(AffiliatesActions.fetchAffiliateAccountsFailed());
      console.error("Error getting affiliate accounts request", error);
    },
  });

  return (
    <div className={generalClasses.containerBg}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <AffiliatesFilter search={search} setSearch={setSearch} />
        </Grid>
        <Grid item xs={3} className={classes.affiliateContainer}>
          <AddNewAffiliate />
        </Grid>
      </Grid>

      <div className={classes.tableWrapper}>
        <ContainerWithLoader isLoading={loading} report={data}>
          <table
            className={cx(
              generalClasses.table,
              generalClasses.tableWithoutHover,
              classes.tableContainer
            )}
          >
            <TableHeader sortBy={sortBy} setSortBy={setSortBy} />
            <TableBody affiliates={data} />
          </table>
        </ContainerWithLoader>
      </div>

      <div>
        <PaginationWithLimit
          count={count}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPageState={setPage}
        />
      </div>
    </div>
  );
};

export default Affiliates;

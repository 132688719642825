import React from "react";
import cx from "classnames";
import moment from "moment-timezone";
import { Delete } from "@material-ui/icons";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";

import { DialogConfirm } from "../../../../pages/Customer/components";
const useStyles = makeStyles({
  inactiveNote: {
    borderSpacing: 0,
    "& td": {
      background: "#ffff003b",
    },
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  activeContainer: {
    marginLeft: 0,
  },
  table: {
    borderSpacing: 0,
    marginTop: 7,
    width: "100%",
    "& tr": {
      wordBreak: "break-word",
    },
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0,0,0,.2)",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      margin: 0,
    },
  },
});

export interface INote {
  id: number;
  note: string;
  create_date: string;
  is_active: boolean;
  admin_account: {
    id: number;
    name: string;
  };
}

interface IProps {
  report: INote[];
  handleDeleteNote: (noteId: number) => Promise<void>;
  handleChangeStatus: (noteId: number, isActive: boolean) => Promise<void>;
  isAllowedChangeStatusAndRemoveActions: boolean;
}
const TableData: React.FC<IProps> = ({
  report,
  handleDeleteNote,
  handleChangeStatus,
  isAllowedChangeStatusAndRemoveActions,
}) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <tbody>
        {[...report]
          .sort((a, b) => b.id - a.id)
          .map((noteData) => (
            <React.Fragment key={noteData.id}>
              <tr
                className={cx({
                  [classes.inactiveNote]: !noteData.is_active,
                })}
              >
                <td className={classes.actionsContainer}>
                  <div className={classes.alignCenter}>
                    {isAllowedChangeStatusAndRemoveActions && (
                      <>
                        <DialogConfirm
                          isShowMainButton={false}
                          dialogTitle="Delete note"
                          contentText="Are you sure you want to delete note ?"
                          buttonText="delete"
                          onClickConfirm={() => handleDeleteNote(noteData.id)}
                          ButtonComponent={Delete}
                          externalStyles={{ color: "#e53935", cursor: "pointer" }}
                        />
                        <FormControlLabel
                          className={classes.activeContainer}
                          control={
                            <Checkbox
                              checked={noteData.is_active}
                              onChange={() => handleChangeStatus(noteData.id, !noteData.is_active)}
                              name="inactive"
                              color="primary"
                            />
                          }
                          label={noteData.is_active ? "Active" : "Inactive"}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <p data-test="created-date">
                      {moment(noteData.create_date)
                        .tz("America/Chicago")
                        .format("YYYY-MM-DD HH:mm")}
                    </p>
                    <p>
                      {noteData.admin_account
                        ? `${noteData.admin_account?.name}(${noteData.admin_account?.id})`
                        : "Internal"}
                    </p>
                  </div>
                </td>
              </tr>
              <tr
                className={cx({
                  [classes.inactiveNote]: !noteData.is_active,
                })}
              >
                <td className={cx({ [classes.borderBottom]: report.length > 1 })}>
                  {noteData.note}
                </td>
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default TableData;

import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";

import { COLUMNS } from "../../constants";
import { useSearchCustomersContext, useAdminProviderDataContext } from "../../../../context";
import ExportCsv from "../../ExportCsv";
import TableColumnsView from "../../../../components/ui/TableColumnsView";
import MainFilters from "../MainFilters";
import { DiscountsModal } from "../../../../components/ui";

const useStyles = makeStyles({
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  resetFiltersContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
  },
});
interface IProps {
  keyVal: number;
  setKey: (k: number) => void;
}

const ViewTableControlsAndActions: React.FC<IProps> = ({ keyVal, setKey }) => {
  const classes = useStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const {
    relatedTables,
    setRelatedTables,
    setSearch,
    setShowInactiveAccounts,
    setIsShowActiveSubscription,
    setIsShowLeads,
    setIsShowPassedEvaluations,
  } = useSearchCustomersContext();

  const [isOpenDiscountModal, setIsOpenDiscountModal] = useState(false);

  const handleShowExternalColumns = (value: string[]) => setRelatedTables(value);
  const handleOpenDiscountModal = () => setIsOpenDiscountModal(true);
  const handleClearFilters = () => {
    setSearch({ email: "" });
    setShowInactiveAccounts(true);
    setIsShowActiveSubscription(true);
    setIsShowLeads(true);
    setIsShowPassedEvaluations(true);
    setKey(keyVal + 1);
  };

  return (
    <>
      {allowed_features.batch_create_codes_for_customers && (
        <DiscountsModal setIsOpen={setIsOpenDiscountModal} isOpen={isOpenDiscountModal} />
      )}

      <div>
        <div className={classes.searchContainer}>
          <div>
            <TableColumnsView
              relatedTables={relatedTables}
              handleShowExternalColumns={handleShowExternalColumns}
              columns={COLUMNS}
            />

            <MainFilters />
          </div>

          <div>
            {allowed_features.batch_create_codes_for_customers && (
              <Button
                data-test="button-create-codes"
                variant="contained"
                color="primary"
                onClick={handleOpenDiscountModal}
              >
                Create discounts for current users
              </Button>
            )}
            {allowed_features.export_customers_csv && <ExportCsv data-test="button-export-csv" />}

            <div className={classes.resetFiltersContainer}>
              <Button variant="contained" color="primary" onClick={handleClearFilters}>
                Clear filters
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTableControlsAndActions;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  tableContainer: {
    overflowX: "scroll",
  },
  table: {
    width: "100%",
  },
  bottomControls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  totalRecords: {
    margin: "5px 0",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 15,
    marginBottom: 20,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    marginLeft: 20,
  },
  expiredSoonNotification: {
    color: theme.palette.warning.main,
  },
  expiredNotification: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  feedCardMainContainer: {
    "& .chargeCardContainer": {
      display: "flex",
    },
    "& .chargeCardContainer:nth-child(4n+1) ": {
      justifyContent: "start",
    },
    "& .chargeCardContainer:nth-child(2n+2) ": {
      justifyContent: "center",
    },
    "& .chargeCardContainer:nth-child(4n+3) ": {
      justifyContent: "end",
    },
  },
  toggleButtonGroupContainer: {
    display: "flex",
    justifyContent: "center",
  },
  toggleButton: {
    minWidth: 120,
  },
});

export default useStyles;

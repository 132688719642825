import React from "react";
import { Tooltip } from "@material-ui/core";
import { TooltipProps } from "@material-ui/core/Tooltip/Tooltip";

interface IProps extends TooltipProps {
  children: React.ReactElement;
  title: string;
  isDisableHoverListener?: boolean;
}

const ContextualHelp = ({
  children,
  title = "",
  placement = "top",
  isDisableHoverListener = false,
}: IProps): React.ReactElement => {
  if (!title) {
    return children;
  }

  return (
    <Tooltip
      disableHoverListener={isDisableHoverListener}
      placement={placement}
      title={title}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default ContextualHelp;

import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { CircularProgress, Chip } from "@material-ui/core";
import moment from "moment-timezone";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";

import { useDiscountsContext, useAdminProviderDataContext } from "../../../../context";
import CodeActivationActionStatus from "../ActivationActions/CodeActivationActionStatus";

import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../constants";
import SearchUserAutoComplete from "../SearchUserAutoComplete";
import ChangeDiscountCodeExpires from "../ChangeDiscountCodeExpires/ChangeDiscountCodeExpires";
import { IntlFormatUSD } from "../../../../utils";
import { DiscountCodeState } from "../../../../api/discounts/index.types";
import { StatusIcons, ContextualHelp } from "../../../../components/ui";

import useGeneralStyles from "../../../../useGeneralStyles";
import DiscountCodeTableData from "../DiscountCodeTableData";
import { calcPlanWithDiscount } from "./utils";
import DeactivateActivateActionsButtons from "../DeactivateActivateActionsButtons";

interface IProps {
  getDiscount: () => void;
  handleClickCreatedBy: (agentName: string | null) => void;
  handleClickDiscountCodeAction: (discountCode: DiscountCodeState, isEditing: boolean) => void;
}

const getExpirationDate = (expirationDate: string) => {
  const isUseDateExpiration = isNaN(parseInt(expirationDate as string));
  const isUseNumberDaysExpiration = !isUseDateExpiration;
  return isUseNumberDaysExpiration
    ? `${expirationDate} days`
    : moment(expirationDate).format(DATE_FORMAT);
};

const TableBody: React.FC<IProps> = ({
  getDiscount,
  handleClickCreatedBy,
  handleClickDiscountCodeAction,
}) => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const discounts = useSelector((state) => state.discountCodes?.data?.discount_codes);
  const { sortBy, columns } = useDiscountsContext();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  let rowNumber = 0;
  if (!sortBy.order) {
    rowNumber = discounts.length + 1;
  }

  return (
    <tbody>
      {discounts.map((discount) => {
        sortBy.order && (rowNumber += 1);
        !sortBy.order && (rowNumber -= 1);

        const planName = discount?.plan?.name || "All";
        const isResetPlan = discount.reset ? "Reset" : planName;

        const LifeTime = () =>
          discount.reset ? (
            <span className={classes.notApplicable}>n/a</span>
          ) : (
            <StatusIcons isActive={discount.one_time} />
          );

        const ActionButtonsWithLoader = () =>
          discount.updating ? (
            <td>
              <CircularProgress />
            </td>
          ) : (
            <DeactivateActivateActionsButtons discount={discount} getDiscount={getDiscount} />
          );

        return (
          discount.code.toLocaleLowerCase() && (
            <tr key={discount.id} className={classes.tableRow}>
              {sortBy.by !== "id" && <td />}
              <td>
                {rowNumber}
                <div className={classes.actionIconsContainer}>
                  {allowed_features.access_to_create_discount_code && (
                    <FileCopyIcon
                      color="action"
                      className={classes.actionIcon}
                      onClick={() => handleClickDiscountCodeAction(discount, false)}
                    />
                  )}
                  {allowed_features.edit_discount_codes && (
                    <EditIcon
                      color="action"
                      className={classes.actionIcon}
                      onClick={() => handleClickDiscountCodeAction(discount, true)}
                    />
                  )}
                </div>
              </td>

              {columns.includes("code") && <DiscountCodeTableData discount={discount} />}

              {columns.includes("description") && (
                <td>
                  {discount.description || <span className={classes.notApplicable}>n/a</span>}
                </td>
              )}

              {columns.includes("user") && (
                <SearchUserAutoComplete
                  defaultValue={discount?.account?.email}
                  codeId={discount.id}
                  getDiscount={getDiscount}
                />
              )}

              {columns.includes("discount") && (
                <td className={classes.alignRight}>
                  {discount.type === "flat" && "$"}
                  {Math.round(discount.discount)}
                  {discount.type === "percent" && "%"}
                </td>
              )}

              {/*discount_past_expiration*/}
              {columns.includes("discount_past_expiration") && (
                <td className={classes.alignRight}>
                  {discount.failover_type === "flat" && "$"}
                  {discount.failover_amt || ""}
                  {discount.failover_type === "percent" && "%"}
                </td>
              )}

              {columns.includes("type") && (
                <td className={classes.alignCenter}>{discount.type === "percent" ? "%" : "$"}</td>
              )}

              {columns.includes("price") && (
                <td>
                  {IntlFormatUSD(
                    Number(
                      discount.plan.name
                        ? discount.price
                        : calcPlanWithDiscount(discount.reset, discount.discount)
                    )
                  )}
                </td>
              )}

              {columns.includes("plan") && <td>{isResetPlan}</td>}

              {columns.includes("used") && (
                <td>
                  {discount.code_count ? <Chip label="Yes" color="primary" /> : <Chip label="No" />}
                </td>
              )}

              <td className={classes.alignCenter}>
                <ContextualHelp title="Used / Available">
                  <span>
                    {discount.code_count} / {discount.qty === null ? "∞" : `${discount.qty}`}
                  </span>
                </ContextualHelp>
              </td>

              {columns.includes("active") && <ActionButtonsWithLoader />}

              {columns.includes("expires") && <ChangeDiscountCodeExpires discount={discount} />}

              {columns.includes("expiration") && (
                <td>{discount.expiration && getExpirationDate(discount.expiration)}</td>
              )}

              {columns.includes("lifetime") && (
                <td className={classes.alignCenter}>
                  <LifeTime />
                </td>
              )}

              {columns.includes("accepted") && (
                <CodeActivationActionStatus
                  discount={discount}
                  isAvailableActions={allowed_features.accept_discount_codes}
                />
              )}

              {columns.includes("createdBy") && (
                <td
                  onClick={() => handleClickCreatedBy(discount.ac?.createdBy)}
                  className={cx(classes.alignCenter, generalClasses.tableHover)}
                >
                  {discount.ac?.createdBy || "n/a"}
                </td>
              )}
              {columns.includes("created") && (
                <td>
                  {moment(discount.created).isValid() &&
                    moment(discount.created).tz("America/Chicago").format(DATE_FORMAT)}
                </td>
              )}
            </tr>
          )
        );
      })}
    </tbody>
  );
};

export default TableBody;

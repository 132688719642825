import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  column: {
    cursor: "default",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#71b9e9 !important",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  restSortParams: {
    color: "#fff",
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#3a94db",
    },
  },
  name: {
    display: "flex",
  },
  columnName: {
    color: "#fff",
    marginRight: 5,
    whiteSpace: "nowrap",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default useStyles;

import React from "react";
import { TextField } from "@material-ui/core";
import { FormikHelpers } from "formik";

import { ICode } from "../types";
import { useAdminProviderDataContext } from "../../../../context";

interface IProps {
  code: ICode;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
}
const QuantityOptions = ({ code, setFieldValue }: IProps): React.ReactElement => {
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const isShowQuantityField = code.plan !== -2 || code.accounts_id;

  return (
    <>
      {isShowQuantityField && (
        <TextField
          disabled={!allowed_features.quantity_create_codes}
          select
          label="Quantity"
          id="d_new_quantity"
          value={code.qty}
          onChange={(e) => setFieldValue("qty", +e.target.value)}
          SelectProps={{
            native: true,
          }}
          data-test="quantity-value"
        >
          <option value="1">Single Use</option>
          {[2, 3, 4, 5, 6, 7, 8, 9].map((o) => (
            <option key={o} value={o}>
              {o} times
            </option>
          ))}
          <option value={-1}>Unlimited</option>
        </TextField>
      )}
    </>
  );
};

export default QuantityOptions;

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { PASSES_COLUMNS } from "../../constants";
import SearchByListValues from "../SearchByListValues";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";

const HeliosPassesSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();

  const dispatch = useDispatch();
  const {
    data: { results, updated },
    loading,
  } = useSelector((state) => state.complianceSearch.watchlistSubmissionToProp);

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { names } =
    tabsTablesSearchState.fundedAccountQuickSearchTab.heliosPassesSearchTable.search;

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    enabled: !!names,
    queryKey: [COMPLIANCE_API_URLS.getPasses, names, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(names, query, "names");

      dispatch(ComplianceSearchActions.fetchWatchlistPassesRequest());
      const response = await ComplianceAPI.getWatchlistPassesRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistPassesSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching passes", error);
      dispatch(ComplianceSearchActions.fetchWatchlistPassesFailed());
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [names, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Passes</b>
      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            fundedAccountQuickSearchTab: {
              ...tabsTablesSearchState.fundedAccountQuickSearchTab,
              heliosPassesSearchTable: {
                search: { names: values.names },
              },
            },
          });
        }}
        isLoading={loading}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={PASSES_COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.timestamp} />}
        csv_table_name="Funded account quick search - Passes"
      />
    </div>
  );
};

export default HeliosPassesSearch;

import React from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";

import TabPanel from "../../../../components/ui/TabPanel";
import TableReport from "../TableReport";
import { useEvaluationsContext } from "../../../../context";
import useStyles from "./useStyles";
import Filters from "../Filters";
import { E_EVALUATIONS_STEPS } from "../../constants";

const EvaluationJourneyTabs = (): React.ReactElement => {
  const classes = useStyles();
  const { activeTabIndex, onChangeActiveTab } = useEvaluationsContext();
  const {
    data: { loading, evaluations },
  } = useSelector((state) => state.evaluations);

  return (
    <div>
      <div>
        <Paper square className={classes.tabsContainer}>
          <Tabs value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab label="Close to Passing" data-test="close-to-passing" />
            <Tab label="Passed" data-test="passed" />
            <Tab label="Certificate Design" data-test="certificate-design" />
            <Tab label="Certificate Email" data-test="certificate-email" />
            <Tab label="Waiting for offer" data-test="waiting-for-offer" />
            {/*{allowed_features.access_waiting_for_offer && (*/}
            {/*  <Tab label="Welcomming process" data-test="welcomming-process" />*/}
            {/*)}*/}
          </Tabs>
          {!loading && (
            <div className={classes.usersCount}>
              <p>Users found: {evaluations.count || 0}</p>
            </div>
          )}
        </Paper>
      </div>

      <div className={classes.tableContainer}>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.CLOSE_TO_PASSING_TAB_INDEX}>
          <Filters />
          <TableReport />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.PASSED_TAB_INDEX}>
          <Filters />
          <TableReport />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.CERTIFICATE_DESIGN_TAB_INDEX}>
          <Filters />
          <TableReport />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.CERTIFICATE_EMAIL_TAB_INDEX}>
          <Filters />
          <TableReport />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.WAITING_FOR_OFFER_TAB_INDEX}>
          <Filters />
          <TableReport />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={E_EVALUATIONS_STEPS.WELCOMING_PROCESS}>
          <Filters />
          <TableReport />
        </TabPanel>
      </div>
    </div>
  );
};

export default EvaluationJourneyTabs;

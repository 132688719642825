import React, { useEffect, useState } from "react";
import cx from "classnames";
import moment from "moment";

import { getSumAllCharges } from "../../../../utils";
import { APICharges } from "../../../../api";
import {
  ICharge,
  ICustomerDbTransactions,
  IDbChargesByAPIIds,
  IGetCustomerChargesResponse,
} from "../../../../api/charges/index.types";

import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import usePagination from "../../../../hooks/usePagination";
import { ContainerWithLoader, PaginationWithLimit } from "../../../../components/ui";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import MainInfo from "./components/MainInfo";
import { HOURS_12_FORMAT_WITH_DATE } from "../../../../constants";

export type TCharge<T> = {
  [key: string]: T;
};

interface IPros {
  stripeCustomerId?: string;
  accountsId?: number;
  email?: string;
}
const ChargesTable = ({ stripeCustomerId, accountsId, email }: IPros): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const pagination = usePagination();

  const [isLoading, setIsLoading] = useState(false);
  const [dbTransactionsByAPIIds, setDbTransactionsByAPIIds] = useState<TCharge<IDbChargesByAPIIds>>(
    {}
  );
  const [dbTransactions, setDbTransactions] = useState<TCharge<ICustomerDbTransactions>>({});
  const [apiDataCharges, setApiDataCharges] = useState<TCharge<ICharge>>({});
  const [chargesToRender, setChargesToRender] = useState<string[]>([]);
  const [isFilterByEmail, setIsFilterByEmail] = useState(true);

  const [responseData, setResponseData] = useState<IGetCustomerChargesResponse["data"]>({
    stripeChargesByFingerPrint: [],
    payPalCharges: [],
    bitPayCharges: [],
    bridgerPayCharges: [],
    dbChargesByAPIIds: [],
    dbTransactions: [],
  });
  const handleChangePage = (page: number) => pagination.setPage(page);

  useEffect(() => {
    (async () => {
      if (accountsId && email) {
        try {
          setIsLoading(true);
          const requestData = {
            accounts_id: +accountsId,
            email,
          };
          const response = await APICharges.getCustomerChargesRequest(requestData);

          const dbTransactions: TCharge<ICustomerDbTransactions> = {};
          response.data.dbTransactions.forEach((charge) => {
            dbTransactions[charge.stripe_ch] = charge;
          });
          setDbTransactions(dbTransactions);

          const dbTransactionsByAPIIds: TCharge<IDbChargesByAPIIds> = {};
          response.data.dbChargesByAPIIds.forEach((charge) => {
            dbTransactionsByAPIIds[charge.stripe_ch] = charge;
          });

          response.data.bridgerPayCharges.forEach((charge) => {
            dbTransactionsByAPIIds[charge.id] = {
              stripe_ch: charge.id,
            };
          });

          setDbTransactionsByAPIIds(dbTransactionsByAPIIds);
          setResponseData(response.data);
        } catch (error) {
          console.error("Error fetching getCustomerChargesRequest", error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [stripeCustomerId, accountsId]);

  useEffect(() => {
    const apiChargesData: TCharge<ICharge> = {};
    [
      ...responseData.stripeChargesByFingerPrint,
      ...responseData.payPalCharges,
      ...responseData.bitPayCharges,
      ...(isFilterByEmail
        ? responseData.bridgerPayCharges
        : responseData.bridgerPayCharges.filter((c) => {
            const { date } = c;
            const record = responseData.stripeChargesByFingerPrint.find((sc) => {
              /*
               * Records in monitoring DB can be the same as in stripe api data
               * In this case we need to filter out them from table when filter by email is off
               * We don't have unique identifier between bridgerpay charges and stripe charges
               * */
              if (
                moment(sc.date * 1000).format(HOURS_12_FORMAT_WITH_DATE) ===
                moment(date * 1000).format(HOURS_12_FORMAT_WITH_DATE)
              ) {
                return true;
              }
              return false;
            });
            return !record;
          })),
    ]
      .sort((p, n) => n.date - p.date)
      .forEach((charge) => {
        apiChargesData[charge.id] = charge;
      });
    setApiDataCharges(apiChargesData);
  }, [responseData, isFilterByEmail]);

  const chargesIds = Object.keys(apiDataCharges);
  const chargesByEmail = chargesIds.filter((charge) => dbTransactions[charge]);
  const chargesSum = getSumAllCharges(
    isFilterByEmail
      ? Object.values(apiDataCharges).filter(({ id }) => dbTransactions[id])
      : Object.values(apiDataCharges)
  );

  useEffect(() => {
    const { page, limit } = pagination;
    pagination.setCount(isFilterByEmail ? chargesByEmail.length : chargesIds.length);
    const paginatedCharges = (isFilterByEmail ? chargesByEmail : chargesIds).slice(
      page * +limit,
      page * +limit + +limit
    );

    setChargesToRender(paginatedCharges);
  }, [pagination.page, apiDataCharges, pagination.limit, isFilterByEmail]);

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <div className={classes.tittleContainer}>
        <b>Charges</b>
      </div>

      <div className={cx(generalClasses.containerBg, classes.scrollableContainer)}>
        <div className={classes.filterContainer}>
          {!isLoading && (
            <MainInfo
              chargesByEmail={chargesByEmail}
              chargesSum={chargesSum}
              chargesIds={chargesIds}
              isFilterByEmail={isFilterByEmail}
              setIsFilterByEmail={setIsFilterByEmail}
            />
          )}
        </div>
        <ContainerWithLoader isLoading={isLoading} report={chargesIds}>
          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <TableHead />
            <TableBody
              apiDataCharges={apiDataCharges}
              chargesToRender={chargesToRender}
              dbTransactionsByAPIIds={dbTransactionsByAPIIds}
              dbTransactions={dbTransactions}
            />
          </table>
        </ContainerWithLoader>

        <div>
          <PaginationWithLimit
            count={pagination.count}
            limit={pagination.limit}
            setLimit={pagination.setLimit}
            page={pagination.page}
            setPageState={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ChargesTable;

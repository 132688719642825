import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button, Grid } from "@material-ui/core";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import useStyles from "./useStyles";
import useComponentsStyles from "../useStyles";
import { APIFunds } from "../../../../api";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { DATE_FORMAT } from "../../../../constants";
import { FormDatePicker } from "../../../../components/ui";
import { Accordion } from "../../../../components/ui";

export interface FundedAccountDocumentDates {
  personal_id: string | null;
  address_proof: string | null;
  w8or9: string | null;
}

const DOCUMENT_DATES_FORM_SCHEMA = Yup.object().shape({
  personal_id: Yup.date().typeError("Invalid date").nullable(),
  address_proof: Yup.date().typeError("Invalid date").nullable(),
  w8or9: Yup.date().typeError("Invalid date").nullable(),
});

const DocumentDatesForm = (): React.ReactElement => {
  const componentsClasses = useComponentsStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, accounts_id, documentsDates } = useSelector((state) => state.fundedTrader);

  const { isLoading } = documentsDates;
  const handleSubmit = async (document_dates: FundedAccountDocumentDates) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesRequest());
      const response = await APIFunds.setFundedAccountDocumentDatesRequest(id, {
        ...document_dates,
        accounts_id,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesSuccess(response));
    } catch (error) {
      console.error("Error handle saving document dates form request", error);
      dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesFailed());
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesRequest());
          const response = await APIFunds.getFundedAccountDocumentDatesRequest(id);
          dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesSuccess(response));
        }
      } catch (error) {
        console.error("Error fetching funded account document dates");
        dispatch(FundedTraderActions.fetchFundedTraderDocumentsDatesFailed());
      }
    })();
  }, [id]);

  const [documentsExpiringInfo, setDocumentsExpiringInfo] = useState<FundedAccountDocumentDates>({
    personal_id: null,
    address_proof: null,
    w8or9: null,
  });

  useEffect(() => {
    const data: FundedAccountDocumentDates = {
      personal_id: null,
      address_proof: null,
      w8or9: null,
    };
    for (const [key, value] of Object.entries(documentsDates.data)) {
      if (value) {
        if (moment(value).isBefore(moment().subtract(-14, "days"))) {
          data[key as keyof FundedAccountDocumentDates] = "expiring";
        }
        if (moment(value).isBefore(moment().subtract(0, "days"))) {
          data[key as keyof FundedAccountDocumentDates] = "expired";
        }
      }
    }
    setDocumentsExpiringInfo(data);
  }, [
    documentsDates.data.address_proof,
    documentsDates.data.personal_id,
    documentsDates.data.w8or9,
  ]);

  const countExpiredDocuments = Object.values(documentsExpiringInfo).filter(
    (v) => v === "expired"
  ).length;
  const countExpiringDocuments = Object.values(documentsExpiringInfo).filter(
    (v) => v === "expiring"
  ).length;
  const isHasExpiredDocuments = !!countExpiredDocuments && !isLoading;
  const isHasExpiringDocuments = !!countExpiringDocuments && !isLoading;

  return (
    <Accordion
      accordionSummary={
        <div className={componentsClasses.title}>
          <b>
            Document dates{" "}
            {isHasExpiredDocuments && (
              <span className={classes.expiredNotification}>
                Documents expired {countExpiredDocuments}.
              </span>
            )}
            {isHasExpiringDocuments && (
              <span className={classes.expiredSoonNotification}>
                {" "}
                Expiring documents {countExpiringDocuments}.
              </span>
            )}
          </b>
          {isLoading && <CircularProgress size={18} className={classes.loader} />}
        </div>
      }
      accordionDetails={
        <>
          {!isLoading && (
            <Formik
              initialValues={documentsDates.data}
              validationSchema={DOCUMENT_DATES_FORM_SCHEMA}
              validateOnChange
              enableReinitialize
              onSubmit={handleSubmit}
              initialErrors={{
                personal_id: documentsExpiringInfo.personal_id === "expired" ? "Expired" : "",
                address_proof: documentsExpiringInfo.address_proof === "expired" ? "Expired" : "",
                w8or9: documentsExpiringInfo.w8or9 === "expired" ? "Expired" : "",
              }}
            >
              {({ isValid, dirty, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={2}>
                        <Field
                          name="personal_id"
                          label="Personal ID"
                          placeholder="Personal ID"
                          component={FormDatePicker}
                          onChange={(data: MaterialUiPickersDate) => {
                            if (data) {
                              setFieldValue("personal_id", data.format(DATE_FORMAT));
                            }
                          }}
                          waringMessage={
                            documentsExpiringInfo.personal_id === "expiring" ? "Expiring" : ""
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={2}>
                        <Field
                          name="address_proof"
                          label="Address proof"
                          placeholder="Address proof"
                          component={FormDatePicker}
                          onChange={(data: MaterialUiPickersDate) => {
                            if (data) {
                              setFieldValue("address_proof", data.format(DATE_FORMAT));
                            }
                          }}
                          waringMessage={
                            documentsExpiringInfo.address_proof === "expiring" ? "Expiring" : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3} xs={2}>
                        <Field
                          label="W8/9"
                          name="w8or9"
                          placeholder="W8/9"
                          component={FormDatePicker}
                          onChange={(data: MaterialUiPickersDate) => {
                            if (data) {
                              setFieldValue("w8or9", data.format(DATE_FORMAT));
                            }
                          }}
                          waringMessage={
                            documentsExpiringInfo.w8or9 === "expiring" ? "Expiring" : ""
                          }
                        />
                      </Grid>
                      <Grid item md={2} xs={2} className={classes.flexBox}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!(isValid && dirty)}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </>
      }
    />
  );
};

export default DocumentDatesForm;

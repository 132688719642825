import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { Field, Form, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ACCOUNTS_SCHEMA,
  getAccountsInitialValues,
  getTradedAccountsOptions,
  MAXIMUM_FUNDED_ACCOUNTS_COUNT,
} from "../../utils";
import CreateAccountConfirmDialog from "../CreateAccountConfirmDialog";
import { FormInput, FormSelect } from "../../../../components/ui";
import {
  EProcessingStatuses,
  INITIAL_ACCOUNT_SIZE_OPTIONS,
  INVOICE_LIVE_SIM_STATUSES_OPTIONS,
  INVOICE_LIVE_STATUSES_OPTIONS,
  PROCESSING_STATUSES_OPTIONS,
} from "../../../../constants";
import { getAccountTypeAndPropFirm } from "../TradedAccounts/utils";
import { INewTradedAccountForm } from "../TradedAccounts";
import { useCustomerProvider } from "../../../../context";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { APIFunds } from "../../../../api";
import useStyles from "./useStyles";
import { EVALUATIONS_API_URLS, EvaluationsAPI } from "../../../../api/evaluations";

const FormikContext = () => {
  const { values, setFieldValue, validateField } = useFormikContext<INewTradedAccountForm>();

  useEffect(() => {
    if (values.e2t_account_id) {
      const { propFirm, accountType, account_drawdown } = getAccountTypeAndPropFirm(
        values.e2t_account_id
      );
      setFieldValue("prop_firm", propFirm);
      setFieldValue("account_type", accountType);
      setFieldValue("account_drawdown", account_drawdown);

      validateField("prop_firm");
      validateField("account_type");
    }
  }, [values.e2t_account_id]);
  return null;
};

const CreateTradedAccountForm = (): React.ReactElement => {
  const queryClient = useQueryClient();

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    tradedAccounts: { data: tradedAccountsData },
    evaluationCompletedActiveOrRecentAccounts,
    id,
  } = useSelector((state) => state.fundedTrader);
  const { customer } = useCustomerProvider();
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);

  const { fundedAccounts } = useSelector((state) => state.fundedTrader);

  const isHaveMaximumFundedAccounts =
    fundedAccounts.filter(
      ({ processing_status }) => processing_status !== EProcessingStatuses.completed
    ).length >= MAXIMUM_FUNDED_ACCOUNTS_COUNT;
  const tradedAccountsOptions = getTradedAccountsOptions(tradedAccountsData);

  const handleSubmit = async (
    traded_account: INewTradedAccountForm,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      setIsOpenDialogConfirm(false);
      dispatch(FundedTraderActions.fetchFundedTraderAccountsRequest());
      const response = await APIFunds.setTradedAccountsRequest(customer.id, traded_account);
      dispatch(FundedTraderActions.fetchFundedTraderAccountsSuccess(response));

      queryClient.invalidateQueries({
        queryKey: [EVALUATIONS_API_URLS.customerEvaluationCompleteHistory],
      });
    } catch (error) {
      console.error("Error creating traded accounts", error);
    } finally {
      resetForm();
    }
  };

  useQuery({
    enabled: !!customer.id,
    queryKey: [
      EVALUATIONS_API_URLS.customerEvaluationCompleteHistory,
      "get-active-passed-accounts",
      customer.id,
    ],
    queryFn: async () => {
      dispatch(FundedTraderActions.fetchFundedTraderPassesActiveAndRecentAccountsRequest());

      const query = new URLSearchParams();
      query.set("funded_trader_id", id.toString());
      query.set("status", "recentOrActive");
      const response = await EvaluationsAPI.getCustomerEvaluationCompleteHistoryRequest(
        query.toString()
      );

      dispatch(
        FundedTraderActions.fetchFundedTraderPassesActiveAndRecentAccountsSuccess(response.data)
      );
      return response.data;
    },
    onError: (error) => {
      console.error("Error fetching funded trader data", error);
      dispatch(FundedTraderActions.fetchFundedTraderPassesActiveAndRecentAccountsFailed());
    },
  });

  const activePassedAccountsData =
    evaluationCompletedActiveOrRecentAccounts.data.map((r) => ({
      value: r.id,
      label: r.e2t_account_id,
      id: r.id,
    })) || [];

  return (
    <Formik
      initialValues={getAccountsInitialValues(customer.id)}
      onSubmit={handleSubmit}
      validationSchema={ACCOUNTS_SCHEMA}
      enableReinitialize
    >
      {({ isValid, setFieldValue, submitForm, dirty, values }) => (
        <Form>
          <CreateAccountConfirmDialog
            isOpenDialogConfirm={isOpenDialogConfirm}
            setIsOpenDialogConfirm={setIsOpenDialogConfirm}
            submitForm={submitForm}
          />

          <Grid container spacing={4}>
            <Grid item md={2} xs={2}>
              <Field
                name="e2t_account_id"
                label="Account ID"
                placeholder="Account ID"
                component={FormInput}
                disabled={isHaveMaximumFundedAccounts}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="prop_firm"
                label="Prop firm"
                placeholder="Prop firm"
                component={FormInput}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="account_type"
                label="Account type"
                placeholder="Account type"
                component={FormInput}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="account_drawdown"
                label="Drawdown"
                placeholder="Drawdown"
                component={FormInput}
                disabled
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="account_size"
                label="Account Size"
                placeholder="Account Size"
                options={INITIAL_ACCOUNT_SIZE_OPTIONS}
                component={FormSelect}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="processing_status"
                label="Processing status"
                placeholder="Processing status"
                options={PROCESSING_STATUSES_OPTIONS}
                component={FormSelect}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("processing_status", e.target.value);
                }}
                disabled={isHaveMaximumFundedAccounts}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={2} xs={2}>
              <Field
                name="invoice_status"
                label="Invoice status"
                placeholder="Invoice status"
                component={FormSelect}
                options={
                  values.account_type === "LiveSim"
                    ? INVOICE_LIVE_SIM_STATUSES_OPTIONS
                    : INVOICE_LIVE_STATUSES_OPTIONS
                }
                disabled={isHaveMaximumFundedAccounts}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="initial_tcp_account_id"
                label="Initial TCP account"
                placeholder="Initial TCP"
                options={tradedAccountsOptions}
                component={FormSelect}
                disabled={isHaveMaximumFundedAccounts}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <Field
                name="evaluation_completed_history_id"
                label="E2T account name"
                placeholder="E2T account name"
                options={[
                  {
                    value: 0,
                    label: "No",
                    id: 0,
                  },
                  ...activePassedAccountsData,
                ]}
                component={FormSelect}
                disabled={isHaveMaximumFundedAccounts}
              />
            </Grid>
            {dirty && (
              <Grid item md={2} xs={2} className={classes.flexBox}>
                <Button
                  onClick={() => setIsOpenDialogConfirm(true)}
                  variant="contained"
                  color="primary"
                  disabled={!(isValid && dirty)}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
          <FormikContext />
        </Form>
      )}
    </Formik>
  );
};

export default CreateTradedAccountForm;

import React, { useEffect, useState } from "react";

import Select from "../Select";
import { useAdminProviderDataContext } from "../../../context";
import { IPresetsOptions } from "../../../api/service/index.types";

interface IProps {
  onChange: (value: string) => void;
  value?: string;
  options: IPresetsOptions[];
}
const AgentPresetsSelect: React.FC<IProps> = ({ onChange, value = "", options }) => {
  const { user } = useAdminProviderDataContext();
  const [selectedPreset, setSelectedPreset] = useState(value);
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSelectedPreset(event.target.value as string);
    onChange(event.target.value as string);
  };

  useEffect(() => {
    setSelectedPreset(value);
  }, [value]);

  return (
    <Select
      name="preset"
      inputLabel="Agent preset"
      label="Agent preset"
      options={options.map(({ name, name_preset }) => ({
        text: name_preset,
        name,
      }))}
      onChange={handleChange}
      value={selectedPreset}
      disabled={!user.allowed_features.change_agent_allowed_features}
    />
  );
};

export default AgentPresetsSelect;

import produce from "immer";
import {
  ForcedToLiveAccountsActionType,
  IForcedToLiveAccountsState,
  TForcedToLiveAccountsAction,
} from "./index.types";

const initialState: IForcedToLiveAccountsState = {
  accounts: {
    data: {
      count: 0,
      rows: [],
      accountsOver8KWithdrawals: [],
      forcedToLive: [],
    },
    isLoading: false,
  },
};

const forcedToLiveAccountsReducer = (
  prevState = initialState,
  action: TForcedToLiveAccountsAction
): IForcedToLiveAccountsState =>
  produce(prevState, (draft: IForcedToLiveAccountsState) => {
    switch (action.type) {
      case ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchRequest:
        draft.accounts.isLoading = true;
        break;
      case ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchSuccess:
        draft.accounts.isLoading = false;
        draft.accounts.data = action.payload;
        break;
      case ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchFailed:
        draft.accounts.isLoading = false;
        break;
      default:
        break;
    }

    return draft;
  });

export default forcedToLiveAccountsReducer;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { useRouteMatch } from "react-router-dom";

import { APIAccounts } from "../../../api";
import { FormCheckbox } from "../index";
import useStyles from "./useStyles";
import { IBannedAccountData } from "../../../api/accounts/index.types";
import { ACCOUNTS_API_URLS } from "../../../api/accounts";
import { AGENT_ACTIVITY_LOG_API_URLS } from "../../../api/agentActivityLog";
import { PAYMENT_CARDS_API_URLS } from "../../../api/paymentCards";
import { useCustomerProvider, useIntercomContext } from "../../../context";
import { FundsAPI } from "../../../api/funds";

const BanAccount = (): React.ReactElement => {
  const { id } = useParams();
  const matchFundedTraderPage = useRouteMatch("/fundedTraders/:id");
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { customer, fina } = useCustomerProvider();

  const [open, setOpen] = useState(false);
  const { plans, managementData, payment_cards, mainData } = useSelector((state) => state.customer);
  const { account_gauntlets, account_simulators } = managementData.data;
  const { banned_account, funded_trader } = mainData.data;

  const isBannedFundedAccount = !!funded_trader?.ban_date;

  const [isUpdating, setIsUpdating] = useState(false);
  const { getIntercomContact } = useIntercomContext();
  const [results, setResults] = useState({
    is_block_access_to_account: false,
    is_cancel_active_subscription: false,
    is_clear_last_account_configuration: false,
    is_remove_all_payments_methods: false,
  });
  const [isShowResults, setIsShowResults] = useState(false);
  const handleCloseResults = () => setIsShowResults(false);

  const handleClickOpen = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const handleConfirm = async (values: IBannedAccountData) => {
    setResults(values);
    setIsUpdating(true);
    try {
      if (matchFundedTraderPage) {
        if (isBannedFundedAccount) {
          await FundsAPI.unBunAccountRequest(id);
          return;
        }
        await FundsAPI.bunAccountRequest(id);
        return;
      }

      if (banned_account) {
        await APIAccounts.unBunAccountRequest(id, {
          xid: customer.xid,
        });
        return;
      }
      await APIAccounts.bunAccountRequest(id, values);
      setIsShowResults(true);
    } catch (error) {
      console.log("Error handleConfirm", error);
    } finally {
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans, id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountsExtend(id)],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.getAccountManagementData, id],
      });

      queryClient.invalidateQueries({
        queryKey: [AGENT_ACTIVITY_LOG_API_URLS.getCustomerLogs, id],
      });

      queryClient.invalidateQueries({
        queryKey: [PAYMENT_CARDS_API_URLS.getCustomerPaymentCards, id],
      });

      if (fina.id) {
        getIntercomContact(fina.id);
      }
      setIsUpdating(false);
      setOpen(false);
    }
  };

  const isActiveGauAccount =
    account_gauntlets[0]?.active && account_gauntlets[0]?.status === "active";
  const isActiveSimAccount =
    account_simulators[0]?.active && account_simulators[0]?.status === "active";

  const latestPlan = plans?.data.activePlan;
  const latestPlanStatus = latestPlan?.status;

  return (
    <div className={classes.container}>
      <Button
        className={classes.banButton}
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        {(!matchFundedTraderPage && banned_account) ||
        (matchFundedTraderPage && isBannedFundedAccount)
          ? "Unban account"
          : "Ban account"}
      </Button>

      <Dialog open={isShowResults} onClose={handleCloseResults}>
        <DialogTitle>Summary</DialogTitle>
        <DialogContent>
          {results.is_block_access_to_account && (
            <DialogContentText>Account access blocked</DialogContentText>
          )}
          {results.is_cancel_active_subscription && (
            <DialogContentText>Canceled active subscription</DialogContentText>
          )}
          {results.is_clear_last_account_configuration && (
            <DialogContentText>Cleared the last account configuration</DialogContentText>
          )}
          {results.is_remove_all_payments_methods && (
            <DialogContentText>Payment methods removed</DialogContentText>
          )}
          {matchFundedTraderPage && <DialogContentText>Account was banned</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            autoFocus
            variant="contained"
            onClick={handleCloseResults}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleCancel}>
        <div>
          <Formik
            onSubmit={handleConfirm}
            enableReinitialize
            validateOnChange
            initialValues={{
              is_block_access_to_account: false,
              is_cancel_active_subscription: matchFundedTraderPage
                ? false
                : latestPlanStatus === "active",
              is_clear_last_account_configuration: false,
              is_remove_all_payments_methods: false,
              xid: customer.xid,
            }}
          >
            {({ values, setFieldValue, dirty }) => {
              const isSelectedOption = Object.values(values).some((v) => v === true);
              return (
                <Form>
                  <DialogTitle id="alert-dialog-title">
                    {banned_account ? "Unban customer account" : "Ban customer account"}
                  </DialogTitle>

                  <DialogContent dividers>
                    <DialogContentText>
                      {banned_account || isBannedFundedAccount
                        ? "Are you sure you want to unban account ?"
                        : "Are you sure you want to ban account ?"}
                    </DialogContentText>

                    {!banned_account && !matchFundedTraderPage && (
                      <div className={classes.formContainer}>
                        <Field
                          label="Block access to the account"
                          name="is_block_access_to_account"
                          component={FormCheckbox}
                        />
                        <Field
                          disabled={
                            !latestPlan || !plans?.data.plans.length || plans.data.passedPlan
                          }
                          label="Cancel active subscription"
                          name="is_cancel_active_subscription"
                          component={FormCheckbox}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("is_cancel_active_subscription", e.target.checked);
                            if (!e.target.checked) {
                              setFieldValue(
                                "is_clear_last_account_configuration",
                                e.target.checked
                              );
                            }
                          }}
                        />
                        <Field
                          disabled={
                            !(
                              values.is_cancel_active_subscription &&
                              isActiveGauAccount &&
                              isActiveSimAccount
                            )
                          }
                          label="Clear the last account configuration request"
                          name="is_clear_last_account_configuration"
                          component={FormCheckbox}
                        />
                        <Field
                          disabled={!payment_cards.data.payment_cards.count}
                          label="Remove all payment methods"
                          name="is_remove_all_payments_methods"
                          component={FormCheckbox}
                        />
                      </div>
                    )}
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={handleCancel}
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled={isUpdating}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      {...{
                        ...(matchFundedTraderPage && {
                          disabled: isUpdating,
                        }),
                        ...(!matchFundedTraderPage && {
                          disabled: (!banned_account && !isSelectedOption) || isUpdating,
                        }),
                      }}
                      color="primary"
                      autoFocus
                      variant="contained"
                      data-test="button-submit"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default BanAccount;

import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";
import { Grid, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import newSortBy from "../../../../utils/newSortBy";
import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import { ISortBy } from "../../../../types";
import { APIAgents } from "../../../../api";
import usePagination from "../../../../hooks/usePagination";
import useGeneralStyles from "../../../../useGeneralStyles";
import { AgentsActions } from "../../../../reducers/agentsReducer";
import { useAuthContext } from "../../../../context";
import { AGENTS_API_URLS } from "../../../../api/agents";
import FilterByAllowedFunctions from "./components/FilterByAllowedFunctions";
import { ContainerWithLoader, PaginationWithLimit } from "../../../../components/ui";

export type TBy = "name" | "email" | "preset" | "active";

const AgentsList = (): React.ReactElement => {
  const dispatch = useDispatch();
  const generalClasses = useGeneralStyles();
  const { hasExpired } = useAuthContext();
  const { page, setPage, limit, setLimit } = usePagination();
  const { data, loading } = useSelector((state) => state.agents);
  const policesState = useSelector((state) => state.polices);

  const [sortBy, setSortBy] = useState<ISortBy<TBy>>({
    by: "active",
    order: false,
  });
  const [search, setSearch] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useQuery({
    queryKey: [AGENTS_API_URLS.agents, page, search, limit, sortBy, selectedOptions],
    queryFn: async ({ signal }) => {
      const dataRequest = {
        limit,
        page,
        contains: { nameOrEmail: search, prop_firm: selectedOptions },
        sortBy,
      };

      dispatch(AgentsActions.fetchAgentsRequest());
      const response = await APIAgents.getAgentsRequest(dataRequest, signal);
      dispatch(AgentsActions.fetchAgentsSuccess(response));
      return response;
    },
    onError: (error) => {
      dispatch(AgentsActions.fetchAgentsFailed());
      console.error("error fetching agents list", error);
      hasExpired();
    },
  });

  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  useEffect(() => {
    setPage(0);
  }, [search, selectedOptions]);

  return (
    <div className="customers">
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Name or email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={10}>
          <FilterByAllowedFunctions
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <ContainerWithLoader isLoading={loading || policesState.loading} report={data.agents}>
            <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
              <TableHead sortBy={sortBy} handleSortBy={handleSortBy} />
              <TableBody />
            </table>
          </ContainerWithLoader>
        </Grid>

        <Grid item xs={3}>
          <div>
            <PaginationWithLimit
              count={data.count}
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPageState={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AgentsList;

import httpClient from "../apiClient";

import {
  TGetActivityLogsRequest,
  TGetCustomerLogsRequest,
  TResetAccountLogRequest,
  TExtendAccountLogRequest,
  TCreateAccountLoginRequest,
} from "./index.types";

export const AGENT_ACTIVITY_LOG_API_URLS = {
  getCustomerLogs: "customer_activity_log",
} as const;
export class AgentActivityLogAPI {
  static getActivityLogsRequest: TGetActivityLogsRequest = (body) =>
    httpClient.post("agent_activity_log", body);
  static getCustomerLogsRequest: TGetCustomerLogsRequest = (body) =>
    httpClient.post(AGENT_ACTIVITY_LOG_API_URLS.getCustomerLogs, body);
  static resetAccountLogRequest: TResetAccountLogRequest = (body) =>
    httpClient.post("agent_activity_log/mgm/reset_user", body);
  static extendAccountLogRequest: TExtendAccountLogRequest = (body) =>
    httpClient.post("/agent_activity_log/finamark/extend_finamark", body);
  static createAccountLoginRequest: TCreateAccountLoginRequest = (body) =>
    httpClient.post("/agent_activity_log/finamark/create_finamark", body);
}

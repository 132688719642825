import React from "react";

import SubscriptionStatusTable from "./SubscriptionStatusTable";
import AllPurchases from "./AllPurchases";
import ResetsRebillsNewPurchases from "./ResetsRebillsNewPurchases";
import BasicAccountDetailsAndSalesValue from "./BasicAccountDetailsAndSalesValue";
import SearchAccountsPlans from "../SearchAccountsPlans";
import { useAdminProviderDataContext } from "../../../../context";

const QuickSearch = (): React.ReactElement => {
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  return (
    <div>
      <SubscriptionStatusTable />
      <AllPurchases />
      <ResetsRebillsNewPurchases />
      {(allowed_features.access_to_compliance_search_plans ||
        allowed_features.access_all_features) && <SearchAccountsPlans />}
      <BasicAccountDetailsAndSalesValue />
    </div>
  );
};

export default QuickSearch;

import React from "react";

interface IProps {
  w?: string | number;
  h?: string | number;
  m?: string | number;
  r?: string | number;
  c?: string;
  onClick?: () => void;
  rotate?: string;
}
const Arrow = ({ w, h, m, r, c, onClick, rotate }: IProps): React.ReactElement => (
  <svg
    height={h || w || "16px"}
    width={w || "16px"}
    style={{
      marginBottom: m || 0,
      marginRight: r || 10,
      cursor: "pointer",
      transform: `rotate(${rotate})`,
    }}
    fill={c || "#FFFFFF"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    version="1.1"
    x="0px"
    y="0px"
    onClick={() => (onClick ? onClick() : "")}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={c || "#FFFFFF"}>
        <path
          d="M9.5,16.5 L9.5,4.50748397 C9.5,3.94892021 9.05228475,3.5 8.5,3.5 C7.94386482,3.5 7.5,3.95106594 7.5,4.50748397 L7.5,17.492516 L7.50000671,17.4962596 L7.5,17.5 C7.5,17.7768235 7.61175967,18.0258299 7.79264224,18.206124 C7.97609125,18.3887702 8.22509093,18.5 8.5,18.5 L8.5037436,18.4999932 L8.50748397,18.5 L21.492516,18.5 C22.0510798,18.5 22.5,18.0522847 22.5,17.5 C22.5,16.9438648 22.0489341,16.5 21.492516,16.5 L9.5,16.5 Z"
          transform="translate(15.000000, 11.000000) rotate(-315.000000) translate(-15.000000, -11.000000) "
        />
      </g>
    </g>
  </svg>
);

export default Arrow;

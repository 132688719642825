import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core/";

import useStyles from "../../useStyles";
import AllowedFeaturesToolTip from "../../../AllowedFeaturesToolTip";

const TableBody: React.FC = () => {
  const classes = useStyles();

  const { data } = useSelector((state) => state.agents);

  return (
    <tbody>
      {data.agents.map((agent) => {
        return (
          <tr
            key={agent.id}
            className={cx({
              [classes.activeAccount]: agent.active,
              [classes.inactiveAccount]: !agent.active,
            })}
          >
            <td>{agent.id}</td>
            <td>
              <MenuItem component={Link} to={`/agent/${agent.id}`}>
                {agent.name}
              </MenuItem>
            </td>
            <td>{agent.email}</td>
            <td>{agent.admin_accounts_policies_preset.name_preset}</td>
            <td>
              {data.agentsWithCustomPreset[agent.id] && (
                <AllowedFeaturesToolTip
                  allowed_features={Array.from(new Set(data.agentsWithCustomPreset[agent.id]))}
                />
              )}
            </td>
            <td>{agent.active ? "Active" : "Inactive"}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React, { createContext, useContext, useEffect, useState } from "react";
import usePagination from "../../hooks/usePagination";
import { SortBy } from "../DiscountsProvider";

interface IDiscountCodesLogsContextProps {
  search: {
    log_time: string;
  };
  page: number;
  limit: string;
  setPage: (page: number) => void;
  setLimit: (limit: string) => void;
  sortBy: SortBy;
  setSortBy: (sort: SortBy) => void;
  setSearch: (search: { log_time: string; admin?: string; new_field_value?: string }) => void;
}
const DiscountCodesLogsContext = createContext<IDiscountCodesLogsContextProps>({
  search: {
    log_time: "",
  },
  page: 0,
  limit: "25",
  setPage: () => ({}),
  setLimit: () => ({}),
  sortBy: {
    by: "id",
    order: false,
  },
  setSortBy: () => null,
  setSearch: () => null,
});

interface IProps {
  children: React.ReactNode;
}
const DiscountCodesLogsProvider = ({ children }: IProps): React.ReactElement => {
  const { page, setPage, limit, setLimit } = usePagination();
  const [search, setSearch] = useState({
    log_time: "",
  });
  const [sortBy, setSortBy] = useState<SortBy>({
    by: "log_time",
    order: false,
  });

  useEffect(() => {
    setPage(0);
  }, [limit, search]);

  return (
    <DiscountCodesLogsContext.Provider
      value={{
        search,
        page,
        limit,
        sortBy,
        setPage,
        setSearch,
        setLimit,
        setSortBy,
      }}
    >
      {children}
    </DiscountCodesLogsContext.Provider>
  );
};

export default DiscountCodesLogsProvider;
export const useDiscountCodesLogsContext = (): IDiscountCodesLogsContextProps =>
  useContext(DiscountCodesLogsContext);

import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, Button, ButtonProps } from "@material-ui/core";
import useStyles from "./useStyles";

interface IProps {
  children: React.ReactNode;
  buttonText: string;
  buttonProps?: ButtonProps;
}
const CollapseWithControls: React.FC<IProps> = ({ children, buttonText, buttonProps }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleExpand = () => setOpen(!open);

  return (
    <>
      <div className={classes.expandButton}>
        <Button size="small" variant="contained" onClick={handleExpand} {...buttonProps}>
          {buttonText}
          {open ? (
            <ExpandLess className={classes.hover} />
          ) : (
            <ExpandMore className={classes.hover} data-test="expand-notes" />
          )}
        </Button>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default CollapseWithControls;

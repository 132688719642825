import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import { IPaginationState } from "../../../../hooks/usePagination";
import { APIAccounts } from "../../../../api";

export const useFetchCustomerNotes = (
  id: number,
  pagination: IPaginationState
): (() => Promise<void>) => {
  const dispatch = useDispatch();
  return useCallback(async () => {
    try {
      dispatch(CustomerActions.fetchCustomerNotesRequest());
      const { data } = await APIAccounts.getAccountNotesRequest(id, {
        page: pagination.page,
        pageSize: pagination.limit,
      });
      dispatch(CustomerActions.fetchCustomerNotesSuccess(data.rows, data.count, data.countActive));
      pagination.setCount(data.count);
    } catch (error) {
      console.error("Error handle getAccountNotesRequest", error);
      dispatch(CustomerActions.fetchCustomerNotesFailed());
    }
  }, [pagination.page, pagination.limit]);
};

export const useFetchSuspiciousNotes = (
  id: number,
  pagination: IPaginationState
): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    try {
      dispatch(CustomerActions.fetchCustomerSuspiciousNotesRequest());
      const { data } = await APIAccounts.getAccountSuspiciousNotesRequest(id, {
        page: pagination.page,
        pageSize: pagination.limit,
      });
      pagination.setCount(data.count);
      dispatch(
        CustomerActions.fetchCustomerSuspiciousNotesSuccess(data.rows, data.count, data.countActive)
      );
    } catch (error) {
      console.error("Error handle suspicious notes", error);
      dispatch(CustomerActions.fetchCustomerSuspiciousNotesFailed());
    }
  }, [pagination.page, pagination.limit]);
};

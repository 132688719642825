import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  baseSelect: {
    borderRadius: 16,
    overflow: "hidden",
  },
  rootBaseSelect: {
    padding: "6.5px 10px",
  },
});

export default useStyles;

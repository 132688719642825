import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import TableBody from "./components/TableBody";
import TableHeader from "./components/TableHeaeder";
import { ContainerWithLoader, PaginationWithLimit } from "../../components/ui";
import AccountsFilterAutocomplete from "./components/AccountsFilterAutocomplete";

import useGeneralStyles from "../../useGeneralStyles";
import { RithmicAccountsActions } from "../../reducers/rithmicAccounts";
import { APIRithmic } from "../../api";
import { useRithmicAccountsContext } from "../../context";

import { setPage } from "../../utils";

const RithmicAccounts = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const dispatch = useDispatch();
  const {
    page,
    setPage: setPageContext,
    sortBy,
    setLimit,
    limit,
    search,
  } = useRithmicAccountsContext();

  const { e2tAccounts, count, loading } = useSelector((state) => state.rithmicAccounts.data);

  const handleSetPage = (value: number) => setPage(value, count, +limit, setPageContext);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    (async () => {
      try {
        dispatch(RithmicAccountsActions.fetchRithmicAccountsRequest());
        const data = { limit, page, sortBy, contains: search };
        const response = await APIRithmic.getRithmicAccountsData(data, signal);
        dispatch(RithmicAccountsActions.fetchRithmicAccountsSuccess(response.data));
      } catch (error) {
        console.error("Error fetching rithmic accounts");
        dispatch(RithmicAccountsActions.fetchRithmicAccountsFailed());
      }
    })();
    return () => controller.abort();
  }, [search, limit, page, sortBy]);

  return (
    <div className={generalClasses.containerBg}>
      <AccountsFilterAutocomplete />
      <div>
        <p>
          Users found:<b>{count}</b>
        </p>
      </div>

      <ContainerWithLoader
        isLoading={loading}
        report={e2tAccounts}
        noResultsMessage="There are no results"
      >
        <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
          <TableHeader />
          <TableBody />
        </table>
      </ContainerWithLoader>

      <div>
        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setPageState={handleSetPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default RithmicAccounts;

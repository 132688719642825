import httpClient from "../apiClient";

import { TUpdateGauntletAccountRequest } from "./index.types";

const ACCOUNTS_GAUNTLET_API_URLS = {
  updateGauntletAccount: "/gauntlet-accounts/update-account",
} as const;

export class AccountsGauntletAPI {
  static updateGauntletAccountRequest: TUpdateGauntletAccountRequest = (id, data) =>
    httpClient.post(`${ACCOUNTS_GAUNTLET_API_URLS.updateGauntletAccount}/${id}`, data);
}

import React, { useEffect, useState } from "react";
import ReactJson, { ThemeKeys } from "react-json-view";
import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";

import { APIAppDebug } from "../../api";
import { REACT_JSON_THEME } from "./constants";

const useStyles = makeStyles({
  themeSelectContainer: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    right: 0,
    background: "#fff",
  },
});
const AppLogs = (): React.ReactElement => {
  const classes = useStyles();
  const [data, setData] = useState<unknown[]>([]);
  const [theme, setTheme] = useState<ThemeKeys>("isotope");

  useEffect(() => {
    (async () => {
      const res = await APIAppDebug.getAppLogsRequest();
      setData(
        res.data
          .split(";\n")
          .reverse()
          .map((j) => {
            if (j) {
              return JSON.parse(j);
            }
            return {};
          })
      );
    })();
  }, []);

  const handleChangeTheme = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setTheme(e.target.value as ThemeKeys);
  };
  return (
    <div>
      <FormControl variant="outlined" className={classes.themeSelectContainer}>
        <Select value={theme} onChange={handleChangeTheme}>
          {REACT_JSON_THEME.map((theme) => (
            <MenuItem key={theme} value={theme}>
              {theme}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ReactJson
        groupArraysAfterLength={10}
        src={data}
        theme={theme}
        displayObjectSize={false}
        displayDataTypes={false}
      />
    </div>
  );
};

export default AppLogs;

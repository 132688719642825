import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { AnyObjectSchema } from "yup";

import { FormInput } from "../../../../components/ui";

interface IProps<TInitialValues> {
  fetchData: (values: TInitialValues) => void;
  isLoading: boolean;
  validationSchema: AnyObjectSchema;
  placeholder: string;
  label: string;
  name: string;
  initialValues: TInitialValues;
}
const SearchByListValues = <TInitialValues,>({
  fetchData,
  isLoading,
  validationSchema,
  placeholder,
  label,
  name,
  initialValues,
}: IProps<TInitialValues>): React.ReactElement => {
  return (
    <Formik
      onSubmit={fetchData}
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Field
                  name={name}
                  component={FormInput}
                  placeholder={placeholder}
                  label={label}
                  size="small"
                  fullWidth
                  variant="outlined"
                  multiline
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  disabled={!isValid || !dirty || isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchByListValues;

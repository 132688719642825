import React from "react";
import { makeStyles } from "@material-ui/core/";
import moment from "moment";

import { DATE_FORMAT } from "../../../../constants";
import { MainInfoValue } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";
import { useCustomerProvider } from "../../../../context";

const useStyles = makeStyles({
  consistencyValues: {
    display: "flex",
    flexDirection: "column-reverse",
  },
});
const Evaluations = (): React.ReactElement => {
  const classes = useStyles();
  const { customerEvaluation } = useCustomerProvider();

  const timeStamp = moment(customerEvaluation.timestamp);
  return (
    <div>
      <MainInfoValue title="PnL">{IntlFormatUSD(customerEvaluation.pnl || 0)}</MainInfoValue>
      <MainInfoValue title="Consistency">
        <div className={classes.consistencyValues}>
          <span>{IntlFormatUSD(customerEvaluation.largest_pnl)}</span>
          <span>{customerEvaluation.largest_pnl_percent}%</span>
        </div>
      </MainInfoValue>
      <MainInfoValue title="Days Traded">{customerEvaluation.days_traded}</MainInfoValue>
      <MainInfoValue title="Timestamp">
        {timeStamp.isValid() ? timeStamp.format(DATE_FORMAT) : ""}
      </MainInfoValue>
    </div>
  );
};

export default Evaluations;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  planDataHeader: {
    "& td": {
      backgroundColor: "#d7e3fc !important",
    },
  },
  borderBottom: {
    "& td": {
      borderBottom: "1px solid #000",
    },
  },
});

export default useStyles;

import {
  FundedTradersStatusesListAction,
  FundedTradersStatusesListActionType,
} from "./index.types";
import { IFundedTradersStatus } from "../../api/service/index.types";

export const FundedTradersStatusesListActions = {
  fetchFundedTradersStatusesListRequest: (): FundedTradersStatusesListAction => ({
    type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListRequest,
  }),
  fetchFundedTradersStatusesListSuccess: (
    payload: IFundedTradersStatus[]
  ): FundedTradersStatusesListAction => ({
    type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListSuccess,
    payload,
  }),
  fetchFundedTradersStatusesListFailed: (): FundedTradersStatusesListAction => ({
    type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListFailed,
  }),
};

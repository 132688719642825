import React, { useEffect, useState } from "react";

import { ChartBar } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";
import { INetSalesValuesReport } from "../../../../api/billing/index.types";

interface IDataset {
  backgroundColor: string[];
  data: number[];
  label: string;
}
interface IBarData {
  datasets: IDataset[];
  labels: string[];
}

const BitPayChargesUSvsInternational: React.FC<
  Pick<INetSalesValuesReport, "not_us_cryptocurrency" | "us_cryptocurrency">
> = (data) => {
  const [barData, setBarData] = useState<IBarData>({ datasets: [], labels: [] });

  const { not_us_cryptocurrency = {}, us_cryptocurrency = {} } = data;
  const cryptocurrenciesInReport = Array.from(
    new Set(...[Object.keys({ ...not_us_cryptocurrency, ...us_cryptocurrency })])
  );

  useEffect(() => {
    const not_us_dataset: IDataset = {
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      data: [],
      label: "International",
    };
    const us_dataset: IDataset = {
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      data: [],
      label: "US",
    };

    if (cryptocurrenciesInReport.length) {
      cryptocurrenciesInReport.forEach((symbol) => {
        not_us_cryptocurrency[symbol]
          ? not_us_dataset.data.push(not_us_cryptocurrency[symbol])
          : not_us_dataset.data.push(0);

        us_cryptocurrency[symbol]
          ? us_dataset.data.push(us_cryptocurrency[symbol])
          : us_dataset.data.push(0);
      });

      setBarData({
        datasets: [not_us_dataset, us_dataset],
        labels: cryptocurrenciesInReport,
      });
    }
  }, [not_us_cryptocurrency, us_cryptocurrency]);

  if (!Object.keys(not_us_cryptocurrency).length) {
    return null;
  }

  return (
    <div>
      <ChartBar
        // @ts-ignore
        barData={barData}
        height={250}
        externalOptions={{
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              formatter(value: number) {
                if (!value) {
                  return null;
                }
                return IntlFormatUSD(value, { maximumSignificantDigits: 6 });
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BitPayChargesUSvsInternational;

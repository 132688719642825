import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  icon: {
    padding: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  autocompleteContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2px 0 0 0",
    minWidth: 225,
    width: "100%",
  },
  editIcon: {
    marginLeft: 5,
    "&:hover": {
      color: "#1886cf",
      cursor: "pointer",
    },
  },
  dataContainer: {
    display: "flex",
  },
});

export default useStyles;

import React from "react";

interface IAccountsCounterProps {
  title: string;
  count: number;
}
const AccountsCounter: React.FC<IAccountsCounterProps> = ({ title, count }) => {
  return (
    <div>
      <p>
        {title}: {count}
      </p>
    </div>
  );
};

export default AccountsCounter;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  preloaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  controlsContainer: {
    marginRight: 50,
    verticalAlign: "top",
  },
  selectAllOptions: {
    marginTop: 8,
  },
});

export default useStyles;

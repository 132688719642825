import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    marginBottom: 10,
  },
  ipInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ipInfo: {
    margin: 0,
  },
  ipInfoValues: {
    marginRight: 10,
  },
  getInfoButton: {
    color: "#000",
    backgroundColor: "rgba(235, 109, 44, 0.61)",
    "&:hover": {
      backgroundColor: "rgba(235, 109, 44, 0.61)",
    },
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  verticalAlign: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: 15,
  },
  addButton: {
    marginLeft: 7,
  },
});

export default useStyles;

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId } from "lodash";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { TitleUpdated } from "../../utils";
import { TOP_LIVE_SIM_TAKERS } from "../../constants";

const TopLiveSimTakers: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();
  const { loading, data } = useSelector((state) => state.complianceSearch.topLiveSimTakers);

  useQuery({
    queryKey: [COMPLIANCE_API_URLS.getTopLiveSimTakers, pagination.limit, pagination.page],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());

      dispatch(ComplianceSearchActions.fetchTopLiveSimTakersRequest());
      const response = await ComplianceAPI.getTopLiveSimTakersRequest(query.toString(), signal);
      dispatch(ComplianceSearchActions.fetchTopLiveSimTakersSuccess(response.data));
      pagination.setCount(response.data.results.count);
      return response;
    },
    onError(error) {
      console.error("Error fetching Top LiveSim Takers", error);
      dispatch(ComplianceSearchActions.fetchTopLiveSimTakersFailed());
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Top LiveSim takers</b>

      <Table
        title={<TitleUpdated value={data.updated.timestamp} />}
        columns={TOP_LIVE_SIM_TAKERS}
        loading={loading}
        extractKey={(row) => `${row.id}_${uniqueId()}`}
        data={data.results.rows}
        pagination={pagination}
        handleChangePage={pagination.setPage}
        csv_table_name={"Top Live losses, and top LiveSim takers - Top LiveSim takers"}
      />
    </div>
  );
};

export default TopLiveSimTakers;

import { IFundedTradersStatus } from "../../api/service/index.types";

export type FundedTradersStatusesListAction =
  | FundedTradersStatusesListActionsFetchRequest
  | FundedTradersStatusesListActionsFetchSuccess
  | FundedTradersStatusesListActionsFetchFailed;

export interface FundedTradersStatusesListActionsFetchRequest {
  type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListRequest;
}
export interface FundedTradersStatusesListActionsFetchSuccess {
  type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListSuccess;
  payload: IFundedTradersStatus[];
}
export interface FundedTradersStatusesListActionsFetchFailed {
  type: FundedTradersStatusesListActionType.fetchFundedTradersStatusesListFailed;
}

export enum FundedTradersStatusesListActionType {
  fetchFundedTradersStatusesListRequest = "fundedTradersStatusesList/fetchFundedTradersStatusesListRequest",
  fetchFundedTradersStatusesListSuccess = "fundedTradersStatusesList/fetchFundedTradersStatusesListSuccess",
  fetchFundedTradersStatusesListFailed = "fundedTradersStatusesList/fetchFundedTradersStatusesListFailed",
}

export interface IFundedTradersStatusesListState {
  data: {
    fundedTradersStatusesList: IFundedTradersStatus[];
  };
  loading: boolean;
}

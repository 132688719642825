import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { APIAccounts } from "../../../../api";
import usePagination from "../../../../hooks/usePagination";

import { useFetchCustomerNotes, useFetchSuspiciousNotes } from "./utils";
import { CollapseWithControls, NotesTableWithActions } from "../../../../components/ui";
import { useAdminProviderDataContext } from "../../../../context";

interface IProps {
  isShowSuspiciousNotes?: boolean;
}
const CustomerNotes: React.FC<IProps> = ({ isShowSuspiciousNotes }) => {
  const { id } = useParams();
  const pagination = usePagination("4");

  const {
    data: { countActive, notes },
  } = useSelector((state) =>
    isShowSuspiciousNotes ? state.customer.suspiciousNotes : state.customer.notes
  );

  const fetchNotes = useFetchCustomerNotes(id, pagination);
  const fetchSuspiciousNotes = useFetchSuspiciousNotes(id, pagination);
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const saveNote = async (note: string) => {
    const data = {
      accounts_id: id,
      note: note.trim(),
    };
    try {
      if (isShowSuspiciousNotes) {
        await APIAccounts.addAccountSuspiciousNotesRequest(data);
        await fetchSuspiciousNotes();
      } else {
        await APIAccounts.addAccountNotesRequest(data);
        await fetchNotes();
      }
    } catch (error) {
      console.error("Error handle saveNote", error);
    }
  };

  const handleChangeStatus = async (noteId: number, is_active: boolean) => {
    try {
      if (isShowSuspiciousNotes) {
        await APIAccounts.updateAccountSuspiciousNoteRequest(noteId, { is_active });
        await fetchSuspiciousNotes();
      } else {
        await APIAccounts.updateAccountNoteRequest(noteId, { is_active });
        await fetchNotes();
      }
    } catch (error) {
      console.error("Error handle updateAccountNoteRequest", error);
    }
  };
  const handleDeleteNote = async (noteId: number) => {
    try {
      if (isShowSuspiciousNotes) {
        await APIAccounts.deleteAccountSuspiciousNoteRequest(noteId);
        await fetchSuspiciousNotes();
      } else {
        await APIAccounts.deleteAccountNoteRequest(noteId);
        await fetchNotes();
      }
    } catch (error) {
      console.error("Error handle deleteAccountNoteRequest", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (isShowSuspiciousNotes) {
          await fetchSuspiciousNotes();
        } else {
          await fetchNotes();
        }
      } catch (error) {
        console.error("Error handle getAccountNotesRequest", error);
      }
    })();
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <CollapseWithControls
        buttonText={`${isShowSuspiciousNotes ? "Suspicious " : ""}Notes: ${countActive}`}
        buttonProps={{ ...(countActive && { color: "secondary" }) }}
      >
        <NotesTableWithActions
          notes={notes}
          pagination={pagination}
          saveNote={saveNote}
          handleChangeStatus={handleChangeStatus}
          handleDeleteNote={handleDeleteNote}
          isAllowedChangeStatusAndRemoveActions={
            allowed_features.customer_notes_change_status_or_remove
          }
        />
      </CollapseWithControls>
    </div>
  );
};

export default CustomerNotes;

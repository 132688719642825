import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface IProps {
  html: string;
  onChangeEditor: (data: string) => void;
}
const CKEDITOR_CONFIG = {
  toolbar: [
    "heading",
    "|",
    "link",
    "bold",
    "italic",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "undo",
    "redo",
  ],
};

const HTMLEditor = ({ html, onChangeEditor }: IProps): React.ReactElement => (
  <CKEditor
    name="html"
    config={CKEDITOR_CONFIG}
    editor={ClassicEditor}
    data={html}
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    onChange={(event: any, editor: any) => onChangeEditor(editor.getData())}
  />
);

export default HTMLEditor;

import { PolicesAction, PolicesActionType } from "./index.types";
import { IGetPolicesOptionsResponse } from "../../api/service/index.types";

export const PolicesActions = {
  fetchPolicesRequest: (): PolicesAction => ({
    type: PolicesActionType.fetchPolicesRequest,
  }),
  fetchPolicesSuccess: (payload: IGetPolicesOptionsResponse["data"]): PolicesAction => ({
    type: PolicesActionType.fetchPolicesSuccess,
    payload,
  }),
  fetchPolicesFailed: (): PolicesAction => ({
    type: PolicesActionType.fetchPolicesFailed,
  }),
};

import React from "react";
import cx from "classnames";
import useStyles from "../../useStyles";
import { useCustomerProvider } from "../../../../context";

interface IProps {
  popup: string;
  setPopup: (popup: string) => void;
  extendFinamark: () => void;
}

const ExtendFinamarkUserConfirmWindow: React.FC<IProps> = ({ popup, setPopup, extendFinamark }) => {
  const classes = useStyles();
  const { fina, setFina } = useCustomerProvider();

  return (
    <div
      className={cx("fina fina-extend", classes.createFinUserContainer)}
      style={{ display: popup === "fina-extend" ? "flex" : "none" }}
    >
      <div className="title">Extend Finamark User</div>
      <div style={{ display: "block" }}>
        Add{" "}
        <input
          type="text"
          name="days"
          placeholder="Days"
          value={fina.days}
          style={{ display: "inline", maxWidth: 50 }}
          onChange={(e) => setFina({ ...fina, days: e.target.value })}
        />{" "}
        days to Finamark subscription
      </div>

      <button type="button" onClick={() => extendFinamark()}>
        Extend
      </button>
      <button type="button" onClick={() => setPopup("")}>
        Cancel
      </button>
    </div>
  );
};

export default ExtendFinamarkUserConfirmWindow;

import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@material-ui/core";

import downloadCsv from "../../../../../utils/downloadCsv";
import SelectPairPrice from "../SelectPairPrice";
import { IntlFormatUSD } from "../../../../../utils";
import { useIntercomContext } from "../../../../../context";
import { DEFAULT_TRADE_COMMISSION_PRICE } from "../../../../../constants";
import { APIRithmic } from "../../../../../api";
import { IGetRithmicDataResponse } from "../../../../../api/rithmic/index.types";
import useStyles from "./useStyles";

interface IProps {
  dialogOpen: boolean;
  commissions: {
    [key: string]: number;
  };
  setDialogOpen: (isOpen: boolean) => void;
  fetchRithmicData: (isExportCsv: boolean) => Promise<IGetRithmicDataResponse>;
}
export interface IPairCommission {
  pair: string;
  price: number;
}
const SELECTED_PAIR_INITIAL_STATE = {
  pair: "",
  price: DEFAULT_TRADE_COMMISSION_PRICE,
};
const GenerateGauntletReportDialog = ({
  dialogOpen,
  setDialogOpen,
  commissions,
  fetchRithmicData,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const { contact } = useIntercomContext();
  const { custom_attributes } = contact;
  const gauntletAccountName = custom_attributes["Gauntlet Account Name"];
  const [products, setProducts] = useState<string[]>([]);
  const [selectedPairs, setSelectedPairs] = useState<Array<IPairCommission>>([
    SELECTED_PAIR_INITIAL_STATE,
  ]);

  const handleClose = () => setDialogOpen(false);
  const handleEditPair = (pair: IPairCommission, index: number) => {
    const pairs = [...selectedPairs];
    pairs[index] = pair;
    setSelectedPairs(pairs);
  };
  const handleAddNewPair = () => {
    const pairs = [
      ...selectedPairs,
      {
        pair: "",
        price: 2.22,
      },
    ];
    setSelectedPairs(pairs);
  };
  const handleDeletePair = (index: number) => {
    const pairs = [...selectedPairs];
    if (pairs.length === 1) {
      return;
    }
    pairs.splice(index, 1);
    setSelectedPairs(pairs);
  };
  const handleExportCsv = async () => {
    const HEADER = ["Date", "Time", "Symbol", "Quantity", "Price", "Side", "Commission"];
    const csvRows: (string | number)[][] = [HEADER];
    const commissionsData = { ...commissions };

    selectedPairs.forEach(({ pair, price }) => {
      commissionsData[pair] = price;
    });
    try {
      const response = await fetchRithmicData(true);
      const { rows } = response.data.rithmicData;

      rows.forEach((data) => {
        const date = data["Server Process Date"];
        const time = data["Server Timestamp"];
        const symbol = data.Instrument;
        const quantity = data.Quantity;
        const price = data["Fill Price"];
        const side = data["Buy/Sell Indicator"];
        const commission = IntlFormatUSD(
          quantity * (commissionsData[data.Product] || DEFAULT_TRADE_COMMISSION_PRICE)
        ).replace(",", "."); // .replace() - prevent broken csv file
        csvRows.push([date, time, symbol, quantity, price, side, commission]);
      });
      downloadCsv(csvRows, "Trades");
    } catch (error) {
      console.error("error fetching csv data", error);
    } finally {
      setDialogOpen(false);
      setSelectedPairs([SELECTED_PAIR_INITIAL_STATE]);
    }
  };

  useEffect(() => {
    if (gauntletAccountName && dialogOpen) {
      (async () => {
        const { data } = await APIRithmic.getRithmicUserProductsRequest(gauntletAccountName);
        const userProducts = Array.from(new Set(data.userProducts.map(({ Product }) => Product)));
        setProducts(userProducts);
      })();
    }
  }, [gauntletAccountName, dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Generate gauntlet report</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          {selectedPairs.map((pair, index) => (
            <SelectPairPrice
              pair={pair}
              handleDeletePair={handleDeletePair}
              handleEditPair={handleEditPair}
              index={index}
              options={products}
              selectedOptions={selectedPairs}
              commissions={commissions}
              key={`${pair.pair}`}
            />
          ))}
        </Grid>
        <Grid container spacing={3}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddNewPair}
            disabled={!selectedPairs.slice(-1)[0].pair}
          >
            Add pair
          </Button>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleExportCsv}
          variant="contained"
          color="primary"
          size="small"
          type="submit"
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateGauntletReportDialog;

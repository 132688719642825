/* eslint-disable no-param-reassign */
import produce from "immer";
import { ICustomerDiscountCodesData } from "../api/discounts/index.types";

import { ReduxAction } from "../static-types";

interface IDiscountCodes {
  codes: ICustomerDiscountCodesData[];
  count: number;
}

export type TDiscountCodesAction =
  | IUserDiscountCodesFetchRequest
  | IUserDiscountCodesFetchSuccess
  | IUserDiscountCodesFetchFailed;

export enum EDiscountCodesActionType {
  fetchRequest = "userDiscountCodes/FetchRequest",
  fetchSuccess = "userDiscountCodes/FetchSuccess",
  fetchFailed = "userDiscountCodes/FetchFailed",
}

interface IUserDiscountCodesFetchRequest extends ReduxAction {
  type: EDiscountCodesActionType.fetchRequest;
}
interface IUserDiscountCodesFetchSuccess extends ReduxAction {
  type: EDiscountCodesActionType.fetchSuccess;
  payload: {
    discountCodes: IDiscountCodes;
    id: number;
  };
}
interface IUserDiscountCodesFetchFailed extends ReduxAction {
  type: EDiscountCodesActionType.fetchFailed;
}

export const UserDiscountCodesActions = {
  fetchRequest: (): TDiscountCodesAction => ({
    type: EDiscountCodesActionType.fetchRequest,
  }),
  fetchSuccess: (discountCodes: IDiscountCodes, id: number): TDiscountCodesAction => ({
    type: EDiscountCodesActionType.fetchSuccess,
    payload: {
      discountCodes,
      id,
    },
  }),
  fetchFailed: (): TDiscountCodesAction => ({
    type: EDiscountCodesActionType.fetchFailed,
  }),
};

export interface IUserDiscountCodesState {
  data: {
    [id: number]: {
      codes: ICustomerDiscountCodesData[];
      count: number;
    };
  };
  loading: boolean;
}

const initialState: IUserDiscountCodesState = {
  data: {},
  loading: false,
};

const userDiscountReducer = (
  prevState = initialState,
  action: TDiscountCodesAction
): IUserDiscountCodesState =>
  produce(prevState, (draft: IUserDiscountCodesState): IUserDiscountCodesState => {
    switch (action.type) {
      case EDiscountCodesActionType.fetchRequest:
        draft.loading = true;
        break;
      case EDiscountCodesActionType.fetchSuccess:
        draft.loading = false;
        draft.data[action.payload.id] = action.payload.discountCodes;
        break;
      case EDiscountCodesActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default userDiscountReducer;

import { makeStyles } from "@material-ui/core/";
import { STATUS_COLORS_OPACITY } from "../../../../constants";

const useStyles = makeStyles({
  centeredText: {
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  inactiveAccount: {
    backgroundColor: STATUS_COLORS_OPACITY.lightRed,
  },
  activeAccount: {
    backgroundColor: STATUS_COLORS_OPACITY.lightGreen,
  },
});

export default useStyles;

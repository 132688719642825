import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
  notApplicable: {
    color: "gray",
  },
  textHelper: {
    width: 170,
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -18,
      margin: 0,
      textWrap: "nowrap",
    },
  },
});

export default useStyles;

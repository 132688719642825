import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 10,
    justifyContent: "space-between",
    display: "flex",
  },
  listItemTextRoot: {
    margin: 0,
    lineHeight: 1,
    flex: "none",
  },
  showPriorAccountsButton: {
    margin: "26px 0 0 5px",
  },
  invoiceContainer: {
    marginTop: 10,
  },
  statusesContainer: {
    marginLeft: 20,
    display: "grid",
    justifyItems: "stretch",
    gridTemplateColumns: "1fr 1fr",
    width: 435,
  },
  listContainer: {
    width: "fit-content",
  },
});

export default useStyles;

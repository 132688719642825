import React from "react";
import { getIn } from "formik";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";

import { InfoTitle, InputMessage, ContextualHelp } from "../index";

interface IProps {
  infoTitle: string;
  label: string;
  value: boolean;
  field: {
    value: string;
    name: string;
  };
  form: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    errors: Record<string, any>;
    touched: boolean;
  };
  contextualHelpText: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  contextualHelpProps: Record<string, any>;
}

const useStyles = makeStyles({
  input: {
    "& input": {
      width: "auto !important",
    },
  },
});

const CustomCheckbox = ({
  infoTitle,
  label,
  field,
  form: { errors, touched },
  contextualHelpText,
  contextualHelpProps = {},
  ...rest
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const errorMessage = getIn(errors, field.name);
  const hasError = errorMessage && getIn(touched, field.name);

  return (
    <>
      <ContextualHelp title={contextualHelpText} {...contextualHelpProps}>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...rest}
              checked={!!field.value}
              classes={{ root: classes.input }}
            />
          }
          label={label}
        />
      </ContextualHelp>
      {hasError && <InputMessage error={errorMessage} />}

      {infoTitle && <InfoTitle title={infoTitle} />}
    </>
  );
};

export default CustomCheckbox;

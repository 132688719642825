import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ISortBy } from "../../../../types";
import { SuspiciousCustomersActions } from "../../../../reducers/SuspiciousCustomersReducer/actions";
import { SUSPICIOUS_CUSTOMERS_FILTERS } from "./utils";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";

import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import usePagination from "../../../../hooks/usePagination";
import {
  ContainerWithLoader,
  PaginationWithLimit,
  FilterAutocomplete,
} from "../../../../components/ui";

import { APIAccounts } from "../../../../api";
import { IGetSuspiciousAccountsData } from "../../../../api/accounts/index.types";

const Monitoring = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { page, setPage, limit, setLimit } = usePagination();

  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "id",
    order: false,
  });
  const [search, setSearch] = useState<IGetSuspiciousAccountsData["search"]>({});

  useEffect(() => {
    (async () => {
      try {
        const data = { limit: +limit, page, search, sortBy };
        dispatch(SuspiciousCustomersActions.fetchSuspiciousCustomersRequest());
        const response = await APIAccounts.getSuspiciousNotesRequest(data);
        dispatch(SuspiciousCustomersActions.fetchSuspiciousCustomersSuccess(response.data));
      } catch (error) {
        console.error("Error fetching suspicious notes", error);
        dispatch(SuspiciousCustomersActions.fetchSuspiciousCustomersFailed());
      }
    })();
  }, [page, limit, search, sortBy]);
  const { isLoading, data, count } = useSelector((state) => state.suspiciousCustomers);
  const handleSetPage = (value: number) => setPage(value);

  return (
    <div>
      <div className={classes.filtersWrapper}>
        <FilterAutocomplete
          setSearch={setSearch}
          // @ts-ignore
          search={search}
          filters={SUSPICIOUS_CUSTOMERS_FILTERS}
        />
      </div>

      <div>
        <p className={classes.foundUsers}>
          Users found:<b>{count}</b>
        </p>
      </div>

      <div>
        <ContainerWithLoader isLoading={isLoading} report={data}>
          <table className={generalClasses.table}>
            <TableHead handleSort={[sortBy, setSortBy]} />
            <TableBody />
          </table>
        </ContainerWithLoader>
      </div>

      <div>
        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setPageState={handleSetPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default Monitoring;

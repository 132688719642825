import React from "react";

import useGeneralStyles from "../../useGeneralStyles";
import Sales from "./components/Sales";

const Affiliate = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();

  return (
    <div className={generalClasses.containerBg}>
      <Sales />
    </div>
  );
};

export default Affiliate;

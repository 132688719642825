import React from "react";
import cx from "classnames";

import useStyles from "./useStyles";

interface IProps {
  title: string;
}

const InfoTitle = ({ title }: IProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={cx(classes.wrapper)}>
      <div>{title}</div>
    </div>
  );
};

export default InfoTitle;

import palette from "google-palette";
import moment from "moment";
import moment_tz from "moment-timezone";

export const BATCH_CREATE_CODES_FOR_CUSTOMERS = "batch_create_codes_for_customers";
export const EXPORT_CUSTOMERS_CSV = "export_customers_csv";

export type TAllowedFeaturesList = Record<string, boolean>;

export const HELIOS_INACTIVE_AGENT_ACCESS_LEVEL = 0;
export const HELIOS_AGENT_ACCESS_LEVEL = 1;
export const HELIOS_ADMIN_ACCESS_LEVEL = 2;

export const CENTS_IN_DOLLAR = 100;

export const AC_CURRENT_TIMEZONE = moment_tz().tz("America/Chicago").format("z");
export const DATE_FORMAT = "YYYY-MM-DD";
export const HOURS_12_FORMAT = "hh:mma";
export const HOURS_12_FORMAT_WITH_DATE = `${DATE_FORMAT} (${HOURS_12_FORMAT})`;
export const DATE_FORMAT_hhmmssA = `${DATE_FORMAT} hh:mm:ss A`;
export const DATE_FORMAT_hhmmssAzz = `${DATE_FORMAT} hh:mm:ss A zz`;
export const DATE_FORMAT_hhmmAzz = `${DATE_FORMAT} hh:mm A zz`;

export const PAYMENTS_STATUSES: {
  [index: string]: {
    [index: string]: string;
  };
} = {
  accepted: {
    status: "accepted",
    title: "Successful",
  },
  all: {
    status: "all",
    title: "Show all",
  },
  fraud: {
    status: "fraud",
    title: "Fraud",
  },
  chargeback: {
    status: "chargeback",
    title: "Chargeback",
  },
  failed: {
    status: "failed",
    title: "Failed",
  },
};

enum Env {
  Dev = "development",
  Staging = "staging",
  Prod = "production",
}

// enum Hosts {
//   Dev = "http://localhost:3000/",
//   Staging = "https://managment-ui-web-xre3leqv3a-uc.a.run.app/",
//   Prod = "https://admin.earn2trade.com/",
// }

// const isServerEnv = process.env.REACT_APP_ENVOY_SERVER_PATH;
export const isDevelopEnv = process.env.REACT_APP_NODE_ENV === Env.Dev;
export const isProduction = process.env.REACT_APP_NODE_ENV === Env.Prod;
// const isStagingEnv = process.env.ENVOY_ENV === Env.Staging;

// const mainHostUrl = isStagingEnv ? Hosts.Staging : Hosts.Prod;
export const HOST: string =
  (isDevelopEnv && "http://localhost:3000/") || process.env.REACT_APP_ENVOY_API_URL || "/";

export const PAGES_PATHNAME = {
  discounts: "/discounts",
};

export const STATUSES_COLOR = {
  successful: "#1886cf",
  refund: "#c7c763",
  failed: "#a47031",
  chargeback: "#EA5348",
  fraud: "#cf2418",
};

export const STATUS_COLORS_OPACITY = {
  lightGreen: "#04dd0747",
  lightYellow: "#ffff003b",
  lightRed: "#F88F703b",
  purple: "#8A2BE250",
  tango: "#eb6d2c9c",
} as const;

export const INTERCOM_ROLES = [
  {
    value: "user",
    label: "User",
    id: 1,
  },
  {
    value: "lead",
    label: "Lead",
    id: 2,
  },
];

export const INTERCOM_STATUS = [
  {
    value: "active",
    label: "Active",
    id: 1,
  },
  {
    value: "inactive",
    label: "Inactive",
    id: 2,
  },
];

export const MS_IN_SECOND = 1000;

export const COLOR_PALETTE = [
  ...palette("cb-Set3", 10),
  ...palette("cb-Paired", 10),
  ...palette("tol-rainbow", 10),
  ...palette("cb-Spectral", 10),
  ...palette("mpn65", 10),
  ...palette("cb-RdYlGn", 10),
  ...palette("cb-RdGy", 10),
].map((hex) => `#${hex}`);

export enum DATES_VALUE {
  today = "today",
  yesterday = "yesterday",
  thisWeek = "thisWeek",
  days30back = "days30back",
  thisMonth = "thisMonth",
  lastMonth = "lastMonth",
  thisYear = "thisYear",
  lastYEar = "lastYEar",
  allTIme = "allTIme",
  thisQuarter = "thisQuarter",
  customPeriod = "customPeriod",
  numberMonthAgo_1 = "numberMonthAgo_1",
  numberMonthAgo_2 = "numberMonthAgo_2",
  numberMonthAgo_3 = "numberMonthAgo_3",
  numberMonthAgo_4 = "numberMonthAgo_4",
  numberMonthAgo_5 = "numberMonthAgo_5",
  numberMonthAgo_6 = "numberMonthAgo_6",
  numberMonthAgo_7 = "numberMonthAgo_7",
}

interface IFilterByDate {
  text: string;
  name: string;
}
export const LAST_6_MONTH: IFilterByDate[] = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i <= 6; i++) {
  const monthName = moment().subtract(i, "months").format("MMMM");
  LAST_6_MONTH.push({
    text: monthName,
    // @ts-ignore
    name: i ? DATES_VALUE[`numberMonthAgo_${i}`] : DATES_VALUE.thisMonth,
  });
}

export const FILTER_BY_DATES = [
  {
    text: "Today",
    name: DATES_VALUE.today,
  },
  {
    text: "Yesterday",
    name: DATES_VALUE.yesterday,
  },
  {
    text: "This week",
    name: DATES_VALUE.thisWeek,
  },
  ...LAST_6_MONTH,
  {
    text: "30 days",
    name: DATES_VALUE.days30back,
  },
  {
    text: "This quarter",
    name: DATES_VALUE.thisQuarter,
  },
  {
    text: "This year",
    name: DATES_VALUE.thisYear,
  },
  {
    text: "Last year",
    name: DATES_VALUE.lastYEar,
  },
  {
    text: "All time",
    name: DATES_VALUE.allTIme,
  },
  {
    text: "Custom period",
    name: DATES_VALUE.customPeriod,
  },
];

export const STRIPE_USA_INTEREST = 2.7;
export const STRIPE_USA_OTHER_COUNTRIES = 3.7;

export const PAYPAL_INTEREST = 3.49;
export const PAYPAL_FIXED_FEE_INTEREST = 1.5;

export const DEFAULT_TRADE_COMMISSION_PRICE = 2.22;

export const PAYPAL_CODE_TRANSACTION_STATUS = {
  D: "PayPal or merchant rules denied the transaction.",
  P: "The transaction is pending. The transaction was created but waits for another payment process to complete, such as an ACH transaction, before the status changes to S",
  S: "The transaction successfully completed without a denial and after any pending statuses.",
  V: "successful transaction was fully reversed and funds were refunded to the original sender.",
};

export enum EInitialAccountSizeOptions {
  TCP25 = "TCP25",
  TCP50 = "TCP50",
  TCP100 = "TCP100",
  GM50 = "GM50",
  GM100 = "GM100",
  GM150 = "GM150",
  GM200 = "GM200",
}

export enum EProcessingStatuses {
  live_sim_live = "LiveSim:Live",
  failed = "Failed",
  duplicate = "Duplicate",
  askedForInfo = "Asked for Info",
  pro_must_pay_for_data = "Pro:Must Pay",
  ok_paperwork = "Ok Paperwork",
  ready_for_review = "Ready for Review",
  reviewed = "Reviewed",
  active_trading_account = "Active Trading Account",
  on_hold = "On Hold",
  completed = "Completed",
}

export enum EInvoiceStatusesLiveSimFundedTrader {
  needs_setup_invoice = "Needs Setup Invoice",
  invoice_sent = "Invoice Sent",
  paid = "Paid",
  claimed_paid = "Claimed Paid",
  pro_sept_2023 = "Pre-Sept2023",
  pro_needs_invoice = "Pro:Needs Invoice",
  pro_invoice_sent = "Pro:Invoice Sent",
  pro_paid = "Pro:Paid",
}
export enum EInvoiceStatusesLiveFundedTrader {
  needs_invoice = "Needs Invoice",
  no_data = "No Data",
  invoice_sent = "Invoice Sent",
  claimed_paid = "Claimed Paid",
  paid = "Paid",
}
export enum EEvaluationType {
  live = "Live",
  liveSim = "LiveSim",
}

export const EPProcessingCompletedStatuses = [EProcessingStatuses.failed];

export const EVALUATION_TYPE_OPTIONS = [
  {
    value: EEvaluationType.live,
    label: EEvaluationType.live,
    id: 0,
  },
  {
    value: EEvaluationType.liveSim,
    label: EEvaluationType.liveSim,
    id: 1,
  },
];
export const INVOICE_LIVE_STATUSES_OPTIONS = [
  {
    value: EInvoiceStatusesLiveFundedTrader.needs_invoice,
    label: EInvoiceStatusesLiveFundedTrader.needs_invoice,
    id: 0,
  },
  {
    value: EInvoiceStatusesLiveFundedTrader.no_data,
    label: EInvoiceStatusesLiveFundedTrader.no_data,
    id: 1,
  },
  {
    value: EInvoiceStatusesLiveFundedTrader.invoice_sent,
    label: EInvoiceStatusesLiveFundedTrader.invoice_sent,
    id: 2,
  },
  {
    value: EInvoiceStatusesLiveFundedTrader.claimed_paid,
    label: EInvoiceStatusesLiveFundedTrader.claimed_paid,
    id: 3,
  },
  {
    value: EInvoiceStatusesLiveFundedTrader.paid,
    label: EInvoiceStatusesLiveFundedTrader.paid,
    id: 4,
  },
];
export const INVOICE_LIVE_SIM_STATUSES_OPTIONS = [
  {
    value: EInvoiceStatusesLiveSimFundedTrader.needs_setup_invoice,
    label: EInvoiceStatusesLiveSimFundedTrader.needs_setup_invoice,
    id: 0,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.invoice_sent,
    label: EInvoiceStatusesLiveSimFundedTrader.invoice_sent,
    id: 1,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.paid,
    label: EInvoiceStatusesLiveSimFundedTrader.paid,
    id: 2,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.claimed_paid,
    label: EInvoiceStatusesLiveSimFundedTrader.claimed_paid,
    id: 3,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.pro_sept_2023,
    label: EInvoiceStatusesLiveSimFundedTrader.pro_sept_2023,
    id: 4,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.pro_needs_invoice,
    label: EInvoiceStatusesLiveSimFundedTrader.pro_needs_invoice,
    id: 5,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.pro_invoice_sent,
    label: EInvoiceStatusesLiveSimFundedTrader.pro_invoice_sent,
    id: 6,
  },
  {
    value: EInvoiceStatusesLiveSimFundedTrader.pro_paid,
    label: EInvoiceStatusesLiveSimFundedTrader.pro_paid,
    id: 7,
  },
];

export const INITIAL_ACCOUNT_SIZE_OPTIONS = [
  {
    value: "",
    label: "No",
    id: 0,
  },
  {
    value: EInitialAccountSizeOptions.TCP25,
    label: EInitialAccountSizeOptions.TCP25,
    id: 1,
  },
  {
    value: EInitialAccountSizeOptions.TCP50,
    label: EInitialAccountSizeOptions.TCP50,
    id: 2,
  },
  {
    value: EInitialAccountSizeOptions.TCP100,
    label: EInitialAccountSizeOptions.TCP100,
    id: 3,
  },
  {
    value: EInitialAccountSizeOptions.GM50,
    label: EInitialAccountSizeOptions.GM50,
    id: 4,
  },
  {
    value: EInitialAccountSizeOptions.GM100,
    label: EInitialAccountSizeOptions.GM100,
    id: 5,
  },
  {
    value: EInitialAccountSizeOptions.GM150,
    label: EInitialAccountSizeOptions.GM150,
    id: 6,
  },
  {
    value: EInitialAccountSizeOptions.GM200,
    label: EInitialAccountSizeOptions.GM200,
    id: 7,
  },
];
export const PROCESSING_STATUSES_OPTIONS = [
  {
    value: EProcessingStatuses.live_sim_live,
    label: EProcessingStatuses.live_sim_live,
    id: 0,
  },
  {
    value: EProcessingStatuses.failed,
    label: EProcessingStatuses.failed,
    id: 1,
  },
  {
    value: EProcessingStatuses.duplicate,
    label: EProcessingStatuses.duplicate,
    id: 2,
  },
  {
    value: EProcessingStatuses.askedForInfo,
    label: EProcessingStatuses.askedForInfo,
    id: 3,
  },
  {
    value: EProcessingStatuses.pro_must_pay_for_data,
    label: EProcessingStatuses.pro_must_pay_for_data,
    id: 4,
  },
  {
    value: EProcessingStatuses.ok_paperwork,
    label: EProcessingStatuses.ok_paperwork,
    id: 5,
  },
  {
    value: EProcessingStatuses.ready_for_review,
    label: EProcessingStatuses.ready_for_review,
    id: 6,
  },
  {
    value: EProcessingStatuses.reviewed,
    label: EProcessingStatuses.reviewed,
    id: 7,
  },
  {
    value: EProcessingStatuses.active_trading_account,
    label: EProcessingStatuses.active_trading_account,
    id: 8,
  },
  {
    value: EProcessingStatuses.on_hold,
    label: EProcessingStatuses.on_hold,
    id: 9,
  },
  {
    value: EProcessingStatuses.completed,
    label: EProcessingStatuses.completed,
    id: 10,
  },
];

export enum EWithdrawalStatuses {
  all = "all",
  paid = "paid",
  canceled = "canceled",
  pending = "pending",
  onHold = "on hold",
}

export const MAX_LENGTH_DISCOUNT_CODE_DESCRIPTION = 200;

export const CA_AND_US_STATES_OPTIONS: { id: string; c: string; name: string }[] = [
  {
    id: "AB",
    c: "CA",
    name: "Alberta",
  },
  {
    id: "BC",
    c: "CA",
    name: "British Columbia",
  },
  {
    id: "MB",
    c: "CA",
    name: "Manitoba",
  },
  {
    id: "NB",
    c: "CA",
    name: "New Brunswick",
  },
  {
    id: "NF",
    c: "CA",
    name: "Newfoundland",
  },
  {
    id: "NS",
    c: "CA",
    name: "Nova Scotia",
  },
  {
    id: "NU",
    c: "CA",
    name: "Nunavut",
  },
  {
    id: "NW",
    c: "CA",
    name: "Northwest Territories",
  },
  {
    id: "ON",
    c: "CA",
    name: "Ontario",
  },
  {
    id: "PE",
    c: "CA",
    name: "Prince Edward Island",
  },
  {
    id: "QB",
    c: "CA",
    name: "Quebec",
  },
  {
    id: "SK",
    c: "CA",
    name: "Saskatchewan",
  },
  {
    id: "YK",
    c: "CA",
    name: "Yukon",
  },
  {
    id: "AK",
    c: "US",
    name: "Alaska",
  },
  {
    id: "AL",
    c: "US",
    name: "Alabama",
  },
  {
    id: "AR",
    c: "US",
    name: "Arkansas",
  },
  {
    id: "AZ",
    c: "US",
    name: "Arizona",
  },
  {
    id: "CA",
    c: "US",
    name: "California",
  },
  {
    id: "CO",
    c: "US",
    name: "Colorado",
  },
  {
    id: "CT",
    c: "US",
    name: "Connecticut",
  },
  {
    id: "DC",
    c: "US",
    name: "Washington DC",
  },
  {
    id: "DE",
    c: "US",
    name: "Delaware",
  },
  {
    id: "FL",
    c: "US",
    name: "Florida",
  },
  {
    id: "GA",
    c: "US",
    name: "Georgia",
  },
  {
    id: "HI",
    c: "US",
    name: "Hawaii",
  },
  {
    id: "IA",
    c: "US",
    name: "Iowa",
  },
  {
    id: "ID",
    c: "US",
    name: "Idaho",
  },
  {
    id: "IL",
    c: "US",
    name: "Illinois",
  },
  {
    id: "IN",
    c: "US",
    name: "Indiana",
  },
  {
    id: "KS",
    c: "US",
    name: "Kansas",
  },
  {
    id: "KY",
    c: "US",
    name: "Kentucky",
  },
  {
    id: "LA",
    c: "US",
    name: "Louisiana",
  },
  {
    id: "MA",
    c: "US",
    name: "Massachusetts",
  },
  {
    id: "MD",
    c: "US",
    name: "Maryland",
  },
  {
    id: "ME",
    c: "US",
    name: "Maine",
  },
  {
    id: "MI",
    c: "US",
    name: "Michigan",
  },
  {
    id: "MN",
    c: "US",
    name: "Minnesota",
  },
  {
    id: "MO",
    c: "US",
    name: "Missouri",
  },
  {
    id: "MS",
    c: "US",
    name: "Mississippi",
  },
  {
    id: "MT",
    c: "US",
    name: "Montana",
  },
  {
    id: "NC",
    c: "US",
    name: "North Carolina",
  },
  {
    id: "ND",
    c: "US",
    name: "North Dakota",
  },
  {
    id: "NE",
    c: "US",
    name: "Nebraska",
  },
  {
    id: "NH",
    c: "US",
    name: "New Hampshire",
  },
  {
    id: "NJ",
    c: "US",
    name: "New Jersey",
  },
  {
    id: "NM",
    c: "US",
    name: "New Mexico",
  },
  {
    id: "NV",
    c: "US",
    name: "Nevada",
  },
  {
    id: "NY",
    c: "US",
    name: "New York",
  },
  {
    id: "OH",
    c: "US",
    name: "Ohio",
  },
  {
    id: "OK",
    c: "US",
    name: "Oklahoma",
  },
  {
    id: "OR",
    c: "US",
    name: "Oregon",
  },
  {
    id: "PA",
    c: "US",
    name: "Pennsylvania",
  },
  {
    id: "RI",
    c: "US",
    name: "Rhode Island",
  },
  {
    id: "SC",
    c: "US",
    name: "South Carolina",
  },
  {
    id: "SD",
    c: "US",
    name: "South Dakota",
  },
  {
    id: "TN",
    c: "US",
    name: "Tennessee",
  },
  {
    id: "TX",
    c: "US",
    name: "Texas",
  },
  {
    id: "UT",
    c: "US",
    name: "Utah",
  },
  {
    id: "VA",
    c: "US",
    name: "Virginia",
  },
  {
    id: "VT",
    c: "US",
    name: "Vermont",
  },
  {
    id: "WA",
    c: "US",
    name: "Washington",
  },
  {
    id: "WI",
    c: "US",
    name: "Wisconsin",
  },
  {
    id: "WV",
    c: "US",
    name: "West Virginia",
  },
  {
    id: "WY",
    c: "US",
    name: "Wyoming",
  },
];

export const INACTIVE_PLANS = ["Gauntlet Mini 75K", "The Gauntlet", "Gauntlet Mini 25K"];

import React from "react";

const CardDeclined = (): React.ReactElement => (
  <svg
    width="16px"
    height="12px"
    viewBox="0 0 16 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: -3, marginRight: 5 }}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-10.000000, -10.000000)" fill="#000000">
        <g id="247561" transform="translate(10.000000, 10.000000)">
          <path
            d="M14.8745,0.12975 C15.449,0.12975 15.91625,0.60325 15.91625,1.18525 L15.91625,1.18525 L15.91625,9.23075 C15.91625,9.47129901 15.8363616,9.69338264 15.7020358,9.87114344 L15.3269853,9.49560086 C15.370647,9.41746274 15.3956683,9.32702711 15.39575,9.23075 L15.39575,9.23075 L15.39575,8.61525 L14.446,8.61475 L12.8224849,6.99122731 L13.803,6.00975 L15.39525,6.01075 L15.395,4.41775 L15.4150867,4.39854765 L15.395,4.37775 L15.39525,1.18525 C15.39525,0.92325 15.2103611,0.70470679 14.9677054,0.6593762 L14.87425,0.65075 L1.0415,0.65075 C0.7545,0.65075 0.52075,0.8905 0.52075,1.18525 L0.52075,1.18525 L0.52075,6.01075 L7.598,6.00975 L8.57948494,6.99122731 L6.955,8.61475 L0.521,8.61525 L0.521,9.23075 C0.521,9.5255 0.7545,9.7655 1.04175,9.7655 L1.04175,9.7655 L5.805,9.76475 L5.284,10.28575 L1.04175,10.28625 C0.50555,10.28625 0.0627766667,9.87356111 0.00612333333,9.34555163 L-2.49933407e-12,9.23075 L-2.49933407e-12,1.18525 C-2.49933407e-12,0.60325 0.46725,0.13 1.04175,0.12975 L1.04175,0.12975 Z M11.353,9.76475 L11.874,10.28575 L9.527,10.28575 L10.047,9.76475 L11.353,9.76475 Z"
            id="Combined-Shape"
            fillRule="nonzero"
          />
          <path
            d="M13.7743667,2.98968416 L14.7169997,3.93231722 L11.6522989,6.99668416 L14.7169997,10.060752 L13.7743667,11.003385 L10.7102989,7.93868416 L7.64593192,11.003385 L6.70329887,10.060752 L9.76829887,6.99668416 L6.70329887,3.93231722 L7.64593192,2.98968416 L10.7102989,6.05468416 L13.7743667,2.98968416 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CardDeclined;

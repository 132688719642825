import httpClient from "../apiClient";
import {
  TGetAdminDataRequest,
  TGetBitPayExchangeRatesRequest,
  TGetFundedTradersStatusesListRequest,
  TGetPolicesOptionsRequest,
} from "./index.types";

export const SERVICE_API_URLS = {
  getAdminData: "agents/getAdminData",
  getBitPayExchangeRates: "/services/bit-pay-exchange-rates",
  getPolicesOptions: "/services/polices-options",
  getFundedTradersStatusesList: "/services/funded-trader-statuses-list",
} as const;

export class ServiceAPI {
  static getPolicesOptionsRequest: TGetPolicesOptionsRequest = () =>
    httpClient.get(SERVICE_API_URLS.getPolicesOptions);
  static getAdminDataRequest: TGetAdminDataRequest = () =>
    httpClient.post(SERVICE_API_URLS.getAdminData);
  static getBitPayExchangeRates: TGetBitPayExchangeRatesRequest = () =>
    httpClient.post(SERVICE_API_URLS.getBitPayExchangeRates);
  static getFundedTradersStatusesListRequest: TGetFundedTradersStatusesListRequest = () =>
    httpClient.get(SERVICE_API_URLS.getFundedTradersStatusesList);
}

import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core";
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

const useStyles = makeStyles({
  rootAccordion: {
    backgroundColor: "transparent",
    marginBottom: 24,
  },
  rootAccordionDetails: {
    paddingBottom: 36,
  },
  expanded: {
    marginBottom: "24px !important",
  },
});
interface IProps {
  accordionSummary: React.ReactNode;
  accordionDetails: React.ReactNode;
}
const Accordion: React.FC<IProps> = ({ accordionSummary, accordionDetails }) => {
  const classes = useStyles();

  return (
    <MuiAccordion classes={{ root: classes.rootAccordion, expanded: classes.expanded }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{accordionSummary}</AccordionSummary>
      <AccordionDetails classes={{ root: classes.rootAccordionDetails }}>
        {accordionDetails}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;

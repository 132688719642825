import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FilterAutocomplete from "../../../../components/ui/FilterAutocomplete";
import { useEvaluationsContext } from "../../../../context";
import { REGULAR_EVALUATIONS_FILTERS, INPUT_TYPES } from "../../constants";

const useStyles = makeStyles({
  container: {
    marginBottom: 10,
  },
});
const Filters = (): React.ReactElement => {
  const classes = useStyles();
  const { search, setSearch } = useEvaluationsContext();

  return (
    <div className={classes.container}>
      <FilterAutocomplete
        // @ts-ignore
        search={search}
        setSearch={setSearch}
        filters={{
          ...REGULAR_EVALUATIONS_FILTERS,
        }}
        inputTypes={INPUT_TYPES}
      />
    </div>
  );
};

export default Filters;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../api/compliance";
import { ComplianceSearchActions } from "../../reducers/ComplianceSearchReducer/actions";
import { ISortBy } from "../../types";
import usePagination, { IPaginationState } from "../../hooks/usePagination";
import { tabsTablesSearchInitialState } from "./utils";

export interface IComplianceSearchContextProps {
  accounts_table: {
    search: {
      [index: string]: string | number;
    };
    setSearch: (s: Record<string, string | number>) => void;
    sortBy: ISortBy;
    setSortBy: (s: { by: string; order: boolean }) => void;
    pagination: IPaginationState;
    selectedRows: Record<"id", number>[];
    setSelectedRows: (r: Record<"id", number>[]) => void;
  };
  logins_table: {
    sortBy: ISortBy;
    setSortBy: (s: { by: string; order: boolean }) => void;
  };
  unique_registration_ip_table: {
    sortBy: ISortBy;
    setSortBy: (s: { by: string; order: boolean }) => void;
  };
  payment_cards_table: {
    sortBy: ISortBy;
    setSortBy: (s: { by: string; order: boolean }) => void;
  };
  tabsTablesSearchState: {
    allAccountSearchTab: {
      searchAccountsByName: {
        search: {
          names: string;
        };
      };
      searchAccountsByPhone: {
        search: {
          phones: string;
        };
      };
      evaluationsRithmicAccounts: {
        search: {
          emails: string;
        };
      };
    };
    quickSearchTab: {
      subscriptionStatusTable: {
        search: {
          emails: string;
        };
      };
      allPurchasesTable: {
        search: {
          emails: string;
        };
      };
      resetsRebillsNewPurchasesTable: {
        search: {
          emails: string;
        };
      };
      searchAccountsPlansTable: {
        search: {
          emails: string;
        };
      };
      basicAccountDetailsAndSalesValue: {
        search: {
          emails: string;
        };
      };
    };
    fundedAccountQuickSearchTab: {
      heliosPassesSearchTable: {
        search: {
          names: string;
        };
      };
      heliosWithdrawalsSearchTable: {
        search: {
          names: string;
        };
      };
      heliosFailedSearchTable: {
        search: {
          names: string;
        };
      };
      heliosLiveSIMTraderInformationFormSearchTable: {
        search: {
          names: string;
          email: string;
          status: string;
        };
      };
      heliosAndAppiusLiveSearchTable: {
        search: {
          names: string;
          phone: string;
          email: string;
          address: string;
        };
      };
      tcpStepsSearchTable: {
        search: {
          names: string;
        };
      };
      gmCappersSearchTable: {
        search: {
          names: string;
        };
      };
    };
    fundedAccountInvestigationTab: {
      fundedAccountsInvestigationSearchTable: {
        search: {
          names: string;
          email: string;
          phone: string;
          address: string;
        };
      };
    };
    watchListBanPnlTab: {
      pnLCalculatorTable: {
        search: {
          names: string;
          email: string;
        };
      };
      masterBanTable: {
        search: {
          names: string;
          email: string;
        };
      };
      heliosNotesSearchTable: {
        search: {
          names: string;
          email: string;
        };
      };
    };
  };
  setTabsTablesSearchState: (s: IComplianceSearchContextProps["tabsTablesSearchState"]) => void;
}
interface Props {
  children: React.ReactNode;
}

const ComplianceSearchContext = createContext<IComplianceSearchContextProps>({
  accounts_table: {
    search: {},
    pagination: {
      count: 0,
      limit: "20",
      page: 0,
      setCount: () => null,
      setLimit: () => null,
      setPage: () => null,
    },
    setSearch: () => null,
    sortBy: {
      by: "id",
      order: false,
    },
    setSortBy: () => null,
    selectedRows: [],
    setSelectedRows: () => null,
  },
  logins_table: {
    sortBy: {
      by: "timestamp",
      order: false,
    },
    setSortBy: () => null,
  },
  unique_registration_ip_table: {
    sortBy: {
      by: "reg_ip",
      order: false,
    },
    setSortBy: () => null,
  },
  payment_cards_table: {
    sortBy: {
      by: "name",
      order: false,
    },
    setSortBy: () => null,
  },
  tabsTablesSearchState: tabsTablesSearchInitialState,
  setTabsTablesSearchState: () => null,
});

const ComplianceSearchProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [tabsTablesSearchState, setTabsTablesSearchState] = useState<
    IComplianceSearchContextProps["tabsTablesSearchState"]
  >(tabsTablesSearchInitialState);

  // Accounts table state
  const [accountsTableSearch, setAccountsTableSearch] = useState({});
  const [accountsTableSortBy, setAccountsTableSortBy] = useState<ISortBy>({
    by: "id",
    order: false,
  });
  const [accountsTableSelectedRows, setAcountsTableSelectedRows] = useState<Array<{ id: number }>>(
    []
  );

  // Logins table state
  const [loginsTableSortBy, setLoginsTableSortBy] = useState<ISortBy>({
    by: "timestamp",
    order: false,
  });
  // Unique registration IP table state
  const [uniqueRegistrationIpTableSortBy, setUniqueRegistrationIpTableSortBy] = useState<ISortBy>({
    by: "reg_ip",
    order: false,
  });
  // Payment cards table state
  const [paymentCardsTableSortBy, setPaymentCardsTableSortBy] = useState<ISortBy>({
    by: "name",
    order: false,
  });

  const accountsTablePagination = usePagination();

  const { accounts_table } = useSelector((state) => state.complianceSearch);

  const accounts_table_params = new URLSearchParams();
  const accounts_table_id_params = new URLSearchParams();

  [
    ...(accountsTableSelectedRows.length ? accountsTableSelectedRows : accounts_table.data.rows),
  ].forEach((r) => {
    accounts_table_id_params.append("id", r.id.toString());
  });
  accounts_table_params.append("sortBy", loginsTableSortBy.by);
  accounts_table_params.append("order", loginsTableSortBy.order ? "asc" : "desc");

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getEntries,
      accountsTableSortBy,
      accountsTableSearch,
      accountsTablePagination.limit,
      accountsTablePagination.page,
    ],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchRequest());
      const response = await ComplianceAPI.getAllEntriesRequest(
        {
          accounts_table: {
            search: accountsTableSearch,
            sortBy: accountsTableSortBy,
            limit: +accountsTablePagination.limit,
            page: accountsTablePagination.page,
          },
        },
        signal
      );
      dispatch(ComplianceSearchActions.fetchComplianceSearchSuccess(response.data.accounts_table));

      if (!response.data.accounts_table.count) {
        dispatch(
          ComplianceSearchActions.fetchComplianceSearchLoginsSuccess({ count: 0, rows: [] })
        );
        dispatch(ComplianceSearchActions.fetchComplianceSearchUniqueReqIpSuccess([]));
        dispatch(ComplianceSearchActions.fetchComplianceSearchPaymentCardsSuccess([]));
      }

      await queryClient.invalidateQueries({
        queryKey: [
          COMPLIANCE_API_URLS.getCustomersLogins,
          loginsTableSortBy,
          accounts_table_params,
        ],
      });

      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchFailed());
      console.error(error);
    },
  });

  useEffect(() => {
    accountsTablePagination.setPage(0);
  }, [Object.values(accountsTableSearch).join("")]);

  // Fetch logins
  useQuery({
    enabled: !!accounts_table.data.count,
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getCustomersLogins,
      loginsTableSortBy,
      accounts_table_id_params.toString(),
    ],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchLoginsRequest());

      const response = await ComplianceAPI.getCustomersLoginsRequest(
        `${accounts_table_params.toString()}&${accounts_table_id_params.toString()}`,
        signal
      );
      dispatch(ComplianceSearchActions.fetchComplianceSearchLoginsSuccess(response.data.logins));
      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchLoginsFailed());
      console.error(error);
    },
  });
  // Fetch unique registration IP
  useQuery({
    enabled: !!accounts_table.data.count,
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getUniqueReqIp,
      uniqueRegistrationIpTableSortBy,
      accounts_table_id_params.toString(),
    ],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchUniqueReqIpRequest());

      const query = new URLSearchParams();
      query.append("sortBy", uniqueRegistrationIpTableSortBy.by);
      query.append("order", uniqueRegistrationIpTableSortBy.order ? "asc" : "desc");

      const response = await ComplianceAPI.getUniqueReqIpRequest(
        `${query.toString()}&${accounts_table_id_params.toString()}`,
        signal
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchUniqueReqIpSuccess(response.data.usageData)
      );
      return response;
    },
    onError(error) {
      console.error(error);
      dispatch(ComplianceSearchActions.fetchComplianceSearchUniqueReqIpFailed());
    },
  });
  // Fetch payment cards
  useQuery({
    retry: false,
    enabled: !!accounts_table.data.count,
    queryKey: [
      COMPLIANCE_API_URLS.getPaymentCards,
      paymentCardsTableSortBy,
      accounts_table_id_params.toString(),
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("sortBy", paymentCardsTableSortBy.by);
      query.append("order", paymentCardsTableSortBy.order ? "asc" : "desc");

      dispatch(ComplianceSearchActions.fetchComplianceSearchPaymentCardsRequest());
      const response = await ComplianceAPI.getPaymentCardsRequest(
        `${query.toString()}&${accounts_table_id_params.toString()}`,
        signal
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchPaymentCardsSuccess(response.data.paymentCards)
      );
      return response;
    },
    onError(error) {
      console.log(error);
      dispatch(ComplianceSearchActions.fetchComplianceSearchPaymentCardsFailed());
    },
  });
  return (
    <ComplianceSearchContext.Provider
      value={{
        accounts_table: {
          search: accountsTableSearch,
          setSearch: setAccountsTableSearch,
          sortBy: accountsTableSortBy,
          setSortBy: setAccountsTableSortBy,
          pagination: accountsTablePagination,
          selectedRows: accountsTableSelectedRows,
          setSelectedRows: setAcountsTableSelectedRows,
        },
        logins_table: {
          sortBy: loginsTableSortBy,
          setSortBy: setLoginsTableSortBy,
        },
        unique_registration_ip_table: {
          sortBy: uniqueRegistrationIpTableSortBy,
          setSortBy: setUniqueRegistrationIpTableSortBy,
        },
        payment_cards_table: {
          sortBy: paymentCardsTableSortBy,
          setSortBy: setPaymentCardsTableSortBy,
        },
        tabsTablesSearchState,
        setTabsTablesSearchState,
      }}
    >
      {children}
    </ComplianceSearchContext.Provider>
  );
};

export default ComplianceSearchProvider;
export const useComplianceSearchContext = (): IComplianceSearchContextProps =>
  useContext(ComplianceSearchContext);

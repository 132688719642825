import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

interface IProps {
  children: React.ReactNode;
  isLoading: boolean;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  report: any[];
  noResultsMessage?: string;
}

const useStyles = makeStyles({
  container: {
    minHeight: 36,
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const ContainerWithLoader = ({
  children,
  isLoading,
  report,
  noResultsMessage = "There are no results",
}: IProps): React.ReactElement => {
  const classes = useStyles();
  if (isLoading) {
    return (
      <div className={classes.container}>
        <div className={classes.centered}>
          <CircularProgress />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {!report.length ? <span className={classes.centered}>{noResultsMessage}</span> : children}
    </div>
  );
};

export default ContainerWithLoader;

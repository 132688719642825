import React from "react";

import ForcedToLive from "../../../../components/ui/ForcedToLive";

const AccountsOver8KWithdrawals = (): React.ReactElement => {
  return (
    <div>
      <ForcedToLive isGetAllAccounts />
    </div>
  );
};

export default AccountsOver8KWithdrawals;

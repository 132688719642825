import { makeStyles } from "@material-ui/core/";
import { STATUS_COLORS_OPACITY } from "../../../../constants";

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 5,
  },
  scrollContainer: {
    height: "30vh",
    overflow: "scroll",
  },
  selectedRow: {
    "&:nth-child(even) td": {
      backgroundColor: `${STATUS_COLORS_OPACITY.tango} !important`,
    },
    "& td": {
      backgroundColor: STATUS_COLORS_OPACITY.tango,
    },
  },
  tdEmail: {
    display: "flex",
    alignItems: "center",
  },
  fixedRow: {
    "& td": {
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 10,
      background: "#1886cf !important",
    },
  },
});

export default useStyles;

/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { IPlan, IPlansRules } from "../api/plans/index.types";

export type PlansAction = PlansFetchRequest | PlansFetchSuccess | PlansFetchFailed;

interface PlansFetchRequest extends ReduxAction {
  type: PlansActionType.fetchRequest;
}

interface PlansFetchSuccess extends ReduxAction {
  type: PlansActionType.fetchSuccess;
  payload: {
    plans: IPlan[];
    plans_rules: IPlansRules[];
  };
}

interface PlansFetchFailed extends ReduxAction {
  type: PlansActionType.fetchFailed;
}

export enum PlansActionType {
  fetchRequest = "plans/FetchRequest",
  fetchSuccess = "plans/FetchSuccess",
  fetchFailed = "plans/FetchFailed",
}

export const CodesActions = {
  fetchRequest: (): PlansAction => ({
    type: PlansActionType.fetchRequest,
  }),

  fetchSuccess: ({
    plans,
    plans_rules,
  }: {
    plans: IPlan[];
    plans_rules: IPlansRules[];
  }): PlansAction => ({
    type: PlansActionType.fetchSuccess,
    payload: { plans, plans_rules },
  }),
  fetchFailed: (): PlansAction => ({
    type: PlansActionType.fetchFailed,
  }),
};

export interface PlansState {
  data: {
    plans: IPlan[];
    plans_rules: IPlansRules[];
  };
  loading: boolean;
}

const initialState: PlansState = {
  data: {
    plans: [],
    plans_rules: [],
  },
  loading: true,
};

const plansReducer = (prevState = initialState, action: PlansAction): PlansState =>
  produce(prevState, (draft: PlansState): PlansState => {
    switch (action.type) {
      case PlansActionType.fetchRequest:
        draft.loading = true;
        break;
      case PlansActionType.fetchSuccess:
        draft.data.plans = action.payload.plans;
        draft.data.plans_rules = action.payload.plans_rules;
        draft.loading = false;
        break;
      case PlansActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default plansReducer;

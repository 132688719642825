import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  labelContainer: {
    width: 200,
  },
  labelContent: {
    overflowWrap: "anywhere",
  },
  htmlContent: {
    width: 300,
  },
});

export default useStyles;

import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonProps,
} from "@material-ui/core";

import Snackbar, { TTypeMessage } from "../../../../components/ui/Snackbar";

interface IProps {
  buttonText: string;
  dialogTitle: string;
  contentText: string;
  alertTextSuccess?: string;
  alertTextError?: string;
  isShowMainButton: boolean;
  // eslint-disable-next-line
  handleRequest?: () => Promise<any>;
  onClickConfirm?: (e: React.MouseEvent) => void;
  externalStyles?: Record<string, unknown>;
  ButtonComponent?: React.ElementType;
  mainButtonProps?: ButtonProps;
  children?: React.ReactNode;
}

const DialogConfirm = ({
  isShowMainButton,
  contentText,
  dialogTitle,
  buttonText,
  handleRequest,
  alertTextSuccess,
  alertTextError,
  onClickConfirm,
  externalStyles,
  ButtonComponent,
  mainButtonProps,
  children,
}: IProps): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<TTypeMessage>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleClickOk = async (e: React.MouseEvent) => {
    if (onClickConfirm) {
      onClickConfirm(e);
      handleCancel();
    }
    if (handleRequest) {
      setOpen(false);
      setUpdating(true);
      try {
        const response = await handleRequest();
        if (response.data.result === "success") {
          setSnackbarType("success");
        }
        return response;
      } catch (error) {
        setSnackbarType("error");
      } finally {
        setSnackbarOpen(true);
        setUpdating(false);
      }
    }

    return null;
  };

  const handleCloseSnackbar = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setTimeout(() => {
      setSnackbarType("");
    }, 500);
  };
  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {ButtonComponent && (
        <ButtonComponent style={{ ...externalStyles }} onClick={handleClickOpen} />
      )}
      {isShowMainButton && (
        <Button
          onClick={handleClickOpen}
          disabled={isUpdating}
          size="small"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            minWidth: 125,
            minHeight: 32,
            ...externalStyles,
          }}
          data-test="main-button"
          {...mainButtonProps}
        >
          {isUpdating ? <CircularProgress size={14} /> : buttonText}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText color="initial" id="alert-dialog-description">
            {contentText}
          </DialogContentText>
          {children}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} size="small" color="primary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleClickOk}
            size="small"
            color="secondary"
            autoFocus
            variant="contained"
            data-test="button-submit"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        type={snackbarType}
        isOpenSnackbar={snackbarOpen}
        responseSuccessMessage={alertTextSuccess as string}
        responseErrorMessage={alertTextError as string}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default DialogConfirm;

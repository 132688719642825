import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
  active: {
    color: "green",
  },
  expiredInactiveCodeBg: {
    backgroundColor: "#a6a3a3",
  },
});

export default useStyles;

import React from "react";
import { TableDataCellWithSort } from "../../../../components/ui";
import { ISortBy } from "../../../../types";

interface IProps {
  sortBy: ISortBy;
  handleSortBy: (by: string) => void;
}

const TableHead: React.FC<IProps> = ({ sortBy, handleSortBy }) => {
  return (
    <thead>
      <tr>
        <TableDataCellWithSort by="id" title="ID" sortBy={sortBy} newSortBy={handleSortBy} />
        <TableDataCellWithSort by="email" title="Email" sortBy={sortBy} newSortBy={handleSortBy} />
        <TableDataCellWithSort by="name" title="Name" sortBy={sortBy} newSortBy={handleSortBy} />
        <TableDataCellWithSort
          by="reason"
          title="Reason"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="measure"
          title="Measure"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="timestamp"
          title="Timestamp"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          by="measure_num"
          title="Measure num"
          sortBy={sortBy}
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHead;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ISortBy } from "../../types";
import usePagination from "../../hooks/usePagination";
import { EvaluationsActions } from "../../reducers/evaluationsReducer";
import { APIEvaluation } from "../../api";
import { IEvaluationsFilters, TEvaluationsSortBy } from "../../api/evaluations/index.types";
import { E_EVALUATIONS_STEPS } from "../../pages/Evaluations/constants";

export interface IEvaluationsContextProps {
  pagination: {
    count: number;
    limit: string;
    page: number;
    setLimit: (limit: string) => void;
    setPage: (page: number) => void;
  };
  activeTabIndex: number;
  onChangeActiveTab: (event: React.BaseSyntheticEvent, newValue: number) => void;
  sortBy: ISortBy<TEvaluationsSortBy>;
  setSortBy: (s: ISortBy<TEvaluationsSortBy>) => void;
  search: Partial<IEvaluationsFilters>;
  setSearch: (s: IEvaluationsFilters) => void;
  fetchAllEvaluations: () => void;
}
const EvaluationsContext = createContext<IEvaluationsContextProps>({
  pagination: {
    count: 0,
    limit: "20",
    page: 0,
    setLimit: () => ({}),
    setPage: () => ({}),
  },
  activeTabIndex: 0,
  onChangeActiveTab: () => ({}),
  sortBy: {
    by: "pnl",
    order: false,
  },
  setSortBy: () => ({}),
  search: {},
  setSearch: () => ({}),
  fetchAllEvaluations: () => ({}),
});

interface IProps {
  children: React.ReactNode;
}

const EvaluationsProvider: React.FC<IProps> = ({ children }) => {
  const {
    CLOSE_TO_PASSING_TAB_INDEX,
    PASSED_TAB_INDEX,
    CERTIFICATE_DESIGN_TAB_INDEX,
    CERTIFICATE_EMAIL_TAB_INDEX,
    WAITING_FOR_OFFER_TAB_INDEX,
    WELCOMING_PROCESS,
  } = E_EVALUATIONS_STEPS;
  const dispatch = useDispatch();
  const pagination = usePagination();

  const [activeTabIndex, setActiveTab] = useState(CLOSE_TO_PASSING_TAB_INDEX);
  const [search, setSearch] = useState<Partial<IEvaluationsFilters>>({});

  const [sortBy, setSortBy] = useState<ISortBy<TEvaluationsSortBy>>({
    by: "acc_id",
    order: false,
  });

  const onChangeActiveTab = (event: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  const fetchAllEvaluations = async (signal?: AbortSignal) => {
    try {
      dispatch(EvaluationsActions.fetchEvaluationsRequest());
      const { data } = await APIEvaluation.getAllEvaluationsRequest(
        {
          isShowPassedCustomers: PASSED_TAB_INDEX === activeTabIndex,
          isShowCertificatedDesign: CERTIFICATE_DESIGN_TAB_INDEX === activeTabIndex,
          isShowCertificateEmail: CERTIFICATE_EMAIL_TAB_INDEX === activeTabIndex,
          isShowWaitingForOffer: WAITING_FOR_OFFER_TAB_INDEX === activeTabIndex,
          isShowWelcomingProcess: WELCOMING_PROCESS === activeTabIndex,
          sortBy,
          limit: pagination.limit,
          page: pagination.page,
          contains: search,
        },
        signal
      );
      dispatch(EvaluationsActions.fetchEvaluationsSuccess(data));
      pagination.setCount(data.report[0]?.full_count);
    } catch (error) {
      dispatch(EvaluationsActions.fetchEvaluationsFailed());
      console.error("Error fetching evaluations request");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    (async () => {
      await fetchAllEvaluations(signal);
    })();

    return () => {
      controller.abort();
    };
  }, [activeTabIndex, sortBy, pagination.page, pagination.limit, search]);

  useEffect(() => {
    pagination.setPage(0);
  }, [search, activeTabIndex]);

  return (
    <EvaluationsContext.Provider
      value={{
        pagination,
        activeTabIndex,
        onChangeActiveTab,
        sortBy,
        setSortBy,
        search,
        setSearch,
        fetchAllEvaluations,
      }}
    >
      {children}
    </EvaluationsContext.Provider>
  );
};

export default EvaluationsProvider;
export const useEvaluationsContext = (): IEvaluationsContextProps => useContext(EvaluationsContext);

import React from "react";
import { RadioGroup, FormControl, Radio, FormControlLabel } from "@material-ui/core";
import { getIn } from "formik";

import { useStyles } from "./useStyles";
import ContexualHelp from "../ContexualHelp";
import { InputMessage } from "../index";

interface IProps {
  label: string;
  field: {
    value: string;
    name: string;
  };
  form: {
    errors: Record<string, string>;
    touched: boolean;
    setFieldValue: (path: string, value: string | number | boolean) => void;
  };
  contextualHelpText: string;
  contextualHelpProps: Record<string, unknown>;
  isDisabled?: boolean;
  options: [
    {
      key: string | number;
      value: string | number | boolean;
      label: string;
    }
  ];
}

const FormRadioGroup: React.FC<IProps> = ({
  label,
  field,
  form: { errors, setFieldValue },
  options,
  contextualHelpText,
  contextualHelpProps = {},
  isDisabled,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue(field.name, event.target.value);

  const errorMessage = getIn(errors, field.name);
  const hasError = errorMessage && getIn(errors, field.name);

  return (
    <FormControl error={!!hasError} component="fieldset">
      <ContexualHelp title={contextualHelpText} {...contextualHelpProps}>
        <RadioGroup
          aria-label={label}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          row={true}
        >
          {label}
          <div>
            {options.map((item) => (
              <FormControlLabel
                disabled={isDisabled}
                key={item.key}
                value={item.value}
                label={item.label}
                classes={{ root: classes.root }}
                control={<Radio color="primary" />}
              />
            ))}
          </div>
        </RadioGroup>
      </ContexualHelp>

      {hasError && <InputMessage error={errorMessage} />}
    </FormControl>
  );
};

export default FormRadioGroup;

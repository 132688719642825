import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { useAdminProviderDataContext, useSearchCustomersContext } from "../../../../context";
import useStyles from "../../useStyles";

const MainFilters: React.FC = () => {
  const classes = useStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const {
    isShowActiveSubscription,
    isShowLeads,
    setIsShowActiveSubscription,
    setIsShowLeads,
    isShowInactiveAccounts,
    setShowInactiveAccounts,
    isShowPassedEvaluations,
    setIsShowPassedEvaluations,
  } = useSearchCustomersContext();

  return (
    <div className={classes.filtersContainer}>
      {allowed_features.show_inactive_accounts && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isShowInactiveAccounts}
              onChange={() => setShowInactiveAccounts(!isShowInactiveAccounts)}
              name="inactiveAccounts"
              color="primary"
            />
          }
          label="Show inactive accounts"
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={isShowActiveSubscription}
            onChange={() => setIsShowActiveSubscription(!isShowActiveSubscription)}
            name="inactive"
            color="primary"
          />
        }
        label="Show active subscription"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isShowLeads}
            onChange={() => setIsShowLeads(!isShowLeads)}
            name="inactive"
            color="primary"
          />
        }
        label="Show Leads"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isShowPassedEvaluations}
            onChange={() => setIsShowPassedEvaluations(!isShowPassedEvaluations)}
            name="passed_evaluations"
            color="primary"
          />
        }
        label="Show passed evaluations"
      />
    </div>
  );
};

export default MainFilters;

import React, { useState } from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";

import TabPanel from "../../components/ui/TabPanel";
import Customers from "./Customers";
import Monitoring from "./components/Monitoring";
// import IpMonitoring from "./components/IpMonitoring";

import useGeneralStyles from "../../useGeneralStyles";

const CustomersTabs = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const [activeTabIndex, setActiveTab] = useState(0);
  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  return (
    <div>
      <div>
        <Paper square>
          <Tabs value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab label="Customers" value={0} />
            <Tab label="Monitoring" value={1} />
            {/*<Tab label="IP Monitoring" value={2} />*/}
          </Tabs>
        </Paper>
      </div>

      <div className={generalClasses.containerBg}>
        <TabPanel value={activeTabIndex} index={0}>
          <Customers />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <Monitoring />
        </TabPanel>
      </div>
    </div>
  );
};

export default CustomersTabs;

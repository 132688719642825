import React from "react";

const Admin = ({
  w,
  h,
  m,
}: {
  w?: string | number;
  h?: string | number;
  m?: string | number;
}): React.ReactElement => (
  <svg
    height={h || w || "300px"}
    width={w || "300px"}
    style={{ marginBottom: m || 0, marginRight: 10 }}
    viewBox="0 0 14 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard-Copy-4" transform="translate(-28.000000, -9.000000)" fill="#000000">
        <g id="Group-Copy-3" transform="translate(28.000000, 9.000000)">
          <circle id="Oval" cx="7" cy="4" r="4" />
          <path
            d="M7,9 C10.8659932,9 14,12.1658249 14,16.0710678 C14,19.9763107 0,19.9763107 0,16.0710678 C0,12.1658249 3.13400675,9 7,9 Z M7,10 C6.44771525,10 6,10.4477153 6,11 C6,11.4751325 6.33136389,11.8728707 6.77443591,11.9756584 C6.2581453,14.2599718 6,15.8028131 6,16.6041824 C6,17.9701211 6.65316492,18 7,18 C7.34683508,18 8,17.9701211 8,16.6041824 C8,15.8028131 7.7418547,14.2599718 7.22439934,11.9747238 C7.66863611,11.8728707 8,11.4751325 8,11 C8,10.4477153 7.55228475,10 7,10 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Admin;

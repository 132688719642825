import React from "react";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, ListItemText, List, ListItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 280,
    padding: 0,
  },
  listItemButton: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  countOfCollapsedAccounts: {
    display: "flex",
    alignItems: "center",
  },
});

interface IProps {
  accounts: string;
}
const E2TAccountIDsList = ({ accounts }: IProps): React.ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const accountsList = accounts.split(", ");
  if (accountsList.length > 1) {
    return (
      <List component="nav" className={classes.root}>
        <ListItem button onClick={handleClick} className={classes.listItemButton}>
          <ListItemText primary={accountsList[0]} data-test="latest-e2t-account" />
          {open ? (
            <ExpandLess />
          ) : (
            <span className={classes.countOfCollapsedAccounts}>
              <ExpandMore /> <span data-test="expand-more">+{accountsList.length - 1}</span>
            </span>
          )}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {accountsList.slice(1).map((a) => (
              <ListItem key={a}>
                <ListItemText primary={a} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    );
  }
  return (
    <List component="div" disablePadding>
      <ListItem>
        <ListItemText primary={accountsList} />
      </ListItem>
    </List>
  );
};

export default E2TAccountIDsList;

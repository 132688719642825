import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  sectionTitle: {
    margin: "0 0 5px 0",
  },
  countriesContainer: {
    display: "grid",
    gridTemplateColumns: "50% auto auto",
  },
  locationRow: {
    position: "relative",
    borderBottom: "1px solid #e5e5e5",
    padding: "3px 0px 3px 0px",
  },
  locationRowProgress: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    zIndex: -1,
    backgroundColor: "#cde2f1",
  },
});

export default useStyles;

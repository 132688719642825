import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import { PAYMENTS_STATUSES } from "../../../../constants";
import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "./useStyles";

import { IStripeChargeInfo } from "../../../../api/stripe/index.types";
import { IntlFormatUSD } from "../../../../utils";
import { getTimeByAmericaChicagoTz } from "../../../../utils/moment";

interface IPros {
  groupBy: string;
}

const TableBody = ({ groupBy }: IPros): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const history = useHistory();
  const { payments, retrievedCharges } = useSelector((state) => state.feed.data.failedPayments);

  const handleClick = (accounts_id: number) => history.push(`/customer/${accounts_id}`);
  return (
    <tbody>
      {payments.map((payment, index) => {
        const retrievedData = retrievedCharges[index];
        const { status, stripe_ch } = payment;
        const { all, fraud, chargeback, failed } = PAYMENTS_STATUSES;
        const stripeData: IStripeChargeInfo | undefined = retrievedCharges.find(
          ({ id }) => id === stripe_ch
        );

        return (
          <tr
            key={`${payment.id}_${stripe_ch}`}
            onClick={() => handleClick(payment.accounts_id)}
            className={generalClasses.tableHover}
            data-test="payment-status-row"
          >
            <td>
              {getTimeByAmericaChicagoTz(payment.purchase_date, true)}
              CT
            </td>
            <td>{payment.price_usd ? IntlFormatUSD(payment.price_usd / 100) : "n/a"}</td>
            <td>{payment.accounts_id}</td>
            <td>{payment.account.name}</td>
            <td>{payment.account.email}</td>
            <td>{retrievedData ? retrievedData.object : "n/a"}</td>
            <td>{payment.plan.name || "n/a"}</td>
            <td>{stripeData ? stripeData.failure_code : "n/a"}</td>
            <td>{stripeData ? stripeData.failure_message : "n/a"}</td>
            <td>{stripeData ? stripeData.outcome.seller_message : "n/a"}</td>

            {groupBy === all.status && (
              <td>
                <span
                  className={cx(
                    classes.withStatus,
                    { [generalClasses.fraudBg]: status === fraud.status },
                    { [generalClasses.chargebackBg]: status === chargeback.status },
                    { [generalClasses.failedBg]: status === failed.status }
                  )}
                >
                  {status}
                </span>
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React from "react";

import { TBy } from "../../../../reducers/customersReducer";
import newSortBy from "../../../../utils/newSortBy";
import TableDataCellWithSort from "../../../../components/ui/TableDataCellWithSort";
import { useRithmicAccountsContext } from "../../../../context";

const TableHeader = (): React.ReactElement => {
  const { sortBy, setSortBy } = useRithmicAccountsContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        <td>Id</td>
        <TableDataCellWithSort sortBy={sortBy} by="email" title="Email" newSortBy={handleSortBy} />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="accounts"
          title="E2T Accounts"
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHeader;

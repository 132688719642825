import React, { useState } from "react";
import { Tab, Tabs, styled, Grid } from "@material-ui/core";

import cx from "classnames";

import PieChart from "./componets/PieChart";
import { ContainerWithLoader, SelectByDateWithCustomPeriod } from "../../../../components/ui";
import BitPayChargesUSvsInternational from "../BitPayChargesUSvsInternational";

import { getReportValues } from "./utils";
import { IntlFormatUSD } from "../../../../utils";
import useGeneralStyles from "../../../../useGeneralStyles";
import { APIBilling } from "../../../../api";
import { INetSalesValuesReport } from "../../../../api/billing/index.types";
import useStyles from "./useStyles";

export enum NET_SALES_CALCULATOR_TAB_INDEXES {
  combined_sales = "combined_sales",
  us_sales = "us_sales",
  international_sales = "international_sales",
}

const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  padding: "3px 6px",
  minWidth: "auto",
  minHeight: 38,
}));

const NetSalesCalculator = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const [activeTabIndex, setActiveTab] = useState(NET_SALES_CALCULATOR_TAB_INDEXES.combined_sales);
  const [report, setReport] = useState<INetSalesValuesReport>({
    us_stripe_total: 0,
    not_us_stripe_total: 0,
    us_stripe_total_ref_and_char: 0,
    not_us_stripe_total_ref_and_char: 0,
    us_paypal_total: 0,
    not_us_paypal_total: 0,
    paypal_total_ref_and_char: 0,
    not_us_bitpay_total_gross: 0,
    not_us_bitpay_total_net: 0,
    not_us_bitpay_total_ref_and_char: 0,
    not_us_cryptocurrency: {},
    us_cryptocurrency: {},
    us_bridger_pay_total: 0,
    not_us_us_bridger_pay_total_total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState(["", ""]);
  const getReport = async (
    startDate: string,
    endDate: string,
    isAllTime = false,
    reportType: NET_SALES_CALCULATOR_TAB_INDEXES = activeTabIndex
  ) => {
    setCurrentPeriod([startDate, endDate]);

    setIsLoading(true);
    try {
      const response = await APIBilling.getNetSalesValuesRequest(startDate, endDate, reportType);
      setReport(response.data.report);
    } catch (error) {
      console.error("error getting NetSalesValuesRequest", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isCombinedSales = activeTabIndex === "combined_sales";

  const onChangeActiveTab = (
    event: React.BaseSyntheticEvent,
    newValue: NET_SALES_CALCULATOR_TAB_INDEXES
  ) => {
    setActiveTab(newValue);
    getReport(currentPeriod[0], currentPeriod[1], false, newValue);
  };

  const {
    us_total = 0,
    international_total = 0,
    totalNet,
    sumRefAndChar,
    paypalNetTotal,
    payPalGrossTotal,
    stripeNetTotal,
    stripeGrossTotal,
    bridgerPayGrossTotal,
    bridgerPayNetTotal,
  } = getReportValues(report, activeTabIndex);

  return (
    <div>
      <h2>Net sales calculator</h2>
      <SelectByDateWithCustomPeriod
        setIsLoading={setIsLoading}
        getReport={getReport}
        setReport={setReport}
      />

      <div className={cx(generalClasses.containerBg, classes.container)}>
        <Tabs value={activeTabIndex} onChange={onChangeActiveTab} centered>
          <StyledTab
            value={NET_SALES_CALCULATOR_TAB_INDEXES.combined_sales}
            label="Combined sales"
          />
          <StyledTab value={NET_SALES_CALCULATOR_TAB_INDEXES.us_sales} label="US sales" />
          <StyledTab
            value={NET_SALES_CALCULATOR_TAB_INDEXES.international_sales}
            label="international sales"
          />
        </Tabs>

        <ContainerWithLoader isLoading={isLoading} report={[report]}>
          <Grid container>
            <Grid item xs={4}>
              <div>
                <ul className={classes.ul}>
                  <li>
                    Stripe payments total gross{" "}
                    <span data-test="stripe-total-gross">
                      {IntlFormatUSD(stripeGrossTotal / 100)}
                    </span>
                  </li>
                  <li>
                    Stripe payments total Net{" "}
                    <span data-test="stripe-total-net">{IntlFormatUSD(stripeNetTotal / 100)}</span>
                  </li>
                  <hr />
                  <li>
                    Paypal payments total gross{" "}
                    <span data-test="paypal-total-gross">
                      {IntlFormatUSD(payPalGrossTotal / 100)}
                    </span>
                  </li>
                  <li>
                    Paypal payments total Net{" "}
                    <span data-test="paypal-total-net">{IntlFormatUSD(paypalNetTotal / 100)}</span>
                  </li>
                  <hr />
                  <li>
                    BitPay payments total gross{" "}
                    <span data-test="not_us_bitpay_total_gross">
                      {IntlFormatUSD(report.not_us_bitpay_total_gross / 100)}
                    </span>
                  </li>
                  <li>
                    BitPay payments total Net{" "}
                    <span data-test="not_us_bitpay_total_net">
                      {IntlFormatUSD(report.not_us_bitpay_total_net / 100)}
                    </span>
                  </li>
                  <hr />
                  <li>
                    BridgerPay payments total gross{" "}
                    <span>{IntlFormatUSD(bridgerPayGrossTotal / 100)}</span>
                  </li>
                  <li>
                    BridgerPay payments total Net{" "}
                    <span>{IntlFormatUSD(bridgerPayNetTotal / 100)}</span>
                  </li>
                  <hr />
                  <li>
                    Sum of refunds and chargebacks{" "}
                    <span data-test="refunds-chargebacks">
                      -{IntlFormatUSD(sumRefAndChar / 100)}
                    </span>
                  </li>
                  <li>
                    Total Net <span data-test="total-net">{IntlFormatUSD(totalNet / 100)}</span>
                  </li>
                </ul>
              </div>
            </Grid>

            <Grid item xs={4}>
              {isCombinedSales && (
                <div className={classes.chartsContainer}>
                  <PieChart us_total={us_total} international_total={international_total} />
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <BitPayChargesUSvsInternational
                not_us_cryptocurrency={report.not_us_cryptocurrency}
                us_cryptocurrency={report.us_cryptocurrency}
              />
            </Grid>
          </Grid>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default NetSalesCalculator;

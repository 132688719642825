//@ts-nocheck
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";

import LineChart, { IDataLineChart } from "../../../../components/ui/ChartLine";
import { ContainerWithLoader } from "../../../../components/ui";

import { DATE_FORMAT } from "../../../../constants";
import { useIntercomContext } from "../../../../context";
import { APIRithmic } from "../../../../api";
import { ITRadeResult } from "../../../../api/rithmic/index.types";

import useStyles from "./useStyles";

const ChartBalance = (): React.ReactElement => {
  const classes = useStyles();
  const { gauntletAccountName = "" } = useIntercomContext();
  const { plans_rules } = useSelector((state) => state.plans.data);

  const [userTradedSymbols, setUserTradedSymbols] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [tradesData, setTradesData] = useState<ITRadeResult[]>([]);
  const [activeSymbol, setActiveSymbol] = useState<string>("all");
  const [barData, setBarData] = useState<IDataLineChart>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });

  const handleShowExternalColumns = (event: React.ChangeEvent<{ value: unknown }>) =>
    setActiveSymbol(event.target.value as string);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await APIRithmic.getRithmicUserBalanceOverTimeRequest(
          gauntletAccountName,
          activeSymbol
        );
        const { symbols, trades } = data;
        setUserTradedSymbols(symbols);
        setTradesData(trades);
      } catch (error) {
        console.error("Error fetch chart data");
      } finally {
        setLoading(false);
      }
    })();
  }, [activeSymbol]);

  useEffect(() => {
    const labels: string[] = [];
    const dataBalance: number[] = [];

    const plan = plans_rules.find(({ plans_key }) =>
      gauntletAccountName.includes(plans_key.toLowerCase())
    );

    if (plan) {
      let starting_capital = plan.starting_capital;

      tradesData.forEach(({ tradeDate, netChange }) => {
        labels.push(moment(tradeDate.toString()).format(DATE_FORMAT));
        dataBalance.push((starting_capital += netChange));
      });
    }

    const data = {
      labels,
      datasets: [
        {
          label: "Balance",
          data: dataBalance,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
    setBarData(data);
  }, [plans_rules, tradesData]);

  return (
    <div>
      <ContainerWithLoader
        isLoading={loading}
        report={userTradedSymbols}
        noResultsMessage="There are no trades"
      >
        <div>
          <FormControl className={classes.select}>
            <InputLabel id="demo-simple-select-label">Symbol</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={activeSymbol}
              onChange={handleShowExternalColumns}
            >
              <MenuItem value="all">All</MenuItem>
              {userTradedSymbols.map((symbol) => (
                <MenuItem key={symbol} value={symbol}>
                  {symbol}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <LineChart
          data={barData}
          externalOptions={{
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </ContainerWithLoader>
    </div>
  );
};

export default ChartBalance;

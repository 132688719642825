import React from "react";

import { useDiscountCodesLogsContext } from "../../../../../context";
import TableDataCellWithSort from "../../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";

const TableHeader = (): React.ReactElement => {
  const { sortBy, setSortBy } = useDiscountCodesLogsContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        <TableDataCellWithSort
          sortBy={sortBy}
          by="code"
          title="Code"
          newSortBy={handleSortBy}
          isReset={sortBy.by !== "code" || sortBy.order}
        />
        <TableDataCellWithSort sortBy={sortBy} by="admin" title="Admin" newSortBy={handleSortBy} />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="field_name"
          title="Filed"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="previous_field_value"
          title="Previous value"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="new_field_value"
          title="New value"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="log_time"
          title="Date"
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHeader;

import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import cx from "classnames";

import { getTimeByAmericaChicagoTz } from "../../../../../utils/moment";
import { IntlFormatUSD } from "../../../../../utils";
import {
  getAmountToBePay,
  getNewAccountBalance,
} from "../../WithdrawalRequestFormWithRequestsList/utils";
import {
  IWithdrawalWithAccountInfo,
  TCompletedStatuses,
} from "../../../../../api/funds/index.types";
import useStyles from "./useStyles";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { DialogConfirm } from "../../../../Customer/components";
import { useAdminProviderDataContext } from "../../../../../context";
import { CENTS_IN_DOLLAR } from "../../../../../constants";

interface IProps {
  withdrawals: IWithdrawalWithAccountInfo[];
  handleChangeWithdrawalStatus: (
    e: React.MouseEvent,
    id: number,
    status: TCompletedStatuses
  ) => void;
  handleEditWithdrawal: (withdrawal: IWithdrawalWithAccountInfo) => void;
}
const EDITABLE_STATUSES = ["pending", "on hold"];
const TableBody: React.FC<IProps> = ({
  withdrawals,
  handleChangeWithdrawalStatus,
  handleEditWithdrawal,
}) => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { id } = useParams();
  const isShowCustomerInfoColumn = !id;

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  return (
    <tbody>
      {withdrawals.map((withdrawal) => {
        const isEditable = EDITABLE_STATUSES.includes(withdrawal.status);
        const {
          manual_withdrawal_amount,
          changed_status_time,
          status,
          comment,
          manual_deduction,
          watermark_level,
          account_balance,
          amount_requested,
          initialization_date,
          id,
        } = withdrawal;
        const {
          amountToPay,
          fee: { regular_deduction_rate, conveniences_fees, sum },
        } = getAmountToBePay(withdrawal);
        return (
          <tr
            key={id}
            onClick={() => isEditable && handleEditWithdrawal(withdrawal)}
            className={cx({
              [generalClasses.tableHover]: isEditable,
              [classes.pendingColor]: isEditable,
              [classes.disableHover]: !isEditable,
            })}
          >
            <td>
              <span className={classes.noWrap}>
                {getTimeByAmericaChicagoTz(initialization_date, true)}
              </span>{" "}
              CT
            </td>
            {isShowCustomerInfoColumn && (
              <>
                <td>{withdrawal.account.name}</td>
                <td>{withdrawal.account.email}</td>
              </>
            )}
            <td>{withdrawal.e2t_account_id}</td>
            <td>
              {IntlFormatUSD((manual_withdrawal_amount || amount_requested) / CENTS_IN_DOLLAR)}
            </td>
            <td>{IntlFormatUSD(account_balance / CENTS_IN_DOLLAR)}</td>
            <td>{IntlFormatUSD(watermark_level)}</td>
            <td>{IntlFormatUSD(getNewAccountBalance(withdrawal) / CENTS_IN_DOLLAR)}</td>
            <td>{manual_deduction / CENTS_IN_DOLLAR}</td>
            <td className={classes.noWrap}>
              <p>Regular deduct {IntlFormatUSD(regular_deduction_rate / CENTS_IN_DOLLAR)}</p>
              <p>Convenience Fee: {IntlFormatUSD(conveniences_fees)}</p>
              <p>Sum: {IntlFormatUSD(sum / CENTS_IN_DOLLAR)}</p>
            </td>
            <td>{comment}</td>
            <td>{IntlFormatUSD((manual_withdrawal_amount || amountToPay) / CENTS_IN_DOLLAR)}</td>
            <td>{changed_status_time && getTimeByAmericaChicagoTz(changed_status_time, true)}</td>
            <td>
              <span className={classes.actionsContainer}>
                <p>{status !== "pending" && status}</p>
                {status === "pending" && (
                  <span className={classes.buttonsContainer}>
                    {allowed_features.access_withdrawals && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => handleChangeWithdrawalStatus(e, withdrawal.id, "paid")}
                      >
                        Paid
                      </Button>
                    )}

                    <DialogConfirm
                      isShowMainButton
                      dialogTitle="Delete the withdrawal"
                      contentText="This will delete the withdrawal request. Are you sure?"
                      buttonText="Decline"
                      onClickConfirm={(e: React.MouseEvent) => {
                        handleChangeWithdrawalStatus(e, withdrawal.id, "canceled");
                      }}
                      externalStyles={{
                        backgroundColor: "#e53935",
                        minWidth: 75,
                        textTransform: "uppercase",
                      }}
                    />
                  </span>
                )}
              </span>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

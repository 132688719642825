import React from "react";

import { Pie } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
  height?: number;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
  options: {
    maintainAspectRatio?: boolean;
    plugins: { [key: string]: unknown };
  };
}

const PieChart: React.FC<IProps> = ({ data, options, height = 200 }) => (
  <Pie height={height} data={data} options={options} />
);

export default PieChart;

/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  FundedTraderFilesAction,
  IFundedTraderFilesState,
  FundedTraderFilesActionType,
} from "./index.types";

const initialState: IFundedTraderFilesState = {
  isLoading: false,
  listOfUploadedFiles: [],
};

const fundedTraderFilesReducer = (
  prevState = initialState,
  action: FundedTraderFilesAction
): IFundedTraderFilesState =>
  produce(prevState, (draft: IFundedTraderFilesState) => {
    switch (action.type) {
      case FundedTraderFilesActionType.fundedTraderFilesFetchRequest:
        draft.isLoading = true;
        break;
      case FundedTraderFilesActionType.fundedTraderFilesFetchSuccess:
        draft.isLoading = false;
        draft.listOfUploadedFiles = action.payload.listOfUploadedFiles;
        break;
      case FundedTraderFilesActionType.fundedTraderFilesFetchFailed:
        draft.isLoading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default fundedTraderFilesReducer;

import React, { createContext, useContext, useState } from "react";
import { APIIntercom } from "../../api";
import { IIntercomContact } from "../../api/intercom/index.types";
import { getCustomAttributes } from "./utils";

interface IProps {
  children: React.ReactNode;
}
interface IIntercomProps {
  contact: IIntercomContact;
  setContact: (contact: IIntercomContact) => void;
  gauntletAccountName: string;
  isLoading: boolean;
  getIntercomContact: (id: string) => void;
}

const IntercomContext = createContext<IIntercomProps>({
  contact: {
    role: "",
    external_id: "",
    email: "",
    phone: "",
    name: "",
    avatar: "",
    owner_id: "",
    signed_up_at: "",
    last_seen_at: "",
    unsubscribed_from_emails: false,
    id: "-1",
    custom_attributes: {},
  },
  setContact: () => null,
  gauntletAccountName: "",
  isLoading: true,
  getIntercomContact: () => null,
});

const IntercomProvider = ({ children }: IProps): React.ReactElement => {
  const [isLoading, setLoading] = useState(true);
  const [contact, setContact] = useState<IIntercomContact>({
    role: "",
    external_id: "",
    email: "",
    phone: "",
    name: "",
    avatar: "",
    owner_id: "",
    signed_up_at: "",
    last_seen_at: "",
    unsubscribed_from_emails: false,
    id: "-1",
    custom_attributes: {},
  });
  const { custom_attributes } = contact;
  const gauntletAccountName = custom_attributes["Gauntlet Account Name"];

  const getIntercomContact = async (id: string) => {
    if (id) {
      try {
        setLoading(true);
        const response = await APIIntercom.getIntercomContactRequest(id);
        const { custom_attributes } = response.data;
        response.data.custom_attributes = getCustomAttributes(custom_attributes);
        setContact(response.data);
      } catch (error) {
        console.error("error retrieving Intercom values", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <IntercomContext.Provider
      value={{
        contact,
        setContact,
        gauntletAccountName,
        isLoading,
        getIntercomContact,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export default IntercomProvider;
export const useIntercomContext = (): IIntercomProps => useContext(IntercomContext);

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  error: {
    fontSize: "12px",
    position: "relative",
    "& p": {
      lineHeight: "1",
      margin: "0",
      color: theme.palette.secondary.main,
    },
    "& svg": {
      marginRight: "5px",
    },
  },
  waring: {
    fontSize: "12px",
    position: "relative",
    "& p": {
      lineHeight: "1",
      margin: "0",
      color: theme.palette.warning.main,
    },
    "& svg": {
      marginRight: "5px",
    },
  },
  errorContainer: {
    marginTop: "1px",
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;

import React, { useState } from "react";
import moment from "moment-timezone";
import { Grid, IconButton } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Delete, CheckCircle, Cancel } from "@material-ui/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import useStyles from "./useStyles";
import usePagination from "../../../../hooks/usePagination";
import { IAgentNote } from "../../../../api/agents/index.types";
import { AgentActions } from "../../../../reducers/agentReducer/actions";
import { AGENTS_API_URLS, AgentsAPI } from "../../../../api/agents";
import { AGENT_NOTE_VALIDATION_SCHEMA } from "../../constants";

import CreateNoteForm from "../CreateNoteForm";
import { ContainerWithLoader, PaginationWithLimit } from "../../../../components/ui";
import { FormInput, FormCheckbox, ConfirmDialog } from "../../../../components/ui";

const AgentNotes = (): React.ReactElement => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const classes = useStyles();
  const pagination = usePagination("5");

  const agent = useSelector((state) => state.agent);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(-1);

  const handleUpdateNote = useMutation({
    mutationKey: [`patch/${AGENTS_API_URLS.agentsNotes}`, agent.data.id],
    mutationFn: async (values: IAgentNote) => {
      return await AgentsAPI.updateAgentNote(values.id, values);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          `get/${AGENTS_API_URLS.agentsNotes}`,
          agent.data.id,
          pagination.page,
          pagination.limit,
        ],
      });
    },
  });
  const handleDeleteNote = useMutation({
    mutationKey: [`delete/${AGENTS_API_URLS.agentsNotes}`, agent.data.id],
    mutationFn: async (noteId: number) => {
      await AgentsAPI.deleteAgentNote(agent.data.id, noteId);
    },
    onSuccess: () => {
      setIsOpenConfirmDialog(false);
      queryClient.invalidateQueries({
        queryKey: [
          `get/${AGENTS_API_URLS.agentsNotes}`,
          agent.data.id,
          pagination.page,
          pagination.limit,
        ],
      });
    },
  });

  useQuery({
    enabled: !!agent.data.id,
    queryKey: [
      `get/${AGENTS_API_URLS.agentsNotes}`,
      agent.data.id,
      pagination.page,
      pagination.limit,
    ],
    queryFn: async () => {
      if (agent.data.id) {
        dispatch(AgentActions.fetchAgentNotesRequest());
        const response = await AgentsAPI.getAgentNotes(
          agent.data.id,
          pagination.page,
          pagination.limit
        );
        dispatch(AgentActions.fetchAgentNotesSuccess(response));
        return response;
      }
    },
    onError: (error) => {
      dispatch(AgentActions.fetchAgentNotesFailed());
      console.error("Error fetching agents notes", error);
    },
  });

  return (
    <div className={classes.container}>
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        dialogTitle="Delete note"
        dialogContentText="Are you sure you want to delete this note?"
        handleCancel={() => {
          setIsOpenConfirmDialog(false);
          setSelectedNote(-1);
        }}
        handleClickOk={() => handleDeleteNote.mutate(selectedNote)}
      />

      <CreateNoteForm pagination={{ page: pagination.page, limit: pagination.limit }} />

      <ContainerWithLoader
        report={agent.data.admin_accounts_notes.rows}
        isLoading={agent.data.admin_accounts_notes.loading}
        noResultsMessage="There are no notes"
      >
        <div className={classes.tableBorder}>
          {agent.data.admin_accounts_notes.rows.map((data) => {
            const { id, create_date, admin_account } = data;
            return (
              <div key={id} className={classes.createdNoteContainer}>
                <Formik
                  enableReinitialize
                  validateOnChange
                  validationSchema={AGENT_NOTE_VALIDATION_SCHEMA}
                  onSubmit={(values) => handleUpdateNote.mutate(values)}
                  initialValues={data}
                >
                  {({ dirty, isValid, submitForm, handleReset }) => {
                    return (
                      <Form>
                        <Grid container>
                          <Grid item xs={2}>
                            <div className={classes.activeContainer}>
                              <IconButton
                                onClick={() => {
                                  setIsOpenConfirmDialog(true);
                                  setSelectedNote(id);
                                }}
                              >
                                <Delete color="secondary" />
                              </IconButton>
                              <Field
                                name="is_active"
                                label="Active"
                                color="primary"
                                component={FormCheckbox}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} className={classes.noteValueContainer}>
                            <Field
                              name="note"
                              placeholder="Note"
                              size="small"
                              component={FormInput}
                              errorContainerClassName={classes.errorContainerEditNote}
                              multiline
                            />
                            <div className={classes.actionsChangeContainer}>
                              {dirty && isValid && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    submitForm();
                                  }}
                                  size="small"
                                >
                                  <CheckCircle color="primary" />
                                </IconButton>
                              )}
                              {dirty && (
                                <IconButton size="small" onClick={() => handleReset()}>
                                  <Cancel color="secondary" />
                                </IconButton>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={classes.centered}>
                              {moment(create_date).tz("America/Chicago").format("YYYY-MM-DD HH:mm")}
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={classes.centered}>
                              {admin_account
                                ? `${admin_account?.name}(${admin_account?.id})`
                                : "Internal"}
                            </div>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            );
          })}
        </div>
      </ContainerWithLoader>

      <PaginationWithLimit
        count={agent.data.admin_accounts_notes.count}
        limit={pagination.limit.toString()}
        page={pagination.page}
        setPageState={pagination.setPage}
        setLimit={pagination.setLimit}
      />
    </div>
  );
};

export default AgentNotes;

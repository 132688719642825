export interface ICustomAttributes {
  [key: string]: unknown;
}
export const getCustomAttributes = (custom_attributes: ICustomAttributes): ICustomAttributes => {
  return {
    city: custom_attributes.city,
    state: custom_attributes.state,
    est_days_traded: custom_attributes.est_days_traded,
    gauntlet_balance: custom_attributes.gauntlet_balance,
    largest_pnl: custom_attributes.largest_pnl,
    largest_pnl_date: custom_attributes.largest_pnl_date,
    largest_pnl_perc: custom_attributes.largest_pnl_perc,
    fail_reason: custom_attributes.fail_reason,
    partner: custom_attributes.partner,
    Journalytix: custom_attributes.Journalytix || false,
  };
};

import React, { useState } from "react";
import { filesize } from "filesize";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@material-ui/core";
import cx from "classnames";

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";

import { ContainerWithLoader, ConfirmDialog } from "../../../../components/ui";
import { TMimeType } from "../../../../api/googleDrive/index.types";
import { DATE_FORMAT_hhmmAzz } from "../../../../constants";
import useStyles from "./useStyles";
import { G_DRIVE_API_URLS, GoogleDriveAPI } from "../../../../api/googleDrive";
import { useAdminProviderDataContext } from "../../../../context";

const getIconByType = (type: TMimeType): React.ReactElement => {
  if (type === "application/pdf") {
    return <PictureAsPdfIcon />;
  }
  return <ImageIcon />;
};
const UploadedFilesList = (): React.ReactElement => {
  const { id } = useParams();
  const classes = useStyles();
  const queryClient = useQueryClient();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [fileToDelete, setFileToDelete] = useState("");
  const [fileNameToDelete, setFileNameToDelete] = useState("");

  const { isLoading, listOfUploadedFiles } = useSelector((state) => state.fundedTraderFiles);

  const uploadedFilesSumSize = listOfUploadedFiles.reduce((acc, { size }) => acc + +size, 0);

  const handleDeleteFile = useMutation({
    mutationKey: [G_DRIVE_API_URLS.deleteCustomerFile, id],
    mutationFn: async ({
      fileToDelete,
      fileNameToDelete,
    }: {
      fileToDelete: string;
      fileNameToDelete: string;
    }) => {
      await GoogleDriveAPI.deleteCustomerFile(id, {
        fileId: fileToDelete,
        fileName: fileNameToDelete,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [G_DRIVE_API_URLS.getCustomerFiles, id] });
    },
  });

  const handleCancelDeleteFile = () => setFileToDelete("");
  const handleConfirmDeleteFile = () => {
    setFileToDelete("");
    setFileNameToDelete("");
    handleDeleteFile.mutate({ fileToDelete, fileNameToDelete });
  };
  return (
    <div>
      <h4 className={classes.title}>Uploaded files</h4>
      <div>
        <ContainerWithLoader
          isLoading={isLoading}
          report={listOfUploadedFiles}
          noResultsMessage="There aren't uploaded files"
        >
          <div>
            <ConfirmDialog
              isOpen={!!fileToDelete}
              dialogTitle="Delete uploaded file"
              dialogContentText="Are you sure you want to delete uploaded file?"
              handleClickOk={handleConfirmDeleteFile}
              handleCancel={handleCancelDeleteFile}
            />
            <List>
              {listOfUploadedFiles.map(
                ({ id, name, size, mimeType, modifiedTime, appProperties }) => {
                  const uploadTime = `Uploaded at: ${moment(modifiedTime)
                    .tz("America/Chicago")
                    .format(DATE_FORMAT_hhmmAzz)}`;

                  const FileInfo = (
                    <>
                      {uploadTime}{" "}
                      {appProperties?.agentId && (
                        <Link to={`/agent/${appProperties.agentId}`}>
                          By {appProperties.agentName}.
                        </Link>
                      )}{" "}
                      Size: {filesize(+size)}
                    </>
                  );

                  return (
                    <ListItem key={id}>
                      <ListItemAvatar>
                        <div className={classes.iconsContainer}>
                          <Avatar>{getIconByType(mimeType)}</Avatar>
                          {allowed_features.access_delete_uploaded_files && (
                            <>
                              {id === handleDeleteFile?.variables?.fileToDelete ? (
                                <IconButton className={classes.deleteIcon}>
                                  <CircularProgress color="secondary" size={20} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  disabled={handleDeleteFile.isLoading}
                                  className={cx(classes.deleteIcon, classes.deletingIcon)}
                                  color="secondary"
                                  onClick={() => {
                                    setFileToDelete(id);
                                    setFileNameToDelete(name);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </div>
                      </ListItemAvatar>
                      <ListItemText primary={name} secondary={FileInfo} />
                    </ListItem>
                  );
                }
              )}
            </List>
          </div>
          <div>
            Uploaded {listOfUploadedFiles.length} files, size: {filesize(uploadedFilesSumSize)}
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default UploadedFilesList;

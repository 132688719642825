import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    position: "relative",
    zIndex: 2,
  },
  banButton: {
    textTransform: "none",
    minWidth: 125,
    minHeight: 32,
    position: "absolute",
    right: 0,
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
});

export default useStyles;

import React, { useEffect, useState } from "react";

import { useAdminProviderDataContext } from "../../../../context";
import { EditableContainerWithActions, SearchAutocomplete } from "../../../../components/ui";
import { APIDiscounts } from "../../../../api";
import { IUserEmailWithId } from "../../../../api/discounts/index.types";

interface IProps {
  defaultValue: string;
  codeId: number;
  getDiscount: () => void;
}

const SearchUserAutoComplete = ({
  defaultValue,
  codeId,
  getDiscount,
}: IProps): React.ReactElement => {
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IUserEmailWithId[]>([]);
  const [loading, setLoading] = useState(false);
  const [linkedUser, setLinkedUser] = useState(-1);
  const [isActiveSearch, setActiveSearch] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  useEffect(() => {
    let active = true;

    (async () => {
      if (search.length < 3) return;
      setLoading(true);
      const response = await APIDiscounts.getEmailsListRequest({ email: search });
      setLoading(false);
      if (active) {
        // @ts-ignore
        setOptions(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [search]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const handleCloseAlert = () => setIsShowAlert(false);

  const changeUserLink = async () => {
    setIsUpdating(true);
    try {
      const data = {
        code_id: codeId,
        accounts_id: linkedUser,
        editableValueKeyName: "accounts_id",
      };
      const result = await APIDiscounts.updateDiscountCodeRequest(data);
      // @ts-ignore
      if (result.status === 200) {
        getDiscount();
        setActiveSearch(false);
        setLinkedUser(-1);
      }
    } catch (error) {
      setIsShowAlert(true);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <EditableContainerWithActions
      renderComponent={() => (
        <SearchAutocomplete
          handleChangeInput={handleChangeInput}
          label="Email"
          onChange={(newValue: IUserEmailWithId) => setLinkedUser(newValue ? newValue.id : -1)}
          options={options}
          getOptionLabel={(option: IUserEmailWithId) => option.email}
          getOptionSelected={(option, value) => option.email === value.email}
          loading={loading}
          open={open}
          setOpen={setOpen}
        />
      )}
      isEditable={allowed_features.assign_discount_code_to_customer}
      tableDataCellClasses={{
        hover: !isActiveSearch && allowed_features.assign_discount_code_to_customer,
      }}
      isChanged={linkedUser >= 0}
      handleApply={changeUserLink}
      isUpdating={isUpdating}
      isShowAlert={isShowAlert}
      defaultValue={defaultValue || <span>n/a</span>}
      setIsShowAlert={handleCloseAlert}
    />
  );
};

export default SearchUserAutoComplete;

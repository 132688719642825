import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
  },
  subTableHeader: {
    "& td": {
      backgroundColor: "#d7e3fc !important",
    },
  },
  borderBottom: {
    "& td": {
      borderBottom: "1px solid #000",
    },
  },
  theadTitle: {
    color: "#0e0c0c",
  },
});

export default useStyles;

import React from "react";

import useStyles from "./useStyles";
import SortArrow from "../SortArrow";
import { TBy } from "../../../reducers/customersReducer";
import { ISortBy } from "../../../types";

interface IProps {
  newSortBy(arg0: string): void;
  sortBy: ISortBy;
  by: string;
  isReset?: boolean;
  title: string;
}

const TableDataCellWithSort = ({
  sortBy,
  newSortBy,
  by,
  isReset,
  title,
}: IProps): React.ReactElement => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    const value: TBy = (e.currentTarget as HTMLButtonElement).getAttribute("data-sort") as TBy;
    newSortBy(value);
  };

  return (
    <td className={classes.column}>
      <div
        className={classes.wrapper}
        onClick={handleClick}
        data-sort={by}
        role="button"
        tabIndex={-1}
      >
        {isReset && (
          <span
            className={classes.restSortParams}
            onClick={handleClick}
            data-sort={by}
            role="button"
            tabIndex={-1}
          >
            R
          </span>
        )}

        <div className={classes.name}>
          <span className={classes.columnName}>{title}</span>
          <SortArrow sortBy={sortBy} by={by} />
        </div>
      </div>
    </td>
  );
};

export default TableDataCellWithSort;

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

import useStyles from "./useStyles";
import { APICharges } from "../../../../api";
import { getSumAllCharges, IntlFormatUSD } from "../../../../utils";
import WithdrawalsRequestList from "../WithdrawalsRequestList";
import WithdrawalRequestForm from "../WithdrawalRequestForm";
import { useCustomerProvider } from "../../../../context";
import { Watermark } from "../../../Customer/components";

const WithdrawalRequestFormWithRequestsList = (): React.ReactElement => {
  const classes = useStyles();
  const { id } = useParams();
  const { customer } = useCustomerProvider();

  const [withdrawal, setWithdrawal] = useState({ id: 0 });
  const [totalSpent, setTotalSpent] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        if (customer.email) {
          const requestData = {
            accounts_id: +id,
            email: customer.email,
          };
          const response = await APICharges.getCustomerChargesRequest(requestData);
          const { stripeChargesByFingerPrint, payPalCharges } = response.data;
          setTotalSpent(getSumAllCharges([...stripeChargesByFingerPrint, ...payPalCharges]));
        }
      } catch (error) {
        console.error("Error fetch customer payments", error);
      }
    })();
  }, [customer.email, id]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <b>Finance information</b>
          <p className={classes.text}>
            Total spending: <b>{IntlFormatUSD(totalSpent)}</b>
          </p>
          <Watermark />
        </Grid>
      </Grid>
      <WithdrawalRequestForm accounts_id={id} setWithdrawal={setWithdrawal} />
      <div>
        <WithdrawalsRequestList key={withdrawal.id} />
      </div>
    </div>
  );
};

export default WithdrawalRequestFormWithRequestsList;

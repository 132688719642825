import React, { useEffect, useState } from "react";

import { SearchAutocomplete, EditableContainerWithActions } from "../index";

interface ICurrentValue {
  name: unknown;
  id: unknown;
}
interface IProps<SearchResult, OnUpdate, CurrentValue> {
  currentValue: CurrentValue;
  onSearch: (search: string) => Promise<SearchResult>;
  currentOptions?: SearchResult[];
  onUpdated: () => void;
  onUpdate: (data: ICurrentValue) => Promise<OnUpdate>;
  ParentComponent: string;
  label: string;
  isShowEditIcon: boolean;
  containerClass?: Record<string, boolean | string>;
}
const SearchAutocompleteWithActions = <SearchResult, OnUpdate, CurrentValue>({
  currentOptions = [],
  currentValue,
  onSearch,
  onUpdated,
  onUpdate,
  ParentComponent,
  label,
  isShowEditIcon,
  containerClass,
}: IProps<SearchResult, OnUpdate, CurrentValue>): React.ReactElement => {
  const [search, setSearch] = useState("");
  const [selectedValue, setSelectedValue] = useState<ICurrentValue>({
    name: currentValue,
    id: -1,
  });
  const [options, setOptions] = useState<SearchResult[]>(currentOptions);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const handleCloseAlert = () => setIsShowAlert(false);
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const handleApply = async () => {
    setIsUpdating(true);
    try {
      await onUpdate(selectedValue);
      onUpdated();
    } catch (error) {
      setIsShowAlert(true);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    setSelectedValue({
      ...selectedValue,
      name: currentValue,
    });
  }, [currentValue]);

  const onCancel = () => {
    setSelectedValue({
      ...selectedValue,
      name: currentValue,
    });
  };

  useEffect(() => {
    (async () => {
      if (search.length < 2) return;
      try {
        setLoading(true);
        const response = await onSearch(search);
        // @ts-ignore
        setOptions(response.data);
      } catch (error) {
        console.log("error");
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  return (
    <EditableContainerWithActions
      isShowEditIcon={isShowEditIcon}
      ParentComponent={ParentComponent}
      tableDataCellClasses={containerClass}
      renderComponent={() => (
        <SearchAutocomplete
          label={label}
          handleChangeInput={handleChangeInput}
          onChange={(newValue: ICurrentValue) => setSelectedValue(newValue)}
          // @ts-ignore
          options={options}
          // @ts-ignore
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          loading={loading}
          open={open}
          setOpen={setOpen}
          style={{ width: "100%" }}
        />
      )}
      isEditable
      isChanged={currentValue !== selectedValue.name}
      handleApply={handleApply}
      isUpdating={isUpdating}
      isShowAlert={isShowAlert}
      defaultValue={currentValue || <span>n/a</span>}
      setIsShowAlert={handleCloseAlert}
      onCancel={onCancel}
    />
  );
};

export default SearchAutocompleteWithActions;

import React from "react";
import { CircularProgress as CircularProgressComponent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  preloaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 80,
  },
});

interface IProps {
  isLoading: boolean;
}
const CircularProgress = ({ isLoading }: IProps): React.ReactElement | null => {
  const classes = useStyles();

  return isLoading ? (
    <div className={classes.preloaderContainer}>
      <CircularProgressComponent />
    </div>
  ) : null;
};

export default CircularProgress;

import { AffiliatesAction, AffiliatesActionType } from "./index.types";
import { IGetAffiliateAccountsResponse } from "../../api/affiliates/intex.types";

export const AffiliatesActions = {
  fetchAffiliateAccountsRequest: (): AffiliatesAction => ({
    type: AffiliatesActionType.affiliateAccountsFetchRequest,
  }),
  fetchAffiliateAccountsSuccess: (
    payload: IGetAffiliateAccountsResponse["data"]
  ): AffiliatesAction => ({
    type: AffiliatesActionType.affiliateAccountsFetchSuccess,
    payload,
  }),
  fetchAffiliateAccountsFailed: (): AffiliatesAction => ({
    type: AffiliatesActionType.affiliateAccountsFetchFailed,
  }),
};

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

import { EditableContainerWithActions } from "../../../../../../components/ui";
import { APIFunds } from "../../../../../../api";

interface IProps {
  note_id: number;
  note: string;
  fetchFundedTraderNotes: () => Promise<void>;
}

const EditNote: React.FC<IProps> = ({ note_id, note, fetchFundedTraderNotes }) => {
  const [value, setValue] = useState(note);
  const handleEditMote = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleUpdateNote = async () => {
    await APIFunds.updateFundedAccountNoteRequest(note_id, { note: value });
    await fetchFundedTraderNotes();
  };

  return (
    <EditableContainerWithActions
      renderComponent={() => (
        <TextField
          onChange={handleEditMote}
          value={value}
          id="note"
          label="Note"
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
      tableDataCellClasses={{
        hover: false,
      }}
      isEditable
      isChanged={note !== value && value.length >= 5}
      handleApply={handleUpdateNote}
      isUpdating={false}
      isShowAlert={false}
      setIsShowAlert={() => ({})}
      defaultValue={note}
    />
  );
};

export default EditNote;

import { FundedTraderFilesAction, FundedTraderFilesActionType } from "./index.types";
import { IGoogleDriveCustomerFilesResponse } from "../../api/googleDrive/index.types";

export const FundedTraderFilesActions = {
  fetchFundedTraderFilesRequest: (): FundedTraderFilesAction => ({
    type: FundedTraderFilesActionType.fundedTraderFilesFetchRequest,
  }),
  fetchFundedTraderFilesSuccess: (
    payload: IGoogleDriveCustomerFilesResponse["data"]
  ): FundedTraderFilesAction => ({
    type: FundedTraderFilesActionType.fundedTraderFilesFetchSuccess,
    payload,
  }),
  fetchFundedTraderFilesFailed: (): FundedTraderFilesAction => ({
    type: FundedTraderFilesActionType.fundedTraderFilesFetchFailed,
  }),
};

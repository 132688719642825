/* eslint-disable no-param-reassign */
import produce from "immer";

import { ReduxAction } from "../static-types";
import { IDiscountCodesLogsData } from "../api/discounts/index.types";
// import { DiscountCodesData } from "../api/discounts";

export type TDiscountCodesLogsAction =
  | IDiscountCodesLogsFetchRequest
  | IDiscountCodesLogsFetchSuccess
  | IDiscountCodesLogsFetchFailed;

export enum EDiscountCodesLogsActionType {
  fetchRequest = "discountCodesLogs/FetchRequest",
  fetchSuccess = "discountCodesLogs/FetchSuccess",
  fetchFailed = "discountCodesLogs/FetchFailed",
}

interface IDiscountCodesLogsFetchRequest extends ReduxAction {
  type: EDiscountCodesLogsActionType.fetchRequest;
}
interface IDiscountCodesLogsFetchSuccess extends ReduxAction {
  type: EDiscountCodesLogsActionType.fetchSuccess;
  payload: IDiscountCodesLogsData;
}
interface IDiscountCodesLogsFetchFailed extends ReduxAction {
  type: EDiscountCodesLogsActionType.fetchFailed;
}

export const DiscountCodesLogsActions = {
  fetchRequest: (): TDiscountCodesLogsAction => ({
    type: EDiscountCodesLogsActionType.fetchRequest,
  }),
  fetchSuccess: (discountCodesLogs: IDiscountCodesLogsData): TDiscountCodesLogsAction => ({
    type: EDiscountCodesLogsActionType.fetchSuccess,
    payload: discountCodesLogs,
  }),
  fetchFailed: (): TDiscountCodesLogsAction => ({
    type: EDiscountCodesLogsActionType.fetchFailed,
  }),
};

export interface IDiscountCodesLogsState {
  data: IDiscountCodesLogsData;
  loading: boolean;
}

const initialState: IDiscountCodesLogsState = {
  data: {
    rows: [],
    count: 0,
  },
  loading: false,
};

const discountCodesLogsReducer = (
  prevState = initialState,
  action: TDiscountCodesLogsAction
): IDiscountCodesLogsState =>
  produce(prevState, (draft: IDiscountCodesLogsState): IDiscountCodesLogsState => {
    switch (action.type) {
      case EDiscountCodesLogsActionType.fetchRequest:
        draft.loading = true;
        break;
      case EDiscountCodesLogsActionType.fetchSuccess:
        draft.data = action.payload;
        draft.loading = false;
        break;
      case EDiscountCodesLogsActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }

    return draft;
  });

export default discountCodesLogsReducer;

import React, { useState } from "react";
import cx from "classnames";
import { Tab, Tabs, Paper } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import TabPanel from "../../components/ui/TabPanel";
import useStyles from "./useStyles";
import QuickSearch from "./components/QuickSearch";
import { WBP_TAB_TITLES } from "./components/WatchListBanPnlTab/constants";
import WatchListBanPnlTab from "./components/WatchListBanPnlTab";
import useGeneralStyles from "../../useGeneralStyles";
import AllAccountSearch from "./components/AllAccountSearch";
import FundedAccountQuickSearch from "./components/FundedAccountQuickSearch";
import E2TAccountsInvestigation from "./components/E2TAccountsInvestigation";
import { STATUS_COLORS_OPACITY } from "../../constants";
import FundedAccountInvestigation from "./components/FundedAccountInvestigation";
import { useAdminProviderDataContext } from "../../context";
import LiveLosersAndTopLiveSimTakersTab from "./components/TopLiveLosersAndLiveSimTakersTab";

const StyledE2TTab = styled(Tab)({
  backgroundColor: STATUS_COLORS_OPACITY.lightYellow,
});
const StyledHeliosTab = styled(Tab)({
  backgroundColor: STATUS_COLORS_OPACITY.lightGreen,
});

const ComplianceSearch = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [activeTabIndex, setActiveTab] = useState(0);
  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);
  return (
    <div>
      <div>
        <Paper square>
          <Tabs
            scrollButtons="off"
            variant="scrollable"
            value={activeTabIndex}
            onChange={onChangeActiveTab}
          >
            <StyledE2TTab
              label="E2T Accounts Investigation"
              value={0}
              classes={{ root: classes.e2tSearchTabColor }}
            />
            <StyledE2TTab label="E2T All accounts search" value={1} />
            <StyledE2TTab
              label="E2T quick search"
              value={2}
              disabled={!allowed_features.access_to_compliance_quick_search}
            />
            <StyledHeliosTab label="Funded account quick search" value={4} />
            <StyledHeliosTab label="Funded account investigation" value={5} />
            <StyledHeliosTab label={WBP_TAB_TITLES.tabName} value={6} />
            <StyledHeliosTab label="Top Live losses, and top LiveSim takers" value={7} />
          </Tabs>
        </Paper>
      </div>

      <div className={cx(generalClasses.containerBg, classes.mainContainer)}>
        <TabPanel value={activeTabIndex} index={0}>
          <E2TAccountsInvestigation />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <AllAccountSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          <QuickSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={4}>
          <FundedAccountQuickSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={5}>
          <FundedAccountInvestigation />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={6}>
          <WatchListBanPnlTab />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={7}>
          <LiveLosersAndTopLiveSimTakersTab />
        </TabPanel>
      </div>
    </div>
  );
};

export default ComplianceSearch;

import { IPlan } from "../../../../api/plans/index.types";

export const RESET_FLAG_ID = -2;

export interface IPlanOption {
  value: number;
  label: string;
  id: number;
}

type TGetPlanOptions = (plans: IPlan[]) => Array<IPlanOption>;
export const get_plan_options: TGetPlanOptions = (plans) => [
  {
    value: 0,
    label: "Select Plan",
    id: 0,
  },
  {
    value: RESET_FLAG_ID,
    label: "Reset",
    id: RESET_FLAG_ID,
  },
  {
    value: -1,
    label: "ALL - All plans",
    id: -1,
  },
  ...[...plans]
    .sort((a: IPlan, b: IPlan) => a.key.localeCompare(b.key))
    .filter((p) =>
      ["BCC", "GAU100", "GAU150", "GAU200", "GAU50", "TCP25", "TCP50", "TCP100"].includes(p.key)
    )
    .map((p) => ({
      value: p.id,
      label: p.name,
      id: p.id,
    })),
];

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableDataCellWithSort from "../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../reducers/customersReducer";
import newSortBy from "../../../../utils/newSortBy";
import { useAdminProviderDataContext, useEvaluationsContext } from "../../../../context";
import TableDataJourneyName from "../TableDataJourneyName";

const useStyles = makeStyles({
  column: {
    "& td": {
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 1,
      background: "#1886cf",
    },
  },
});
const TableHead = (): React.ReactElement => {
  const classes = useStyles();

  const { activeTabIndex } = useEvaluationsContext();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const isDisabledActions =
    allowed_features.access_to_edit_waiting_for_offer_only && !(activeTabIndex === 4);

  const { sortBy, setSortBy } = useEvaluationsContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });
  return (
    <thead className={classes.column}>
      <tr>
        {!isDisabledActions && <TableDataJourneyName />}

        <TableDataCellWithSort
          sortBy={sortBy}
          by="plans_key"
          title="E2T Account ID"
          newSortBy={handleSortBy}
          isReset={sortBy.by !== "acc_id" || sortBy.order}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="account_name"
          title="Customer"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort sortBy={sortBy} by="email" title="Email" newSortBy={handleSortBy} />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="acc_balance"
          title="Account balance"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="pnl"
          title="Total PnL"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="largest_pnl"
          title="Largest PnL"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="largest_pnl_percent"
          title="Consistency"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="days_traded"
          title="Days Traded"
          newSortBy={handleSortBy}
        />
      </tr>
    </thead>
  );
};

export default TableHead;

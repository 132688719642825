import React from "react";
import cx from "classnames";

import Select from "../../../../components/ui/Select";
import useStyles from "./useStyles";
import { EProcessingStatuses } from "../../../../constants";

interface IProps {
  value: string | number;
  id: number;
  accounts_id: number;
  handleChange: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number
  ) => void;
  name: string;
  label?: string;
  options: IOptions[];
  bgStylesClass?: string;
  useSmallSize?: boolean;
}

interface IOptions {
  label: string | number;
  value: string | number | boolean;
}

const ProcessingStatusSelect: React.FC<IProps> = ({
  value,
  id,
  accounts_id,
  handleChange,
  label,
  options,
  name,
  bgStylesClass,
  useSmallSize,
}) => {
  const classes = useStyles();
  return (
    <Select
      {...(useSmallSize && {
        baseSelectStyles: {
          className: cx(classes.baseSelect, bgStylesClass),
          classes: {
            root: classes.rootBaseSelect,
          },
        },
      })}
      name={name}
      size="small"
      options={options.map((opt) => ({
        text: opt.label as string,
        name: opt.value,
      }))}
      onChange={(e) => {
        return handleChange(e, id, accounts_id);
      }}
      disabled={[EProcessingStatuses.failed, EProcessingStatuses.completed].includes(
        value as EProcessingStatuses
      )}
      value={value}
      {...(label && { inputLabel: label })}
      {...(label && { label })}
    />
  );
};

export default ProcessingStatusSelect;

import {
  FAILED_OR_BANNED_STATUS_COLOR,
  IN_PROGRESS_STATUS_COLOR,
  READY_STATUS_COLOR,
} from "./components/TradedAccountsInfoTableColumns/utils";

export const FUNDED_TRADERS = {
  "Account id": "id",
  Name: "name",
  Email: "email",
};

export const FORCED_TO_LIVE_ACCOUNTS = {
  "Account id": "id",
  Name: "name",
  Email: "email",
  Withdrawals: "withdrawals",
};
export const FORCED_TO_LIVE_ACCOUNTS_INPUT_TYPES = {
  Withdrawals: "number",
};

export const FUNDED_TRADERS_INPUT_TYPES = {
  "Account id": "number",
};

export const CAPPED_STOPPED_ACCOUNTS_FILTER_OPTIONS = {
  Name: "name",
  Email: "email",
  "Account ID": "e2t_account_id",
  "Account type": "account_type",
};

export const FUNDED_TRADER_STATUS = {
  recentPasser: {
    color: "rgba(235, 109, 44, 0.61)",
    name: "Forced to live",
    backgroundColor: "transparent",
    border: `1px solid ${FAILED_OR_BANNED_STATUS_COLOR}`,
  },
};

export const STATUSES_COLORS = {
  recentPasser: {
    color: READY_STATUS_COLOR,
    name: "Ready",
  },
  duplicate: {
    color: IN_PROGRESS_STATUS_COLOR,
    name: "Not ready",
  },
};

import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Grid, Button, Checkbox, FormControlLabel } from "@material-ui/core/";

import { FormCheckbox, FormInput } from "../../../../components/ui";
import AgentFormFeaturesControls from "../AgentFormFeaturesControls";

import utils from "../../utils";
import { IAgent } from "../../../../api/agents/index.types";
import { APIAgents } from "../../../../api";
import useStyles from "./useStyles";
import { useAdminProviderDataContext, useAuthContext } from "../../../../context";
import { AgentActions } from "../../../../reducers/agentReducer/actions";
import ProfilePhoto from "../ProfilePhoto";
import SubAgentDataAccordion from "../SubAgentDataAccordion";

const AgentForm = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { hasExpired } = useAuthContext();
  const { loading, data } = useSelector((state) => state.agent);
  const polices = useSelector((state) => state.polices);

  const [status, setStatus] = useState<string>("Save");
  const { user } = useAdminProviderDataContext();

  const handleSubmit = async (values: IAgent) => {
    try {
      dispatch(AgentActions.fetchAgentRequest());
      const { allowed_features, ...rest } = values;
      // @ts-ignore
      const newValues = {
        ...rest,
        ...(values.preset === "custom" && { allowed_features }),
      };

      if (values.profile_photo && typeof values.profile_photo === "object") {
        const res = await APIAgents.updateAgentProfilePhotoRequest(
          newValues.id,
          values.profile_photo
        );
        newValues.profile_photo = res.data.profile_photo;
      }
      const response = await APIAgents.updateAgentRequest(newValues);
      if (response.status === 200) {
        setStatus("Success!");
        dispatch(AgentActions.fetchAgentSuccess(response.data));
      }
    } catch (error) {
      console.error("error updating agent", error);
      setStatus("Error!");
      dispatch(AgentActions.fetchAgentFailed());
      hasExpired();
    }
  };

  useEffect(() => {
    utils.handleIsSelectAll(data.allowed_features, polices.data.policiesOptions, setIsSelectedAll);
  }, [data.allowed_features, polices.data.policiesOptions]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStatus("Save");
    }, 2000);
    return () => clearTimeout(timeout);
  }, [status]);

  return (
    <div>
      {loading ? (
        <div className={classes.preloaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.controlsContainer}>
          <Formik
            initialValues={{
              ...data,
              preset: data.admin_accounts_policies_preset.name,
              password: "",
              passwordConfirmation: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={utils.AGENT_SCHEMA}
            validateOnChange
            eneblaeReinitialize
          >
            {({ isValid, values, dirty, setValues, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <ProfilePhoto<IAgent>
                        fieldName="profile_photo"
                        setFieldValue={setFieldValue}
                        backgroundImageUrl={data.profile_photo}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Field
                            name="email"
                            label="Email"
                            placeholder="Email"
                            component={FormInput}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="active"
                            label="Active profile"
                            component={FormCheckbox}
                            contextualHelpText="Active / inactive agent"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Field
                            name="password"
                            label="Password"
                            placeholder="Password"
                            component={FormInput}
                            type="password"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {values.password && (
                            <Field
                              name="passwordConfirmation"
                              label="Password confirmation"
                              placeholder="Password confirmation"
                              component={FormInput}
                              type="password"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={1}>
                      <h3>Features</h3>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        className={classes.selectAllOptions}
                        control={
                          <Checkbox
                            disabled={!user.allowed_features.change_agent_allowed_features}
                            checked={isSelectedAll}
                            onChange={() =>
                              utils.handleSelectAll(
                                setValues,
                                values,
                                setIsSelectedAll,
                                isSelectedAll,
                                polices.data.policiesOptions
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Select All"
                      />
                    </Grid>
                  </Grid>

                  <AgentFormFeaturesControls
                    values={values}
                    setFieldValue={setFieldValue}
                    // @ts-ignore
                    setValues={setValues}
                  />

                  <Grid container spacing={3}>
                    <Grid item xs={1}>
                      <Button
                        className={cx({
                          backgroundColor: utils.getButtonColor(status),
                        })}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid || !dirty}
                      >
                        {status}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SubAgentDataAccordion />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default AgentForm;

import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";

import { PaginationWithLimit } from "../index";
import useStyles from "./useStyles";
import { IPaginationState } from "../../../hooks/usePagination";
import TableData, { INote } from "./TableData";

type TProps = {
  pagination: IPaginationState;
  saveNote: (note: string) => Promise<void>;
  notes: INote[];
  handleDeleteNote: (noteId: number) => Promise<void>;
  handleChangeStatus: (noteId: number, isActive: boolean) => Promise<void>;
  isAllowedChangeStatusAndRemoveActions?: boolean;
};
const NotesTableWithActions: React.FC<TProps> = ({
  notes,
  pagination,
  saveNote,
  handleChangeStatus,
  handleDeleteNote,
  isAllowedChangeStatusAndRemoveActions = false,
}) => {
  const classes = useStyles();
  const [note, setNote] = useState("");

  const handleSaveNote = async () => {
    try {
      await saveNote(note);
      setNote("");
    } catch (error) {
      console.error("Error handle saveNote", error);
    }
  };

  return (
    <div className={classes.tableBorder}>
      <div className={classes.alignCenter}>
        <TextField
          data-test="new-note-input"
          size="small"
          variant="outlined"
          fullWidth
          multiline
          label="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className={classes.noteInput}
        />
        <Button
          data-test="add-note-button"
          variant="contained"
          color="primary"
          onClick={handleSaveNote}
        >
          Add
        </Button>
      </div>

      <TableData
        report={notes}
        handleDeleteNote={handleDeleteNote}
        handleChangeStatus={handleChangeStatus}
        isAllowedChangeStatusAndRemoveActions={isAllowedChangeStatusAndRemoveActions}
      />

      <PaginationWithLimit
        count={pagination.count}
        limit={pagination.limit.toString()}
        page={pagination.page}
        setPageState={pagination.setPage}
        setLimit={pagination.setLimit}
      />
    </div>
  );
};

export default NotesTableWithActions;

/* eslint-disable no-param-reassign */
import produce from "immer";

import { CustomerAction, CustomerActionType, ICustomerState } from "./index.types";

export const initialState: ICustomerState = {
  complianceNotes: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  suspiciousNotes: {
    data: {
      count: 0,
      countActive: 0,
      notes: [],
    },
    loading: false,
  },
  notes: {
    data: {
      count: 0,
      countActive: 0,
      notes: [],
    },
    loading: false,
  },
  agentLogs: {
    data: {
      logs: [],
      count: 0,
    },
    loading: false,
  },
  plans: {
    data: {
      passedPlan: null,
      activePlan: null,
      plans: [],
      billing_history_by_plans: [],
      count: 0,
    },
    loading: false,
  },
  managementData: {
    loading: false,
    data: {
      account_gauntlets: [],
      account_plans: [],
      account_simulators: [],
      status: -1,
      id: -1,
    },
  },
  payment_cards: {
    loading: false,
    data: {
      payment_cards: {
        cards: [],
        count: 0,
      },
    },
  },
  mainData: {
    loading: false,
    data: {
      banned_account: null,
      funded_trader: null,
    },
  },
  ipsData: {
    sharedIps: {
      loading: false,
      data: {
        count: 0,
        rows: [],
      },
    },
    sharedRegIp: {
      loading: false,
      data: {
        count: 0,
        rows: [],
      },
    },
  },
  similarTradedAccounts: {
    loading: false,
    data: {
      evaluation_completed_history: [],
      fundedTrader: null,
      fundedTradersList: [],
      similarAccountsBasedOnNameAddressAndPhone: [],
    },
  },
};

const index = (prevState = initialState, action: CustomerAction): ICustomerState =>
  produce(prevState, (draft: ICustomerState): ICustomerState => {
    switch (action.type) {
      case CustomerActionType.fetchAgentLogsRequest:
        draft.agentLogs.loading = true;
        break;
      case CustomerActionType.fetchAgentLogsSuccess:
        draft.agentLogs.data.logs = action.payload.logs;
        draft.agentLogs.data.count = action.payload.count;
        draft.agentLogs.loading = false;
        break;
      case CustomerActionType.fetchAgentLogsFailed:
        draft.agentLogs.loading = false;
        break;

      case CustomerActionType.fetchCustomerPlansRequest:
        draft.plans.loading = true;
        break;
      case CustomerActionType.fetchCustomerPlansSuccess:
        draft.plans.data = action.payload;
        draft.plans.loading = false;
        break;
      case CustomerActionType.fetchCustomerPlansFailed:
        draft.plans.loading = false;
        break;
      case CustomerActionType.fetchCustomerComplianceNotesRequest:
        draft.complianceNotes.loading = true;
        break;
      case CustomerActionType.fetchCustomerComplianceNotesSuccess:
        draft.complianceNotes.data = action.payload;
        draft.complianceNotes.loading = false;
        break;
      case CustomerActionType.fetchCustomerComplianceNotesFailed:
        draft.complianceNotes.loading = false;
        break;
      case CustomerActionType.fetchCustomerNotesRequest:
        draft.notes.loading = true;
        break;
      case CustomerActionType.fetchCustomerNotesSuccess:
        draft.notes.data.notes = action.payload.notes;
        draft.notes.data.count = action.payload.count;
        draft.notes.data.countActive = action.payload.countActive;
        draft.notes.loading = false;
        break;
      case CustomerActionType.fetchCustomerNotesFailed:
        draft.notes.loading = false;
        break;
      case CustomerActionType.fetchCustomerSuspiciousNotesRequest:
        draft.suspiciousNotes.loading = true;
        break;
      case CustomerActionType.fetchCustomerSuspiciousNotesSuccess:
        draft.suspiciousNotes.data.notes = action.payload.notes;
        draft.suspiciousNotes.data.count = action.payload.count;
        draft.suspiciousNotes.data.countActive = action.payload.countActive;
        draft.suspiciousNotes.loading = false;
        break;
      case CustomerActionType.fetchCustomerSuspiciousNotesFailed:
        draft.suspiciousNotes.loading = false;
        break;
      case CustomerActionType.fetchCustomerManagementDataRequest:
        draft.managementData.loading = true;
        break;
      case CustomerActionType.fetchCustomerManagementDataSuccess:
        draft.managementData.data = action.payload;
        draft.managementData.loading = false;
        break;
      case CustomerActionType.fetchCustomerManagementDataFailed:
        draft.managementData.loading = false;
        break;
      case CustomerActionType.fetchCustomerPaymentCardsRequest:
        draft.payment_cards.loading = true;
        break;
      case CustomerActionType.fetchCustomerPaymentCardsSuccess:
        draft.payment_cards.data.payment_cards.cards = action.payload.paymentCards.rows;
        draft.payment_cards.data.payment_cards.count = action.payload.paymentCards.count;
        draft.payment_cards.loading = false;
        break;
      case CustomerActionType.fetchCustomerPaymentCardsFailed:
        draft.payment_cards.loading = false;
        break;
      case CustomerActionType.fetchCustomerMainDataRequest:
        draft.mainData.loading = true;
        break;
      case CustomerActionType.fetchCustomerMainDataSuccess:
        draft.mainData.data.banned_account = action.payload.banned_account || null;
        draft.mainData.data.funded_trader = action.payload.funded_trader || null;
        draft.mainData.loading = false;
        break;
      case CustomerActionType.fetchCustomerMainDataFailed:
        draft.mainData.loading = false;
        break;
      // Shared Ips
      case CustomerActionType.fetchCustomerSharedIpsDataRequest:
        draft.ipsData.sharedIps.loading = true;
        break;
      case CustomerActionType.fetchCustomerSharedIpsDataSuccess:
        draft.ipsData.sharedIps.data.rows = action.payload.rows;
        draft.ipsData.sharedIps.data.count = action.payload.count;
        draft.ipsData.sharedIps.loading = false;
        break;
      case CustomerActionType.fetchCustomerSharedIpsDataFailed:
        draft.ipsData.sharedIps.loading = false;
        break;
      // Shared Reg Ips
      case CustomerActionType.fetchCustomerSharedRegIpsRequest:
        draft.ipsData.sharedRegIp.loading = true;
        break;
      case CustomerActionType.fetchCustomerSharedRegIpsSuccess:
        draft.ipsData.sharedRegIp.data.rows = action.payload.rows;
        draft.ipsData.sharedRegIp.data.count = action.payload.count;
        draft.ipsData.sharedRegIp.loading = false;
        break;
      case CustomerActionType.fetchCustomerSharedRegIpsFailed:
        draft.ipsData.sharedRegIp.loading = false;
        break;
      default:
        return draft;
      // similarFundedAccounts
      case CustomerActionType.fetchCustomerSimilarFundedAccountsRequest:
        draft.similarTradedAccounts.loading = true;
        break;
      case CustomerActionType.fetchCustomerSimilarFundedAccountsSuccess:
        draft.similarTradedAccounts.data = action.payload;
        draft.similarTradedAccounts.loading = false;
        break;
      case CustomerActionType.fetchCustomerSimilarFundedAccountsFailed:
        draft.similarTradedAccounts.loading = false;
        break;
    }
    return draft;
  });

export default index;

import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core/";

import { IntlFormatUSD } from "../../../../utils";
import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "./useStyles";
import { CopyToClipboard } from "../../../../components/ui";
import { useAdminProviderDataContext, useEvaluationsContext } from "../../../../context";
import { E_EVALUATIONS_STEPS } from "../../constants";
import TableDataJourneyActions from "../TableDataJourneyActions";
import { getIsShowPlanDivider } from "./utils";

const TableBody = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { activeTabIndex } = useEvaluationsContext();

  const { customersInfo, report } = useSelector((state) => state.evaluations.data.evaluations);
  const { plans } = useSelector((state) => state.plans.data);

  const isShowCloseToPassing = E_EVALUATIONS_STEPS.CLOSE_TO_PASSING_TAB_INDEX === activeTabIndex;
  const isShowPassedCustomers = E_EVALUATIONS_STEPS.PASSED_TAB_INDEX === activeTabIndex;

  const handleRowClick = (email: string) => {
    const clickedCustomer = customersInfo.find((customer) => customer.email === email);
    if (clickedCustomer) {
      return `/customer/${clickedCustomer.id}`;
    }
    return "/";
  };

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const isDisabledActions =
    allowed_features.access_to_edit_waiting_for_offer_only && !(activeTabIndex === 4);

  return (
    <tbody>
      {report.map(
        (
          {
            acc_id,
            account_name,
            email,
            largest_pnl,
            largest_pnl_percent,
            days_traded,
            pnl,
            acc_balance,
            plans_key,
          },
          index
        ) => {
          const isShowPlanDivider = getIsShowPlanDivider(report, index, plans_key);
          const currentPlan = plans.find(({ key }) => key === plans_key);
          const currentCustomerInfo = customersInfo.find((customer) => customer.email === email);

          return (
            <React.Fragment key={`${email}_${acc_id}_${largest_pnl}`}>
              {isShowPlanDivider && (
                <tr>
                  <th colSpan={2} className={classes.plansDivider}>
                    {currentPlan?.name}
                  </th>
                </tr>
              )}
              <tr
                className={cx(generalClasses.tableHover, {
                  [classes.passedColor]: isShowPassedCustomers,
                  [classes.closeToPassingColor]: isShowCloseToPassing,
                  [classes.suspiciousAccountColor]:
                    currentCustomerInfo?.suspicious_accounts_notes?.length,
                })}
                onClick={() => handleRowClick(email)}
              >
                {!isDisabledActions && <TableDataJourneyActions email={email} account={acc_id} />}
                <td>
                  <CopyToClipboard value={acc_id} />
                </td>
                <td>
                  {allowed_features.access_to_edit_waiting_for_offer_only ? (
                    <p>{account_name}</p>
                  ) : (
                    <MenuItem component={Link} to={handleRowClick(email)}>
                      {account_name}
                    </MenuItem>
                  )}
                </td>
                <td>
                  <CopyToClipboard value={email} />
                </td>
                <td>{IntlFormatUSD(acc_balance)}</td>
                <td>
                  <p>{IntlFormatUSD(pnl)}</p>
                </td>
                <td>
                  <p>{IntlFormatUSD(largest_pnl)}</p>
                </td>
                <td>
                  <p>{largest_pnl_percent}%</p>
                </td>
                <td>{days_traded}</td>
              </tr>
            </React.Fragment>
          );
        }
      )}
    </tbody>
  );
};

export default TableBody;

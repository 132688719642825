import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface IProps {
  isOpenDialogConfirm: boolean;
  setIsOpenDialogConfirm: (isOpen: boolean) => void;
  submitForm: () => Promise<void>;
}

const CreateAccountConfirmDialog: React.FC<IProps> = ({
  isOpenDialogConfirm,
  setIsOpenDialogConfirm,
  submitForm,
}) => (
  <Dialog open={isOpenDialogConfirm} onClose={() => setIsOpenDialogConfirm(!isOpenDialogConfirm)}>
    <DialogTitle>Create new account</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to create this account?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          setIsOpenDialogConfirm(false);
        }}
        size="small"
        color="primary"
        variant="contained"
      >
        Cancel
      </Button>
      <Button
        onClick={async () => {
          await submitForm();
        }}
        size="small"
        color="secondary"
        autoFocus
        variant="contained"
        type="submit"
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default CreateAccountConfirmDialog;

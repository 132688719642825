import React from "react";
import { FilterAutocomplete } from "../../../../components/ui";
import { ISearch } from "../../index";

const AFFILIATES_FILTERS = {
  Name: "name",
  Email: "email",
  Code: "code",
};

interface IProps {
  search: ISearch;
  setSearch: (search: ISearch) => void;
}
const AffiliatesFilter: React.FC<IProps> = ({ search, setSearch }) => {
  return (
    <div>
      <FilterAutocomplete
        setSearch={setSearch}
        // @ts-ignore TODO: fix types
        search={search}
        filters={AFFILIATES_FILTERS}
        defaultSearchFields={AFFILIATES_FILTERS}
      />
    </div>
  );
};

export default AffiliatesFilter;

import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";

import Trades from "../Trades";
import PastPositions from "../PastPositions";
import ChartBalance from "../ChartBalance";
import { useIntercomContext } from "../../../../context";

interface TabPanelProps {
  children?: React.ReactNode;
  index: unknown;
  value: unknown;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#f2f2f2",
  marginTop: 20,
  color: "#000",
});

const RithmicDataTabs = (): React.ReactElement | null => {
  const { gauntletAccountName } = useIntercomContext();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
  };

  if (!gauntletAccountName) {
    return null;
  }

  return (
    <StyledAppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Rithmic data"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Trades" />
        <Tab label="Past positions" />
        <Tab label="Chart" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Trades />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PastPositions />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ChartBalance />
      </TabPanel>
    </StyledAppBar>
  );
};

export default RithmicDataTabs;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    display: "block",
    margin: "10px 0",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  form: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootAccordion: {
    backgroundColor: "transparent",
  },
  rootAccordionDetails: {
    paddingBottom: 36,
  },
});

export default useStyles;

import React from "react";

const Funds = ({
  width = 24,
  margin = "0",
}: {
  width?: number;
  margin?: string;
}): React.ReactElement => (
  <svg
    width={width}
    style={{ margin }}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 64 64"
    viewBox="0 0 64 64"
  >
    <path d="M62,60h-4c0.5,0,1-0.5,1-1s-0.5-1-1-1H46c-0.5,0-1,0.5-1,1s0.5,1,1,1h-2.8c-0.5-1.4,0.1-2.5,0.6-3c-1.5-1.9-0.5-3.5,0-4c-1.5-1.8-0.5-3.5,0-4c-1.5-2-0.5-3.5,0-4c-1.5-1.8-0.5-3.5,0-4c-2.1-2.6,0.4-5.1,2.2-5h3v-3c0-9.9-6.8-18.4-16.4-20.5c0,0,0,0,0,0c-0.7,1-1.4,1.2-1.6,1.3V17c0,0.5-0.5,1-1,1s-1-0.5-1-1v-3h-2v7c0,0.5-0.5,1-1,1s-1-0.5-1-1v-7.2c0,0-1-0.3-1.6-1.3C13.8,14.6,7,23.1,7,33v11.8c0,5-0.6,10.1-1.8,15.2H2c-0.5,0-1,0.5-1,1s0.5,1,1,1h60c0.5,0,1-0.5,1-1S62.5,60,62,60z M11.1,24.2c1.9-3.6,4.9-6.6,8.4-8.3c0.5-0.3,1.1,0,1.3,0.4c0.3,0.5,0,1.1-0.4,1.3c-3.2,1.6-5.9,4.2-7.6,7.5c-0.2,0.3-0.8,0.7-1.3,0.4C11,25.4,10.9,24.7,11.1,24.2z M9.4,28.9c0.1-0.5,0.6-0.9,1.1-0.8c0.6,0.1,0.9,0.6,0.9,1.1c0,0.4-0.2,1.7-0.3,2C10.8,32.2,10,32,10,32c-0.6-0.1-0.9-0.6-0.9-1.1C9.2,30.5,9.4,29.2,9.4,28.9z M12,58h-2c-0.5,0-1-0.5-1-1s0.5-1,1-1h2c0.5,0,1,0.5,1,1S12.5,58,12,58z M24,58h-8c-0.5,0-1-0.5-1-1s0.5-1,1-1h8c0.5,0,1,0.5,1,1S24.5,58,24,58z M28,38c2.8,0,5,2.2,5,5c0,2.4-1.7,4.4-4,4.9V51c0,0.5-0.5,1-1,1s-1-0.5-1-1v-3.1c-2.3-0.5-4-2.5-4-4.9c0-0.5,0.5-1,1-1s1,0.5,1,1c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3c-2.8,0-5-2.2-5-5c0-2.4,1.7-4.4,4-4.9V27c0-0.5,0.5-1,1-1s1,0.5,1,1v3.1c2.3,0.5,4,2.5,4,4.9c0,0.5-0.5,1-1,1s-1-0.5-1-1c0-1.7-1.3-3-3-3s-3,1.3-3,3S26.3,38,28,38z" />
    <path d="M58 54H46c-.5 0-1 .5-1 1s.5 1 1 1h12c.5 0 1-.5 1-1S58.5 54 58 54zM58 50H46c-.5 0-1 .5-1 1s.5 1 1 1h12c.5 0 1-.5 1-1S58.5 50 58 50zM58 46H46c-.5 0-1 .5-1 1s.5 1 1 1h12c.5 0 1-.5 1-1S58.5 46 58 46zM58 42H46c-.5 0-1 .5-1 1s.5 1 1 1h12c.5 0 1-.5 1-1S58.5 42 58 42zM45 39c0 .5.5 1 1 1h12c.5 0 1-.5 1-1s-.5-1-1-1H46C45.5 38 45 38.5 45 39zM26.5 8h3l3.7-5c.3-.3.2-1-.5-1h-9.4c-.6 0-.8.6-.5 1L26.5 8zM26 10c-.5 0-1 .5-1 1 0 0 0 1 1 1h4c1 0 1-1 1-1 0-.3 0-1-1-1H26z" />
  </svg>
);

export default Funds;

import { ITradedAccount } from "../../../../api/funds/index.types";

export const getAccountsToShow = (listItems: ITradedAccount[]): ITradedAccount[] => {
  const accountsToShow: ITradedAccount[] = [];
  for (const { id, initial_tcp_account_id, processing_status, ...rest } of listItems) {
    const tradedAccount = {
      id,
      initial_tcp_account_id,
      processing_status,
      ...rest,
    };
    if (!initial_tcp_account_id) {
      accountsToShow.push(tradedAccount);
      const subAccounts = listItems.filter((account) => account.initial_tcp_account_id === id);
      subAccounts && accountsToShow.push(...subAccounts);
    }
    if (
      initial_tcp_account_id &&
      processing_status === "Failed" &&
      !accountsToShow.find((a) => a.id === id)
    ) {
      accountsToShow.push(tradedAccount);
    }
    if (
      initial_tcp_account_id &&
      processing_status !== "Failed" &&
      !accountsToShow.find((a) => a.id === id)
    ) {
      accountsToShow.push(tradedAccount);
    }
  }
  return accountsToShow;
};

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  listItem: {
    display: "flex",
    justifyContent: "space-around",
  },
  submitContainer: {
    marginTop: 10,
  },
  presetContainer: {
    position: "relative",
  },
  errorContainer: {
    position: "absolute",
    left: 0,
    top: 55,
  },
});

export default useStyles;

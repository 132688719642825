import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  filterContainer: {
    width: 250,
    marginBottom: 10,
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  legendContainer: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  legendInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  tableContainer: {
    overflowX: "scroll",
  },
});

export default useStyles;

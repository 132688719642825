import {
  CustomersByFailedPayment,
  FailedStripeTransactions,
  ICharge,
} from "../../../../api/charges/index.types";
import { ICharge as IBillingCharge } from "../../../../api/billing/index.types";

// eslint-disable-next-line import/prefer-default-export
export const getFilteredFailedCharges = (
  transactions: ICharge[],
  failedStripeTransactions: FailedStripeTransactions[],
  customersByFailedPayment: CustomersByFailedPayment[]
): IBillingCharge[] => {
  const failedCharges: IBillingCharge[] = [];
  transactions.forEach((transaction) => {
    const { status, amount_refunded } = transaction;
    if (status === "failed" || status === "D" || amount_refunded > 0) {
      const failedStripeTransaction = failedStripeTransactions.find(
        ({ id }) => id === transaction.id
      );

      const customerByFailedPayment = customersByFailedPayment.find(({ stripe_customer }) => {
        if (failedStripeTransaction) {
          return stripe_customer === failedStripeTransaction.customer;
        }
        return false;
      });
      // @ts-ignore
      failedCharges.push({
        stripe_ch: transaction.id,
        plan: {
          name: transaction.object,
        },
        account: {
          name: failedStripeTransaction
            ? failedStripeTransaction.name
            : transaction.cardSource.name,
          id: customerByFailedPayment ? customerByFailedPayment.id.toString() : "",
          email: customerByFailedPayment ? customerByFailedPayment.email : "",
          country: {
            name: customerByFailedPayment
              ? customerByFailedPayment.country.name
              : transaction.payment_method_details.card.country,
          },
        },
        price_usd: amount_refunded * 100 || transaction.amount * 100,
        created: transaction.date,
        failure_message: transaction.outcome?.seller_message || transaction.outcome?.reason || "",
        ...transaction,
        refunded: !!amount_refunded,
      });
    }
  });
  return failedCharges;
};

import React, { useEffect } from "react";
import { Grid } from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";

import { AdminActions } from "../../reducers/heliosAdminReducer/actions";
import useGeneralStyles from "../../useGeneralStyles";
import { APIAccounts } from "../../api";

import * as Component from "./components";

const HeliosAdmin = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const dispatch = useDispatch();

  const {
    data: { liveSimCount, liveCount },
  } = useSelector((state) => state.heliosAdmin.simAndLiveSimAccounts);

  useEffect(() => {
    (async () => {
      try {
        dispatch(AdminActions.fetchSimAndLiveSimAccountsRequest());
        const response = await APIAccounts.getLiveAndLiveSimAccountsCountRequest();
        dispatch(AdminActions.fetchSimAndLiveSimAccountsSuccess(response));
      } catch (error) {
        console.error("Error handle get getLiveAndLiveSimAccountsCountRequest", error);
        dispatch(AdminActions.fetchSimAndLiveSimAccountsFailed());
      }
    })();
  }, []);

  return (
    <div className={generalClasses.containerBg}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Component.AccountsCounter title="Live accounts" count={liveCount} />
        </Grid>
        <Grid item xs={3}>
          <Component.AccountsCounter title="LiveSims accounts" count={liveSimCount} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Component.AccountsOver8KWithdrawals />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeliosAdmin;

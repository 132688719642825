import React from "react";
import { Snackbar as SnackbarComponent } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export type TTypeMessage = "success" | "error" | "";

interface IProps {
  type: TTypeMessage;
  isOpenSnackbar: boolean;
  responseSuccessMessage: string;
  responseErrorMessage: string;
  onClose: () => void;
}

const Snackbar = ({
  type,
  isOpenSnackbar,
  responseSuccessMessage,
  responseErrorMessage,
  onClose,
}: IProps): React.ReactElement => (
  <>
    <SnackbarComponent
      open={type === "success" && isOpenSnackbar}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="success" elevation={6} variant="filled">
        {responseSuccessMessage}
      </Alert>
    </SnackbarComponent>

    <SnackbarComponent
      open={type === "error" && isOpenSnackbar}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="error" elevation={6} variant="filled">
        {responseErrorMessage}
      </Alert>
    </SnackbarComponent>
  </>
);

export default Snackbar;

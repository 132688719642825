import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    minWidth: 515,
    maxWidth: 800,
    padding: 30,
    background: "rgb(247, 247, 247)",
  },
  title: {
    margin: 0,
  },
  leftContainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
  },
  discountInputs: {
    flexFlow: "column",
    display: "flex",
    marginRight: 30,
    minWidth: 320,
  },
  rightContainer: {
    margin: "10px 0",
    padding: "30px 40px",
    minWidth: 150,
  },
  resultContainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
  },
  textAlignRight: {
    textAlign: "right",
  },
  scrollContainer: {
    maxHeight: 150,
    overflow: "auto",
  },
  amount: {
    marginBottom: 10,
  },
  planContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  containerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default useStyles;

import { ISuspiciousNote, IGetSuspiciousNotesResponse } from "../../api/accounts/index.types";
import { ReduxAction } from "../../static-types";

export type SuspiciousCustomersAction =
  | SuspiciousCustomersFetchRequest
  | SuspiciousCustomersFetchSuccess
  | SuspiciousCustomersFetchFailed;

export enum SuspiciousCustomersActionType {
  suspiciousCustomersFetchRequest = "suspiciousCustomers/FetchRequest",
  suspiciousCustomersFetchSuccess = "suspiciousCustomers/FetchSuccess",
  suspiciousCustomersFetchFailed = "suspiciousCustomers/FetchFailed",
}

export interface SuspiciousCustomersFetchRequest extends ReduxAction {
  type: SuspiciousCustomersActionType.suspiciousCustomersFetchRequest;
}
export interface SuspiciousCustomersFetchSuccess extends ReduxAction {
  type: SuspiciousCustomersActionType.suspiciousCustomersFetchSuccess;
  payload: IGetSuspiciousNotesResponse["data"];
}
export interface SuspiciousCustomersFetchFailed extends ReduxAction {
  type: SuspiciousCustomersActionType.suspiciousCustomersFetchFailed;
}

export interface ISuspiciousCustomersState {
  data: ISuspiciousNote[];
  count: number;
  isLoading: boolean;
}

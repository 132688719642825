import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Field } from "formik";

import { ICustomerPlan } from "../../../../../../api/accounts/index.types";
import { IntlFormatUSD } from "../../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";
import { getCurrentPlanPrice, getExpirationData } from "../PlansTable/utils";
import { FormInput } from "../../../../../../components/ui";

const useStyles = makeStyles({
  lineThrough: {
    textDecoration: "line-through",
  },
  planPriceContainer: {
    maxWidth: "80px",
  },
});

interface IProps {
  index: number;
  plan: ICustomerPlan;
  isEditing: boolean;
}

interface IEditCurrentPlanPriceProps {
  index: number;
}
const EditCurrentPlanPrice: React.FC<IEditCurrentPlanPriceProps> = ({ index }) => {
  const classes = useStyles();
  return (
    <div className={classes.planPriceContainer}>
      <Field
        type="number"
        variant="standard"
        name={`plans.${index}.price_usd`}
        component={FormInput}
      />
    </div>
  );
};

const PlansPriceData: React.FC<IProps> = ({ plan, index, isEditing }) => {
  const classes = useStyles();
  const {
    data: { billing_history_by_plans },
  } = useSelector((state) => state.customer.plans);
  const billing_history = billing_history_by_plans[index] || {
    price_usd: plan.price_usd,
  };

  const { discount_code } = plan;
  const one_time = discount_code?.one_time;

  const { isOutExpiration, isNoOutExpiration, isHasExpiration } = getExpirationData(plan);

  const isAvailableForEditing =
    isEditing && (!discount_code || !isHasExpiration || !isNoOutExpiration);

  const isChangedPriceAfterExpiration =
    isHasExpiration && plan.price_usd !== billing_history.price_usd;

  return (
    <>
      {/*Initial Plan Price column*/}
      <td className={cx({ [classes.lineThrough]: isOutExpiration })}>
        {(discount_code && one_time) || isChangedPriceAfterExpiration
          ? IntlFormatUSD(billing_history.price_usd / CENTS_IN_DOLLAR)
          : IntlFormatUSD(plan.price_usd / CENTS_IN_DOLLAR)}
      </td>
      {/*Current Plan Price column*/}
      <td>
        {isAvailableForEditing ? (
          <EditCurrentPlanPrice index={index} />
        ) : (
          IntlFormatUSD(getCurrentPlanPrice(plan, billing_history))
        )}
      </td>
    </>
  );
};

export default PlansPriceData;

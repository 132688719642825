import React from "react";
import cx from "classnames";
import { Button, Tooltip } from "@material-ui/core";
import moment from "moment-timezone";

import { APIDiscounts } from "../../../../api";
import { DiscountCodeState } from "../../../../api/discounts/index.types";
import { useAdminProviderDataContext } from "../../../../context";
import useStyles from "./useStyles";

interface IProps {
  discount: DiscountCodeState;
  getDiscount: () => void;
}
const DeactivateActivateActionsButtons: React.FC<IProps> = ({ discount, getDiscount }) => {
  const classes = useStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const isExpiredCode = moment().isAfter(discount.expires);

  const updateDiscount = async (code_id: number, active: boolean) => {
    try {
      await APIDiscounts.updateDiscountCodeRequest({
        code_id,
        active,
        editableValueKeyName: "active",
      });
      getDiscount();
    } catch (error) {
      console.error(error);
    }
  };

  if (discount.active) {
    return (
      <td className={cx(classes.alignCenter, classes.active)}>
        <Tooltip
          title={isExpiredCode ? `Expired on ${discount.expires}` : ""}
          placement="top-start"
        >
          <Button
            data-test="deactivate-code-button"
            disabled={!allowed_features.change_discount_code_active_status}
            variant="contained"
            {...(!isExpiredCode && {
              color: "primary",
            })}
            onClick={() => updateDiscount(discount.id, !discount.active)}
          >
            Deactivate
          </Button>
        </Tooltip>
      </td>
    );
  }
  return (
    <td style={{ textAlign: "center", color: "red" }}>
      <Tooltip title={isExpiredCode ? `Expired on ${discount.expires}` : ""} placement="top-start">
        <Button
          data-test="activate-code-button"
          disabled={
            !allowed_features.change_discount_code_active_status || discount.accepted === null
          }
          variant="contained"
          {...(!isExpiredCode && {
            color: "secondary",
          })}
          className={cx({ [classes.expiredInactiveCodeBg]: isExpiredCode })}
          onClick={() => updateDiscount(discount.id, !discount.active)}
        >
          Activate
        </Button>
      </Tooltip>
    </td>
  );
};

export default DeactivateActivateActionsButtons;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  selectFieldBasic: {
    position: "relative",
  },
  fullWidth: {
    width: "100%",
  },
  inputLabelRoot: {
    padding: "0 5px",
    backgroundColor: "#f2f2f2",
  },
  errorContainer: {
    position: "absolute",
    left: 0,
    top: 55,
  },
});

export default useStyles;

import React from "react";
import { DialogContentText } from "@material-ui/core";
import momentTz from "moment-timezone";

import { HOURS_12_FORMAT_WITH_DATE } from "../../../../../../constants";

interface IProps {
  funded_trader_ban_date: string | null | undefined;
  banned_account_timestamp: string | null | undefined;
}
const DialogConfirmFundedOrBannedData: React.FC<IProps> = ({
  funded_trader_ban_date,
  banned_account_timestamp,
}) => {
  return (
    <>
      {funded_trader_ban_date && (
        <DialogContentText>
          Note that this account was <b>banned</b> on{" "}
          {momentTz(funded_trader_ban_date).tz("America/Chicago").format(HOURS_12_FORMAT_WITH_DATE)}{" "}
          <b>Helios</b>.
        </DialogContentText>
      )}
      {banned_account_timestamp && (
        <DialogContentText>
          Note that this account was <b>banned</b> on{" "}
          {momentTz(banned_account_timestamp)
            .tz("America/Chicago")
            .format(HOURS_12_FORMAT_WITH_DATE)}{" "}
          <b>E2t</b>.
        </DialogContentText>
      )}
    </>
  );
};

export default DialogConfirmFundedOrBannedData;

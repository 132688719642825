import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  dialogContent: {
    width: 405,
  },
  button: {
    margin: "10px 0",
  },
});

export default useStyles;

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { FilterAutocomplete } from "../../../../components/ui";
import { MASTER_BAN_LIST_COLUMNS } from "./constants";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendSearchParamsToQuery, TitleUpdated } from "../../utils";
import { WBP_TAB_TITLES } from "../WatchListBanPnlTab/constants";

const MasterBanList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } = tabsTablesSearchState.watchListBanPnlTab.masterBanTable;

  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.masterBanList);

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getMasterBanList,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendSearchParamsToQuery(search, query);

      dispatch(ComplianceSearchActions.fetchMasterBanListRequest());
      const response = await ComplianceAPI.getMasterBanListRequest(query.toString(), signal);
      dispatch(ComplianceSearchActions.fetchMasterBanListSuccess(response.data));
      pagination.setCount(response.data.results.count);

      return response;
    },
    onError(error) {
      console.error("Error fetching master ban list search", error);
      dispatch(ComplianceSearchActions.fetchMasterBanListFailed());
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Master ban list</b>

      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              watchListBanPnlTab: {
                ...tabsTablesSearchState.watchListBanPnlTab,
                masterBanTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
          }}
        />
      </div>

      <Table
        columns={MASTER_BAN_LIST_COLUMNS}
        title={<TitleUpdated value={updated?.timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        data={results.rows}
        csv_table_name={WBP_TAB_TITLES.masterBanTable}
      />
    </div>
  );
};

export default MasterBanList;

import React from "react";

import useGeneralStyles from "../../useGeneralStyles";
import WithdrawalsRequestList from "../FundedTrader/components/WithdrawalsRequestList";
import { useAdminProviderDataContext } from "../../context";

const Withdrawals = (): React.ReactElement | null => {
  const generalClasses = useGeneralStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  if (!allowed_features.access_withdrawals) {
    return null;
  }

  return (
    <div className={generalClasses.containerBg}>
      <WithdrawalsRequestList />
    </div>
  );
};

export default Withdrawals;

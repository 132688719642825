import React from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import useGeneralStyles from "../../../../useGeneralStyles";
import { ContainerWithLoader } from "../../../../components/ui";

import EditNote from "./components/EditNote";
import useStyles from "./useStyles";

interface IProps {
  fetchFundedTraderNotes: () => Promise<void>;
}

const FundedTraderNotes: React.FC<IProps> = ({ fetchFundedTraderNotes }) => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const { notes } = useSelector((state) => state.fundedTrader);
  const { isLoading, data } = notes;

  return (
    <ContainerWithLoader isLoading={isLoading} report={data} noResultsMessage="There are no notes">
      <table className={generalClasses.table}>
        <tbody>
          {data.map(({ id, note, create_date, admin_account }) => (
            <React.Fragment key={id}>
              <tr className={classes.info}>
                <td>
                  <p>{moment(create_date).tz("America/Chicago").format("YYYY-MM-DD HH:mm")}</p>
                  <p>{admin_account.name}</p>
                </td>
              </tr>
              <tr className={classes.borderBottom}>
                <EditNote
                  fetchFundedTraderNotes={fetchFundedTraderNotes}
                  note_id={id}
                  note={note}
                />
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </ContainerWithLoader>
  );
};

export default FundedTraderNotes;

const ADD_ACCOUNT = {
  add_account: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    8: "The account already exists.",
  },
};
const ADD_USER = {
  add_user: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    8: "The user already exists.",
  },
};
const MODIFY_ACCOUNT = {
  modify_account: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
  },
};
const MODIFY_USER = {
  modify_user: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
  },
};
const ASSIGN_ACCOUNT = {
  assign_account_to_user: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    8: "Account already assigned.",
  },
};
const UN_ASSIGN_ACCOUNT = {
  remove_account_from_user: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    8: "Account already unassigned.",
  },
};

const SET_RMS_ACCOUNT_LIMIT = {
  "Demo-IB": {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    13: "Permission denied.",
  },
};
const SET_RMS_PRODUCT_CODE_LIMIT = {
  "Demo-IB": {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    13: "Permission denied.",
  },
};
const EQUITY_RUN = {
  "Rithmic-FCM": {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
  },
};

const RESET_AUTO_LIQUIDATE_THRESHOLD_VALUE = {
  reset_auto_liquidate_threshold_value: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
  },
};

const SET_USER_STATUS = {
  set_user_status: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
  },
};

const SET_USER_EXCHANGE_ENTITLEMENTS = {
  set_user_exchange_entitlements: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    13: "Permission denied.",
  },
};

const SET_FCM_MULTIPLE_LIQUIDATION_CRITERIA = {
  set_fcm_multiple_liquidation_criteria: {
    6: "Critical field not present or too many fields.",
    7: "Bad data.",
    13: "Permission denied.",
  },
};

export const ERRORS_DICTIONARY = {
  ...ADD_ACCOUNT,
  ...ADD_USER,
  ...MODIFY_ACCOUNT,
  ...MODIFY_USER,
  ...ASSIGN_ACCOUNT,
  ...UN_ASSIGN_ACCOUNT,
  ...SET_RMS_ACCOUNT_LIMIT,
  ...EQUITY_RUN,
  ...SET_RMS_PRODUCT_CODE_LIMIT,
  ...RESET_AUTO_LIQUIDATE_THRESHOLD_VALUE,
  ...SET_USER_STATUS,
  ...SET_USER_EXCHANGE_ENTITLEMENTS,
  ...SET_FCM_MULTIPLE_LIQUIDATION_CRITERIA,
} as const;

import React from "react";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { mergeWith } from "lodash";

interface IProps {
  barData: Record<string, unknown>;
  externalOptions?: Record<string, unknown>;
  height?: number;
}

const ChartBar = ({ barData, externalOptions, height = 200 }: IProps): React.ReactElement => {
  ChartJS.register(DataLabelsPlugin);

  const options = {
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
    plugins: {
      tooltip: {
        position: "nearest",
      },
      datalabels: {
        display: true,
        color: "black",
        offset: -4,
        font: { size: "10" },
        anchor: "end",
        align: "end",
      },
    },
  };

  return (
    <div>
      {/*@ts-ignore*/}
      <Bar data={barData} options={mergeWith(options, externalOptions)} height={height} />
    </div>
  );
};

export default ChartBar;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/";

import { useAuthContext } from "../../../context";

const useStyles = makeStyles({
  debugContainer: {
    marginLeft: 0,
    width: "100%",
  },
});

interface IProps {
  children: React.ReactNode;
  path: string;
  exact?: boolean;
}
const Private = ({ children, ...rest }: IProps): React.ReactElement => {
  const classes = useStyles();
  const { token, expired } = useAuthContext();
  const { pathname } = useLocation();
  const isDebugPage = pathname === "/app-logs";

  return (
    <div
      className={cx({
        [classes.debugContainer]: isDebugPage,
        "main logged-in": token && !isDebugPage,
        main: !token && !isDebugPage,
      })}
    >
      <Route
        {...rest}
        render={() =>
          token !== "" && !expired ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
        }
      />
    </div>
  );
};

export default Private;

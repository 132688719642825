import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";
import {
  copyToClipboardInXlsFormatAccountPlans,
  IColum,
} from "../../../../../utils/copyToClipboardInXlsFormat";
import useGeneralStyles from "../../../../../useGeneralStyles";

const COLUMNS: Array<IColum> = [
  {
    by: "id",
    title: "IID",
    path: "a_id",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "acc_email",
  },
  {
    by: "reg_ip",
    title: "RegIp",
    path: "reg_ip",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
  {
    by: "country",
    title: "Country",
    path: "country",
  },
  {
    by: "zip",
    title: "Zip",
    path: "zip",
  },
  {
    by: "city",
    title: "City",
    path: "city",
  },
  {
    by: "adress",
    title: "Address",
    path: "address",
  },
  {
    by: "joined",
    title: "Joined",
    path: "joined",
  },
  {
    by: "Total_spent",
    title: "total_spent",
    path: "total_spent",
  },
  {
    by: "total_orders",
    title: "Total orders",
    path: "total_orders",
  },
  {
    by: "total_new_purchases",
    title: "Total new purchases",
    path: "total_new_purchases",
  },
  {
    by: "total_paid_resets",
    title: "Total paid resets",
    path: "total_paid_resets",
  },
  {
    by: "total_free_resets",
    title: "Total free resets",
    path: "total_free_resets",
  },
  {
    by: "total_rebills",
    title: "Total rebills",
    path: "total_rebills",
  },
];

export const PLANS_COLUMNS: Array<IColum> = [
  {
    by: "plan_name",
    title: "Plan Name",
    path: "plan.name",
  },
  // {
  //   by: "price_usd",
  //   title: "price_usd",
  //   path: "price_usd",
  //   copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  // },
  {
    by: "expires",
    title: "Expires",
    path: "expires",
  },
  {
    by: "failtures",
    title: "Failures",
    path: "failures",
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "passed",
    title: "Passed",
    path: "passed",
    copyToClipBoardCallBack: (value) => (value ? "passed" : ""),
  },
  {
    by: "start",
    title: "Start",
    path: "start",
  },
  {
    by: "canceled",
    title: "Canceled",
    path: "canceled",
  },
  {
    by: "campaign_property",
    title: "Campaign property",
    path: "campaign_property_id",
  },
  {
    by: "upgrade",
    title: "Upgrade",
    path: "upgrade",
  },
  {
    by: "discount_code",
    title: "Discount code",
    path: "discount_code_id",
  },
  {
    by: "free_resets",
    title: "Free resets",
    path: "free_resets",
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { accounts, plans } = useSelector((state) => state.complianceSearch.plans.data);
  const handleCopyToClipBoard = () =>
    copyToClipboardInXlsFormatAccountPlans(
      accounts,
      COLUMNS,
      plans,
      PLANS_COLUMNS,
      "E2T plans data"
    );

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={16}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <td key={r.title}>{r.title}</td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import newSortBy from "../../../../utils/newSortBy";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import useGeneralStyles from "../../../../useGeneralStyles";
import { useComplianceSearchContext } from "../../../../context";

const COLUMNS: Array<IColum> = [
  {
    by: "reg_ip",
    title: "RegIp",
    path: "reg_ip",
    isSortable: true,
  },
  {
    by: "count",
    title: "Unique email",
    path: "used_times",
    isSortable: true,
  },
];

const UniqueRegistrationIpTable = (): React.ReactElement => {
  const baseClasses = useStyles();
  const generalClasses = useGeneralStyles();
  const { data, loading } = useSelector((state) => state.complianceSearch.uniqueReqIp);
  const accounts_table = useSelector((state) => state.complianceSearch.accounts_table);
  const {
    unique_registration_ip_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
      <Table
        theadTitle="Unique emails per registration IP"
        loading={accounts_table.loading || loading}
        columns={COLUMNS}
        extractKey={(row) => row.reg_ip}
        data={data}
        csv_table_name={"E2T Accounts Investigation - Unique emails per registration IP"}
        handleSortBy={handleSortBy}
        sortBy={sortBy}
      />
    </div>
  );
};

export default UniqueRegistrationIpTable;

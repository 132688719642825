import { ForcedToLiveAccountsActionType, TForcedToLiveAccountsAction } from "./index.types";
import { TGetForcedToLiveAccountsResponse } from "../../api/funds/index.types";

export const ForcedToLiveAccounts = {
  // ForcedToLiveAccounts
  fetchForcedToLiveAccountsRequest: (): TForcedToLiveAccountsAction => ({
    type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchRequest,
  }),
  fetchForcedToLiveAccountsSuccess: (
    payload: TGetForcedToLiveAccountsResponse["data"]
  ): TForcedToLiveAccountsAction => ({
    type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchSuccess,
    payload,
  }),
  fetchForcedToLiveAccountsFailed: (): TForcedToLiveAccountsAction => ({
    type: ForcedToLiveAccountsActionType.forcedToLiveAccountsFetchFailed,
  }),
};

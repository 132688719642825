import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  expirationSubOptionsContainer: {
    paddingLeft: 20,
  },
  radio: {
    "&$checked": {
      color: "#1288e5",
    },
  },
  cursorHelp: {
    cursor: "help",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      margin: "0 0 5px 0",
      textTransform: "capitalize",
    },
  },
  buttonsContainer: {
    display: "flex",
  },
  noWrap: {
    whiteSpace: "nowrap",
    "& p": {
      margin: 0,
    },
  },
  disableHover: {
    "&:hover td": {
      cursor: "default !important",
      background: "inherit",
    },
  },
  pendingColor: {
    "& td": {
      backgroundColor: "#ffff003b !important",
    },
  },
});

export default useStyles;

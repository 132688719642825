import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../../components/ui/Select";
import { APIFunds } from "../../../../api";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";

const E2T_AFFILIATE_VALUES = [
  {
    name: "not_an_affiliate",
    text: "Not an Affiliate ",
  },
  {
    name: "small_affiliate",
    text: "Small Affiliate",
  },
  {
    name: "medium_affiliate",
    text: "Medium Affiliate",
  },
  {
    name: "large_affiliate",
    text: "Large Affiliate",
  },
];

const E2TAffiliateValue = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { accounts_id, e2t_affiliate_value } = useSelector((state) => state.fundedTrader);

  const handleChangeE2TAffiliateValue = async (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown | string }>
  ) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.updateAffiliateValueRequest(accounts_id, {
        e2t_affiliate_value: event.target.value as string,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error handle change E2T affiliate value", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  return (
    <div>
      <Select
        options={E2T_AFFILIATE_VALUES}
        label="E2T Affiliate"
        inputLabel="E2T Affiliate"
        onChange={handleChangeE2TAffiliateValue}
        value={e2t_affiliate_value || ""}
        size="small"
      />
    </div>
  );
};

export default E2TAffiliateValue;

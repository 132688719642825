import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { IEditingHTML } from "../../index";

interface IProps {
  handleSave: () => void;
  handleCloseEditing: () => void;
  isOpen: boolean;
  isUpdating: boolean;
  setEditing: (a: boolean) => void;
  editingHtml: {
    label: string;
    html: string;
    id: number;
  };
  setEditingHtml: (a: IEditingHTML) => void;
}

const useStyles = makeStyles({
  content: {
    width: "500px",
  },
  input: {
    minWidth: "100%",
  },
});

const EditHTMLMessageDialog: React.FC<IProps> = ({
  isOpen,
  setEditing,
  editingHtml,
  handleSave,
  setEditingHtml,
  handleCloseEditing,
  isUpdating,
}) => {
  const classes = useStyles();

  const handleChangeHtmlValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingHtml({
      ...editingHtml,
      html: e.target.value,
    });
  };

  return (
    <Dialog open={isOpen} onClose={() => setEditing(false)}>
      <DialogTitle>Edit content</DialogTitle>

      <DialogContent className={classes.content}>
        <TextField
          className={classes.input}
          multiline
          value={editingHtml.html}
          onChange={handleChangeHtmlValue}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseEditing} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined" disabled={isUpdating}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHTMLMessageDialog;

import React, { useReducer } from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import useStyles from "./useStyles";
import { APIAffiliates } from "../../../../api";
import { INewAffiliateData } from "../../../../api/affiliates/intex.types";
import { FormInput, Snackbar } from "../../../../components/ui";
import { AFFILIATES_API_URLS } from "../../../../api/affiliates";
import stateReducer, { initialState } from "./stateReducer";
import { newAffiliateValidationSchema, newAffiliateInitialValues } from "./utils";

interface IHandleNewAffiliateData {
  formValues: INewAffiliateData;
  form: FormikHelpers<INewAffiliateData>;
}

interface IOnError {
  response: {
    data: {
      message: string;
      errors: {
        [key: string]: string;
      }[];
    };
  };
}

const AddNewAffiliate = (): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const handleOpenDialog = () => dispatch({ type: "openDialog" });
  const handleCloseDialog = () => dispatch({ type: "closeDialog" });

  const handleAddAffiliate = useMutation({
    mutationKey: [AFFILIATES_API_URLS.addNewAffiliate, "createAffiliate"],
    mutationFn: async (data: IHandleNewAffiliateData) => {
      const { formValues, form } = data;
      const response = await APIAffiliates.createAffiliateRequest(formValues);
      dispatch({
        type: "showSnackbar",
        payload: { snackbarType: "success", isOpenSnackbar: true },
      });
      form.resetForm();
      return response.data;
    },
    onSuccess: () => {
      dispatch({
        type: "showSnackbar",
        payload: { snackbarType: "success", isOpenSnackbar: true },
      });
      queryClient.invalidateQueries({ queryKey: [AFFILIATES_API_URLS.getAffiliates] });
    },
    onError: (error: IOnError, data) => {
      const { form } = data;
      error.response.data.errors.forEach((error) => {
        const [name] = Object.entries(error);
        form.setFieldError(name[0], name[1]);
      });

      console.error("error hande save affiliate account", error);
      dispatch({
        type: "showSnackbar",
        payload: {
          snackbarType: "error",
          isOpenSnackbar: true,
          responseErrorMessage: error.response.data.message,
        },
      });
    },
  });

  const handleCloseSnackbar = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "showSnackbar", payload: { snackbarType: "", isOpenSnackbar: false } });
  };

  return (
    <>
      <Dialog open={state.isOpenDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add new affiliate</DialogTitle>

        <Formik
          initialValues={newAffiliateInitialValues}
          onSubmit={(formValues: INewAffiliateData, form) => {
            handleAddAffiliate.mutate({ formValues, form });
          }}
          validateOnChange
          validationSchema={newAffiliateValidationSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <DialogContent dividers className={classes.dialogContentRoot}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Field
                      name="name"
                      label="Name"
                      placeholder="Name"
                      component={FormInput}
                      contextualHelpText="Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="email"
                      label="Email"
                      placeholder="Email"
                      component={FormInput}
                      contextualHelpText="Email"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="code"
                      label="Affiliate code"
                      placeholder="Affiliate code"
                      component={FormInput}
                      contextualHelpText="Affiliate code"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isValid || !dirty}
                  data-test="submit-button"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>

        <Snackbar
          type={state.snackbarType}
          isOpenSnackbar={state.isOpenSnackbar}
          responseSuccessMessage="Success"
          responseErrorMessage={`${state.responseErrorMessage}`}
          onClose={handleCloseSnackbar}
        />
      </Dialog>

      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Add new affiliate
      </Button>
    </>
  );
};

export default AddNewAffiliate;

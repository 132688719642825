import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import { APIFunds } from "../../../../api";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";

const ProfessionalLiveSimData = (): React.ReactElement | null => {
  const dispatch = useDispatch();

  const { accounts_id, is_professional_live_sim, isLoading } = useSelector(
    (state) => state.fundedTrader
  );

  const handleChangeLiveSimProfessionalStatus = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.updateFundedTraderDataRequest(accounts_id, {
        is_professional_live_sim: event.target.checked,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error handle update liveSim professional status", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <FormControlLabel
        label="Pro trader in Rithmic"
        control={
          <Checkbox
            size="small"
            disabled={isLoading}
            onChange={handleChangeLiveSimProfessionalStatus}
            color="primary"
            checked={is_professional_live_sim}
            name="Pro trader in Rithmic"
          />
        }
      />
    </div>
  );
};

export default ProfessionalLiveSimData;

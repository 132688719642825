import React from "react";
import { styled } from "@material-ui/core";
import { KeyboardDatePickerProps } from "@material-ui/pickers/DatePicker/DatePicker";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { DATE_FORMAT } from "../../../constants";

const StyledDatePicker = styled(KeyboardDatePicker)({
  position: "relative",
  "& .MuiInputBase-input": {
    width: 90,
  },
});

interface IProps extends KeyboardDatePickerProps {
  label: string;
  name: string;
  value: string | null;
  disabled?: boolean;
  autoOk?: boolean;
}
const DatePicker = ({ label, name, value, onChange, ...rest }: IProps): React.ReactElement => (
  <StyledDatePicker
    inputVariant="outlined"
    label={label}
    name={name}
    value={value}
    placeholder="2018/10/10/"
    onChange={onChange}
    format={DATE_FORMAT}
    clearable
    {...rest}
  />
);

export default DatePicker;

import React from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";

import { PAYMENTS_STATUSES } from "../../../../constants";
import PaymentsByCategory from "../PaymentsByCategory";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";

import { useFeedContext } from "../../../../context";

const PaymentsByStatus = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { groupBy, setGroupBy, setSearch, search } = useFeedContext();

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setGroupBy(e.target.value as string);
  };
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      email: e.target.value,
    });
  };
  return (
    <div className={generalClasses.containerBg}>
      <div>
        <FormControl variant="outlined" className={classes.formControlContainer}>
          <InputLabel classes={{ root: classes.label }}>Filter by</InputLabel>
          <Select autoWidth value={groupBy} onChange={handleChange}>
            {Object.keys(PAYMENTS_STATUSES).map((key) => {
              const status = PAYMENTS_STATUSES[key];
              return (
                <MenuItem key={key} value={status.status}>
                  {status.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          name="email"
          onChange={handleChangeEmail}
          value={search.email}
          label="Filter by email"
          variant="outlined"
          className={classes.email}
        />
      </div>

      <PaymentsByCategory groupBy={groupBy} inputProps={{ max: 25 }} />
    </div>
  );
};

export default PaymentsByStatus;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  preloader: {
    marginTop: 10,
  },
  circle: {
    color: "#1886cf",
  },
  tableContainer: {
    width: "100%",
  },
  preloaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  noResults: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
});

export default useStyles;

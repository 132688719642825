import React from "react";

import useStyles from "../../useStyles";
import PnLCalculator from "../PnLCalculator";
import HeliosNotesSearch from "../../../HeliosNotesSearch";
import MasterBanList from "../MasterBanList";

const WatchListBanPnlTab: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.tableContainer}>
        <PnLCalculator />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <MasterBanList />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosNotesSearch />
      </div>
    </div>
  );
};

export default WatchListBanPnlTab;

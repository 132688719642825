import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "@material-ui/core";

import useStyles from "./useStyles";
import { TabPanel } from "../../components/ui";
import ChangeLogs from "./components/ChangeLogs/ChangeLogs";
import DiscountCodes from "./components/DiscountCodes/DiscountCodes";
import { useAdminProviderDataContext } from "../../context";

const Discounts = (): React.ReactElement => {
  const classes = useStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const [value, setValue] = useState(0);

  const handleChange = (event: unknown, newValue: number) => setValue(newValue);

  return (
    <>
      {allowed_features.access_discount_codes_logs && (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.rootTabs,
          }}
        >
          <Tab label="Discount codes" />
          <Tab label="Discount codes logs" />
        </Tabs>
      )}

      <TabPanel value={value} index={0}>
        <DiscountCodes />
      </TabPanel>

      {allowed_features.access_discount_codes_logs && (
        <TabPanel value={value} index={1}>
          <ChangeLogs />
        </TabPanel>
      )}
    </>
  );
};

export default Discounts;

import React, { useEffect, useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

import useStyles from "./useStyles";
import {
  handleCopySelectedCellsToClipBoard,
  onBlockKeyDownKeys,
  removeSelectedCells,
  getFormData,
  getChangedCsv,
  isValidPastedValue,
  getChangedCsvAfterPaste,
  handleCopySelectedRowsToClipBoard,
  addCellWithCheckboxIntoRow,
  getUpdatedCheckboxSelections,
} from "./utils";
import { RithmicFilesAPI } from "../../../../api/rithmicFiles";
import { TTypeMessage } from "../../../../components/ui/Snackbar";
import * as Type from "../../index.types";
import { Actions, Feedback } from "./components";

interface IProps {
  csv: Type.TCsv;
  currentFileName: string;
  folderPath: Type.TFolderPath;
}
const CsvView: React.FC<IProps> = ({ csv, currentFileName, folderPath }) => {
  const isArchiveFolder = folderPath === "archive";
  const classes = useStyles();
  const [rowSelections, setRowSelections] = useState<boolean[]>([]);
  const [csvWithActions, setCsvWithActions] = useState<Type.TCsv>([]);

  const [isChanged, setIsChanged] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [status, setStatus] = useState<TTypeMessage>("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<Type.TDialogWindowTypes>("");
  const [selectedCells, setSelectedCells] = useState<Type.TSelection>({
    start: { i: -1, j: -1 },
    end: { i: -1, j: -1 },
  });

  const [responseErrorMessage, setResponseErrorMessage] = useState("Error uploading file");

  const handleUploadCsv = async () => {
    try {
      const formData = getFormData(csvWithActions, currentFileName);
      setIsOpenSnackbar(true);

      try {
        setStatus("success");
        await RithmicFilesAPI.uploadFile(formData, "coperations");
      } catch (error) {
        console.error("Error in handleUploadCsv", error);
        setStatus("error");
        setResponseErrorMessage("Error uploading file");
      } finally {
        setIsChanged(false);
      }
    } catch (error) {
      console.log("Error in handleUploadCsv", error);
    }
  };
  const handleCloseSnackBar = () => setIsOpenSnackbar(false);
  const handleOpenConfirmDialog = (type: Type.TDialogWindowTypes) => setIsConfirmDialogOpen(type);
  const handleCancelRemoveRowsAndColumns = () => setIsConfirmDialogOpen("");

  const handleRemoveRows = () => {
    const newCsv = csvWithActions.filter((_, index) => !rowSelections[index]);
    setRowSelections(newCsv.map(() => false));
    setCsvWithActions(newCsv);
    setIsChanged(true);
    setIsConfirmDialogOpen("");
  };

  const handleRemoveSelectedCells = () =>
    removeSelectedCells({
      csv: csvWithActions,
      setCsv: setCsvWithActions,
      selectedCells,
      setIsChanged,
      setIsConfirmDialogOpen,
    });
  const handleParsePaste = (str: string) => {
    const newCSV = getChangedCsvAfterPaste(str, selectedCells, csvWithActions);
    setCsvWithActions(newCSV);
    return [[]];
  };

  const { start, end } = selectedCells;
  const hasSelection = start.i !== -1 || start.j !== -1 || end.i !== -1 || end.j !== -1;

  useEffect(() => {
    const newCsv = addCellWithCheckboxIntoRow(
      csv,
      rowSelections,
      setRowSelections,
      isArchiveFolder
    );
    setRowSelections(newCsv.map(() => false));
    setCsvWithActions(newCsv);
  }, [csv]);

  useEffect(() => {
    const newCsv = getUpdatedCheckboxSelections(
      csvWithActions,
      rowSelections,
      setRowSelections,
      isArchiveFolder
    );
    setCsvWithActions(newCsv);
  }, [rowSelections]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paperRoot}>
            <div
              className={classes.tableContainer}
              onKeyDown={onBlockKeyDownKeys}
              onPaste={(e) => {
                if (!isValidPastedValue(e)) {
                  setStatus("error");
                  setIsOpenSnackbar(true);
                  setResponseErrorMessage("Invalid value");
                }
              }}
            >
              <ReactDataSheet
                overflow="nowrap"
                data={csvWithActions}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = getChangedCsv(csvWithActions, changes);
                  setCsvWithActions(grid);
                  setIsChanged(true);
                }}
                selected={selectedCells}
                onSelect={({ start, end }) => {
                  if (!start.j) {
                    // prevent select cells with checkbox
                    return;
                  }
                  setSelectedCells({ start, end });
                }}
                handleCopy={(data) => handleCopySelectedCellsToClipBoard(data, csvWithActions)}
                parsePaste={handleParsePaste}
              />
            </div>
          </Paper>
        </Grid>

        {!!csv.length && isArchiveFolder && (
          <Actions
            hasSelection={hasSelection}
            isChanged={isChanged}
            handleUploadCsv={handleUploadCsv}
            handleOpenConfirmDialog={handleOpenConfirmDialog}
            handleCopySelectedRowsToClipBoard={() =>
              handleCopySelectedRowsToClipBoard(csvWithActions, rowSelections)
            }
            isSelectedRows={rowSelections.some((row) => row)}
          />
        )}

        <Feedback
          status={status}
          isOpenSnackbar={isOpenSnackbar}
          isConfirmDialogOpen={isConfirmDialogOpen}
          responseErrorMessage={responseErrorMessage}
          handleCloseSnackBar={handleCloseSnackBar}
          handleRemoveSelectedCells={handleRemoveSelectedCells}
          handleCancelRemoveRowsAndColumns={handleCancelRemoveRowsAndColumns}
          handleRemoveRows={handleRemoveRows}
        />
      </Grid>
    </div>
  );
};

export default CsvView;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { MOMENT_DAYS, MOMENT_PERIODS_BACK } from "../../../utils/moment";
import { DATES_VALUE, FILTER_BY_DATES } from "../../../constants";
import Select from "../Select";

interface IProps {
  dateBy: DATES_VALUE;
  handleChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => void;
  getReport: (startDate: string, endDate: string, isAllTime?: boolean) => void;
  periodStart: string;
  periodEnd: string;
}

export const useStyles = makeStyles({
  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export const SelectByDate = ({
  dateBy,
  handleChange,
  getReport,
  periodStart,
  periodEnd,
}: IProps): React.ReactElement => {
  useEffect(() => {
    if (dateBy === DATES_VALUE.today) {
      getReport(MOMENT_DAYS.today, MOMENT_DAYS.today);
    }
    if (dateBy === DATES_VALUE.yesterday) {
      getReport(MOMENT_DAYS.yesterday, MOMENT_DAYS.yesterday);
    }
    if (dateBy === DATES_VALUE.days30back) {
      getReport(MOMENT_PERIODS_BACK.days30back, MOMENT_DAYS.today);
    }
    if (dateBy === DATES_VALUE.thisWeek) {
      getReport(MOMENT_DAYS.thisWeekBegin, MOMENT_DAYS.thisWeekEnd);
    }
    if (dateBy === DATES_VALUE.thisMonth) {
      getReport(MOMENT_DAYS.thisMonthBegin, MOMENT_DAYS.thisMonthEnd);
    }
    if (dateBy === DATES_VALUE.lastMonth) {
      getReport(MOMENT_DAYS.prevMonthBegin, MOMENT_DAYS.prevMonthEnd);
    }
    if (dateBy === DATES_VALUE.thisYear) {
      getReport(MOMENT_DAYS.thisYearBegin, MOMENT_DAYS.thisYearEnd);
    }
    if (dateBy === DATES_VALUE.lastYEar) {
      getReport(MOMENT_DAYS.lastYearBegin, MOMENT_DAYS.lastYearEnd);
    }
    if (dateBy === DATES_VALUE.allTIme) {
      getReport("", MOMENT_DAYS.today, true);
    }
    if (dateBy === DATES_VALUE.thisQuarter) {
      getReport(MOMENT_DAYS.thisQuarterBegin, MOMENT_DAYS.nextQuarterBegin);
    }
    if (dateBy === DATES_VALUE.customPeriod && periodStart && periodEnd) {
      getReport(periodStart, periodEnd);
    }

    if (dateBy === DATES_VALUE.numberMonthAgo_1) {
      getReport(MOMENT_DAYS.oneMonthAgoBegin, MOMENT_DAYS.oneMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_2) {
      getReport(MOMENT_DAYS.towMonthAgoBegin, MOMENT_DAYS.twoMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_3) {
      getReport(MOMENT_DAYS.treMonthAgoBegin, MOMENT_DAYS.treMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_4) {
      getReport(MOMENT_DAYS.fourMonthAgoBegin, MOMENT_DAYS.fourMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_5) {
      getReport(MOMENT_DAYS.fiveMonthAgoBegin, MOMENT_DAYS.fiveMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_6) {
      getReport(MOMENT_DAYS.sixMonthAgoBegin, MOMENT_DAYS.sixMonthAgoEnd);
    }
    if (dateBy === DATES_VALUE.numberMonthAgo_7) {
      getReport(MOMENT_DAYS.sevenMonthAgoBegin, MOMENT_DAYS.sevenMonthAgoEnd);
    }
  }, [dateBy, periodStart, periodEnd]);

  return (
    <Select
      inputLabel="Filter by"
      value={dateBy}
      onChange={handleChange}
      options={FILTER_BY_DATES}
      label="Date"
    />
  );
};

export default SelectByDate;

import React from "react";
import { FormikHelpers, Field } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { useRouteMatch } from "react-router-dom";

import useStyles from "./useStyles";
import { ICode } from "../types";
import { useAdminProviderDataContext } from "../../../../context";
import { FormCheckbox, FormRadioGroup } from "../../index";
import { FAILOVER_TYPE_OPTIONS } from "../utils";

interface IProps {
  values: ICode;
  isEditingDiscountCode?: boolean;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
}
const Properties: React.FC<IProps> = ({ values, setFieldValue }) => {
  const classes = useStyles();
  const { user } = useAdminProviderDataContext();
  const { create_active_code, set_code_expiration_props } = user.allowed_features;
  const matchCustomersPage = useRouteMatch("/customers");

  const handleChangeTypeDiscount = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue("percent", event.target.value === "percent");
  const StyledRadio = ({ ...rest }) => (
    <Radio color="primary" classes={{ root: classes.radio }} {...rest} />
  );

  return (
    <>
      <h3>Properties</h3>

      <FormControl component="fieldset">
        <FormLabel component="legend">Discount type</FormLabel>
        <RadioGroup onChange={handleChangeTypeDiscount} value={values.percent ? "percent" : "flat"}>
          <FormControlLabel value="percent" control={<StyledRadio />} label="Percent" />
          <FormControlLabel value="flat" control={<StyledRadio />} label="Discount amount" />
        </RadioGroup>
      </FormControl>

      {values.plan !== -2 && (
        <div>
          <Field
            color="primary"
            name="one_time"
            label="Lifetime"
            component={FormCheckbox}
            disabled={values.use_expiration}
          />
        </div>
      )}

      <FormControlLabel
        control={
          <Checkbox
            name="autoExpires"
            checked={values.autoExpires}
            onChange={(e) => {
              if (!e.target.checked) {
                setFieldValue("expires", null);
              }
              setFieldValue("autoExpires", e.target.checked);
            }}
            color="primary"
          />
        }
        label={
          <div>
            Auto Expires{" "}
            <Tooltip
              title="Chose the date when the code no longer be avialable for use at checkout."
              placement="top"
            >
              <InfoIcon className={classes.cursorHelp} />
            </Tooltip>
          </div>
        }
      />
      {set_code_expiration_props && (
        <div>
          <FormGroup>
            <Field
              color="primary"
              name="use_expiration"
              label={
                <div>
                  Expiration{" "}
                  <Tooltip
                    title="Set the duration for which the discount rate will be applied to a subscription."
                    placement="top"
                  >
                    <InfoIcon className={classes.cursorHelp} />
                  </Tooltip>
                </div>
              }
              component={FormCheckbox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setFieldValue("one_time", true);
                }
                setFieldValue("use_expiration", e.target.checked);
                setFieldValue("use_failover_amt", e.target.checked);
                setFieldValue("use_failover_type", e.target.checked);
                setFieldValue("expiration", null);
                setFieldValue("failover_type", null);
                setFieldValue("failover_amt", null);
              }}
            />
            {values.use_expiration && (
              <div className={classes.expirationSubOptionsContainer}>
                <Field
                  color="primary"
                  name="use_failover_type"
                  label="Discount Type Past Expiration"
                  component={FormCheckbox}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      setFieldValue("use_failover_amt", true);
                    } else {
                      setFieldValue("use_failover_amt", e.target.checked);
                      setFieldValue("failover_type", null);
                      setFieldValue("failover_amt", null);
                    }
                    setFieldValue("use_failover_type", e.target.checked);
                  }}
                />

                <div className={classes.expirationSubOptionsContainer}>
                  <Field
                    name="failover_type"
                    component={FormRadioGroup}
                    color="primary"
                    options={FAILOVER_TYPE_OPTIONS}
                    isDisabled={!values.use_failover_type}
                  />
                </div>

                <Field
                  color="primary"
                  name="use_failover_amt"
                  label="Discount Amount Past Expiration"
                  component={FormCheckbox}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      setFieldValue("use_failover_type", true);
                    } else {
                      setFieldValue("use_failover_type", e.target.checked);
                    }
                    setFieldValue("use_failover_amt", e.target.checked);
                  }}
                />
              </div>
            )}
          </FormGroup>
        </div>
      )}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!create_active_code}
              checked={values.active}
              onChange={(e) => setFieldValue("active", e.target.checked)}
              color="primary"
            />
          }
          label="Active"
        />
      </div>

      {matchCustomersPage && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isReset}
                onChange={(e) => setFieldValue("isReset", e.target.checked)}
                color="primary"
              />
            }
            label="Reset"
          />
        </div>
      )}
    </>
  );
};

export default Properties;

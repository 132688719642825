import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  vacationInfo: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
});

export default useStyles;

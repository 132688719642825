import React from "react";
import { uniqueId } from "lodash";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { CopyToClipboard } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../constants";

export const COLUMNS: Array<IColum> = [
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value: unknown): React.ReactElement => {
      const stringValue = value as unknown as string;
      return (
        <>
          {stringValue?.split(", ").map((v) => (
            <CopyToClipboard key={`${v}_${uniqueId()}`} value={v} />
          ))}
        </>
      );
    },
  },
  {
    by: "sales",
    title: "Sales",
    path: "billingHistory",
    getCellValue: (value) => (
      <CopyToClipboard value={IntlFormatUSD((value as unknown as number) / CENTS_IN_DOLLAR)} />
    ),
  },
  {
    by: "20%",
    title: "20%",
    path: "w20",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
  {
    by: "80%",
    title: "80%",
    path: "w80",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
  {
    by: "liveLoss",
    title: "Live Loss",
    path: "liveLosses",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
  {
    by: "total_pnl",
    title: "Total PnL",
    path: "total_pnl",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
];

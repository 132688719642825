/* eslint-disable no-param-reassign */
import produce from "immer";
import { AgentAction, AgentActionType, IAgentState } from "./index.types";

export const initialState: IAgentState = {
  data: {
    email: "",
    id: 0,
    name: "",
    allowed_features: {} as Record<string, boolean>,
    active: false,
    preset: "",
    profile_photo: null,
    position: null,
    shift: null,
    start_date: null,
    used_vacation_allowance: 0,
    admin_accounts_notes: {
      count: 0,
      rows: [],
      active_notes: 0,
      loading: false,
    },
    admin_accounts_policies_preset: {
      name: "",
      name_preset: "",
    },
  },
  loading: false,
};

const agentReducer = (prevState = initialState, action: AgentAction): IAgentState =>
  produce(prevState, (draft: IAgentState): IAgentState => {
    switch (action.type) {
      case AgentActionType.fetchAgentRequest:
        draft.loading = true;
        break;
      case AgentActionType.fetchAgentSuccess:
        draft.loading = false;
        draft.data.id = action.payload.id;
        draft.data.email = action.payload.email;
        draft.data.name = action.payload.name;
        draft.data.allowed_features = action.payload.allowed_features;
        draft.data.active = action.payload.active;
        draft.data.preset = action.payload.preset;
        draft.data.profile_photo = action.payload.profile_photo;
        draft.data.position = action.payload.position;
        draft.data.shift = action.payload.shift;
        draft.data.start_date = action.payload.start_date;
        draft.data.used_vacation_allowance = action.payload.used_vacation_allowance;
        draft.data.admin_accounts_policies_preset = action.payload.admin_accounts_policies_preset;
        break;
      case AgentActionType.fetchAgentFailed:
        draft.loading = false;
        break;
      case AgentActionType.fetchAgentNotesRequest:
        draft.data.admin_accounts_notes.loading = true;
        break;
      case AgentActionType.fetchAgentNotesSuccess:
        draft.data.admin_accounts_notes.count = action.payload.data.count;
        draft.data.admin_accounts_notes.rows = action.payload.data.rows;
        draft.data.admin_accounts_notes.active_notes = action.payload.data.active_notes;
        draft.data.admin_accounts_notes.loading = false;
        break;
      case AgentActionType.fetchAgentNotesFailed:
        draft.data.admin_accounts_notes.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default agentReducer;

import React from "react";

import { PaginationWithLimit } from "../../components/ui";
import useGeneralStyles from "../../useGeneralStyles";
import EvaluationJourneyTabs from "./components/EvaluationJourneyTabs";
import { useEvaluationsContext } from "../../context";

const Evaluations = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { pagination } = useEvaluationsContext();
  const { count, limit, setLimit, setPage, page } = pagination;

  return (
    <div className={generalClasses.containerBg}>
      <EvaluationJourneyTabs />

      <div>
        <PaginationWithLimit
          count={count}
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPageState={setPage}
        />
      </div>
    </div>
  );
};

export default Evaluations;

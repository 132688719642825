/* eslint-disable no-param-reassign */
import produce from "immer";

import { ReduxAction } from "../static-types";
import { DiscountCodesData } from "../api/discounts/index.types";

export type DiscountCodesAction =
  | DiscountCodesFetchRequest
  | DiscountCodesFetchSuccess
  | DiscountCodesFetchFailed;

interface DiscountCodesFetchRequest extends ReduxAction {
  type: DiscountCodesActionType.fetchRequest;
}
interface DiscountCodesFetchSuccess extends ReduxAction {
  type: DiscountCodesActionType.fetchSuccess;
  payload: DiscountCodesData;
}
interface DiscountCodesFetchFailed extends ReduxAction {
  type: DiscountCodesActionType.fetchFailed;
}

export enum DiscountCodesActionType {
  fetchRequest = "discountCodes/FetchRequest",
  fetchSuccess = "discountCodes/FetchSuccess",
  fetchFailed = "discountCodes/FetchFailed",
}

export const DiscountCodesActions = {
  fetchRequest: (): DiscountCodesAction => ({
    type: DiscountCodesActionType.fetchRequest,
  }),
  fetchSuccess: (discountCodes: DiscountCodesData): DiscountCodesAction => ({
    type: DiscountCodesActionType.fetchSuccess,
    payload: discountCodes,
  }),
  fetchFailed: (): DiscountCodesAction => ({
    type: DiscountCodesActionType.fetchFailed,
  }),
};

export interface DiscountCodesState {
  data: DiscountCodesData;
  loading: boolean;
}

const initialState: DiscountCodesState = {
  data: {
    discount_codes: [],
    count: 0,
    usedDiscountCodesWithFullDiscount: [],
  },
  loading: false,
};

const discountCodesReducer = (
  prevState = initialState,
  action: DiscountCodesAction
): DiscountCodesState =>
  produce(prevState, (draft: DiscountCodesState): DiscountCodesState => {
    switch (action.type) {
      case DiscountCodesActionType.fetchRequest:
        draft.loading = true;
        break;
      case DiscountCodesActionType.fetchSuccess:
        // action.payload.usedDiscountCodesWithFullDiscount.forEach(({ discount_code_id }) => {
        // const codeIndex = action.payload.discount_codes.findIndex(
        //   ({ id }) => id === discount_code_id
        // );
        // if (codeIndex !== -1) {
        //   action.payload.discount_codes[codeIndex].code_count += 1;
        // }
        // });
        draft.data = action.payload;
        draft.loading = false;
        break;
      case DiscountCodesActionType.fetchFailed:
        draft.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default discountCodesReducer;

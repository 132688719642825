import * as Yup from "yup";
export const PRE_ENVOY_DETAILS_SCHEMA = Yup.object().shape({
  passes_before_envoy: Yup.number()
    .typeError(({ originalValue }) => `"${originalValue}" is invalid value`)
    .min(0, "Must be greater than 0 or equal to 0")
    .required("Past passes is required"),
  withdrawals_before_envoy: Yup.number()
    .min(0, "Must be greater than 0 or equal to 0")
    .typeError(({ originalValue }) => `"${originalValue}" is invalid value`)
    .required("Withdrawals is required"),
  total_withdrawn_before_envoy: Yup.number()
    .min(0, "Must be greater than 0 or equal to 0")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 2 digits after decimal or less",
      // @ts-ignore
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .typeError(({ originalValue }) => `"${originalValue}" is invalid value`)
    .required("Total Withdrawn is required"),
});

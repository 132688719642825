import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
});

export default useStyles;

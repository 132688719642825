import produce from "immer";

import {
  ISuspiciousCustomersState,
  SuspiciousCustomersAction,
  SuspiciousCustomersActionType,
} from "./index.types";

const initialState: ISuspiciousCustomersState = {
  data: [],
  count: 0,
  isLoading: false,
};

const SuspiciousCustomerReducer = (
  prevState = initialState,
  action: SuspiciousCustomersAction
): ISuspiciousCustomersState =>
  produce(prevState, (draft: ISuspiciousCustomersState) => {
    switch (action.type) {
      case SuspiciousCustomersActionType.suspiciousCustomersFetchRequest:
        draft.isLoading = true;
        break;
      case SuspiciousCustomersActionType.suspiciousCustomersFetchSuccess:
        draft.isLoading = false;
        draft.data = action.payload.rows;
        draft.count = action.payload.count;
        break;
      case SuspiciousCustomersActionType.suspiciousCustomersFetchFailed:
        draft.isLoading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default SuspiciousCustomerReducer;

import React, { ChangeEvent } from "react";
import { FormControl, TextField, Chip, styled } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getIn } from "formik";

import { InputMessage, ContextualHelp } from "../index";

const StyledFormControl = styled(FormControl)({
  width: "100%",
});

interface IProps {
  field: {
    name: string;
    value: string[];
  };
  form: {
    errors: {
      optionalObject: Record<string, string>;
    };
    setFieldValue: (path: string, values: string[]) => void;
    values: Record<string, string>;
  };
  label: string;
  placeholder: string;
  contextualHelpText: string;
  contextualHelpProps: Record<string, unknown>;
}
const ChipInput = ({
  field,
  form,
  label,
  placeholder,
  contextualHelpText,
  contextualHelpProps,
}: IProps): React.ReactElement => {
  const errorMessage = getIn(form.errors, field.name);
  const initialValue = getIn(form.values, field.name);

  const handleInput = (event: ChangeEvent<Record<string, never>>, value: string[]) => {
    event.persist();
    form.setFieldValue(field.name, value);
  };

  return (
    <StyledFormControl>
      <ContextualHelp title={contextualHelpText} {...contextualHelpProps}>
        <Autocomplete
          onChange={handleInput}
          multiple
          id="tags-filled"
          options={[]}
          value={initialValue}
          freeSolo
          limitTags={2}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
          )}
        />
      </ContextualHelp>
      {errorMessage && <InputMessage error={errorMessage} />}
    </StyledFormControl>
  );
};

export default ChipInput;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

import { List, ListItem, ListItemText } from "@material-ui/core";
import { stringDistance } from "../../../SimilarAccounts/utils";
import { useCustomerProvider } from "../../../../../../context";
import { IDbAccounts } from "../../../../../../api/dbModelsInterfaces";
import useStyles from "./useStyles";

interface ISimilarEmail {
  id: number;
  accounts_id: number;
  account: Pick<IDbAccounts, "email" | "id">;
  index: number;
}
interface IProps {
  assignToFundedTrader: null | number;
  setAssignToFundedTrader: (id: null | number) => void;
}

const SimilarFundedAccountsData: React.FC<IProps> = ({
  setAssignToFundedTrader,
  assignToFundedTrader,
}) => {
  const classes = useStyles();
  const { customer } = useCustomerProvider();

  const [similarEmails, setSimilarEmails] = useState<ISimilarEmail[]>([]);

  const {
    similarTradedAccounts: {
      data: {
        fundedTradersList,
        evaluation_completed_history,
        similarAccountsBasedOnNameAddressAndPhone,
      },
    },
  } = useSelector((state) => state.customer);

  useEffect(() => {
    const similarEmails: ISimilarEmail[] = [];

    fundedTradersList.forEach(({ account, accounts_id, id }) => {
      const sd = stringDistance(account.email, customer.email);
      if (sd > 0.9) similarEmails.push({ id, account, accounts_id, index: Number(sd.toFixed(2)) });
    });

    setSimilarEmails(similarEmails);
  }, [fundedTradersList]);

  const handleChangeSelectedEmail = (id: number | null) => {
    if (assignToFundedTrader === id) {
      setAssignToFundedTrader(null);
      return;
    }
    setAssignToFundedTrader(id);
  };

  return (
    <div>
      {!!evaluation_completed_history.length && (
        <div>
          <div>
            <b>Funded trader already exists with E2T Account ID:</b>
          </div>
          <div className={classes.sectionContainer}>
            <FormControl component="fieldset">
              <FormLabel>Assign to:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        evaluation_completed_history[0].funded_trader_id === assignToFundedTrader
                      }
                      onChange={() =>
                        handleChangeSelectedEmail(evaluation_completed_history[0].funded_trader_id)
                      }
                      name="email"
                    />
                  }
                  label={evaluation_completed_history[0].email}
                />
              </FormGroup>
            </FormControl>
            <List className={classes.e2tAccountsList}>
              {evaluation_completed_history.map(({ e2t_account_id }) => (
                <ListItem className={classes.e2tAccountsList} key={e2t_account_id}>
                  <ListItemText primary={e2t_account_id} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      )}
      {!!similarEmails.length && (
        <div>
          <div>
            <b>Funded traders with similar email:</b>
          </div>
          <div className={classes.sectionContainer}>
            <FormControl component="fieldset">
              <FormLabel>Assign to:</FormLabel>
              <FormGroup>
                {similarEmails.map(({ account, id }) => (
                  <FormControlLabel
                    key={account.email}
                    control={
                      <Checkbox
                        checked={id === assignToFundedTrader}
                        onChange={() => handleChangeSelectedEmail(id)}
                        name="email"
                      />
                    }
                    label={account.email}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      )}

      {!!similarAccountsBasedOnNameAddressAndPhone.length && (
        <div>
          <div>
            <b>Funded traders with similar phone, address and name:</b>
          </div>
          <div className={classes.sectionContainer}>
            <FormControl component="fieldset">
              <FormLabel>Assign to:</FormLabel>
              <FormGroup>
                {similarAccountsBasedOnNameAddressAndPhone.map(({ account, id }) => (
                  <FormControlLabel
                    key={account.id}
                    control={
                      <Checkbox
                        checked={id === assignToFundedTrader}
                        onChange={() => handleChangeSelectedEmail(id)}
                        name="email"
                      />
                    }
                    label={account.email}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimilarFundedAccountsData;

import React, { useEffect } from "react";
import { FormikHelpers } from "formik";
import { Grid } from "@material-ui/core/";
import { useSelector } from "react-redux";

import { AgentPresetsSelect, ContainerWithLoader } from "../../../../components/ui";
import AllowedFeaturesAutocomplete from "../AllowedFeaturesAutocomplete";
import utils from "../../utils";
import { IAgent } from "../../../../api/agents/index.types";
import { IPoliciesOptions } from "../../../../api/service/index.types";

interface IProps {
  values: IAgent;
  setFieldValue: FormikHelpers<IAgent>["setFieldValue"];
  setValues: (values: React.SetStateAction<IAgent>, shouldValidate?: boolean) => void;
}
const AgentFormFeaturesControls = ({
  values,
  setFieldValue,
  setValues,
}: IProps): React.ReactElement => {
  const { data, loading } = useSelector((state) => state.polices);
  const { policiesOptions, presetsOptions, optionsByCategory } = data;

  const handleChangePreset = (
    value: keyof IPoliciesOptions,
    formValues: IAgent,
    setValues: (values: React.SetStateAction<IAgent>, shouldValidate?: boolean) => void
  ) => {
    const allowed_features: { [key: string]: boolean } = {};
    policiesOptions.forEach((option) => {
      if (option[value]) {
        allowed_features[option.name] = true;
      }
    });

    setValues({
      ...formValues,
      preset: value,
      allowed_features,
    });
  };

  useEffect(() => {
    if (!loading) {
      const preset = utils.getAgentPreset(values.allowed_features, presetsOptions, policiesOptions);
      setFieldValue("preset", preset);
    }
  }, [values.allowed_features, presetsOptions, policiesOptions]);

  return (
    <ContainerWithLoader report={[1]} isLoading={loading}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <AgentPresetsSelect
            options={presetsOptions}
            value={values.preset}
            // @ts-ignore
            onChange={(preset) => handleChangePreset(preset, values, setValues)}
          />
        </Grid>
        {Object.keys(optionsByCategory)
          .filter((category) => category !== "Private")
          .map((category) => {
            return (
              <Grid item xs={4} key={category}>
                <AllowedFeaturesAutocomplete
                  label={category}
                  setFieldValue={setFieldValue}
                  options={optionsByCategory[category]}
                  allowed_features={values.allowed_features}
                />
              </Grid>
            );
          })}
      </Grid>
    </ContainerWithLoader>
  );
};

export default AgentFormFeaturesControls;

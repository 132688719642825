import React from "react";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  bottomControls: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  inputLimit: {
    border: "1px solid gray",
    padding: 5,
    background: "#f7f7f7",
    margin: "5px 0 10px",
    fontSize: "16px",
    borderRadius: 10,
  },
  verticalAlign: {
    display: "flex",
    alignItems: "center",
  },
  textFieldRoot: {
    width: 65,
    marginRight: 5,
  },
  input: {
    padding: "5.5px 10px",
  },
  navigateButtons: {
    margin: "0 3px",
    display: "inline-flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  labelRoot: {
    borderRadius: 10,
  },
});

interface IProps {
  count: number;
  // TODO fix limit should be number
  limit: string;
  page: number;
  setPageState: (a: number) => void;
  setLimit: (a: string) => void;
  inputProps?: {
    max: number;
    min?: number;
  };
  showFirstButton?: boolean;
  showLastButton?: boolean;
  isIgnoreCountTotalPage?: boolean;
  isHasMore?: boolean;
}

const Pagination = ({
  count,
  limit,
  setPageState,
  page,
  setLimit,
  inputProps,
  showFirstButton = true,
  showLastButton = true,
  isHasMore = true,
  isIgnoreCountTotalPage,
}: IProps): React.ReactElement => {
  const classes = useStyles();

  const setPage = (page: number) => {
    let value = page;
    const max = Math.ceil(count / parseInt(limit, 10));
    if (value >= max && !isIgnoreCountTotalPage) value = max - 1;
    if (value <= 0) value = 0;
    setPageState(value);
  };

  const handleChangeLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputProps && inputProps.max < +e.target.value) {
      return;
    }
    setLimit(e.target.value);
  };

  return (
    <div className={classes.bottomControls}>
      <div className={classes.verticalAlign}>
        {showFirstButton && (
          <div
            onClick={() => setPage(0)}
            className={classes.navigateButtons}
            role="button"
            tabIndex={-1}
          >
            {"|<"}
          </div>
        )}

        <div
          onClick={() => setPage(page - 1)}
          className={classes.navigateButtons}
          role="button"
          tabIndex={-1}
        >
          {"<"}
        </div>

        <div style={{ fontWeight: "bold" }}>{page + 1}</div>
        {!isIgnoreCountTotalPage && <div>/{Math.ceil(count / parseInt(limit, 10)) || 1}</div>}
        <div
          onClick={() => isHasMore && setPage(page + 1)}
          className={classes.navigateButtons}
          role="button"
          tabIndex={-1}
        >
          {">"}
        </div>
        {showLastButton && (
          <div
            onClick={() => setPage(Math.ceil(count / parseInt(limit, 10)) - 1)}
            className={classes.navigateButtons}
            role="button"
            tabIndex={-1}
          >
            {">|"}
          </div>
        )}
      </div>

      <div className={classes.verticalAlign}>
        <TextField
          {...inputProps}
          type="number"
          value={limit}
          onChange={handleChangeLimit}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          classes={{
            root: classes.textFieldRoot,
          }}
        />{" "}
        <span>/page</span>
      </div>
    </div>
  );
};

export default Pagination;

import React from "react";
import { Field, FormikHelpers } from "formik";
import { makeStyles, Button } from "@material-ui/core";

import { ICode } from "../DiscountsModal/types";
import { FormInput } from "../index";
import { generateRandomString } from "../../../utils";

const useStyles = makeStyles({
  codeContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  code: {
    width: "66%",
  },
});

interface IProps {
  label: string;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
  name: string;
}
const InputWithRandomGeneratorString: React.FC<IProps> = ({ label, name, setFieldValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.codeContainer}>
      <div className={classes.code}>
        <Field name={name} label={label} component={FormInput} variant="standard" />
      </div>
      <Button
        data-test="generate-discount-code-button"
        variant="contained"
        size="small"
        onClick={() => setFieldValue("code", generateRandomString())}
      >
        Generate
      </Button>
    </div>
  );
};

export default InputWithRandomGeneratorString;

import React from "react";

import lodash from "lodash";
import copy from "copy-to-clipboard";
import {
  IAccountBillingData,
  IAccountPlanData,
  IGetEvaluationsRithmicAccountsResponse,
} from "../api/compliance/index.types";

export interface IColum {
  by: string;
  title: string;
  path: string;
  copyToClipBoardCallBack?: (value: string) => string;
  getCellValue?: <T, R>(value: T, restValues: R) => string | React.ReactElement;
  isSortable?: boolean;
}

const getHeaders = (columns: IColum[]): string[] =>
  columns.filter((col) => !!col.title).map((c) => c.title);

const getRowData = <D,>(columns: IColum[], r: D) =>
  columns
    .filter((col) => !!col.title)
    .map((c) => {
      if (c.copyToClipBoardCallBack) {
        return c.copyToClipBoardCallBack(lodash.get(r, c.path));
      }
      return lodash.get(r, c.path);
    });

const getXls = (rows: string[][]) => rows.map((lines) => lines.join("\t")).join("\n");

type TCopyToClipboardInXlsFormat = <D>(arg: {
  data: (D & { sub_table_data?: { id: string; [key: string]: string | number }[] })[];
  columns: IColum[];
  sub_columns?: IColum[];
  table_name?: string;
}) => void;

const copyToClipboardInXlsFormat: TCopyToClipboardInXlsFormat = ({
  data,
  columns,
  sub_columns,
  table_name,
}) => {
  const headers = getHeaders(columns);

  const rows: string[][] = [headers];
  if (table_name) rows.unshift([table_name]);

  data.forEach((r) => {
    const row = getRowData(columns, r);
    rows.push(row);

    if (r.sub_table_data && sub_columns) {
      const headers = getHeaders(sub_columns);
      rows.push(headers);

      r.sub_table_data.forEach((sr) => {
        const row = getRowData(sub_columns, sr);
        rows.push(row);
      });
    }
  });

  copy(getXls(rows), { format: "text/plain" });
};

export const copyToClipboardInXlsFormatAccountPlans = (
  parentData: IAccountBillingData[],
  parentColumns: IColum[],
  childData: IAccountPlanData[],
  childColumns: IColum[],
  table_name?: string
): void => {
  const parentHeaders = getHeaders(parentColumns);
  const childHeaders = getHeaders(childColumns);

  const rows: string[][] = [parentHeaders];
  if (table_name) rows.unshift([table_name]);

  parentData.forEach((parentRow) => {
    const parentRowData = getRowData(parentColumns, parentRow); // parentColumns
    rows.push(parentRowData);

    const currentAccountPlans = childData.filter((plan) => {
      return plan.accounts_id === parentRow.a_id;
    });
    if (currentAccountPlans.length) {
      rows.push(childHeaders);
      currentAccountPlans.forEach((childRow) => {
        const childRowData = getRowData(childColumns, childRow); // childColumns
        rows.push(childRowData);
      });
    }
  });

  copy(getXls(rows), { format: "text/plain" });
};

export const copyToClipboardInXlsFormatTradedAccounts = (
  data: IGetEvaluationsRithmicAccountsResponse["data"],
  parentColumns: IColum[],
  childColumns: IColum[]
): void => {
  const parentHeaders = getHeaders(parentColumns);

  const rows: string[][] = [parentHeaders];

  data.accounts.forEach((parentRow) => {
    const parentRowData = getRowData(parentColumns, parentRow); // parentColumns
    rows.push(parentRowData);

    const accounts = data.tradedAccounts.filter((account) => account.email === parentRow.email);
    if (accounts.length) {
      const childHeaders = getHeaders(childColumns);
      rows.push(childHeaders);
      accounts.forEach((childRow) => {
        const passes = data.evaluation_completed_history.find(
          (e) => e.e2t_account_id === childRow.account
        );
        const childRowData = getRowData(childColumns, passes);
        rows.push(childRowData);
      });
    }
  });

  copy(getXls(rows), { format: "text/plain" });
};

export default copyToClipboardInXlsFormat;

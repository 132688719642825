import React from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import { DATE_FORMAT } from "../../../../../constants";
import useGeneralStyles from "../../../../../useGeneralStyles";
import useStyles from "./useStyles";

export const getCellValue = (field_name: string, value: string): React.ReactElement | string => {
  const getValue = (value: string, acceptedValue: string, declinedValue: string): string =>
    value === "true" ? acceptedValue : declinedValue;
  const fields = {
    accounts_id: function LinkToCustomer() {
      return value !== "n/a" ? <Link to={`customer/${value}`}>{value}</Link> : "n/a";
    },
    active: () => (value === "n/a" ? "n/a" : getValue(value, "✔", "✘")),
    accepted: () => (value === "n/a" ? "n/a" : getValue(value, "accepted", "declined")),
  };
  // @ts-ignore
  return typeof fields[field_name] === "function" ? fields[field_name]() : <>{value}</>;
};

interface IProps {
  handleSetSearchParams: (name: string) => void;
}
const TableBody = ({ handleSetSearchParams }: IProps): React.ReactElement => {
  const { rows } = useSelector((state) => state.discountCodesLogs.data);
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  return (
    <tbody>
      {rows.map(
        ({
          discount_code,
          admin_account,
          field_name,
          previous_field_value,
          new_field_value,
          log_time,
        }) => (
          <tr key={log_time}>
            <td className={generalClasses.tableHover}>
              <Link to={`discount/${discount_code.code}`}>{discount_code.code}</Link>
            </td>
            <td
              onClick={() => handleSetSearchParams(admin_account.name)}
              className={generalClasses.tableHover}
            >
              {admin_account.name}
            </td>
            <td>{field_name}</td>
            <td className={classes.code_values}>
              {getCellValue(field_name, previous_field_value)}
            </td>
            <td className={classes.code_values}>{getCellValue(field_name, new_field_value)}</td>
            <td>{moment(log_time).tz("America/Chicago").format(`${DATE_FORMAT} (H:mm)`)}</td>
          </tr>
        )
      )}
    </tbody>
  );
};

export default TableBody;

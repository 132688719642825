import moment_tz from "moment-timezone";
import { STANDARD_VACATION_DURATION } from "./constants";

export const getCooperationDuration = (date: string): string => {
  const getYearMonthLabel = (number: number, unit: string) => {
    if (number === 1) {
      return `${number} ${unit}`;
    } else {
      return `${number} ${unit}s`;
    }
  };

  const years = getYearMonthLabel(moment_tz().diff(date, "years"), "year");
  const months = getYearMonthLabel(moment_tz().diff(date, "months") % 12, "month");
  return `${years} and ${months} in company`;
};

export const getAvailableVocationDays = (date: string, used_vacation_allowance: number): number => {
  const fullYears = moment_tz().diff(date, "years");

  if (!fullYears) {
    return 0;
  }
  if (fullYears === 1) {
    return STANDARD_VACATION_DURATION - used_vacation_allowance;
  }
  return STANDARD_VACATION_DURATION + (fullYears - 1) - used_vacation_allowance;
};

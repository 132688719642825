import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import { useCustomerProvider } from "../../../context";
import { StatusList } from "../../../pages/Customer/Customer";

interface IProps {
  children: React.ReactNode;
}

const CustomerStatus: React.FC<IProps> = ({ children }) => {
  const { customer } = useCustomerProvider();
  const { banned_account, funded_trader } = useSelector((state) => state.customer.mainData.data);

  const [isStatusDanger, setStatusDanger] = useState(false);
  const [isStatusAttention, setStatusAttention] = useState(false);
  const [warning, setWarning] = useState(false);

  const mainContainerClasses = () =>
    cx("customer", {
      warning: warning || isStatusDanger || banned_account || funded_trader?.ban_date,
      attention: isStatusAttention,
    });

  useEffect(() => {
    const histories = customer.billing_histories.filter(({ status }) => status !== "accepted");

    if (histories.length) {
      const statusesList = new Set(histories.map(({ status }) => status));
      if (statusesList.has(StatusList.chargeback) || statusesList.has(StatusList.fraud)) {
        setStatusDanger(true);
        return;
      }

      if (statusesList.has(StatusList.refunded)) {
        setStatusAttention(true);
      }
    }
  }, [customer.billing_histories]);

  useEffect(() => {
    if (customer?.account_flags?.length) {
      setWarning(!!customer.account_flags[0]);
    }
  }, [customer.account_flags]);

  return <div className={mainContainerClasses()}>{children}</div>;
};

export default CustomerStatus;

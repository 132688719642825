import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../Table";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import SearchByListValues from "../SearchByListValues";
import { TCP_STEPS_COLUMNS } from "../../constants";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../../../context";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";

const TCPStepsSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const {
    data: { results, updated },
    loading,
  } = useSelector((state) => state.complianceSearch.tcpSteps);

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { names } = tabsTablesSearchState.fundedAccountQuickSearchTab.tcpStepsSearchTable.search;

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    enabled: !!names,
    queryKey: [COMPLIANCE_API_URLS.getTCPSteps, names, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchTCPStepsRequest());

      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(names, query, "names");

      const response = await ComplianceAPI.getTCPStepsRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchTCPStepsSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching TCP steps", error);
      dispatch(ComplianceSearchActions.fetchTCPStepsFailed());
    },
  });

  return (
    <div>
      <b className={classes.title}>TCP Steps</b>
      <SearchByListValues
        fetchData={(values) => {
          setTabsTablesSearchState({
            ...tabsTablesSearchState,
            fundedAccountQuickSearchTab: {
              ...tabsTablesSearchState.fundedAccountQuickSearchTab,
              tcpStepsSearchTable: {
                search: { names: values.names },
              },
            },
          });
        }}
        isLoading={loading}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={TCP_STEPS_COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.timestamp} />}
        csv_table_name="Funded account quick search - TCP Steps"
      />
    </div>
  );
};

export default TCPStepsSearch;

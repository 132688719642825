import React from "react";
import { TableContainer } from "@material-ui/core";
import { useSelector } from "react-redux";
import cx from "classnames";

import { ContainerWithLoader } from "../../../../components/ui";
import TableHead from "../TableHead";
import TableBody from "../TableBody";
import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "./useStyles";

const TableReport = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const {
    data: { loading, evaluations },
  } = useSelector((state) => state.evaluations);

  return (
    <div className={classes.tableScrollContainer}>
      <ContainerWithLoader report={evaluations.report} isLoading={loading}>
        <TableContainer classes={{ root: classes.customTableContainer }}>
          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <TableHead />
            <TableBody />
          </table>
        </TableContainer>
      </ContainerWithLoader>
    </div>
  );
};

export default TableReport;

import React, { useEffect, useState } from "react";
import { APIRithmic } from "../../../../api";
import { ICustomerE2TAccount } from "../../../../api/rithmic/index.types";
import { ContainerWithLoader, E2TAccountIDsList } from "../../../../components/ui";
interface IProps {
  email: string;
}

const E2TAccountsList: React.FC<IProps> = ({ email }) => {
  const [e2tAccounts, setE2tAccounts] = useState("");
  const [responseData, setResponseData] = useState<ICustomerE2TAccount[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (email) {
        try {
          setIsLoading(true);
          const {
            data: { e2tAccounts },
          } = await APIRithmic.getRithmicAccountsByCustomer(email);
          setResponseData(e2tAccounts);
          let accounts = "";
          e2tAccounts.forEach(({ account }, index) => {
            accounts += `${account}${e2tAccounts.length - 1 === index ? "" : ", "}`;
          });
          setE2tAccounts(accounts.toString());
        } catch (error) {
          console.error("Error fetching getRithmicAccountsByCustomer", error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [email]);

  return (
    <div>
      <b>E2T Accounts</b>
      <ContainerWithLoader
        report={responseData}
        isLoading={isLoading}
        noResultsMessage="There are not e2t accounts"
      >
        <E2TAccountIDsList accounts={e2tAccounts} />
      </ContainerWithLoader>
    </div>
  );
};

export default E2TAccountsList;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableScrollContainer: {
    overflowX: "scroll",
  },
  customTableContainer: {
    height: "80vh",
  },
});

export default useStyles;

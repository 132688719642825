import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { EditableText } from "../../../../components/ui";
import { useCustomerProvider, useAdminProviderDataContext } from "../../../../context";
import { APIFunds } from "../../../../api";

const WATERMARK_MAX_VALUE = 200000;
const validateSchemaEmail = Yup.object().shape({
  watermark: Yup.number()
    .typeError(({ originalValue }) => `"${originalValue}" is invalid value`)
    .max(WATERMARK_MAX_VALUE, `Max value is ${WATERMARK_MAX_VALUE}`)
    .min(0)
    .required("Watermark is required"),
});

const Watermark = (): React.ReactElement | null => {
  const { id } = useParams();
  const { customer, getCustomer } = useCustomerProvider();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const [watermark, setWatermark] = useState(0);
  const { funded_trader } = customer;

  useEffect(() => {
    if (funded_trader) {
      setWatermark(funded_trader.watermark);
    }
  }, [customer]);

  const handleSave = async () => {
    await APIFunds.setFundedTraderWatermark(id, watermark);
    getCustomer();
  };

  return (
    <div>
      <EditableText
        isAllowedEditing={allowed_features.access_edit_watermark}
        isWithCopyToClipboard
        title="Current Watermark: "
        value={watermark}
        onSave={handleSave}
        name="watermark"
        label="Watermark"
        setValue={setWatermark}
        validationSchema={validateSchemaEmail}
      />
    </div>
  );
};

export default Watermark;

import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { FormControl, InputLabel, Select, TextField, MenuItem } from "@material-ui/core";

import { IPairCommission } from "../GenerateGauntletReportDialog";
import { DEFAULT_TRADE_COMMISSION_PRICE } from "../../../../../constants";
import useStyles from "./useStyles";

interface IProps {
  options: string[];
  index: number;
  handleDeletePair: (index: number) => void;
  handleEditPair: (pair: IPairCommission, index: number) => void;
  pair: IPairCommission;
  selectedOptions: IPairCommission[];
  commissions: {
    [key: string]: number;
  };
}
const SelectPairPrice = ({
  options,
  index,
  handleDeletePair,
  handleEditPair,
  pair,
  selectedOptions,
  commissions,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const onChangePair = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target;
    handleEditPair(
      {
        // @ts-ignore
        price: commissions[value] || DEFAULT_TRADE_COMMISSION_PRICE,
        pair: value as string,
      },
      index
    );
  };
  const onChangeValue = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target;
    handleEditPair(
      {
        ...pair,
        price: value as number,
      },
      index
    );
  };
  const handleDelete = () => handleDeletePair(index);

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel>Pair</InputLabel>
        <Select
          variant="outlined"
          value={pair.pair}
          onChange={onChangePair}
          label="Pair"
          placeholder="Pair"
          name="pairName"
          inputProps={{
            name: "pairName",
          }}
          className={classes.select}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              disabled={!!selectedOptions.find((op) => op.pair === option)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <TextField
          name="price"
          label="Commission"
          variant="outlined"
          type="number"
          value={pair.price}
          onChange={onChangeValue}
        />
      </FormControl>

      <CancelIcon onClick={handleDelete} className={classes.cancelIcon} color="secondary" />
    </div>
  );
};

export default SelectPairPrice;

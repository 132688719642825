import httpClient from "../apiClient";
import {
  TGetCustomerPaymentCardsRequest,
  TGetCustomerSharedCardsRequest,
  TUpdatePaymentCardRequest,
} from "./index.types";

export const PAYMENT_CARDS_API_URLS = {
  updatePaymentCardData: "/payment-cards/update-payment-card",
  getCustomerPaymentCards: "/payment-cards/get-customer-payment-cards",
  getCustomerSharedCards: "/payment-cards/get-customer-shared-cards",
} as const;

export class PaymentCardsAPI {
  static updatePaymentCardRequest: TUpdatePaymentCardRequest = (id, data) =>
    httpClient.post(`${PAYMENT_CARDS_API_URLS.updatePaymentCardData}/${id}`, data);
  static getCustomerPaymentCardsRequest: TGetCustomerPaymentCardsRequest = (
    customer_id: number,
    body
  ) => httpClient.post(`${PAYMENT_CARDS_API_URLS.getCustomerPaymentCards}/${customer_id}`, body);
  static getCustomerSharedCardsRequest: TGetCustomerSharedCardsRequest = (card_id, query, signal) =>
    httpClient.get(`${PAYMENT_CARDS_API_URLS.getCustomerSharedCards}/${card_id}?${query}`, {
      signal,
    });
}

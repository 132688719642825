import React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "formik";
import { useSelector } from "react-redux";

import { INTERCOM_STATUS } from "../../../../../constants";
import { IIntercomContact } from "../../../../../api/intercom/index.types";
import { CUSTOM_ATTRIBUTES_KEY, isDateKeyValue } from "../utils";
import SearchContainer from "./SearchContainer";
import { FormCheckbox, FormDatePicker, FormSelect, FormInput } from "../../../../../components/ui";

interface IProps {
  contact: IIntercomContact;
  search: string[];
  searchText: string;
  fields: string[];
}

const CustomAttributes = ({ contact, search, fields, searchText }: IProps): React.ReactElement => {
  const custom_attributes_filtered = Object.keys(contact[CUSTOM_ATTRIBUTES_KEY]);
  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const isBannedIntercomData = !!banned_account?.field_values_before_bun?.intercom;

  return (
    <>
      <Grid container spacing={3}>
        {custom_attributes_filtered.map((key) => {
          if (typeof contact[CUSTOM_ATTRIBUTES_KEY][key] === "boolean") {
            return (
              <SearchContainer
                name={key}
                key={key}
                currentFilters={search}
                fields={fields}
                searchText={searchText}
              >
                <Field
                  disabled={isBannedIntercomData}
                  data-test={key}
                  key={key}
                  name={`${CUSTOM_ATTRIBUTES_KEY}.${key}`}
                  label={key}
                  placeholder={key}
                  component={FormCheckbox}
                />
              </SearchContainer>
            );
          }

          if (isDateKeyValue(key)) {
            return (
              <SearchContainer
                name={key}
                key={key}
                currentFilters={search}
                fields={fields}
                searchText={searchText}
              >
                <Field
                  disabled={isBannedIntercomData}
                  key={key}
                  name={`${CUSTOM_ATTRIBUTES_KEY}.${key}`}
                  label={key}
                  placeholder={key}
                  component={FormDatePicker}
                />
              </SearchContainer>
            );
          }

          if (key === "subscription_status") {
            return (
              <SearchContainer
                name={key}
                key={key}
                currentFilters={search}
                fields={fields}
                searchText={searchText}
              >
                <Field
                  disabled={isBannedIntercomData}
                  key={key}
                  name={`${CUSTOM_ATTRIBUTES_KEY}.${key}`}
                  label={key}
                  placeholder={key}
                  options={INTERCOM_STATUS}
                  component={FormSelect}
                />
              </SearchContainer>
            );
          }

          return (
            <SearchContainer
              name={key}
              key={key}
              currentFilters={search}
              fields={fields}
              searchText={searchText}
            >
              <Field
                disabled={isBannedIntercomData}
                name={`${CUSTOM_ATTRIBUTES_KEY}.${key}`}
                label={key}
                placeholder={key}
                component={FormInput}
              />
            </SearchContainer>
          );
        })}
      </Grid>
    </>
  );
};

export default CustomAttributes;

/* eslint-disable no-param-reassign */
import produce from "immer";

import { HeliosAdminAction, HeliosAdminActionType, IHeliosAdminState } from "./index.types";

export const initialState: IHeliosAdminState = {
  simAndLiveSimAccounts: {
    data: {
      liveSimCount: 0,
      liveCount: 0,
    },
    loading: false,
  },
};

const heliosAdminReducer = (
  prevState = initialState,
  action: HeliosAdminAction
): IHeliosAdminState =>
  produce(prevState, (draft: IHeliosAdminState): IHeliosAdminState => {
    switch (action.type) {
      case HeliosAdminActionType.fetchSimAndLiveSimAccountsRequest:
        draft.simAndLiveSimAccounts.loading = true;
        break;
      case HeliosAdminActionType.fetchSimAndLiveSimAccountsSuccess:
        draft.simAndLiveSimAccounts.loading = false;
        draft.simAndLiveSimAccounts.data = action.payload.data;
        break;
      case HeliosAdminActionType.fetchSimAndLiveSimAccountsFailed:
        draft.simAndLiveSimAccounts.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default heliosAdminReducer;

import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { APIFunds } from "../../../../api";
const PriorityStatusControls = (): React.ReactElement => {
  const dispatch = useDispatch();

  const { accounts_id, is_priority_to_process, isLoading } = useSelector(
    (state) => state.fundedTrader
  );

  const handleChangePriorityProcess = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.updateFundedTraderDataRequest(accounts_id, {
        is_priority_to_process: event.target.checked,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error handle change priority process", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  return (
    <FormControlLabel
      label="Priority to process"
      control={
        <Checkbox
          size="small"
          disabled={isLoading}
          checked={is_priority_to_process}
          onChange={handleChangePriorityProcess}
        />
      }
    />
  );
};

export default PriorityStatusControls;

import { IColum } from "../../../../../../../utils/copyToClipboardInXlsFormat";
import { IReport } from "./index";
import { IntlFormatUSD } from "../../../../../../../utils";

export const PRODUCT_SALES_COLUMNS: Array<IColum> = [
  {
    by: "",
    title: "",
    path: "plan",
  },
  {
    by: "total_income",
    title: "Total income",
    path: "total_income",
    getCellValue: (value) => IntlFormatUSD(+value),
  },
  {
    by: "total_case",
    title: "Total case",
    path: "total_case",
  },
  // {
  //   by: "total_amount",
  //   title: "Total amount",
  //   path: "total_amount",
  // },
  {
    by: "total_pnl",
    title: "Income per transaction",
    path: "",
    // @ts-ignore
    getCellValue: (_: unknown, rowData: IReport): string => {
      if (!rowData.total_case) return "0";
      return IntlFormatUSD(rowData.total_income / rowData.total_case);
    },
  },
];

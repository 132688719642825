import produce from "immer";

import { FinancialsAction, FinancialsActionType, IFinancialsState } from "./index.types";

export const initialState: IFinancialsState = {
  sales_by_days: {
    data: [],
    loading: false,
  },
};

const financialsReducer = (prevState = initialState, action: FinancialsAction): IFinancialsState =>
  produce(prevState, (draft: IFinancialsState): IFinancialsState => {
    switch (action.type) {
      //SalesByDays
      case FinancialsActionType.fetchFinancialsSalesByDaysRequest:
        draft.sales_by_days.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsSalesByDaysSuccess:
        draft.sales_by_days.data = action.payload;
        draft.sales_by_days.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsSalesByDaysFailed:
        draft.sales_by_days.loading = false;
        break;
    }
    return draft;
  });

export default financialsReducer;

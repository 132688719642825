import React from "react";
import cx from "classnames";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { getIn } from "formik";

import useStyles from "./useStyles";
import { InputMessage, ContextualHelp, InfoTitle } from "../index";

interface IErrorMessageComponent {
  error: string;
}

export interface ISelectOption {
  value: string | number | readonly string[] | undefined;
  id: number;
  label: string;
}
interface IProps {
  placeholder: string;
  options: Array<ISelectOption>;
  required: boolean;
  label: string;
  field: {
    name: string;
    value: string;
  };
  infoTitle: string;
  form: {
    errors: {
      name: string;
    };
    touched: boolean;
  };
  isMulti: boolean;
  shrink: boolean;
  contextualHelpText: string;
  contextualHelpProps: Record<string, never>;
  ErrorMessageComponent: ({ error }: IErrorMessageComponent) => React.ReactElement;
  variant?: "filled" | "outlined" | "standard" | undefined;
  size?: "small" | "medium";
}

const CustomSelect = ({
  placeholder,
  options = [],
  required,
  label,
  field,
  infoTitle,
  form: { errors },
  shrink,
  contextualHelpText,
  contextualHelpProps = {},
  ErrorMessageComponent = InputMessage,
  variant,
  ...rest
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage;

  return (
    <FormControl
      variant={variant || "outlined"}
      classes={{
        root: classes.fullWidth,
      }}
      {...("size" in rest && { size: rest.size })}
    >
      <InputLabel shrink={shrink} classes={{ root: classes.inputLabelRoot }}>
        {label}
      </InputLabel>

      <ContextualHelp title={contextualHelpText} {...contextualHelpProps}>
        <Select
          {...field}
          {...rest}
          {...{
            ...(!variant && { input: <OutlinedInput /> }),
          }}
          className={cx(classes.selectFieldBasic)}
          // error={isError}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </ContextualHelp>

      <div className={classes.errorContainer}>
        {isError && <ErrorMessageComponent error={errorMessage} />}
      </div>

      {infoTitle && <InfoTitle title={infoTitle} />}
    </FormControl>
  );
};

export default CustomSelect;

import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import TradedAccountsList from "../TradedAccountsList";
import { CircularProgress } from "../../../../components/ui";
import { INewTradedAccount } from "../../../../api/funds/index.types";
import { EInvoiceStatusesLiveFundedTrader, EProcessingStatuses } from "../../../../constants";
import { fetchTradedAccounts } from "../../utils";
import CreateTradedAccountForm from "../CreateTradedAccountForm";
import useStyles from "./useStyles";
import useComponentsStyles from "../useStyles";

interface IProps {
  customerId: number;
}

export interface INewTradedAccountForm
  extends Omit<INewTradedAccount, "processing_status" | "invoice_status"> {
  processing_status: EProcessingStatuses | "";
  invoice_status: EInvoiceStatusesLiveFundedTrader | "";
  evaluation_completed_history_id: number | null;
}

const TradedAccounts = ({ customerId }: IProps): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const componentsClasses = useComponentsStyles();

  const {
    tradedAccounts: { isLoading: isFetchingTradedAccounts },
    fundedAccounts,
    priorAccounts,
  } = useSelector((state) => state.fundedTrader);

  useEffect(() => {
    if (customerId) {
      (async () => {
        await fetchTradedAccounts(dispatch, customerId);
      })();
    }
  }, [customerId]);

  return (
    <Paper className={cx(classes.container, componentsClasses.paperContainer)}>
      <b className={classes.title}>Add new account</b>

      <CircularProgress isLoading={isFetchingTradedAccounts} />
      {!isFetchingTradedAccounts && <CreateTradedAccountForm />}

      {!isFetchingTradedAccounts && (
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TradedAccountsList
                fetchTradedAccounts={() => fetchTradedAccounts(dispatch, customerId)}
                title="Funded accounts"
                listItems={fundedAccounts}
                priorAccounts={priorAccounts}
              />

              <TradedAccountsList
                withCollapse
                fetchTradedAccounts={() => fetchTradedAccounts(dispatch, customerId)}
                title="Prior accounts"
                listItems={priorAccounts}
                priorAccounts={fundedAccounts}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Paper>
  );
};

export default TradedAccounts;

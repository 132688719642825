/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { IStripeChargeInfo } from "../api/stripe/index.types";

export type FeedAction =
  | FailedPaymentsFetchRequest
  | FailedPaymentsFetchSuccess
  | FailedPaymentsFetchFailed;

interface FailedPaymentsFetchRequest extends ReduxAction {
  type: FailedPaymentsActionType.failedPaymentsFetchRequest;
}
interface FailedPaymentsFetchSuccess extends ReduxAction {
  type: FailedPaymentsActionType.failedPaymentsFetchSuccess;
  payload: {
    payments: Payment[];
    retrievedCharges: IStripeChargeInfo[];
    count: number;
  };
}
interface FailedPaymentsFetchFailed extends ReduxAction {
  type: FailedPaymentsActionType.failedPaymentsFetchFailed;
}

export enum FailedPaymentsActionType {
  failedPaymentsFetchRequest = "failedPayments/FetchRequest",
  failedPaymentsFetchSuccess = "failedPayments/FetchSuccess",
  failedPaymentsFetchFailed = "failedPayments/FetchFailed",
}

export const FeedActions = {
  fetchFailedPaymentsRequest: (): FeedAction => ({
    type: FailedPaymentsActionType.failedPaymentsFetchRequest,
  }),
  fetchFailedPaymentsSuccess: ({
    count,
    payments,
    retrievedCharges,
  }: IPaymentsStatus): FeedAction => ({
    type: FailedPaymentsActionType.failedPaymentsFetchSuccess,
    payload: {
      count,
      payments,
      retrievedCharges,
    },
  }),
  fetchFailedPaymentsFailed: (): FeedAction => ({
    type: FailedPaymentsActionType.failedPaymentsFetchFailed,
  }),
};

export interface Payment {
  account: {
    name: string;
    id: number;
    email: string;
  };
  accounts_id: number;
  id: number;
  plan: {
    name: string;
  };
  price_usd: number;
  status: string;
  purchase_date: string;
  stripe_ch: string;
}

export interface IPaymentsStatus {
  payments: Payment[];
  retrievedCharges: IStripeChargeInfo[];
  count: number;
  loading: boolean;
}

export interface FeedState {
  data: {
    failedPayments: IPaymentsStatus;
  };
}

const initialState: FeedState = {
  data: {
    failedPayments: {
      payments: [],
      retrievedCharges: [],
      count: 0,
      loading: false,
    },
  },
};

const feedReducer = (prevState = initialState, action: FeedAction): FeedState =>
  produce(prevState, (draft: FeedState) => {
    switch (action.type) {
      case FailedPaymentsActionType.failedPaymentsFetchRequest:
        draft.data.failedPayments.loading = true;
        break;
      case FailedPaymentsActionType.failedPaymentsFetchSuccess:
        draft.data.failedPayments.loading = false;
        draft.data.failedPayments.payments = action.payload.payments;
        draft.data.failedPayments.retrievedCharges = action.payload.retrievedCharges;
        draft.data.failedPayments.count = action.payload.count;
        break;
      case FailedPaymentsActionType.failedPaymentsFetchFailed:
        draft.data.failedPayments.loading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default feedReducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { PaginationWithLimit, ContainerWithLoader } from "../index";

import FilterAutocomplete from "../FilterAutocomplete";

import useStyles from "./useStyles";
import newSortBy from "../../../utils/newSortBy";
import usePagination from "../../../hooks/usePagination";
import useGeneralStyles from "../../../useGeneralStyles";
import { APIFunds } from "../../../api";
import {
  FORCED_TO_LIVE_ACCOUNTS,
  FORCED_TO_LIVE_ACCOUNTS_INPUT_TYPES,
} from "../../../pages/FundedTraders/constants";
import { ForcedToLiveAccounts } from "../../../reducers/forcedToLiveAccountsReducer/actions";

interface IProps {
  isGetAllAccounts?: boolean;
}
const ForcedToLive: React.FC<IProps> = ({ isGetAllAccounts }) => {
  const dispatch = useDispatch();
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const { isLoading, data } = useSelector((state) => state.forcedToLiveAccounts.accounts);
  const { limit, page, setLimit, setPage, count, setCount } = usePagination();

  const [sortBy, setSortBy] = useState({
    by: "id",
    order: false,
  });
  const [search, setSearch] = useState({
    // Uses on admin page
    ...(isGetAllAccounts && {
      compare_total: ">",
      withdrawals: "0",
      withdrawals_compare: ">",
      get_livesim_net_withdrawal_amount: true,
    }),
  });
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  useEffect(() => {
    (async () => {
      try {
        dispatch(ForcedToLiveAccounts.fetchForcedToLiveAccountsRequest());
        const { data } = await APIFunds.getForcedToLiveAccountsRequest({
          limit: +limit,
          page,
          sortBy,
          contains: search,
        });
        setCount(data.count);
        dispatch(ForcedToLiveAccounts.fetchForcedToLiveAccountsSuccess(data));
      } catch (error) {
        dispatch(ForcedToLiveAccounts.fetchForcedToLiveAccountsFailed());
        console.error("Error fetching getWithdrawalsListRequest", error);
      }
    })();
  }, [sortBy, limit, page, search]);

  return (
    <div>
      <div className={classes.filterContainer}>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={FORCED_TO_LIVE_ACCOUNTS}
          inputTypes={FORCED_TO_LIVE_ACCOUNTS_INPUT_TYPES}
        />
      </div>

      <ContainerWithLoader isLoading={isLoading} report={data.rows}>
        <table className={generalClasses.table}>
          <TableHead sortBy={sortBy} handleSortBy={handleSortBy} />
          <TableBody isEditableWithdrawalAmount={isGetAllAccounts} />
        </table>
      </ContainerWithLoader>

      <PaginationWithLimit
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPageState={setPage}
      />
    </div>
  );
};

export default ForcedToLive;

import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { CopyToClipboard } from "../../../../components/ui";

export const MASTER_BAN_LIST_COLUMNS: Array<IColum> = [
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "date_of_ban_at_e2t",
    title: "Ban date",
    path: "date_of_ban_at_e2t",
  },
];

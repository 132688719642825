import React from "react";

const Checklist = ({
  width = 24,
  margin = "0",
}: {
  width?: number;
  margin?: string;
}): React.ReactElement => (
  <svg
    width={width}
    style={{ margin }}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 34 34"
    viewBox="0 0 34 34"
  >
    <path
      d="M25.1,17.2c-4.4,0-7.9,3.5-7.9,7.9c0,4.4,3.5,7.9,7.9,7.9c4.3,0,7.9-3.5,7.9-7.9C32.9,20.7,29.4,17.2,25.1,17.2z
			 M28.7,23.7l-4,4c-0.2,0.2-0.5,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l3.3-3.3
			c0.4-0.4,1-0.4,1.4,0S29.1,23.3,28.7,23.7z"
    />
    <path
      d="M21.1,1h-17c-1.7,0-3,1.4-3,3v24c0,1.7,1.3,3,3,3h13c-1.2-1.6-2-3.7-2-5.9c0-0.4,0-0.7,0.1-1.1H6.1c-0.6,0-1-0.4-1-1
			c0-0.5,0.4-1,1-1h9.6c0.2-0.7,0.5-1.4,0.9-2H6.1c-0.6,0-1-0.4-1-1c0-0.5,0.4-1,1-1h12.1c1.6-1.5,3.6-2.5,5.9-2.8V4
			C24.1,2.4,22.7,1,21.1,1z M6.1,6h8c0.5,0,1,0.5,1,1c0,0.6-0.5,1-1,1h-8c-0.6,0-1-0.4-1-1C5.1,6.5,5.5,6,6.1,6z M19.1,16h-13
			c-0.6,0-1-0.4-1-1c0-0.5,0.4-1,1-1h13c0.5,0,1,0.5,1,1C20.1,15.6,19.6,16,19.1,16z M19.1,12h-13c-0.6,0-1-0.4-1-1c0-0.5,0.4-1,1-1
			h13c0.5,0,1,0.5,1,1C20.1,11.6,19.6,12,19.1,12z"
    />
  </svg>
);

export default Checklist;

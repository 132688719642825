import produce from "immer";

import {
  ComplianceWatchlistAction,
  ComplianceWatchlistActionType,
  IComplianceWatchlistState,
} from "./index.types";

export const initialState: IComplianceWatchlistState = {
  watchlist: {
    data: {
      count: 0,
      rows: [],
    },
    loading: true,
  },
};

const complianceWatchlistReducer = (
  prevState = initialState,
  action: ComplianceWatchlistAction
): IComplianceWatchlistState =>
  produce(prevState, (draft: IComplianceWatchlistState): IComplianceWatchlistState => {
    switch (action.type) {
      case ComplianceWatchlistActionType.fetchComplianceWatchlistRequest:
        draft.watchlist.loading = true;
        break;
      case ComplianceWatchlistActionType.fetchComplianceWatchlistSuccess:
        draft.watchlist.data = action.payload;
        draft.watchlist.loading = false;
        break;
      case ComplianceWatchlistActionType.fetchComplianceWatchlistFailed:
        draft.watchlist.loading = false;
        break;
    }
    return draft;
  });

export default complianceWatchlistReducer;

import React from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { useAuthContext } from "../../context";

const Login = (): React.ReactElement => {
  const { token, loginData, setLoginData, login, loginFeedback, expiredMessage } = useAuthContext();

  const history = useHistory();

  const loginDataFN = (data: string, type: string) => {
    setLoginData({ ...loginData, [type]: data });
  };

  setTimeout(() => {
    token !== "" && history.replace("/");
  }, 100);

  return (
    <form className="login" onSubmit={() => login()}>
      <div>
        <div className={`expired ${expiredMessage && "extend"}`}>{expiredMessage}</div>

        <div className="title">Login</div>

        <div className="input-text">
          <TextField
            label="Username"
            id="username"
            onChange={(e) => loginDataFN(e.target.value, "email")}
            value={loginData.email}
          />
        </div>
        <div className="input-text">
          <TextField
            label="Password"
            type="password"
            id="password"
            onChange={(e) => loginDataFN(e.target.value, "password")}
            value={loginData.password}
          />
        </div>
        <div style={{ marginTop: 15, textAlign: "right" }}>
          {loginFeedback}{" "}
          <Button variant="contained" color="primary" onClick={() => login()}>
            Login
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Login;

import React from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Select } from "../../../../components/ui";
import { EVALUATION_TYPE_OPTIONS } from "../../../../constants";
import { FUNDS_API_URLS, FundsAPI } from "../../../../api/funds";

const FundedTraderStatus = (): React.ReactElement => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { evaluation_type } = useSelector((state) => state.fundedTrader);

  const handleUpdateFundedTraderStatus = useMutation({
    mutationKey: [FUNDS_API_URLS.update_trader, id],
    mutationFn: (value: string) => {
      return FundsAPI.updateFundedTraderDataRequest(id, { evaluation_type: value });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.get_funded_trader_data, id] });
    },
  });

  return (
    <div>
      <Select
        size="small"
        options={EVALUATION_TYPE_OPTIONS.map((option) => ({
          name: option.value,
          text: option.label,
        }))}
        label="Funded trader status"
        inputLabel="Funded trader status"
        value={evaluation_type || ""}
        onChange={(
          event: React.ChangeEvent<{ name?: string | undefined; value: unknown | string }>
        ) => handleUpdateFundedTraderStatus.mutate(event.target.value as string)}
      />
    </div>
  );
};

export default FundedTraderStatus;

import React from "react";
import { FormControl } from "@material-ui/core";
import { getIn } from "formik";
import cx from "classnames";

import { InfoTitle, InputMessage, ContextualHelp } from "../index";
import { BaseDatePicker, useStyles } from "./styled";
import { DATE_FORMAT } from "../../../constants";

interface IProps {
  field: {
    name: string;
    value: string;
  };
  label: string;
  disabled: boolean;
  infoTitle: string;
  format: string;
  minDate: Date;
  form: {
    errors: {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      optionalObject: Record<string, any>;
    };
    touched: boolean;
    setFieldValue: (a: string, b: string) => string;
  };
  datePickerProps: Record<string, any>;
  contextualHelpText: string;
  contextualHelpProps: Record<string, any>;
  onChange: (a: any) => string;
  waringMessage?: string;
  variant?: string;
}

const DatePicker = ({
  field,
  label,
  disabled,
  infoTitle,
  format = DATE_FORMAT,
  form: { errors, setFieldValue },
  datePickerProps = {},
  contextualHelpText,
  contextualHelpProps = {},
  onChange = (value) => setFieldValue(field.name, value),
  waringMessage,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const errorMessage = getIn(errors, field.name);
  return (
    <ContextualHelp title={contextualHelpText} {...contextualHelpProps} isDisableHoverListener>
      <FormControl style={{ width: "100%" }}>
        <BaseDatePicker
          autoOk
          label={label}
          disabled={disabled}
          format={format}
          inputVariant="outlined"
          error={!!errorMessage}
          invalidDateMessage={false}
          {...field}
          onChange={onChange}
          {...datePickerProps}
          value={field.value === "" ? null : field.value}
          classes={{
            ...(waringMessage && { root: classes.warning }),
            ...(datePickerProps.classes && datePickerProps.classes),
          }}
        />

        {infoTitle && <InfoTitle title={infoTitle} />}

        <div
          className={cx(classes.errorMessage, {
            [classes.errorContainerStandardVariant]: datePickerProps.inputVariant === "standard",
            [classes.errorContainerSmallSize]: datePickerProps.size === "small",
          })}
        >
          {(errorMessage || waringMessage) && (
            <InputMessage waring={waringMessage} error={errorMessage} />
          )}
        </div>
      </FormControl>
    </ContextualHelp>
  );
};

export default DatePicker;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  sum: {
    margin: "0 0 0 10px",
  },
  tableWrapper: {
    margin: "initial",
    marginTop: 10,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core/";
import { STATUS_COLORS_OPACITY } from "../../../../../constants";

const useStyles = makeStyles({
  sharedPaymentCard: {
    "& td": {
      backgroundColor: `${STATUS_COLORS_OPACITY.tango} !important`,
    },
    "& .usedTimes": {
      backgroundColor: `${STATUS_COLORS_OPACITY.tango} !important`,
      cursor: "help !important",
      "&:hover": {
        background: "rgba(128, 128, 128, 0.4) !important",
      },
    },
  },
  defaultPaymentCard: {
    "& td": {
      backgroundColor: "#04dd0747 !important",
    },
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  wrapper: {
    display: "flex",
    color: "white",
  },
});

export default useStyles;

import { IAgent, IGetAgentNotesResponse } from "../../api/agents/index.types";
import { ReduxAction } from "../../static-types";

export type AgentAction =
  | AgentActionFetchRequest
  | AgentActionFetchSuccess
  | AgentActionFetchFailed
  | AgentActionFetchNotesRequest
  | AgentActionFetchNotesSuccess
  | AgentActionFetchNotesFailed;

export interface AgentActionFetchRequest extends ReduxAction {
  type: AgentActionType.fetchAgentRequest;
}
export interface AgentActionFetchSuccess extends ReduxAction {
  type: AgentActionType.fetchAgentSuccess;
  payload: IAgent;
}
export interface AgentActionFetchFailed extends ReduxAction {
  type: AgentActionType.fetchAgentFailed;
}

export interface AgentActionFetchNotesRequest extends ReduxAction {
  type: AgentActionType.fetchAgentNotesRequest;
}
export interface AgentActionFetchNotesSuccess extends ReduxAction {
  type: AgentActionType.fetchAgentNotesSuccess;
  payload: IGetAgentNotesResponse;
}
export interface AgentActionFetchNotesFailed extends ReduxAction {
  type: AgentActionType.fetchAgentNotesFailed;
}

export enum AgentActionType {
  fetchAgentRequest = "agent/fetchAgentRequest",
  fetchAgentSuccess = "agent/fetchAgentSuccess",
  fetchAgentFailed = "agent/fetchAgentFailed",

  fetchAgentNotesRequest = "agent/fetchAgentNotesRequest",
  fetchAgentNotesSuccess = "agent/fetchAgentNotesSuccess",
  fetchAgentNotesFailed = "agent/fetchAgentNotesFailed",
}
export interface IAgentState {
  data: IAgent & { admin_accounts_notes: IGetAgentNotesResponse["data"] };
  loading: boolean;
}

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  tableContainer: {
    overflow: "scroll",
    "& .data-grid-container .data-grid .cell": {
      textAlign: "left",
      verticalAlign: "inherit",
      padding: "0 5px",
      height: 35,
      // minWidth: 75,
      // minWidth: 20,
      // "&.editing": {
      //   minWidth: 100,
      // },
      "&.selected": {
        backgroundColor: "rgba(33, 133, 208, 0.15)",
        boxShadow: "none",
        minWidth: 75,
      },
      "& > input": {
        textAlign: "left",
        border: "1px solid #000",
        minWidth: 75,
        width: "calc(100% - 10px)",
        borderRadius: 0,
        margin: 0,
        height: 15,
      },
    },
  },
  paperRoot: {
    backgroundColor: "#f7f7f7",
  },
  cellBorder: {
    "&, th, td": {
      border: "1px solid black",
    },
  },
  rowActionContainer: {
    border: "1px solid #DDD",
  },
});

export default useStyles;

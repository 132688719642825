import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableWrapper: {
    marginTop: 20,
  },
  tableContainer: {
    marginTop: 25,
  },
  affiliateContainer: {
    display: "flex",
    justifyContent: "end",
  },
});

export default useStyles;

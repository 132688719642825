import React, { ChangeEvent, useEffect, useState } from "react";
import moment from "moment-timezone";
import { get } from "lodash";
import { Button, TextField } from "@material-ui/core";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { TBy } from "../../../../reducers/customersReducer";
import {
  ContainerWithLoader,
  StyledDatePicker,
  PaginationWithLimit,
  TableDataCellWithSort,
  StatusColorLegend,
} from "../../../../components/ui";
import { SharedIPsDialog, SharedRegIps } from "../index";

import { useAdminProviderDataContext } from "../../../../context";
import newSortBy from "../../../../utils/newSortBy";
import downloadCsv from "../../../../utils/downloadCsv";

import useGeneralStyles from "../../../../useGeneralStyles";
import { DATE_FORMAT, DATE_FORMAT_hhmmssAzz, STATUS_COLORS_OPACITY } from "../../../../constants";
import useStyles from "./useStyles";
import { APILogins } from "../../../../api";
import { ILogins } from "../../../../api/logins/index.types";
import usePagination from "../../../../hooks/usePagination";

interface SharedIps {
  [key: string]: number;
}
const LoginsHistory = (): React.ReactElement => {
  const controller = new AbortController();
  const { signal } = controller;

  const { id } = useParams();
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [isLoading, setLoading] = useState(true);
  const { limit, setLimit, page, setPage } = usePagination();

  const [search, setSearch] = useState({
    ip: "",
    timestamp: "",
  });
  const [logs, setLogs] = useState<ILogins[]>([]);
  const [sharedIps, setSharedIps] = useState<SharedIps>({});
  const [selectedIp, setSelectedIp] = useState("");

  const [sortBy, setSortBy] = useState({
    by: "timestamp",
    order: false,
  });

  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });
  const fetchListLogins = async (getCsv?: boolean) => {
    const data = {
      limit,
      sortBy,
      page,
      isGetAll: getCsv,
      ...search,
    };
    try {
      setLoading(true);
      const response = await APILogins.getLoginsListRequest(id, data, signal, getCsv);

      if (getCsv) {
        return response.data.logins;
      }

      const sharedIps: SharedIps = {};
      response.data.sharedIps.forEach(({ ip, used_times }) => {
        sharedIps[ip] = used_times;
      });

      setLogs(response.data.logins);
      setSharedIps(sharedIps);

      return response.data.logins;
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  };
  const handleChangeIp = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };
  const handleGetCsvLogins = async () => {
    try {
      const response = await fetchListLogins(true);
      const HEADER = ["Ip", "timestamp", "Success"];
      const rows: string[][] = [HEADER];
      response.forEach(({ ip, timestamp, success }) => {
        rows.push([
          ip,
          moment(timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz),
          success ? "✔" : "✘",
        ]);
      });
      downloadCsv(rows, "Access_logs");
    } catch (error) {
      console.error("error export CSV logins list", error);
    }
  };
  const handleChangeDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      setSearch({
        ...search,
        timestamp: "",
      });
      return;
    }
    if (date.isValid()) {
      setSearch({
        ...search,
        timestamp: date.format(DATE_FORMAT),
      });
    }
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  useEffect(() => {
    (async () => {
      try {
        await fetchListLogins();
      } catch (error) {
        console.error("error getting logins list", error);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [sortBy, limit, page, id, search]);

  return (
    <div>
      <h3>Logins history</h3>
      <div className={classes.formWrapper}>
        <div>
          <form noValidate autoComplete="off" className={classes.formContainer}>
            <TextField
              name="ip"
              id="ip"
              label="Ip"
              variant="outlined"
              className={classes.ipInput}
              onChange={handleChangeIp}
            />
            <StyledDatePicker
              autoOk
              value={search.timestamp || null}
              name="timestamp"
              label="Timestamp"
              onChange={handleChangeDate}
              maxDate={new Date()}
            />
          </form>

          <SharedRegIps />
        </div>

        <div className={classes.exportButtonContainer}>
          <div className={classes.legend}>
            <StatusColorLegend
              legend={{
                sharedIps: {
                  color: STATUS_COLORS_OPACITY.tango,
                  name: "Shared IPs",
                },
              }}
            />
          </div>

          {allowed_features.export_customer_logins_csv && !!logs.length && (
            <div>
              <Button
                onClick={handleGetCsvLogins}
                variant="contained"
                color="primary"
                data-test="export-csv"
              >
                Export CSV
              </Button>
            </div>
          )}
        </div>
      </div>

      <ContainerWithLoader
        isLoading={isLoading}
        report={logs}
        noResultsMessage="There is aren't logins"
      >
        <>
          <SharedIPsDialog
            ip={selectedIp}
            isOpen={!!selectedIp}
            handleClose={() => {
              setSelectedIp("");
            }}
          />

          <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
            <thead>
              <tr>
                <TableDataCellWithSort
                  sortBy={sortBy}
                  by="ip"
                  title="Ip"
                  newSortBy={handleSortBy}
                />
                <td>IP Usage Count</td>
                <TableDataCellWithSort
                  sortBy={sortBy}
                  by="success"
                  title="Success"
                  newSortBy={handleSortBy}
                />
                <TableDataCellWithSort
                  sortBy={sortBy}
                  by="timestamp"
                  title="Date"
                  newSortBy={handleSortBy}
                />
              </tr>
            </thead>

            <tbody>
              {logs.map(({ ip, timestamp, success }) => (
                <tr
                  key={`${timestamp}_${ip}`}
                  className={cx({ [classes.sharedIp]: sharedIps[ip] })}
                  onClick={() => sharedIps[ip] && setSelectedIp(ip)}
                >
                  <td>{ip}</td>
                  <td>{sharedIps[ip] || null}</td>
                  <td className={cx(success ? classes.success : classes.failed)}>
                    {success ? "✔" : "✘"}
                  </td>
                  <td>{moment(timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <PaginationWithLimit
            count={get(logs, "[0].full_count", 0)}
            limit={limit}
            page={page}
            setPageState={setPage}
            setLimit={setLimit}
          />
        </>
      </ContainerWithLoader>
    </div>
  );
};

export default LoginsHistory;

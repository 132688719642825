import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

const SearchInput = ({
  params,
  label,
  handleChangeInput,
  loading,
}: {
  params: AutocompleteRenderInputParams;
  label: string;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}) => (
  <TextField
    autoFocus
    {...params}
    label={label}
    variant="outlined"
    onChange={handleChangeInput}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </>
      ),
    }}
  />
);

interface IProps<Option> {
  label: string;
  onChange: (value: Option) => void;
  options: Option[];
  getOptionLabel: (option: Option) => string;
  getOptionSelected: (option: Option, value: Option) => boolean;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  style?: React.CSSProperties;
}
const SearchAutocomplete = <Option extends unknown>({
  label,
  onChange,
  options,
  getOptionLabel,
  getOptionSelected,
  handleChangeInput,
  loading,
  open,
  setOpen,
  style,
}: IProps<Option>): React.ReactElement => (
  <Autocomplete
    size="small"
    style={{ width: 200, ...style }}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
    onChange={(_, newValue) => {
      //@ts-ignore
      newValue ? onChange(newValue) : onChange({});
    }}
    options={options}
    loading={loading}
    renderInput={(params) => (
      <SearchInput
        params={params}
        label={label}
        loading={loading}
        handleChangeInput={handleChangeInput}
      />
    )}
  />
);

export default SearchAutocomplete;

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { FormikHelpers } from "formik";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  ButtonGroup,
  Button,
  Grid,
} from "@material-ui/core";

import { ICode } from "../types";

enum Options {
  create_code,
  batch_create,
}
const OPTIONS = ["Create code", "Batch create"];

interface IProps {
  code: ICode;
  isAvailableBatchCreating: boolean;
  isEditingDiscountCode: boolean;
  isValid: boolean;
  dirty: boolean;
  isSavingCode: boolean;
  setFieldValue: FormikHelpers<ICode>["setFieldValue"];
}

const CreateButtonGroup = ({
  isValid,
  code,
  isAvailableBatchCreating,
  isEditingDiscountCode,
  dirty,
  isSavingCode,
  setFieldValue,
}: IProps): React.ReactElement => {
  const matchCustomersPage = useRouteMatch("/customers");

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(Options.create_code);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (selectedIndex === Options.batch_create) {
      setFieldValue("isBatchCreate", true);
    }
  };
  const handleMenuItemClick = (_: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          disabled={!isValid}
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          data-test="select-type-creation"
        >
          <Button
            data-test="submit-discount-code-button"
            disabled={!isValid || !dirty || isSavingCode}
            {...{
              ...(selectedIndex === Options.create_code && { type: "submit" }),
              ...(selectedIndex === Options.batch_create && { onClick: handleClick }),
            }}
          >
            {isEditingDiscountCode
              ? "Update code"
              : `${OPTIONS[selectedIndex]}${matchCustomersPage ? "(S)" : ""}`}
          </Button>

          {!isEditingDiscountCode && !matchCustomersPage && (
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {OPTIONS.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={
                          (index === Options.batch_create && !isAvailableBatchCreating) ||
                          (index === Options.batch_create && !!code.bulkAssignEmails) ||
                          isSavingCode
                        }
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default CreateButtonGroup;

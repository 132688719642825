import React, { useEffect, useState } from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core";
import { get, set } from "lodash";

import useGeneralStyles from "../../../../useGeneralStyles";
import { MOMENT_DAYS, MOMENT_PERIODS_BACK } from "../../../../utils/moment";
import SelectCustomPeriod from "../SelectCustomPeriod";
import TableColumnsView from "../../../../components/ui/TableColumnsView";
import { APIBilling } from "../../../../api";
import { IProductStatisticReport } from "../../../../api/billing/index.types";
import { DATES_VALUE, FILTER_BY_DATES, LAST_6_MONTH } from "../../../../constants";

const useStyles = makeStyles({
  customPeriodContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    height: 56,
  },
  dateContainer: {
    marginLeft: 20,
  },
  scrollableContainer: {
    "overflow-x": "scroll",
    paddingTop: 5,
  },
});

interface IReport {
  [key: string]: {
    [key: string]: IProductStatisticReport;
  };
}
interface IReportPeriods {
  [key: string]: IProductStatisticReport[];
}

const ProductsStatistic = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const [customPeriodStart, setCustomPeriodStart] = useState("");
  const [customPeriodEnd, setCustomPeriodEnd] = useState("");
  const [report, setReport] = useState<IReport>({});

  const DISABLED_BY_DEFAULT_FILTERS: string[] = [
    DATES_VALUE.customPeriod,
    DATES_VALUE.thisWeek,
    DATES_VALUE.days30back,
    DATES_VALUE.thisQuarter,
    DATES_VALUE.numberMonthAgo_3,
    DATES_VALUE.numberMonthAgo_4,
    DATES_VALUE.numberMonthAgo_5,
    DATES_VALUE.numberMonthAgo_6,
    DATES_VALUE.numberMonthAgo_7,
  ];

  const [columns, setColumns] = useState<string[]>(
    FILTER_BY_DATES.map(({ name }) => (DISABLED_BY_DEFAULT_FILTERS.includes(name) ? "" : name))
  );

  const isShowToday = columns.includes(DATES_VALUE.today);
  const isShowYesterday = columns.includes(DATES_VALUE.yesterday);
  const isShowThisWeek = columns.includes(DATES_VALUE.thisWeek);
  const isShowThisMonth = columns.includes(DATES_VALUE.thisMonth);
  const isShowLast30days = columns.includes(DATES_VALUE.days30back);
  const isShowThisQuarter = columns.includes(DATES_VALUE.thisQuarter);
  const isShowThisYear = columns.includes(DATES_VALUE.thisYear);
  const isShowLastYear = columns.includes(DATES_VALUE.lastYEar);
  const isShowAllTime = columns.includes(DATES_VALUE.allTIme);
  const isShowCustomPeriod = columns.includes(DATES_VALUE.customPeriod);

  const isShow1MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_1);
  const isShow2MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_2);
  const isShow3MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_3);
  const isShow4MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_4);
  const isShow5MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_5);
  const isShow6MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_6);
  const isShow7MonthAgo = columns.includes(DATES_VALUE.numberMonthAgo_7);

  const reportPeriods: IReportPeriods = {};
  const tempReport = {};
  const getStatistics = async () => {
    const dates = [
      [MOMENT_DAYS.today, MOMENT_DAYS.today, DATES_VALUE.today],
      [MOMENT_DAYS.yesterday, MOMENT_DAYS.yesterday, DATES_VALUE.yesterday],
      [MOMENT_DAYS.thisWeekBegin, MOMENT_DAYS.thisWeekEnd, DATES_VALUE.thisWeek],
      [MOMENT_DAYS.thisMonthBegin, MOMENT_DAYS.thisMonthEnd, DATES_VALUE.thisMonth],
      [MOMENT_DAYS.prevMonthBegin, MOMENT_DAYS.prevMonthEnd, DATES_VALUE.lastMonth],

      [MOMENT_DAYS.oneMonthAgoBegin, MOMENT_DAYS.oneMonthAgoEnd, DATES_VALUE.numberMonthAgo_1],
      [MOMENT_DAYS.towMonthAgoBegin, MOMENT_DAYS.twoMonthAgoEnd, DATES_VALUE.numberMonthAgo_2],
      [MOMENT_DAYS.treMonthAgoBegin, MOMENT_DAYS.treMonthAgoEnd, DATES_VALUE.numberMonthAgo_3],
      [MOMENT_DAYS.fourMonthAgoBegin, MOMENT_DAYS.fourMonthAgoEnd, DATES_VALUE.numberMonthAgo_4],
      [MOMENT_DAYS.fiveMonthAgoBegin, MOMENT_DAYS.fiveMonthAgoEnd, DATES_VALUE.numberMonthAgo_5],
      [MOMENT_DAYS.sixMonthAgoBegin, MOMENT_DAYS.sixMonthAgoEnd, DATES_VALUE.numberMonthAgo_6],
      [MOMENT_DAYS.sevenMonthAgoBegin, MOMENT_DAYS.sevenMonthAgoEnd, DATES_VALUE.numberMonthAgo_7],

      [MOMENT_PERIODS_BACK.days30back, MOMENT_DAYS.today, DATES_VALUE.days30back],
      [MOMENT_DAYS.thisQuarterBegin, MOMENT_DAYS.nextQuarterBegin, DATES_VALUE.thisQuarter],
      [MOMENT_DAYS.thisYearBegin, MOMENT_DAYS.thisYearEnd, DATES_VALUE.thisYear],
      [MOMENT_DAYS.lastYearBegin, MOMENT_DAYS.lastYearEnd, DATES_VALUE.lastYEar],
      ["", MOMENT_DAYS.today, DATES_VALUE.allTIme],
      ...(customPeriodStart && setCustomPeriodStart
        ? [[customPeriodStart, customPeriodEnd, DATES_VALUE.customPeriod]]
        : []),
    ];

    try {
      const response = await Promise.all(
        dates.map((date) => {
          const [startPeriod, endPeriod, period] = date;
          return APIBilling.getProductsStatisticsRequest(startPeriod, endPeriod, period);
        })
      );

      response.forEach(({ data }) => {
        reportPeriods[data.period] = data.report;
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, values] of Object.entries(reportPeriods)) {
        values.forEach(({ p_name, total, total_reset, p_id }) => {
          set(tempReport, `${p_name}.${key}`, { p_id, total });

          const resetVal = get(tempReport, `reset.${key}.total_reset`, 0);
          set(tempReport, `reset.${key}`, { p_id, total_reset: total_reset + resetVal });
        });
      }
      setReport(tempReport);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    (async () => {
      await getStatistics();
    })();
  }, [customPeriodStart, customPeriodEnd]);

  const handleShowExternalColumns = (value: string[]) => setColumns(value);

  return (
    <div className={classes.scrollableContainer}>
      <div className={classes.customPeriodContainer}>
        <TableColumnsView
          relatedTables={columns}
          columns={FILTER_BY_DATES}
          handleShowExternalColumns={handleShowExternalColumns}
        />
        {isShowCustomPeriod && (
          <div className={classes.dateContainer}>
            <SelectCustomPeriod
              autoOk
              customPeriodStart={customPeriodStart}
              customPeriodEnd={customPeriodEnd}
              setCustomPeriodStart={setCustomPeriodStart}
              setCustomPeriodEnd={setCustomPeriodEnd}
              isActive={isShowCustomPeriod}
            />
          </div>
        )}
      </div>

      <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
        <thead>
          <tr>
            <td>Plan name</td>
            {isShowToday && <td>Today</td>}
            {isShowYesterday && <td>Yesterday</td>}
            {isShowThisWeek && <td>This week</td>}
            {isShowThisMonth && <td>{LAST_6_MONTH[0].text}</td>}
            {isShow1MonthAgo && <td>{LAST_6_MONTH[1].text}</td>}
            {isShow2MonthAgo && <td>{LAST_6_MONTH[2].text}</td>}
            {isShow3MonthAgo && <td>{LAST_6_MONTH[3].text}</td>}
            {isShow4MonthAgo && <td>{LAST_6_MONTH[4].text}</td>}
            {isShow5MonthAgo && <td>{LAST_6_MONTH[5].text}</td>}
            {isShow6MonthAgo && <td>{LAST_6_MONTH[6].text}</td>}
            {isShow7MonthAgo && <td>{LAST_6_MONTH[7].text}</td>}
            {isShowLast30days && <td>30 days</td>}
            {isShowThisQuarter && <td>This quarter</td>}
            {isShowThisYear && <td>This year</td>}
            {isShowLastYear && <td>Last year</td>}
            {isShowAllTime && <td>All time</td>}
            {isShowCustomPeriod && <td>Custom period</td>}
          </tr>
        </thead>

        <tbody>
          {Object.keys(report).map((key) => {
            if (key === "reset") {
              return null;
            }
            return (
              <tr key={`${key}`}>
                <td>{key}</td>
                {isShowToday && <td>{report[key][DATES_VALUE.today].total}</td>}
                {isShowYesterday && <td>{report[key][DATES_VALUE.yesterday].total}</td>}
                {isShowThisWeek && <td>{report[key][DATES_VALUE.thisWeek].total}</td>}
                {isShowThisMonth && <td>{report[key][DATES_VALUE.thisMonth].total}</td>}
                {isShow1MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_1].total}</td>}
                {isShow2MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_2].total}</td>}
                {isShow3MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_3].total}</td>}
                {isShow4MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_4].total}</td>}
                {isShow5MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_5].total}</td>}
                {isShow6MonthAgo && <td>{report[key][DATES_VALUE.numberMonthAgo_6].total}</td>}
                {isShowLast30days && <td>{report[key][DATES_VALUE.days30back].total}</td>}
                {isShowThisQuarter && <td>{report[key][DATES_VALUE.thisQuarter].total}</td>}
                {isShowThisYear && <td>{report[key][DATES_VALUE.thisYear].total}</td>}
                {isShowLastYear && <td>{report[key][DATES_VALUE.lastYEar].total}</td>}
                {isShowAllTime && <td>{report[key][DATES_VALUE.allTIme].total}</td>}

                {isShowCustomPeriod && (
                  <td>
                    {get(report, `${key}.${DATES_VALUE.customPeriod}.total`, 0) as React.ReactNode}
                  </td>
                )}
              </tr>
            );
          })}

          <tr>
            <td>Resets</td>
            {isShowToday && (
              <td>{get(report, `reset.${DATES_VALUE.today}.total_reset`, 0) as React.ReactNode}</td>
            )}
            {isShowYesterday && (
              <td>
                {get(report, `reset.${DATES_VALUE.yesterday}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowThisWeek && (
              <td>
                {get(report, `reset.${DATES_VALUE.thisWeek}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowThisMonth && (
              <td>
                {get(report, `reset.${DATES_VALUE.thisMonth}.total_reset`, 0) as React.ReactNode}
              </td>
            )}

            {isShow1MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_1}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow2MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_2}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow3MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_3}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow4MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_4}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow5MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_5}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow6MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_6}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShow7MonthAgo && (
              <td>
                {
                  get(
                    report,
                    `reset.${DATES_VALUE.numberMonthAgo_7}.total_reset`,
                    0
                  ) as React.ReactNode
                }
              </td>
            )}
            {isShowLast30days && (
              <td>
                {get(report, `reset.${DATES_VALUE.days30back}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowThisQuarter && (
              <td>
                {get(report, `reset.${DATES_VALUE.thisQuarter}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowThisYear && (
              <td>
                {get(report, `reset.${DATES_VALUE.thisYear}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowLastYear && (
              <td>
                {get(report, `reset.${DATES_VALUE.lastYEar}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowAllTime && (
              <td>
                {get(report, `reset.${DATES_VALUE.allTIme}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
            {isShowCustomPeriod && (
              <td>
                {get(report, `reset.${DATES_VALUE.customPeriod}.total_reset`, 0) as React.ReactNode}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductsStatistic;

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import moment from "moment";
import { uniqueId } from "lodash";

import Table from "../Table";
import newSortBy from "../../../../utils/newSortBy";
import useStyles from "../../useStyles";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import useGeneralStyles from "../../../../useGeneralStyles";
import { CopyToClipboard } from "../../../../components/ui";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";
import { useComplianceSearchContext } from "../../../../context";

export const COLUMNS: Array<IColum> = [
  {
    by: "timestamp",
    title: "Timestamp",
    path: "timestamp",
    getCellValue: (value) => (
      <>{moment(value).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}</>
    ),
    isSortable: true,
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
    isSortable: true,
  },
  {
    by: "ip",
    title: "IP",
    path: "ip",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
    isSortable: true,
  },
  {
    by: "success",
    title: "Success",
    path: "success",
    isSortable: true,
    getCellValue: (value) => (
      <>{value ? <b style={{ color: "green" }}>✔</b> : <b style={{ color: "red" }}>✘</b>}</>
    ),
  },
];
const LogLoginsTable = (): React.ReactElement => {
  const baseClasses = useStyles();
  const generalClasses = useGeneralStyles();
  const { data, loading } = useSelector((state) => state.complianceSearch.logins);
  const accounts_table = useSelector((state) => state.complianceSearch.accounts_table);
  const {
    logins_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <div>
      <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
        <Table
          theadTitle="Log of logins"
          loading={loading || accounts_table.loading}
          columns={COLUMNS}
          extractKey={(row) => row.timestamp + uniqueId()}
          data={data.rows}
          csv_table_name={"E2T Accounts Investigation - Log of logins"}
          handleSortBy={handleSortBy}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
};

export default LogLoginsTable;

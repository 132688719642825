import React from "react";

const Dashboard = ({
  w,
  h,
  m,
}: {
  w?: string | number;
  h?: string | number;
  m?: string | number;
}): React.ReactElement => (
  <svg
    height={h || w || "300px"}
    width={w || "300px"}
    style={{ marginBottom: m || 0, marginRight: 10 }}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <path d="M57.5,66.1c6.6,1.2,12.7,3.7,17.9,5.8c6.1,2.5,11.4,4.6,13.8,3c1.4-1,2.2-3.5,2.4-7.4h-7.9c-0.8,0-1.4-0.6-1.4-1.4  c0-0.8,0.6-1.4,1.4-1.4h7.9c-0.3-10.5-4.5-19.9-11.2-27.1l-5.6,5.6c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-1-0.4c-0.5-0.5-0.5-1.4,0-1.9  l5.6-5.6C71.3,29,61.8,24.8,51.4,24.5v7.9c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4v-7.9C38.2,24.8,28.7,29,21.5,35.7l5.6,5.6  c0.5,0.5,0.5,1.4,0,1.9c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-1-0.4l-5.6-5.6C12.9,44.8,8.7,54.3,8.4,64.8h7.9c0.8,0,1.4,0.6,1.4,1.4  c0,0.8-0.6,1.4-1.4,1.4H8.4c0.1,4,0.9,6.4,2.4,7.4c2.5,1.7,7.8-0.5,13.8-3c5.4-2.2,11.9-4.8,18.9-6c0,0.9,0.1,1.8,0.5,2.7  c0.7,1.7,1.9,3.1,3.7,3.8c0.8,0.4,1.7,0.5,2.6,0.5c2.7,0,5.2-1.6,6.3-4.2c0,0,0,0,0,0C56.7,68.3,57,67.4,57.5,66.1z M48.7,69.9  c-1-0.4-1.8-1.2-2.2-2.2s-0.4-2.1,0-3.1c1.4-3.3,11.7-22.8,15.5-26.8c-0.1,5.5-6.6,26.6-8,29.9C53.2,69.8,50.8,70.8,48.7,69.9z" />
  </svg>
);

export default Dashboard;

import React from "react";

import { ISortBy } from "../../../types";
import { TBy } from "../../../reducers/customersReducer";
import newSortBy from "../../../utils/newSortBy";
import TableDataCellWithSort from "../TableDataCellWithSort";

interface IProps {
  activeColumns: string[];
  sortBy: ISortBy;
  setSortBy: (a: ISortBy) => void;
}
const SortableTableHeader: React.FC<IProps> = ({ activeColumns, sortBy, setSortBy }) => {
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        {activeColumns.map((columnName) => (
          <TableDataCellWithSort
            key={columnName}
            sortBy={sortBy}
            by={columnName}
            title={columnName}
            newSortBy={handleSortBy}
          />
        ))}
      </tr>
    </thead>
  );
};

export default SortableTableHeader;

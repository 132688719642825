const getSubstringAfterNumbers = (input: string) => {
  const match = input.match(/\d+([a-zA-Z][a-zA-Z0-9]*)/);
  return match ? match[1] : null;
};

const PROP_FIRM_MAP = {
  e: "EDX",
  h: "Helios",
  a: "Appius",
};
const ACCOUNT_TYPE_MAP = {
  "1": "gm25",
  "2": "gm50",
  "3": "gm75",
  "4": "gm100",
  "5": "gm150",
  "6": "gm200",
  "7": "tcp25",
  "8": "tcp50",
  "9": "tcp100",
  "10": "tcp150",
  "11": "tcp200",
  "12": "tcp200 fixed",
  "13": "tcp400",
};

const ACCOUNT_DRAWDOWN_MAP = {
  t: "Trailing",
  d: "EOD",
};

export const getAccountTypeAndPropFirm = (
  accountName: string
): { accountType?: string; propFirm?: string; account_drawdown?: string } => {
  const substringAfterNumbers = getSubstringAfterNumbers(accountName);
  if (substringAfterNumbers) {
    const propFirmIndificator = substringAfterNumbers[0].toLocaleLowerCase();
    const accountTypeIndificator = substringAfterNumbers.slice(1, 3).replace(/[^0-9]/g, "");

    return {
      propFirm: PROP_FIRM_MAP[propFirmIndificator as keyof typeof PROP_FIRM_MAP] || "",
      accountType: ACCOUNT_TYPE_MAP[accountTypeIndificator as keyof typeof ACCOUNT_TYPE_MAP] || "",
      account_drawdown:
        ACCOUNT_DRAWDOWN_MAP[
          substringAfterNumbers.at(-1)?.toLocaleLowerCase() as keyof typeof ACCOUNT_DRAWDOWN_MAP
        ],
    };
  }

  return {
    accountType: "",
    propFirm: "",
    account_drawdown: "",
  };
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  info: {
    textAlign: "right",
    "& p": {
      margin: 0,
    },
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0,0,0,.2)",
  },
});

export default useStyles;
